import React, { useState, useEffect } from 'react';
import { Table, Card } from 'react-bootstrap';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { notificationMessage } from '../../../redux/healthBanc/actions/actionCreators';

const SentTable = ({
  handleSend,
  handleTrash,
  notifications,
  dottedOptions,
  notificationMessage
}) => {

  // hide modal
  const [hide, setHide] = useState(false);

  // instanciate history object
  const location = useLocation();
  const path = location.pathname.slice(1, location.pathname.length);

  // const openDropdown = () => setOpen(!open);
  useEffect(() => {
    const path = location.pathname;
    if (path === '/sent') {
      setHide(true);
    }
  });

  // map notification unto table row
  const tabelRow = notifications && notifications.map((notification, item) => (
    <tr key={item} className='table-row'>
      {/* <td>
        <form className='m-0 p-0 search-form'>
          <input type='checkbox' />
        </form>
      </td> */}
      <td onClick={() => notificationMessage(notification)}><Link to='/message'>{notification.subject}</Link></td>
      <td onClick={() => notificationMessage(notification)}><Link to='/message'>{notification.message.padEnd(35, '.')}</Link></td>
      <td onClick={() => notificationMessage(notification)}><Link to='/message'>{notification.scheduleDate.slice(0, 10)}</Link></td>
      <td className={hide ? 'dropdown-container d-none' : 'dropdown-container'}>
        <i><BsThreeDotsVertical /></i>
        <div className='dropdown-option'>
          <ul>
            <li onClick={(e) => handleSend(notification.id)}>{dottedOptions && dottedOptions.option1}</li>
            <li onClick={(e) => handleTrash(notification.id)}>{dottedOptions && dottedOptions.option2}</li>
          </ul>
        </div>
      </td>
    </tr>
  ));

  return (
    <div className='mt-4 template-table'>
      <Card className='nofication-card '>
        <Card.Body className='px-5 py-4'>
          <Table hover size="md" responsive='sm' className='notication-table' id={path}>
            <tbody>
              {tabelRow}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
};

export default connect(null, { notificationMessage })(SentTable);
