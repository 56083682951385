export const filter = [
  {
    name: 'Active Users',
    id: 1
  },
  {
    name: 'Pending Users',
    id: 2
  },
  {
    name: 'Inactive Users',
    id: 3
  },
];