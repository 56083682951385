import React from 'react'

const Ready = ({ checkCustomerState, loading }) => {
  return (
    <div className="axa-mansard-ready-section d-flex flex-column align-items-center justify-content-center">
      <h2 className="ready text-center px-3">Ready to take the next step?</h2>
      <p className="sign-up text-center px-3">
        Sign up and start enjoying amazing benefits.
      </p>
      <button className="get-early mx-auto" onClick={checkCustomerState}>
        {loading ? 'Starting...' : 'Enjoy your first month, for free.'}
      </button>
    </div>
  )
}

export default Ready
