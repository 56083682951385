import { FETCH_USER_PRODUCTS_ASYNC } from '../actions/actionTypes'

// const INITIAL_STATE = {
//     products: {
//         data: []
//     }
// }

const INITIAL_STATE = {
	userProducts: {
		results: [],
		currentPage: 1,
		pageCount: 1,
		pageSize: 10,
		recordCount: 0,
	},
}

export const userProductsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case FETCH_USER_PRODUCTS_ASYNC:
			return { ...state, userProducts: action.payload }

		default:
			return state
	}
}
