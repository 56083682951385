import { put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import {
  moveInactiveBeneficiariesToPendingListFailed,
  moveInactiveBeneficiariesToPendingListRequest,
  moveInactiveBeneficiariesToPendingListSuccess,
} from '../../actions/healthInsuredCreators/corporateUserActions/moveInactiveBeneficiariesToPendingListAction'

function* moveToPendingList({ payload }) {
  yield put(moveInactiveBeneficiariesToPendingListRequest())

  try {
    const { data } = yield axios.post(
      '/v2/api/CorporateInsurance/MoveCompanyBeneficiaryFromInactiveToPendingState',
      { emails: [...payload] }
    )

    alert(data.message)

    yield put(moveInactiveBeneficiariesToPendingListSuccess(data))
  } catch (error) {
    yield put(moveInactiveBeneficiariesToPendingListFailed(error.response))
  }
}

export function* watchMoveInactiveBeneficiariesToPendingList() {
  yield takeEvery(
    'MOVE_INACTIVE_BENEFICIARIES_TO_PENDING_LIST',
    moveToPendingList
  )
}
