import axios from 'axios'
import { store } from './redux/store'
import CryptoJS from 'crypto-js'

import { isLoadingAsync } from './redux/pharmHub/user/actions/actionCreators'
import { healthBancSignOut } from './redux/healthBanc/actions/actionCreators'

const { REACT_APP_ENCRYPTION_SECRET_KEY } = process.env
const { REACT_APP_VECTOR } = process.env

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL

// Set up axios request interceptors
axios.interceptors.request.use(
  function (config) {
    if (typeof config.headers.Authorization === 'undefined') {
      const token = store.getState().user.userInfo.token

      config.headers = {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'X-XSS-Protection': '1; mode=block',
        'Content-Type': 'application/json',
        'Cross-Origin-Embedder-Policy': 'require-corp',
        'Content-Security-Policy':
          "script-src 'self' 'unsafe-inline' kit.fontawesome.com code.jquery.com cdn.jsdelivr.net embed.tawk.to static-v.tawk.to stackpath.bootstrapcdn.com cdn.datatables.net pharmmall.azurewebsites.net https://standard.paystack.co",
        'X-Frame-Options': 'SAMEORIGIN',
        'X-Content-Type-Options': 'nosniff',
        'Strict-Transport-Security': 'max-age=31536000',
        'Feature-Policy':
          "accelerometer 'none'; gyroscope 'none'; magnetometer 'none'; microphone 'none'; usb 'none'",
        'Cache-Control': 'no-cache',
        'Cross-Origin-Opener-Policy': 'same-origin',
        Pragma: 'no-store',
      }
    }

    if (config?.url.toLowerCase().includes('v2')) {
      try {
        const cipherText = CryptoJS.AES.encrypt(
          JSON.stringify(config?.data?.data || config?.data),
          CryptoJS.enc.Utf8.parse(REACT_APP_ENCRYPTION_SECRET_KEY),
          {
            mode: CryptoJS.mode.CBC,
            iv: CryptoJS.enc.Utf8.parse(REACT_APP_VECTOR),
            keySize: 128 / 8,
          }
        ).toString()

        config.data = { data: cipherText }
      } catch (error) {}
    }

    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

// Axios Response Interceptor
axios.interceptors.response.use(
  function (response) {
    let isValidJSON = true

    // debugger

    try {
      const testData = JSON.parse(response?.data?.data)
    } catch (error) {
      isValidJSON = false
    }

    // if (
    //   response?.data &&
    //   !isValidJSON &&
    //   typeof response?.data?.data !== 'object' &&
    //   response?.config?.url.toLowerCase().includes('v2')
    // ) {
    //   //Decrypt Data

    //   const parsedData = response.data

    //   const bytes = CryptoJS.AES.decrypt(
    //     parsedData,
    //     CryptoJS.enc.Utf8.parse(REACT_APP_ENCRYPTION_SECRET_KEY),

    //     {
    //       mode: CryptoJS.mode.CBC,
    //       iv: CryptoJS.enc.Utf8.parse(REACT_APP_VECTOR),
    //       keySize: 128 / 8,
    //     }
    //   )

    //   // const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))

    //   const decryptedData = bytes.toString(CryptoJS.enc.Utf8)

    //   response.data = JSON.parse(decryptedData)
    // }

    return response
  },
  function (error) {
    // console.log(decryptResponse(error))

    if (updateOnlineStatus() === 'offline') {
      error = {
        message:
          'You are currently offline. Kindly turn on your network or try again',
      }
      return Promise.reject(error)
    }

    store.dispatch(isLoadingAsync(false))
    if (error.response.status === 401) {
      store.dispatch(healthBancSignOut())
    }

    return Promise.reject(error)
  }
)

function updateOnlineStatus() {
  let onLineStatus
  return (onLineStatus = navigator.onLine ? 'online' : 'offline')
}

window.addEventListener('offline', updateOnlineStatus)
window.addEventListener('online', updateOnlineStatus)
