import { takeLatest, put } from 'redux-saga/effects'
import axios from 'axios'
import {
  getDrugCategoryRequested,
  getDrugCategorySuccessful,
  getDrugCategoryFailed,
} from '../../../reducer/oneDrugStore/admin/drugCategorySlice'

function* getDrugCategoryAsync({ payload }) {
  try {
    yield getDrugCategoryRequested()

    const { data } = yield axios.get(
      `${process.env.REACT_APP_ONE_DRUG_BASE}/v1/api/DrugCategory/List`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('adminInfo')}`,
        },
      }
    )

    yield put(getDrugCategorySuccessful(data))
  } catch (error) {
    if (error.response) {
      yield put(getDrugCategoryFailed(error.response.data))
      yield put(getDrugCategoryFailed(null))
      return
    }

    yield put(getDrugCategoryFailed(error.message))
    yield put(getDrugCategoryFailed(null))
  }
}

export function* watchGetDrugCategory() {
  yield takeLatest(getDrugCategoryRequested.type, getDrugCategoryAsync)
}
