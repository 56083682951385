import React from 'react'
import { useHistory } from 'react-router-dom'
import { FiCheckCircle } from 'react-icons/fi'
import CustomButton from '../../../../healthBancComponents/Templates/CustomButton'
import StatusStyle from './StatusPage.style'

const PaymentFailed = () => {
  const history = useHistory()

  return (
    <StatusStyle>
      <FiCheckCircle className="icon-success" />
      <div className="status-massage ms-4">
        <h2>Great!!!</h2>
        <p>Your payment was successful.</p>
        <div className="col-sm-12 col-md-10">
          <CustomButton onClick={() => history.push('/OneDrugStore/shop')}>
            Back to OneDrugStore
          </CustomButton>
        </div>
      </div>
    </StatusStyle>
  )
}

export default PaymentFailed
