import React from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { GetStartedBtn } from '../ServicesIntro/PharmHubIntro'

const Div = styled.div`
	background-color: #213f7d;
	height: 250px;
	/* margin-bottom: 150px; */
	margin: 150px 0;

	.text {
		/* margin-top: 70px; */
		text-align: center;
		color: #fff;

		.heading {
			font-size: 24px;
			font-weight: 600px;
		}
	}
`

const Button = styled(GetStartedBtn)`
	background: #fff;
	color: #d71e1b;
	border: #fff;
`

const JoinCommunity = ({ history }) => {
	return (
		<Div className='container d-flex align-items-center mx-auto justify-content-center'>
			<div className='text'>
				<h4 className='heading'>Ready to Join the Community?</h4>
				<p>Sign up now!</p>
				<div>
					<Button onClick={() => history.push('/signup')}>
						Get Started
					</Button>
				</div>
			</div>
		</Div>
	)
}

export default withRouter(JoinCommunity)
