import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ListGroup, Row, Col } from 'react-bootstrap'

import Navbar from '../../../profileDashboard/navbar/NavBar'
import Header from '../../../profileDashboard/header/Header'

function FriendDetails() {
  const history = useHistory()
  const friends = useSelector((state) => state.friends.data?.data) ?? []

  const friend = friends.find(
    (friend) => friend.id === history.location.state?.friendId
  )
  return (
    <>
      <Header />

      <div className="family-dashboard">
        <div className="container">
          <Navbar />
        </div>

        <div className="family-dashboard__content container px-sm-2 px-md-5">
          {/* <button className="back-btn">&#8592; Go Back</button> */}
          <div className="family-dashboard__heading mb-4 ">
            <h1>Member</h1>
          </div>
          <ListGroup variant="flush" className="col-md-10 col-12">
            <ListGroup.Item>
              <Row>
                <Col>
                  <h5>Surname</h5>
                  <p>{friend.surname || 'Unavailable'}</p>
                </Col>
                <Col>
                  <h5>Other Names</h5>
                  <p>{friend.othernames || 'Unavailable'}</p>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col>
                  <h5>Phone number</h5>
                  <p>{friend.phoneNumber || 'Unavailable'}</p>
                </Col>
                <Col>
                  <h5>Residential Address</h5>
                  <p>{friend.contactAddress || 'Unavailable'}</p>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col>
                  <h5>Town</h5>
                  <p>{friend.townOfResidence || 'Unavailable'}</p>
                </Col>
                <Col>
                  <h5>Date of birth</h5>
                  <p>{new Date(friend.dateOfBirth).toDateString()}</p>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col>
                  <h5>Gender</h5>
                  <p>{friend.gender || 'Unavailable'}</p>
                </Col>
                <Col>
                  <h5>Marital Status</h5>
                  <p>{friend.maritalStatus || 'Unavailable'}</p>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col>
                  <h5>Occupation</h5>
                  <p>{friend.occupation || 'Unavailable'}</p>
                </Col>
                <Col>
                  <h5>Care Provider Hospital</h5>
                  <p>{friend.careProviderName || 'Unavailable'}</p>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col>
                  <h5>Care Provider City</h5>
                  <p>{friend.cpCity || 'Unavailable'}</p>
                </Col>
                <Col>
                  <h5>Enrollee Number</h5>
                  <p>{friend.transId || 'Unavailable'}</p>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col>
                  <h5>Active Status</h5>
                  {friend.activeStatus ? (
                    <p className="text-success">Active</p>
                  ) : (
                    <p className="text-danger">Inactive</p>
                  )}
                </Col>
                <Col>
                  <h5>Subscription Status</h5>
                  {friend.subscriptionStatus ? (
                    <p className="text-success">Active</p>
                  ) : (
                    <p className="text-danger">Inactive</p>
                  )}
                </Col>
              </Row>
            </ListGroup.Item>
          </ListGroup>
        </div>
      </div>
    </>
  )
}

export default FriendDetails
