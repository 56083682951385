import React from 'react';
import HealthInsuredDashboardStructure from '../../HealthInsuredDashboardStructure/HealthInsuredDashboardStructure';
import Nav from '../../components/notifications/nav/Nav';
import MainSection from '../../components/notifications/mainsection/Scheduled';

const Scheduled = () => {
  return (
    <HealthInsuredDashboardStructure title='Notification'>
      <Nav />
      <MainSection />
    </HealthInsuredDashboardStructure>
  );
};

export default Scheduled;