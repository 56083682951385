import React from 'react'
/**iterate over dff arays to get values and text for dff select fields */
import identifications from '../data/identificationType';
import gender from '../data/gender';
import maritalStatus from '../data/maritalStatus';
import Loading from './../../../pharmHub/admin/components/Spinner/Spinner';


function personalDetailsOptions({ towns, states, townLoading, getTownError }) {

  const genderOptions = gender.map((option, index) => {
    return <option key={index} value={option.id}>{option.name}</option>
  });

  const maritalStatusOptions = maritalStatus.map((option, index) => {
    return <option key={index} value={option.id}>{option.name}</option>
  });

  const identificationOption = identifications && identifications.map(identification => {
    return <option key={identification.code} value={identification.code}>{identification.text}</option>
  });

  const statesOption = states && states.map(state => {
    return <option key={state} value={state}>{state}</option>
  });

  const townOption = townLoading ? <Loading /> : towns.length === 0 ? <option value='No service provider in this state'>No service provider in this state</option> : towns[0].cities.map(towns => {
    return <option key={towns} value={towns}>{towns}</option>
  });

  return { genderOptions, maritalStatusOptions, identificationOption, statesOption, townOption }
}

export default personalDetailsOptions;