import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Row, Col, FormCheck, Button, Table } from 'react-bootstrap'
import CorporateDashboardStructure from '../../corporateDashboardStructure/CorporateDashboardStructure'
import BeneficiaryFilter from '../../components/beneficiaryFilter/BeneficiaryFilter'
import CustomButton from '../../../../../healthBancComponents/Templates/CustomButton'
import LoadingIndicator from '../../../../../healthBancComponents/LoadingIndicator/LoadingIndicator'
import HealthInsuredPagination from '../../components/healthInsuredPagination/HealthInsuredPagination'
import './beneficiaryReview.scss'

const BeneficiaryReview = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const beneficiariesReview = useSelector((state) => state.beneficiariesReview)

  const cardTokenizationStatus = useSelector(
    (state) => state.profileCompletionStatus
  )

  useEffect(() => {
    if (
      cardTokenizationStatus &&
      cardTokenizationStatus.profileCompletionStatus
    ) {
      cardTokenizationStatus.profileCompletionStatus.tokenizationCompleted &&
        history.push({
          pathname: '/health_corporate/beneficiaries',
          state: { pendingBeneficiaries: true },
        })
    }
  }, [cardTokenizationStatus, history])

  useEffect(() => {
    dispatch({ type: 'BENEFICIARIES_REVIEW' })
  }, [dispatch])

  const handleSearch = (e) => {
    dispatch({
      type: 'BENEFICIARIES_REVIEW',
      payload: { searchText: e.target.value },
    })
  }

  const handleSort = (e) => {
    dispatch({
      type: 'BENEFICIARIES_REVIEW',
      payload: { sortBy: Number(e.target.value) },
    })
  }

  const handleGoToPrevPage = () => {
    if (beneficiariesReview && beneficiariesReview.data) {
      const { data } = beneficiariesReview

      if (data.pageNumber === 1) {
        return
      } else {
        dispatch({
          type: 'BENEFICIARIES_REVIEW',
          payload: { pageNumber: data.pageNumber - 1 },
        })
      }
    }
  }

  const handleGoToNextPage = () => {
    if (beneficiariesReview && beneficiariesReview.data) {
      const { data } = beneficiariesReview

      if (data.pageNumber === data.pageCount) {
        return
      } else {
        dispatch({
          type: 'BENEFICIARIES_REVIEW',
          payload: { pageNumber: data.pageNumber + 1 },
        })
      }
    }
  }

  return (
    <CorporateDashboardStructure screenTitle='Beneficiary Review'>
      <Card className='w-100 px-4 pt-4 beneficiary__review'>
        <BeneficiaryFilter
          search={handleSearch}
          sort={handleSort}
          tableName='beneficiaries-review-table-to-xls'
        />

        <div className='mt-4 beneficiary__table'>
          <BeneficiariesTable beneficiaries={beneficiariesReview} />
        </div>

        <div className='beneficiary__review--pagination-container'>
          <HealthInsuredPagination
            prev={handleGoToPrevPage}
            next={handleGoToNextPage}
            pageDetails={
              beneficiariesReview && beneficiariesReview.data
                ? beneficiariesReview.data
                : {}
            }
          />
        </div>
      </Card>
    </CorporateDashboardStructure>
  )
}

export const BeneficiariesTable = ({ beneficiaries }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const beneficiariesList = (
    (beneficiaries.data && beneficiaries.data.data) ||
    []
  ).map((beneficiary, index) => (
    <tr key={index}>
      <td>
        <FormCheck />
      </td>
      <td>
        {beneficiary.firstName} {beneficiary.lastName}
      </td>
      <td>{beneficiary.email}</td>
      <td>{beneficiary.phoneNumber}</td>
      <td className='text-right'>{beneficiary.amount.toFixed(2)}</td>
      <td>
        {beneficiary.isRemove ? (
          <Button
            variant='link'
            className='beneficiary__table__button--restore p-0'
            onClick={() =>
              dispatch({
                type: 'RESTORE_BENEFICIARY_IN_REVIEW',
                payload: beneficiary.email,
              })
            }
          >
            Restore
          </Button>
        ) : (
          <Button
            variant='link'
            className='beneficiary__table__button--remove p-0'
            onClick={() =>
              dispatch({
                type: 'REMOVE_BENEFICIARY_IN_REVIEW',
                payload: beneficiary.email,
              })
            }
          >
            Remove
          </Button>
        )}
      </td>
    </tr>
  ))

  return beneficiaries.isLoading ? (
    <div className='pt-4 mx-auto text-center loading__indicator--container'>
      <LoadingIndicator />
    </div>
  ) : (
    <div>
      <Table
        striped
        hover
        responsive
        className='beneficiary__table'
        id='beneficiaries-review-table-to-xls' //If reusing the BeneficiaryFilter Component, table Id is required and Must be the same as the tableName value passed to the beneficiaryFilter component for the 'export as excel' feature to work
      >
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Amount</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {beneficiariesList.length === 0 ? (
            <tr>
              <td colSpan='6' className='text-center'>
                No pending beneficiaries
              </td>
            </tr>
          ) : (
            beneficiariesList
          )}
        </tbody>
      </Table>

      <div className='ml-auto beneficiary__grand-total--container'>
        <Row>
          <Col className='beneficiary--grand-total text-right'>
            <span className='label'> Grand Total:</span>{' '}
            <span className='figure'>
              &#8358;
              {beneficiaries &&
                beneficiaries.data &&
                beneficiaries.data.amount.toFixed(2)}
            </span>
          </Col>
        </Row>
        <Row>
          <Col className='ml-auto mt-4 text-right beneficiary__grand-total__button'>
            <CustomButton
              disabled={
                beneficiaries &&
                beneficiaries.data &&
                beneficiaries.data.amount === 0
                  ? true
                  : false
              }
              type='button'
              onClick={() =>
                history.push('/health_corporate/payment_card-info')
              }
            >
              Proceed to Pay
            </CustomButton>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default BeneficiaryReview
