import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  FiUsers,
  FiBriefcase,
  FiUserPlus,
  FiSettings,
  FiLogOut,
  FiGrid,
  FiCodepen,
  FiActivity,
} from 'react-icons/fi'
import { RiErrorWarningLine } from 'react-icons/ri'
import { FaUserCog } from 'react-icons/fa'
import { BsChat } from 'react-icons/bs'
import { IoMdNotificationsOutline } from 'react-icons/io'

import './sidebarNav.scss'

const SidebarNav = () => {
  const history = useHistory()

  const handleLogout = () => {
    localStorage.clear()

    history.push('/admin')
  }

  return (
    <div className="healthInsuredSideNav--container">
      <div className="healthInsuredSideNav">
        <Link to="/admin/health_insured/dashboard">
          <button className="healthInsuredSideNav__item">
            <span className="healthInsuredSideNav__item--icon">
              <FiGrid />
            </span>
            <span>Dashboard</span>
          </button>
        </Link>

        <Link to="/admin/health_insured/users">
          <button className="healthInsuredSideNav__item">
            <span className="healthInsuredSideNav__item--icon">
              <FiUsers />
            </span>
            Users
          </button>
        </Link>

        {/* <Link to="/admin/health_insured/groups">
          <button className="healthInsuredSideNav__item">
            <span className="healthInsuredSideNav__item--icon">
              <FiBriefcase />
            </span>
            <span>Groups</span>
          </button>
        </Link> */}

        <Link to="/admin/health_insured/transaction-log">
          <button className="healthInsuredSideNav__item">
            <span className="healthInsuredSideNav__item--icon">
              <FiActivity />
            </span>
            Transaction Log
          </button>
        </Link>

        {/* <Link to="/admin/health_insured/settings">
          <button className="healthInsuredSideNav__item">
            <span className="healthInsuredSideNav__item--icon">
              <FiSettings />
            </span>
            Settings
          </button>
        </Link> */}

        <Link
          to="/health_insured/admin/providers"
          className="healthInsuredSideNav__item"
        >
          <span className="healthInsuredSideNav__item--icon">
            <FiCodepen />
          </span>
          <span>Providers</span>
        </Link>

        {/* <Link
          to="/health_insured/admin/support/newtickets"
          className="healthInsuredSideNav__item"
        >
          <span className="healthInsuredSideNav__item--icon">
            <BsChat />
          </span>
          <span>Support</span>
        </Link> */}

        <Link
          to="/health_insured/admin/roles"
          className="healthInsuredSideNav__item"
        >
          <span className="healthInsuredSideNav__item--icon">
            <FiUserPlus />
          </span>
          Admin Roles
        </Link>

        <Link
          to="/admin/health_insured/error-log"
          className="healthInsuredSideNav__item"
        >
          <span className="healthInsuredSideNav__item--icon">
            <RiErrorWarningLine />
          </span>
          Error Log
        </Link>
        {/* <Link
          to="/health_insured/admin/notifications/drafts"
          className="healthInsuredSideNav__item"
        >
          <span className="healthInsuredSideNav__item--icon">
            <IoMdNotificationsOutline />
          </span>
          Notifications
        </Link> */}

        {/* <div className='healthInsured__admin__user'> */}
        {/* <span className='healthInsured__admin__user--icon'> */}
        {/* <FaUserCog /> */}

        <button
          className="healthInsuredSideNav__item btn"
          onClick={handleLogout}
        >
          <span className="healthInsuredSideNav__item--icon text-danger">
            <FiLogOut />
          </span>{' '}
          <span className="text-danger">Logout</span>
        </button>
        {/* </div> */}
      </div>
    </div>
  )
}

export default SidebarNav
