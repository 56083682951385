import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: {},
  error: null,
}

const getDeliveryInfoSlice = createSlice({
  name: 'getDeliveryInfo',
  initialState,
  reducers: {
    getDeliveryInfoRequested: (state) => {
      state.isLoading = true
      state.data = {}
      state.error = null
    },

    getDeliveryInfoSuccessful: (state, action) => {
      state.isLoading = false
      state.data = action.payload
      state.error = null
    },

    getDeliveryInfoFailed: (state, action) => {
      state.isLoading = false
      state.data = {}
      state.error = action.payload
    },
  },
})

export const {
  getDeliveryInfoRequested,
  getDeliveryInfoSuccessful,
  getDeliveryInfoFailed,
} = getDeliveryInfoSlice.actions

export default getDeliveryInfoSlice.reducer
