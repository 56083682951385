import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Spinner } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Product from '../Product/Product'
import PaginationTemplate from '../../../../healthBancComponents/Templates/PaginationTemplate/PaginationTemplate'
import { getAllDrugItemRequested } from '../../../../redux/healthBanc/reducer/oneDrugStore/user/drugItemsSlices'
import { getCartItemsRequested } from '../../../../redux/healthBanc/reducer/oneDrugStore/user/cartItemsSlices'

import './products.scss'

const Products = ({ setPageNumber }) => {
  const [addToCartError, setAddToCartError] = useState(false)

  const dispatch = useDispatch()

  const { isLoading, data: allDrugItem } = useSelector(
    (state) => state.getAllDrugItem
  )
  const { data: cartItems } = useSelector((state) => state.getCartItems)

  useEffect(() => {
    dispatch(getAllDrugItemRequested())
    dispatch(getCartItemsRequested())
  }, [dispatch])

  useEffect(() => {
    addToCartError &&
      toast.error('Item already in cart! \nGo to cart to adjust quantity.')

    return () => {
      setAddToCartError(false)
    }
  }, [addToCartError])

  const handleNextPage = () => {
    if (allDrugItem?.pageNumber === allDrugItem?.pageCount) {
      return
    }
    setPageNumber(allDrugItem.pageNumber + 1)
  }

  const handlePrevPage = () => {
    if (allDrugItem?.pageNumber <= 1) {
      return
    }

    setPageNumber(allDrugItem.pageNumber - 1)
  }

  return (
    <>
      <div>
        {isLoading ? (
          <Spinner
            animation="border"
            role="status"
            className="m-5 mx-auto d-flex"
            style={{
              width: '4rem',
              height: '4rem',
            }}
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          <>
            <div className="d-flex flex-wrap justify-content-start oneDrug__products mb-4">
              <ToastContainer />

              {/* <div className="product__category">Multivitamins & Supplements</div> */}

              {(allDrugItem?.data || []).map((drug) => (
                <div key={drug.id} className="product__item">
                  <Product
                    drug={drug}
                    cartItems={cartItems}
                    setError={setAddToCartError}
                  />
                </div>
              ))}
            </div>
            <PaginationTemplate
              className="d-flex mx-auto mt-5 col-12 text-center"
              pageDetails={{
                pageCount: allDrugItem?.pageCount,
                pageNumber: allDrugItem.pageNumber,
              }}
              prev={handlePrevPage}
              next={handleNextPage}
            />
          </>
        )}
      </div>
    </>
  )
}

export default Products
