import { takeEvery, put } from 'redux-saga/effects'
import axios from 'axios'
import {
  getHealthInsuredAdminActivityLogFailed,
  getHealthInsuredAdminActivityLogRequest,
  getHealthInsuredAdminActivityLogSuccess,
} from '../../actions/healthInsuredCreators/adminUserActions/getHealthInsuredAdminActivityLog'

function* getHealthInsuredAdminActivityLog({ payload }) {
  const { pageNumber = 1 } = payload

  yield put(getHealthInsuredAdminActivityLogRequest())

  try {
    const { data } = yield axios({
      method: 'post',
      url: `/v2/api/ActivityLog/GetAdminActivityLogs`,
      data: {
        PageNumber: pageNumber,
      },
      headers: { Authorization: `Bearer ${localStorage.getItem('adminInfo')}` },
    }).then((response) => response)

    yield put(getHealthInsuredAdminActivityLogSuccess(data))
  } catch (error) {
    yield put(getHealthInsuredAdminActivityLogFailed(error.response))
  }
}

export function* watchGetHealthInsuredAdminActivityLog() {
  yield takeEvery(
    'GET_HEALTHINSURED_ADMIN_ACTIVITY_LOG',
    getHealthInsuredAdminActivityLog
  )
}
