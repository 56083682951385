import {
  CREATE_CORPORATE_USER_FAILED,
  CREATE_CORPORATE_USER_REQUEST,
  CREATE_CORPORATE_USER_SUCCESS,
} from '../../healthInsuredTypes/corporateActionTypes'

export const createCorporateUserRequest = () => ({
  type: CREATE_CORPORATE_USER_REQUEST,
})

export const createCorporateUserSuccess = (userData) => ({
  type: CREATE_CORPORATE_USER_SUCCESS,
  payload: userData,
})

export const createCorporateUserFailed = (errorMsg) => ({
  type: CREATE_CORPORATE_USER_FAILED,
  payload: errorMsg,
})
