import { takeLatest, put } from 'redux-saga/effects'
import axios from 'axios'
import {
  deletePharmacyRequested,
  deletePharmacySuccessful,
  deletePharmacyFailed,
  getAllPharmacyRequested,
} from '../../../reducer/oneDrugStore/admin/pharmacySlices'

function* deletePharmacyAsync({ payload }) {
  try {
    yield deletePharmacyRequested()

    const { data } = yield axios({
      method: 'DELETE',
      url: `${process.env.REACT_APP_ONE_DRUG_BASE}/v1/api/Pharmacy/Delete?pharmacyId=${payload}`,
      headers: { Authorization: `Bearer ${localStorage.getItem('adminInfo')}` },
    })

    yield put(deletePharmacySuccessful(data))
    yield put(getAllPharmacyRequested())
  } catch (error) {
    if (error.response) {
      yield put(deletePharmacyFailed(error.response.data))
      yield put(deletePharmacyFailed(null))
      return
    }

    yield put(deletePharmacyFailed(error.message))
    yield put(deletePharmacyFailed(null))
  }
}

export function* watchDeletePharmacy() {
  yield takeLatest(deletePharmacyRequested.type, deletePharmacyAsync)
}
