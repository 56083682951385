import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { RiVisaLine } from 'react-icons/ri';
import { GrAdd } from 'react-icons/gr';
import shortId from 'shortid';

import Loading from '../../../../../pharmHub/admin/components/Spinner/Spinner';
import MasterCard from '../../../assets/mastercard.png';
import VerveCard from '../../../assets/verveCard.png';

const CardSection = ({ handleShowAddcard, loading, cards, handleShowDeleteCard }) => {

  useEffect(() => window.scrollTo(0, 0), []);


  const addedCard = cards !== 0 && cards.map(card => (<div className='card-container' key={shortId.generate()} >
    <div className='the-card' tabIndex='0' >
      <div className='card-back justify-content-center align-items-center' >
        <button className='delete-card' onClick={(e) => handleShowDeleteCard(card.id, e)}>Delete card</button>
      </div>

      <div className='card-master' >
        <div className='master-card'>
          <div className='master-one'></div>
          <div className='master-two' ></div>
          <div className='master-three' ></div>
          {
            card.type.trim() === 'master' ?
              <img src={MasterCard} alt='master card' className='master-card-logo' /> :
              card.type.trim() === 'verve' ? <img src={VerveCard} alt='verve logo' className='master-card-verve' /> :
                <RiVisaLine className='master-visa-logo' />
          }
          <p className='card-number'>• • • •{card.lastFourDigit}</p>
        </div>
      </div>
    </div>
  </div>
  )
  )

  if (loading) return <Loading />;

  return (
    <div>
      <Card className='axa-mansard-dashboard-card '>
        <Card.Body className='card-dashboard'>
          {addedCard}

          <div className='addCard d-flex justify-content-center align-items-center'>
            <div className='add-new-wrapper d-flex flex-column align-items-center' onClick={handleShowAddcard}>
              <GrAdd className='icon' />
              <button className='add-new' >Add new card</button>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );

};

export default CardSection;
