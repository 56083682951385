import {
  GET_COMPANY_PROFILE_FAILED,
  GET_COMPANY_PROFILE_REQUEST,
  GET_COMPANY_PROFILE_SUCCESS,
} from '../../../actions/healthInsuredTypes/corporateActionTypes'

const initialState = {
  isLoading: false,
  data: {},
  errorMessage: [],
}

export const getCompanyProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANY_PROFILE_REQUEST:
      return { isLoading: true, data: {}, errorMessage: [] }

    case GET_COMPANY_PROFILE_SUCCESS:
      return { isLoading: false, data: action.payload, errorMessage: [] }

    case GET_COMPANY_PROFILE_FAILED:
      return { isLoading: false, data: {}, errorMessage: action.payload }

    default:
      return state
  }
}
