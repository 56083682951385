import {
  SUBMIT_CORPORATE_CARD_OTP_FAILED,
  SUBMIT_CORPORATE_CARD_OTP_REQUEST,
  SUBMIT_CORPORATE_CARD_OTP_SUCCESS,
} from '../../healthInsuredTypes/corporateActionTypes'

export const submitCorporateCardOtpRequest = () => ({
  type: SUBMIT_CORPORATE_CARD_OTP_REQUEST,
})

export const submitCorporateCardOtpSuccess = (data) => ({
  type: SUBMIT_CORPORATE_CARD_OTP_SUCCESS,
  payload: data,
})

export const submitCorporateCardOtpFailed = (errorMessage) => ({
  type: SUBMIT_CORPORATE_CARD_OTP_FAILED,
  payload: errorMessage,
})
