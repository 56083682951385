import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Spinner } from 'react-bootstrap'

import CartItem from '../../components/cartItem/CartItem'
import Summary from '../../components/cartSummary/Summary'
import Layout from '../../components/Layout/Layout'

import './cart.scss'

const Cart = () => {
  const [productsInCart, setProductsInCart] = useState([])

  const history = useHistory()

  const { data: cartItems, isLoading: loadingCartItems } = useSelector(
    (state) => state.getCartItems
  )

  const { data: allDrugItems, isLoading: loadingDrugItems } = useSelector(
    (state) => state.getAllDrugItem
  )

  //Get drug Items and append quantity and total for each item
  const itemsWithQuantityAndSumTotal = () => {
    const cartDetails = []

    cartItems.forEach((item) => {
      const product = (allDrugItems?.data || []).find(
        (drug) => drug.id === item.drugItemId
      )

      cartDetails.push({
        ...product,
        quantity: item.quantity,
        total: product?.price * item?.quantity || 0,
      })
    })

    setProductsInCart(cartDetails)
  }

  useEffect(() => {
    cartItems && allDrugItems && itemsWithQuantityAndSumTotal()
  }, [allDrugItems, cartItems])

  // const handleClearCart = () => {
  //   console.log('Clear Shopping Cart')
  // }

  return (
    <Layout>
      <div className="oneDrug__cart container py-4">
        <h5>Shopping Cart</h5>

        <div className="d-lg-flex">
          <div className="mt-5 col-sm-7 col-md-9">
            {loadingCartItems || loadingDrugItems ? (
              <Spinner
                animation="border"
                role="status"
                className="m-5 mx-auto d-flex"
                style={{
                  width: '4rem',
                  height: '4rem',
                }}
              >
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              productsInCart.map((product) => (
                <CartItem key={product.id} details={product} />
              ))
            )}

            <div className="cta__btn">
              <button
                className="cta__btn--continue-shopping"
                onClick={() => history.push('/OneDrugStore/shop')}
              >
                Continue Shopping
              </button>
              {/* <button
                className="cta__btn--clear-cart"
                onClick={handleClearCart}
              >
                Clear shopping cart
              </button> */}
            </div>
          </div>
          <div className="col-sm-4 col-md-3 col-12 px-0">
            {/* <Summary totalPrice={sumTotal} /> */}
            <Summary productsInCart={productsInCart} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Cart
