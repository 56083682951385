import { FETCH_SHOWCASE_PRODUCTS_ASYNC } from "../actions/actionTypes"

const INITIAL_STATE = {
    showcaseProducts : []
}
export const showcaseProductsReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case FETCH_SHOWCASE_PRODUCTS_ASYNC:
            return {...state, showcaseProducts: action.payload}
        default:
            return state
    }
}