import {
  CHARGE_CORPORATE_CARD_FAILED,
  CHARGE_CORPORATE_CARD_REQUEST,
  CHARGE_CORPORATE_CARD_SUCCESS,
} from '../../../actions/healthInsuredTypes/corporateActionTypes'

export const chargeCorporateCardReducer = (state = {}, action) => {
  switch (action.type) {
    case CHARGE_CORPORATE_CARD_REQUEST:
      return { isLoading: true }

    case CHARGE_CORPORATE_CARD_SUCCESS:
      if (
        action.payload &&
        action.payload.data &&
        action.payload.responseCode === 0
      ) {
        // Delay for 5 secs before redirecting
        // setTimeout(
        //   () => (window.location.href = '/health_corporate/beneficiaries'),
        //   5000
        // )
        window.location.href = '/health_corporate/beneficiaries'
      } else if (
        action.payload &&
        action.payload.data &&
        action.payload.responseCode === 12
      ) {
        window.location.href = '/health_corporate/payment_otp'
      } else if (
        action.payload &&
        action.payload.data &&
        action.payload.responseCode === 20
      ) {
        const { redirectUrl } = action.payload.data

        const newWindow = window.open(redirectUrl, '_blank')

        if (
          !newWindow ||
          newWindow.closed ||
          typeof newWindow.closed === 'undefined'
        ) {
          alert(
            'Please enable pop-up and redirect on your browser to complete payment'
          )
          return
        }

        window.location.href = '/health_corporate/beneficiaries'

        // setTimeout(() => {
        //   window.location.href = '/health_corporate/beneficiaries'
        // }, 10000)
      }

      return { isLoading: false, ...action.payload }

    case CHARGE_CORPORATE_CARD_FAILED:
      if (
        action.payload &&
        action.payload.data &&
        action.payload.data.Message
      ) {
        // Error msg with uppercase 'M'
        alert(action.payload.data.Message)
      } else if (
        action.payload &&
        action.payload.data &&
        action.payload.data.message
      ) {
        // Error msg with lowercase 'm'
        alert(action.payload.data.message)
      } else {
        alert('An Error occured. \nPlease try again or contact support.')
      }
      return { isLoading: false, errorMessage: action.payload }

    default:
      return state
  }
}
