import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Field, Form } from 'formik'
import * as yup from 'yup'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Layout from '../../../components/adminComponents/Layout/Layout'
import CustomInput from '../../../../../healthBancComponents/Templates/CustomInput'
import CustomTextarea from '../../../../../healthBancComponents/Templates/CustomTextarea'
import CustomButton from '../../../../../healthBancComponents/Templates/CustomButton'
import {
  createAggregatorRequested,
  updateAggregatorRequested,
} from '../../../../../redux/healthBanc/reducer/oneDrugStore/admin/aggregatorSlices'

const CreateAggregator = () => {
  const [aggregator, setAggregator] = useState({})
  const dispatch = useDispatch()

  const history = useHistory()

  const { id: aggregatorId } = useParams()

  const { isLoading: isCreating } = useSelector(
    (state) => state.createAggregator
  )
  const { isLoading: isUpdating } = useSelector(
    (state) => state.updateAggregator
  )
  const aggregators = useSelector((state) => state.getAggregators.data)

  useEffect(() => {
    const aggregatorForEdit = aggregators?.data?.find(
      (aggregator) => aggregator.id === +aggregatorId
    )
    setAggregator(aggregatorForEdit)
  }, [aggregatorId, aggregators])

  const aggregatorSchema = yup.object().shape({
    name: yup.string().required('This field is required'),
    description: yup.string(),
    address: yup.string(),
  })

  const handleSubmit = (values, actions) => {
    if (aggregatorId) {
      dispatch(
        updateAggregatorRequested({
          ...values,
          aggregatorId: Number(aggregatorId),
          history,
        })
      )
    } else {
      dispatch(createAggregatorRequested(values))
    }
  }

  return (
    <Layout canGoBack>
      <ToastContainer />
      <div className="mt-5 mx-4">
        <div className="mt-5">
          <h4>{aggregatorId ? 'Update Aggregator' : 'Create Aggregator'}</h4>

          <div>
            <Formik
              initialValues={{
                name: aggregator?.name || '',

                description: aggregator?.description || '',
                address: aggregator?.address || '',
              }}
              validationSchema={aggregatorSchema}
              onSubmit={(values, actions) => handleSubmit(values, actions)}
              enableReinitialize={true}
            >
              {(props) => (
                <Form>
                  <div className="col-6 ms-4 my-4">
                    <Field
                      as={CustomInput}
                      id="name"
                      type="text"
                      label="Name"
                      name="name"
                      placeholder="Enter an aggregator's name"
                    />

                    <Field
                      as={CustomTextarea}
                      id="description"
                      type="text"
                      label="Description"
                      name="description"
                      placeholder="Brief description"
                    />

                    <Field
                      as={CustomInput}
                      id="address"
                      type="text"
                      label="Address"
                      name="address"
                      placeholder="Enter an aggregator's address"
                    />
                    <div className="col-6">
                      {aggregatorId ? (
                        <CustomButton type="submit" disabled={isUpdating}>
                          {isUpdating ? 'Updating...' : 'Update'}
                        </CustomButton>
                      ) : (
                        <CustomButton type="submit" disabled={isCreating}>
                          {isCreating ? 'Creating...' : 'Create'}
                        </CustomButton>
                      )}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CreateAggregator
