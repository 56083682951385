import {
	GET_DASHBOARD_REQUIREMENTS_ASYNC,
	GET_USER_NOTIFICATIONS_ASYNC,
	SET_NEW_ACCOUNT,
	GET_BANK_ACCOUNT_DETAILS_ASYNC,
	SET_COMPANY_DETAILS,
	SAVE_BUSINESS_INFO_ASYNC,
	SAVE_ROLES_AND_SIGNATORIES,
	SAVE_BUSINESS_CREDENTIALS_ASYNC,
	IS_LOADING,
	FETCH_USER_PRODUCTS_ASYNC,
	GET_CART_ITEMS_ASYNC,
	ADD_TO_CART_ASYNC,
	UPDATE_CART_ITEM_QTY_ASYNC,
	DELETE_FROM_CART_ASYNC,
	USER_ID,
	ACCOUNT_REQUEST_ID,
	COMPANY_ID,
	ADMIN_ID,
	VALID_ID_STATUS,
	PHARMACIST_LICENCE_STATUS,
	ADMIN_INDIVIDUAL_ID,
	GET_USER_UPLINE_LIST_ASYNC,
	GET_USER_DOWNLINE_LIST_ASYNC,
	GET_REQUEST_TO_JOIN_ASYNC,
	FETCH_SHOWCASE_PRODUCTS_ASYNC,
	INCOMING_ORDER_REQUEST_ASYNC,
	OUTGOING_ORDER_REQUEST_ASYNC,
	PREMISES_LICENCE_STATUS,
	FETCH_COMPANY_LIST_IN_USER_LINE_ASYNC,
} from './actionTypes'

export const getDashboardRequirementsAsync = (requirements) => ({
	type: GET_DASHBOARD_REQUIREMENTS_ASYNC,
	payload: requirements,
})

export const getUserNotificationsAsync = (notifications) => ({
	type: GET_USER_NOTIFICATIONS_ASYNC,
	payload: notifications,
})

export const getBankAccountDetailsAsync = (details) => ({
	type: GET_BANK_ACCOUNT_DETAILS_ASYNC,
	payload: details,
})

export const newAccountType = (accountType) => {
	return {
		type: SET_NEW_ACCOUNT,
		payload: { ...accountType },
	}
}

export const setCompanyDetails = (companyDetails) => {
	return {
		type: SET_COMPANY_DETAILS,
		payload: { ...companyDetails },
	}
}

export const isLoadingAsync = (bool) => {
	return {
		type: IS_LOADING,
		payload: bool,
	}
}

// Documents Exist
export const setValidIdStatus = (bool) => ({
	type: VALID_ID_STATUS,
	payload: bool,
})

export const setPharmacistLicenceStatus = (bool) => ({
	type: PHARMACIST_LICENCE_STATUS,
	payload: bool,
})

export const setPremisesLicenceStatus = (bool) => ({
	type: PREMISES_LICENCE_STATUS,
	payload: bool,
})

// Save Business Info Form
export const saveBusinessInfoAsync = (businessData) => ({
	type: SAVE_BUSINESS_INFO_ASYNC,
	payload: businessData,
})

export const saveRolesAndSignatoriesAsync = (signatories) => ({
	type: SAVE_ROLES_AND_SIGNATORIES,
	payload: signatories,
})

export const saveBusinessCredentialsAsyc = () => ({
	type: SAVE_BUSINESS_CREDENTIALS_ASYNC,
})

//Fetch list of companies a user can purchase from
export const fetchCompanyListAsync = (companyList) => ({
	type: FETCH_COMPANY_LIST_IN_USER_LINE_ASYNC,
	payload: companyList,
})

//User Products
export const fetchUserProductsAsync = (products) => ({
	type: FETCH_USER_PRODUCTS_ASYNC,
	payload: products,
})

export const fetchShowcaseProductsAsync = (products) => ({
	type: FETCH_SHOWCASE_PRODUCTS_ASYNC,
	payload: products,
})

//Cart Actions
export const getCartItemsAsync = (cartItems) => ({
	type: GET_CART_ITEMS_ASYNC,
	payload: cartItems,
})
export const addToCartAsync = (item) => ({
	type: ADD_TO_CART_ASYNC,
	payload: item,
})

export const updateCartItemQtyAsync = (updatedCart) => ({
	type: UPDATE_CART_ITEM_QTY_ASYNC,
	payload: updatedCart,
})

export const deleteFromCartAsync = (item) => ({
	type: DELETE_FROM_CART_ASYNC,
	payload: item,
})

// admin backend action creators
// user email for individual details
export const userId = (id) => ({
	type: USER_ID,
	id,
})

// report id for view individual report
export const accountRequestId = (id) => ({
	type: ACCOUNT_REQUEST_ID,
	id,
})

export const companyId = (id) => ({
	type: COMPANY_ID,
	id,
})

export const adminId = (id) => ({
	type: ADMIN_ID,
	id,
})

export const adminIndividualId = (id) => ({
	type: ADMIN_INDIVIDUAL_ID,
	id,
})

//get User upline action
export const getUserUplineAsync = (uplineList) => ({
	type: GET_USER_UPLINE_LIST_ASYNC,
	payload: uplineList,
})

//get User downline action
export const getUserDownlineAsync = (downlineList) => ({
	type: GET_USER_DOWNLINE_LIST_ASYNC,
	payload: downlineList,
})

//get requests to join network action
export const getRequestToJoinAsync = (requests) => ({
	type: GET_REQUEST_TO_JOIN_ASYNC,
	payload: requests,
})

//Orders Requests
export const getIncomingOrderRequestAsync = (orders) => ({
	type: INCOMING_ORDER_REQUEST_ASYNC,
	payload: orders,
})

export const getOutgoingOrderRequestAsync = (orders) => ({
	type: OUTGOING_ORDER_REQUEST_ASYNC,
	payload: orders,
})
