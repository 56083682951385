import { takeEvery, put } from 'redux-saga/effects'
import axios from 'axios'
import {
  corporateProfileCompletionStatusFailed,
  corporateProfileCompletionStatusRequest,
  corporateProfileCompletionStatusSuccess,
} from '../../actions/healthInsuredCreators/corporateUserActions/corporateProfileCompletionStatusActions'

function* profileCompletionStatus() {
  try {
    yield put(corporateProfileCompletionStatusRequest())

    const { data } = yield axios({
      method: 'GET',
      url: '/v2/api/CorporateInsurance/GetCompanyProfileDetails',
    })

    yield put(corporateProfileCompletionStatusSuccess(data))
  } catch (error) {
    yield put(corporateProfileCompletionStatusFailed(error.response))
  }
}

export function* watchCorporateProfileCompletionStatus() {
  yield takeEvery(
    'CORPORATE_PROFILE_COMPLETION_STATUS',
    profileCompletionStatus
  )
}
