import { createSlice } from '@reduxjs/toolkit'

const initialState = []

const nonAuthUsercartItemsSlice = createSlice({
  name: 'nonAuthUserCartItems',
  initialState,
  reducers: {
    addNonAuthUserCartItems: (state, action) => {
      state = [...state, action.payload]
      return state
    },

    increaseCartItemQuantity: (state, action) => {
      return state.map((product) =>
        product.productId === action.payload
          ? { ...product, quantity: product.quantity + 1 }
          : product
      )
    },

    decreaseCartItemQuantity: (state, action) => {
      return state.map((product) =>
        product.productId === action.payload
          ? { ...product, quantity: product.quantity - 1 }
          : product
      )
    },

    deleteCartItem: (state, action) => {
      return state.filter((product) => product.productId !== action.payload)
    },
  },
})

export const {
  addNonAuthUserCartItems,
  increaseCartItemQuantity,
  decreaseCartItemQuantity,
  uploadCartItemPrescription,
  deleteCartItem,
} = nonAuthUsercartItemsSlice.actions
export default nonAuthUsercartItemsSlice.reducer
