const INITIAL_STATE = {
  userId: "",
};

export const userIdReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "USER_ID":
      return { ...state, userId: action.id };

    default:
      return state;
  }
};
