import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ListGroup, Row, Col } from 'react-bootstrap'

import Navbar from '../healthInsuredIndividualFlow/profileDashboard/navbar/NavBar'
import Header from '../healthInsuredIndividualFlow/profileDashboard/header/Header'

function FamilyMemberDetails() {
  const history = useHistory()
  const familyMembers =
    useSelector((state) => state.getFamilyMembers.data?.data?.data) || []

  const member = familyMembers.find(
    (member) => member.id === history.location.state?.memberId
  )
  return (
    <>
      <Header />

      <div className="family-dashboard">
        <div className="container">
          <Navbar />
        </div>

        <div className="family-dashboard__content container px-sm-2 px-md-5">
          {/* <button className="back-btn">&#8592; Go Back</button> */}
          <div className="family-dashboard__heading mb-4 ">
            <h1>Member</h1>
          </div>
          <ListGroup variant="flush" className="col-md-10 col-12">
            <ListGroup.Item>
              <Row>
                <Col>
                  <h5>Surname</h5>
                  <p>{member.surname}</p>
                </Col>
                <Col>
                  <h5>Other Names</h5>
                  <p>{member.othernames}</p>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col>
                  <h5>Residential Address</h5>
                  <p>{member.contactAddress}</p>
                </Col>
                <Col>
                  <h5>Town</h5>
                  <p>{member.townOfResidence}</p>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col>
                  <h5>Date of birth</h5>
                  <p>{new Date(member.dateOfBirth).toDateString()}</p>
                </Col>
                <Col>
                  <h5>Gender</h5>
                  <p>{member.gender}</p>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col>
                  <h5>Marital Status</h5>
                  <p>{member.maritalStatus}</p>
                </Col>
                <Col>
                  <h5>Occupation</h5>
                  <p>{member.occupation}</p>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col>
                  <h5>Care Provider Hospital</h5>
                  <p>{member.careProviderName}</p>
                </Col>
                <Col>
                  <h5>Care Provider City</h5>
                  <p>{member.cpCity}</p>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col>
                  <h5>Enrollee Number</h5>
                  <p>{member.transId || 'Unavailable'}</p>
                </Col>
                <Col>
                  <h5>Active Status</h5>
                  {member.activeStatus ? (
                    <p className="text-success">Active</p>
                  ) : (
                    <p className="text-danger">Inactive</p>
                  )}
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col>
                  <h5>Subscription Status</h5>
                  {member.subscriptionStatus ? (
                    <p className="text-success">Active</p>
                  ) : (
                    <p className="text-danger">Inactive</p>
                  )}
                </Col>
              </Row>
            </ListGroup.Item>
          </ListGroup>
        </div>
      </div>
    </>
  )
}

export default FamilyMemberDetails
