import React from 'react';

const CustomeButton = ({ buttonText, disable }) => {
  return (
    <button
      className='axa-mansard-custom-button my-4'
      disabled={disable}
    >
      {buttonText}
    </button>
  );
};

export default CustomeButton;
