import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CorporateDashboardStructure from '../../corporateDashboardStructure/CorporateDashboardStructure'
import DebitCardInfoForm from '../../../../../healthBancComponents/debitCardInfoForm/DebitCardInfoForm'

const CorporatePaymentCardInfo = () => {
  const dispatch = useDispatch()

  const chargeCard = useSelector((state) => state.chargeCorporateCard)

  const handleSubmitCardInfo = (values, { isSubmitting }) => {
    dispatch({ type: 'CHARGE_CORPORATE_CARD', payload: values })
  }

  return (
    <CorporateDashboardStructure screenTitle="Payment">
      <DebitCardInfoForm
        chargeCard={chargeCard}
        handleSubmitCardInfo={handleSubmitCardInfo}
      />
    </CorporateDashboardStructure>
  )
}

export default CorporatePaymentCardInfo
