import {
  GET_COMPANY_PROFILE_FAILED,
  GET_COMPANY_PROFILE_REQUEST,
  GET_COMPANY_PROFILE_SUCCESS,
} from '../../healthInsuredTypes/corporateActionTypes'

export const getCompanyProfileRequest = () => ({
  type: GET_COMPANY_PROFILE_REQUEST,
})

export const getCompanyProfileSuccess = (payload) => ({
  type: GET_COMPANY_PROFILE_SUCCESS,
  payload,
})

export const getCompanyProfileFailed = (errorMessage) => ({
  type: GET_COMPANY_PROFILE_FAILED,
  payload: errorMessage,
})
