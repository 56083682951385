import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Table, Button } from 'react-bootstrap'
import ConfirmModal from '../../../../healthInsuredFamilyFlow/ConfirmActionModal'
import CustomButton from '../../../../../../healthBancComponents/Templates/CustomButton'
import LoadingIndicator from '../../../../../../healthBancComponents/LoadingIndicator/LoadingIndicator'
import CustomSearchInput from '../../../../../../healthBancComponents/Templates/CustomSearchInput/CustomSearchInput'
import PaginationTemplate from '../../../../../../healthBancComponents/Templates/PaginationTemplate/PaginationTemplate'
import { REMOVE_FRIEND_REQUESTED } from '../../../../../../redux/healthBanc/reducer/healthInsuredReducer/removeFriendReducer'
import { GET_FRIENDS_REQUESTED } from '../../../../../../redux/healthBanc/reducer/healthInsuredReducer/getIndividualFriendsReducer'

import './_friendsTable.scss'
import { DEACTIVATE_FRIEND_REQUESTED } from '../../../../../../redux/healthBanc/reducer/healthInsuredReducer/deactivateFriendReducer'
import { ACTIVATE_FRIEND_REQUESTED } from '../../../../../../redux/healthBanc/reducer/healthInsuredReducer/activateFriendReducer'

const FriendsTable = ({ isCardTokenized }) => {
  const [show, setShow] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})
  const [action, setAction] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [currentPage, setCurrentPage] = useState(1)

  const friendState = useSelector((state) => state.friends)
  const removeFriend = useSelector((state) => state.removeFriend)
  const deactivateFriend = useSelector((state) => state.deactivateFriend)
  const activateFriend = useSelector((state) => state.activateFriend)

  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(GET_FRIENDS_REQUESTED({ searchValue, pageNumber: currentPage }))
  }, [currentPage, dispatch, searchValue])

  const handleShow = (action, item) => {
    setShow(true)
    setSelectedItem(item)
    setAction(action)
  }

  const handleRemoveFriend = (friend) => {
    dispatch(REMOVE_FRIEND_REQUESTED({ email: friend.email, setShow }))
  }

  const handleActivateFriend = (friend) => {
    dispatch(ACTIVATE_FRIEND_REQUESTED({ id: friend.id, setShow }))
  }
  const handleDeactivateFriend = (friend) => {
    dispatch(DEACTIVATE_FRIEND_REQUESTED({ id: friend.id, setShow }))
  }

  const handleGoToNextPage = () => {
    const { pageCount, pageNumber } = friendState

    if (pageNumber < pageCount) {
      setCurrentPage(pageNumber + 1)
    }
  }

  const handleGoToPrevPage = () => {
    const { pageNumber } = friendState
    if (pageNumber > 1) {
      setCurrentPage(pageNumber - 1)
    }
  }

  const displayFriends = (friendState.data?.data ?? []).map((friend, index) => {
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>
          <Link
            to={{
              pathname: `/health-friends/${friend.email}`,
              state: { friendId: friend.id },
            }}
          >
            <td>
              {friend.surname}, {friend.othernames || 'Unavailable'}
            </td>
          </Link>
        </td>
        <td>{friend.email || 'Unavailable'}</td>
        {/* <td>{friend.cpAddress || 'Unavailable'}</td> */}
        <td>{friend.transId || 'Unavailable'}</td>
        <td
          className={`${friend.activeStatus ? 'text-success' : 'text-danger'}`}
        >
          <strong> {friend.activeStatus ? 'Active' : 'Inactive'}</strong>
        </td>
        <td>
          {isCardTokenized && (
            <div>
              {/* if a friend has an active subscription, the super user can deactivate the account
                  if the friend does not have an active subscription, the super user can activate a subscription or remove a friend
              */}
              {friend.subscriptionStatus ? (
                <Button
                  size="sm"
                  variant="danger"
                  onClick={() => handleShow('deactivate', friend)}
                >
                  Deactivate
                </Button>
              ) : (
                <div>
                  {friend.activeStatus !== null && (
                    <Button
                      size="sm"
                      variant="success"
                      onClick={() => handleShow('activate', friend)}
                      className="me2"
                    >
                      Re-activate
                    </Button>
                  )}

                  <Button
                    size="sm"
                    variant="danger"
                    onClick={() => handleShow('remove', friend)}
                  >
                    Remove
                  </Button>
                </div>
              )}
            </div>
          )}
        </td>
      </tr>
    )
  })

  return (
    <>
      <div className="d-flex flex-wrap mb-4 mt-4 justify-content-between">
        <CustomSearchInput onChange={(e) => setSearchValue(e.target.value)} />
        <CustomButton
          auto
          type="button"
          outline
          onClick={() => history.push('/health-friends/add-friend')}
        >
          Add a friend
        </CustomButton>
      </div>

      {friendState.isLoading ? (
        <div className="text-center mt-4">
          <LoadingIndicator />
        </div>
      ) : (
        <div className="members-table table-responsive">
          <Table striped hover>
            <thead>
              <tr>
                <th>s/n</th>
                <th>Name</th>
                <th>Email</th>
                <th>Enrollee Number</th>
                <th>Status</th>
                <th>Take Action</th>
              </tr>
            </thead>
            <tbody>
              {displayFriends.length === 0 ? (
                <tr>
                  <td colSpan="6" className="text-center">
                    No friends to display
                  </td>
                </tr>
              ) : (
                displayFriends
              )}
            </tbody>
          </Table>
        </div>
      )}
      <div className="d-flex justify-content-end mt-4">
        <PaginationTemplate
          prev={handleGoToPrevPage}
          next={handleGoToNextPage}
          pageDetails={friendState.data}
        />
      </div>

      <ConfirmModal
        isLoading={
          removeFriend.isLoading ||
          deactivateFriend.isLoading ||
          activateFriend.isLoading
        }
        setShow={setShow}
        show={show}
        id={selectedItem}
        action={action}
        removeItem={handleRemoveFriend}
        activateItem={handleActivateFriend}
        deactivateItem={handleDeactivateFriend}
      />
    </>
  )
}

export default FriendsTable
