import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Layout from '../../components/Layout/Layout'
import Login from '../../components/login/Login'

const Authentication = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const handleLogin = (values, { setSubmitting, resetForm }) => {
    history.push('/')

    dispatch({
      type: 'HEALTHBANC_SIGNIN',
      payload: {
        values,
      },
    })
  }

  return (
    <Layout>
      <div className="container mt-3">
        <h4>Customer Login</h4>
        <Login handleLogin={handleLogin} />
      </div>
    </Layout>
  )
}

export default Authentication
