import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: [],
  error: null,
}

const getCartItemsSlice = createSlice({
  name: 'getCartItems',
  initialState,
  reducers: {
    getCartItemsRequested: (state) => {
      state.isLoading = true
      state.data = []
      state.error = null
    },

    getCartItemsSuccessful: (state, action) => {
      state.isLoading = false
      state.data = action.payload
      state.error = null
    },

    getCartItemsFailed: (state, action) => {
      state.isLoading = false
      state.data = []
      state.error = action.payload
    },
  },
})

export const {
  getCartItemsRequested,
  getCartItemsSuccessful,
  getCartItemsFailed,
} = getCartItemsSlice.actions

export default getCartItemsSlice.reducer
