import React, { useEffect } from 'react'

import Navbar from '../LandingPage/Navbar/Navbar'
import Footer from '../Templates/Footer'
import './privacyPolicy.scss'

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  })
  return (
    <>
      <Navbar />
      <div className="container my-5">
        <header className="text-center title">
          <h2>HealthInsured PRIVACY POLICY</h2>
        </header>
        <div className="content px-md-4">
          <p>
            This privacy policy ("Privacy Policy", “Policy”) is to inform you of
            how we collect, use, disclose and store personal information
            (“data”) provided by you when you visit our website- (
            <a href="https://www.HealthInsured.ng">
              https://www.HealthInsured.ng
            </a>
            ) , or use the HealthInsured application (“app”, “application”) and
            the associated services at any time (collectively, our "Services" ).
          </p>

          <p>
            By using the Service, you agree to the collection and use of your
            personal information in accordance with this policy. If you do not
            agree to every provision of this Privacy Policy, you may not access
            or use our Services.
          </p>

          <h4>APPLICATION OF THIS PRIVACY POLICY</h4>
          <p>
            The Privacy Policy applies to users (“you", “your”) of our website,
            applications, and other online services. It applies to your use of
            (regardless of means of access) our Services. You may access or use
            our Services through a desktop, laptop, mobile phone, tablet, or
            other consumer electronic device (each, a "Device").
          </p>

          <h4>INFORMATION WE COLLECT</h4>
          <p>
            We collect different types of information for various purposes to
            provide and improve our Service to you.
          </p>

          <h4>Information You Provide Us</h4>
          <p>
            In general, you can visit{' '}
            <a href="https://www.HealthInsured.ng">
              https://www.HealthInsured.ng
            </a>{' '}
            or the application without telling us who you are or revealing any
            information about yourself. When you submit an inquiry via our
            Services or register for an HealthInsured account, we may collect
            personal information from you, which may include your name, email
            address, mobile phone number, banking information, and other
            information that identifies you (collectively, "
            <u>Personal Information</u>"). By providing your Personal
            Information to us, you expressly agree to our collection, use,
            storage, and disclosure of such information as described in this
            Privacy Policy.
          </p>

          <p>
            We may also ask you to create login information for your
            HealthInsured account, such as a username and password. When you
            provide your mobile phone number/email address, we may ask for your
            consent to receive messages relating to our Services at that
            number/email address.
          </p>

          <h4>Information About Your Transactions</h4>
          <p>
            We collect Personal Information about your transactions with us and
            others. This includes information on any bank accounts you use,
            debit card numbers, financial history, information you provide to
            deliver payment initiation services and account information services
            regarding accounts you hold with other providers.
          </p>

          <h4>Information from Third Parties</h4>
          <p>
            We also collect Personal Information about you from other companies.
            For instance, we may receive Personal Information about you from a
            consumer-reporting agency.
          </p>

          <h4>Information Automatically Collected</h4>
          <p>
            We (or our service providers acting on our behalf) may collect
            information about your use of our Services. This information may
            include Personal Information as well as statistical information that
            does not identify you ("Analytics"). Some Analytics may be
            correlated with Personal Information. When Analytics are, directly
            or indirectly, associated or combined with Personal Information,
            such Analytics will be considered Personal Information for purposes
            of this Privacy Policy.
          </p>

          <p>
            Information that we automatically collect in connection with your
            access or use of our Services may include:
            <br /> <u>Device Information</u>: We may collect Device-specific
            information (such as hardware model, operating system version,
            unique Device identifiers, and mobile network Information, including
            your mobile phone number). We may associate your Device identifiers
            or mobile phone number with your HealthInsured account. <br />{' '}
            <u>Log Information</u>: We may record or log information from your
            Devices, their software, and your activity accessing or using our
            Services. This information may include:
          </p>
          <ul>
            <li>
              The Device's Internet Protocol ("<u>IP</u>") address
            </li>
            <li>Identification numbers associated with your Devices</li>
            <li>
              Device event information, such as crashes, system activity, and
              hardware setting
            </li>
            <li>Location preference</li>
            <li>Date and time stamps of transactions</li>
            <li>System configuration information</li>
            <li>Other interactions with our Services</li>
          </ul>

          <h4>
            Information collected through Cookies and similar Technologies
          </h4>
          <p>
            We use cookies to personalize our Services for you and to collect
            aggregate information about usage of our Services. A cookie is a
            text file or other local storage identifier provided by your browser
            or associated applications. We use cookies for record-keeping
            purposes and to enhance the quality of your use of our Services. The
            cookies assign random, unique numbers to your Devices to enable our
            systems to recognize your Devices and to allow us to see how you use
            our Services. Additional general information about cookies and how
            they work is available at{' '}
            <a href="www.allaboutcookies.org">www.allaboutcookies.org.</a>
          </p>

          <p>The cookies we use in connection with our Services include:</p>

          <p>
            <strong>
              <u>Session cookies</u>
            </strong>
            : Session cookies are temporary cookies that expire and are
            automatically erased whenever you close your browser window. We use
            session cookies to grant users access to content and to enable
            actions they must be logged into their HealthInsured account to
            perform.
          </p>
          <p>
            <strong>
              <u>Persistent cookies</u>
            </strong>
            : Persistent cookies usually have an expiration date in the distant
            future and remain in your browser until they expire or you manually
            delete them. We use persistent cookies to better understand usage
            patterns so we can improve our Services. For example, we may use a
            persistent cookie to associate you with your HealthInsured account
            or to remember your choices for our Services.
          </p>
          <p>
            <strong>
              <u>Third-party cookies</u>
            </strong>
            : We permit certain third parties to place cookies through our
            Services to provide us with better insights into the use of our
            Services and user demographics and to advertise our Services to you.
            These third parties may collect information about your online
            activities over time and across different websites when you access
            or use our Services. For example, we utilize Google Analytics to
            analyze usage patterns for our Services. Google Analytics generates
            a cookie to capture information about your use of our Services,
            which Google uses to compile reports on website activity for us and
            to provide other related services. Google may use a portion of your
            IP address to identify its cookie, but this will not be associated
            with any other data held by Google. We may also permit third-party
            service providers to place cookies for our Services, as indicated
            above, to perform analytic or marketing functions where you are
            notified of them and you have consented to the usage. We do not
            control the use of such third-party cookies or the resulting
            information, and we are not responsible for any actions or policies
            of such third parties.
          </p>

          <p>
            By accessing or using our Services, you consent to the placement of
            cookies on your Devices as described in this Privacy Policy. If you
            prefer not to receive cookies through our Services, you may control
            how your browser responds to cookies by adjusting the privacy and
            security settings of your web browser. Unless you set your browser
            settings to refuse all cookies, our system may issue cookies when
            you access or use our Services. If you set your browser settings to
            refuse all cookies, the performance of certain features of our
            Services may be limited or not work at all.
          </p>

          <h4>Information from social networks or online accounts</h4>
          <p>
            Information from any social media profiles or any accounts that you
            share with us.
          </p>
          <h4>Other information</h4>
          <p>
            We may also process specific categories of information for specific
            and limited purposes, such as detecting and preventing financial
            crime or making our services available to customers. We will only
            treat particular types of information where we have obtained your
            explicit consent or are otherwise lawfully permitted to do so.
          </p>

          <h4>HOW WE USE INFORMATION</h4>
          <p>
            We may Process your Personal Information for a variety of reasons
            including
          </p>
          <ol type="i">
            <li>
              To operate the Application and provide the Services including:
            </li>
            <ul>
              <li>
                communicate with you about your Account, the HealthInsured
                application or the services on the application
              </li>
              <li>
                perform creditworthiness and other financial standing checks,
                evaluate applications, and compare information for accuracy and
                verification purposes,
              </li>
              <li>keep your Account and financial information up to date.</li>
            </ul>
            <li>
              To manage the Services, such as monitoring, analysing, and
              improving the Services and functionality of the App. For example,
              we analyse User behaviour and perform research about the way you
              use our Services.
            </li>
            <li>
              To manage risk and protect the App, the Services and you from
              fraud by verifying your identity using your Data, Device
              Information, Technical Usage Data, and Geolocation Information
              from the App to help detect and prevent fraud, protect your
              information and prevent an abuse of the Services and unsafe
              activities.
            </li>
            <li>
              To market to you about our other products and Services and the
              products and services of affiliated businesses. We may also
              process your Data to tailor the marketing content of certain
              Services to better match your interests.
            </li>
            <li>
              We may use Analytics as described elsewhere in this Policy and for
              research and commercial purposes, such as to improve our Services.
            </li>
            <li>
              We may use Personal Information for the purposes described
              elsewhere in this Policy and internally for our general commercial
              purposes, including, among other things, offer products and
              services of third parties that we think you might find of
              interest, but only HealthInsured and our third-party service
              providers involved in distributing the offers or providing the
              products or services will have access to your Personal
              Information. Our third-party service providers will only be
              permitted to use Personal Information for that intended purpose.
            </li>
            <li>
              We may use your email address to respond to your inquiries and to
              provide you information about our Services. You may elect not to
              receive promotional emails from us either by "unsubscribing" to an
              email you receive from us or by contacting us as indicated below.
              If you unsubscribe from receiving emails from us, we may still
              send you non-promotional emails, such as emails about your
              HealthInsured account or our on-going business relations, unless
              you withdraw your consent to receive electronic communications as
              provided in our Terms of Use.
            </li>

            <li>
              Comply with and enforce applicable legal and regulatory
              requirements, relevant industry standards, contractual obligations
              and our policies.
            </li>

            <li>
              Provide customer care and support and for internal operations,
              including troubleshooting, data analysis, testing, research,
              security, fraud-detection, and account management.
            </li>
          </ol>

          <h4>HOW WE SHARE INFORMATION</h4>
          <p>We do not share your Personal Information with:</p>
          <ol>
            <li>Other financial companies for joint marketing purposes;</li>
            <li>
              Affiliated companies for their everyday business purposes; or
            </li>
            <li>Any third parties so they can market to you.</li>
          </ol>
          <p>
            We may share your Personal Information with unaffiliated third
            parties:
          </p>
          <ol>
            <li>If you request or authorize it;</li>
            <li>
              if the information is provided to help complete a transaction for
              you;
            </li>
            <li>if the information is provided to:</li>
            <ul>
              <li>
                comply with applicable laws, rules, regulations, governmental
                and quasi-governmental requests, court orders, or subpoenas;
              </li>
              <li>Enforce our Terms of Use or other agreements; or</li>
              <li>
                Protect our rights, property, or safety or the rights, property,
                or safety of our users or others (e.g., to a consumer reporting
                agency for fraud protection, etc.);
              </li>
            </ul>
            <li>
              if the disclosure is done as part of a purchase, transfer, or sale
              of services or assets (e.g., in the event that substantially all
              of our assets are acquired by another party, your Personal
              Information may be one of the transferred assets)
            </li>
            <li>
              if the information is provided to our third-party service
              providers to perform functions on our behalf (e.g., analyzing
              data, providing marketing assistance, providing customer service,
              processing orders, etc.);
            </li>
            <li>for our everyday business purposes;</li>
            <li>
              We find that your actions on our web site or application violate
              any part of our Privacy Policy; or
            </li>
            <li>
              as permitted by applicable law or otherwise described in this
              Privacy Policy. When you are no longer our customer, we continue
              to share your information as described in this Privacy Policy.
            </li>
          </ol>

          <p>
            We may disclose Analytics with third parties as described elsewhere
            in this Privacy Policy and for our commercial purposes.
          </p>

          <h4>OPT-OUT RIGHTS</h4>
          <p>
            You may exercise the following control over your personal
            information with us.
          </p>

          <h4>
            Opt-in or opt-out from receiving electronic communications from us
          </h4>
          <p>
            If you do not wish to receive offers or other notices from us in the
            future, you can "opt out" by contacting us as indicated at the end
            of this Privacy Policy or by following the "unsubscribe"
            instructions in any communication you receive from us. Please be
            aware that you are not able to opt out of receiving communications
            about your HealthInsured account or related transactions with us.
          </p>

          <h4>Device permissions</h4>
          <p>
            Depending on the mobile operating system of a phone, most mobile
            device platforms have certain types of device data that applications
            cannot access without the device owner’s permission, and these
            platforms have different methods for how that permission can be
            obtained. Your device may notify you the first time we make a
            request to access certain data on your device. If you do not enable
            these permissions, you may be unable to access some of our services.
            We may require you to enable your location, camera, microphone,
            photo, calendar etc. at the device level.
          </p>

          <h4>HOW LONG WE WILL KEEP YOUR INFORMATION</h4>
          <p>
            We will keep your personal information for as long as we have a
            relationship with you, i.e. for as long as you use our Services, or
            partake in surveys. Once that relationship with you has come to an
            end (e.g. following closure of your account), we will not gather or
            collect any new information about you. Once the relationship is
            over, we will only retain personal information for a period of time.
            This period will vary depending on the purpose for which we hold
            that information and subject to business or legal and regulatory
            requirements.
          </p>

          <h4>ACCESSING YOUR INFORMATION</h4>
          <p>
            You must notify us of any change in your Personal Information by
            updating your HealthInsured account profile through our Services.
            Any changes will affect only future uses of your Personal
            Information.
          </p>

          <p>
            Subject to applicable law, which might, from time to time, oblige us
            to store your Personal Information for a certain period of time, we
            will respect your wishes to correct inaccurate information.
            Otherwise, we will hold your Personal Information for as long as we
            believe it will help us achieve our objectives as detailed in this
            Privacy Policy.
          </p>

          <p>
            You can ask us whether we are storing your Personal Information and
            you can ask to receive a copy of that Personal Information. Before
            sending you any Personal Information, we will ask you to provide
            proof of your identity. If you are not able to provide proof of your
            identity, we reserve the right to refuse to send you any Personal
            Information. We will respond as quickly as we can to your requests
            for details of Personal Information we hold about you.
          </p>

          <h4>INFORMATION YOU SHARE SOCIALLY</h4>
          <p>
            Our Services may allow you to connect and share your actions,
            comments, content, and information publicly or with friends. We are
            not responsible for maintaining the confidentiality of any
            information you share publicly or with friends.
          </p>
          <p>
            Our Services may also allow you to connect with us on, share on, and
            use third-party websites, applications, and services. Please be
            mindful of your personal privacy needs and the privacy needs of
            others, as you choose whom to connect with and what to share and
            make public. We cannot control the privacy or security of
            information you choose to make public or share with others. We also
            do not control the privacy practices of third parties. Please
            contact those sites and services directly if you want to learn about
            their privacy practices.
          </p>

          <h4>SECURITY</h4>
          <p>
            To protect your personal Data we maintain technical, physical, and
            administrative security measures designed to provide reasonable
            protection for your Personal Information against loss, misuse,
            unauthorised access, disclosure, and alteration. We use computer
            safeguard such as firewalls and data encryption, physical access
            controls to our data centres and only authorize access to personal
            information to only employees who require it to fulfill their job
            responsibilities or our affiliates as may be required in the
            provision of our services to you. Although we have taken measures to
            secure and keep your information confidential, because the security
            of your data is important to us, please be aware that no method of
            transmission over the Internet, or method of electronic storage can
            guarantee 100% security at all times. While we strive to use
            commercially acceptable means to protect your Personal Information,
            we cannot guarantee its absolute security and, we are not
            responsible for the matters, which include actions of hackers and
            other unauthorized third parties that breach our reasonable security
            procedure. Accordingly, you are responsible for securing and
            maintaining the privacy of your password and Account/profile
            registration & login information and verifying that the Personal
            Information we maintain about you is valid, accurate and up to date.
            If we receive instructions using your account login information, we
            will consider that you have authorized the instructions and process
            your instruction accordingly and without incurring any liability for
            doing so. You agree to notify us immediately of any unauthorized use
            of your HealthInsured account or any other breach of security. We
            reserve the right, in our sole discretion, to refuse to provide our
            Services, terminate HealthInsured accounts, and to remove or edit
            content.
          </p>

          <h4>PROTECTING CHILDREN'S PRIVACY</h4>
          <p>
            We do not allow children under the age of 18 to open accounts nor
            provide Services for anyone less than 18 years of age without the
            consent of a parent/guardian. We do not knowingly collect Personal
            Information from anyone less than 18 years of age. If you are under
            the age of 18, please do not use our Services or submit any
            information to us. If you are a parent or guardian and you are aware
            that your child has provided us with Personal Data, please contact
            us. If we become aware that we have collected Personal Information
            from children without verification of parental consent, we reserve
            the right to remove that information from our servers.
          </p>

          <h4>LINKS TO THIRD-PARTY WEBSITES</h4>
          <p>
            When you use our Services, you may be directed to other websites
            that are beyond our control. We may also allow third-party websites
            or applications to link to our Services. We are not responsible for
            the privacy practices of any third party or the content of linked
            websites, but we do encourage you to read the applicable privacy
            policies and terms and conditions of such parties and websites. This
            Privacy Policy only applies to our Services.
          </p>

          <h4>CHANGES TO OUR PRIVACY POLICY</h4>
          <p>
            We may revise this Privacy Policy from time to time to reflect
            changes to our business, the App or Services, or applicable laws.
            The revised Privacy Policy will be effective as of the published
            effective date. Accordingly, we encourage periodic reviews of this
            Privacy Policy for awareness of any changes that may have occurred.
          </p>

          <p>
            The revised Privacy Policy will be effective upon posting via our
            Services, unless otherwise set forth therein or as otherwise
            required by applicable law. You are free to decide whether or not to
            accept a revised version of this Privacy Policy, but accepting this
            Privacy Policy, as revised, is required for you to continue
            accessing or using our Services.
          </p>

          <p>
            If you do not agree to the terms of this Privacy Policy or any
            revised version of this Privacy Policy, your sole recourse is to
            terminate your access and use of our Services. Except as otherwise
            expressly stated by us, your access and use of our Services is
            subject to the version of this Privacy Policy in effect at the time
            of access or use.
          </p>

          <h4>HOW CAN I CONTACT HealthInsured?</h4>
          <p>
            If you have any questions, comments, or concerns regarding these
            Terms or the Services, please contact us at +234 700 7837 5464 or{' '}
            <a href="mailto:HealthInsured@sterling.ng">
              HealthInsured@sterling.ng
            </a>
          </p>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default PrivacyPolicy
