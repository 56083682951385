import {
  TOWN_OF_RESIDENCE_REQUEST,
  TOWN_OF_RESIDENCE_SUCCESS,
  TOWN_OF_RESIDENCE_FAIL,
} from '../../actions/healthInsuredTypes/townOfResidence'

export const townOfResidence = (state = { townOfResidence: [] }, action) => {
  switch (action.type) {
    case TOWN_OF_RESIDENCE_REQUEST:
      return { loading: true, townOfResidence: [] }

    case TOWN_OF_RESIDENCE_SUCCESS:
      return { loading: false, townOfResidence: [...action.payload] }

    case TOWN_OF_RESIDENCE_FAIL:
      return { loading: false, error: action.payload, townOfResidence: [] }

    default:
      return state
  }
}
