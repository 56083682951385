import React from 'react'

import { FiCheck } from 'react-icons/fi'
import Layout from '../../components/Layout/Layout'
import ShippingDetailsForm from '../../components/ShippingDetailsForm/ShippingDetailsForm'
import OrderSummary from '../../components/orderSummary/OrderSummary'

import './checkout.scss'

const Checkout = () => {
  return (
    <Layout>
      <div className="checkout container">
        <section className="header ">
          <div className="d-flex align-items-center justify-content-between mt-4">
            <div className="d-sm-flex align-items-center">
              <h3 className="mb-3 mb-sm-0 mesm-5 d-flex align-items-center">
                Checkout
              </h3>
            </div>

            <div className="checkout__progress">
              <div className="checkout__progress__shipping">
                <span className="progress--line"></span>
                <span className="progress--check">
                  <FiCheck />
                </span>
                <div className="progress--text">Shipping</div>
              </div>

              <div className="checkout__progress__payment">
                <span className="progress--line"></span>
                <span className="progress--check">2</span>
                <div className="progress--text">Review & payment</div>
              </div>
            </div>
          </div>
        </section>

        <section className="shipping-details">
          <div className="shipping-details__form-box d-md-flex justify-content-between">
            <div className="col-md-7">
              <h5 className="form__title">Shipping Address</h5>
              <div className="col-md-10 col-lg-7">
                <ShippingDetailsForm />
              </div>
            </div>

            <div className="mt-4 col-md-5">
              <OrderSummary />
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Checkout
