import { takeEvery, put } from 'redux-saga/effects'
import axios from 'axios'
import {
  getCompanyProfileRequest,
  getCompanyProfileSuccess,
  getCompanyProfileFailed,
} from '../../actions/healthInsuredCreators/corporateUserActions/getCompanyProfileActions'

function* getCompanyProfile() {
  try {
    yield put(getCompanyProfileRequest())

    const { data } = yield axios.get(
      '/v2/api/CorporateInsurance/GetCompanyProfileDetails'
    )

    yield put(getCompanyProfileSuccess(data))
  } catch (error) {
    yield put(getCompanyProfileFailed(error.response))
  }
}

export function* watchGetCompanyProfile() {
  yield takeEvery('GET_COMPANY_PROFILE', getCompanyProfile)
}
