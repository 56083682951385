import React from 'react';
import { Line } from 'react-chartjs-2';

const LineChart = ({ signUpMonths }) => {
  const lineRef = React.createRef();

  // get lists of months
  const monthNames = signUpMonths && signUpMonths.map(serviceBreakdown => {
    return serviceBreakdown.name;
  });

  // get number of registred users per year
  const registeredNumber = signUpMonths && signUpMonths.map(serviceBreakdown => {
    return serviceBreakdown.count;
  });


  const data = {
    labels: monthNames,
    datasets: [{
      label: "Analytics",
      data: registeredNumber,
      backgroundColor: ['#6610f2', ' #A630A0', '#EB5757', '#532C60', '#20A4C1'],
      fill: 'No',
      borderColor: "#6610f2"
    }]
  }

  return (
    <Line
      data={data}
      ref={lineRef}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          displayTitle: true,
          position: 'right'
        },
        plugins: {
          datalabels: {
            labels: {
              title: {
                color: 'white'
              }
            }
          }
        }
      }}
    />
  );
};

export default LineChart;
