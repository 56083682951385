import React, { useState } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { Formik, Field } from 'formik'
import * as yup from 'yup'
import { connect } from 'react-redux'
import { Row } from 'react-bootstrap'
import Iframe from 'react-iframe'

import CustomInput from '../../../../../../healthBancComponents/Templates/CustomInput'
import Header from '../../../signup/template/header/Header'
import CustomButton from '../../../signup/template/customButton/CustomeButton'
import { axaUserPinReference } from '../../../../../../redux/healthBanc/actions/actionCreators'

const tokenizeCardUrl = '/v2/api/Tokenization/ChargeCard'
const baseUrl = process.env.REACT_APP_BASE_URL

const PaymentDetails = ({ axaUserPinReference, history }) => {
  const [paystackError, setpayStackError] = useState('')
  const [tokenizationLoading, setTokenizationLoading] = useState(false)
  const [tokenizationError, setTokenizationError] = useState('')
  const [paymentRedirectUrl, setPaymentRedirectUrl] = useState(null)

  const { healthInsuredPlan } = useSelector(
    (state) =>
      state.profileCompletionStatus.data?.data ??
      state.profileCompletionStatus.data ??
      {}
  )

  const cardInfoSchema = yup.object().shape({
    cardNumber: yup.number().required('Required'),
    expiryDate: yup.string().required('Required'),
    cvv: yup.string().required('Required'),
    pin: yup
      .string()
      .min(4, 'Minimum of 4 digits required.')
      .required('Required'),
  })

  const handleSubmitCardInfo = (values) => {
    tokenizeCard(values)
  }

  const tokenizeCard = (values) => {
    const { cardNumber, expiryDate, cvv, pin } = values

    setTokenizationLoading(true)
    setTokenizationError('')

    const month = expiryDate.slice(0, 2)
    const year = expiryDate.slice(3)

    const tokenFormData = {
      card: {
        cvv: cvv,
        expiry_month: Number(month),
        expiry_year: Number(year),
        number: cardNumber.trim(),
        type: '',
      },
      pin: pin,
    }

    axios
      .post(`${baseUrl}${tokenizeCardUrl}`, tokenFormData)
      .then((response) => {
        setTokenizationLoading(false)
        if (response.data.status && response.data.responseCode === 0) {
          // show success page if there is no otp required
          alert(response.data.message)
          history.push('/health_card')
        }

        //redirect to opt page
        if (response.data.status && response.data.responseCode === 12) {
          const { pin, reference } = response.data.data

          const pinReference = { pin, reference }

          axaUserPinReference(pinReference) // dispatch to redux store user pin and reference id

          history.push('/health_tokenization_otp')
        }

        //redirect to paystack payment gateway if need be
        if (response.data.status && response.data.responseCode === 20) {
          const { pin, reference, redirectUrl } = response.data.data
          const pinReference = { pin, reference }

          axaUserPinReference(pinReference) // dispatch to redux store user pin and reference id

          let newWindow = window.open(redirectUrl, '_blank')

          if (
            !newWindow ||
            newWindow.closed ||
            typeof newWindow.closed === 'undefined'
          ) {
            setPaymentRedirectUrl(redirectUrl)
            // alert(
            //   'Please enable pop up and redirect on your browser to complete payment'
            // )
          }
        }
      })
      .catch((error) => {
        setTokenizationLoading(false)

        if (error.response) {
          const { message, Message } = error.response.data
          message
            ? setTokenizationError(message)
            : setTokenizationError(Message)
          setTokenizationError(error.response.data.message)
        } else {
          console.log(error.message)
        }
      })
  }

  window.addEventListener('message', (e) => {
    if (e.origin !== 'https://standard.paystack.co') return
    const { message, status } = JSON.parse(e.data.replace('PaystackAuth', ' '))

    if (status === 'success') {
      setTokenizationLoading(true)
      setTimeout(() => setTokenizationLoading(false), 9900) //delay for 9s to enable the backend sync the user data
      setTimeout(
        () =>
          history.push(
            healthInsuredPlan === 1 ? '/health-' : '/health_profile'
          ),
        10000
      )
    } else {
      message && setpayStackError(message)
    }
  })

  return (
    <div className="axa-mansard-sign-up-card-tokenization">
      <Header />

      <div className="form-container">
        {paymentRedirectUrl && (
          <div className="bg-white">
            <Iframe
              url={paymentRedirectUrl}
              width="450px"
              height="450px"
              id="myId"
              className="myClassname"
              display="initial"
              position="absolute"
            />
          </div>
        )}
        <Row className="personal-details-form">
          <Formik
            initialValues={{
              cardNumber: '',
              expiryDate: '',
              cvv: '',
              pin: '',
            }}
            validationSchema={cardInfoSchema}
            onSubmit={(values, { isSubmitting }) => {
              handleSubmitCardInfo(values, { isSubmitting })
            }}
          >
            {(props) => (
              // <form className="personal-form" onSubmit={tokenizeCard}>
              <form className="personal-form" onSubmit={props.handleSubmit}>
                <h1 className="start-your">Add your debit card</h1>
                <p className="provide-some mb-4">
                  Provide your card information
                </p>

                <Field
                  as={CustomInput}
                  type="tel"
                  label="Card Number"
                  format="#####################"
                  name="cardNumber"
                />

                <Field
                  as={CustomInput}
                  type="password"
                  maxLength="4"
                  label="Card Pin"
                  format="####"
                  name="pin"
                />

                <div className=" justify-content-between">
                  <Field
                    as={CustomInput}
                    name="expiryDate"
                    type="tel"
                    label="Expiry Date"
                    format="##/##"
                    placeholder="MM/YY"
                    mask={['M', 'M', 'Y', 'Y']}
                  />

                  <Field
                    as={CustomInput}
                    type="tel"
                    name="cvv"
                    label="CVV"
                    maxLength="3"
                  />
                </div>

                {tokenizationError && (
                  <menu
                    className={
                      tokenizationError
                        ? 'error-message error-message-show text-danger m-0 p-0'
                        : 'error-message p-0 m-0'
                    }
                  >
                    <small>*{tokenizationError}</small>
                  </menu>
                )}

                {paystackError && (
                  <menu
                    className={
                      paystackError
                        ? 'error-message error-message-show text-danger m-0 p-0'
                        : 'error-message p-0 m-0'
                    }
                  >
                    <small>*{paystackError}</small>
                  </menu>
                )}
                <CustomButton
                  buttonText={tokenizationLoading ? 'Processing...' : 'Proceed'}
                  disable={tokenizationLoading ? true : false}
                />
              </form>
            )}
          </Formik>
        </Row>
      </div>
    </div>
  )
}

export default connect(null, { axaUserPinReference })(PaymentDetails)
