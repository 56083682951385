import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactHTMLTableToExcel from 'react-html-table-to-excel'

import { useHistory } from 'react-router'
import {
  Row,
  Col,
  Form,
  InputGroup,
  FormControl,
  Dropdown,
  Button,
} from 'react-bootstrap'
import { FiCodepen, FiSearch, FiDownload, FiCalendar } from 'react-icons/fi'
import HealthInsuredDashboardStructure from '../../HealthInsuredDashboardStructure/HealthInsuredDashboardStructure'
import AdminTransactionTable from './adminTransactionTable/AdminTransactionTable'
import HealthInsuredPagination from '../../../HealthInsuredCorporateFlow/components/healthInsuredPagination/HealthInsuredPagination'

import './healthInsuredAdminTransactionLog.scss'

const TransactionLog = ({ history }) => {
  const [filter, setFilter] = useState({
    searchText: '',
    pageNumber: 1,
  })

  const dispatch = useDispatch()

  const transactionLog = useSelector(
    (state) => state.getHealthInsuredAdminTransactionLog
  )

  useEffect(() => {
    dispatch({
      type: 'GET_HEALTHINSURED_ADMIN_TRANSACTION_LOG',
      payload: { ...filter },
    })
  }, [dispatch, filter])

  const handleFilterByStatus = (e) => {
    const value = Number(e.target.value)
    dispatch({
      type: 'GET_HEALTHINSURED_ADMIN_TRANSACTION_LOG',
      payload: { ...filter, status: value },
    })
  }

  const goToPrevPage = () => {
    if (transactionLog.hasOwnProperty('data')) {
      const { data } = transactionLog.data

      if (data.pageNumber === 1) {
        return
      } else {
        setFilter({ ...filter, pageNumber: filter.pageNumber - 1 })
      }
    }
  }

  const goToNextPage = () => {
    if (transactionLog.hasOwnProperty('data')) {
      const { data } = transactionLog.data

      if (data.pageNumber < data.pageCount) {
        return
      } else {
        setFilter({ ...filter, pageNumber: filter.pageNumber + 1 })
      }
    }
  }

  return (
    <HealthInsuredDashboardStructure title="Transaction Log">
      <div className="healthInsuredAdminLogSearchAndFilter">
        <LogSearchAndFilter
          filter={filter}
          setFilter={setFilter}
          tableName="healthInsured_admin_transaction_log"
          handleFilterByStatus={handleFilterByStatus}
        />
        <div>
          <AdminTransactionTable transactionLog={transactionLog} />
        </div>

        <div className="d-flex justify-content-end mt-4">
          <HealthInsuredPagination
            next={goToNextPage}
            prev={goToPrevPage}
            pageDetails={
              transactionLog.hasOwnProperty('data') && transactionLog.data
            }
          />
        </div>
      </div>
    </HealthInsuredDashboardStructure>
  )
}

export const LogSearchAndFilter = ({
  filter,
  setFilter,
  tableName,
  handleFilterByStatus,
  handleFilterByDate,
}) => {
  const history = useHistory()

  return (
    <>
      <Row className="mb-4">
        {history.location.pathname !== '/admin/health_insured/error-log' ? (
          <Col xs="auto">
            <Form.Label htmlFor="inlineFormInputGroup" srOnly>
              Search
            </Form.Label>
            <InputGroup className="mb-2">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <FiSearch />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                id="inlineFormInputGroup"
                placeholder="Search for users"
                value={filter.searchText}
                onChange={(e) =>
                  setFilter({ ...filter, searchText: e.target.value })
                }
              />
            </InputGroup>
          </Col>
        ) : (
          <Col xs="auto">
            <InputGroup className="mb-2">
              {/* <InputGroup.Prepend>
              <InputGroup.Text>
                <FiCalendar />
              </InputGroup.Text>
            </InputGroup.Prepend> */}
              <FormControl type="date" onChange={handleFilterByDate} />
            </InputGroup>
          </Col>
        )}

        {/* If rendered by the transaction log screen, show. */}
        {history.location.pathname ===
          '/admin/health_insured/transaction-log' && (
          <Col>
            <select className="form-control" onChange={handleFilterByStatus}>
              <option value="">All</option>
              <option value="1">Successful</option>
              <option value="2">Pending</option>
              <option value="3">Failed</option>
            </select>
          </Col>
        )}

        <Col className="d-flex justify-content-end">
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button btn btn-outline-secondary"
            table={tableName}
            filename={tableName}
            sheet="log_sheet"
            buttonText="Export as Excel"
          />
          {/* <Button variant="outline-secondary">
            {' '}
            <FiDownload /> Export
          </Button> */}
        </Col>
      </Row>
    </>
  )
}

export default TransactionLog
