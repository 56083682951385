import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: {},
  errors: [],
}

const activateFamilyMemberSlice = createSlice({
  name: 'ActivateFamilyMember',
  initialState,
  reducers: {
    ACTIVATE_FAMILY_MEMBER_REQUESTED: (state) => {
      return { isLoading: true, data: {}, errors: [] }
    },

    ACTIVATE_FAMILY_MEMBER_SUCCESSFUL: (state, action) => {
      return { isLoading: false, data: { ...action.payload }, errors: [] }
    },

    ACTIVATE_FAMILY_MEMBER_FAILED: (state, action) => {
      return { isLoading: false, data: {}, errors: [action.payload] }
    },
  },
})

export const {
  ACTIVATE_FAMILY_MEMBER_SUCCESSFUL,
  ACTIVATE_FAMILY_MEMBER_REQUESTED,
  ACTIVATE_FAMILY_MEMBER_FAILED,
} = activateFamilyMemberSlice.actions

export default activateFamilyMemberSlice.reducer
