import React from 'react';
import { FcCalendar } from 'react-icons/fc'


const CustomDateInput = ({
  labelName,
  inputValue,
  inputType,
  handleChange,
  inputName,
  errorMessage
}) => {

  return (
    <div className='d-flex flex-column mb-2 axa-mansard-signup-date-group'>
      <span className='display-content mb-1' >{labelName}</span>
      <label className='mb-1 px-2 py-1 input-label' htmlFor={inputValue}>
        {
          inputValue ? inputValue :
            <span className='d-flex justify-content-between align-items-center'>
              <p className='m-0 upload-document '>YYYY/MM/DD</p>
              <i className='upload-icon'><FcCalendar className='icon' /></i>
            </span>
        }
        <input
          id={inputValue}
          type={inputType}
          value={inputValue}
          onChange={(e) => handleChange(e)}
          name={inputName}
          className='input-field px-2 py-1'
        />
      </label>
      {errorMessage && <p className='text-danger text-justify d-block m-0 w-100'><small>*{errorMessage}</small></p>}
    </div>
  );
};

export default CustomDateInput;
