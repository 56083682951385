import {
  GET_CORPORATE_ACTIVITY_LOG_FAILED,
  GET_CORPORATE_ACTIVITY_LOG_REQUEST,
  GET_CORPORATE_ACTIVITY_LOG_SUCCESS,
} from '../../healthInsuredTypes/corporateActionTypes'

export const getCorporateActivityLogRequest = () => ({
  type: GET_CORPORATE_ACTIVITY_LOG_REQUEST,
})

export const getCorporateActivityLogSuccess = (payload) => ({
  type: GET_CORPORATE_ACTIVITY_LOG_SUCCESS,
  payload,
})
export const getCorporateActivityLogFailed = (errorMessage) => ({
  type: GET_CORPORATE_ACTIVITY_LOG_FAILED,
  payload: errorMessage,
})
