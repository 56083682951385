import React from 'react';

const StatsCard = ({
  img,
  alt,
  title,
  amount,
}) => {

  return (
    <article className='health__insured__admin__providers__stats__card'>
      <div className='health__insured__admin__providers__stats__card--container'>

        <i className='health__insured__admin__providers__stats__card--container-img'>
          <img src={img} alt={alt} />
        </i>

        <div>
          <h2 className='health__insured__admin__providers__stats__card--title'>{title}</h2>
          <p className='health__insured__admin__providers__stats__card--amount'>{amount}</p>
        </div>
      </div>
    </article>
  )
}

export default StatsCard
