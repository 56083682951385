import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: {},
  error: null,
}

const getDrugCategorySlice = createSlice({
  name: 'getDrugCategory',
  initialState,
  reducers: {
    getDrugCategoryRequested: (state) => {
      state.isLoading = true
      state.data = {}
      state.error = null
    },

    getDrugCategorySuccessful: (state, action) => {
      state.isLoading = false
      state.data = action.payload
      state.error = null
    },

    getDrugCategoryFailed: (state, action) => {
      state.isLoading = false
      state.data = {}
      state.error = action.payload
    },
  },
})

export const {
  getDrugCategoryRequested,
  getDrugCategorySuccessful,
  getDrugCategoryFailed,
} = getDrugCategorySlice.actions
export default getDrugCategorySlice.reducer
