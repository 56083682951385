import React from 'react'
import { Table } from 'react-bootstrap'
import { Modal, Button } from 'react-bootstrap'

const ErrorLogModal = ({ show, handleShow, handleClose, errorDetails }) => {
  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Error Details</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table responsive>
            <tbody>
              <tr>
                <td>Error Code</td>
                <td className="text-bold">{errorDetails.errorCode}</td>
              </tr>
              <tr>
                <td>Source</td>
                <td>{errorDetails.source} </td>
              </tr>
              <tr>
                <td>Message</td>
                <td>{errorDetails.errorMessage}</td>
              </tr>
              <tr>
                <td>Stack trace</td>
                <td className="text-wrap">{errorDetails.stackTrace}</td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer className="mx-auto">
          <Button variant="outline-danger" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default ErrorLogModal
