import React, { useState, useEffect } from 'react';
import axios from 'axios';


export const useFetchdata = (endPoint) => {

  const [url, setUrl] = useState(endPoint);
  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [token] = useState(localStorage.getItem("adminInfo"));

  useEffect(() => {
    
    (async () => {
      try {
        setLoading(true);
        
        const { data } = await axios.post(url, null, { headers: { Authorization: `Bearer ${token}` } });
        setUsers(data.data);
        setLoading(false);

      } catch (error) {
        setLoading(false);
        error.response ? setError(error.response.data.message) : setError(error.message);
      }
    })();

  }, [url]);

  return [{ loading, error, users, setError }, setUrl];
};