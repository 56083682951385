import React, { useEffect } from 'react'
import Navbar from '../LandingPage/Navbar/Navbar'

import Partners from '../LandingPage/Partners/Partners'
import JoinCommunity from '../LandingPage/JoinCommunity/JoinCommunity'
import Footer from '../Templates/Footer'
import aboutImg1 from './Assets/aboutImg1.svg'
import aboutImg2 from './Assets/aboutImg2.svg'
import './aboutUs.scss'

const TermsOfUse = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  })
  return (
    <div className="about-us">
      <header>
        <Navbar />
        <div className="header-content container mx-auto">
          <h3>WE ARE ON A MISSION TO DEMOCRATIZE HEALTH FOR ALL. </h3>
          <br />
          <p>
            All over the world, the reality of healthcare delivery is no more
            business as usual and we are leading a revolution to create better
            experiences for all players in the industry despite the disruptions.{' '}
          </p>
        </div>
      </header>

      <div className="container my-5 d-flex flex-wrap">
        <div className="col-sm-6 mb-5">
          <h4>About Us</h4>
          <p>
            HealthInsured is an online platform that uses technology, advocacy,
            and finance to solve the problem of inefficient service delivery
            within the health sector.{' '}
          </p>

          <div>
            <img src={aboutImg1} className="img-fluid" alt="" />
          </div>
        </div>

        <div className="col-sm-6 mb-5">
          <p>
            By building intelligent tools and amazing products, we are focused
            on creating innovative and sustainable solutions to make medical and
            pharmaceutical services accessible to everyone in the health
            ecosystem; from providers, manufacturers, retailers and distributors
            to customers and governments.{' '}
          </p>
          <div>
            <img src={aboutImg2} className="img-fluid" alt="" />
          </div>
        </div>

        <div className="col-sm-6">
          <p>
            Partnering with leading healthcare providers and vendors like
            Drugstoc, Helium Health, Axa Mansard and Hygeia; we are on a
            decisive mission to forever change health experiences across the
            whole value-chain.{' '}
          </p>
        </div>

        <br />
        {/* <p>
					Join our community and be a part of something that is truly
					revolutionary and empowering.{' '}
				</p> */}
      </div>
      <Partners />
      <JoinCommunity />
      <Footer />
    </div>
  )
}

export default TermsOfUse
