import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { FcCheckmark } from 'react-icons/fc'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

// import MasterCard from '../../../assets/mastercard.png';
import Loading from '../../../../../pharmHub/admin/components/Spinner/Spinner'
import { ReactComponent as CreditCard } from '../../../assets/creditcard.svg'
import { ReactComponent as MasterCard } from '../../../assets/mc.svg'
import { ReactComponent as VisaCard } from '../../../assets/visa.svg'
import { ReactComponent as VerveCard } from '../../../assets/verve.svg'

const SubscriptionSection = ({
  handleShowCardModal,
  cards,
  handleShowCancelModal,
  loading,
}) => {
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth)
  const subscriptionStatus = useSelector(
    (state) =>
      state.healthInsuredIndividualProfile.healthInsuredIndividualProfile
        ?.subscriptionStatus
  )
  const activeStatus = useSelector(
    (state) =>
      state.healthInsuredIndividualProfile.healthInsuredIndividualProfile
        ?.activeStatus
  )
  const profile = useSelector(
    (state) =>
      state.healthInsuredIndividualProfile.healthInsuredIndividualProfile
  )

  const histroy = useHistory()

  const { healthInsuredPlan } = useSelector(
    (state) =>
      state.profileCompletionStatus.data?.data ??
      state.profileCompletionStatus.data ??
      null
  )

  useEffect(() => {
    setDeviceWidth(window.innerWidth)
    window.scrollTo(0, 0)
  }, [])

  /**show loading spinner on network request */
  if (loading) return <Loading />

  /**get last four digit of primary card */
  const lastFour = cards
    .filter((card) => {
      return card.status === 1
    })
    .map((card) => card.lastFourDigit)

  /**get card type */
  const cardType = cards
    .filter((card) => {
      return card.status === 1
    })
    .map((card) => card.type)

  /**take a user to card reactivation page */
  const goto = () => {
    if (!subscriptionStatus) {
      histroy.push('/health_reactivation')
    }
  }

  return (
    <div>
      <Card className="axa-mansard-dashboard-subscription">
        <Card.Body className="subscription">
          {/* Display personal subscription info if choosen plan IS NOT family plan(1) */}
          {healthInsuredPlan !== 1 && (
            <section className="mb-4 subscription-container">
              <h2 className="subscription-plan">Subscription plan</h2>
              {subscriptionStatus ? (
                <div className="subscription-plan-container">
                  <div className="d-lg-flex justify-content-lg-between">
                    <div>
                      <p className="plan-type mb-xs-2">
                        {profile.planCode === '7' ? 'Ruby' : 'Sapphire'}
                      </p>
                    </div>

                    <p className="amount">
                      ₦{profile.planCode === '7' ? '1000' : '2000'}/
                      <small className="month">month</small>
                    </p>
                    <p className={activeStatus ? 'plan-status' : 'inactive'}>
                      {activeStatus ? 'Active' : 'Paused'}
                    </p>
                  </div>
                  <div className="d-lg-flex justify-content-lg-between">
                    <ul>
                      <li className="d-flex">
                        <FcCheckmark className="icon d-block" />
                        <span className="list-text">
                          Inpatient benefit of N200,000 per annum
                        </span>
                      </li>
                      <li className="d-flex">
                        <FcCheckmark className="icon d-block" />
                        <span className="list-text">
                          Covers accidents & emergencies
                        </span>
                      </li>
                      <li className="d-flex">
                        <FcCheckmark className="icon d-block" />
                        <span className="list-text">
                          Surgeries (up to N70,000)
                        </span>
                      </li>
                      {profile.planCode === '8' && (
                        <li className="d-flex">
                          <FcCheckmark className="icon d-block" />
                          <span className="list-text">
                            Physiotherapy sessions
                          </span>
                        </li>
                      )}
                      {subscriptionStatus ? (
                        <button
                          className="cancel-subscription-desktop"
                          onClick={handleShowCancelModal}
                        >
                          Cancel subscription
                        </button>
                      ) : null}
                    </ul>

                    <ul>
                      {profile.planCode === '8' && (
                        <li className="d-flex">
                          <FcCheckmark className="icon d-block" />
                          <span className="list-text">
                            Mother and child care, and more.
                          </span>
                        </li>
                      )}
                      <li className="d-flex">
                        <FcCheckmark className="icon d-block" />
                        <span className="list-text">
                          Family planning and IUCDs
                        </span>
                      </li>
                      <li className="d-flex">
                        <FcCheckmark className="icon d-block" />
                        <span className="list-text">
                          Immunisations, and more.
                        </span>
                      </li>
                      {profile.planCode === '8' && (
                        <li className="d-flex">
                          <FcCheckmark className="icon d-block" />
                          <span className="list-text">Eye care</span>
                        </li>
                      )}
                      {subscriptionStatus ? (
                        <p
                          className="cancel-subscription-mobile"
                          onClick={handleShowCancelModal}
                        >
                          Cancel subscription
                        </p>
                      ) : null}
                    </ul>
                  </div>
                </div>
              ) : (
                <div className="no-subscription-container">
                  <h2 className="title">No subscription found</h2>
                  <p className="info">
                    You currently do not have an active subscription,{' '}
                    {deviceWidth > 992 ? <br /> : null}
                    subscribe to a plan by clicking the button below
                  </p>
                  <button className="subscribe" onClick={() => goto()}>
                    subscribe for a plan
                  </button>
                </div>
              )}
            </section>
          )}

          <section className="payment-container">
            <h2 className="payment-method">Payment method</h2>

            <div className="payment-method-container">
              <div className="payment-method-wrapper">
                <div className="icon-wrapper">
                  <CreditCard />
                </div>
                <div>
                  <h2 className="debit-card">Debit card</h2>
                  <div className="last-digit-wrapper">
                    <p className="last-digit">
                      {Object.values(lastFour).length !== 0
                        ? `• • • •${lastFour}`
                        : 'N/A'}
                    </p>
                    {Object.values(lastFour).length !== 0 &&
                    cardType[0].trim() === 'verve' ? (
                      <VerveCard className="verve-card-logo" />
                    ) : Object.values(lastFour).length !== 0 &&
                      cardType[0].trim() === 'visa' ? (
                      <VisaCard className="visa-card-logo" />
                    ) : Object.values(lastFour).length !== 0 &&
                      cardType[0].trim() === 'master' ? (
                      <MasterCard className="master-card-logo" />
                    ) : null}
                  </div>
                </div>
              </div>
              <button className="change-card" onClick={handleShowCardModal}>
                Change card
              </button>
            </div>
          </section>
        </Card.Body>
      </Card>
    </div>
  )
}

export default SubscriptionSection
