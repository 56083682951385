import React,{useState} from 'react'
import NavBar from '../navbar/NavBar'
import SectionWallet from './SectionWallet'
import Alert from '../../../../../healthBancServices/healthInsured/template/alert/ALert'
import Header from '../header/Header'
function WalletMain() {
    const [loading, setLoading] = useState()
  return (
    <div>
        <Header />
            <main className="axa-mansard-dashboard-mainsection px-3 pb-4">

            <NavBar />
            {/* {error && (
        <Alert variant="danger" showAlert={true}>
                {' '}
                {error}{' '}
        </Alert>
            )} */}
            <SectionWallet loading={loading}/>
  </main>
    </div>

  )
}

export default WalletMain