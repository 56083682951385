import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'

const NavBar = () => {
  const profileStatus = useSelector(
    (state) => state.profileCompletionStatus.data
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: 'PROFILE_COMPLETION_STATUS' })
  }, [dispatch])

  // const choosenPlan = profileStatus?.healthInsuredPlan ?? null

  const location = useLocation()
  const path = location.pathname

  const displayFamilyOrFriendsTab = () => {
    // if (profileStatus?.healthInsuredPlan === 0 && !profileStatus.referee) {
    if (profileStatus?.healthInsuredPlan === 0) {
      return (
        <li className={path === '/health-friends' ? 'current-page' : ''}>
          <NavLink to="/health-friends">Friends</NavLink>
        </li>
      )
    }

    if (profileStatus?.healthInsuredPlan === 1) {
      return (
        <li
          className={
            path === '/health-family' || path === '/health-family/:name'
              ? 'current-page'
              : ''
          }
        >
          {/* {path === '/health-family' ? ( */}
          <NavLink to="/health-family">Pay for Others</NavLink>
          {/* ) : (
            <NavLink to="/health-friend">Friends</NavLink>
          )} */}
        </li>
      )
    }

    return null
  }

  return (
    <nav className="axa-mansard-dashboard-nav">
      <ul>
        {/* Check if user choosen plan === family plan, dont show personal and care provider tabs  */}
        {profileStatus?.healthInsuredPlan !== 1 && [
          <li className={path === '/health_profile' ? 'current-page' : ''}>
            <NavLink to="/health_profile">Personal Info</NavLink>
          </li>,

          <li
            className={
              path === '/health_care_provider'
                ? 'current-page'
                : path === '/health_care_providers'
                ? 'current-page'
                : ''
            }
          >
            <NavLink to="/health_care_provider">Care Provider</NavLink>
          </li>,
        ]}

        <li className={path === '/health_subscription' ? 'current-page' : ''}>
          <NavLink to="/health_subscription">
            Subscriptions and Payments
          </NavLink>
        </li>

        <li className={path === '/health_card' ? 'current-page' : ''}>
          <NavLink to="/health_card">Cards</NavLink>
        </li>

        {/* <li className={path === '/health_section_wallet' ? 'current-page' : ''}>
          <NavLink to="/health_section_wallet">Wallet</NavLink>
        </li> */}

        {displayFamilyOrFriendsTab()}
      </ul>
    </nav>
  )
}

export default NavBar
