import {
  GET_HEALTHINSURED_ADMIN_TRANSACTION_LOGS_FAILED,
  GET_HEALTHINSURED_ADMIN_TRANSACTION_LOGS_REQUEST,
  GET_HEALTHINSURED_ADMIN_TRANSACTION_LOGS_SUCCESS,
} from '../../actions/healthInsuredTypes/healthInsuredAdminActionTypes'

export const getHealthInsuredAdminTransactionLogReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case GET_HEALTHINSURED_ADMIN_TRANSACTION_LOGS_REQUEST:
      return { isLoading: true }

    case GET_HEALTHINSURED_ADMIN_TRANSACTION_LOGS_SUCCESS:
      return { isLoading: false, ...action.payload }

    case GET_HEALTHINSURED_ADMIN_TRANSACTION_LOGS_FAILED:
      return { isLoading: false, ...action.payload }

    default:
      return state
  }
}
