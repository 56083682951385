import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: {},
  error: [],
}

const addFamilyMemberSlice = createSlice({
  name: 'AddFamilyMember',
  initialState,
  reducers: {
    addFamilyMemberRequested: (state) => {
      return { isLoading: true, data: {}, error: [] }
    },

    addFamilyMemberSuccessful: (state, action) => {
      return { isLoading: false, data: { ...action.payload }, error: [] }
    },

    addFamilyMemberFailed: (state, action) => {
      return { isLoading: false, data: {}, error: [...action.payload] }
    },
  },
})

export const {
  addFamilyMemberRequested,
  addFamilyMemberSuccessful,
  addFamilyMemberFailed,
} = addFamilyMemberSlice.actions

export default addFamilyMemberSlice.reducer
