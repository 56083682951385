import { takeLatest, put } from 'redux-saga/effects'
import axios from 'axios'
import { store } from '../../../../store'

import {
  updateCartItemQuantityRequested,
  updateCartItemQuantitySuccessful,
  updateCartItemQuantityFailed,
  getCartItemsRequested,
} from '../../../reducer/oneDrugStore/user/cartItemsSlices'

function* updateCartItemQuantity({ payload }) {
  const isAuthUser = store.getState().user.userInfo.isAuth

  try {
    yield updateCartItemQuantityRequested()
    if (isAuthUser) {
      const { data } = yield axios.put(
        `${process.env.REACT_APP_ONE_DRUG_BASE}/v1/api/Cart/UpdateCartQuantity?quantity=${payload.quantity}&cartId=${payload.id}`
      )
      yield put(updateCartItemQuantitySuccessful(data))
    } else {
      const cartList = JSON.parse(window.localStorage.getItem('oneDrugCart'))

      const updatedCartList = cartList.map((item) =>
        item.drugItemId === payload.details.id
          ? {
              ...item,
              drugItemId: payload.details.id,
              price: payload.details.price,
              quantity:
                payload.action === 'increase'
                  ? payload.details.quantity + 1
                  : payload.details.quantity - 1,
            }
          : item
      )

      window.localStorage.setItem(
        'oneDrugCart',
        JSON.stringify(updatedCartList)
      )
      yield put(updateCartItemQuantitySuccessful({}))
    }
    yield put(getCartItemsRequested())
  } catch (error) {
    if (error.response) {
      yield put(updateCartItemQuantityFailed(error.response.data))
      yield put(updateCartItemQuantityFailed(null))
      return
    }

    yield put(updateCartItemQuantityFailed(error.message))
    yield put(updateCartItemQuantityFailed(null))
  }
}

export function* watchUpdateCartItemQuantity() {
  yield takeLatest(updateCartItemQuantityRequested.type, updateCartItemQuantity)
}
