import { ADD_TO_CART_ASYNC, GET_CART_ITEMS_ASYNC, DELETE_FROM_CART_ASYNC, UPDATE_CART_ITEM_QTY_ASYNC } from "../actions/actionTypes"

const INITIAL_STATE = {
    cartItems : {
        data: []
    }
}

export const cartReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case GET_CART_ITEMS_ASYNC:
            return {...state, cartItems: action.payload }

        case ADD_TO_CART_ASYNC:
            return {...state, cartItems: {...action.payload}}

        case UPDATE_CART_ITEM_QTY_ASYNC:
            return {...state, cartItems: {...action.payload}}

        // case DELETE_FROM_CART_ASYNC:
        //     const updatedCart = state.cart.filter( item => item.id !== action.payload)
        //     return {...state, cartItems: updatedCart}

        default:
            return state
    }
}