import { put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { healthBancSigninAsync } from '../actions/actionCreators'
import {
  signupRequest,
  signupSuccess,
  signupFailed,
} from '../actions/signupActionCreators'

function* signup({ payload }) {
  const {
    values: {
      firstName,
      lastName,
      email,
      phoneNumber,
      password,
      confirmPassword,
    },
    setSubmitting,
    resetForm,
    history,
  } = payload

  const currentPathname = history.location.pathname

  const SOCIAL_MEDIA_SIGNIN_API =
    '/v2/api/Identity/SocialMediaRegistrationLink?app=HealthInsured'
  const REGULAR_SIGNUP_API = '/v2/api/Identity/RegisterUser?app=HealthInsured'

  const isSocialMediaSignup = currentPathname === '/health-insurance'

  yield put(signupRequest())

  try {
    const response = yield axios({
      method: 'post',
      url: isSocialMediaSignup ? SOCIAL_MEDIA_SIGNIN_API : REGULAR_SIGNUP_API,
      data: {
        firstName,
        lastName,
        emailAddress: email,
        phoneNumber,
        password,
        confirmPassword,
      },
    }).then((response) => response.data.data)

    if (isSocialMediaSignup) {
      yield put(healthBancSigninAsync(response))

      history.push({
        pathname: '/health_corporate/select_type',
        state: { fromSocials: true },
      })
    } else {
      history.push('/verify-email')
    }

    yield put(signupSuccess(response))

    resetForm()
  } catch (error) {
    yield put(signupFailed(error.response))

    if (error.response) {
      alert(error.response.data.message)
    } else if (error.request) {
      alert('Response error! \nPlease try again or contact support.')
    } else {
      alert('An Error Occured! \n \nPlease try again or contact support.')
    }
  } finally {
    setSubmitting(false)
  }
}

// Watch for signin dispatch action
export function* watchSignup() {
  yield takeEvery('SIGNUP', signup)
}
