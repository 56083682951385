import React from 'react'
import Layout from '../../../components/adminComponents/Layout/Layout'
import Login from '../../../components/login/Login'

const AdminLogin = () => {
  const handleLogin = async (values, { setSubmitting, resetForm }) => {
    const afterSubmitAction = { resetForm, setSubmitting }

    // history.push('/')

    // await dispatch({
    //   type: 'HEALTHBANC_SIGNIN',
    //   payload: {
    //     values,
    //     afterSubmitAction,
    //   },
    // })
  }

  const signupBenefits = (
    <ul>
      <li>Monitor your stock items</li>
      <li>Keep tracks of orders</li>
      <li>Upload and remove items on the store</li>
    </ul>
  )
  return (
    <Layout>
      <div className="container">
        <h5 className="font-weight-bold mt-4">Admin Login</h5>
        <div>
          <Login
            handleLogin={handleLogin}
            loginTitle="Admin"
            loginDesc="You are about to login as an admin"
            signupTitle="Admin/ Aggregator?"
            signupDesc="Creating an account has many benefits"
            signupBenefits={signupBenefits}
          />
        </div>
      </div>
    </Layout>
  )
}

export default AdminLogin
