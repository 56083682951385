import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Layout from '../../../components/adminComponents/Layout/Layout'
import StatsCard from '../../../components/adminComponents/statsCard/StatsCard'
import CustomButton from '../../../../../healthBancComponents/Templates/CustomButton'
import { getAllPharmacyRequested } from '../../../../../redux/healthBanc/reducer/oneDrugStore/admin/pharmacySlices'

const Dashboard = () => {
  const history = useHistory()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllPharmacyRequested())
  }, [dispatch])

  return (
    <Layout>
      <div className={`container p-5`}>
        <h5>Welcome Admin</h5>

        <div className="col-4 mt-5">
          <CustomButton
            onClick={() => history.push('/admin/OneDrugStore/aggregators')}
          >
            Manage Aggregator
          </CustomButton>
        </div>

        {/* <div className={`mt-5`}>
          <StatsCard figure="125" label="Products Sold" icon="" />
          <StatsCard figure="250" label="Total Users" icon="" />
          <StatsCard figure="N25,000" label="Total Revenue" icon="" />
          <StatsCard figure="N25,500" label="Total payout due" icon="" />
          <StatsCard figure="50" label="Wallet Subscription" icon="" />
        </div> */}
      </div>
    </Layout>
  )
}

export default Dashboard
