import React from 'react'
import { Toast } from 'react-bootstrap'
import { IoMdCheckmark } from 'react-icons/io'

const ResponseToast = ({ toastShow, handleToastClose, toastError }) => {
  return (
    <div className="response-toast">
      <Toast
        show={toastShow}
        onClose={handleToastClose}
        style={{
          position: 'absolute',
          top: 10,
          width: '90%',
        }}
      >
        <Toast.Header className="d-flex justify-content-between align-items-center py-2">
          <div className="d-flex align-items-center">
            <i
              className={
                toastError
                  ? 'icon-wrapper bg-danger me3 d-flex justify-content-center align-items-center'
                  : 'icon-wrapper bg-success me3 d-flex justify-content-center align-items-center'
              }
            >
              <IoMdCheckmark />
            </i>
            <div>
              <h1 className="success">{toastError ? 'Failure' : 'Success!'}</h1>
              <p className="response">
                {toastError ? toastError : 'Your invitations are on the way.'}
              </p>
            </div>
          </div>
        </Toast.Header>
      </Toast>
    </div>
  )
}

export default ResponseToast
