import { takeLatest, put } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import axios from 'axios'
import { store } from '../../../../store'
import {
  deleteCartItemRequested,
  deleteCartItemSuccessful,
  deleteCartItemFailed,
  getCartItemsRequested,
} from '../../../reducer/oneDrugStore/user/cartItemsSlices'

function* deleteCartItemAsync({ payload }) {
  const isAuthUser = store.getState().user.userInfo.isAuth

  yield deleteCartItemRequested()
  try {
    if (isAuthUser) {
      const { data } = yield axios.delete(
        `${process.env.REACT_APP_ONE_DRUG_BASE}/v1/api/Cart/Remove?cartId=${payload.details.id}`
      )
      yield put(deleteCartItemSuccessful(data))
    } else {
      const cartList = JSON.parse(window.localStorage.getItem('oneDrugCart'))

      const updatedList = (cartList || []).filter(
        (item) => item.drugItemId !== payload.details.id
      )

      window.localStorage.setItem('oneDrugCart', JSON.stringify(updatedList))

      yield put(deleteCartItemSuccessful(updatedList))
    }

    yield toast.success('Item deleted from cart.')
    yield put(getCartItemsRequested())
  } catch (error) {
    if (error.response) {
      yield put(deleteCartItemFailed(error.response.data))
      yield put(deleteCartItemFailed(null))
      return
    }

    yield put(deleteCartItemFailed(error.message))
    yield put(deleteCartItemFailed(null))
  }
}

export function* watchDeleteCartItem() {
  yield takeLatest(deleteCartItemRequested.type, deleteCartItemAsync)
}
