import React, { useEffect } from 'react'
import { Card } from 'react-bootstrap'
import axios from 'axios'
import Loading from '../../../../pharmHub/admin/components/Spinner/Spinner'
import { useSelector } from 'react-redux'

const { REACT_APP_BASE_URL } = process.env

const SectionWallet = () => {
  const { token } = useSelector((state) => state.user.userInfo)

  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(false)

  useEffect(() => {
    setLoading(true)
    axios({
      method: 'GET',
      url: `${REACT_APP_BASE_URL}/v2/api/Wallet/WalletDetails`,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        const allData = res.data.data
        setData(allData)
      })
      .catch((error) => console.error('Error', error))
      .then(() => setLoading(false))
  }, [])
  
  if (loading) return <Loading />
  if (data === null) {
    
    return (
      <div>
        <Card className="axa-mansard-dashboard-profile-card">
          <Card.Body className="card-dashboard">No data to display</Card.Body>
        </Card>
      </div>
    )
  }

  return (
    <div>
      <Card className="axa-mansard-dashboard-profile-card">
        <Card.Body className="card-dashboard">
          <div className="d-flex justify-content-between">
            <div className="info-container w-50">
              <p className="title">First name</p>
              <p className="description">{data.firstname}</p>
            </div>

            <div className="w-50">
              <p className="title">Last name</p>
              <p className="description">{data.lastname}</p>
            </div>
          </div>

          <hr />

          <div className="d-flex justify-content-between">
            <div className="info-container w-50">
              <p className="title">Mobile</p>
              <p className="description">{data.mobile}</p>
            </div>

            <div className="w-50">
              <p className="title text-left">Nuban</p>
              <p className="description text-left">{data.nuban}</p>
            </div>
          </div>

          <hr />

          <div className="d-flex justify-content-between">
            <div className="info-container w-50">
              <p className="title">Available balance</p>
              <p className="description">₦{data.availablebalance}</p>
            </div>

            <div className="w-50">
              <p className="title text-left">Customer id</p>
              <p className="description text-left">{data.customerid}</p>
            </div>
          </div>
          <hr />

          <div className="d-flex justify-content-between">
            <div className="info-container w-50">
              <p className="title">Virtual Account</p>
              <p className="description">{data.virtualacct}</p>
            </div>
            <div className="w-50">
              <p className="title">Gender</p>
              <p className="description">{data.gender}</p>
            </div>
          </div>

          <hr className="mb-5" />
        </Card.Body>
      </Card>
    </div>
  )
}

export default SectionWallet
