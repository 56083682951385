import React from 'react'
import { Formik, Field, Form } from 'formik'
import * as yup from 'yup'
import { Link, useHistory } from 'react-router-dom'
import CustomInput from '../../../../healthBancComponents/Templates/CustomInput'
import CustomButton from '../../../../healthBancComponents/Templates/CustomButton'

import styles from './login.module.scss'

const Login = ({
  loginTitle,
  loginDesc,
  handleLogin, //handle login is required
  signupTitle,
  signupDesc,
  signupBenefits,
  ...props
}) => {
  const history = useHistory()

  const loginSchema = yup.object().shape({
    email: yup
      .string()
      .email('Invalid email')
      .required('Your email address is required'),
    password: yup.string().required('Your password is required'),
  })

  return (
    <div>
      <div className={`${styles.authBox} d-sm-flex `}>
        <div className={` ${styles.authBoxLogin} mesm-4`}>
          <h5 className={styles.title}>
            {loginTitle || 'Registered Customers'}
          </h5>
          <p className={styles.desc}>
            {loginDesc ||
              'If you have an account, sign in with your email address.'}
          </p>
          <div>
            <Formik
              initialValues={{ email: '', password: '' }}
              validationSchema={loginSchema}
              onSubmit={(values, actions) => handleLogin(values, actions)}
            >
              {(props) => (
                <Form className={styles.signinForm}>
                  <Field
                    as={CustomInput}
                    label="Email"
                    name="email"
                    type="email"
                    value={props.email}
                  />

                  <Field
                    as={CustomInput}
                    label="Password"
                    name="password"
                    type="password"
                    value={props.password}
                  />

                  <div className="d-sm-flex align-content-center justify-content-between">
                    <div className="col-sm-5 mb-4">
                      <CustomButton className="">Sign in</CustomButton>
                    </div>
                    <div>
                      <Link to="/reset-password">Forgot your password?</Link>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>

        <div className={` ${styles.authBoxSignup} ms-sm-4`}>
          <h5 className={styles.title}>{signupTitle || 'New Customer?'}</h5>
          <p className={styles.desc}>
            {signupDesc || 'Creating an account has many benefits:'}
          </p>
          {signupBenefits || (
            <ul>
              <li>Check out faster</li>
              <li>Keep more than one address</li>
              <li>Track orders and more</li>
            </ul>
          )}
          <div className="col-10 col-sm-6">
            <CustomButton onClick={() => history.push('/signup')}>
              Create an account
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
