import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: {},
  error: null,
}

const getAggregatorsSlice = createSlice({
  name: 'getAggregators',
  initialState,
  reducers: {
    getAggregatorsRequested: (state) => {
      state.isLoading = true
      state.data = {}
      state.error = null
    },

    getAggregatorsSuccessful: (state, action) => {
      state.isLoading = false
      state.data = action.payload
      state.error = null
    },

    getAggregatorsFailed: (state, action) => {
      state.isLoading = false
      state.data = {}
      state.error = action.payload
    },
  },
})

export const {
  getAggregatorsRequested,
  getAggregatorsSuccessful,
  getAggregatorsFailed,
} = getAggregatorsSlice.actions
export default getAggregatorsSlice.reducer
