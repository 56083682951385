import { takeEvery, put } from 'redux-saga/effects'
import axios from 'axios'
import {
  getHealthInsuredAdminErrorLogsRequest,
  getHealthInsuredAdminErrorLogsFailed,
  getHealthInsuredAdminErrorLogsSuccess,
} from '../../actions/healthInsuredCreators/adminUserActions/getHealthInsuredAdminErrorLog'

function* getHealthInsuredAdminErrorLogs({ payload }) {
  const { pageNumber = 1, searchText = '' } = payload

  yield put(getHealthInsuredAdminErrorLogsRequest())

  try {
    const { data } = yield axios({
      method: 'post',
      url: `/v2/api/ErrorLog/GetPaginatedErrorLog`,
      data: { SearchText: searchText, PageNumber: pageNumber },
      headers: { Authorization: `Bearer ${localStorage.getItem('adminInfo')}` },
    }).then((response) => response)

    yield put(getHealthInsuredAdminErrorLogsSuccess(data))
  } catch (error) {
    yield put(getHealthInsuredAdminErrorLogsFailed(error.response))
  }
}

export function* watchGetHealthInsuredAdminErrorLogs() {
  yield takeEvery(
    'GET_HEALTHINSURED_ADMIN_ERROR_LOGS',
    getHealthInsuredAdminErrorLogs
  )
}
