import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: {},
  error: null,
}

const addCartItemSlice = createSlice({
  name: 'addCartItem',
  initialState,
  reducers: {
    addCartItemRequested: (state) => {
      state.isLoading = true
      state.data = {}
      state.error = null
    },

    addCartItemSuccessful: (state, action) => {
      state.isLoading = false
      state.data = action.payload
      state.error = null
    },

    addCartItemFailed: (state, action) => {
      state.isLoading = false
      state.data = {}
      state.error = action.payload
    },
  },
})

export const {
  addCartItemRequested,
  addCartItemSuccessful,
  addCartItemFailed,
} = addCartItemSlice.actions

export default addCartItemSlice.reducer
