import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import './styles/VerifyOtp.css'
import NavWallet from '../NavWallet'
import Progress from '../../Progress'
import Header from '../../../template/header/Header'
import Payment from '../../Payment'
//  Inputed OTP

import { _otp } from './InputOtp'
const VerifyOtp_ = () => {
  let history = useHistory()
  //const [countTimer, setCountTimer] = useState(30)

  const [redirectingTimer, setRedirectingTimer] = useState(5)

  let otp_spread = [..._otp]

  // function handleClick() {
    
  // }

  useEffect(() => {
    const interval = setInterval(() => {
        setRedirectingTimer((prev) => prev- 1)
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      history.push('/pay_with_wallet/create_wallet/verify')

    }, 5000)

    return () =>{
      clearInterval(interval)
    }
  }, [history])

  return (
    <div className="ver-main">
      <Header />
      <Payment />
      <section className="ver-sect">
        <div className="verify_otp">
          <div className="otp_pos1">
            <h2>Verify your phone number</h2>
            <p>
              A (One Time Password) OTP was sent to your Phone Number. Enter the
              code to continue.
            </p>
            <div className="verify_otp_sp">
              {otp_spread.map((l, i) => (
                <span key={i}>{l}</span>
              ))}
            </div>

            <h4>Redirecting page in {redirectingTimer} seconds</h4>

            <div className="ver">
              

              {/* <p>Did not get OTP? Click to resend OTP in 20s</p> */}
            </div>
          </div>
        </div>
      </section>
      <Progress />
    </div>
  )
}

export default VerifyOtp_
