import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Card, Row, Col, Button } from 'react-bootstrap'

import CorporateDashboardStructure from '../../corporateDashboardStructure/CorporateDashboardStructure'
import BeneficiaryFilter from '../../components/beneficiaryFilter/BeneficiaryFilter'
import ActiveBeneficiariesTable from '../../components/beneficiariesTables/ActiveBeneficiariesTable'
import InactiveBeneficiariesTable from '../../components/beneficiariesTables/InactiveBeneficiariesTable'
import PendingBeneficiariesTable from '../../components/beneficiariesTables/PendingBeneficiariesTable'
import { AddBeneficiariesBtn } from '../Dashboard/Dashboard'

import './beneficiaries.scss'

const Beneficiaries = () => {
  const [activeTab, setActiveTab] = useState({
    active: true,
    pending: false,
    inactive: false,
  })

  const dispatch = useDispatch()
  const location = useLocation()

  useEffect(() => {
    const { active, pending, inactive } = activeTab

    dispatch({
      type: 'FETCH_ACTIVE_CORPORATE_BENEFICIARIES',
      payload: { status: active ? 1 : pending ? 2 : inactive ? 3 : 0 },
    })

    location && location.state && location.state.pendingBeneficiaries
      ? setActiveTab({ pending: true })
      : setActiveTab({ active: true })
  }, [dispatch, location])

  const handleSetActiveTab = (e) => {
    switch (e.target.name) {
      case 'active':
        setActiveTab({ active: true, pending: false, inactive: false })
        break
      case 'pending':
        setActiveTab({ active: false, pending: true, inactive: false })
        break

      case 'inactive':
        setActiveTab({ active: false, pending: false, inactive: true })
        break

      default:
        setActiveTab({ active: true })
    }
  }

  const handleSearch = (e) => {
    const { active, pending, inactive } = activeTab
    dispatch({
      type: 'FETCH_CORPORATE_BENEFICIARIES',
      payload: {
        searchText: e.target.value,
        status: active ? 1 : pending ? 2 : inactive ? 3 : 0,
      },
    })
  }

  const handleSort = (e) => {
    const { active, pending, inactive } = activeTab

    dispatch({
      type: 'FETCH_CORPORATE_BENEFICIARIES',
      payload: {
        sortBy: Number(e.target.value),
        status: active ? 1 : pending ? 2 : inactive ? 3 : 0,
      },
    })
  }

  return (
    <CorporateDashboardStructure
      screenTitle="Beneficiaries"
      actionBtn={<AddBeneficiariesBtn />}
    >
      <Card className="w-100 beneficiaries">
        <Row className="beneficiaries__header">
          <Col className="beneficiaries--select">
            <div>
              <Button
                variant="link"
                className={activeTab.active && 'active'}
                onClick={handleSetActiveTab}
                name="active"
              >
                Active
              </Button>
            </div>
            <div>
              <Button
                variant="link"
                className={activeTab.pending && 'active'}
                onClick={handleSetActiveTab}
                name="pending"
              >
                Pending
              </Button>
            </div>
            <div>
              <Button
                variant="link"
                className={activeTab.inactive && 'active'}
                onClick={handleSetActiveTab}
                name="inactive"
              >
                Inactive
              </Button>
            </div>
          </Col>
        </Row>
        <Row className="beneficiaries--filter">
          <Col>
            <BeneficiaryFilter
              search={handleSearch}
              sort={handleSort}
              tableName={
                activeTab.active
                  ? 'active-beneficiary-table-to-excel'
                  : activeTab.pending
                  ? 'pending-beneficiary-table-to-excel'
                  : 'inactive-beneficiary-table-to-excel'
              }
            />
          </Col>
        </Row>
        <Row>
          {activeTab.active ? (
            <ActiveBeneficiariesTable />
          ) : activeTab.pending ? (
            <PendingBeneficiariesTable />
          ) : (
            <InactiveBeneficiariesTable />
          )}
        </Row>
      </Card>
    </CorporateDashboardStructure>
  )
}

export default Beneficiaries
