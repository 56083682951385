import React, { useState } from 'react'
import { Card, Table } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import UserDetails from './UserDetails';

const UserTable = ({ Users }) => {

  const [show, setShow] = useState(false);
  const [User, setUser] = useState({});

  // instanciate history object
  const location = useLocation();
  const path = location.pathname.slice(1, location.pathname.length);

  // show user details modal
  const handleShow = (User) => {
    setUser(User)
    setShow(true)
  };

  // hide user details modal
  const handleHide = () => setShow(false);

  // map individual user table row
  const tableRow = Users && Users.map((User, index) => (
    <tr key={index}>
      {/* <td>
        <form className='m-0 p-0 search-form'>
          <input type='checkbox' />
        </form>
      </td> */}
      <td onClick={() => handleShow(User)}>{User.firstName}</td>
      <td onClick={() => handleShow(User)}>{User.lastName}</td>
      <td onClick={() => handleShow(User)}>{User.email}</td>
      <td onClick={() => handleShow(User)}>{User.phoneNumber}</td>
      <td onClick={() => handleShow(User)}>{User.serviceUsed}</td>
    </tr >
  ));

  return (
    <div className='mt-4'>
      <UserDetails
        show={show}
        handleClose={handleHide}
        user={User}
      />
      <Card>
        <Card.Body className='px-5 py-4'>
          <Table hover size="md" responsive='sm' id={path}>
            <thead className='hb-admin-table-h'>
              <tr>
                {/* <th></th> */}
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>PHONE</th>
                <th>SERVICE IN USE</th>
              </tr>
            </thead>
            <tbody hb-admin-table-b >
              {tableRow}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
};

export default UserTable;
