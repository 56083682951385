import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import OtpInput from 'react-otp-input'
import { Card, Row, Col } from 'react-bootstrap'
import CustomButton from '../../../../../healthBancComponents/Templates/CustomButton'
import CorporateDashboardStructure from '../../corporateDashboardStructure/CorporateDashboardStructure'
import OTPInput from '../../../../../healthBancComponents/OTPInput/OTPInput'
import './corporatePayment_otp.scss'

const CorporatePayment_otp = () => {
  const dispatch = useDispatch()
  const superAdminEmail = useSelector((state) => state.user.userInfo.username)
  const chargeCorporateCard = useSelector((state) => state.chargeCorporateCard)
  const submitCorporateCardOtp = useSelector(
    (state) => state.submitCorporateCardOtp
  )

  const handleOTPSubmit = (otp) => {
    let pin,
      reference = ''

    if (chargeCorporateCard && chargeCorporateCard.data) {
      pin = chargeCorporateCard.data.pin
      reference = chargeCorporateCard.data.reference
    }

    dispatch({
      type: 'SUBMIT_CORPORATE_CARD_OTP',
      payload: { pin, reference, otp },
    })
  }

  return (
    <CorporateDashboardStructure screenTitle="Payment">
      <OTPInput
        email={superAdminEmail}
        handleSubmit={handleOTPSubmit}
        isLoading={submitCorporateCardOtp.isLoading}
      />
    </CorporateDashboardStructure>
  )
}

export default CorporatePayment_otp
