import React from 'react'
import styled from 'styled-components'
// import PharmHubIntro from './PharmHubIntro'
import DigitalHospital from './DigitalHospital'
import Insurance from './Insurance'
import HealthFinance from './HealthFinance'
import OneDrugStore from './OneDrugStore'

const ServicesContainer = styled.div`
  /* margin-top: 154px; */
  /* margin-bottom: 50px; */

  & > div {
    /* margin-bottom: 200px; */
  }

  @media (max-width: 576px) {
    margin-top: 10px;

    & > div {
      margin-top: -50px;
      margin-bottom: 100px;
    }
  }

  .service__container {
    margin-bottom: 150px;
  }
`

const ServicesIntro = () => {
  return (
    <ServicesContainer className="d-flex flex-column mx-auto justify-content-center">
      {/* <div className='service__container'>
				<PharmHubIntro />
			</div> */}
      <div className="service__container">
        <Insurance />
      </div>

      <div className="service__container">
        <OneDrugStore />
      </div>

      <div className="service__container">
        <DigitalHospital />
      </div>

      <div className="service__container">
        <HealthFinance />
      </div>
    </ServicesContainer>
  )
}

export default ServicesIntro
