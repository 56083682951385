import React, { useEffect } from 'react'
import classNames from 'classnames'
import { useLocation } from 'react-router'
import Layout from '../../components/Layout/Layout'

import styles from './dashboard.module.scss'

const Dashboard = () => {
  const location = useLocation()

  useEffect(() => {
    window.location.hash = '#account-info'
  }, [])

  const activeNavItem = (name) =>
    classNames({
      [styles.isActive]: name === location.hash,
    })

  return (
    <Layout>
      <div className={`${styles.dashboard} container`}>
        <h5 className={styles.title}>Dashboard</h5>

        <div className={`${styles.dashboardContent} d-sm-flex mt-5`}>
          <div className={`${styles.sideNav} me3 pb-4`}>
            <ul>
              <li>
                <a
                  href="#account-info"
                  name="#account-info"
                  className={` ${activeNavItem('#account-info')}`}
                  onClick={(e) => activeNavItem(e.target.name)}
                >
                  Account Information
                </a>
              </li>
              <li>
                <a
                  href="#address-book"
                  name="#address-book"
                  className={` ${activeNavItem('#address-book')}`}
                  onClick={(e) => activeNavItem(e.target.name)}
                >
                  Address Book
                </a>
              </li>
              <li>
                <a
                  href="#orders"
                  name="#orders"
                  className={` ${activeNavItem('#orders')}`}
                  onClick={(e) => activeNavItem(e.target.name)}
                >
                  My Orders
                </a>
              </li>
              <hr />
              <li>
                <a
                  href="#my-products"
                  name="#my-products"
                  className={` ${activeNavItem('#my-products')}`}
                  onClick={(e) => activeNavItem(e.target.name)}
                >
                  My Downloadable Products
                </a>
              </li>
              <li>
                <a
                  href="#stored-payment"
                  name="#stored-payment"
                  className={` ${activeNavItem('#stored-payment')}`}
                  onClick={(e) => activeNavItem(e.target.name)}
                >
                  Stored Payment Methods
                </a>
              </li>
              <li>
                <a
                  href="#billing-agreement"
                  name="#billing-agreement"
                  className={` ${activeNavItem('#billing-agreement')}`}
                  onClick={(e) => activeNavItem(e.target.name)}
                >
                  Billing Agreements
                </a>
              </li>
              <li>
                <a
                  href="#wish-list"
                  name="#wish-list"
                  className={` ${activeNavItem('#wish-list')}`}
                  onClick={(e) => activeNavItem(e.target.name)}
                >
                  My Wish List
                </a>
              </li>
              <hr />
              <li>
                <a
                  href="#my-product-reviews"
                  name="#my-product-reviews"
                  className={` ${activeNavItem('#my-product-reviews')}`}
                  onClick={(e) => activeNavItem(e.target.name)}
                >
                  My Product Reviews
                </a>
              </li>
              <li>
                <a
                  href="#newsletter"
                  name="#newsletter"
                  className={` ${activeNavItem('#newsletter')}`}
                  onClick={(e) => activeNavItem(e.target.name)}
                >
                  Newsletter Subscriptions
                </a>
              </li>
            </ul>
          </div>

          <div className={`${styles.main} pl-lg-5`}>
            <h5 className={`${styles.title} pb-3`}>Account Information</h5>
            <div className={`d-md-flex`}>
              <div className="content pt-2">
                <h6>Contact Information</h6>
                <div>Name</div>
                <div>Email Address</div>
              </div>
              <div className="content pt-2">NewsLetter</div>
            </div>

            <h5 className={`${styles.title} pb-3`}>Address Book</h5>
            <div className={`d-md-flex`}>
              <div className="content pt-2">
                <h6>Default Billing Address</h6>
                <div>No Default Address</div>
              </div>
              <div className="content pt-2">
                <h6>Default Shipping Address</h6>
                <div>No Default Address</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Dashboard
