import React, { useState } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'

import BackgroundTemplate from '../Templates/BackgroundTemplate'

import CustomInput from '../Templates/CustomInput'
import CustomButton from '../Templates/CustomButton'

const Response = styled.div`
  text-align: center;
  height: 40px;
  font-size: 12px;
  padding: 10px 3px;
  border: 3px;

  &.success {
    background: #eefbf4;
    color: #27ae60;
  }

  &.error {
    background: RGBA(215, 30, 27, 0.1);
    color: #d71e1b;
  }
`

const HBResetPassword = ({ user }) => {
  const [responseMessage, setResponseMessage] = useState({
    success: null,
    message: '',
  })

  const resetSchema = Yup.object({
    email: Yup.string().email().required('Required'),
  })

  const handleReset = async (values, { setSubmitting }) => {
    setResponseMessage({ success: null, message: '' })

    try {
      const PASSWORD_RESET_API =
        '/v2/api/Identity/ForgotPassword?app=HealthInsured'

      await axios({
        method: 'post',
        url: PASSWORD_RESET_API,
        data: { username: values.email },
        headers: { 'content-type': 'application/json' },
      }).then((response) => {
        setResponseMessage({
          success: true,
          message: 'A password reset link has been sent to your email!',
        })
      })
    } catch (error) {
      if (error.response) {
        setResponseMessage({
          success: false,
          message: error.response.data.message,
        })
      } else if (error.request) {
        alert('Response Error! \nPlease try again or contact support.')
      } else {
        alert('An error occured! \nPlease try again or contact support.')
      }
    } finally {
      setSubmitting(false)
    }
  }

  if (!user.isAuth) {
    return (
      <BackgroundTemplate
        formTitle="Reset Password"
        subTitle="Enter your email address to reset your password!"
        showLogo={true}
      >
        <Formik
          initialValues={{ email: '' }}
          validationSchema={resetSchema}
          onSubmit={(values, { setSubmitting }) =>
            handleReset(values, { setSubmitting })
          }
        >
          {(formik) => (
            <Form>
              <Field
                as={CustomInput}
                label="Email Address"
                type="email"
                name="email"
                placeholder="Enter your email here"
                autoFocus
              />
              {responseMessage.success === true ? (
                <Response className="success">
                  {' '}
                  {responseMessage.message}{' '}
                </Response>
              ) : responseMessage.success === false ? (
                <Response className="error">
                  {' '}
                  {responseMessage.message}
                </Response>
              ) : null}
              <CustomButton
                type="submit"
                disabled={formik.isSubmitting ? true : false}
              >
                {formik.isSubmitting ? 'Sending Reset Link...' : 'Reset'}
              </CustomButton>
            </Form>
          )}
        </Formik>
      </BackgroundTemplate>
    )
  } else {
    // return <Redirect to="/dashboard" />
    return <Redirect to="/" />
  }
}

const mapStateToProps = (state) => ({
  user: state.user.userInfo,
})

export default connect(mapStateToProps)(HBResetPassword)
