import React from 'react';

import HealthInsuredDashboardStructure from '../../HealthInsuredDashboardStructure/HealthInsuredDashboardStructure';
import MainSection from '../../components/support/mainSection/NewTicket';

const NewTicket = () => {
  return (
    <HealthInsuredDashboardStructure title='Support'>
      <MainSection />
    </HealthInsuredDashboardStructure>
  );
};

export default NewTicket;
