import React from 'react'
import Layout from '../../components/Layout/Layout'
import PrescriptionUpload from '../../components/prescriptionUpload/PrescriptionUpload'

const UploadPrescription = () => {
  return (
    <Layout>
      <div className="container mt-sm-4">
        <h5>Upload Prescription</h5>
        <div>
          <PrescriptionUpload />
        </div>
      </div>
    </Layout>
  )
}

export default UploadPrescription
