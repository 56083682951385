import axios from 'axios'

const { REACT_APP_ONE_DRUG_BASE } = process.env

export async function useGetOrderAdmin(orderId) {
  const response = await axios({
    method: 'GET',
    url: `${REACT_APP_ONE_DRUG_BASE}/v1/api/Order/OrderItems?orderId=${orderId}`,
  })

  return response
}
