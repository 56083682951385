import { put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import {
  uploadBeneficiariesFileFailed,
  uploadBeneficiariesFileRequest,
  uploadBeneficiariesFileSuccess,
} from '../../actions/healthInsuredCreators/corporateUserActions/uploadBeneficiariesListAction'

function* uploadCorporateBeneficiaries({ payload }) {
  const formData = new FormData()

  formData.append('FileUpload', payload)

  try {
    yield put(uploadBeneficiariesFileRequest())

    const { data } = yield axios
      .post(
        '/v1/api/CorporateInsurance/UploadUserProfileFromExcelFile',
        formData
      )
      .then((response) => response)

    yield put(uploadBeneficiariesFileSuccess(data))
  } catch (error) {
    yield put(uploadBeneficiariesFileFailed(error.response.data.message))
  }
}

export function* watchUploadCorporateBeneficiaries() {
  yield takeEvery(
    'UPLOAD_CORPORATE_BENEFICIARIES',
    uploadCorporateBeneficiaries
  )
}
