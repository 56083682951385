import {
  GET_HEALTHINSURED_ADMIN_ERROR_LOGS_FAILED,
  GET_HEALTHINSURED_ADMIN_ERROR_LOGS_REQUEST,
  GET_HEALTHINSURED_ADMIN_ERROR_LOGS_SUCCESS,
} from '../../actions/healthInsuredTypes/healthInsuredAdminActionTypes'

export const getHealthInsuredAdminErrorLogsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_HEALTHINSURED_ADMIN_ERROR_LOGS_REQUEST:
      return { isLoading: true }

    case GET_HEALTHINSURED_ADMIN_ERROR_LOGS_SUCCESS:
      return { isLoading: false, ...action.payload }

    case GET_HEALTHINSURED_ADMIN_ERROR_LOGS_FAILED:
      return { isLoading: false, errorMessage: action.payload }

    default:
      return state
  }
}
