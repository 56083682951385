import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'

const Box = styled.div`
  position: relative;
  border: 1px solid RGBA(128, 128, 128, 0.5);
  /* margin: 40px 20px; */
  margin-bottom: 40px;
  margin-left: 20px;
  margin-right: 20px;
  height: 250px;
  /* max-width: 300px; */
  width: 300px;
  background-color: RGBA(255, 255, 255, 0.7);
  text-align: left;
  font-size: 14px;
  /* min-width: 160px; */

  div.box-footer {
    background-color: RGBA(201, 200, 198, 0.2);
    position: absolute;
    width: 100%;
    height: 50px;
    bottom: 0;
    text-align: center;
    padding-top: 10px;
  }

  .desc {
    padding: 0px 15px;

    h3 {
      font-size: 25px;
      font-weight: 700;
      text-align: left;
    }
  }
`

const Button = styled.button`
  background: transparent;
  border: none;
  color: red;
  font-size: 14px;
  outline: none !important;

  :hover {
    font-size: 15px;
    transition: font-size 0.3s;
  }

  &:disabled {
    /* background-color: black; */
    font-size: 14px;
    cursor: not-allowed;
    color: #aaa;
  }
`

const Img = styled.img`
  width: 40px;
  text-align: left;
`

const ServiceItemTemplate = ({
  logo,
  name,
  description,
  redirect,
  isAvailable,
}) => {
  return (
    <Box>
      <div className="text-left p-3">
        {logo && <Img src={logo} alt={name} className="img-fluid" />}
      </div>
      <div className="desc">
        <h3 className="m-0">{name}</h3>
        <p>{description}</p>
      </div>
      <div className="box-footer pt-0">
        {!isAvailable ? (
          'Coming soon...'
        ) : (
          <Button style={{ width: '100%', height: '100%' }} onClick={redirect}>
            {
              <i>
                Continue <FontAwesomeIcon icon={faLongArrowAltRight} />
              </i>
            }
          </Button>
        )}
      </div>
    </Box>
  )
}

export default ServiceItemTemplate
