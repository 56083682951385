import React, { useState } from 'react'
import axios from 'axios'
import { useHistory, Link } from 'react-router-dom'
import Background from './Background'
import ReactivationHeader from '../signup/template/header/Header'
import ContentWrapper from './ContentWrapper'
// import CustomButton from '../../../../healthBancComponents/Templates/CustomButton'
import CustomButton from '../../../../healthBancComponents/Templates/CustomButton'
export default () => {
  const history = useHistory()
  const [isReactivating, setIsReactivating] = useState(false)

  const handleReactivation = async () => {
    setIsReactivating(true)
    await axios({
      method: 'get',
      url: '/v2/api/Tokenization/ReactivateWithPresentPrimaryCard',
    })
      .then((response) => history.push('/health_profile'))
      .catch((error) => {
        alert(error.response.data.message)
      })
      .then(() => setIsReactivating(false))
  }

  return (
    <Background className="container">
      <ReactivationHeader />
      <ContentWrapper>
        We saved your details from last time, do you want to continue?
        <div className="d-flex flex-wrap justify-content-center mt-4">
          <div className="me3 mb-3">
            <CustomButton
              onClick={handleReactivation}
              disabled={isReactivating ? true : false}
            >
              {isReactivating ? 'Reactivating...' : 'Yes, Continue'}
            </CustomButton>
          </div>
          <div>
            <Link
              to={{
                pathname: '/health_update_details',
                state: { fromReactivationPage: true },
              }}
            >
              <CustomButton outline>I want to update my details</CustomButton>
            </Link>
          </div>
        </div>
      </ContentWrapper>
    </Background>
  )
}
