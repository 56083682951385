import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'
import { Spinner } from 'react-bootstrap'
import Background from './Background'
import ReactivationHeader from '../signup/template/header/Header'
import ContentWrapper from './ContentWrapper'
import { Heading } from './ReactivateSubscription'
import CustomButton from '../../../../healthBancComponents/Templates/CustomButton'
import CustomInput from '../../../../healthBancComponents/Templates/CustomInput'
import CustomSelect from '../../../../healthBancComponents/Templates/CustomSelect'
import maritalStatusList from '../data/maritalStatus'

export default () => {
  const [userData, setUserData] = useState({})
  const [states, setStates] = useState([])
  const [careProviders, setCareProviders] = useState([])
  const [plans, setPlans] = useState([])
  const [buttonText, setButtonText] = useState('Update')
  const [isLoading, setIsLoading] = useState(false)
  const [insuranceProviderTowns, setInsuranceProviderTowns] = useState([])

  const history = useHistory()

  const userDetailsSchema = yup.object().shape({
    maritalStatus: yup.string().required('Required'),
    occupation: yup.string().required('Required'),
    contactAddress: yup.string().required('Required'),
    stateOfResidence: yup.string().required('Required'),
    townOfResidence: yup.string().required('Required'),
    planCode: yup.string().required('Required'),
    careProviderName: yup.string().required('Required'),
    // alternateHospital: yup.string().required('Required'),
  })

  //Get user details from DB && Get List of states
  useEffect(() => {
    const fetchUserData = async () => {
      setIsLoading(true)
      const userDetailsUrl = axios.get(
        '/v2/api/Insurance/GetUserInsuranceProfile'
      )
      const getStates = axios.get('/v2/api/Insurance/GetState')

      const getHealthPlans = axios.get(
        '/v2/api/Insurance/AxaMansardGetHealthPlans'
      )

      try {
        await axios
          .all([
            userDetailsUrl,
            getStates,
            // getTowns,
            getHealthPlans,
            // getHealthProvider,
          ])
          .then(
            axios.spread((userDetails, states, plans) => {
              setUserData(userDetails.data.data)
              setStates(states.data)
              // console.log(towns)
              setPlans(plans.data.data)
              // console.log(provider)
            })
          )
      } catch (error) {
        if (error.response) {
          alert(error.response.data.Message)
        } else if (error.request) {
          alert('Response Error.\nThere was no response from the server')
        } else {
          alert('Error!\nPlease try again or contact support')
        }
      } finally {
        setIsLoading(false)
      }
    }

    fetchUserData()
  }, [])

  const getTowns = async (town) => {
    console.log('GET City', { city: userData.insuranceService, town })
    try {
      await axios({
        method: 'post',
        url: `/v2/api/Insurance/GetTowns`,
        data: {
          InsurancePovider: userData.insuranceService,
          State: town,
        },
      }).then((response) => {
        return setInsuranceProviderTowns(response.data.data[0].cities)
      })
    } catch (error) {
      if (error.response) {
        console.error(error.response)
      } else if (error.request) {
        alert('There was no response from the server')
      } else {
        console.error('Error')
        alert('Error! \nPlease try again or contact support.')
      }
    }
  }

  const getCareHealthProviders = async (
    state,
    city,
    healthPlan,
    insuranceService
  ) => {
    // console.log(state, city, healthPlan)
    try {
      await axios({
        method: 'post',
        url: `/v2/api/Insurance/GetHealthProvider`,
        data: {
          InsurancePovider: insuranceService,
          State: state,
          City: city,
        },
      }).then((response) => setCareProviders(response.data.data))
    } catch (error) {
      if (error.response) {
        console.error(error.response)
      } else if (error.request) {
        console.error('Error! No response from server')
      } else {
        console.error(error)
        alert(
          'Error!\nThere was an error, please try again or contact support.'
        )
      }
    }
  }

  const handleSubmit = async (values, actions) => {
    const {
      maritalStatus,
      occupation,
      contactAddress,
      stateOfResidence,
      townOfResidence,
      planCode,
      careProviderName,
      // alternateHospital,
    } = values
    try {
      setButtonText('Updating...')
      await axios({
        method: 'post',
        url: '/v2/api/Insurance/UpdateProfile',
        data: {
          maritalStatus,
          occupation,
          contactAddress,
          stateOfResidence,
          townOfResidence,
          careProviderName,
          // alternateHospital,
          planCode,
        },
      }).then(() => {
        history.push('/health_verify_card')
      })
    } catch (error) {
      if (error.response) {
        alert(error.response.data.message)
      } else if (error.request) {
        alert('Error making request')
      } else {
        alert('Error. Please try again or contact support')
      }
    } finally {
      actions.setSubmitting(false)
    }
  }
  return (
    <Background className="container">
      <ReactivationHeader />
      <ContentWrapper>
        <Heading>
          <h3>Update Your Details</h3>
        </Heading>
        {isLoading ? (
          <Spinner animation="grow" />
        ) : (
          <Formik
            initialValues={{ ...userData }}
            enableReinitialize={true}
            validationSchema={userDetailsSchema}
            onSubmit={(values, actions) => {
              handleSubmit(values, actions)
            }}
          >
            {(props) => {
              return (
                <div
                  className="col-md-10 col-sm-12 mx-auto text-left"
                  style={{
                    position: 'relative',
                    maxHeight: 400,
                    overflowY: 'scroll',
                  }}
                >
                  <Form
                    className=""
                    style={{
                      background: 'inherit',
                    }}
                  >
                    <Field
                      as={CustomSelect}
                      label="Marital Status"
                      name="maritalStatus"
                      type="text"
                      autoFocus
                    >
                      {maritalStatusList.map((status) => (
                        <option value={status.id} key={status.id}>
                          {status.name}
                        </option>
                      ))}
                    </Field>

                    <Field
                      as={CustomInput}
                      type="text"
                      label="Occupation"
                      name="occupation"
                      placeholder="Occupation"
                    />

                    <Field
                      as={CustomInput}
                      type="text"
                      label="Home Address"
                      name="contactAddress"
                      placeholder="Home Address"
                    />

                    <Field
                      as={CustomSelect}
                      label="State of Residence"
                      type="text"
                      name="stateOfResidence"
                      onChange={(e) => {
                        props.handleChange(e)
                        getTowns(e.target.value)
                      }}
                    >
                      {/* <option
												value={
													props.values.stateOfResidence
												}
												defaultValue={true}
											>
												{props.values.stateOfResidence}
											</option> */}

                      <option>Choose State of Residence</option>
                      {states.map((state) => (
                        <option
                          key={state}
                          value={state}
                          defaultValue={state === props.values.stateOfResidence}
                        >
                          {state}
                        </option>
                      ))}
                    </Field>

                    <Field
                      as={CustomSelect}
                      type="text"
                      label="Town of Residence"
                      name="townOfResidence"
                      placeholder="Town of Residence"
                    >
                      <option value={props.values.townOfResidence}>
                        {props.values.townOfResidence}
                      </option>
                      {/* <option>
												Select town of Residence
											</option> */}
                      {insuranceProviderTowns.map((town) => (
                        <option
                          key={town}
                          value={town}
                          defaultValue={town === props.values.townOfResidence}
                        >
                          {town}
                        </option>
                      ))}
                    </Field>

                    <Field
                      as={CustomSelect}
                      label="Insurance Plan"
                      type="text"
                      name="planCode"
                      onChange={(e) => {
                        props.handleChange(e)
                        getCareHealthProviders(
                          props.values.stateOfResidence,
                          props.values.townOfResidence,
                          props.values.planCode,
                          props.values.insuranceService
                        )
                      }}
                    >
                      {plans.map((plan) => (
                        <option
                          key={plan.code}
                          value={plan.code}
                          defaultValue={
                            props.planCode === '7' ? 'Ruby' : 'Sapphire'
                          }
                        >
                          {plan.code === '7' ? 'Ruby' : 'Sapphire'}
                        </option>
                      ))}
                    </Field>

                    <Field
                      as={CustomSelect}
                      label="Primary Care Provider"
                      type="text"
                      name="careProviderName"
                      value={props.values.careProviderName}
                      defaultValue={true}
                    >
                      <option defaultValue={userData.careProviderName}>
                        {userData.careProviderName}
                      </option>
                      {careProviders.map((provider) => (
                        <option
                          key={provider.hospitalName}
                          defaultValue={
                            props.values.hospitalName ===
                              provider.hospitalName && props.values.hospitalName
                          }
                          value={`${provider.hospitalName}:${provider.address}:${provider.city}`}
                        >
                          {provider.hospitalName}
                        </option>
                      ))}
                    </Field>
                    {/* <Field
											as={CustomSelect}
											label='Secondary Care Provider'
											type='text'
											name='alternateHospital'

										>
											<option
												defaultValue={
													userData.alternateHospital
												}
											>
												{userData.alternateHospital}
											</option> */}
                    {/* <option>
												{props.values.alternateHospital}
											</option> */}
                    {/* {careProviders.map((provider) => (
												<option
													key={provider.hospitalName}
													value={
														provider.hospitalName
													}
												>
													{provider.hospitalName}
												</option>
											))}
										</Field> */}

                    <div className="mt-4">
                      <CustomButton
                        type="submit"
                        disabled={props.isSubmitting ? true : false}
                      >
                        {buttonText}
                      </CustomButton>
                    </div>
                  </Form>
                </div>
              )
            }}
          </Formik>
        )}
      </ContentWrapper>
    </Background>
  )
}
