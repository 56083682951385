import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import thunk from 'redux-thunk'
// import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import rootReducer from './rootReducer'
import logger from 'redux-logger'

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import rootSaga from './rootSaga'

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: hardSet,
  blacklist: ['signInRequestOTP'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const sagaMiddleware = createSagaMiddleware()

const middlewares = [sagaMiddleware, thunk]

//Check if env is development, then show logger
if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger)
}

const store = configureStore({
  reducer: persistedReducer,
  middleware: [...middlewares],
})

const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)

export { store, persistor }

// persistor.purge()
