import React from 'react'
import { ReactComponent as FilterIcon } from '../../../../../../../assets/healthInsuredAdmin/filterIcon.svg'
import { AiOutlineUsergroupAdd } from 'react-icons/ai'

const Filter = ({ filter, filterOptions }) => {
  return (
    <form className="health__insured__admin__providers__filter">
      <label
        className="health__insured__admin__providers__filter--label"
        htmlFor="#template__health_insurance-filter"
      >
        <select
          name="Filter"
          id="template__health_insurance-filter"
          className="health__insured__admin__providers__filter--select"
          onChange={filter}
        >
          <option value=" ">All</option>
          {filterOptions}
        </select>
        <i>
          <AiOutlineUsergroupAdd className="health__insured__admin__providers__filter--icon-user" />
          <FilterIcon className="health__insured__admin__providers__filter--icon-dropdown" />
        </i>
      </label>
    </form>
  )
}

export default Filter
