import { takeLatest, put } from 'redux-saga/effects'
import axios from 'axios'
import {
  createOrderRequested,
  createOrderSuccessful,
  createOrderFailed,
} from '../../../reducer/oneDrugStore/admin/orderSlices'

function* createOrderAsync({ payload }) {
  try {
    yield createOrderRequested()

    const { data } = yield axios({
      method: 'POST',
      url: `${process.env.REACT_APP_ONE_DRUG_BASE}/v1/api/Order/Add`,
      data: payload,
      headers: { Authorization: `Bearer ${localStorage.getItem('adminInfo')}` },
    })

    yield put(createOrderSuccessful(data))
  } catch (error) {
    if (error.response) {
      yield put(createOrderFailed(error.response.data))
      yield put(createOrderFailed(null))
      return
    }

    yield put(createOrderFailed(error.message))
    yield put(createOrderFailed(null))
  }
}

export function* watchCreateOrder() {
  yield takeLatest(createOrderRequested.type, createOrderAsync)
}
