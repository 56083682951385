import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import OtpInput from 'react-otp-input'
import BackgroundTemplate from '../../../../../healthBancComponents/Templates/BackgroundTemplate'
import CustomButton from '../../../../../healthBancComponents/Templates/CustomButton'
import './businessRegistrationOTPVerification.scss'

const BusinessRegistrationOTPVerification = () => {
  const [otp, setOtp] = useState('')
  const [countDown, setCountDown] = useState(120)

  const dispatch = useDispatch()
  const history = useHistory()
  const corporateUser = useSelector((state) => state.getCorporateUser)
  const resendOtp = useSelector((state) => state.resendCorporateOtp)
  const verifyCorporateUserOtp = useSelector(
    (state) => state.verifyCorporateUserOtp
  )

  useEffect(() => {
    dispatch({ type: 'GET_CORPORATE_USER' })
  }, [dispatch])

  // Check if user's email is confirm? Redirect to dashboard, else show otp page
  useEffect(() => {
    if (corporateUser.emailConfirmed) {
      history.push('/health_corporate/welcome')
    }
  }, [corporateUser, history])

  // Disable otp resend button for 30 seconds on load
  useEffect(() => {
    setTimeout(() => {
      if (countDown > 0) {
        setCountDown(countDown - 1)
      }
    }, 1000)
  }, [countDown])

  const handleVerifyOtp = () => {
    dispatch({
      type: 'VERIFY_CORPORATE_USER_OTP',
      payload: otp,
    })
  }

  return (
    <BackgroundTemplate showLogo={true} formTitle='Verify your profile'>
      <div className='text-center mb-3'>
        A verification code was sent to{' '}
        {corporateUser ? corporateUser.companyEmail : 'your email'}. Enter the
        code to continue.
      </div>
      <div className='otp__verification'>
        <OtpInput
          value={otp}
          onChange={(value) => setOtp(value)}
          numInputs={6}
          inputStyle='otp__input'
          className='mx-auto'
        />

        <div className='mt-3'>
          <CustomButton
            onClick={handleVerifyOtp}
            disabled={
              verifyCorporateUserOtp && verifyCorporateUserOtp.isLoading
                ? true
                : false
            }
          >
            {verifyCorporateUserOtp && verifyCorporateUserOtp.isLoading
              ? 'Verifying...'
              : 'Verify'}
          </CustomButton>
        </div>

        {/* Resend OTP link --- active after 30 seconds of page load */}
        {resendOtp && resendOtp.isLoading ? (
          <div className='resend__otp__link mx-auto mt-4'>Resending OTP...</div>
        ) : (
          <Button
            variant='link'
            className='resend__otp__link mt-4 mx-auto d-flex'
            disabled={countDown > 0 ? true : false}
            onClick={() => dispatch({ type: 'RESEND_CORPORATE_OTP' })}
          >
            {countDown > 0 ? `Resend OTP in ${countDown}s ...` : 'Resend OTP'}
          </Button>
        )}
      </div>
    </BackgroundTemplate>
  )
}

export default BusinessRegistrationOTPVerification
