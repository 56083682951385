import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: {},
  errors: [],
}

const removeFamilyMemberSlice = createSlice({
  name: 'RemoveFamilyMember',
  initialState,
  reducers: {
    removeFamilyMemberRequested: (state) => {
      return { isLoading: true, data: {}, errors: [] }
    },

    removeFamilyMemberSuccessful: (state, action) => {
      return { isLoading: false, data: { ...action.payload }, errors: [] }
    },

    removeFamilyMemberFailed: (state, action) => {
      return { isLoading: false, data: {}, errors: [...action.payload] }
    },
  },
})

export const {
  removeFamilyMemberRequested,
  removeFamilyMemberSuccessful,
  removeFamilyMemberFailed,
} = removeFamilyMemberSlice.actions

export default removeFamilyMemberSlice.reducer
