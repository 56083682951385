import { put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import {
  healthBancSigninAsync,
  healthBancSignOut,
} from '../actions/actionCreators'

function* refreshToken({ payload }) {
  const { token, refreshToken } = payload

  // yield axios({
  // 	method: 'post',
  // 	url:
  // 		'/swagger/index.html',
  // 	data: {
  // 		token,
  // 		refreshToken,
  // 	},
  // }).then((response) => console.log(response))

  try {
    const newToken = yield axios({
      method: 'post',
      url: '/swagger/index.html',
      data: {
        token,
        refreshToken,
      },
    }).then((response) => response)

    yield put(healthBancSigninAsync(newToken))
  } catch (error) {
    if (error.response) {
      if (error.response.status === 404) {
        yield put(healthBancSignOut())
        localStorage.clear()
        window.location.replace('/signin')
        return
      }
      alert(error.response.data.message)
    } else if (error.request) {
      alert('Response error! \nPlease try again or contact support.')
    } else {
      alert('An Error Occured! \n \nPlease try again or contact support.')
    }
  }
}

export function* watchRefreshToken() {
  yield takeEvery('REFRESH_TOKEN', refreshToken)
}
