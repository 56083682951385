import React, { useEffect, useState } from 'react'
import '../createNewWallet/styles/InputOtp.css'
import OtpInput from 'react-otp-input'
import Header from '../../../template/header/Header'
import Payment from '../../Payment'
import '../../paydetails.css'
import { IoMdRadioButtonOn } from 'react-icons/io'
import { AiFillCheckCircle } from 'react-icons/ai'
import { _phoneNumber } from './Pay'
import useConfirmOtp from '../walletHooks/useConfirmOtp'
import useResendOtp from '../walletHooks/useResendOtp'
import { ToastContainer } from 'react-toastify'

export let _otp = ''

const InputOtp = () => {
  const [otp, setOtp] = React.useState()
  const [countTimer, setCountTimer] = useState(30)

  let phnNum = ''
  _otp = otp

  const method = 'POST'
  const url = '/v2/api/Wallet/LinkWallet'
  const nextRoute = '/pay_with_wallet/verify_otp'
  const action = 'LinkWallet'
  const phoneNumber = _phoneNumber
  const resendUrl = `/v2/api/Wallet/GenerateOTPForExistingWallet`

  const { isLoading, handleClick } = useConfirmOtp(
    method,
    url,
    nextRoute,
    otp,
    action,
    phoneNumber
  )

  const { loadingResend, handleResend } = useResendOtp(resendUrl, _phoneNumber)

  useEffect(() => {
    if (!countTimer) return
    const timeout = setInterval(() => {
      setCountTimer((prev) => prev - 1)
    }, 1000)

    return () => {
      clearInterval(timeout)
    }
  }, [countTimer])

  return (
    <div className="inputOTP">
      <Header />
      <Payment />
      <div className="otp_input">
        <div className="otp_pos">
          <div className="otp_pos2">
            <h2>Verify your phone number</h2>
            <p>
              A (One Time Password) OTP was sent to your Phone number {phnNum}.
              Enter the code to continue.
            </p>
            <div className="otp-box-lg">
              <OtpInput
                className="otp-box"
                value={otp}
                onChange={(value) => setOtp(value)}
                numInputs={6}
                inputStyle={{
                  height: '59px',
                  width: '59px',
                  outline: 'none',
                  borderRadius: '5px',
                  marginLeft: '5px',
                  border: '1px solid grey',
                }}
              />
            </div>

            <div className="otp-box-md">
              <OtpInput
                className="otp-box-lg"
                value={otp}
                onChange={(value) => setOtp(value)}
                numInputs={6}
                inputStyle={{
                  height: '45px',
                  width: '45px',
                  outline: 'none',
                  borderRadius: '5px',
                  marginLeft: '5px',
                  border: '1px solid grey',
                }}
              />
            </div>
          </div>

          <div className="otp-btns">
            <button disabled={isLoading} onClick={handleClick}>
              <p>{isLoading ? 'Verifying OTP' : 'Verify OTP'}</p>
            </button>

            <p className="d-p">
              Did not get OTP? Click to{' '}
              <span
                onClick={handleResend}
                style={{
                  color: 'red',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
              >
                {loadingResend ? 'Resending OTP....' : 'Resend OTP'}
              </span>{' '}
              in {countTimer}s
            </p>
            <ToastContainer />
          </div>
        </div>
      </div>
      {/* progress bar */}

      <div className="progress">
        <ul>
          <li>
            <AiFillCheckCircle
              className="icon me3 fulfilled"
              style={{ color: '#d71e1b' }}
            />
            <span className="inactive active-inactive">Personal details</span>
          </li>

          <hr className="z" />

          <li>
            <AiFillCheckCircle
              className="icon me3 fulfilled"
              style={{ color: '#d71e1b' }}
            />
            <span className="inactive active-inactive">Bank information</span>
          </li>

          <hr className="z-z" />

          <li>
            <AiFillCheckCircle
              className="icon me3 fulfilled"
              style={{ color: '#d71e1b' }}
            />
            <span className="inactive">Care provider details</span>
          </li>

          <hr className="z-z-z" />

          <li>
            <IoMdRadioButtonOn
              className="icon me3 fulfilled"
              style={{ color: '#d71e1b' }}
            />
            <span className="active">Payment information</span>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default InputOtp
