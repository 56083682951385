import { takeLatest, put } from 'redux-saga/effects'
import axios from 'axios'
import {
  getDeliveryInfoRequested,
  getDeliveryInfoSuccessful,
  getDeliveryInfoFailed,
} from '../../../reducer/oneDrugStore/user/deliveryInfoSlices'

function* getDeliveryInfoAsync({ payload }) {
  try {
    yield getDeliveryInfoRequested()

    const { data } = yield axios.get(
      `${process.env.REACT_APP_ONE_DRUG_BASE}/v1/api/DeliveryInformation/Get`
    )

    yield put(getDeliveryInfoSuccessful(data))
  } catch (error) {
    if (error.response) {
      yield put(getDeliveryInfoFailed(error.response.data))
      yield put(getDeliveryInfoFailed(null))
      return
    }

    yield put(getDeliveryInfoFailed(error.message))
    yield put(getDeliveryInfoFailed(null))
  }
}

export function* watchGetDeliveryInfo() {
  yield takeLatest(getDeliveryInfoRequested.type, getDeliveryInfoAsync)
}
