import React, { useEffect } from 'react'
import { useLocation, Link } from 'react-router-dom'

const Nav = () => {
  //get current page
  const location = useLocation().pathname.split('/').splice(-1).join()

  useEffect(() => {
    //add active class to the li to indicate current page
    const listItems = document.querySelectorAll(
      '.healthInsured__admin__support--nav-item'
    )

    listItems.forEach((li) => {
      li.textContent.split(' ').join('').toLocaleLowerCase() === location &&
        li.classList.add('healthInsured__admin__support--active')
    })
  }, [])

  return (
    <nav className="healthInsured__admin__support">
      <ul className="healthInsured__admin__support--nav">
        <li className="healthInsured__admin__support--nav-item">
          <Link
            to="/health_insured/admin/support/newtickets"
            className="healthInsured__admin__support--nav-link"
          >
            New Tickets
          </Link>
        </li>

        <li className="healthInsured__admin__support--nav-item">
          <Link
            to="/health_insured/admin/support/pendingtickets"
            className="healthInsured__admin__support--nav-link"
          >
            Pending Tickets
          </Link>
        </li>

        <li className="healthInsured__admin__support--nav-item">
          <Link
            to="/health_insured/admin/support/resolvedtickets"
            className="healthInsured__admin__support--nav-link"
          >
            Resolved Tickets
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export default Nav
