import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const TitleHealth = styled.span`
  color: #d71e1b;
  font-size: 25px;
  font-weight: 700;
`

const TitleBanc = styled.span`
  color: #aaacae;
  font-size: 25px;
  font-weight: 700;
`

const HealthBancLogo = (props) => {
  return (
    <Link to={props.to || '/'} {...props} className="text-decoration-none">
      <TitleHealth>Health</TitleHealth>
      <TitleBanc>Insured</TitleBanc>
    </Link>
  )
}

export default HealthBancLogo
