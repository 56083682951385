import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: [],
  errors: [],
}

const getStatesSlice = createSlice({
  name: 'getStates',
  initialState,
  reducers: {
    GET_STATES_REQUESTED: (state) => {
      return { isLoading: true, data: [], errors: [] }
    },

    GET_STATES_SUCCESSFUL: (state, action) => {
      return { isLoading: false, data: [...action.payload], errors: [] }
    },

    GET_STATES_FAILED: (state, action) => {
      return { isLoading: false, data: [], errors: [action.payload] }
    },
  },
})

export const {
  GET_STATES_SUCCESSFUL,
  GET_STATES_REQUESTED,
  GET_STATES_FAILED,
} = getStatesSlice.actions

export default getStatesSlice.reducer
