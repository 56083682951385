import {
  STATE_PLAN_FAIL,
  STATE_PLAN_REQUEST,
  STATE_PLAN_SUCCESS,
} from '../../actions/healthInsuredTypes/hospitalAndPlan'

const initialState = {
  hospitalAndPlan: { healthplans: [], states: [] },
  loading: false,
  error: null,
}

// export const healthInsuredStateAndPlan = (state = { hospitalAndPlan: { healthplans: [], states: [] } }, action) => {
export const healthInsuredStateAndPlan = (state = initialState, action) => {
  switch (action.type) {
    case STATE_PLAN_REQUEST:
      return { ...state, loading: true }

    case STATE_PLAN_SUCCESS:
      return { ...state, loading: false, hospitalAndPlan: action.payload }

    case STATE_PLAN_FAIL:
      return { ...state, loading: false, error: action.payload }

    default:
      return state
  }
}
