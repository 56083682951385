import React, { useState } from 'react';

const EnrolleCard = ({
  title,
  name,
  nameValue,
  address,
  addressValue,
  enrollee,
  enrolleNumber,
}) => {

  const [copied, setCopied] = useState(false);

  const copyToClipboard = async () => {
    try {
      if (!navigator.clipboard) return;

      if (!copied) {
        await navigator.clipboard.writeText(enrolleNumber);
        setCopied(true);
      } else {
        await navigator.clipboard.writeText(' ');
        setCopied(false);
      }
    } catch (error) {
      alert(error.message)
    }
  };

  return (
    <article className='axa-enrolle-card'>
      <div className='wrapper'>
        <div className='header'>
          <h2 className='title'>{title}</h2>
          <button type='button' className='copy' onClick={copyToClipboard}>{copied ? 'Copied' : 'Copy'}</button>
        </div>

        <div className='name-wrapper'>
          <p className='title'>{name}</p>
          <p className='message'>{nameValue ? nameValue : 'N/A'}</p>
        </div>

        <div className='address-wrapper'>
          <p className='title'>{address}</p>
          <p className='message'>{addressValue ? addressValue : 'N/A'}</p>
        </div>


        <div className='name-wrapper'>
          <p className='title'>{enrollee}</p>
          <p className='message'>{enrolleNumber ? enrolleNumber : 'N/A'}</p>
        </div>

      </div>
    </article>
  );
};

export default EnrolleCard;
