import React from 'react';
import DescriptiveCard from '../descriptiveCard/DescriptiveCard';

const IntroSectionTwo = () => {
  return (
    <section className='mt-4 axa-mansard-introsection-two'>
      <DescriptiveCard />
    </section>
  );
};

export default IntroSectionTwo;
