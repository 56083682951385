import React from 'react'

import { Button, Modal } from 'react-bootstrap'
import CustomButton from '../../../healthBancComponents/Templates/CustomButton'

const ConfirmModal = ({
  isLoading,
  setShow,
  show,
  id,
  removeItem,
  activateItem,
  deactivateItem,
  action,
}) => {
  const handleClose = () => {
    setShow(false)
  }

  const handleAction = (action, id) => {
    switch (action) {
      case 'remove':
        removeItem(id)
        break

      case 'activate':
        activateItem(id)
        break

      case 'deactivate':
        deactivateItem(id)
        break

      default:
        return
    }
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} size="sm" centered>
        <p className="text-center text-bold pt-2">
          Click confirm to proceed with this action.
        </p>
        <Modal.Footer className="d-flex">
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            as={CustomButton}
            variant="danger"
            onClick={() => handleAction(action, id)}
            style={{ width: 'auto' }}
            disabled={isLoading && true}
          >
            {isLoading ? 'Confirming...' : 'Confirm Action'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ConfirmModal
