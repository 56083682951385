import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: {},
  error: null,
}

const deleteAggregatorSlice = createSlice({
  name: 'deleteAggregator',
  initialState,
  reducers: {
    deleteAggregatorRequested: (state) => {
      state.isLoading = true
      state.data = {}
      state.error = null
    },

    deleteAggregatorSuccessful: (state, action) => {
      state.isLoading = false
      state.data = action.payload
      state.error = null
    },

    deleteAggregatorFailed: (state, action) => {
      state.isLoading = false
      state.data = {}
      state.error = action.payload
    },
  },
})

export const {
  deleteAggregatorRequested,
  deleteAggregatorSuccessful,
  deleteAggregatorFailed,
} = deleteAggregatorSlice.actions
export default deleteAggregatorSlice.reducer
