import React from 'react'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Navbar, Nav } from 'react-bootstrap'
import { TiLockClosedOutline } from 'react-icons/ti'
import styled from 'styled-components'

import HealthBankLogo from '../../HealthBancLogo/HealthBancLogo'

const Button = styled.button`
  width: 124px;
  height: 43px;
  background: ${(props) => {
    if (props.path === '/' && props.signIn) {
      return 'transparent'
    } else if (props.path !== '/' && props.signIn) {
      return '#D71E1B'
    } else {
      return 'transparent'
    }
  }};

  color: ${(props) => {
    if (props.path === '/') {
      return '#fff'
    } else if (props.path !== '/' && props.signIn) {
      return '#fff'
    } else {
      return 'black'
    }
  }};

  border: ${(props) => {
    if (props.signIn && props.path === '/') {
      return '1px solid #fff'
    } else if (props.signIn && props.path !== '/') {
      return 'none'
    } else {
      return 'none'
    }
  }};
  border-radius: 3px;
`

const LandingPageNavbar = ({ history }) => {
  const isAuthUser = useSelector((state) => state.user.userInfo.isAuth)

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      variant="dark"
      style={{ background: 'transparent', width: '100%' }}
    >
      <div className="container">
        <Navbar className="nav-item">
          <HealthBankLogo />
        </Navbar>
        {isAuthUser ? (
          <>
            {/* <Button
            variant="link"
            onClick={() => history.push('/dashboard')}
            style={{ width: 'auto' }}
          >
            Back to Dashboard
          </Button>  */}
          </>
        ) : (
          <>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="d-lg-flex justify-content-lg-end"
            >
              <Nav className="text-center">
                <div className="my-2">
                  <Button
                    path={history.location.pathname}
                    onClick={() => history.push('/signin')}
                  >
                    <TiLockClosedOutline style={{ fontSize: '14px' }} />
                    Login
                  </Button>
                </div>
                <div className="ml-1 my-2">
                  <Button
                    path={history.location.pathname}
                    signIn
                    onClick={() => history.push('/signup')}
                  >
                    Sign up
                  </Button>
                </div>
              </Nav>
            </Navbar.Collapse>
          </>
        )}
      </div>
    </Navbar>
  )
}

export default withRouter(LandingPageNavbar)
