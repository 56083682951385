import {
  CORPORATE_PROFILE_COMPLETION_STATUS_FAILED,
  CORPORATE_PROFILE_COMPLETION_STATUS_REQUEST,
  CORPORATE_PROFILE_COMPLETION_STATUS_SUCCESS,
} from '../../healthInsuredTypes/corporateActionTypes'

export const corporateProfileCompletionStatusRequest = () => ({
  type: CORPORATE_PROFILE_COMPLETION_STATUS_REQUEST,
})

export const corporateProfileCompletionStatusSuccess = (payload) => ({
  type: CORPORATE_PROFILE_COMPLETION_STATUS_SUCCESS,
  payload,
})

export const corporateProfileCompletionStatusFailed = (errorMessage) => ({
  type: CORPORATE_PROFILE_COMPLETION_STATUS_FAILED,
  payload: errorMessage,
})
