import { takeEvery, put } from 'redux-saga/effects'
import {
  verifyCorporateOtpFailed,
  verifyCorporateOtpRequest,
  verifyCorporateOtpSuccess,
} from '../../actions/healthInsuredCreators/corporateUserActions/verifyCorporateOtpActions'
import axios from 'axios'

function* handleVerifyCorporateOtp({ payload }) {
  try {
    yield put(verifyCorporateOtpRequest())

    const { data } = yield axios
      .post(`/v2/api/CorporateInsurance/ConfirmOtp`, { data: { OTP: payload } })
      .then((response) => response)

    yield put(verifyCorporateOtpSuccess(data))
  } catch (error) {
    yield put(verifyCorporateOtpFailed(error.response))
  }
}

export function* watchVerifyCorporateOtp() {
  yield takeEvery('VERIFY_CORPORATE_USER_OTP', handleVerifyCorporateOtp)
}
