import axios from 'axios'
import { put, takeEvery } from 'redux-saga/effects'
import {
  beneficiariesReviewRequest,
  beneficiariesReviewSuccess,
} from '../../actions/healthInsuredCreators/corporateUserActions/corporateBeneficiariesReviewActions'
import {
  restoreBeneficiaryInReviewFailed,
  restoreBeneficiaryInReviewRequest,
  restoreBeneficiaryInReviewSuccess,
} from '../../actions/healthInsuredCreators/corporateUserActions/restoreBeneficiaryInReviewAction'

function* restoreBeneficiaryInReview({ payload }) {
  try {
    yield put(restoreBeneficiaryInReviewRequest())

    const { data } = yield axios
      .post(`/v2/api/CorporateInsurance/RestoreCompanyBeneficiaryReviewUser`, {
        data: { Email: payload },
      })
      .then((response) => response)

    yield put(restoreBeneficiaryInReviewSuccess(data))

    // Fetch list of updated beneficiaries
    // yield put(beneficiariesReviewRequest())

    const updatedBeneficiaries = yield axios
      .post(`/v2/api/CorporateInsurance/GetCompanyBeneficiaryReview`)
      .then((response) => response.data)

    yield put(beneficiariesReviewSuccess(updatedBeneficiaries))
  } catch (error) {
    yield put(restoreBeneficiaryInReviewFailed(error.response))
  }
}

export function* watchRestoreBeneficiaryInReview() {
  yield takeEvery('RESTORE_BENEFICIARY_IN_REVIEW', restoreBeneficiaryInReview)
}
