import React from 'react'
import { FcCheckmark } from 'react-icons/fc'
import { IoMdCheckmark } from 'react-icons/io'

const SubscriptionPlan = ({ checkCustomerState, loading }) => {
  return (
    <article className="axa-mansard-subscription-plan">
      <h2 className="title">Our affordable plans </h2>

      <div className="plans-container">
        <div className="plans-wrapper-ruby mb-sm-0">
          <div className="header">
            <h2 className="title">Ruby</h2>
            <p className="amount">
              ₦1000<small className="month">/month</small>
            </p>
          </div>

          <ul className="subscription-list">
            <li className="d-flex">
              <FcCheckmark className="icon d-block" />{' '}
              <span className="text">
                Inpatient benefit of N250,000 per annum
              </span>
            </li>
            <li className="d-flex">
              <FcCheckmark className="icon d-block" />{' '}
              <span className="text">Covers accidents & emergencies</span>
            </li>
            <li className="d-flex">
              <FcCheckmark className="icon d-block" />{' '}
              <span className="text">Surgeries (up to N70,000)</span>
            </li>
            <li className="d-flex">
              <FcCheckmark className="icon d-block" />{' '}
              <span className="text">Family planning and IUCDs</span>
            </li>
            <li className="d-flex">
              <FcCheckmark className="icon d-block" />{' '}
              <span className="text">Immunisations, and more.</span>
            </li>
          </ul>
          <button className="subscription-btn" onClick={checkCustomerState}>
            {loading ? 'Subscribe...' : 'Subscribe'}
          </button>
        </div>

        <div className="plans-wrapper-sapphire">
          <div className="header">
            <h2 className="title">Sapphire</h2>
            <p className="amount">
              ₦2000<small className="month">/month</small>
            </p>
          </div>

          <ul className="subscription-list">
            <li className="d-flex">
              {' '}
              <IoMdCheckmark className="icon d-block" />{' '}
              <span className="text">
                Inpatient benefit of N300,000 per annum
              </span>
            </li>
            <li className="d-flex">
              {' '}
              <IoMdCheckmark className="icon d-block" />{' '}
              <span className="text">Covers accidents & emergencies</span>
            </li>
            <li className="d-flex">
              {' '}
              <IoMdCheckmark className="icon d-block" />{' '}
              <span className="text">Surgeries (up to N70,000)</span>
            </li>
            <li className="d-flex">
              {' '}
              <IoMdCheckmark className="icon d-block" />{' '}
              <span className="text">Family planning and IUCDs</span>
            </li>
            <li className="d-flex">
              {' '}
              <IoMdCheckmark className="icon d-block" />{' '}
              <span className="text">Immunisations.</span>
            </li>
            <li className="d-flex">
              {' '}
              <IoMdCheckmark className="icon d-block" />{' '}
              <span className="text">Physiotherapy sessions</span>
            </li>
            <li className="d-flex">
              {' '}
              <IoMdCheckmark className="icon d-block" />{' '}
              <span className="text">Eye care</span>
            </li>
            <li className="d-flex">
              {' '}
              <IoMdCheckmark className="icon d-block" />{' '}
              <span className="text">Mother and child care, and more.</span>
            </li>
          </ul>
          <button className="subscription-btn" onClick={checkCustomerState}>
            {loading ? 'Processing...' : 'Subscribe'}
          </button>
        </div>
      </div>
    </article>
  )
}

export default SubscriptionPlan
