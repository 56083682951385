import {
  MOVE_PENDING_BENEFICIARIES_TO_INACTIVE_LIST_FAILED,
  MOVE_PENDING_BENEFICIARIES_TO_INACTIVE_LIST_REQUEST,
  MOVE_PENDING_BENEFICIARIES_TO_INACTIVE_LIST_SUCCESS,
} from '../../healthInsuredTypes/corporateActionTypes'

export const movePendingBeneficiariesToInactiveListRequest = () => ({
  type: MOVE_PENDING_BENEFICIARIES_TO_INACTIVE_LIST_REQUEST,
})

export const movePendingBeneficiariesToInactiveListSuccess = (payload) => ({
  type: MOVE_PENDING_BENEFICIARIES_TO_INACTIVE_LIST_SUCCESS,
  payload,
})

export const movePendingBeneficiariesToInactiveListFailed = (errorMessage) => ({
  type: MOVE_PENDING_BENEFICIARIES_TO_INACTIVE_LIST_FAILED,
  payload: errorMessage,
})
