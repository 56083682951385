import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useDispatch } from 'react-redux'

import NavigationBar from '../signup/template/header/Header'
import MainSection from './mainSection/MainSection'

const ChooseProvider = () => {
  return (
    <div>
      <NavigationBar />
      <MainSection />
    </div>
  )
}

export default ChooseProvider
