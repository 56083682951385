import React, { useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap';

const AlertDismissible = ({ variant, children, showAlert }) => {
	const [show, setShow] = useState(showAlert);

	useEffect(() => {
		setShow(showAlert);
	}, [setShow, showAlert]);

	const handleClose = () => setShow(false);

	if (show) {
		setTimeout(() => {
			setShow(false);
		}, 6000);
	}

	return (
		<Alert
			variant={variant}
			className='template__alert--component'
			show={show}
			onCLose={handleClose}
		>
			{children}
		</Alert>
	);
};

Alert.defaultProps = {
	variant: 'info',
};
export default AlertDismissible;
