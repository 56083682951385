import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { insuranceProvider } from '../../../data/insuranceProvider';

const CareProviderSection = () => {
  useEffect(() => window.scrollTo(0, 0), []);

  const {
    healthInsuredIndividualProfile: userProfile,
  } = useSelector(state => state.healthInsuredIndividualProfile);

  const insuranceService = useSelector(state => state.healthInsuredIndividualProfile.healthInsuredIndividualProfile.insuranceService);
  const rules = document.querySelectorAll('.rule');
  const roamingCard = document.querySelector('.roaming-card');

  // disable roaming card option if insurance provider is not hegeia
  if (roamingCard) {

    if (insuranceService !== insuranceProvider.hygeia) {
      roamingCard.style.display = 'none';
    };
  }

  rules.forEach((rule => {
    if (roamingCard.style.display === 'none') {
      rule.style.width = '50%'
    } else {
      rule.style.width = '100%'
    }
  }));


  return (
    <div>
      <Card className='axa-mansard-dashboard-care-provider-card'>
        <Card.Body className='card-dashboard'>
          <section>
            <h2 className='tagline'>Primary Care Provider</h2>
            <hr />

            <div>
              <p className='title'>Name</p>
              <p className='description'>{userProfile.careProviderName}</p>
            </div>

            <hr className='mx-0 rule' />

            <div>
              <p className='title'>Address</p>
              <p className='description'>{userProfile.cpAddress}</p>
            </div>

            <hr className='mx-0 rule' />

            <div>
              <p className='title'>City</p>
              <p className='description'>{userProfile.cpCity}</p>
            </div>

            <hr className='mx-0 rule' />
          </section>

          <article className='m-md-3 roaming-card'>
            <div className='container'>
              <h2 className='title'>Roaming</h2>

              <p className='description'>
                With roaming, you have accesss to any of our
                care providers closest to you.What is romaing?
            </p>

              <Link className='tool-tip' >
                What is roaming?
              </Link>

              <Link className='btn-find' to='/health_care_providers'>
                Find a care provider
              </Link>

            </div>
          </article>

        </Card.Body>
      </Card>
    </div>
  );
};

export default CareProviderSection;
