import { put, takeLatest } from 'redux-saga/effects'
import axios from 'axios'
import {
  getInsuranceProfilesFailed,
  getInsuranceProfilesRequest,
  getInsuranceProfilesSuccess,
} from '../../actions/healthInsuredCreators/adminUserActions/getHealthInsuredInsuranceProfiles'

function* getInsuranceProfiles({ payload }) {
  const {
    searchText = '',
    pageNumber = 1,
    sortBy,
    filter,
    status = 1,
  } = payload

  yield put(getInsuranceProfilesRequest())

  try {
    const { data } = yield axios({
      method: 'post',
      url: `/v2/api/Insurance/GetPaginatedInsuranceProfiles`,
      data: {
        SearchText: searchText,
        PageNumber: pageNumber,
        SortBy: sortBy === 0 ? '' : sortBy,
        Status: status,
        Filter: filter === 0 ? '' : filter,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('adminInfo')}`,
      },
    }).then((response) => response)

    yield put(getInsuranceProfilesSuccess(data))
  } catch (error) {
    yield put(getInsuranceProfilesFailed(error.response))
  }
}

export function* watchGetInsuranceProfiles() {
  yield takeLatest('GET_INSURANCE_PROFILES', getInsuranceProfiles)
}
