import React from 'react'

const CustomInput = ({
	labelName,
	inputValue,
	inputType,
	handleChange,
	inputName,
	errorMessage,
	max
}) => {
	return (
		<div className='d-flex flex-column mb-2 axa-mansard-signup-input-group'>
			<label className='mb-1 input-label'>{labelName}</label>
			<input
				type={inputType}
				value={inputValue}
				onChange={(e) => handleChange(e)}
				name={inputName}
				className='input-field px-2 py-1'
				maxLength={max ? max : ''}
			/>
			{errorMessage && (
				<p className='text-danger text-justify d-block m-0 w-100'>
					<small>*{errorMessage}</small>
				</p>
			)}
		</div>
	)
}

export default CustomInput
