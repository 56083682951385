import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { FiActivity } from 'react-icons/fi'
import './activityLog.scss'

const UserActivityLog = ({ userPersonalInfo }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const activityLog = useSelector(
    (state) => state.getHealthInsuredAdminUserActivityLogs
  )

  useEffect(() => {
    dispatch({
      type: 'GET_HEALTHINSURED_ADMIN_USER_ACTIVITY_LOGS',
      payload: { email: userPersonalInfo.email },
    })
  }, [dispatch, userPersonalInfo])

  const displayLog = () => {
    if (activityLog && activityLog.data) {
      return activityLog.data.data.length === 0 ? (
        <p className="text-center">Nothing to see here.</p>
      ) : (
        activityLog.data.data.map(
          (activity, index) =>
            index < 3 && (
              <div key={activity.id}>
                <UserActivity activity={activity} />
              </div>
            )
        )
      )
    }
  }

  return (
    <div className="healthInsured__admin__user__activity-log">
      <h2>Activity Log</h2>
      {displayLog()}

      <div className="text-center ">
        <button
          disabled={
            activityLog &&
            activityLog.data &&
            activityLog.data.data.length === 0
              ? true
              : false
          }
          className="healthInsured__user__profile__activity-log--see-more-btn"
          onClick={() =>
            history.push({
              pathname: `/admin/health_insured/users/profile/activity-log/${userPersonalInfo.surname}`,
              state: { userEmail: userPersonalInfo.email },
            })
          }
        >
          See More
        </button>
      </div>
    </div>
  )
}

export const UserActivity = ({ activity, ...props }) => {
  return (
    <Col className="healthInsured__admin__user__activity-log__activity">
      <div className="healthInsured__admin__user__activity-log__activity--icon">
        <FiActivity />
      </div>
      <Row className="healthInsured__admin__user__activity-log__activity--activity">
        <Col className="col-md-4 healthInsured__admin__user__activity-log__activity--title">
          {activity.actionApplied}
        </Col>
        <Col className="healthInsured__admin__user__activity-log__activity--date ">
          {new Date(activity.date).toDateString()}
        </Col>
      </Row>
    </Col>
  )
}

export default UserActivityLog
