import React from 'react';
import { useHistory } from 'react-router-dom';

import ProvidersIcon from '../../../../../../assets/healthInsuredAdmin/providerIcon.png';

const ProvidersCard = ({ providersName, img, alt }) => {

  const history = useHistory();

  const serviceProvideer = providersName.split('/')[0];

  return (
    <article
      className='health__Insured__admin__providers__card'
      onClick={() => history.push(`/health_insured/admin/providers/${providersName}/?provider=${serviceProvideer}`)}
    >
      <div className='health__Insured__admin__providers__card--container'>
        <img src={img} alt={alt} className='health__Insured__admin__providers__card--container-img' />

        <i className={`health__Insured__admin__providers__card--container-icon ${providersName !== 'hygiea/dashboard' && 'health__Insured__admin__providers__card--none'}`}>
          <img src={ProvidersIcon} alt={alt} className='health__Insured__admin__providers__card--container--icon-img' />
        </i>
      </div>
    </article>
  )
}

export default ProvidersCard;
