import {
  REMOVE_BENEFICIARIES_IN_REVIEW_FAILED,
  REMOVE_BENEFICIARIES_IN_REVIEW_REQUEST,
  REMOVE_BENEFICIARIES_IN_REVIEW_SUCCESS,
} from '../../../actions/healthInsuredTypes/corporateActionTypes'

export const removeBeneficiaryInReviewReducer = (state = {}, action) => {
  switch (action.type) {
    case REMOVE_BENEFICIARIES_IN_REVIEW_REQUEST:
      return { isLoading: true }

    case REMOVE_BENEFICIARIES_IN_REVIEW_SUCCESS:
      return { isLoading: false, ...action.payload }

    case REMOVE_BENEFICIARIES_IN_REVIEW_FAILED:
      return { isLoading: false, errorMessage: action.payload }

    default:
      return state
  }
}
