import {
  GET_HEALTHINSURED_SUBSCRIBER_ACQUISITION_REQUEST,
  GET_HEALTHINSURED_SUBSCRIBER_ACQUISITION_SUCCESS,
  GET_HEALTHINSURED_SUBSCRIBER_ACQUISITION_FAILED,
} from '../../healthInsuredTypes/healthInsuredAdminActionTypes'

export const getHealthInsuredSubscriberAcquisitionRequest = () => ({
  type: GET_HEALTHINSURED_SUBSCRIBER_ACQUISITION_REQUEST,
})

export const getHealthInsuredSubscriberAcquisitionSuccess = (data) => ({
  type: GET_HEALTHINSURED_SUBSCRIBER_ACQUISITION_SUCCESS,
  payload: data,
})

export const getHealthInsuredSubscriberAcquisitionFailed = (errorMessage) => ({
  type: GET_HEALTHINSURED_SUBSCRIBER_ACQUISITION_FAILED,
  payload: errorMessage,
})
