import axios from 'axios'

const getFriendsURL = '/v2/api/Insurance/GetPaginatedRefereeInsuranceProfiles'
const addFriendByEmailURL = '/v2/api/Insurance/PayforRefereeWithEmail'
const removeFriendURL = '/v2/api/Insurance/RemovePaidReferee'
const addFriendWithDetailsURL = '/v1/api/Insurance/PayForRefereeWithFullDetails'
const deactivateFriendURL = '/v2/api/Tokenization/DeactivteReferee'
const activateFriendURL = '/v2/api/Tokenization/ActivateRefereeWithPrimaryCard'

////////////////////
//GET ALL FRIENDS
export const getFriendsAPI = async (payload) => {
  const { pageNumber, searchValue } = payload
  return await axios({
    method: 'POST',
    url: getFriendsURL,

    data: {
      PageNumber: pageNumber,
      SearchText: searchValue,
    },
  })
}

////////////////////
//ADD A FRIEND
export const addFriendByEmailAPI = async (payload) => {
  const { email, insuranceService } = payload

  return await axios({
    method: 'POST',
    url: addFriendByEmailURL,
    data: {
      Email: email,
      InsuranceService: insuranceService,
    },
  })
}

////////////////////
//ADD A FRIEND
export const removeFriendAPI = async (payload) => {
  return await axios({
    method: 'POST',
    url: removeFriendURL,
    data: {
      Email: payload,
    },
  })
}

//////////////////
//ADD A FRIEND WITH DETAILS
export const addFriendWithDetailsAPI = async (payload) => {
  return await axios({
    method: 'POST',
    url: addFriendWithDetailsURL,
    data: payload,
  })
}

/////////////////
// DEACTIVATE FRIEND
export const deactivateFriendAPI = async (payload) => {
  return await axios({
    method: 'POST',
    url: `${deactivateFriendURL}`,
    data: {
      Id: payload,
    },
  })
}

/////////////////
// ACTIVATE FRIEND
export const activateFriendAPI = async (payload) => {
  return await axios({
    method: 'POST',
    url: `${activateFriendURL}`,
    data: {
      Id: payload,
    },
  })
}
