import Navbar from '../../../../../../healthBancComponents/LandingPage/Navbar/Navbar'

import React from 'react'
import Header from '../../template/header/Header';

const NavWallet = () => {
    return (
        <div>
            <Header />
            
        </div>
    )
}

export default NavWallet;