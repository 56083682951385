import React from 'react'
import { Row, Col } from 'react-bootstrap'
import HealthInsuredAdminDashboard from '../components/navbar/Navbar'
import SideNav from '../components/sidebar/SidebarNav'
import './healthInsuredDashboardStructure.scss'

const DashboardStructure = ({ title, children }) => {
  return (
    <Row className="healthInsuredDashboard">
      <Col className="healthInsuredDashboard--header p-0">
        <HealthInsuredAdminDashboard />
      </Col>
      <Col className="healthInsuredDashboard--side-nav">
        <SideNav />
      </Col>
      <Col className="healthInsuredDashboard--content-area">
        <div className="healthInsuredDashboard--page-title">{title}</div>
        <div className="healthInsuredDashboard--page-content">{children}</div>
      </Col>
    </Row>
  )
}

export default DashboardStructure
