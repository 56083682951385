import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react';


const { REACT_APP_BASE_URL } = process.env

const useConfirmOtp = (method, url, nextRoute, otp, action, phoneNumber) => {
    const { token } = useSelector(state => state.user.userInfo)
    const [isLoading, setIsLoading] = useState(false)  

    const history = useHistory(nextRoute)

    const handleClick = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        try {
            const response = await axios({
                method: method,
                url: `${REACT_APP_BASE_URL}/${url}`,
                headers:{ Authorization: `Bearer ${token}`},
                data:{
                    mobileNumber: phoneNumber,
                    otp: otp,
                    action: action
                }
            })
            console.log(response)         
            
            history.push(nextRoute)
        }catch  (error) {
            if (error.response) {
                toast(error.response?.data?.message)
                return
            }
            toast(error.message)
        }
        finally {
            setIsLoading(false)
        }
    }

    return {isLoading, handleClick}
}

export default useConfirmOtp