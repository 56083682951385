export const ACTIONS = {
  SIGNIN_ASYNC: 'SIGNIN_ASYNC',
  SIGN_OUT: 'SIGN_OUT',
  REFRESHTOKEN: 'REFRESH_TOKEN',
}

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_FAILED = 'SIGNUP_FAILED'

export const NOTIFICATION_MESSAGE = 'NOTIFICATION_MESSAGE'

export const AXA_USER_PIN_REFERENCE = 'AXA_USER_PIN_REFERENCE'
