import React from 'react'
import {Route, Redirect} from 'react-router-dom'

import {connect} from 'react-redux'


const ProtectedRouteHOC = ({component: Component, user, ...rest}) => {

    return (
        <Route {...rest} render={(props) => {
            if(user.isAuth) {
                return <Component {...props} />
            } else {
                return <Redirect
                    to={{
                        pathname: '/signin',
                        state: {
                            from: props.location
                        }
                    }} 
                />
            }
        }}
        >
            
        </Route>
    )
}

const mapStateToProps = state => ({
    user: state.user.userInfo
})

const ProtectedRoute =  connect(mapStateToProps)(ProtectedRouteHOC)

export {ProtectedRoute}
