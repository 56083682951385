import { put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import {
  getCorporateUserFailed,
  getCorporateUserRequest,
  getCorporateUserSuccess,
} from '../../actions/healthInsuredCreators/corporateUserActions/getCorporateUserActions'

function* getUser() {
  try {
    yield put(getCorporateUserRequest())
    const { data } = yield axios.get(
      '/v2/api/CorporateInsurance/GetCompanyProfileDetails'
    )
    yield put(getCorporateUserSuccess(data))
  } catch (error) {
    yield put(getCorporateUserFailed(error.response))
  }
}

export function* watchGetCorporateUser() {
  yield takeEvery('GET_CORPORATE_USER', getUser)
}
