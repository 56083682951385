import React from 'react'
import { FiSearch } from 'react-icons/fi'

import './_customSearchInput.scss'

const CustomSearchInput = ({
  onChange,
  placeholder = 'Search',
  value,
  ...props
}) => {
  return (
    <div className="custom-search__input">
      <FiSearch />
      <input
        {...props}
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      ></input>
    </div>
  )
}

export default CustomSearchInput
