import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { Formik, Field } from 'formik'
import Background from './Background'
import ReactivationHeader from '../signup/template/header/Header';
import ContentWrapper from './ContentWrapper'
import CustomSelect from '../../../../healthBancComponents/Templates/CustomSelect'
import CustomButton from '../../../../healthBancComponents/Templates/CustomButton'
import BackArrow from '../assets/BackArrow.svg'

export const Heading = styled.div`
	margin-bottom: 30px;
	h3 {
		font-size: 20px;
		font-weight: bold;
		color: #0c162c;
	}

	p {
		font-size: 16px;
		color: #4f4f4f;
	}
`

const Button = styled.button`
	background: transparent;
	border: none;
	text-align: left;

	&:focus {
		outline: none;
		transform: scale(1.05);
		border-radius: 50px;
		box-shadow: 1px 1px 5px lightgray;
	}
`

const ReactivateSubscription = () => {
	const history = useHistory()
	return (
		<Background>
			<ReactivationHeader />
			<ContentWrapper>
				<Heading>
					<h3>Subscription preference</h3>
					<p>Choose your preferred health plan.</p>
				</Heading>

				<div
					className='col-md-10 col-sm-12 mx-auto'
					style={{ textAlign: 'left' }}
				>
					<div className='col-md-10 col-sm-12 mx-auto'>
						<Button onClick={() => history.goBack()}>
							<div className='d-flex flex-row'>
								<img
									src={BackArrow}
									alt='go back'
									className='pr-3'
								/>
								Previous
							</div>
						</Button>
						<Formik initialValues={{ plan: 'Ruby' }}>
							{(props) => (
								<form style={{ backgroundColor: 'inherit' }}>
									<Field
										name='plan'
										as={CustomSelect}
										label='Preferred Plan'
									>
										<option value='Ruby'>
											Ruby &#8358;1,000
										</option>
										<option value='diamond'>
											Diamond &#8358;2,000
										</option>
									</Field>
									<div>
										<CustomButton
											onClick={() =>
												history.push(
													'/health_verify_card'
												)
											}
										>
											Next
										</CustomButton>
									</div>
								</form>
							)}
						</Formik>
					</div>
				</div>
			</ContentWrapper>
		</Background>
	)
}

export default ReactivateSubscription
