const INIT_STATE = {
  pin: '',
  reference: ''
}

export const axaUserPinReferenceReducer = (state = INIT_STATE, action) => {

  switch (action.type) {
    case 'AXA_USER_PIN_REFERENCE': return { ...state, ...action.pinReference };

    default: return state;
  }
};