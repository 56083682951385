import React, { useState } from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import OtpInput from 'react-otp-input'
import CustomButton from '../Templates/CustomButton'

const OTPInput = ({ email = '', handleSubmit, isLoading = false }) => {
  const [otp, setOtp] = useState('')

  return (
    <Card className="w-100 h-100 p-5 mt-5 col-12 col-sm-6 col-lg-4 mx-auto text-center payment--otp">
      <Row>
        <Col>
          <Row>
            <Col>
              <h3 className="payment--title font-weight-bold">
                Complete your payment
              </h3>
              <p className="mt-4">
                <small>
                  An OTP has been sent to <em>{email || 'your email'}</em>,
                  enter the OTP to complete your payment.
                </small>
              </p>
            </Col>
          </Row>
          <Row className="mx-auto text-center mt-4">
            <Col>
              <input
                type="tel"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className="w-100"
                maxLength={'10'}
              />
              {/* <OtpInput
                value={otp}
                onChange={(value) => setOtp(value)}
                numInputs={6}
                inputStyle="inputs"
              /> */}
              <div className="verify__otp--btn mt-3 p-0">
                <CustomButton
                  onClick={() => handleSubmit && handleSubmit(otp)}
                  disabled={isLoading || otp === '' ? true : false}
                >
                  {isLoading ? 'Verifying OTP...' : 'Verify'}
                </CustomButton>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  )
}

export default OTPInput
