import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import Footer from '../Templates/Footer'
import SubDashboardHeader from '../SubDashboard/SubDashboardHeader'
import Navbar from '../LandingPage/Navbar/Navbar'
import './terms.scss'

const TermsOfUse = () => {
  const isAuthUser = useSelector((state) => state.user.userInfo.isAuth)

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  return (
    <>
      {/* {isAuthUser ? <SubDashboardHeader /> : <Navbar />} */}
      <Navbar />

      <div className="container my-5">
        <h1 className="title">Terms of Use</h1>

        <div className="content d-flex flex-column">
          <div className="col-md-8ms-md-auto">
            <p>Welcome to HealthInsured.</p>
            <br />
            <p>
              {' '}
              HealthInsured is a digital health platform operated and powered by
              Sterling Bank Plc. (“Sterling”, “we”, “us”, and “our").
            </p>
            <br />
            <p>
              Please read these Terms and Conditions ("Terms", "Terms and
              Conditions") carefully before accessing or using the HealthInsured
              website and mobile applications (collectively referred to as{' '}
              <strong>“Platform”</strong>) developed and operated by Sterling
              Bank Plc ("us", "we", "our", “Sterling”).
            </p>
            <br />
            <h4>MEANING OF WORDS USED IN THIS TERMS</h4>
            <p>
              “Customer” means a customer of HealthInsured who has or operates a
              profile on the platform and is named in the application form but
              where two individuals or businesses are named, either or both of
              them are customers.
            </p>
            <br />
            <p>
              “Access code, Passcode, Username and Password” means the enabling
              code with which you access the Platform and which is known to you
              only.
            </p>
            <br />
            <p>
              “Instruction” means the customer’s request to the Platform for the
              services.
            </p>
            <br />
            <p>
              “Mailing Address” means the customer’s mailing address in the
              Platform’s records.
            </p>
            <br />
            <p>“Platform” means HealthInsured</p>
            <br />
            <p>“Profile” means a page containing a user’s platform</p>
            <br />
            <p>“Service” means the HealthInsured’s products and proposition</p>
            <br />
            <p>
              “You”, “your”, “yourself” and “User” means the individual user of
              the Services
            </p>
            <br />
            <h4>OUR SERVICES</h4>
            <ul>
              <li>
                <strong>Telemedicine:</strong>
                <p>
                  This Service is a doctor on demand offering for connecting
                  patients real time to licensed medical doctors who can
                  consult, recommend, test and diagnose on a wide range of
                  ailments to the extent practicable.
                </p>
              </li>
              <li>
                <strong>Pharmaceutical Hub:</strong>
                <p>
                  A Business-to-Business e-commerce platform that creates a
                  marketplace and provides access to affordable financing
                  options for Manufacturers/Importers of Drugs,
                  Distributors/wholesalers, Retailers and PPMV’s in the
                  Pharmaceutical value chain.
                </p>
              </li>
              <li>
                <strong>Health Management Information System (HMIS)</strong>
                <p>
                  This comprises of both the Electronic Medical Record (EMR) and
                  Inventory Management System (IMS). This solution provides
                  access to technology to stakeholders in the health sector by
                  automating their processes for effectiveness and efficiency,
                  by providing them with data for analytic service delivery to
                  patients
                </p>
              </li>
              <li>
                <strong>Insurance as a service:</strong>
                <p>
                  The service creates flexibility and allows users make monthly
                  premium payments for health insurance. The service is
                  integrated to select HMO platforms.
                </p>
              </li>
            </ul>
            <br />
            <h4>ACCOUNT ENROLLMENT AND USE OF ACCOUNT</h4>
            <p>
              You hereby certify that you are (i) over the age of eighteen (18)
              or have your Parent/Guardian’s consent to use the Service, and
              (ii) physically located or are a resident of the State or Country
              you have chosen as your current residency when creating your
              Account. You agree to provide “Contact Information” (your personal
              contact and/or a close family member/relation) to your Provider
              (as may be necessary for accessing some of our services on the
              Platform where knowledge of your location is crucial). Where a
              service is to be accessed by a corporate entity, the Organization
              will be required to provide its full registration information
              including its registered address, business objects, names of its
              key management, shareholding information etc.
            </p>
            <br />
            <p>
              To access the Services, firstly, you need to enroll to create an
              individual or corporate profile (“Account”), by providing certain
              information. In exchange for your use of the Service and, if
              applicable, in order for providers to send notices to you. You
              agree to: (i)provide true, accurate, current and complete
              information about yourself as prompted by our Account enrolment
              form and (ii) each time you log on, maintain and promptly update
              such Account information to keep it true, accurate, current and
              complete. If you provide any information that is untrue,
              inaccurate, not current or incomplete, or we have reasonable
              grounds to suspect that such Account information is untrue,
              inaccurate, not current or incomplete, we reserve the right to
              refuse any future request(s) to use the HealthInsured Services.
              You shall at all times remain responsible for maintaining the
              confidentiality of your Account password/access code or passcode
              and username (if any) and any other security information related
              to your Account. We will not be liable for any loss that you incur
              as a result of someone else accessing and using your Account,
              either with or without your knowledge.
            </p>
            <br />

            <h4>TELEHEALTH CONSENT</h4>
            <p>
              By completing your registration on the Platform, you give consent
              to us and our Service Providers to provide you with telehealth
              care and utilize your medical records for the purpose of providing
              the said services. You may withdraw this consent at any time by
              unsubscribing from our Platform.
            </p>
            <br />
            <p>
              You understand and agree that our telehealth or telemedicine
              services involve the delivery of healthcare services using
              electronic communications, information technology, or other means
              between a medical provider and a patient who are not in the same
              physical location for diagnosis, treatment, follow-up and/or
              patient education, and may include, but is not limited to:
            </p>
            <ul>
              <li>
                Electronic transmission of your medical records, photos/images,
                personal health information, or other data between a patient and
                a medical provider;
              </li>
              <li>
                Interactions between you and medical provider via audio, video,
                and/or other means of communications; and
              </li>
              <li>
                Use of output data from medical devices, sound, and video files.
              </li>
            </ul>
            <br />

            <h4>ACCESS RIGHTS</h4>
            <p>
              We hereby grant to you a limited, non-exclusive, non-transferable
              right to access the Platform and use the Services as permitted
              under these Terms and any separate agreements you may subsequently
              enter into with us (“
              <strong>Access Rights</strong>”). We reserve the right, in our
              sole discretion, to deny or suspend use of the Platform or
              Services to anyone for any reason.
            </p>
            <br />
            <p>
              You agree that you will not, or attempt to: (a) impersonate any
              person or entity, or otherwise misrepresent your affiliation with
              a person or entity; (b) use the Platform or Services to violate
              any local, state, national or international law; (c) reverse
              engineer, disassemble, decompile, or translate any software or
              other components of the Platform or Services; (d) distribute
              viruses or other harmful computer code through the Platform; or
              (e) otherwise use the Services or Platform in any manner that
              exceeds the scope of use granted above. In addition, you agree to
              refrain from abusive language and behavior which could be regarded
              as offensive or inappropriate, or conduct that is unlawful or
              illegal, when communicating with Healthcare Professionals through
              the Platform. We are not responsible for any interactions with
              Healthcare Professionals that are not conducted through the
              Platform.
            </p>
            <br />
            <p>
              All medical consultations offered on the platform are subject to
              medical diagnosis and are not conclusive proof of the medical
              condition stated.
            </p>
            <br />

            <h4>FEES</h4>
            <p>
              You agree to pay all fees and charges (including any taxes)
              associated with your Account on a timely basis in accordance with
              your preferred service option. Such fees and charges (including
              any taxes and late fees, as applicable) may be charged to the
              payment/credit card you provided during the initial setup of your
              Account.
            </p>
            <br />
            <p>
              Where you access the Services through an Employer sponsored
              benefit plan or other insurance backed plan, or access any of the
              Services as an Organization, your payment will be arranged through
              your employer on agreed payment term in accordance with the our
              policies and dynamics of the service offering.
            </p>
            <br />

            <h4>ACCOUNT SECURITY</h4>
            <p>
              You are solely responsible for maintaining the confidentiality of
              the username and password that you designate during the
              registration process, and you are solely and fully responsible for
              all activities that occur under your username and password. You
              agree to (a) immediately notify us of any loss or unauthorized use
              of your username or password or any other breach of security
              related to your account, and (b) ensure that you exit from your
              account at the end of each session. In no circumstance whatsoever
              will we be liable for any loss or damage arising from your failure
              to comply with this provision. We strongly advise against the use
              of public infrastructure when accessing your account as your
              personal information may become compromised.
            </p>
            <br />

            <h4>INTELLECTUAL PROPERTY</h4>
            <p>
              All of the content available on or through the Services on the
              HealthInsured Platform are the sole and exclusive intellectual
              property of Sterling or our licensors and same are protected by
              copyright, trademark, patent, trade secret and other intellectual
              property law. You agree to retain all copyright and trademark
              notices, including any other proprietary notices, contained in the
              materials, and not to alter, obscure or obliterate any of such
              notices You agree not to reproduce, re-transmit, distribute,
              disseminate, sell, publish, broadcast, or circulate the content
              received through the Service to any third party. We and/or our
              licensors own the copyright to all software and accompanying
              documentation made available for download from the platform. Any
              copy made of information obtained from or through the Platform
              must include all applicable copyright notices.
            </p>
            <br />

            <p>
              The license granted herein does not permit you to, and you agree
              not to: (i) modify, translate, reverse engineer, disassemble,
              decompile or create derivative works of the Service or allow a
              third party, whether directly or indirectly (including, but not
              limited to the direct or indirect use of wizards, agents, bots, or
              other utilities), to modify, translate, reverse engineer,
              disassemble, decompile or create derivative works of the Service;
              or (ii) transfer, distribute, sell, lease, rent, disclose or
              provide access to the Service to any third party or use the
              Service to provide service bureau, time sharing or other services
              to third parties. We reserve all rights not expressly granted to
              User.
            </p>
            <br />

            <h4>DISCLAIMER</h4>
            <p>
              The Platform does not provide emergency medical services. If you
              are experiencing a medical emergency, you should immediately dial
              your local emergency numbers or immediately visit a hospital.
            </p>
            <br />

            <p>
              The Platform is offered to provide………………………………... The Platform
              provides the technology to allow ………………………………………………… Users to
              connect with ……….. The existence of the Services to Users is not
              an endorsement by us on behalf of the Service Providers. By using
              the Services, you understand and agree that we shall not be
              responsible for the Services or outcomes or fees paid for same.
            </p>
            <br />
            <p>
              We shall not assume any responsibility for any loss, damages or
              liabilities arising from the failure of or your misuse of any
              protected health information, advice, ideas, information,
              instructions or guidelines accessed through the Services.
            </p>
            <br />
            <p>
              Access to the Services and the information contained herein is
              provided “as is” and “as available” without any warranty of any
              kind, express or implied. To the fullest extent permissible
              pursuant to applicable law, we disclaim all warranties of any
              kind, either express or implied, including but not limited to any
              implied warranties of title, merchantability, fitness for a
              particular purpose or non-infringement. Without limiting the
              foregoing, we do not warrant that access to the Platform of
              Services will be uninterrupted or error-free, or that defects, if
              any, will be corrected. You expressly agree that your use of the
              Platform and your reliance upon any of its contents is at your
              sole risk.
            </p>
            <br />

            <p>
              We make no warranty or condition that (i) the services will meet
              your requirements, (ii) the services will be uninterrupted,
              timely, secure, or error-free, (iii) the results that may be
              obtained from the use of the services will be accurate or
              reliable, or (iv) the services, information, or other material
              obtained by you through the services will meet your expectations.
              Furthermore, we do not make any promises as to the truth,
              accuracy, integrity, quality or completeness of the content or
              information that appears on the Platform neither are we
              responsible for verifying the ownership of any content posted or
              uploaded on the Platform.
            </p>
            <br />

            <p>
              You shall be solely and fully responsible for any damage to the
              Platform or any computer system, any loss of data, or any improper
              use or disclosure of information on the Platform caused by you or
              any person using your username or password. Any posting of
              comments or information on the Platform represents the opinion of
              the person posting only and is not a reflection of our opinions or
              attitudes, nor does it constitute any form of recommendation,
              representation, endorsement or arrangement by us. To be clear,
              each User acts on it/his/her own behalf at all times and not as
              our representative or agent in any way.
            </p>
            <br />

            <h4>LIMITATIONS OF LIABILITY</h4>
            <p>
              Under no circumstances shall we , our licensors or suppliers of
              medical/non-medical services or any affiliated third party who
              promotes the service or provides you with a link to the service,
              be liable for any punitive, exemplary, consequential, incidental,
              indirect or special damages (including, without limitation, any
              personal injury, loss profits, business interruption, loss data or
              otherwise) arising from or in connection with your use of the
              service, whether by a breach of contract, negligence, strict
              liability, malpractice or otherwise, even if you have been advised
              of the possibility of such damages.
            </p>
            <br />

            <h4>AMENDMENTS TO THE TERMS OR SERVICE</h4>
            <p>
              We reserve the right to add to, change, discontinue, temporarily
              or permanently, any service or amend or remove portions of this
              Terms at any time without notice. If any law regulates that
              change, we will only make the change to the extent permitted by,
              and subject to, the requirements of that law. Notification of
              amendments will be provided via notifications on the Platform,
              email or any other designated channel of communication.
              Nevertheless, please check these Terms and Conditions regularly
              for updates as changes shall be effective immediately, and we may
              indicate the date of such revision.
            </p>
            <br />
            <p>
              In addition, we may change, suspend, or discontinue any aspect of
              the Services at any time, including the availability of any
              feature, or content. We may also impose limits on certain features
              and service or restrict your access to parts or all of the
              Platform or the Services provided without notice or liability. You
              agree that the Platform will not be liable to you or any third
              party for any modification or discontinuation of the service.
            </p>
            <br />
            <p>
              By continuing to access or use our Service after amendments or
              modifications become effective, you agree to be bound by the
              revised terms. If you do not agree to the new terms, you may
              discontinue the use of the Platform.
            </p>
            <br />

            <h4>CONFIDENTIALITY</h4>
            <p>
              In providing you with the Services on the Platform, we will comply
              with the relevant Data Privacy Regulations in force from time to
              time and maintain safeguards to protect the security of your
              health information. Additionally, the information you provide to
              your Healthcare Professional during a medical consultation or
              therapy session is legally confidential, except for certain legal
              exceptions as more fully described in our Privacy Policy. We make
              reasonable effort toward ensuring that your personal information
              is secure. Information regarding our use of health and other
              personal information is provided in our Privacy Policy.
            </p>
            <br />

            <p>
              Certain communication such as appointment reminders, service
              announcements, privacy notices, administrative messages and other
              communications about the Services may be made by us via email. We
              cannot ensure the security or confidentiality of messages sent
              through such medium but we will make reasonable effort to ensure
              that information relating to your care, including clinical notes
              and medical records, are stored on secure, encrypted servers,
              secured and protected from loss, theft or unauthorised retrieval
              or transmission.
            </p>
            <br />

            <p>
              You hereby consent to our engagement of third parties to perform,
              provide, or support the performance or provision of, all or any
              portion of the Service and you further acknowledge and agree that
              they may receive access to your personal information in the
              provision of the services hereunder.
            </p>
            <br />

            <h4>PROHIBITED ACTIVITIES</h4>
            <p>
              We reserve the right to investigate and terminate your use of the
              Platform if you have misused the Service, or behaved in a way
              which could be regarded as inappropriate or engaged in conduct
              that is unlawful or illegal. The following activities (without
              limitation) are prohibited uses of the Platform:
            </p>
            <ul>
              <li>
                You will not express or imply that any statements you make are
                endorsed by Us without our specific prior written consent.
              </li>
              <li>
                You will not use any robot, spider ,Platform search/retrieval
                application, or other manual or automatic device or process to
                retrieve, index, “data mine”, or in any way reproduce or
                circumvent the navigational structure or presentation of the
                Service or its contents.
              </li>
              <li>
                You will not violate, or encourage others to violate, any right
                of a third party, including by infringing or misappropriating
                any third-party intellectual property right., or violating any
                third party privacy right.
              </li>
              <li>
                You will not interfere with or disrupt the Services or the
                servers or networks connected to the Service, including any
                security or access control mechanism.
              </li>
              <li>
                You will not post, email or otherwise transmit any material that
                contains software viruses or any other computer code, files or
                programs designed to interrupt, destroy or limit the
                functionality of any computer software or hardware or
                telecommunications equipment.
              </li>
              <li>
                You will not forge headers or otherwise manipulate identifiers
                in order to disguise the origin of any information transmitted
                through the Platform.
              </li>
              <li>
                You will not “frame” or “mirror” any part of the Platform,
                without our prior written authorization.
              </li>
              <li>
                You will not modify, adapt, sublicense, translate, sell, reverse
                engineer, decipher, decompile or otherwise disassemble or modify
                any portion of the Platform or any software used on or for the
                Service or cause or enable others to do so.
              </li>
              <li>
                You will not use the Platform if you are prohibited under
                applicable law from using the Platform.
              </li>
              <li>
                You will not conduct activities that may be harmful to others or
                that could damage the reputation of the Platform.
              </li>
              <li>
                You will not post, upload, or distribute marketing or
                advertising links or content, or any User Content or other
                content that is unlawful, defamatory, libelous, inaccurate, or
                that a reasonable person could deem to be objectionable,
                profane, pornographic, harassing, threatening, embarrassing,
                hateful, or otherwise inappropriate.
              </li>
              <li>
                You will not make any unsolicited offer or advertisement to
                another user of the Platform or attempt to collect personal
                information or other information about another user or third
                party without their consent.
              </li>
              <li>
                You will not perform any fraudulent activity including
                impersonating any person or entity, claiming a false affiliation
                or accreditation, accessing any other Service account without
                permission, or falsifying your account registration information.
              </li>
              <li>
                You will not modify, translate, or create derivative works,
                adaptations or compilations of, or based on, the Platform or
                part thereof, or use, copy or reproduce the Platform or any part
                thereof other than as expressly permitted in this Terms.
              </li>
            </ul>
            <br />

            <h4>TERMINATION</h4>
            <p>
              You can deactivate, cancel or close your account any time you
              want. If you’d like to, please contact us. Once your account is
              closed, you won’t be able to access the account again. We also
              reserve the right to suspend your account or stop you using the
              Platform or Services immediately for any reason including to
              maintain security, if:
            </p>
            <ul>
              <li>
                You have violated or breached the terms and conditions or the
                Bank’s policies
              </li>
              <li>You pose an unacceptable credit or fraud risk</li>
              <li>We suspect fraud or an attempt at fraud</li>
              <li>You engage in fraudulent or illegal conduct</li>
              <li>
                You give us false, incomplete or misleading information at any
                time
              </li>
              <li>You have broken the law or attempt to break the law</li>
              <li>
                We receive notice of your mental incapacity, bankruptcy or death
              </li>
            </ul>

            <p>
              If your Account is closed or terminated for any reason or no
              reason, you agree:
            </p>
            <ol type="a">
              <li>
                that you will immediately be denied access to the Platform,
              </li>
              <li>
                that we have the right to delete all of your information and
                Account data in accordance with our record-keeping policies and
                applicable law, and
              </li>
              <li>
                that we shall be not be liable to you or any third party for any
                compensation, reimbursement, or damages in connection with any
                termination or suspension of the Service.
              </li>
            </ol>

            <p>
              All provisions of the Terms which by their nature should survive
              termination shall survive termination, including without
              limitation, ownership provisions, warranty disclaimers, indemnity
              and limitations of liability.
            </p>

            <h4>FORCE MAJEURE</h4>
            <p>
              To the fullest extent permitted under applicable law, we will be
              excused from performance under these Terms for any period that we
              are prevented from or delayed in performing any obligations
              pursuant to this Terms, in whole or in part, as a result of a
              Force Majeure Event. For purposes of this section, “Force Majeure
              Event” means an event or series of events caused by or resulting
              from any of the following: (a) weather conditions or other
              elements of nature or acts of God; (b) acts of war, acts of
              terrorism, insurrection, riots, civil disorders or rebellion; (c)
              quarantines or embargoes, (d) labor strikes; (e); (f) unauthorized
              access to our information technology systems by third parties; or
              (g) other causes beyond our reasonable control and contemplation.
            </p>

            <h4>ASSIGNMENT</h4>
            <p>
              You may not transfer, assign, or delegate these Terms or your
              rights or obligations hereunder or your HealthInsured Account in
              any way (by operation of law or otherwise). To the extent
              permitted by applicable law, we may transfer, assign, or delegate
              these Terms and our rights and obligations hereunder without your
              consent.
            </p>

            <h4>DISPUTE RESOLUTION</h4>
            <p>
              We will use our best efforts to resolve any issue that may arise
              from the use of the Platform. However, we realize that there may
              be rare cases where we may not be able to resolve an issue to your
              satisfaction. In the event we cannot resolve a dispute between us,
              you agree that all matters related to any use or access to the
              Platform or the services shall be referred to Mediation at the
              Lagos Multidoor Courthouse, in accordance with the Lagos State
              Multidoor Courthouse Rules 2007.
            </p>

            <h4>WAIVER</h4>
            <p>
              No waiver by us of any term or condition set forth in these Terms
              shall be deemed a further or continuing waiver of such term or
              condition or a waiver of any other term or condition, and any
              failure by us to assert a right or provision under these Terms
              shall not constitute a waiver of such right or provision.
            </p>

            <h4>SEVERABILITY</h4>
            <p>
              If any of this terms is judicially determined to be illegal,
              unenforceable, or invalid in whole or in part for any reason, such
              illegal, unenforceable, or invalid provisions or part(s) of the
              same shall be stricken from this terms, and such provision shall
              not affect the legality, enforceability, or validity of the
              remainder of this terms. The stricken provision maybe replaced, to
              the extent possible, with a legal, enforceable, and valid
              provision that is as similar in term to the stricken provision as
              is legally possible.
            </p>

            <h4>GOVERNING LAW</h4>
            <p>
              These Terms of Use and your use of the Platform shall be governed
              by the laws of the Federal Republic of Nigeria, without giving
              effect to the principles of conflict of laws.
            </p>

            <h4>CONTACT US</h4>
            <p>
              Please send any questions or report any violations of these Terms
              of Use to{' '}
              <a href="mailto:HealthInsured@sterling.ng">
                HealthInsured@sterling.ng
              </a>
              .
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default TermsOfUse
