import React from 'react'
import Layout from '../../components/Layout/Layout'
import DebitCardInfoForm from '../../../../healthBancComponents/debitCardInfoForm/DebitCardInfoForm'
import OTPInput from '../../../../healthBancComponents/OTPInput/OTPInput'

const CardDebit = () => {
  const handleSubmitOTP = (otp) => {
    console.log(otp)
  }

  return (
    <Layout>
      {/* <div className="mt-5">{payment()}</div> */}
      <DebitCardInfoForm />
      <OTPInput email="" handleSubmit={handleSubmitOTP} isLoading={false} />
    </Layout>
  )
}

export default CardDebit
