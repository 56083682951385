import { put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import {
  getCorporateActivityLogFailed,
  getCorporateActivityLogRequest,
  getCorporateActivityLogSuccess,
} from '../../actions/healthInsuredCreators/corporateUserActions/corporateActivityLogsAction'

function* getCorporateActivityLog(payload) {
  yield put(getCorporateActivityLogRequest())

  try {
    const { data } = yield axios
      .post(
        '/v2/api/ActivityLog/GetLoggedInUserHealthInsuredPaginatedActivityLog'
      )
      .then((response) => response)

    yield put(getCorporateActivityLogSuccess(data))
  } catch (error) {
    yield put(getCorporateActivityLogFailed(error.response))
  }
}

export function* watchGetCorporateActivityLog() {
  yield takeEvery('GET_CORPORATE_ACTIVITY_LOG', getCorporateActivityLog)
}
