import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { IoMdRadioButtonOn, IoIosRadioButtonOff } from 'react-icons/io'
import styled from 'styled-components'

import { ReactComponent as WarningIcon } from '../../assets/warningicon.svg'
import Header from '../template/header/Header'
import CustomInput from '../template/customInput/CustomInput'
import CustomSelectInput from '../template/customSelect/CustomeSelectput'
import CustomButton from '../template/customButton/CustomeButton'
import CustomDateInput from '../template/customDateInput/CustomerDateInput'
import personalDetailsOptions from '../../data/personalDetails'

const FileInputField = styled.div`
  label {
    font-size: 13px;
    font-weight: 500;
    padding: 5px;
    border-radius: 5px;
    margin: 1px;
    box-shadow: 2px 2px 5px grey;

    &:hover {
      cursor: pointer;
      box-shadow: 2px 2px 7px grey;
    }
  }

  input {
    border: none;
    height: 0.1px;
    width: 0.1px;
  }

  span {
    color: #02761d;
    font-size: 12px;
  }

  .text-info {
    font-size: 11px;
  }

  .imageErrorMsg {
    font-size: 11px;
    font-weight: bold;
  }
`

const PersonalDetails = ({
  handleChange,
  nextPage,
  states,
  towns,
  errorMessage,
  healthInsuredSignupDetails,
  townLoading,
  getTownError,
}) => {
  const {
    DateOfBirth,
    Gender,
    MaritalStatus,
    Occupation,
    ContactAddress,
    StateOfResidence,
    TownOfResidence,
    UserImage,
  } = healthInsuredSignupDetails

  const [fileTypeError, setFileTypeError] = useState(null)

  const profileCompletionStatus = useSelector(
    (state) => state.profileCompletionStatus.data
  )

  const { genderOptions, maritalStatusOptions, statesOption, townOption } =
    personalDetailsOptions({ states, towns, townLoading, getTownError })

  const requiredText = (fieldValue) =>
    String(fieldValue) === '' ? 'Required' : null

  return (
    <section className="axa-mansard-sign-up-personal-details">
      <Header />

      <div className="form-container">
        <div className="personal-details-form">
          <form className="personal-form" onSubmit={nextPage}>
            <h1 className="start-your" id="start">
              Start your 1-month free trial
            </h1>
            <p className="provide-some mb-4">
              Provide some basic information to get access too all our benefits.
            </p>

            <CustomDateInput
              inputType={'date'}
              labelName={'Date of birth'}
              handleChange={handleChange}
              inputValue={DateOfBirth}
              inputName="DateOfBirth"
              errorMessage={
                errorMessage.DateOfBirthError || requiredText(DateOfBirth)
              }
            />
            <CustomSelectInput
              labelName={'Gender'}
              handleChange={handleChange}
              inputValue={Gender}
              inputName="Gender"
              selectOptions={genderOptions}
              errorMessage={errorMessage.GenderError || requiredText(Gender)}
            />
            <CustomSelectInput
              selectOptions={maritalStatusOptions}
              labelName={'Marital Status'}
              handleChange={handleChange}
              inputValue={MaritalStatus}
              inputName="MaritalStatus"
              errorMessage={
                errorMessage.MaritalStatusError || requiredText(MaritalStatus)
              }
            />

            <CustomInput
              inputType={'text'}
              labelName={'Occupation'}
              handleChange={handleChange}
              inputValue={Occupation}
              inputName="Occupation"
              errorMessage={
                errorMessage.OccupationError || requiredText(Occupation)
              }
            />
            <CustomInput
              inputType={'text'}
              labelName={'Home address'}
              handleChange={handleChange}
              inputValue={ContactAddress}
              inputName="ContactAddress"
              errorMessage={
                errorMessage.ContactAddressError || requiredText(ContactAddress)
              }
            />
            <CustomSelectInput
              selectOptions={statesOption}
              labelName={'State of residence'}
              handleChange={handleChange}
              inputValue={StateOfResidence}
              inputName="StateOfResidence"
              errorMessage={
                errorMessage.StateOfResidenceError ||
                requiredText(StateOfResidence)
              }
            />
            <p>
              <WarningIcon />
              Your care provider options will be based on state of residence.
            </p>
            <CustomSelectInput
              selectOptions={townOption}
              labelName={'Town of residence'}
              handleChange={handleChange}
              inputValue={TownOfResidence}
              inputName="TownOfResidence"
              errorMessage={
                errorMessage.TownOfResidenceError ||
                requiredText(TownOfResidence)
              }
            />

            <FileInputField>
              <label htmlFor="photo">Upload Photo</label>
              <input
                id="photo"
                type="file"
                accept="image/jpg, image/jpeg, image/png"
                name="UserImage"
                onChange={(e) => {
                  const file = e.target.files[0]

                  const acceptedFileTypes = [
                    'image/png',
                    'image/jpg',
                    'image/jpeg',
                  ]

                  if (!acceptedFileTypes.includes(file?.type)) {
                    setFileTypeError(
                      'Accepted image formats: .png, .jpg, .jpeg'
                    )
                    return
                  }
                  setFileTypeError(null)

                  return handleChange(e)
                }}
              />
              {` : `}
              <span>{UserImage.name}</span>
              <div className="text-info mt-1">format: .jpg / .jpeg / .png</div>
              <div className="imageErrorMsg text-danger">
                {(fileTypeError && fileTypeError) ||
                  errorMessage.UserImage ||
                  (!UserImage.name && 'Required')}
              </div>
            </FileInputField>

            <CustomButton buttonText="Next" />
          </form>
        </div>

        <div className="progres">
          <ul>
            <li>
              <IoMdRadioButtonOn className="icon me3 fulfilled" />
              <span className="active">Personal Information</span>
            </li>
            <hr className="z" />

            {!profileCompletionStatus?.referee && (
              <>
                <li>
                  <IoIosRadioButtonOff className="icon me3 inactive" />
                  <span className="inactive">Subscription</span>
                </li>
                <hr className="z-z" />
              </>
            )}

            <li>
              <IoIosRadioButtonOff className="icon me3 inactive" />
              <span className="inactive">Care provider Information</span>
            </li>

            {!profileCompletionStatus?.referee && (
              <>
                <hr className="z-z-z" />
                <li>
                  <IoIosRadioButtonOff className="icon me3 inactive" />
                  <span className="inactive">Payment</span>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </section>
  )
}

export default PersonalDetails
