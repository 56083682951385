import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { IoIosArrowForward } from 'react-icons/io'
import { BsArrowRight } from 'react-icons/bs'
import CustomButton from '../../../../healthBancComponents/Templates/CustomButton'
import Layout from '../../components/Layout/Layout'
import newImg1 from '../../assets/landingPageImgs/newImg1.png'
import newImg2 from '../../assets/landingPageImgs/newImg2.png'
import newImg3 from '../../assets/landingPageImgs/newImg3.png'
import backgroundPattern from '../../assets/landingPageImgs/backgroundPattern.png'
import landingPageImg1 from '../../assets/landingPageImgs/landingPageImg1.png'
import sectionImg1 from '../../assets/landingPageImgs/section-img-1.png'
import sectionImg2 from '../../assets/landingPageImgs/section-img-2.png'
import sectionImg3 from '../../assets/landingPageImgs/section-img-3.png'
import sectionImg4 from '../../assets/landingPageImgs/section-img-4.png'
import contactImg from '../../assets/landingPageImgs/contact-img.png'
import { addLocalCartItemsRequested } from '../../../../redux/healthBanc/reducer/oneDrugStore/user/cartItemsSlices'

import './landingPage.scss'

const LandingPage = () => {
  const dispatch = useDispatch()

  const isAuthUser = useSelector((state) => state.user.userInfo.isAuth)

  //Checks if user has items in cart on localstorage
  // if user is auth, send items to db
  const uploadLocalDrugCart = () => {
    const nonAuthCartItems = JSON.parse(
      window.localStorage.getItem('oneDrugCart')
    )
    if (isAuthUser && nonAuthCartItems) {
      dispatch(addLocalCartItemsRequested(nonAuthCartItems))
    }
  }

  const history = useHistory()
  useEffect(() => {
    window.scrollTo(0, 0)
    uploadLocalDrugCart()
  }, [dispatch])

  return (
    <Layout>
      <div className="oneDrug__landing__main ">
        <div className="hero container">
          <div className="content ">
            <div className="content__heading ">
              EASY ACCESS TO PRESCRIPTION DRUGS
            </div>
            <h2 className="content__description ">
              Access to medicines just got easier
            </h2>
            <p className="content__text px-1 p-sm-2">
              Looking for a convenient way to purchase your prescription and
              over-the-counter medications? OneDrugStore has you covered. We
              ensure that you get the best, quality medications at an affordable
              rate while providing easy payment and prompt delivery services.
            </p>
            <div className="cta-btn-box">
              <CustomButton onClick={() => history.push('/OneDrugStore/shop')}>
                Visit Pharmacy <IoIosArrowForward />
              </CustomButton>
            </div>
          </div>
          <div className="image-container memd-3">
            <img src={newImg1} alt="Landing Img" className="img-fluid" />
          </div>
        </div>

        {/* Services Section */}
        <div className="services ">
          <div className="services__background-image"></div>

          <div className="container">
            <h2 className="content__description text-center">
              Why use OneDrugStore?
            </h2>
          </div>

          <div className="services__content container">
            <div
              className="d-sm-flex flex-wrap justify-content-around  services__content--1"
              data-aos="fade-up"
              data-aos-offset="70"
            >
              <div className="services__image-box img-box-1 col-sm-4">
                <img src={newImg2} alt="" className="img-fluid" />
              </div>

              <div className="services__text col-sm-5">
                <h2 className="content__description sub-heading">
                  Quality Medications
                </h2>
                <p className="content_text">
                  All our medications are NAFDAC and FDA approved. As a result,
                  you never have to worry about purchasing fake and counterfeit
                  drugs.
                </p>
                {/* <button className="learn-more__btn">
                  Learn more <BsArrowRight size="30" />
                </button> */}
              </div>
            </div>

            <div
              className="d-sm-flex flex-wrap justify-content-around services__content--2"
              data-aos="fade-up"
              data-aos-offset="70"
            >
              <div className="services__image-box col-sm-4 order-md-1">
                <img src={sectionImg2} alt="" className="img-fluid" />
              </div>

              <div className="services__text col-sm-5 order-md-0">
                <h2 className="content__description sub-heading">
                  Easy Refill of Prescription Drugs
                </h2>
                <p className="content_text">
                  Avoid the hassle of going from shop to shop to search for your
                  prescription drugs. With a valid prescription, you can get all
                  your branded prescription drugs in one place.
                </p>
                {/* <button className="learn-more__btn">
                  Learn more <BsArrowRight size="30" />
                </button> */}
              </div>
            </div>

            <div
              className="d-sm-flex flex-wrap justify-content-around services__content--3"
              data-aos="fade-up"
              data-aos-offset="70"
            >
              <div className="services__image-box col-sm-4">
                <img src={sectionImg3} alt="" className="img-fluid" />
              </div>

              <div className="services__text col-sm-5">
                <h2 className="content__description sub-heading">
                  Dynamic payment options
                </h2>
                <p className="content_text">
                  Purchase all medications using your Debit card or your wallet.
                  Another exciting detail is that you can fund the wallets of
                  loved ones to enable them purchase their medications smoothly
                </p>
                {/* <button className="learn-more__btn">
                  Learn more <BsArrowRight size="30" />
                </button> */}
              </div>
            </div>

            <div
              className="d-sm-flex flex-wrap justify-content-around services__content--4"
              data-aos="fade-up"
              data-aos-offset="70"
            >
              <div className="services__image-box col-sm-4 order-md-1">
                <img src={sectionImg4} alt="" className="img-fluid" />
              </div>

              <div className="services__text col-sm-5 order-md-0">
                <h2 className="content__description sub-heading">
                  Seamless Delivery
                </h2>
                <p className="content_text">
                  Get your medications delivered to your doorstep from the
                  convenience of your mobile device.
                </p>
                {/* <button className="learn-more__btn">
                  Learn more <BsArrowRight size="30" />
                </button> */}
              </div>
            </div>
          </div>
        </div>

        {/* Contact Section */}
        <div className="contact container">
          <div className="contact__description-box mx-sm-auto d-block col-xs-12 col-md-8ms-md-5 d-md-flex">
            <div className="contact__content container col-xs-12 col-md-8ms-md-5 d-flex flex-column justify-content-center ">
              <div className="contact__header">
                ACCESS TO PHARMACIES ROUND THE CLOCK
              </div>
              <h2>Do you need to speak with a Doctor?</h2>
              <p>
                OneDrugStore offers a seamless channel to reach a medical
                professional 24/7 at ease.
              </p>
              {/* <div>
                <button className="learn-more__btn">
                  Learn more <IoIosArrowForward />
                </button>
              </div> */}
            </div>

            <div className="contact__image-box">
              <img src={newImg3} alt="" />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default LandingPage
