import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import axios from 'axios'

import ResponseToast from '../template/ResponseToast'

const removeAdminUrl = `/v2/api/BackendAdminAuth/RemoveAdmin`
const baseUrl = process.env.REACT_APP_BASE_URL

const RemoveAdmin = ({
  showRemove,
  handleCloseRemove,
  admin,
  setRefetchAdmin,
}) => {
  const [toastShow, setToastShow] = useState(false)
  const [toastError, setToastError] = useState('')
  const [remove, setRemove] = useState(false)

  // hide toast response
  const handleToastClose = () => setToastShow(false)

  const hnadleRemoveAdmin = async () => {
    try {
      setRemove(true)
      const token = localStorage.getItem('adminInfo')

      const response = await axios.post(`${baseUrl}${removeAdminUrl}`, {
        data: { Email: admin.email },
        headers: { Authorization: `Bearer ${token}` },
      })

      setRefetchAdmin(true)
      setRemove(false)
      setToastShow(true)
    } catch (error) {
      // show response toast when error occur
      setRemove(false)
      setToastShow(true)

      error.response
        ? setToastError(error.response.statusText)
        : setToastError(error.message)
    }
  }

  return (
    <>
      {' '}
      <Modal
        show={showRemove}
        onHide={handleCloseRemove}
        closeButton
        dialogClassName="modal-sz"
        className="add-admin-modal p-4"
      >
        <Modal.Body className="d-flex flex-column">
          <ResponseToast
            toastShow={toastShow}
            handleToastClose={handleToastClose}
            toastError={toastError}
          />

          <h1 className="add-admin">Remove admin</h1>
          <p className="warning-text">
            Are your sure you want to remove {admin.firstName}
            {admin.lastName}? They will no longer have access to this portal.
          </p>
        </Modal.Body>
        <Modal.Footer className="add-footer">
          <button className="btn-cancel" onClick={handleCloseRemove}>
            cancel
          </button>
          <button className="btn-send" onClick={hnadleRemoveAdmin}>
            {remove ? 'Removing' : 'Remove'}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default RemoveAdmin
