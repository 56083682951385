import React from 'react'
import { Bar } from 'react-chartjs-2'
import { Dropdown } from 'react-bootstrap'
import { FiCalendar } from 'react-icons/fi'

import './userAquisitionChart.scss'

const UserAquisitionChart = ({ data, filterByTimeFrame }) => {
  const userAcquisitions = () => {
    let chartLabels = []
    let chartData = []

    if (data.hasOwnProperty('data')) {
      chartLabels = (data.data.userAcquisitions || []).map((item) => item.name)
      chartData = (data.data.userAcquisitions || []).map((item) => item.count)
    }

    return { chartLabels, chartData }
  }

  const graphData = {
    labels: [...userAcquisitions().chartLabels],
    datasets: [
      {
        label: 'Aquisitions',
        data: [...userAcquisitions().chartData],
        // backgroundColor: ['rgba(23, 137, 252, 0.1)'],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          // '#1789FC',
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 2,
      },
    ],
  }
  return (
    <div className="healthInsured__user-aquisition-chart">
      <div className="healthInsured__user-aquisition-chart__header">
        <h2 className="healthInsured__user-aquisition-chart__header--title">
          User Aquisition
        </h2>
        <div className="form-group">
          <select
            className="form-control"
            onChange={(e) => filterByTimeFrame(Number(e.target.value))}
          >
            <option value="">This Year</option>
            <option value="1">Monthly</option>
            <option value="2">Weekly</option>
          </select>
        </div>
      </div>
      <Bar data={graphData} />
    </div>
  )
}

export default UserAquisitionChart
