import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: null,
  error: null,
}

const updateDeliveryInfoSlice = createSlice({
  name: 'updateDeliveryInfo',
  initialState,
  reducers: {
    updateDeliveryInfoRequested: (state) => {
      state.isLoading = true
      state.data = null
      state.error = null
    },

    updateDeliveryInfoSuccessful: (state, action) => {
      state.isLoading = false
      state.data = action.payload
      state.error = null
    },

    updateDeliveryInfoFailed: (state, action) => {
      state.isLoading = false
      state.data = null
      state.error = action.payload
    },
  },
})

export const {
  updateDeliveryInfoRequested,
  updateDeliveryInfoSuccessful,
  updateDeliveryInfoFailed,
} = updateDeliveryInfoSlice.actions

export default updateDeliveryInfoSlice.reducer
