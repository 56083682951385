import { takeLatest, put } from 'redux-saga/effects'
import axios from 'axios'
import { store } from '../../../../store'
import {
  updateDeliveryInfoRequested,
  updateDeliveryInfoSuccessful,
  updateDeliveryInfoFailed,
} from '../../../reducer/oneDrugStore/user/deliveryInfoSlices'

function* updatetDeliveryInfoAsync({ payload }) {
  const isAuthUser = store.getState().user.userInfo.isAuth

  try {
    if (isAuthUser) {
      yield updateDeliveryInfoRequested()
      const { data } = yield axios({
        method: 'PUT',
        url: `${process.env.REACT_APP_ONE_DRUG_BASE}/v1/api/DeliveryInformation/Update`,
        data: {
          userId: payload.values.userId,
          email: payload.values.email,
          firstName: payload.values.firstName,
          lastName: payload.values.lastName,
          phonenumber: payload.values.phoneNumber,
          streetAddress: payload.values.address,
          city: payload.values.city,
          state: payload.values.state,
          country: payload.values.country,
        },
      })
      yield put(updateDeliveryInfoSuccessful(data))
      yield put(updateDeliveryInfoSuccessful(null))
    } else {
      yield put(updateDeliveryInfoSuccessful({ ...payload.values }))
      payload.history.push('/oneDrugStore/authentication')
    }
  } catch (error) {
    if (error.response) {
      yield put(updateDeliveryInfoFailed(error.response.data))
      yield put(updateDeliveryInfoFailed(null))
      return
    }

    yield put(updateDeliveryInfoFailed(error.message))
    yield put(updateDeliveryInfoFailed(null))
  }
}

export function* watchUpdateDeliveryInfo() {
  yield takeLatest(updateDeliveryInfoRequested.type, updatetDeliveryInfoAsync)
}
