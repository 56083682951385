import {
	INCOMING_ORDER_REQUEST_ASYNC,
	OUTGOING_ORDER_REQUEST_ASYNC,
} from '../actions/actionTypes'

const INITIAL_STATE = {
	allIncomingOrderData: {
		orderDTOs: [
			{
				id: 0,
				orderNumber: '',
				companyOrderedFromId: 0,
				companyOrderingId: 0,
				companyName: '',
				companyAddress: '',
				deliveryAddress: '',
				orderStatus: '',
				superAdminEmail: '',
				products: [
					// {
					// 	id: 0,
					// 	productItemId: 0,
					// 	price: 0,
					// 	quantity: 0,
					// 	totalAmount: 0,
					// 	name: '',
					// 	batchNumber: 0,
					// 	productionDate: '',
					// 	expiryDate: '',
					// 	nafdacNumber: '',
					// 	picture: '',
					// 	activeIngredient: '',
					// 	reOrderLevel: '',
					// 	productCategory: 0,
					// 	productBrand: '',
					// 	productForm: 0,
					// 	orderId: 0,
					// },
				],
				orderTotal: 0,
				orderDate: '',
			},
		],
		currentPage: 0,
		pageCount: 0,
		pageSize: 0,
		recordCount: 0,
	},

	allOutgoingOrderData: {
		orderDTOs: [
			{
				id: 0,
				orderNumber: '',
				companyOrderedFromId: 0,
				companyOrderingId: 0,
				companyName: '',
				companyAddress: '',
				deliveryAddress: '',
				orderStatus: '',
				superAdminEmail: '',
				products: [
					{
						// id: 0,
						// productItemId: 0,
						// price: 0,
						// quantity: 0,
						// totalAmount: 0,
						// name: '',
						// batchNumber: 0,
						// productionDate: '',
						// expiryDate: '',
						// nafdacNumber: '',
						// picture: '',
						// activeIngredient: '',
						// reOrderLevel: '',
						// productCategory: 0,
						// productBrand: '',
						// productForm: 0,
						// orderId: 0,
					},
				],
				orderTotal: 0,
				orderDate: '',
			},
		],
		currentPage: 0,
		pageCount: 0,
		pageSize: 0,
		recordCount: 0,
	},
}

export const ordersReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case INCOMING_ORDER_REQUEST_ASYNC:
			return {
				...state,
				// incomingOrders: action.payload.orderDTOs,
				allIncomingOrderData: action.payload,
			}
		case OUTGOING_ORDER_REQUEST_ASYNC:
			return {
				...state,
				allOutgoingOrderData: action.payload,
			}
		default:
			return state
	}
}
