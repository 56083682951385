import React from 'react'
import { Link } from 'react-router-dom'
import CorporateDashboardStructure from '../../corporateDashboardStructure/CorporateDashboardStructure'
import CustomButton from '../../../../../healthBancComponents/Templates/CustomButton'
import './companyProfileUpdateSuccess.scss'

const CompanyProfileUpdateSuccess = () => {
  return (
    <CorporateDashboardStructure>
      <div className='success__screen text-center'>
        <h1>Success!</h1>
        <p>Your company profile has been updated successfully</p>

        <div className='justify-content-around d-md-flex'>
          <div className='p-0 mx-md-4 mb-4'>
            <Link to='/health_corporate/add_beneficiaries'>
              <CustomButton>Add Beneficiaries</CustomButton>
            </Link>
          </div>
          <div className='p-0 mx-md-4'>
            <Link to='/health_corporate/dashboard'>
              <CustomButton outline>Skip till later</CustomButton>
            </Link>
          </div>
        </div>
      </div>
    </CorporateDashboardStructure>
  )
}

export default CompanyProfileUpdateSuccess
