import React, { useState } from 'react';

import IdentifierCard from '../identifierModal/IdentifierModal';
import './uniqueidentifierCard.scss';

const UniqueidentifierCard = () => {

  const [showIdentifierModal, setShowIdentifierModal] = useState(false);

  const closeIdentifierModalHandler = () => setShowIdentifierModal(false);

  const showIdentifierModalHandler = () => {
    setShowIdentifierModal(true);
  };

  return (
    <>
      <IdentifierCard
        show={showIdentifierModal}
        close={closeIdentifierModalHandler}
      />

      <article
        className='health__Insured__admin__unique__identifier__card'
        onClick={showIdentifierModalHandler}
      >
        <div className='health__Insured__admin__unique__identifier__card--container'>
          <p className={`health__Insured__admin__unique__identifier__card--container-heading`}>
            Generate a Unique Identifier
        </p>
        </div>
      </article>
    </>
  );
};

export default UniqueidentifierCard;
