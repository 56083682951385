import {
  GET_CORPORATE_USER_FAILED,
  GET_CORPORATE_USER_REQUEST,
  GET_CORPORATE_USER_SUCCESS,
} from '../../healthInsuredTypes/corporateActionTypes'

export const getCorporateUserRequest = () => ({
  type: GET_CORPORATE_USER_REQUEST,
})

export const getCorporateUserSuccess = (userDetails) => ({
  type: GET_CORPORATE_USER_SUCCESS,
  payload: userDetails,
})

export const getCorporateUserFailed = (errorMessage) => ({
  type: GET_CORPORATE_USER_FAILED,
  payload: errorMessage,
})
