import React from 'react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import BackgroundTemplate from './Templates/BackgroundTemplate'
import CustomInput from './Templates/CustomInput'
import CustomButton from './Templates/CustomButton'

const ResendEmailLink = () => {
  const history = useHistory()

  const emailSchema = Yup.object({
    email: Yup.string().email('Invalid Email').required('Required'),
  })

  const handleResendLink = async (value, { resetForm, setSubmitting }) => {
    try {
      await axios({
        method: 'post',
        url: '/v1/api/Identity/ResendConfirmationLink',
        data: value,
      }).then((response) => {
        history.push('/signin')
      })
    } catch (error) {
      if (error.response) {
        alert(error.response.data.message)
      } else if (error.request) {
        alert('Response Error! \nTry again or contact support.')
      } else {
        alert('Something went wrong! \nTry again or contact support.')
      }
    } finally {
      setSubmitting('false')
    }
  }

  return (
    <BackgroundTemplate
      showLogo={true}
      formTitle="Resend Verification Email"
      subTitle="Enter your email to get a re-verification link"
    >
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={emailSchema}
        onSubmit={(values, { resetForm, setSubmitting }) =>
          handleResendLink(values, { resetForm, setSubmitting })
        }
      >
        {(formik) => (
          <Form>
            <Field
              as={CustomInput}
              label="Email"
              name="email"
              type="email"
              placeholder="Enter your email address"
              autoFocus
            />
            <CustomButton
              type="submit"
              disabled={formik.isSubmitting ? true : false}
            >
              {formik.isSubmitting ? 'Sending varification link...' : 'Submit'}
            </CustomButton>
          </Form>
        )}
      </Formik>
    </BackgroundTemplate>
  )
}

export default ResendEmailLink
