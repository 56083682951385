import { call, put, takeLatest } from 'redux-saga/effects'
import {
  getFamilyMembersFailed,
  getFamilyMembersRequested,
  getFamilyMembersSuccessful,
} from '../../../reducer/healthInsuredReducer/healthInsuredFamily/getFamilyMembersReducer'
import { getFamilyMembersAPI } from './familyApis'

function* handleGetFamilyMembers({ payload = {} }) {
  const { searchValue = '', pageNumber = 1 } = payload
  try {
    yield getFamilyMembersRequested()

    const { data } = yield call(getFamilyMembersAPI, {
      searchValue,
      pageNumber,
    })

    yield put(getFamilyMembersSuccessful(data))
  } catch (error) {
    if (error.response) {
      alert(error.response.data.message)

      yield put(getFamilyMembersFailed(error.response.data))
    } else {
      alert(error.message)
    }
  }
}

export function* watchGetFamilyMembers() {
  yield takeLatest(getFamilyMembersRequested.type, handleGetFamilyMembers)
}
