import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import 'react-toastify/dist/ReactToastify.css'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Field, Form } from 'formik'
import * as yup from 'yup'
import Layout from '../../../components/adminComponents/Layout/Layout'
import CustomInput from '../../../../../healthBancComponents/Templates/CustomInput'
import CustomTextarea from '../../../../../healthBancComponents/Templates/CustomTextarea'
import CustomButton from '../../../../../healthBancComponents/Templates/CustomButton'
import CustomSelect from '../../../../../healthBancComponents/Templates/CustomSelect'
import { getDrugCategoryRequested } from '../../../../../redux/healthBanc/reducer/oneDrugStore/admin/drugCategorySlice'
import { createDrugItemRequested } from '../../../../../redux/healthBanc/reducer/oneDrugStore/admin/drugItemSlices'

const CreateDrugItem = () => {
  const [drugImage, setDrugImage] = useState('')

  const dispatch = useDispatch()
  const history = useHistory()
  const { pharmacyId } = useParams()

  const { data } = useSelector((state) => state.getDrugCategory)
  const { isLoading } = useSelector((state) => state.createDrugItem)

  useEffect(() => {
    dispatch(getDrugCategoryRequested())
  }, [dispatch])

  const encodeImageFileAsURL = (file) => {
    let reader = new FileReader()
    reader.onloadend = function () {
      setDrugImage(reader.result)
    }
    reader.readAsDataURL(file)
  }

  const pharmacySchema = yup.object().shape({
    name: yup.string().required('This field is required'),
    description: yup.string(),
    quantity: yup.number(),
    price: yup.number(),
    discount: yup.number(),
    form: yup.string(),
    stock: yup.number(),
  })

  const handleCreateDrugItem = (values, actions) => {
    // before sumbit add drugCategoryId
    // Check image validation:(format, size)
    dispatch(
      createDrugItemRequested({
        formValues: {
          ...values,
          price: +values.price,
          discount: +values.discount,
          stock: +values.stock,
          drugCategoryId: +values.drugCategoryId,
          requirePrescription: JSON.parse(values.requirePrescription),
          image: drugImage,
        },
        history,
        pharmacyId,
      })
    )
  }

  return (
    <Layout canGoBack>
      <div className="mt-5 mx-4">
        <div className="mt-5">
          <h4>Create Drug Item</h4>

          <div>
            <Formik
              initialValues={{
                name: '',
                description: '',
                price: '',
                discount: '',
                drugCategoryId: '',
                stock: '',
                requirePrescription: false,
              }}
              validationSchema={pharmacySchema}
              onSubmit={(values, actions) =>
                handleCreateDrugItem(values, actions)
              }
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit}>
                  <div className="col-8 ms-4 my-4">
                    <Field
                      as={CustomInput}
                      id="name"
                      type="text"
                      label="Name"
                      name="name"
                      placeholder="Enter drug name"
                    />

                    <Field
                      as={CustomTextarea}
                      id="description"
                      type="text"
                      label="Description"
                      name="description"
                      placeholder="Brief description"
                    />

                    <div className="row g-2">
                      <div className="col">
                        <Field
                          as={CustomInput}
                          id="price"
                          type="tel"
                          label="Price"
                          name="price"
                        />
                      </div>
                      <div className="col">
                        <Field
                          as={CustomInput}
                          id="discount"
                          type="tel"
                          label="Discount"
                          name="discount"
                        />
                      </div>
                    </div>

                    <div className="row g-2">
                      <div className="col">
                        <Field
                          as={CustomSelect}
                          id="drugCategory"
                          name="drugCategoryId"
                          label="Category"
                        >
                          {(data?.data || []).map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </Field>
                      </div>

                      <div className="col">
                        <Field
                          as={CustomInput}
                          id="stock"
                          type="tel"
                          label="Stock"
                          name="stock"
                          placeholder="Number of item in stock"
                        />
                      </div>
                    </div>

                    <div className="row g-2">
                      <div className="col-6">
                        <Field
                          as={CustomSelect}
                          id="requirePrescription"
                          type="select"
                          label="Prescription Required"
                          name="requirePrescription"
                          placeholder="Number of item in stock"
                        >
                          <option value="false">No</option>
                          <option value="true">Yes</option>
                        </Field>
                      </div>
                      <div className="col" style={{ fontSize: '13px' }}>
                        <label
                          htmlFor="drug-image"
                          style={{ fontSize: '13px', fontWeight: '500' }}
                        >
                          Select Drug Image
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          style={{ fontSize: '13px', height: '40px' }}
                          onChange={(e) =>
                            encodeImageFileAsURL(e.target.files[0])
                          }
                        />
                      </div>
                    </div>

                    <div className="col-6">
                      <CustomButton type="submit" disabled={isLoading}>
                        {isLoading ? 'Creating...' : 'Create'}
                      </CustomButton>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CreateDrugItem
