import React, { useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { Formik, Field } from 'formik'
import * as yup from 'yup'
import CustomInput from '../../../../healthBancComponents/Templates/CustomInput'
import CustomButton from '../../../../healthBancComponents/Templates/CustomButton'
import { walletPaymentRequested } from '../../../../redux/healthBanc/reducer/oneDrugStore/user/walletPaymentSlices'

const WalletPaymentModal = ({ setShow, show }) => {
  const handleClose = () => setShow(false)

  const dispatch = useDispatch()

  const { data, isLoading, error } = useSelector((state) => state.walletPayment)

  useEffect(() => {
    if (error) {
      toast.error(error, { toastId: error })
      return
    }
  }, [error])

  const phoneNumber = yup.object().shape({
    phoneNumber: yup
      .string()
      .min(11, 'Phone number should not be less than 11 numbers')
      .max(13, 'Phone number should not be more than 13 numbers')
      .required('Phone number is required'),
  })

  const handleSubmit = (values) => {
    dispatch(walletPaymentRequested(values))
  }

  return (
    <div>
      <ToastContainer />
      <>
        <Modal centered show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Pay with your Sterling Wallet</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                phoneNumber: '',
              }}
              validationSchema={phoneNumber}
              onSubmit={(values, actions) => handleSubmit(values, actions)}
            >
              {(props) => (
                <form onSubmit={props.handleSubmit}>
                  <Field
                    as={CustomInput}
                    name="phoneNumber"
                    label="Phone number"
                    placeholder="Enter your phone number"
                    value={props.phoneNumber}
                    type="tel"
                  />
                  <div className="mt-2 col-xs-12 col-lg-5">
                    <CustomButton type="submit" disabled={isLoading}>
                      {isLoading ? 'Sending OTP...' : 'Send OTP'}
                    </CustomButton>
                  </div>
                </form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
      </>
    </div>
  )
}

export default WalletPaymentModal
