import React from 'react'
import brandImg1 from '../../assets/brandImgs/brandImg1.png'
import brandImg2 from '../../assets/brandImgs/brandImg2.png'
import brandImg3 from '../../assets/brandImgs/brandImg3.png'
import brandImg4 from '../../assets/brandImgs/brandImg4.png'
import brandImg5 from '../../assets/brandImgs/brandImg5.png'
import brandImg6 from '../../assets/brandImgs/brandImg6.png'
import brandImg7 from '../../assets/brandImgs/brandImg7.png'

const Brands = () => {
  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="me3">
        <img src={brandImg1} alt="brand logo" className="img-fluid" />
      </div>
      <div className="me3">
        <img src={brandImg2} alt="brand logo" className="img-fluid " />
      </div>
      <div className="me3">
        <img src={brandImg3} alt="brand logo" className="img-fluid " />
      </div>
      <div className="me3">
        <img src={brandImg4} alt="brand logo" className="img-fluid " />
      </div>
      <div className="me3">
        <img src={brandImg5} alt="brand logo" className="img-fluid " />
      </div>
      <div className="me3">
        <img src={brandImg6} alt="brand logo" className="img-fluid " />
      </div>
      <div className="me3">
        <img src={brandImg7} alt="brand logo" className="img-fluid " />
      </div>
    </div>
  )
}

export default Brands
