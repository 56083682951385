import React from 'react'
import NumberFormat from 'react-number-format'
import { FiUsers, FiCreditCard } from 'react-icons/fi'
import { RiErrorWarningLine } from 'react-icons/ri'

import './statsCard.scss'

const StatsCard = ({ data }) => {
  const { toatlRevenue, totalPayoutDue, totalUser } = data

  return (
    <section className="healthInsuredDashboard__stats">
      <div className="stats-card">
        <span className="stats-card--icon text-info">
          <FiUsers />
        </span>
        <div>
          <div className="stats-card--title">Total Users</div>
          <div className="stats-card--figure">{totalUser}</div>
        </div>
      </div>
      <div className="stats-card">
        <span className="stats-card--icon text-success">
          <FiCreditCard />
        </span>
        <div>
          <div className="stats-card--title">Total Revenue</div>
          <div className="stats-card--figure">
            &#8358;
            <NumberFormat
              value={toatlRevenue}
              displayType={'text'}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale
            />
          </div>
        </div>
      </div>
      <div className="stats-card">
        <span className="stats-card--icon text-warning">
          <RiErrorWarningLine />
        </span>
        <div>
          <div className="stats-card--title">Total Payout Due</div>
          <div className="stats-card--figure">
            &#8358;
            <NumberFormat
              value={totalPayoutDue}
              displayType={'text'}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default StatsCard
