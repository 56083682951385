import {
  PROFILE_COMPLETION_STATUS_FAIL,
  PROFILE_COMPLETION_STATUS_REQUEST,
  PROFILE_COMPLETION_STATUS_SUCCESS,
} from '../healthInsuredTypes/profileCompletionStatus'

export const profileCompletionRequest = () => ({
  type: PROFILE_COMPLETION_STATUS_REQUEST,
})

export const profileCompletionSuccess = ({ data }) => ({
  type: PROFILE_COMPLETION_STATUS_SUCCESS,
  payload: { ...data },
})

export const profileCompletionFailed = (data) => ({
  type: PROFILE_COMPLETION_STATUS_FAIL,
  payload: data,
})
