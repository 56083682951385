import {
  RESEND_CORPORATE_USER_OTP_FAILED,
  RESEND_CORPORATE_USER_OTP_REQUEST,
  RESEND_CORPORATE_USER_OTP_SUCCESS,
} from '../../../actions/healthInsuredTypes/corporateActionTypes'

export const resendCorporateOtpReducer = (state = {}, action) => {
  switch (action.type) {
    case RESEND_CORPORATE_USER_OTP_REQUEST:
      return { isLoading: true }

    case RESEND_CORPORATE_USER_OTP_SUCCESS:
      alert(action.payload.message)
      return { isLoading: false, ...action.payload }

    case RESEND_CORPORATE_USER_OTP_FAILED:
      return { isLoading: false, ...action.payload }

    default:
      return state
  }
}
