import {
  CORPORATE_PROFILE_COMPLETION_STATUS_FAILED,
  CORPORATE_PROFILE_COMPLETION_STATUS_REQUEST,
  CORPORATE_PROFILE_COMPLETION_STATUS_SUCCESS,
} from '../../../actions/healthInsuredTypes/corporateActionTypes'

const initialState = {
  isLoading: false,
  data: {},

  errorMessage: [],
}

export const corporateProfileCompletionReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case CORPORATE_PROFILE_COMPLETION_STATUS_REQUEST:
      return { isLoading: true, data: {}, errorMessage: [] }

    case CORPORATE_PROFILE_COMPLETION_STATUS_SUCCESS:
      return { isLoading: false, data: action.payload, errorMessage: [] }

    case CORPORATE_PROFILE_COMPLETION_STATUS_FAILED:
      return { isLoading: false, data: {}, errorMessage: [action.payload] }

    default:
      return state
  }
}
