import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'

import { ReactComponent as MedicalInsurance } from '../../assets/medical.svg'
import { getHealthInsuredIndividualProfileSuccess } from '../../../../../redux/healthBanc/actions/healthInsuredCreators/healthInsuredIndividualProfile'
import NavBar from '../../signup/template/header/Header'
const profileUrl = '/v2/api/Insurance/GetUserInsuranceProfile'

const Header = () => {
  const [activeStatus, setActiveStatus] = useState(true)
  const [error, setError] = useState()

  const dispatch = useDispatch()
  const { healthInsuredPlan } = useSelector(
    (state) =>
      state.profileCompletionStatus?.data?.data ??
      state.profileCompletionStatus.data ??
      null
  )

  useEffect(() => {
    ;(async () => {
      try {
        const {
          data: { data },
        } = await axios.get(profileUrl)

        setActiveStatus(data.activeStatus)
        dispatch(getHealthInsuredIndividualProfileSuccess(data))
      } catch (error) {
        error.response
          ? setError(error.response.data.message)
          : setError(error.message)
      }
    })()
  }, [])

  return (
    <header className="axa-mansard-dashboard">
      <NavBar />

      <div className="dashboard-wrapper">
        <div className="icon-container">
          <MedicalInsurance />
        </div>

        <div className="ml-3  ">
          <h1 className="title">Profile</h1>
          {healthInsuredPlan === 0 && (
            <small className="tagline">
              Your subscription is{' '}
              <span className={activeStatus ? 'text-success' : 'text-warning'}>
                {activeStatus ? 'active.' : 'inactive.'}
              </span>
            </small>
          )}
        </div>
      </div>
    </header>
  )
}

export default Header
