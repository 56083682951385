import React, { useEffect } from 'react'
import { Card } from 'react-bootstrap'

import Loading from '../../../../pharmHub/admin/components/Spinner/Spinner'

const SectionCard = ({ loading, userProfile }) => {
  useEffect(() => window.scrollTo(0, 0), [])

  if (loading) return <Loading />

  return (
    <div>
      <Card className="axa-mansard-dashboard-profile-card">
        <Card.Body className="card-dashboard">
          <div className="d-flex justify-content-between">
            <div className="info-container w-50">
              <p className="title">First name</p>
              <p className="description">{userProfile.othernames}</p>
            </div>

            <div className="w-50">
              <p className="title">Last name</p>
              <p className="description">{userProfile.surname}</p>
            </div>
          </div>

          <hr />

          <div className="d-flex justify-content-between">
            <div className="info-container w-50">
              <p className="title">Phone number</p>
              <p className="description">{userProfile.phoneNumber}</p>
            </div>

            <div className="w-50">
              <p className="title text-left">Email address</p>
              <p className="description text-left">{userProfile.email}</p>
            </div>
          </div>

          <hr />

          <div className="d-flex justify-content-between">
            <div className="w-50">
              <p className="title">Date of birth</p>
              <p className="description">
                {userProfile.dateOfBirth &&
                  userProfile.dateOfBirth.slice(0, 10)}
              </p>
            </div>
            <div className="w-50">
              <p className="title">Gender</p>
              <p className="description">{userProfile.gender}</p>
            </div>
          </div>

          <hr />

          <div className="d-flex justify-content-between">
            <div className="w-50">
              <p className="title">Marital status</p>
              <p className="description">{userProfile.maritalStatus}</p>
            </div>
            <div className="w-50">
              <p className="title">Occupation</p>
              <p className="description">{userProfile.occupation}</p>
            </div>
          </div>

          <hr />

          <div>
            <p className="title">Home address</p>
            <p className="description">{userProfile.contactAddress}</p>
          </div>

          <hr />

          <div className="d-flex justify-content-between">
            <div className="w-50">
              <p className="title">State of residence</p>
              <p className="description">{userProfile.stateOfResidence}</p>
            </div>
            <div className="w-50">
              <p className="title">Town of residence</p>
              <p className="description">{userProfile.townOfResidence}</p>
            </div>
          </div>

          <hr />

          <div>
            <p className="title">Enrolee number</p>
            <p className="description">{userProfile.enrolleeNumber}</p>
          </div>

          <hr className="mb-5" />
        </Card.Body>
      </Card>
    </div>
  )
}

export default SectionCard
