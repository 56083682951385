import React from 'react'
import { GoSearch } from 'react-icons/go'

const Search = ({
  searchSubmit,
  handleChange,
  checkName,
  searchName,
  handleCheck,
}) => {
  return (
    <div className="template-search">
      <form className="search-form d-flex " onSubmit={searchSubmit}>
        {/* <label>
          <input
            type='checkbox'
            name={checkName === 'undefine' ? 'hb-rg-checked' : checkName}
            className='check align-top'
            onClick={handleCheck}
          />
        </label> */}

        <div className="d-flex flex-column mx-3">
          <div className="dotted-child"></div>
          <div className="dotted-child"></div>
          <div className="dotted-child"></div>
        </div>

        <label className="label-search">
          <i>
            <GoSearch className="me2 search-icon" />
          </i>
          <input
            type="search"
            name={searchName === 'undefine' ? 'search' : searchName}
            className="search"
            placeholder="Search"
            onChange={handleChange}
            incremental
            autoComplete
          />
        </label>
      </form>
    </div>
  )
}

export default Search
