import { GET_REQUEST_TO_JOIN_ASYNC } from '../actions/actionTypes'

const INITIAL_STATE = {
	joinRequest: {
		paginatedListDTO: [],
		currentPage: 1,
		pageCount: 0,
		pageSize: 10,
		recordCount: 0,
	},
}

export const getRequestToJoinReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_REQUEST_TO_JOIN_ASYNC:
			return { ...state, joinRequest: action.payload }
		default:
			return state
	}
}
