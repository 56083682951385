import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: {},
  errors: [],
}

const addFriendByEmail = createSlice({
  name: 'Add_Friend',
  initialState,
  reducers: {
    ADD_FRIEND_BY_EMAIL_REQUESTED: (state) => {
      return { isLoading: true, data: {}, errors: [] }
    },

    ADD_FRIEND_BY_EMAIL_SUCCESSFUL: (state, action) => {
      return { isLoading: false, data: action.payload, errors: [] }
    },

    ADD_FRIEND_BY_EMAIL_FAILED: (state, action) => {
      return { isLoading: false, data: {}, error: [action.payload] }
    },
  },
})

export const {
  ADD_FRIEND_BY_EMAIL_SUCCESSFUL,
  ADD_FRIEND_BY_EMAIL_REQUESTED,
  ADD_FRIEND_BY_EMAIL_FAILED,
} = addFriendByEmail.actions

export default addFriendByEmail.reducer
