import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { GiMedicines } from 'react-icons/gi'
import { useQuery } from 'react-query'
import { Table } from 'react-bootstrap'
import Layout from '../../../components/adminComponents/Layout/Layout'
import productSample from '../../../assets/storeImgs/product-sample.png'
import LoadingIndicator from '../../../../../healthBancComponents/LoadingIndicator/LoadingIndicator'

import OrderBox from './order.styles'
import CustomButton from '../../../../../healthBancComponents/Templates/CustomButton'
import RejectOrderModal from './RejectOrderModal'

import { useGetOrderAdmin } from '../hooks/getOrderAdmin'

const Order = () => {
  const [show, setShow] = useState(false)

  const getOrderAdmin = useGetOrderAdmin

  const { id } = useParams()

  const { data, isLoading, isError } = useQuery(
    ['getOrdersAdmin', id],
    () => getOrderAdmin(id),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      // staleTime: 1000 * 60 * 5
    }
  )

  // const handleShow = () => setShow(true)

  const orderItems = (data?.data?.data?.orderItemDTOs || []).map((order) => {
    return (
      <tr key={order.id}>
        <td>
          <div className="d-flex align-items-center ">
            {/* <img
              src={productSample}
              alt="product-sample"
              className="product--img me-3"
            /> */}
            <div
              className="d-flex align-items-center justify-content-center h-100 me-3"
              style={{ border: '1px solid gray', borderRadius: '4px' }}
            >
              <GiMedicines size="70px" className="text-secondary" />
            </div>
            <div>
              <p className="product--name">{order.drugItemDTO.name}</p>
              <p className="product--number">{order.drugItemDTO.description}</p>
            </div>
          </div>
        </td>
        <td>₦{order.drugItemDTO.price}</td>
        <td>{order.quantity}</td>
        <td>₦{order.drugItemDTO.price * order.quantity}</td>
      </tr>
    )
  })

  return (
    <Layout canGoBack>
      <RejectOrderModal show={show} setShow={setShow} />

      {}
      <OrderBox>
        <div className="order__header">
          <h3 className="title">
            Order Number: {data?.data?.data?.orderNumber || '#'}{' '}
          </h3>
          {/* <p className="timestamp">{new Date().toLocaleString()}</p> */}
        </div>

        <div className="order__body">
          {isLoading ? (
            <LoadingIndicator />
          ) : isError ? (
            <p>Error getting orders</p>
          ) : (
            <Table hover responsive className="order__table">
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>{orderItems}</tbody>
            </Table>
          )}

          <div className="d-flex justify-content-between one-drug__order__summary mt-5">
            <div className="shipping__summary">
              <div className="shipping__method">
                <h6 className="shipping__method--title">Shipping Method</h6>
                <p className="shipping__method--text">N/A</p>
              </div>

              <div className="mt-5">
                <h6 className="shipping__method--title">Shipping address</h6>
                <p className="shipping__method--text">
                  {data?.data?.data?.deliveryAddress || 'N/A'}
                </p>
              </div>
            </div>

            <div className="order__summary col-6">
              <Table borderless>
                <tbody>
                  {/* <tr>
                    <td>Subtotal</td>
                    <td>₦0.00</td>
                  </tr> */}
                  <tr>
                    <td>Shipping</td>
                    <td>₦0.00</td>
                  </tr>
                  <tr>
                    <td>Tax</td>
                    <td>₦0.00</td>
                  </tr>
                  <tr className="order--total">
                    <td>Total</td>
                    <td>₦{data?.data?.data?.total || '0.00'}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          {/* <div className="mt-5 justify-content-end d-flex">
            <div className="col-2 me-3">
              <CustomButton outline onClick={handleShow}>
                Reject
              </CustomButton>
            </div>
            <div className="col-2">
              <CustomButton>Approve</CustomButton>
            </div>
          </div> */}
        </div>
      </OrderBox>
    </Layout>
  )
}

export default Order
