import { takeLatest, put } from '@redux-saga/core/effects'
import axios from 'axios'
// import { toast } from 'react-toastify'

import {
  walletConfirmOTPSuccessful,
  walletConfirmOTPRequested,
  walletConfirmOTPFailed,
} from '../../../reducer/oneDrugStore/user/walletPaymentSlices'

function* walletConfirmOTPAsync({ payload }) {
  try {
    yield walletConfirmOTPRequested()

    const { data } = yield axios({
      method: 'POST',
      url: `${process.env.REACT_APP_ONE_DRUG_BASE}/v1/api/WalletPayment/WalletDebit?otp=${payload.otp}`,
    })

    // yield toast.success(`OTP sent to ${payload.phoneNumber}`)

    yield put(walletConfirmOTPSuccessful(data))
    yield put(walletConfirmOTPSuccessful(null))
  } catch (error) {
    if (error.response) {
      // yield toast.error(error.response.data.responseDescription)
      yield put(walletConfirmOTPFailed(error.response.data.responseDescription))
      yield put(walletConfirmOTPFailed(null))
      return
    }

    yield put(
      walletConfirmOTPFailed('And error occured!\nTry again or contact support')
    )
    yield put(walletConfirmOTPFailed(null))
  }
}

export function* watchWalletConfirmOTP() {
  yield takeLatest(walletConfirmOTPRequested.type, walletConfirmOTPAsync)
}
