import { put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'

import {
  getHealthInsuredAdminDashboardAnalyticsFailed,
  getHealthInsuredAdminDashboardAnalyticsSuccess,
  getHealthInsuredAdminDashboardAnalyticsRequest,
} from '../../actions/healthInsuredCreators/adminUserActions/getHealthInsuredAdminDashboardAnalytics'

function* getHealthInsuredDashboardAnalytics() {
  try {
    yield put(getHealthInsuredAdminDashboardAnalyticsRequest())

    const { data } = yield axios({
      method: 'post',
      url: '/v2/api/DashboardAnalytics/HealthInsuredDashBoardAnalytics',
      headers: { Authorization: `Bearer ${localStorage.getItem('adminInfo')}` },
    }).then((response) => response)

    yield put(getHealthInsuredAdminDashboardAnalyticsSuccess(data))
  } catch (error) {
    yield put(getHealthInsuredAdminDashboardAnalyticsFailed(error.response))
  }
}

export function* watchGetHealthInsuredAdminDashboardAnalytics() {
  yield takeEvery(
    'GET_HEALTHINSURED_ADMIN_DASHBOARD_ANALYTICS',
    getHealthInsuredDashboardAnalytics
  )
}
