import {
	GET_DASHBOARD_REQUIREMENTS_ASYNC,
	CLEAR_ERROR_MESSAGE,
	VALID_ID_STATUS,
	PHARMACIST_LICENCE_STATUS,
	PREMISES_LICENCE_STATUS,
} from '../actions/actionTypes'

const INITIAL_STATE = {
	dashboardRequirements: {},
	error: null,
}

export const pharmHubAuthReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_DASHBOARD_REQUIREMENTS_ASYNC:
			return { ...state, dashboardRequirements: action.payload }

		case VALID_ID_STATUS:
			return {
				...state,
				pharmHubAuth: {
					...state.pharmHubAuth,
					validIdStatus: action.payload,
				},
			}

		case PHARMACIST_LICENCE_STATUS:
			return {
				...state,
				pharmHubAuth: {
					...state.pharmHubAuth,
					pharmacistLicenceStatus: action.payload,
				},
			}

		case PREMISES_LICENCE_STATUS:
			return {
				...state,
				pharmHubAuth: {
					...state.pharmHubAuth,
					premisesLicenceStatus: action.payload,
				},
			}

		case CLEAR_ERROR_MESSAGE:
			return { ...state, error: action.payload }

		default:
			return state
	}
}
