import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Row, Col } from 'react-bootstrap'

import CustomButton from '../../../../../healthBancComponents/Templates/CustomButton'
import CorporateDashboardStructure from '../../corporateDashboardStructure/CorporateDashboardStructure'
import StatsCards from './statsCard/StatsCards'
import ActivityLogTable from './activityLog/ActivityLogTable'
import HospitalListAndTemplate from './hospitalList/HospitalListAndTemplate'
import './dashboard.scss'

const Dashboard = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: 'GET_CORPORATE_ANALYTICS' })
  }, [dispatch])

  return (
    <CorporateDashboardStructure
      screenTitle="Dashboard"
      actionBtn={<AddBeneficiariesBtn />}
    >
      {/* User Statistics cards */}
      <div className=" mx-lg-auto w-100 dashboard">
        <StatsCards />
      </div>

      {/* Activity log table, hospital list and template */}
      <div className="container">
        <Row md={8}>
          <Col className="mb-4">
            <ActivityLogTable />
          </Col>
          <Col md={4}>
            <HospitalListAndTemplate />
          </Col>
        </Row>
      </div>
    </CorporateDashboardStructure>
  )
}

export const AddBeneficiariesBtn = () => {
  const history = useHistory()

  return (
    <div className="col-md-6 col-sm-6 col-xs-12ms-auto">
      <Link to="/health_corporate/add_beneficiaries">
        <CustomButton
          onClick={() => history.push('/health_corporate/add_beneficiaries')}
        >
          Add New Beneficiaries
        </CustomButton>
      </Link>
    </div>
  )
}

export default Dashboard
