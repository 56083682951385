import React from 'react'
import * as FileSaver from 'file-saver'

import { Button } from 'react-bootstrap'

const ExportAllToExcel = ({ apiData, fileName }) => {
  const downloadFile = () => {
    const file = new File(apiData, fileName, {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    })

    FileSaver.saveAs(file)
  }

  return (
    <Button
      variant="outline-secondary"
      onClick={downloadFile}
      disabled={apiData.length === 0 && true}
    >
      Export All
    </Button>
  )
}

export default ExportAllToExcel
