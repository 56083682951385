import {
  FETCH_CORPORATE_BENEFICIARIES_FAILED,
  FETCH_CORPORATE_BENEFICIARIES_REQUEST,
  FETCH_CORPORATE_BENEFICIARIES_SUCCESS,
} from '../../../actions/healthInsuredTypes/corporateActionTypes'

export const fetchCorporateBeneficiariesReducers = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CORPORATE_BENEFICIARIES_REQUEST:
      return { isLoading: true }

    case FETCH_CORPORATE_BENEFICIARIES_SUCCESS:
      return { isLoading: false, ...action.payload }

    case FETCH_CORPORATE_BENEFICIARIES_FAILED:
      return { isLoading: false, errorMessage: action.payload }
    default:
      return state
  }
}
