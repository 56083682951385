import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: {},
  error: null,
}

const addLocalCartItemsSlice = createSlice({
  name: 'addLocalCartItems',
  initialState,
  reducers: {
    addLocalCartItemsRequested: (state) => {
      state.isLoading = true
      state.data = {}
      state.error = null
    },

    addLocalCartItemsSuccessful: (state, action) => {
      state.isLoading = false
      state.data = action.payload
      state.error = null
    },

    addLocalCartItemsFailed: (state, action) => {
      state.isLoading = false
      state.data = {}
      state.error = action.payload
    },
  },
})

export const {
  addLocalCartItemsRequested,
  addLocalCartItemsSuccessful,
  addLocalCartItemsFailed,
} = addLocalCartItemsSlice.actions

export default addLocalCartItemsSlice.reducer
