import React from 'react'
import caloriesCheckerImg from '../../assets/followOnInstaImgs/caloriesCheckerImg.png'
import ovulationCheckerImg from '../../assets/followOnInstaImgs/ovulationCheckerImg.png'
import ovulationCheckerImg2 from '../../assets/followOnInstaImgs/ovulationCheckerImg-2.png'
import ovulationCheckerImg4 from '../../assets/followOnInstaImgs/ovulationCheckerImg4.png'

import './followOnInsta.scss'

const FollowOnInsta = () => {
  return (
    <div className="insta-follow__content">
      <h4 className="heading pt-3">
        Follow us on Instagram for News, Offers & More
      </h4>

      <div className="insta-follow__image-box d-sm-flex justify-content-sm-around text-center">
        <div className="display__img">
          <img src={ovulationCheckerImg} alt="" className="img-fluid" />
          <div className="img--text">
            <h5 className="font-weight-bold mt-3"> Prescription Refill</h5>
            <p>
              Never run out of your medications. Create a prescription refill
              list.
            </p>
          </div>
        </div>

        <div className="display__img">
          <img src={caloriesCheckerImg} alt="" className="img-fluid" />
          <div className="img--text">
            <h5 className="font-weight-bold mt-3">Calorie Checker</h5>
            <p>Estimate your calorie Consumption. Loose, Gain or add weight</p>
          </div>
        </div>

        <div className="display__img">
          <img src={ovulationCheckerImg2} alt="" className="img-fluid" />
          <div className="img--text">
            <h5 className="font-weight-bold mt-3">Drug Information</h5>
            <p>
              Learn how your drugs help improve your symptoms from a licensed
              pharmacist.
            </p>
          </div>
        </div>

        <div className="display__img">
          <img src={ovulationCheckerImg4} alt="" className="img-fluid" />
          <div className="img--text">
            <h5 className="font-weight-bold mt-3"> Ovulation Checker</h5>
            <p>
              Will I get pregnant? Fertility reproductive assesment and more
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FollowOnInsta
