import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { MdKeyboardBackspace } from 'react-icons/md'
import HealthInsuredAdminDashboardStructure from '../../HealthInsuredDashboardStructure/HealthInsuredDashboardStructure'
import PersonalInfo from '../../components/users/userProfile/PersonalInfo/PersonalInfo'
import UserTransactionLog from '../../components/users/userProfile/TransactionLog/TransactionLog'
import UserActivityLog from '../../components/users/userProfile/activityLog/ActivityLog'

import './healthInsuredUserProfile.scss'

const HealthInsuredUserProfile = () => {
  const history = useHistory()
  const extendedProfile = useSelector(
    (state) => state.getExtendedUserHealthInsuredAdminProfile
  )
  const dispatch = useDispatch()

  const userPersonalInfo = history.location.state.userPersonalInfo

  useEffect(() => {
    dispatch({
      type: 'GET_EXTENDED_HEALTHINSURED_ADMIN_PROFILE',
      payload: { email: history.location.state.userPersonalInfo.email },
    })
  }, [dispatch, history])

  return (
    <HealthInsuredAdminDashboardStructure
      title={<ScreenNameWithBackBtn screenName="User Profile" />}
    >
      <section className="healthInsuredAdmin__user-profile">
        <div className="healthInsuredAdmin__user-profile--personal-info-section">
          <PersonalInfo userPersonalInfo={extendedProfile} />
        </div>
        <div>
          <UserTransactionLog userPersonalInfo={userPersonalInfo} />
        </div>
        <div>
          <UserActivityLog userPersonalInfo={userPersonalInfo} />
        </div>
      </section>
    </HealthInsuredAdminDashboardStructure>
  )
}

export const ScreenNameWithBackBtn = ({ screenName }) => {
  const history = useHistory()

  return (
    <div className="healthInsuredAdminUserProfile__screen-title">
      <span
        className="healthInsuredAdminUserProfile__screen-title--back-btn"
        onClick={() => history.goBack()}
      >
        <MdKeyboardBackspace size="1.3em" />
      </span>
      <span>{screenName}</span>
    </div>
  )
}
export default HealthInsuredUserProfile
