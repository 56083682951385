import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: {},
  errors: [],
}

const deactivateFriendSlice = createSlice({
  name: 'deactivate_friend',
  initialState,
  reducers: {
    DEACTIVATE_FRIEND_REQUESTED: (state) => {
      return { isLoading: true, data: {}, errors: [] }
    },

    DEACTIVATE_FRIEND_SUCCESSFUL: (state, action) => {
      return { isLoading: false, data: action.payload, errors: [] }
    },

    DEACTIVATE_FRIEND_FAILED: (state, action) => {
      return { isLoading: false, data: {}, error: [action.payload] }
    },
  },
})

export const {
  DEACTIVATE_FRIEND_REQUESTED,
  DEACTIVATE_FRIEND_SUCCESSFUL,
  DEACTIVATE_FRIEND_FAILED,
} = deactivateFriendSlice.actions

export default deactivateFriendSlice.reducer
