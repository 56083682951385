import React from 'react'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'

import AOS from 'aos'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'aos/dist/aos.css'

import { store, persistor } from './redux/store'
import './axiosConfigurations'

import LoadingIndicator from './healthBancComponents/LoadingIndicator/LoadingIndicator'

import './web.config'
import './index.css'
import './styles/styles.scss'

// base import for all Sass files
import App from './App'
import * as serviceWorker from './serviceWorker'
// ..
AOS.init({
  offset: 200,
  duration: 600,
  easing: 'ease-in-out',
  delay: 30,
}) //react animation script initialization

const queryClient = new QueryClient()

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<LoadingIndicator />} persistor={persistor}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

if (module.hot) {
  module.hot.accept()
}
