import React from 'react'
import ReactHTMLTableToExcel from 'react-html-table-to-excel'

import { InputGroup, FormControl, Dropdown, Button } from 'react-bootstrap'
import { FiSearch, FiCodepen, FiLogIn } from 'react-icons/fi'
import './userFilter.scss'

const UserFilter = ({
  activeTab,
  setActiveTab,
  handleSearch,
  searchText,
  handleProviders,
}) => {
  const handleActiveTab = (e) => {
    switch (e.target.name) {
      case 'active':
        setActiveTab({
          active: { ...activeTab.active, isActive: true },
          pending: { ...activeTab.pending, isActive: false },
          inactive: { ...activeTab.inactive, isActive: false },
        })
        break

      case 'pending':
        setActiveTab({
          active: { ...activeTab.active, isActive: false },
          pending: { ...activeTab.pending, isActive: true },
          inactive: { ...activeTab.inactive, isActive: false },
        })
        break

      case 'inactive':
        setActiveTab({
          active: { ...activeTab.active, isActive: false },
          pending: { ...activeTab.pending, isActive: false },
          inactive: { ...activeTab.inactive, isActive: true },
        })
        break

      default:
        setActiveTab({
          active: { ...activeTab.active, isActive: true },
          pending: { ...activeTab.pending, isActive: false },
          inactive: { ...activeTab.inactive, isActive: false },
        })
    }
  }

  return (
    <div className="healthInsuredAdmin__users__filter">
      <div className="healthInsuredAdmin__users__status-btns--container">
        <button
          className={`healthInsuredAdmin__users__status-btns--active  ${
            activeTab.active.isActive && 'active'
          }`}
          name="active"
          onClick={handleActiveTab}
        >
          Active
        </button>
        <button
          className={`healthInsuredAdmin__users__status-btns--pending ${
            activeTab.pending.isActive && 'active'
          }`}
          name="pending"
          onClick={handleActiveTab}
        >
          Pending
        </button>
        <button
          className={`healthInsuredAdmin__users__status-btns--inactive  ${
            activeTab.inactive.isActive && 'active'
          }`}
          name="inactive"
          onClick={handleActiveTab}
        >
          Inactive
        </button>
      </div>

      <div className="healthInsuredAdmin__users--search">
        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text id="basic-addon1">
              <FiSearch />
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            placeholder="Search for users"
            aria-label="Search"
            aria-describedby="basic-addon1"
            value={searchText}
            onChange={handleSearch}
          />
        </InputGroup>

        <div className="healthInsuredAdmin__filter-by-serviceProvider">
          {/* <Dropdown>
            <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
              <span className="btn--icon">
                <FiCodepen />
              </span>{' '}
              Providers
            </Dropdown.Toggle> */}

          {/* <Dropdown.Menu> */}
          {/* <Dropdown.Item href="#">All</Dropdown.Item>
              <Dropdown.Item href="#">Hygeia</Dropdown.Item>
              <Dropdown.Item href="#">Axa Mansard</Dropdown.Item> */}

          {/* <Dropdown.Item onClick={(value) => handleProviders(0)}>
                All
              </Dropdown.Item>
              <Dropdown.Item onClick={(value) => handleProviders(1)}>
                Hygeia
              </Dropdown.Item>
              <Dropdown.Item onClick={(value) => handleProviders(2)}>
                Axa Mansard
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}

          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>
                <span className="btn--icon">
                  <FiCodepen />
                </span>
              </InputGroup.Text>
            </InputGroup.Prepend>
            <select
              className="dropdown-toggle bg-light btn-outline-secondary form-control"
              onChange={(e) => handleProviders(Number(e.target.value))}
            >
              <option value="">Providers</option>
              <option value="0">All</option>
              <option value="1">Hygeia</option>
              <option value="2">Axa Mansard</option>
            </select>
          </InputGroup>
        </div>

        <div className="healthInsuredAdmin__export-users">
          {/* <Button
            variant="outline-secondary"
            className="healthInsuredAdmin__export-users--btn"
          >
            <span className="btn--icon">
              <FiLogIn />
            </span>
            Export
          </Button> */}

          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button btn btn-outline-secondary healthInsuredAdmin__export-users--btn"
            table="admin_users_table-to-xls"
            filename="admin_users_table"
            sheet="tablexls"
            buttonText="Export as Excel"
          />
        </div>
      </div>
    </div>
  )
}

export default UserFilter
