import React, { useState, useReducer } from 'react'


export const PayContext = React.createContext();

const initState = {
    payWithWallet: false,
}
//const changeState = () => setPayWithWallet(true)

const PayReducer = (state,action) => {
    switch(action.type) {
        case 'PAY_WALLET':
            return {
                payWithWallet: true
            }

        case 'PAY_CARD':
            return {
                payWithWallet: false
            }
            
            default:
                return state
    }
}

const PayContextProvider = (props) => {
    // const [  payWithWallet, setPayWithWallet] = useState(false);
    const [payWallet, dispatch] = useReducer(PayReducer, initState)
    
    return (
<PayContext.Provider value={{payWallet, dispatch}}>
            {props.children}
        </PayContext.Provider>
    );
}

export default PayContextProvider;
