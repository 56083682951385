import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: null,
  error: null,
}

const walletConfirmOTP = createSlice({
  name: 'walletConfirmOTP',
  initialState,
  reducers: {
    walletConfirmOTPRequested: (state) => {
      state.isLoading = true
      state.data = null
      state.error = null
    },
    walletConfirmOTPSuccessful: (state, action) => {
      state.isLoading = false
      state.data = action.payload
      state.error = null
    },
    walletConfirmOTPFailed: (state, action) => {
      state.isLoading = false
      state.data = null
      state.error = action.payload
    },
  },
})

export const {
  walletConfirmOTPRequested,
  walletConfirmOTPSuccessful,
  walletConfirmOTPFailed,
} = walletConfirmOTP.actions

export default walletConfirmOTP.reducer
