import React from "react";

const Spinner = ({ css }) => {
  return (
    <div className={`${css} spinner`}>
      <div className="spinner-border text-danger spin" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};



export default Spinner;

Spinner.defaultProps = {
  css: 'position-relative'
}
