import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Field, Formik, Form } from 'formik'
import * as yup from 'yup'
import { Button, Modal } from 'react-bootstrap'
import CustomButton from '../../../../../../healthBancComponents/Templates/CustomButton'
import CustomInput from '../../../../../../healthBancComponents/Templates/CustomInput'
import { ADD_FRIEND_BY_EMAIL_REQUESTED } from '../../../../../../redux/healthBanc/reducer/healthInsuredReducer/addFriendByEmailReducer'

function AddFriendByEmail({ show, setShow, handleClose, handleShow }) {
  const serviceUsed = useSelector(
    (state) => state.profileCompletionStatus.data?.serviceUsed
  )
  const addFriendByEmailStatus = useSelector((state) => state.addFriendByEmail)

  const dispatch = useDispatch()
  const emailSchema = yup.object().shape({
    email: yup.string().email().required('Required'),
  })

  useEffect(() => {
    !addFriendByEmailStatus.isLoading && handleClose()
  }, [addFriendByEmailStatus])

  const handleAddFriendByEmail = (values) => {
    dispatch(
      ADD_FRIEND_BY_EMAIL_REQUESTED({
        values: { ...values, insuranceService: serviceUsed },
      })
    )
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* <Modal.Header closeButton>
          <Modal.Title>Modal title</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <Formik
            initialValues={{ email: '' }}
            validationSchema={emailSchema}
            onSubmit={(values) => handleAddFriendByEmail(values)}
          >
            {(props) => (
              <Form>
                <Field
                  as={CustomInput}
                  name="email"
                  type="email"
                  placeholder="friend@example.com"
                  label="Friend's Email Address"
                />
                <div className="d-flex justify-content-endms-auto col-md-8 m-0 p-0">
                  <Button
                    variant="outline-secondary"
                    className="me3"
                    onClick={handleClose}
                    type="button"
                  >
                    Cancel
                  </Button>
                  <CustomButton
                    type="submit"
                    disabled={addFriendByEmailStatus.isLoading && true}
                  >
                    {addFriendByEmailStatus.isLoading
                      ? 'Adding Friend...'
                      : 'Add Friend'}
                  </CustomButton>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AddFriendByEmail
