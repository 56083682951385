import React from 'react'
import { Card } from 'react-bootstrap';
import icon from '../../assets/hmoIcon.svg';


const HmoCard = ({ title, message }) => {
  return (
    <article className='hmo-card col-12 mx-auto col-md-6 col-lg-4 my-2'>
      <Card className='card'>
        <Card.Body>
          <div className='icon'></div>
          <h2 className='label'>{title}</h2>
          <p className='our-quick-to'>
            {message}
          </p>
        </Card.Body>
      </Card>
    </article>
  );
};

export default HmoCard;
