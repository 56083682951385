import { put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import {
  fetchActiveCorporateBeneficiariesFailed,
  fetchActiveCorporateBeneficiariesRequest,
  fetchActiveCorporateBeneficiariesSuccess,
} from '../../actions/healthInsuredCreators/corporateUserActions/fetchCorporateBeneficiariesActions'

function* fetchCorporateBeneficiaries(payload) {
  const {
    status = 0,
    searchText = '',
    sortBy = 0,
    pageNumber = 1,
  } = payload.payload

  yield put(fetchActiveCorporateBeneficiariesRequest())

  try {
    const { data } = yield axios
      .post(`/v2/api/CorporateInsurance/GetCompanyBeneficiaries`, {
        data: {
          Status: status,
          SearchText: searchText,
          SortBy: sortBy,
          PageNumber: pageNumber,
        },
      })
      .then((response) => response)

    yield put(fetchActiveCorporateBeneficiariesSuccess(data))
  } catch (error) {
    yield put(fetchActiveCorporateBeneficiariesFailed(error.response))
  }
}

export function* watchFetchCorporateBeneficiaries() {
  yield takeEvery('FETCH_CORPORATE_BENEFICIARIES', fetchCorporateBeneficiaries)
}
