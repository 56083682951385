import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: {},
  error: null,
}

const createFamilyProfileSlice = createSlice({
  name: 'createFamilyProfile',
  initialState,
  reducers: {
    createFamilyProfileRequested: (state) => {
      return { isLoading: true, data: {}, error: null }
    },

    createFamilyProfileSuccess: (state, action) => {
      return { isLoading: false, data: { ...action.payload }, error: null }
    },

    createFamilyProfileFailed: (state, action) => {
      return { isLoading: false, data: {}, error: { ...action.payload } }
    },
  },
})

export const {
  createFamilyProfileRequested,
  createFamilyProfileSuccess,
  createFamilyProfileFailed,
} = createFamilyProfileSlice.actions

export default createFamilyProfileSlice.reducer
