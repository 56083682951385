import React from 'react';
import HealthInsuredDashboardStructure from '../../../../HealthInsuredDashboardStructure/HealthInsuredDashboardStructure';
import MainSection from '../../../../components/providers/services/axamansard/MainSection';

const Providers = () => {
  return (
    <HealthInsuredDashboardStructure title='Axa Mansard Dashboard'>
      <MainSection />
    </HealthInsuredDashboardStructure>
  );
};

export default Providers;