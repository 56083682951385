import {
  MOVE_INACTIVE_BENEFICIARIES_TO_PENDING_LIST_FAILED,
  MOVE_INACTIVE_BENEFICIARIES_TO_PENDING_LIST_REQUEST,
  MOVE_INACTIVE_BENEFICIARIES_TO_PENDING_LIST_SUCCESS,
} from '../../healthInsuredTypes/corporateActionTypes'

export const moveInactiveBeneficiariesToPendingListRequest = () => ({
  type: MOVE_INACTIVE_BENEFICIARIES_TO_PENDING_LIST_REQUEST,
})

export const moveInactiveBeneficiariesToPendingListSuccess = (payload) => ({
  type: MOVE_INACTIVE_BENEFICIARIES_TO_PENDING_LIST_SUCCESS,
  payload,
})

export const moveInactiveBeneficiariesToPendingListFailed = (errorMessage) => ({
  type: MOVE_INACTIVE_BENEFICIARIES_TO_PENDING_LIST_FAILED,
  payload: errorMessage,
})
