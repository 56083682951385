import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: {},
  error: null,
}

const createAggregatorSlice = createSlice({
  name: 'createAggregator',
  initialState,
  reducers: {
    createAggregatorRequested: (state) => {
      state.isLoading = true
      state.data = {}
      state.error = null
    },

    createAggregatorSuccessful: (state, action) => {
      state.isLoading = false
      state.data = action.payload
      state.error = null
    },

    createAggregatorFailed: (state, action) => {
      state.isLoading = false
      state.data = {}
      state.error = action.payload
    },
  },
})

export const {
  createAggregatorRequested,
  createAggregatorSuccessful,
  createAggregatorFailed,
} = createAggregatorSlice.actions
export default createAggregatorSlice.reducer
