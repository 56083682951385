import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'

import { FiSearch } from 'react-icons/fi'
import { Table, Button } from 'react-bootstrap'

import Navbar from '../healthInsuredIndividualFlow/profileDashboard/navbar/NavBar'
import Header from '../healthInsuredIndividualFlow/profileDashboard/header/Header'
import CustomButton from '../../../healthBancComponents/Templates/CustomButton'
import PaginationTemplate from '../../../healthBancComponents/Templates/PaginationTemplate/PaginationTemplate'

import { getFamilyMembersRequested } from '../../../redux/healthBanc/reducer/healthInsuredReducer/healthInsuredFamily/getFamilyMembersReducer'
import { removeFamilyMemberRequested } from '../../../redux/healthBanc/reducer/healthInsuredReducer/healthInsuredFamily/removeFamilyMemberReducer'
import { DEACTIVATE_FAMILY_MEMBER_REQUESTED } from '../../../redux/healthBanc/reducer/healthInsuredReducer/healthInsuredFamily/deactivateFamilyMemberReducer'
import { ACTIVATE_FAMILY_MEMBER_REQUESTED } from '../../../redux/healthBanc/reducer/healthInsuredReducer/healthInsuredFamily/activateFamilyMemberReducer'

import ConfirmModal from './ConfirmActionModal'

import './_healthInsuredFamily.scss'

const HealthInsuredFamily = () => {
  const [show, setShow] = useState(false)
  const [id, setId] = useState(null)
  const [action, setAction] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [currentPage, setCurrentPage] = useState(1)

  const handleShow = (action, id) => {
    setShow(true)
    setId(id)
    setAction(action)
  }

  const dispatch = useDispatch()
  const history = useHistory()

  const { pathname } = history.location

  const familyMembers = useSelector((state) => state.getFamilyMembers)
  const { tokenizationCompleted } = useSelector(
    (state) => state.profileCompletionStatus.data
  )

  const activateFamilyMemberStatus = useSelector(
    (state) => state.activateFamilyMember
  )
  const deactivateFamilyMemberStatus = useSelector(
    (state) => state.deactivateFamilyMember
  )

  useEffect(() => {
    dispatch(
      getFamilyMembersRequested({ searchValue, pageNumber: currentPage })
    )
    dispatch({ type: 'PROFILE_COMPLETION_STATUS' })
  }, [
    dispatch,
    searchValue,
    currentPage,
    activateFamilyMemberStatus,
    deactivateFamilyMemberStatus,
  ])

  const handleRemoveFamilyMember = (id) => {
    dispatch(removeFamilyMemberRequested(id))
    setShow(false)
  }

  const handleActivateFamilyMember = (id) => {
    dispatch(ACTIVATE_FAMILY_MEMBER_REQUESTED(id))
    setShow(false)
  }

  const handleDeactivateFamilyMember = (id) => {
    dispatch(DEACTIVATE_FAMILY_MEMBER_REQUESTED(id))
    setShow(false)
  }

  const displayMembers = (familyMembers.data?.data?.data || []).map(
    (member, index) => {
      return (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>
            <Link
              to={{
                pathname: `/health-family/${member.surname}`,
                state: { memberId: member.id },
              }}
            >
              <td>
                {member.surname}, {member.othernames}
              </td>
            </Link>
          </td>
          <td>{member.careProviderName || 'Unavailable'}</td>
          {/* <td>{member.cpAddress || 'Unavailable'}</td> */}
          <td>{member.transId || 'Unavailable'}</td>
          <td
            className={`${
              member.subscriptionStatus ? 'text-success' : 'text-danger'
            }`}
          >
            <strong>
              {' '}
              {member.subscriptionStatus ? 'Active' : 'Inactive'}
            </strong>
          </td>
          <td>
            {tokenizationCompleted ? (
              <div>
                {member.subscriptionStatus && member.activeStatus ? (
                  <Button
                    size="sm"
                    variant="danger"
                    onClick={() => handleShow('deactivate', member.id)}
                  >
                    Deactivate
                  </Button>
                ) : (
                  <>
                    <Button
                      size="sm"
                      variant="success"
                      className="me2"
                      onClick={() => handleShow('activate', member.id)}
                    >
                      Activate
                    </Button>
                    {member.activeStatus === null &&
                      member.subscriptionStatus === false && (
                        <Button
                          size="sm"
                          variant="danger"
                          onClick={() => handleShow('remove', member.id)}
                        >
                          Remove
                        </Button>
                      )}
                  </>
                )}
              </div>
            ) : (
              <Button
                size="sm"
                variant="danger"
                onClick={() => handleShow('remove', member.id)}
              >
                Remove
              </Button>
            )}
          </td>
        </tr>
      )
    }
  )

  const handleGoToNextPage = () => {
    const { pageCount, pageNumber } = familyMembers.data?.data

    if (pageNumber < pageCount) {
      setCurrentPage(pageNumber + 1)
    }
  }

  const handleGoToPrevPage = () => {
    const { pageNumber } = familyMembers.data?.data
    if (pageNumber > 1) {
      setCurrentPage(pageNumber - 1)
    }
  }

  return (
    <>
      <Header />

      <div className="family-dashboard">
        <div className="container">
          <Navbar pathname={pathname} />
        </div>

        <div className="family-dashboard__content container px-sm-2 px-md-5 pb-4">
          {/* <button className="back-btn">&#8592; Go Back</button> */}
          <div className="family-dashboard__heading">
            {/* <h1>{pathname === '/health-friend' ? 'Friends' : 'Family'}</h1> */}
            <h1>Members</h1>

            <div className="family-dashboard__cta">
              <div className="search__input">
                <FiSearch />
                <input
                  type="text"
                  placeholder="Search"
                  onChange={(e) => setSearchValue(e.target.value)}
                ></input>
              </div>
              <div className="add-member-btn">
                <button
                  type="button"
                  onClick={() => history.push('/add-family-member')}
                >
                  &#43; Add new beneficiary
                </button>
              </div>
            </div>
          </div>

          <div className="members-table table-responsive">
            <Table striped hover>
              <thead>
                <tr>
                  <th>s/n</th>
                  <th>Name</th>
                  <th>Provider's Name</th>
                  <th>Enrollee Number</th>
                  <th>Status</th>
                  <th>Take Action</th>
                </tr>
              </thead>
              <tbody>{displayMembers}</tbody>
            </Table>
          </div>
          <div className="d-flex justify-content-end mt-5">
            <PaginationTemplate
              prev={handleGoToPrevPage}
              next={handleGoToNextPage}
              pageDetails={familyMembers.data.data}
            />
          </div>

          <div className="d-flex proceed-btn-box">
            {!tokenizationCompleted && (
              <CustomButton
                disabled={familyMembers.data?.data?.data.length === 0}
                onClick={() => history.push('/health_tokenization_payment')}
              >
                Proceed to payment
              </CustomButton>
            )}
          </div>
        </div>
      </div>

      <ConfirmModal
        setShow={setShow}
        show={show}
        id={id}
        action={action}
        removeItem={handleRemoveFamilyMember}
        activateItem={handleActivateFamilyMember}
        deactivateItem={handleDeactivateFamilyMember}
      />
    </>
  )
}

export default HealthInsuredFamily
