import { format } from 'date-fns'
/**
 *
 * @param {*} userDetails
 * @description validate provider's details
 * @returns {false} if there is no validation error
 */

function personalInfoValidator(healthInsuredSignupDetails) {
  /**validation errors */
  const error = {
    DateOfBirthError: '',
    GenderError: '',
    MaritalStatusError: '',
    OccupationError: '',
    StateOfResidenceError: '',
    ContactAddressError: '',
    TownOfResidenceError: '',
    CareProviderNameError: '',
    CPPhoneError: '',
    CPEmailError: '',
    CPAddressError: '',
    CPCityError: '',
    UserImage: '',
  }

  // /**form state */
  const {
    DateOfBirth,
    Gender,
    MaritalStatus,
    Occupation,
    ContactAddress,
    StateOfResidence,
    TownOfResidence,
    UserImage,
  } = healthInsuredSignupDetails

  /**customer's age, year and month of birth */
  const currentYear = Number(format(new Date(), 'yyyy-MM-dd').slice(0, 4))
  const yearOfBirth = Number(DateOfBirth.slice(0, 4))
  const age = currentYear - yearOfBirth

  if (DateOfBirth === '') {
    error.DateOfBirthError = 'date of birth is required'
    return error
  }

  if (new Date(DateOfBirth) > new Date()) {
    error.DateOfBirthError = 'You cannot choose a future date'
    return error
  }

  if (DateOfBirth.split('-')[0].length > 4) {
    error.DateOfBirthError = 'year not valid'
    return error
  }

  if (DateOfBirth === format(new Date(), 'yyyy-MM-dd')) {
    error.DateOfBirthError = 'date of birth not valid'
    return error
  }

  if (age > 60) {
    error.DateOfBirthError = 'not within the age range of 1-60'
    return error
  }

  if (Gender === '') {
    error.GenderError = 'please select your gender'
    return error
  }

  if (MaritalStatus === '') {
    error.MaritalStatusError = 'please select your marital status'
    return error
  }

  if (Occupation === '') {
    error.OccupationError = 'occupation is required'
    return error
  }

  if (ContactAddress === '') {
    error.ContactAddressError = 'home address is required'
    return error
  }

  if (StateOfResidence === '') {
    error.StateOfResidenceError = 'state of residence is required'
    return error
  }

  if (TownOfResidence === '') {
    error.TownOfResidenceError = 'town of residence is required'
    return error
  }

  if (TownOfResidence === 'No service provider in this state') {
    error.TownOfResidenceError = 'choose another state'
    return error
  }

  if (!UserImage.name || UserImage.size) {
    if (!UserImage) {
      error.UserImage = '*Photo is required!'
      return error
    }

    if (UserImage.size > 2097152) {
      error.UserImage = '*Photo must be 2MB or less'
      return error
    }
  }

  return false
}

export default personalInfoValidator
