import React from 'react'

const Hero = ({ checkCustomerState, loading }) => {
  return (
    <section className="axa-mansard-hero d-flex flex-column justify-content-center">
      <h1 className="attain-a-better px-3">Secure your health, on a budget.</h1>
      <p className="enjoy-flexible px-3">
        Enjoy right-on-budget healthcare plans that saves the day when you need
        it <br />
        most. Securing your health should not pose a financial burden.
      </p>
      <button
        className="start-your mx-auto d-block"
        onClick={checkCustomerState}
        disabled={loading ? true : false}
      >
        {loading ? 'Starting...' : 'Enjoy your first month, for free.'}
      </button>
    </section>
  )
}

export default Hero
