import styled from 'styled-components'

const OrderBox = styled.div`
  margin: 20px 2rem;

  .title {
    font-size: 20px;
    font-weight: bold;
    color: #666666;
  }

  .timestamp {
    font-size: 12px;
    color: #666666;
  }

  .order__body {
    background-color: #fff;
    padding: 3rem;

    .order__table {
      tr {
        height: 5rem;

        .product--img {
          border: 1px solid lightgray;
          height: 70px;
          width: 70px;
          object-fit: cover;
          border-radius: 50%;
        }

        .product--name {
          font-size: 12px;
          font-weight: bold;
          margin: 0;
        }

        .product--number {
          font-size: 11px;
          margin: 0;
        }
      }
    }

    .one-drug__order__summary {
      .shipping__summary {
        .shipping__method {
          &--title {
            font-size: 14px;
            font-weight: 600;
            color: #444444;
          }

          &--text {
            font-size: 14px;
            color: #666666;
          }
        }
      }
      .order__summary {
        background-color: #f9f9f8;
        font-size: 14px;
        color: #666666;

        .order--total {
          color: #444444;
          font-weight: 600;
        }
      }
    }
  }
`

export default OrderBox
