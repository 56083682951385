import React, { useEffect } from 'react'
import { useLocation, useHistory } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { Container, Nav, Navbar, NavDropdown, Dropdown } from 'react-bootstrap'
// import { FiSearch } from 'react-icons/fi'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import logo from '../../assets/logo.svg'
import loginLock from '../../assets/login_lock.svg'
import avatar from '../../assets/avatar.png'
import './navbar.scss'
import { getCartItemsRequested } from '../../../../redux/healthBanc/reducer/oneDrugStore/user/cartItemsSlices'
import { getAllDrugItemRequested } from '../../../../redux/healthBanc/reducer/oneDrugStore/user/drugItemsSlices'

const NavbarComp = () => {
  const { pathname } = useLocation()

  const history = useHistory()
  const dispatch = useDispatch()

  const { data: cartItems, isLoading: isLoadingCartItems } = useSelector(
    (state) => state.getCartItems
  )
  const { isLoading: isLoadingDrugItems } = useSelector(
    (state) => state.getAllDrugItem
  )

  const isAuthUser = useSelector((state) => state.user.userInfo.isAuth)

  useEffect(() => {
    dispatch(getCartItemsRequested())
    dispatch(getAllDrugItemRequested())
  }, [dispatch])

  const handleLogout = () => {
    dispatch({ type: 'LOGOUT' })
    history.push('/signin')
  }

  return (
    <Navbar expand="lg" className="oneDrugNav mb-3">
      <Container>
        <Navbar.Brand href="/OneDrugStore">
          <div className="logo-box">
            <img src={logo} alt="one drug store logo" />
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="d-lg-flex justify-content-lg-end"
        >
          <Nav className="nav-btns">
            {pathname !== '/OneDrugStore' || isAuthUser ? (
              <>
                <Nav.Item>
                  <button
                    className="nav-cart"
                    onClick={() => history.push('/OneDrugStore/cart')}
                  >
                    <AiOutlineShoppingCart />
                    {isLoadingCartItems || isLoadingDrugItems ? null : (
                      <div className="nav-cart__item-counter">
                        {cartItems.length}
                      </div>
                    )}
                  </button>
                </Nav.Item>
                <Nav.Item className="d-flex">
                  <Dropdown>
                    <Dropdown.Toggle
                      id="dropdown-custom-components"
                      className="p-0 d-flex  align-items-center"
                      variant="none"
                    >
                      <span className="nav-avatar">
                        <img src={avatar} alt="avatar" />
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu id="basic-nav-dropdown">
                      {isAuthUser ? (
                        <>
                          <NavDropdown.Item
                            onClick={() => history.push('/dashboard')}
                          >
                            Dashboard
                          </NavDropdown.Item>
                          <NavDropdown.Item onClick={handleLogout}>
                            Logout
                          </NavDropdown.Item>
                        </>
                      ) : (
                        <>
                          <NavDropdown.Item
                            onClick={() => history.push('/signin')}
                          >
                            Login
                          </NavDropdown.Item>
                        </>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav.Item>
              </>
            ) : (
              <>
                <Nav.Item>
                  <button
                    className="nav-btns--btn signin text-center"
                    onClick={() => history.push('/signin')}
                  >
                    <img src={loginLock} alt="" className="img-fluid" />
                    Login
                  </button>
                </Nav.Item>
                <Nav.Item>
                  <button
                    className="nav-btns--btn signup"
                    onClick={() => history.push('/signup')}
                  >
                    Sign Up
                  </button>
                </Nav.Item>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default NavbarComp
