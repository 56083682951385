import {
  GET_HEALTHINSURED_ADMIN_USER_ACTIVITY_LOG_FAILED,
  GET_HEALTHINSURED_ADMIN_USER_ACTIVITY_LOG_REQUEST,
  GET_HEALTHINSURED_ADMIN_USER_ACTIVITY_LOG_SUCCESS,
} from '../../healthInsuredTypes/healthInsuredAdminActionTypes'

export const getHealthInsuredAdminUserActivityLogsRequest = () => ({
  type: GET_HEALTHINSURED_ADMIN_USER_ACTIVITY_LOG_REQUEST,
})

export const getHealthInsuredAdminUserActivityLogsSuccess = (logs) => ({
  type: GET_HEALTHINSURED_ADMIN_USER_ACTIVITY_LOG_SUCCESS,
  payload: logs,
})

export const getHealthInsuredAdminUserActivityLogsFailed = (errorMsg) => ({
  type: GET_HEALTHINSURED_ADMIN_USER_ACTIVITY_LOG_FAILED,
  payload: errorMsg,
})
