import { takeEvery, put } from 'redux-saga/effects'
import axios from 'axios'

import {
  profileCompletionRequest,
  profileCompletionSuccess,
  profileCompletionFailed,
} from '../../actions/healthInsuredCreators/profileCompletionStatus'

function* profileCompletionStatus() {
  try {
    yield profileCompletionRequest()

    const { data } = yield axios({
      method: 'GET',
      url: '/v2/api/Insurance/GetProfileCompletion',
    })

    yield put(profileCompletionSuccess(data))
  } catch (error) {
    yield put(profileCompletionFailed(error.response))
  }
}

export function* watchProfileCompletionStatus() {
  yield takeEvery('PROFILE_COMPLETION_STATUS', profileCompletionStatus)
}
