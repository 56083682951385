import {
	SAVE_BUSINESS_INFO_ASYNC,
	SAVE_BUSINESS_CREDENTIALS_ASYNC,
} from '../actions/actionTypes'

const INITIAL_STATE = {}

export const openAccountReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SAVE_BUSINESS_INFO_ASYNC:
			return { ...state, ...action.payload }
		case SAVE_BUSINESS_CREDENTIALS_ASYNC:
			return { ...state, ...action.payload }
		default:
			return state
	}
}
