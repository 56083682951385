import React from 'react'
import { withRouter } from 'react-router-dom'
import { IoIosArrowDropright } from 'react-icons/io'
import styled from 'styled-components'
import { Button as CustomButtonStyle } from '../../Templates/CustomButton'

// import serviceImg from '../../../assets/healthBancLandingPageImgs/oneDrugStoreImg.png'

import serviceImg from '../../../healthBancServices/oneDrugStore/assets/landingPageImgs/newImg1.png'

const Img = styled.div`
  img {
    max-height: 392px;
    object-fit: cover;
    object-position: 50% 10%;
  }
`

export const GetStartedBtn = styled(CustomButtonStyle)`
  background-color: #d71e1b;
  height: 51px;
  width: 153px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #d71e1b;
  box-sizing: border-box;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
`

export const LearnMoreBtn = styled.button`
  color: #d71e1b;
  border: none;
  background-color: inherit;
  font-weight: 500;
  font-size: 16px;
`

// const AnotherServiceContainer = styled.div`
//     /* margin-top: 200px; */
// `

const PHDesc = styled.div`
  height: 400px;
  /* max-width: 500px; */
  background-size: contain;
  background-position: right;

  div.features {
    display: inline-block;
    font-size: 14px;
    color: #d71e1b;
    margin: 10px 10px;
    background: rgba(215, 30, 27, 0.05);
    padding: 8px;
    border-radius: 3px;
  }

  p.heading {
    font-size: 28px;
    font-weight: bold;
    color: #0c162c;
    max-width: 450px;
    line-height: 130%;
  }

  p.desc {
    max-width: 390px;
    font-size: 16px;
    line-height: 150%;
    color: #474747;
  }

  /* .coming__soon {
		font-weight: 700;
		margin-right: 20px;
	} */
`

const OneDrugStore = ({ history }) => {
  return (
    <div className="d-flex flex-wrap justify-content-around">
      <Img className="col-sm-12 col-md-6 order-md-1 order-sm-2">
        <img src={serviceImg} alt="HealthInsuredImg" className="img-fluid" />
      </Img>

      <div className="col-sm-12 col-md-6 ps-md-5 d-flex flex-column order-2 order-sm-1">
        <PHDesc className="ms-sm-3">
          <div className="features">Easy Access to Prescription Drugs</div>
          <p className="heading mt-4">Access to medicines just got easier</p>
          <div></div>
          <p className="desc mt-4">
            We ensure that you get the best, quality medications at an
            affordable rate while providing easy payment and prompt delivery
            services.
          </p>

          <div className="d-flex align-items-center">
            {/* <div className='coming__soon text-secondary'>
							Coming soon!
						</div> */}
            {/* <GetStartedBtn
              onClick={() => history.push('/signup')}
              className="me-2"
            >
              {' '}
              Get Started
            </GetStartedBtn> */}

            <LearnMoreBtn
              className="pe-2"
              onClick={() => history.push('/OneDrugStore')}
            >
              Learn More <IoIosArrowDropright style={{ fontSize: 25 }} />
            </LearnMoreBtn>

            {/* <LearnMoreBtn className='learnMore d-flexms-3 text-secondary'> */}

            {/* </LearnMoreBtn> */}
          </div>
        </PHDesc>
      </div>
    </div>
  )
}

export default withRouter(OneDrugStore)
