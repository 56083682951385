import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { config } from 'dotenv'

import Header from '../../dashboard/Header'
import Sidebar from '../../dashboard/Sidebar'
import MainSection from './MainSection'
import ResponseModal from '../../../../healthBancServices/pharmHub/admin/components/responseModal/ResponseModal'

const url = `/v1/api/Notification/GetAllNotification?Status=2`
const servicesUrl = '/v2/api/DashboardAnalytics/GetServices'
const trashUrl = '/v1/api/Notification/TrashNotification'
const sendNotificationUrl = '/v1/api/Notification/ChangeNotificationStatus'
const baseUrl = process.env.REACT_APP_BASE_URL

const Draft = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [show, setShow] = useState(false)
  const [services, setServices] = useState([])
  const [draftNotifications, setDraftNotification] = useState([])
  const [pageCount, setPageCount] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [recordCount, setRecordCount] = useState(0)
  const [token, setToken] = useState('')
  const [searchText, setSearchText] = useState('')
  const [toastError, setToastError] = useState('')
  const [toastShow, setToastShow] = useState(false)

  const history = useHistory() //instanciate history object
  config() //instanciate environmental variables

  // fetch draft notification on load
  useEffect(() => {
    const token = localStorage.getItem('adminInfo')
    setToken(token)
    setLoading(true)

    function getUsers() {
      return axios.get(`${baseUrl}${url}&PageSize=${pageSize}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
    }

    function getSerices() {
      return axios.get(`${baseUrl}${servicesUrl}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
    }

    axios
      .all([getUsers(), getSerices()])
      .then(
        axios.spread((notifications, platformServices) => {
          const draftNotifications = notifications.data.data.data
          const pagecount = notifications.data.data.pageCount
          const pagenumber = notifications.data.data.pageNumber
          const pagesize = notifications.data.data.pageSize
          const recordcount = notifications.data.data.recordCount
          const services = platformServices.data.data

          setServices(services)
          setDraftNotification(draftNotifications)
          setPageCount(pagecount)
          setPageNumber(pagenumber)
          setPageSize(pagesize)
          setRecordCount(recordcount)
          setLoading(false)
        })
      )
      .catch((error) => {
        setLoading(false)
        setShow(true)
        switch (error.response.status) {
          case 401:
            return setError('Session has expired')

          default:
            return setError(error.response.statusText)
        }
      })
  }, [draftNotifications])

  // serach for notifications on the platform
  const searchSubmit = async (e) => {
    e.preventDefault()
    try {
      const response = await axios.get(
        `${baseUrl}${url}&PageSize=${pageSize}&SearchText=${searchText}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )

      const draftNotifications = response.data.data.data
      const pagecount = response.data.data.pageCount
      const pagenumber = response.data.data.pageNumber
      const pagesize = response.data.data.pageSize
      const recordcount = response.data.data.recordCount

      setDraftNotification(draftNotifications)
      setPageCount(pagecount)
      setPageNumber(pagenumber)
      setPageSize(pagesize)
      setRecordCount(recordcount)
    } catch (error) {
      setShow(true)
      switch (error.response.status) {
        case 401:
          return setError('Session has expired')

        default:
          return setError(error.response.statusText)
      }
    }
  }

  // get user input for search parameter
  const handleChange = (e) => {
    setSearchText(e.target.value)
  }

  // filters nofication on the platform
  const filter = async (e) => {
    try {
      const response = await axios.get(
        `${baseUrl}${url}&PageSize=${pageSize}&Filter=${e.target.value}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )

      const draftNotifications = response.data.data.data
      const pagecount = response.data.data.pageCount
      const pagenumber = response.data.data.pageNumber
      const pagesize = response.data.data.pageSize
      const recordcount = response.data.data.recordCount

      setDraftNotification(draftNotifications)
      setPageCount(pagecount)
      setPageNumber(pagenumber)
      setPageSize(pagesize)
      setRecordCount(recordcount)
    } catch (error) {
      setShow(true)

      switch (error.response.status) {
        case 401:
          return setError('Session has expired')

        default:
          return setError(error.response.statusText)
      }
    }
  }

  // sorts nofication on the platform
  const sort = async (e) => {
    try {
      const response = await axios.get(
        `${baseUrl}${url}&PageSize=${pageSize}&SortBy=${e.target.value}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )

      const draftNotifications = response.data.data.data
      const pagecount = response.data.data.pageCount
      const pagenumber = response.data.data.pageNumber
      const pagesize = response.data.data.pageSize
      const recordcount = response.data.data.recordCount

      setDraftNotification(draftNotifications)
      setPageCount(pagecount)
      setPageNumber(pagenumber)
      setPageSize(pagesize)
      setRecordCount(recordcount)
    } catch (error) {
      setShow(true)

      switch (error.response.status) {
        case 401:
          return setError('Session has expired')

        default:
          return setError(error.response.statusText)
      }
    }
  }

  // handles pagination on the platform
  const paginate = async (currentPage) => {
    if (currentPage <= 1) {
      currentPage = 1
    } else if (currentPage > pageCount) {
      currentPage = pageCount
    } else {
      currentPage = currentPage
    }

    try {
      const response = await axios.get(
        `${baseUrl}${url}&PageSize=${pageSize}&PageNumber=${currentPage}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )

      const draftNotifications = response.data.data.data
      const pagecount = response.data.data.pageCount
      const pagenumber = response.data.data.pageNumber
      const pagesize = response.data.data.pageSize
      const recordcount = response.data.data.recordCount

      setDraftNotification(draftNotifications)
      setPageCount(pagecount)
      setPageNumber(pagenumber)
      setPageSize(pagesize)
      setRecordCount(recordcount)
    } catch (error) {
      setShow(true)

      switch (error.response.status) {
        case 401:
          return setError('Session has expired')

        default:
          return setError(error.response.statusText)
      }
    }
  }

  // move notification to from draft to sent
  const handleSend = async (id) => {
    try {
      await axios.get(`${baseUrl}${sendNotificationUrl}?Id=${id}&statusId=1`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      // show success message on success
      setToastShow(true)
    } catch (error) {
      // show error message on error
      setToastShow(true)

      switch (error.response.status) {
        case 401:
          return setToastError('Session has expired')

        default:
          return setToastError(error.response.statusText)
      }
    }
  }

  // move notification to from draft to trash
  const handleTrash = async (id) => {
    try {
      await axios.get(`${baseUrl}${trashUrl}?Id=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      // show success message on success
      setToastShow(true)
    } catch (error) {
      // show error message on error
      setToastShow(true)

      switch (error.response.status) {
        case 401:
          return setToastError('Session has expired')

        default:
          return setToastError(error.response.statusText)
      }
    }
  }

  const handleToastClose = () => {
    if (toastError === 'Session has expired') {
      localStorage.clear()
      history.push('/admin_signin')
    } else {
      setToastShow(false)
    }
  }

  // hide alert modal
  const handleHide = () => {
    // logout user if authorized
    if (error === 'Session has expired') {
      localStorage.clear()
      history.push('/admin_signin')
    } else {
      setShow(false)
    }
  }

  return (
    <main className="hb-admin-dashboard">
      <ResponseModal show={show} handleHide={handleHide} error={error} />
      <div className="hb-admin-item">
        <Header />
      </div>
      <div className="hb-admin-item">
        <div className="hb-admin-child">
          <Sidebar />
        </div>
        <div className="hb-admin-child">
          <MainSection
            loading={loading}
            draftNotifications={draftNotifications}
            handleChange={handleChange}
            searchSubmit={searchSubmit}
            filter={filter}
            sort={sort}
            services={services}
            pageCount={pageCount}
            pageNumber={pageNumber}
            recordCount={recordCount}
            paginate={paginate}
            handleSend={handleSend}
            handleTrash={handleTrash}
            toastShow={toastShow}
            toastError={toastError}
            handleToastClose={handleToastClose}
          />
        </div>
      </div>
    </main>
  )
}

export default Draft
