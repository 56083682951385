import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'

import Header from '../header/Header'
import MainSection from './mainSection/MainSection'
import CardModal from './cardModal/CardModal'
import ResponseModal from '../../../../pharmHub/admin/components/responseModal/ResponseModal'

const urlCards = '/v2/api/Tokenization/GetCards'
const urlDeleteCard = '/v2/api/Tokenization/DeleteCard'
const baseUrl = process.env.REACT_APP_BASE_URL

const Card = () => {
  const [cards, setCards] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [showAddCard, setAddCard] = useState(false)
  const [showDeleteCard, setShowDeleteCard] = useState(false)
  const [showResponseModal, setShowResponseModal] = useState(false)
  const [cardId, setCardId] = useState('')
  const [loadingCard, setLoadingCard] = useState(false)

  /**instanciate history object */
  const history = useHistory()

  useEffect(() => {
    let signal = axios.CancelToken.source() //subscribe to cancel token
    setLoading(true)

    axios
      .get(`${baseUrl}${urlCards}`, { cancelToken: signal.token })
      .then((response) => {
        setCards(response.data.data)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        setShowResponseModal(true)
        if (error.response) {
          switch (error.response.status) {
            case 401:
              setError('Session has expired')
            default:
              return setError(error.response.data.message)
          }
        } else {
          setError(error.message)
        }
      })
    return () => {
      signal.cancel() //cancel subscription to avoid memory leak
    }
  }, [])

  const hideAddCard = () => setAddCard(false)
  const handleShowAddcard = () => setAddCard(true)

  /**show delete card modal */
  const handleShowDeleteCard = (id, e) => {
    e.stopPropagation() // stop event propagation to enable click events on inner cards
    setCardId(id)
    setShowDeleteCard(true)
  }

  /**hide delete card modal */
  const hideDeleteCard = () => setShowDeleteCard(false)

  /**delete customer card */
  const deleteCard = async (e) => {
    e.stopPropagation()

    try {
      setLoadingCard(true)
      const response = await axios.post(`${baseUrl}${urlDeleteCard}`, {
        data: {
          Id: cardId,
        },
      })

      const responseCard = await axios.get(`${baseUrl}${urlCards}`)

      await setCards(responseCard.data.data)
      const success = await response.data.message
      await setLoadingCard(false)
      await alert(success)
      hideDeleteCard()
    } catch (error) {
      setLoadingCard(false)
      error.response ? alert(error.response.data.message) : alert(error.message)
    }
  }

  /**take a user to tokenization page  */
  const handleAddCardModalAction = () => {
    history.push('/health_tokenization_payment')
  }

  /**hide response modal */
  const hideSHowResonseModal = () => {
    setShowResponseModal(false)
  }

  return (
    <>
      <ResponseModal
        show={showResponseModal}
        handleHide={hideSHowResonseModal}
        error={error}
        success={''}
      />
      <CardModal
        show={showAddCard}
        handleClose={hideAddCard}
        title="Add card"
        message="To add and verify your card, A refundable ₦50 will be charged from your account."
        action="Continue"
        cancel="Cancel"
        handleAction={handleAddCardModalAction}
        loading={loadingCard}
      />

      <CardModal
        show={showDeleteCard}
        handleClose={hideDeleteCard}
        title="Delete card"
        message="Are you sure you want to delete this card?"
        action="Delete"
        cancel="Cancel"
        handleAction={deleteCard}
        loading={loadingCard}
      />
      <Header />
      <MainSection
        handleShowAddcard={handleShowAddcard}
        handleShowDeleteCard={handleShowDeleteCard}
        loading={loading}
        cards={cards}
      />
    </>
  )
}

export default Card
