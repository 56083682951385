import React from 'react'
import styled from 'styled-components'

const ContentWrapper = styled.div`
	position: absolute;
	top: 55%;
	left: 50%;
	height: auto;
	transform: translate(-50%, -50%);
	background: linear-gradient(0deg, #fefcf7, #fefcf7);
`
const Content = styled.div`
	position: relative;
	margin: 50px auto;
	/* top: 50%;
	left: 50%;
	transform: translate(-50%, -50%); */
	font-size: 18px;
	font-weight: 500;
	line-height: 25.04px;
	text-align: center;
	color: #080e1c;
`

const Container = (props) => {
	return (
		<ContentWrapper className='col-md-6 col-sm-12'>
			<Content className='col-sm-10 col-xs-12'>{props.children}</Content>
		</ContentWrapper>
	)
}

export default Container
