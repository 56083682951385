import { put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import {
  resendCorporateOtpFailed,
  resendCorporateOtpSuccess,
  resendCorporateUserOtpRequest,
} from '../../actions/healthInsuredCreators/corporateUserActions/resendCorporateOtpActions'

function* handleResendCorporateOtp() {
  try {
    yield put(resendCorporateUserOtpRequest())
    const { data } = yield axios
      .get('/v2/api/CorporateInsurance/ResendOTP')
      .then((response) => response)

    yield put(resendCorporateOtpSuccess(data))
  } catch (error) {
    yield put(resendCorporateOtpFailed(error.response.message))
  }
}

export function* watchResendCorporateOtp() {
  yield takeEvery('RESEND_CORPORATE_OTP', handleResendCorporateOtp)
}
