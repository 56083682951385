import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { useHistory } from 'react-router-dom'
import { Table } from 'react-bootstrap'
import Layout from '../../../components/adminComponents/Layout/Layout'
import OrderFilter from '../../../components/adminComponents/orderFilter/OrderFilter'
import { getAllOrdersRequested } from '../../../../../redux/healthBanc/reducer/oneDrugStore/admin/orderSlices'

import styles from './orders.module.scss'

const Orders = () => {
  const history = useHistory()

  const dispatch = useDispatch()

  const { data, isLoading } = useSelector((state) => state.getAllOrders)

  useEffect(() => {
    dispatch(getAllOrdersRequested())
  }, [dispatch])

  const allOrders = (data?.data || []).map((order) => (
    <tr
      key={order.id}
      className="text-center"
      onClick={() => history.push(`/admin/oneDrugStore/order/${order.id}`)}
    >
      <td>{order.orderNumber}</td>
      <td>{new Date(order.dateCreated).toLocaleDateString()}</td>
      <td>{order.delivered}</td>
      <td>{order.status}</td>
      <td style={{ textAlign: 'right' }}>₦ {order.price.toFixed(2)}</td>
    </tr>
  ))

  return (
    <Layout canGoBack>
      <div className={`px-5 mt-4 ${styles.orders}`}>
        <p>Track your orders seamlessly</p>
        <div>
          <OrderFilter />
        </div>

        <Table bordered hover responsive className="mt-5">
          <thead>
            <tr className="text-center">
              <th>Order No.</th>
              <th>Date</th>
              <th>Delivery Status</th>
              <th>Payment Status</th>
              <th className="text-right">Total</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="6">
                  <div className="spinner-border d-flex  mx-auto" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </td>
              </tr>
            ) : allOrders.length !== 0 ? (
              allOrders
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  You have no orders
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </Layout>
  )
}

export default Orders
