import { takeLatest, put } from 'redux-saga/effects'
import axios from 'axios'
import { store } from '../../../../store'
import {
  getCartItemsRequested,
  getCartItemsSuccessful,
  getCartItemsFailed,
} from '../../../reducer/oneDrugStore/user/cartItemsSlices'

function* getCartItemsAsync({ payload }) {
  const isAuthUser = store.getState().user.userInfo.isAuth

  yield getCartItemsRequested()

  try {
    if (isAuthUser) {
      const { data } = yield axios.get(
        `${process.env.REACT_APP_ONE_DRUG_BASE}/v1/api/Cart/List`
      )
      yield put(getCartItemsSuccessful(data?.data?.data))
      // yield put(getCartItemsSuccessful({}))
    } else {
      const cartItems =
        JSON.parse(window.localStorage.getItem('oneDrugCart')) || []

      yield put(getCartItemsSuccessful(cartItems))
    }
  } catch (error) {
    if (error.response) {
      yield put(getCartItemsFailed(error.response.data))
      yield put(getCartItemsFailed(null))
      return
    }

    yield put(getCartItemsFailed(error.message))
    yield put(getCartItemsFailed(null))
  }
}

export function* watchGetCartItems() {
  yield takeLatest(getCartItemsRequested.type, getCartItemsAsync)
}
