import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Row, Col, Table } from 'react-bootstrap'
import CorporateDashboardStructure from '../../corporateDashboardStructure/CorporateDashboardStructure'
import LoadingIndicator from '../../../../../healthBancComponents/LoadingIndicator/LoadingIndicator'
import './companyProfile.scss'

const CompanyProfile = () => {
  const dispatch = useDispatch()
  const companyProfile = useSelector((state) => state.getCompanyProfile)

  useEffect(() => {
    dispatch({ type: 'GET_COMPANY_PROFILE' })
  }, [dispatch])

  const showCompanyDetails = () => {
    if (companyProfile.isLoading) {
      return (
        <div className="mx-auto">
          <LoadingIndicator />
        </div>
      )
    }

    if (companyProfile && companyProfile.data?.data) {
      const { companyName, companyEmail, industry, insuranceService } =
        companyProfile.data?.data
      return (
        <>
          <Row className="company__profile--edit__instruction mb-5">
            <Col>
              <p className="company__profile--edit__instruction--text">
                For security reasons we have locked your account information.
                Please contact support to edit.
              </p>
            </Col>
          </Row>
          <Row>
            <Col md="7">
              <Table
                responsive
                striped
                hover
                size="md"
                className="profile__table"
              >
                <tbody>
                  <tr>
                    <td>Company name</td>
                    <td>{companyName}</td>
                  </tr>
                  <tr>
                    <td>Company Email</td>
                    <td>{companyEmail}</td>
                  </tr>
                  <tr>
                    <td>Industry</td>
                    <td>{industry}</td>
                  </tr>
                  <tr>
                    <td>Health Insurance Provider</td>
                    <td>{insuranceService}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </>
      )
    }
  }

  return (
    <CorporateDashboardStructure screenTitle="Company profile">
      <Card className=" company__profile p-md-5">{showCompanyDetails()}</Card>
    </CorporateDashboardStructure>
  )
}

export default CompanyProfile
