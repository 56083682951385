import { takeLatest, put } from 'redux-saga/effects'
import axios from 'axios'
import {
  getAllDrugItemFailed,
  getAllDrugItemRequested,
  getAllDrugItemSuccessful,
} from '../../../reducer/oneDrugStore/user/drugItemsSlices'

function* getAllDrugItemAsync({ payload }) {
  const { searchValue = '', pageNumber = 1 } = payload || {}

  try {
    yield getAllDrugItemRequested()

    const { data } = yield axios.get(
      `${process.env.REACT_APP_ONE_DRUG_BASE}/v1/api/DrugItem/PaginatedList?SearchText=${searchValue}&PageNumber=${pageNumber}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('adminInfo')}`,
        },
      }
    )

    yield put(getAllDrugItemSuccessful(data.data))
  } catch (error) {
    if (error.response) {
      yield put(getAllDrugItemFailed(error.response.data?.responseDescription))
      yield put(getAllDrugItemFailed(null))
      return
    }

    yield put(getAllDrugItemFailed(error.message))
    yield put(getAllDrugItemFailed(null))
  }
}

export function* watchGetAllDrugItem() {
  yield takeLatest(getAllDrugItemRequested.type, getAllDrugItemAsync)
}
