import {
  HEALTH_INSURED_HEALTH_PROVIDER_FAIL,
  HEALTH_INSURED_HEALTH_PROVIDER_REQUEST,
  HEALTH_INSURED_HEALTH_PROVIDER_SUCCESS,
} from '../../actions/healthInsuredTypes/healthcareProviders'

const initialState = {
  loading: false,
  healthCareProviders: {},
  error: null,
}

export const healthCareProviders = (state = initialState, action) => {
  switch (action.type) {
    case HEALTH_INSURED_HEALTH_PROVIDER_REQUEST:
      return { loading: true, healthCareProviders: {}, error: null }

    case HEALTH_INSURED_HEALTH_PROVIDER_SUCCESS:
      return {
        loading: false,
        healthCareProviders: { ...action.payload },
        error: null,
      }

    case HEALTH_INSURED_HEALTH_PROVIDER_FAIL:
      return { loading: false, healthCareProviders: {}, error: action.payload }

    default:
      return state
  }
}
