import React, { useState, useEffect } from 'react'
import axios from 'axios'

import Card from '../card/Card'
import ManageAdmin from '../manageAdmin/ManageAdmin'
import RemoveAdmin from '../removeAdmin/removeAdmin'
import ProfilePicture from '../../../../../../assets/healthInsuredAdmin/adminProfile.png'
import Loader from '../../../../../pharmHub/admin/components/Spinner/Spinner'
import ResponseToast from '../../../../template/responseToast/ResponseToast'
import AddAdmin from '../../../../../../healthBancAdmin/components/administrator/Addadmin'
import CreateButton from '../../../../../../healthBancAdmin/components/template/NewButton'

const adminUrl = '/v2/api/BackendAdminAuth/GetBackendAdminUsers'
const roleUrl = '/v2/api/BackendAdminAuth/GetAdminRoles'
const createAdminurl = '/v2/api/BackendAdminAuth/CreateBackendAdmin'
const changeRoleUrl = '/v2/api/BackendAdminAuth/ChangeAdminRole'
const removeAdminUrl = `/v2/api/BackendAdminAuth/RemoveAdmin`

const MainSection = () => {
  const [showManageAdmin, setShowManageAdmin] = useState(false)
  const [showRemoveAdmin, setShowRemoveAdmin] = useState(false)
  const [token] = useState(localStorage.getItem('adminInfo'))
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [adminRoles, setAdminRoles] = useState(0)
  const [admins, setAdmins] = useState([])
  const [admin, setAdmin] = useState({})
  const [updateLoading, setUpdateLoading] = useState(false)
  const [updateError, setUpdateError] = useState(' ')
  const [updateSuccess, setUpdateSuccess] = useState(' ')
  const [showRemoveToast, setShowRemoveToast] = useState(false)
  const [showRemoveSuccess, setShowRemoveSuccess] = useState(' ')
  const [showRemoveError, setShowRemoveError] = useState(' ')
  const [showRemoveErrorToast, setShowRemoveErrorToast] = useState(false)
  const [removeAdminLoading, setRemoveAdminLoading] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const [showUpdateToast, setShowUpdateToast] = useState(false)
  const [showUpdateToastSuccess, setShowUpdateToastSuccess] = useState(false)
  const [showCreateAdminModal, setShowCreateAdminModal] = useState(false)
  const [toastShow, setToastShow] = useState(false)
  const [toastError, setToastError] = useState('')
  const [send, setSend] = useState(false)
  const [refetchAdmin, setRefetchAdmin] = useState(false)

  useEffect(() => {
    setLoading(true)

    function getAdmins() {
      return axios.get(`${adminUrl}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
    }

    function adminRoles() {
      return axios.get(`${roleUrl}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
    }

    // make multiple request to get admins and their roles
    axios
      .all([getAdmins(), adminRoles()])
      .then(
        axios.spread((adminsResponse, rolesResponse) => {
          const admins = adminsResponse.data.data
          const adminRoles = rolesResponse.data.data

          setAdmins(admins)
          setAdminRoles(adminRoles)
          setLoading(false)
        })
      )
      .catch((error) => {
        setLoading(false)
        setShowToast(true)
        error.response
          ? setError(error.response.data.message)
          : setError(error.message)
      })
  }, [])

  //update the user interface if admin was either created, deleted or role changed
  useEffect(() => {
    ;(async () => {
      try {
        const response = await axios.get(`${adminUrl}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        setAdmins(response.data.data)
        setRefetchAdmin(false)
      } catch (error) {
        setRefetchAdmin(false)
        error.response
          ? setError(error.response.data.message)
          : setError(error.message)
      }
    })()
  }, [refetchAdmin])

  const closeManageAdmin = () => setShowManageAdmin(false)

  const showManageAdminHandler = (id) => {
    if (admins.length <= 0) return //do nothing if there is no admin

    const admin = admins.filter((admin) => admin.id === id)

    setAdmin(admin[0])
    setShowManageAdmin(true)
  }

  const closeRemoveAdmin = () => setShowRemoveAdmin(false)

  const showRemoveAdminHandler = () => {
    setShowManageAdmin(false)
    setShowRemoveAdmin(true)
  }

  const updateAdminRole = async (values, handleReset) => {
    setUpdateLoading(true)

    try {
      const response = await axios.post(`${changeRoleUrl}`, {
        headers: { Authorization: `Bearer ${token}` },
        data: {
          Email: admin.email,
          RoleId: values.roleId,
        },
      })

      setRefetchAdmin(true)
      handleReset()
      setUpdateLoading(false)
      setShowUpdateToastSuccess(true)
      setUpdateSuccess(response.data.message)
    } catch (error) {
      setUpdateLoading(false)
      setShowUpdateToast(true)
      error.response
        ? setUpdateError(error.response.data.message)
        : setUpdateError(error.message)
    }
  }

  const removeAdmin = async () => {
    try {
      setRemoveAdminLoading(true)

      const response = await axios.post(`${removeAdminUrl}`, {
        headers: { Authorization: `Bearer ${token}` },
        data: { Email: admin.email },
      })

      setRefetchAdmin(true)
      setRemoveAdminLoading(false)
      setShowRemoveToast(true)
      setShowRemoveSuccess(response.data.message)
    } catch (error) {
      setRemoveAdminLoading(false)
      setShowRemoveErrorToast(true)
      error.response
        ? setShowRemoveError(error.response.data.message)
        : setShowRemoveError(error.message)
    }
  }

  const createAdmin = async (adminInfo, handleReset) => {
    const data = {
      firstName: adminInfo.firstName,
      lastName: adminInfo.lastName,
      userName: adminInfo.userName,
      email: adminInfo.email,
      roleId: Number(adminInfo.roleId),
    }

    setToastError('')
    setSend(true)

    try {
      await axios.post(`${createAdminurl}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })

      handleReset()
      setRefetchAdmin(true)
      setSend(false)
      setToastShow(true)
    } catch (error) {
      setSend(false)
      setToastShow(true)

      error.response
        ? setToastError(error.response.data.message)
        : setToastError(error.message)
    }
  }

  const closeToast = () => {
    setShowToast(false)
    setShowUpdateToast(false)
    setShowUpdateToastSuccess(false)
    setShowRemoveToast(false)
    setShowRemoveErrorToast(false)
  }

  const openCreateModal = () => setShowCreateAdminModal(true)

  const closeCreateModal = () => setShowCreateAdminModal(false)

  const handleToastClose = () => setToastShow(false)

  const admincard =
    admins.length > 0 &&
    admins.map((admin, index) => (
      <Card
        key={index}
        showManageAdminHandler={() => showManageAdminHandler(admin.id)}
        buttonText="Manage"
        name={`${admin.firstName} ${admin.lastName}`}
        email={admin.email}
        role={admin.classOrRole.name}
      />
    ))

  return (
    <section className="healthInsured__admin__roles__section">
      {loading ? <Loader css="position-abosulte" /> : admincard}

      <CreateButton openCreateModal={openCreateModal} />
      <ResponseToast
        error={error}
        showToast={showToast}
        closeToast={closeToast}
        delay={5000}
      />
      <ManageAdmin
        showManageAdmin={showManageAdmin}
        closeManageAdmin={closeManageAdmin}
        showRemoveAdminHandler={showRemoveAdminHandler}
        updateAdminRole={updateAdminRole}
        updateLoading={updateLoading}
        img={ProfilePicture}
        alt="profile picture"
        name={`${admin.firstName} ${admin.lastName}`}
        email={admin.email}
        roles={adminRoles}
        updateError={updateError}
        showUpdateToast={showUpdateToast}
        closeToast={closeToast}
        updateSuccess={updateSuccess}
        showUpdateToastSuccess={showUpdateToastSuccess}
      />

      <AddAdmin
        handleToastClose={handleToastClose}
        roles={adminRoles}
        toastShow={toastShow}
        toastError={toastError}
        send={send}
        handleClose={closeCreateModal}
        show={showCreateAdminModal}
        createAdmin={createAdmin}
      />

      <RemoveAdmin
        showRemoveAdmin={showRemoveAdmin}
        closeRemoveAdmin={closeRemoveAdmin}
        removeAdmin={removeAdmin}
        showRemoveToast={showRemoveToast}
        showRemoveSuccess={showRemoveSuccess}
        removeAdminLoading={removeAdminLoading}
        showRemoveError={showRemoveError}
        showRemoveErrorToast={showRemoveErrorToast}
        closeToast={closeToast}
      />
    </section>
  )
}

export default MainSection
