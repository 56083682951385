import {
  GET_HEALTHINSURED_ADMIN_ACTIVITY_LOG_FAILED,
  GET_HEALTHINSURED_ADMIN_ACTIVITY_LOG_REQUEST,
  GET_HEALTHINSURED_ADMIN_ACTIVITY_LOG_SUCCESS,
} from '../../healthInsuredTypes/healthInsuredAdminActionTypes'

export const getHealthInsuredAdminActivityLogRequest = () => ({
  type: GET_HEALTHINSURED_ADMIN_ACTIVITY_LOG_REQUEST,
})

export const getHealthInsuredAdminActivityLogSuccess = (logs) => ({
  type: GET_HEALTHINSURED_ADMIN_ACTIVITY_LOG_SUCCESS,
  payload: logs,
})

export const getHealthInsuredAdminActivityLogFailed = (errorMessage) => ({
  type: GET_HEALTHINSURED_ADMIN_ACTIVITY_LOG_FAILED,
  payload: errorMessage,
})
