import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: {},
  error: null,
}

const updatePharmacySlice = createSlice({
  name: 'updatePharmacy',
  initialState,
  reducers: {
    updatePharmacyRequested: (state) => {
      state.isLoading = true
      state.data = {}
      state.error = null
    },

    updatePharmacySuccessful: (state, action) => {
      state.isLoading = false
      state.data = action.payload
      state.error = null
    },

    updatePharmacyFailed: (state, action) => {
      state.isLoading = false
      state.data = {}
      state.error = action.payload
    },
  },
})

export const {
  updatePharmacyRequested,
  updatePharmacySuccessful,
  updatePharmacyFailed,
} = updatePharmacySlice.actions
export default updatePharmacySlice.reducer
