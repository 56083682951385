import { FETCH_COMPANY_LIST_IN_USER_LINE_ASYNC } from "../actions/actionTypes"

const INITIAL_STATE = {
    companyList : {
        data: {
          paginatedListDTO: [],
          currentPage: 1,
          pageCount: 1,
          pageSize: 10,
          "recordCount": 0
        }
      }
} 

export const companyListReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case FETCH_COMPANY_LIST_IN_USER_LINE_ASYNC:
            return {...state, companyList: action.payload}
        default:
            return state
    }
}