import { takeLatest, put } from 'redux-saga/effects'
import axios from 'axios'
import {
  updateDrugItemRequested,
  updateDrugItemSuccessful,
  updateDrugItemFailed,
} from '../../../reducer/oneDrugStore/admin/drugItemSlices'

function* updateDrugItemAsync({ payload }) {
  try {
    yield updateDrugItemRequested()

    const { data } = yield axios.put(
      `${process.env.REACT_APP_ONE_DRUG_BASE}/v1/api/DrugItem/Edit?drugItemId=${payload.drugItemId}`,
      {
        payload,

        headers: {
          Authorization: `Bearer ${localStorage.getItem('adminInfo')}`,
        },
      }
    )

    yield put(updateDrugItemSuccessful(data))
  } catch (error) {
    if (error.response) {
      yield put(updateDrugItemFailed(error.response.data))
      yield put(updateDrugItemFailed(null))
      return
    }

    yield put(updateDrugItemFailed(error.message))
    yield put(updateDrugItemFailed(null))
  }
}

export function* watchUpdateDrugItem() {
  yield takeLatest(updateDrugItemRequested.type, updateDrugItemAsync)
}
