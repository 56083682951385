import {
    SET_NEW_ACCOUNT, 
    SET_COMPANY_DETAILS,
    SET_ADMIN_DETAILS,
    
    IS_LOADING
} from '../actions/actionTypes'



const INITIAL_STATE = {
    
}

export const newAccountReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case IS_LOADING:
            return {...state, isLoading: action.payload}

        case SET_NEW_ACCOUNT:
            return {...state, ...action.payload}

        case SET_COMPANY_DETAILS:
            return { ...state, ...action.payload }

        case SET_ADMIN_DETAILS: 
            return {...state, ...action.payload}


        default:
            return state;
    }
}