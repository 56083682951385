import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { IoIosArrowDropright } from 'react-icons/io'

import { Button as CustomButtonStyle } from '../../Templates/CustomButton'

import Insurance from '../../../assets/InsuranceImgs/Insurance.svg'
import SterlingSun from '../../../assets/healthBancLandingPageImgs/sterlingSun.png'

export const GetStartedBtn = styled(CustomButtonStyle)`
  background-color: #d71e1b;
  height: 51px;
  width: 153px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #d71e1b;
  box-sizing: border-box;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
`

export const LearnMoreBtn = styled.button`
  color: #d71e1b;
  border: none;
  background-color: inherit;
  font-weight: 500;
  font-size: 16px;
`

const Img = styled.img`
  /* margin-top: 400px; */
`

const PHDesc = styled.div`
  height: 400px;
  background: url(${SterlingSun}) no-repeat;
  background-size: contain;
  background-position: right;

  div.features {
    width: 200px;
    font-size: 14px;
    color: #d71e1b;
    margin: 10px 10px;
    background: rgba(215, 30, 27, 0.05);
    padding: 8px;
    border-radius: 3px;
  }

  p.heading {
    font-size: 28px;
    font-weight: bold;
    color: #0c162c;
    max-width: 450px;
    line-height: 130%;
  }

  p.desc {
    max-width: 390px;
    font-size: 16px;
    line-height: 150%;
    margin-top: 32px;
    margin-bottom: 32px;
    color: #474747;
  }
`

const InsuranceIntro = ({ history }) => {
  return (
    <div className="d-flex flex-wrap justify-content-between ">
      <div className="col-sm-12 col-md-6 d-flex flex-column order-2 order-sm-1">
        <PHDesc>
          <div className="features">Affordable health insurance</div>
          <p className="heading mt-4">
            Insure your health without the usual financial stress.
          </p>

          <p className="desc">
            We have broken the status quo and created a stress-free health
            insurance plan that can be serviced every month with as little as a
            thousand naira!
          </p>
          <div className="d-flex align-items-center">
            <GetStartedBtn
              onClick={() => history.push('/signup')}
              className="me-2"
            >
              {' '}
              Get Started
            </GetStartedBtn>
            <LearnMoreBtn className="learnMore d-flex ms-3">
              <div
                className="text"
                onClick={() => history.push('/health_insurance')}
              >
                Learn more <IoIosArrowDropright style={{ fontSize: 25 }} />
              </div>
            </LearnMoreBtn>
          </div>
        </PHDesc>
      </div>

      <div className="col-sm-12 col-md-6 order-1 order-sm-2">
        <Img src={Insurance} alt="Insurance" className="img-fluid" />
      </div>
    </div>
  )
}

export default withRouter(InsuranceIntro)
