import { takeEvery, put, call } from 'redux-saga/effects'
import { getAllStatesAPI } from './locationApi'

import {
  GET_STATES_FAILED,
  GET_STATES_REQUESTED,
  GET_STATES_SUCCESSFUL,
} from '../../../reducer/healthInsuredReducer/locationReducers/getStatesReducer'

function* handleGetStates() {
  try {
    yield GET_STATES_REQUESTED()

    const { data } = yield call(getAllStatesAPI)

    yield put(GET_STATES_SUCCESSFUL(data))
  } catch (error) {
    yield put(GET_STATES_FAILED(error.response.data))
  }
}

export function* watchGetStates() {
  yield takeEvery(GET_STATES_REQUESTED.type, handleGetStates)
}
