import {
  RESTORE_BENEFICIARIES_IN_REVIEW_FAILED,
  RESTORE_BENEFICIARIES_IN_REVIEW_REQUEST,
  RESTORE_BENEFICIARIES_IN_REVIEW_SUCCESS,
} from '../../healthInsuredTypes/corporateActionTypes'

export const restoreBeneficiaryInReviewRequest = () => ({
  type: RESTORE_BENEFICIARIES_IN_REVIEW_REQUEST,
})

export const restoreBeneficiaryInReviewSuccess = (data) => ({
  type: RESTORE_BENEFICIARIES_IN_REVIEW_SUCCESS,
  payload: data,
})

export const restoreBeneficiaryInReviewFailed = (errorMessage) => ({
  type: RESTORE_BENEFICIARIES_IN_REVIEW_FAILED,
  payload: errorMessage,
})
