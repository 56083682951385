import React from 'react';
import { ReactComponent as FilterIcon } from '../../../../assets/selectElementArrow.svg';

const SelectInput = ({ handleSort, labelText, sortByOption }) => {

  return (
    <form className='template__health__insurance-select '>
      <p className='label-name' aria-label>{labelText}</p>
      <label className='label-filter' htmlFor='#template__health_insurance-filter'>
        <select
          name="Filter" id="template__health_insurance-filter"
          className='filter'
          onChange={handleSort}
        >
          <option value="">Filters</option>
          {sortByOption}
        </select>
        <i className='icon-wrapper'>
          <FilterIcon className='filter-icon ' />
        </i>
      </label>
    </form>
  );
};

export default SelectInput;
