import {
  CREATE_CORPORATE_USER_FAILED,
  CREATE_CORPORATE_USER_REQUEST,
  CREATE_CORPORATE_USER_SUCCESS,
} from '../../../actions/healthInsuredTypes/corporateActionTypes'

export const createCorporateUserReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_CORPORATE_USER_REQUEST:
      return { isLoading: true }

    case CREATE_CORPORATE_USER_SUCCESS:
      //Redirect user to otp page on success
      window.location.href = '/health_corporate/business_registration-otp'
      return { isLoading: false, ...action.payload }

    case CREATE_CORPORATE_USER_FAILED:
      alert(action.payload)
      return { errorMessage: action.payload, isLoading: false }

    default:
      return state
  }
}
