import react, { useState, useReducer, useEffect } from 'react';
import axios from 'axios';

export const useHealthcareProviders = (url) => {

  const getHealthProviders = (state, action) => {
    switch (action.type) {
      case 'FETCH':
        return { ...state, loading: true };

      case 'SUCCESS':
        return { ...state, loading: false, healthcareProviders: { ...action.payload } };

      case 'FAILURE':
        return { ...state, loading: false, error: true, errorMessage: action.payload };

      default: return state;
    }
  }

  const [state, dispatch] = useReducer(getHealthProviders, {
    loading: false,
    error: false,
    healthcareProviders: { data: [], pageNumber: 1, pageCount: 0, pageSize: 10, recordCount: 0 },
    errorMessage: ''
  });

  useEffect(() => {
    (async () => {
      try {
        dispatch({ type: 'FETCH' });

        const response = await axios.post(`${url}`);

        dispatch({ type: 'SUCCESS', payload: response.data.data });

      } catch (error) {

        error.response.data ? dispatch({ type: 'FAILURE', payload: error.response.data.message }) : dispatch({ type: 'FAILURE', payload: error.message });

      }
    })();
  }, [url])

  return [state, dispatch];
};
