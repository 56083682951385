import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: {},
  error: null,
}

const getAllOrdersSlice = createSlice({
  name: 'getAllOrders',
  initialState,
  reducers: {
    getAllOrdersRequested: (state) => {
      state.isLoading = true
      state.data = {}
      state.error = null
    },

    getAllOrdersSuccessful: (state, action) => {
      state.isLoading = false
      state.data = action.payload
      state.error = null
    },

    getAllOrdersFailed: (state, action) => {
      state.isLoading = false
      state.data = {}
      state.error = action.payload
    },
  },
})

export const {
  getAllOrdersRequested,
  getAllOrdersSuccessful,
  getAllOrdersFailed,
} = getAllOrdersSlice.actions
export default getAllOrdersSlice.reducer
