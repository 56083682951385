import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: [],
  errors: [],
}

const getTownSlice = createSlice({
  name: 'get towns',
  initialState,
  reducers: {
    GET_TOWNS_REQUESTED: (state) => {
      return { isLoading: true, data: [], errors: [] }
    },

    GET_TOWNS_SUCCESSFUL: (state, action) => {
      return { isLoading: false, data: [...action.payload], errors: [] }
    },

    GET_TOWNS_FAILED: (state, action) => {
      return { isLoading: false, data: [], errors: [] }
    },
  },
})

export const { GET_TOWNS_REQUESTED, GET_TOWNS_SUCCESSFUL, GET_TOWNS_FAILED } =
  getTownSlice.actions

export default getTownSlice.reducer
