import React from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { FcBusinessman } from 'react-icons/fc'

import HealthBancLogo from '../../../healthBancComponents/HealthBancLogo/HealthBancLogo'

const Header = () => {
  // instanciate a location object
  const location = useLocation()

  return (
    <nav className="navbar navbar-expand-sm navbar-light bg-white hb-admin-header d-print">
      <Link className="navbar-brandms-sm-5" to="#">
        <HealthBancLogo />
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#hb-admin-nav"
        aria-controls="hb-admin-nav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      {/* dynamically get current page name from location object */}
      <p className="pagename">
        {location.pathname.slice(1, location.pathname.length).replace('_', ' ')}
      </p>

      <div className="collapse navbar-collapse" id="hb-admin-nav">
        <ul className="navbar-navms-auto me5">
          <li className="nav-item">
            <NavLink className="nav-link" to="#">
              <FcBusinessman className="dp" />
            </NavLink>
          </li>
          <li className="nav-item dropdown align-self-sm-center">
            <Link
              className="nav-link dropdown-toggle"
              to="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Admin
            </Link>
            <div
              className="dropdown-menu dropdown-menu-right"
              aria-labelledby="navbarDropdown"
            >
              <Link className="dropdown-item" to="/admin_profile">
                Profile
              </Link>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default Header
