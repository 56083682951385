import { GET_BANK_ACCOUNT_DETAILS_ASYNC } from '../actions/actionTypes'

const INITIAL_STATE = {
	accountDetails: null,
}

export const bankAccountDetailsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_BANK_ACCOUNT_DETAILS_ASYNC:
			return { ...state, accountDetails: action.payload }

		default:
			return state
	}
}
