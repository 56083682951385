import React from 'react';
import { Card } from 'react-bootstrap';
import { connect } from 'react-redux';

const MessageBody = ({ message }) => {
  return (
    <Card className='message-card'>
      <Card.Body>
        <div className='d-flex justify-content-between mb-4'>
          <h1 className='message-subject'>{message && message.subject}</h1>
          <p><time className='message-time' dateTime='June-20-5-55'>{message && message.scheduleDate.slice(0, 10)}</time></p>
        </div>
        <div className='body-container p-4'>
          <p className='message-body'>{message && message.message}</p>
        </div>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return { message: state.notificationMessage }
}

export default connect(mapStateToProps)(MessageBody);
