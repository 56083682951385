import axios from 'axios'

import {
  SERVICE_PROVIDER,
  RESET_PROVIDER,
  HEALTHCARE_PROVIDER_LIST_FAIL,
  HEALTHCARE_PROVIDER_LIST_SUCCESS,
  HEALTHCARE_PROVIDER_LIST_REQUEST,
} from '../healthInsuredTypes/serviceProvider'
const getHealthCareProviderUrl = '/v2/api/Insurance/GetHealthProvider'

export const serviceProvider = (serviceProvider) => ({
  type: SERVICE_PROVIDER,
  payload: serviceProvider,
})

export const resetProvider = () => ({
  type: RESET_PROVIDER,
  payload: null,
})

export const getHealthCareProviderList =
  (StateOfResidence, TownOfResidence, insurancePovider) => async (dispatch) => {
    try {
      dispatch({ type: HEALTHCARE_PROVIDER_LIST_REQUEST })

      const {
        data: { data },
      } = await axios.post(`${getHealthCareProviderUrl}`, {
        data: {
          InsurancePovider: insurancePovider,
          State: StateOfResidence,
          City: TownOfResidence,
        },
      })

      dispatch({
        type: HEALTHCARE_PROVIDER_LIST_SUCCESS,
        payload: data,
      })
    } catch (error) {
      error.response
        ? dispatch({
            type: HEALTHCARE_PROVIDER_LIST_FAIL,
            payload: error.response.data.message,
          })
        : dispatch({
            type: HEALTHCARE_PROVIDER_LIST_FAIL,
            payload: error.message,
          })
    }
  }
