import React from 'react'
import { Toast } from 'react-bootstrap'
import { IoMdCheckmark } from 'react-icons/io'
import { MdMoodBad } from 'react-icons/md'

const ResponseToast = ({
  showToast,
  closeToast,
  error,
  success,
  position,
  top,
  right,
  width,
  delay,
}) => {
  return (
    <div className="health__insured__template__response__toast">
      <Toast
        show={showToast}
        onClose={closeToast}
        style={{ position, top, width, right }}
        autohide={true}
        delay={delay}
      >
        <Toast.Header className="d-flex justify-content-between align-items-center py-2">
          <div className="d-flex align-items-center">
            {error && (
              <i
                className={
                  'health__insured__template__response__toast--icon-wrapper bg-danger me3 d-flex justify-content-center align-items-center'
                }
              >
                <MdMoodBad />
              </i>
            )}

            {success && (
              <i
                className={
                  'health__insured__template__response__toast--icon-wrapper bg-success me3 d-flex justify-content-center align-items-center'
                }
              >
                {' '}
                <IoMdCheckmark />
              </i>
            )}
            <div>
              <p className="health__insured__template__response__toast--response">
                {error ? error : null}
              </p>
              <p className="health__insured__template__response__toast--response">
                {success ? success : null}
              </p>
            </div>
          </div>
        </Toast.Header>
      </Toast>
    </div>
  )
}

export default ResponseToast

ResponseToast.defaultProps = {
  position: 'absolute',
  top: 0,
  right: 0,
  width: '100%',
  delay: 3000,
}
