import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import { FormControl } from 'react-bootstrap'
import * as Yup from 'yup'
import styled from 'styled-components'

import { GET_STATES_REQUESTED } from '../../../../redux/healthBanc/reducer/healthInsuredReducer/locationReducers/getStatesReducer'
import { GET_TOWNS_REQUESTED } from '../../../../redux/healthBanc/reducer/healthInsuredReducer/locationReducers/getTownsReducer'

import NavBar from '../../healthInsuredIndividualFlow/signup/template/header/Header'
import CustomButton from '../../../../healthBancComponents/Templates/CustomButton'
import CustomInput from '../../../../healthBancComponents/Templates/CustomInput'
import CustomSelect from '../../../../healthBancComponents/Templates/CustomSelect'
import BackgorundTemplate from '../../../../healthBancComponents/Templates/BackgroundTemplate'
import { HEALTH_CENTERS_REQUESTED } from '../../../../redux/healthBanc/reducer/healthInsuredReducer/locationReducers/getHealthCenterListReducer'
import { addFamilyMemberRequested } from '../../../../redux/healthBanc/reducer/healthInsuredReducer/healthInsuredFamily/addFamilyMemberReducer'
import { ADD_FRIEND_WITH_DETAILS_REQUESTED } from '../../../../redux/healthBanc/reducer/healthInsuredReducer/addFriendWithDetailsReducer'

/////////////////
//Styled component for image input field

const FileInputField = styled.div`
  label {
    font-size: 13px;
    font-weight: 500;
    padding: 5px;
    border-radius: 5px;
    margin: 1px;
    box-shadow: 2px 2px 5px grey;

    &:hover {
      cursor: pointer;
      box-shadow: 2px 2px 7px grey;
    }
  }

  input {
    border: none;
    height: 0.1px;
    width: 0.1px;
  }

  span {
    color: #02761d;
    font-size: 12px;
  }

  .text-info {
    font-size: 11px;
  }

  .imageErrorMsg {
    font-size: 11px;
    font-weight: bold;
  }
`

const AddFamilyMemberForm = ({ history }) => {
  const [selectedState, setSelectedState] = useState('')
  const [selectedTown, setSelectedTown] = useState('')
  const [selectedHealthCenter, setSelectedHealthCenter] = useState('')
  const [image, setImage] = useState(null)
  const [imageError, setImageError] = useState(null)

  const dispatch = useDispatch()
  const states = useSelector((state) => state.getStates.data)
  const towns = useSelector((state) => state.getTowns.data)
  const healthCenters = useSelector((state) => state.healthCenters.data)
  const addFamilyMember = useSelector((state) => state.addFamilyMember)

  const userData = useSelector((state) => state?.profileCompletionStatus?.data)

  useEffect(() => {
    dispatch(GET_STATES_REQUESTED())
    setSelectedTown(towns[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    setSelectedState(states[0])
  }, [states])

  useEffect(() => {
    dispatch(GET_TOWNS_REQUESTED(selectedState))
  }, [dispatch, selectedState])

  useEffect(() => {
    dispatch(HEALTH_CENTERS_REQUESTED({ selectedState, selectedTown }))
  }, [dispatch, selectedState, selectedTown])

  useEffect(() => {
    setSelectedHealthCenter(healthCenters[0]?.hospitalName)
  }, [healthCenters])

  const isAddFriendUrl =
    history.location.pathname === '/health-friends/add-friend-full-details'

  const formValidation = Yup.object({
    firstName: Yup.string()
      .matches(/^[A-Z\-]+$/i, 'Enter letters and hyphens only')
      .required('Required'),
    lastName: Yup.string()
      .matches(/^[A-Z\-]+$/i, 'Enter letters and hyphens only')
      .required('Required'),
    phoneNumber: isAddFriendUrl
      ? Yup.string()
          .min(11, '11 digits minimum')
          .max(13, '13 digits maximum')
          .required('Required')
      : Yup.string().min(11, '11 digits minimum').max(13, '13 digits maximum'),
    email: isAddFriendUrl
      ? Yup.string()
          .email('Email must be in the correct format')
          .required('Required')
      : Yup.string().email('Email must be in the correct format'),
    gender: Yup.string().required('Required'),
    // maidenName: Yup.string().required('Required'),
    dateOfBirth: Yup.string().required('Required'),
    // occupation: Yup.string().required('Required'),
    maritalStatus: Yup.string().required('Required'),
    // careProviderName: Yup.string().required('Required'),
    // cpAddress: Yup.string().required('Required'),
    cpCity: Yup.string().required('Required'),
    planCode: isAddFriendUrl ? Yup.string().required('Required') : Yup.string(),
    // stateOfResidence: Yup.string().required('Required'),
    // townOfResidence: Yup.string().required('Required'),
    contactAddress: Yup.string().required('Required'),
  })

  const handleImageChange = (e) => {
    setImageError(null)
    setImage(e.target.files[0])
  }

  const handleAddMember = (values, { setSubmitting, resetForm }) => {
    const memberDetails = {
      ...values,
      stateOfResidence: selectedState,
      townOfResidence: selectedTown,
      careProviderName: selectedHealthCenter,
      insuranceService: userData?.serviceUsed, // Axa Mansard is the default insurance service for family
    }

    // Check if user is trying to add a family member, the run this function
    if (!isAddFriendUrl) {
      return dispatch(addFamilyMemberRequested({ memberDetails }))
    }

    //If user is adding a friend with full details, then run the instructions below
    if (isAddFriendUrl && !image) {
      setImageError('Please upload a photo')
      return
    }

    if (image?.size > 3145728) {
      setImageError('Image should not be greater then 3MB')
      return
    }

    setImageError(null)

    const formData = new FormData()
    formData.append('FirstName', memberDetails.firstName)
    formData.append('LastName', memberDetails.lastName)

    formData.append('Email', memberDetails.email)
    formData.append('PhoneNumber', memberDetails.phoneNumber)

    formData.append('Gender', memberDetails.gender)
    // // formData.append('MaidenName', memberDetails.maidenName)
    formData.append('DateOfBirth', memberDetails.dateOfBirth)
    formData.append('ContactAddress', memberDetails.contactAddress)
    // // formData.append('Occupation', memberDetails.occupation)
    formData.append('MaritalStatus', memberDetails.maritalStatus)
    formData.append('CareProviderName', memberDetails.careProviderName)
    formData.append('CPCity', memberDetails.cpCity)
    formData.append('PlanCode', memberDetails.planCode)
    formData.append('StateOfResidence', memberDetails.stateOfResidence)
    formData.append('TownOfResidence', memberDetails.townOfResidence)
    formData.append('InsuranceService', memberDetails.insuranceService)

    formData.append('UserImage', image)

    dispatch(
      ADD_FRIEND_WITH_DETAILS_REQUESTED({ formData, setSubmitting, resetForm })
    )
  }

  return (
    <>
      <NavBar />

      <BackgorundTemplate
        formTitle="Add Individual"
        subTitle="Fill in the individual's details"
        // showLogo={true}
      >
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            phoneNumber: '',
            email: '',
            gender: 'male',
            // maidenName: '',
            dateOfBirth: '',
            // occupation: '',
            maritalStatus: 'single',
            planCode: userData?.serviceUsed === 'Hygeia' ? '8' : '7',
            // careProviderName: '',
            // cpAddress: '',
            cpCity: '',
            // stateOfResidence: selectedState,
            // townOfResidence: '',
            contactAddress: '',
          }}
          validationSchema={formValidation}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            handleAddMember(values, { setSubmitting, resetForm })
          }}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <Field
                as={CustomInput}
                label="First Name"
                type="text"
                name="firstName"
                placeholder="Enter first name"
                autoFocus
              />

              <Field
                as={CustomInput}
                label="Last Name"
                type="text"
                name="lastName"
                placeholder="Enter last name"
              />

              {isAddFriendUrl && (
                <>
                  <Field
                    as={CustomInput}
                    label="Phone number"
                    type="tel"
                    name="phoneNumber"
                    placeholder="Enter Phone number"
                    maxLength="13"
                  />

                  <Field
                    as={CustomInput}
                    label="Email"
                    type="email"
                    name="email"
                    placeholder="Enter email address"
                  />

                  <Field
                    as={CustomSelect}
                    label="Choose Plan"
                    type="select"
                    name="planCode"
                  >
                    {userData?.serviceUsed === 'Hygeia' ? (
                      <>
                        <option value="8">Sapphire(&#8358;2000)</option>
                      </>
                    ) : (
                      <>
                        <option value="7">Ruby(&#8358;1000)</option>
                        <option value="8">Sapphire(&#8358;2000)</option>
                      </>
                    )}
                  </Field>
                </>
              )}

              {/* <Field
                as={CustomInput}
                label="Mother's Maiden Name"
                type="text"
                // name="maidenName"
                placeholder=""
              /> */}

              <Field
                as={CustomInput}
                label="Date of Birth"
                type="date"
                name="dateOfBirth"
                placeholder=""
              />

              {/* <Field
                as={CustomInput}
                // label="Occupation"
                type="text"
                // name="occupation"
                placeholder=""
              /> */}

              <Field
                as={CustomSelect}
                label="Marital Status"
                type="select"
                name="maritalStatus"
              >
                <option value="single">Single</option>
                <option value="married">Married</option>
                <option value="divorced">Divorced</option>
                <option value="widow">Widow</option>
                <option value="widower">Widower</option>
              </Field>

              <Field
                as={CustomInput}
                label="Contact Address"
                type="text"
                name="contactAddress"
                placeholder="Enter person's address"
              />

              <Field
                as={CustomSelect}
                label="Gender"
                type="select"
                name="gender"
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
              </Field>

              <FormControl
                as={CustomSelect}
                label="State of Residence"
                type="select"
                name="stateOfResidence"
                onChange={(e) => setSelectedState(e.target.value)}
              >
                {states.map((state) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </FormControl>

              <FormControl
                as={CustomSelect}
                label="Town of Residence"
                type="select"
                name="townOfResidence"
                onChange={(e) => setSelectedTown(e.target.value)}
              >
                {towns.map(
                  (town) =>
                    town !== null && (
                      <option key={town} value={town}>
                        {town}
                      </option>
                    )
                )}
              </FormControl>

              <Field
                as={CustomInput}
                label="City of Residence"
                type="address"
                name="cpCity"
                placeholder="Enter city of residence"
              />

              <FormControl
                as={CustomSelect}
                label="Care Providers"
                type="select"
                name="cpAddress"
                onChange={(e) => setSelectedHealthCenter(e.target.value)}
              >
                {healthCenters.map(
                  (center) =>
                    center !== null && (
                      <option key={center.id} value={center.hospitalName}>
                        {center.hospitalName}
                      </option>
                    )
                )}
              </FormControl>

              {isAddFriendUrl && (
                <FileInputField>
                  <label htmlFor="photo">Upload Photo</label>
                  <input
                    id="photo"
                    type="file"
                    accept="image/jpg, image/jpeg, image/png"
                    name="UserImage"
                    onChange={handleImageChange}
                  />
                  {` : `}
                  <span>{image?.name}</span>
                  <div className="text-info mt-1">
                    format: .jpg / .jpeg / .png
                  </div>
                  <div className="imageErrorMsg text-danger">{imageError}</div>
                </FileInputField>
              )}

              <div className="mt-3">
                <CustomButton
                  type="submit"
                  disabled={
                    addFamilyMember.isLoading || formik.isSubmitting
                      ? true
                      : false
                  }
                >
                  {addFamilyMember.isLoading || formik.isSubmitting
                    ? 'Adding...'
                    : 'Add Now'}
                </CustomButton>
              </div>
            </Form>
          )}
        </Formik>
      </BackgorundTemplate>
    </>
  )
}

export default AddFamilyMemberForm
