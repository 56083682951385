import React from 'react';
import { Card } from 'react-bootstrap';

const MainSection = () => {

  const admin = JSON.parse(localStorage.getItem('admin'));

  return (
    <div className='hb-main-section hb-registered-users p-4'>
      <Card >
        <Card.Body className='hb-admin-profile'>
          <h1 className='title'>Personal Information</h1>
          <div className='note-container'>
            <h2 className='note-title'>Note</h2>
            <p className='note-text'>
              Your account information is view only and cannot be changed.
            </p>
          </div>

          <table className='table'>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{admin.firstName}</td>
                <td>{admin.lastName}</td>
              </tr>
            </tbody>
          </table>

          <div className='email-address-container'>
            <h2 className='email-address-title'>Email Address</h2>
            <p className='email-address-text'>{admin.email}</p>
          </div>

          <div className='role-container'>
            <h2 className='role-title'>Role</h2>
            <p className='role-text'>{admin.roles}</p>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default MainSection;
