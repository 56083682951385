import React from 'react'

const ServicePercent = ({
  serviceName,
  amount,
  percent,
  borderC,
  backgroundC,
}) => {
  return (
    <div className="d-flex">
      <div className="service-percent me2" style={{ border: borderC }}>
        <div
          className="service-percent-child"
          style={{ background: backgroundC }}
        ></div>
      </div>
      <div>
        <p className="service-name m-0">{serviceName}</p>
        <p className="service-amount">
          {amount} ({percent}%)
        </p>
      </div>
    </div>
  )
}

export default ServicePercent
