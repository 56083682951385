import { put, call, takeLatest } from 'redux-saga/effects'
import {
  HEALTH_CENTERS_FAILED,
  HEALTH_CENTERS_REQUESTED,
  HEALTH_CENTERS_SUCCESSFUL,
} from '../../../reducer/healthInsuredReducer/locationReducers/getHealthCenterListReducer'
import { getHealthCentersAPI } from './locationApi'

function* handleHealthCenters({ payload }) {
  try {
    yield HEALTH_CENTERS_REQUESTED()

    const { data } = yield call(getHealthCentersAPI, payload)

    yield put(HEALTH_CENTERS_SUCCESSFUL(data.data))
  } catch (error) {
    yield put(HEALTH_CENTERS_FAILED(error.response.data))
  }
}

export function* watchGetHealthCenters() {
  yield takeLatest(HEALTH_CENTERS_REQUESTED.type, handleHealthCenters)
}
