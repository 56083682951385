import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: {},
  error: null,
}

const getAllPharmacySlice = createSlice({
  name: 'getAllPharmacy',
  initialState,
  reducers: {
    getAllPharmacyRequested: (state) => {
      state.isLoading = true
      state.data = {}
      state.error = null
    },

    getAllPharmacySuccessful: (state, action) => {
      state.isLoading = false
      state.data = action.payload
      state.error = null
    },

    getAllPharmacyFailed: (state, action) => {
      state.isLoading = false
      state.data = {}
      state.error = action.payload
    },
  },
})

export const {
  getAllPharmacyRequested,
  getAllPharmacySuccessful,
  getAllPharmacyFailed,
} = getAllPharmacySlice.actions
export default getAllPharmacySlice.reducer
