import React, { useEffect } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'

import BackgroundTemplate from '../Templates/BackgroundTemplate'
import CustomInput from '../Templates/CustomInput'
import CustomButton from '../Templates/CustomButton'

const ForgotPassword = styled.button`
  padding: 7px;
  font-size: 14px;
  background: rgba(215, 30, 27, 0.05);
  border-radius: 3px;
  border: none;
  color: #d71e1b;
  text-decoration: none;
  text-align: right;
`

const SignupLink = styled.div`
  font-size: 14px;

  button {
    color: #d71e1b;
    text-decoration: none;
    border: none;
    background-color: inherit;
  }
`

const HBSignIn = ({ signIn, user, history }) => {
  const dispatch = useDispatch()

  const {
    data: signInOTPResponse,
    error: signInOTPRequestError,
    isLoading: isRequestingSignInOTP,
  } = useSelector((state) => state?.signInRequestOTP) || {}

  const handleSignIn = (values, { setSubmitting, resetForm }) => {
    const afterSubmitAction = { resetForm, setSubmitting }

    if (signInOTPResponse) {
      // Make Login request
      signIn({
        values,
        afterSubmitAction,
        history,
      })
    } else {
      // Make request to send OTP to email
      dispatch({
        type: 'HEALTHBANC_SIGNIN_REQUEST_OTP',
        payload: { email: values.email, afterSubmitAction },
      })
    }
  }

  useEffect(() => {
    if (signInOTPResponse) {
      alert(
        'An OTP has been sent to your email address.\n\nOTP expires in 5 minutes.'
      )
      return
    }

    if (signInOTPRequestError) {
      alert(signInOTPRequestError)
      return
    }
  }, [signInOTPRequestError, signInOTPResponse])

  const signInSchema = Yup.object({
    email: Yup.string().email().required('Required'),
    password: signInOTPResponse
      ? Yup.string().required('Required')
      : Yup.string(),
    otp: signInOTPResponse ? Yup.string().required('Required') : Yup.string(),
  })

  if (!user.isAuth) {
    return (
      <BackgroundTemplate
        formTitle="Welcome"
        subTitle="Sign in to continue"
        showLogo={true}
      >
        <div className="text-right mt-5 d-flex justify-content-end">
          <ForgotPassword onClick={() => history.push('/reset-password')}>
            Forgot Password?
          </ForgotPassword>
        </div>
        <Formik
          initialValues={{
            email: '',
            password: '',
            otp: '',
          }}
          validationSchema={signInSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            handleSignIn(values, { setSubmitting, resetForm })
          }}
        >
          {(formik) => (
            <Form className="mt-2">
              <Field
                as={CustomInput}
                label="Email Address"
                type="email"
                name="email"
                placeholder="user@example.com"
                autoFocus
              />

              {signInOTPResponse && !isRequestingSignInOTP ? (
                <>
                  <Field
                    as={CustomInput}
                    label="Password"
                    type="password"
                    name="password"
                    placeholder="Enter your password"
                  />

                  <Field
                    as={CustomInput}
                    label="OTP"
                    type="text"
                    name="otp"
                    placeholder="Enter OTP sent to your email"
                  />
                </>
              ) : null}

              {signInOTPResponse && !isRequestingSignInOTP ? (
                <div>
                  <CustomButton
                    type="submit"
                    disabled={formik.isSubmitting ? true : false}
                  >
                    {formik.isSubmitting ? 'Signing in...' : 'Sign In'}
                  </CustomButton>
                </div>
              ) : (
                <div>
                  <CustomButton
                    type="submit"
                    disabled={formik.isSubmitting ? true : false}
                  >
                    {formik.isSubmitting ? 'Sending OTP...' : 'Continue'}
                  </CustomButton>
                </div>
              )}
            </Form>
          )}
        </Formik>

        <SignupLink className="text-center my-4">
          Don't have an account?{' '}
          <button
            onClick={() => history.push('/signup')}
            className="text-danger"
          >
            Sign Up
          </button>
        </SignupLink>
      </BackgroundTemplate>
    )
  } else {
    // return <Redirect to="/dashboard" />
    return <Redirect to="/" />
  }
}

const mapStateToProps = (state) => ({
  user: state.user.userInfo,
})

const mapDispatchToProps = (dispatch) => ({
  signIn: (payload) => dispatch({ type: 'HEALTHBANC_SIGNIN', payload }),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HBSignIn))
