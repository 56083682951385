import React from 'react';
import HealthInsuredDashboardStructure from '../../HealthInsuredDashboardStructure/HealthInsuredDashboardStructure';
import MainSection from '../../components/providers/mainSection/MainSection';

const Providers = () => {
  return (
    <HealthInsuredDashboardStructure title='Service Providers'>
      <MainSection />
    </HealthInsuredDashboardStructure>
  );
};

export default Providers;