import React from 'react';

import CardSection from '../cardSection/CardSection';
import NavBar from '../../navbar/NavBar';

const MainSection = ({
  handleShowAddcard,
  loading,
  cards,
  handleShowDeleteCard
}) => {

  return (
    <main className='axa-mansard-dashboard-mainsection px-3 pb-4 .axa-main'>
      <NavBar />
      < CardSection
        loading={loading}
        handleShowAddcard={handleShowAddcard}
        cards={cards}
        handleShowDeleteCard={handleShowDeleteCard}
      />
    </main>
  );
};

export default MainSection;