import React from 'react';
import Header from '../header/Header';
import MainSection from './mainSection/MainSection';

const CardProvider = () => {
  return (
    <div>
      <Header />
      <MainSection />
    </div>
  );
};


export default CardProvider;
