import {
  GET_HEALTHINSURED_ADMIN_DASHBOARD_ANALYTICS_REQUEST,
  GET_HEALTHINSURED_ADMIN_DASHBOARD_ANALYTICS_SUCCESS,
  GET_HEALTHINSURED_ADMIN_DASHBOARD_ANALYTICS_FAILED,
} from '../../healthInsuredTypes/healthInsuredAdminActionTypes'

export const getHealthInsuredAdminDashboardAnalyticsRequest = () => ({
  type: GET_HEALTHINSURED_ADMIN_DASHBOARD_ANALYTICS_REQUEST,
})

export const getHealthInsuredAdminDashboardAnalyticsSuccess = (data) => ({
  type: GET_HEALTHINSURED_ADMIN_DASHBOARD_ANALYTICS_SUCCESS,
  payload: data,
})

export const getHealthInsuredAdminDashboardAnalyticsFailed = (
  errorMessage
) => ({
  type: GET_HEALTHINSURED_ADMIN_DASHBOARD_ANALYTICS_FAILED,
  payload: errorMessage,
})
