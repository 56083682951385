import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { GiMedicines } from 'react-icons/gi'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { RiUpload2Line } from 'react-icons/ri'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import cartImg from '../../assets/cartImg/cartImg.png'
import {
  deleteCartItemRequested,
  updateCartItemQuantityRequested,
} from '../../../../redux/healthBanc/reducer/oneDrugStore/user/cartItemsSlices'
import './cartItem.scss'

const CartItem = ({ details }) => {
  const dispatch = useDispatch()

  const { data: cartItems } = useSelector((state) => state.getCartItems)

  const isAuthUser = useSelector((state) => state.user.userInfo.isAuth)

  const hasPrescription = cartItems.find(
    (item) => item.drugItemId === details.id
  )

  const history = useHistory()

  const handleQtyIncrease = () => {
    if (details.stock > details.quantity) {
      if (isAuthUser) {
        const itemForIncrease = cartItems.find(
          (item) => item.drugItemId === details.id
        )
        dispatch(
          updateCartItemQuantityRequested({
            ...itemForIncrease,
            quantity: itemForIncrease.quantity + 1,
          })
        )

        return
      }

      dispatch(updateCartItemQuantityRequested({ details, action: 'increase' }))
    } else {
      return toast.error('Exceeded number of product in stock!')
    }
  }

  const handleQtyDecrease = () => {
    if (details.quantity > 1) {
      if (isAuthUser) {
        const itemForDecrease = cartItems.find(
          (item) => item.drugItemId === details.id
        )
        dispatch(
          updateCartItemQuantityRequested({
            ...itemForDecrease,
            quantity: itemForDecrease.quantity - 1,
          })
        )
        return
      }

      dispatch(updateCartItemQuantityRequested({ details, action: 'decrease' }))
    } else {
      return toast.error('Quantity can not be less than 1')
    }
  }

  const handleDeleteItem = () => {
    if (isAuthUser) {
      const itemForDelete = cartItems.find(
        (item) => item.drugItemId === details.id
      )

      dispatch(deleteCartItemRequested({ details: itemForDelete }))
      return
    }
    dispatch(deleteCartItemRequested({ details }))
  }

  return (
    <div className="oneDrug__cartItem d-lg-flex justify-content-between">
      <ToastContainer />
      <div className="image">
        {details.image ? (
          <img src={details?.image || cartImg} alt="" className="" />
        ) : (
          <div
            className="d-flex  justify-content-center h-100"
            // style={{ border: '1px solid gray', borderRadius: '4px' }}
          >
            <GiMedicines size="70px" className="text-secondary" />
          </div>
        )}
      </div>
      <div className="description memd-2">
        <h4>{details.name}</h4>
        <p>{details.description}</p>
      </div>
      <div className="price mt-3">
        <div>
          <small>Price</small>
        </div>
        &#8358;{details.price}
      </div>

      <div className="qty-prescription d-flex flex-column justify-content-around px-2">
        <div className="qty__change d-flex align-content-center">
          <div className="qty">{details.quantity || 0}</div>
          <div className="qty__change__btn-box">
            <button onClick={handleQtyIncrease}>
              <IoIosArrowUp />
            </button>
            <button disabled={details.quantity <= 1}>
              <IoIosArrowDown onClick={handleQtyDecrease} />
            </button>
          </div>
        </div>

        {details.RequirePrescription && (
          <>
            <div
              className="prescription__upload-box d-flex align-content-center justify-content-center"
              onClick={() =>
                history.push(`/OneDrugStore/upload-prescription/${details.id}`)
              }
            >
              <RiUpload2Line size="20" />
            </div>
            {hasPrescription?.prescription ? (
              <div className="prescription__upload--label text-center text-success">
                PRESCRIPTION UPLOADED!
              </div>
            ) : (
              <div className="prescription__upload--label text-center">
                Upload prescription
              </div>
            )}
          </>
        )}
      </div>
      <div className="price mt-3">
        <div>
          <small>Total</small>
        </div>
        &#8358; {details.price * details.quantity || 0}
      </div>
      <div className="delete mt-0">
        <button onClick={() => handleDeleteItem(details.id)}>
          <AiOutlineCloseCircle size="20" />
        </button>
      </div>
    </div>
  )
}

export default CartItem
