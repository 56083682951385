import React from 'react'
import { IoMdRadioButtonOn } from 'react-icons/io'
import { AiFillCheckCircle } from 'react-icons/ai'
import './paydetails.css'

const Progress = () => {
    return (
        <div>
        <div className="progress">
            <ul>
                <li>
                <AiFillCheckCircle className="icon me3 fulfilled" style={{color: '#d71e1b'}}/>
                <span className="inactive active-inactive">Personal details</span>
                </li>

                <hr className="z" />

                <li>
                <AiFillCheckCircle className="icon me3 fulfilled" style={{color: '#d71e1b'}}/>
                <span className="inactive active-inactive">Bank information</span>
                </li>

                <hr className="z-z" />

                <li>
                <AiFillCheckCircle className="icon me3 fulfilled" style={{color: '#d71e1b'}}/>
                <span className="inactive">Care provider details</span>
                </li>

                <hr className="z-z-z" />

                <li>
                <IoMdRadioButtonOn className="icon me3 fulfilled" style={{color: '#d71e1b'}}/>
                <span className="active">Payment information</span>
                </li>
            </ul>
        </div>
        </div>
    )
}

export default Progress