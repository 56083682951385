import React from 'react';
import AfricanGuy from '../../assets/africanGuy.svg';
import { ReactComponent as Family } from '../../assets/healthInsuranceFamily.svg';
import { ReactComponent as Bg } from '../../assets/healthInsurancebg.svg';

const DescriptiveCard = () => {

  return (
    <article className='axa-mansard-descriptive-section'>
      <div className='family-container'>
        <div className='wrapper-family-description'>
          <div>
            <h2 className='keep-their'>Keep their smile forever</h2>
            <p className='your-quick'>
              We share the love you have for your family and
              that’s why we have made it super easy to secure
              their health and keep their radiant smile with our
              affordable health cover.
            </p>
          </div>
        </div>

        <div className='wrapper-family-img'>
          <Bg className='bg-vector-family' />
          <div className='bg-rectangle-family'></div>
          <Family className='img-family' />
        </div>
      </div>


      <div className='african-guy-container'>
        <div className='wrapper-african-guy-img'>
          <img src={AfricanGuy} alt='african guy' className='img-african-guy' />
          <div className='bg-rectangle-africa'></div>
          <Bg className='bg-vector-africa' />
        </div>

        <div className='wrapper-african-guy-description'>
          <div>
            <h2 className='keep-their'>No stress, no sweat.</h2>
            <p className='your-quick'>
              Everything happens right on your phone or laptop!
              Setting up your account and monthly payment is more
              than easy. Then we take care of the rest. It’s as simple as ABC.
            </p>
          </div>
        </div>
      </div>
    </article>
  )
}

export default DescriptiveCard;
