import React, { useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import CustomButton from '../../../../../healthBancComponents/Templates/CustomButton'
import {
  deleteAggregatorRequested,
  getAggregatorsRequested,
} from '../../../../../redux/healthBanc/reducer/oneDrugStore/admin/aggregatorSlices'
import Layout from '../../../components/adminComponents/Layout/Layout'

const Aggregators = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { data, isLoading } = useSelector((state) => state.getAggregators)

  useEffect(() => {
    dispatch(getAggregatorsRequested())
  }, [dispatch])

  const handleDelete = (id) => {
    const confirmDelete = window.confirm('Delete this aggregator?')

    if (confirmDelete) {
      dispatch(deleteAggregatorRequested({ aggregatorId: id }))
    }
  }

  return (
    <Layout canGoBack>
      <div className="mt-5 ps-5 container">
        <h4>Manage Aggregators</h4>
        <div className="col-3 mt-5">
          <CustomButton
            outline
            onClick={() =>
              history.push('/admin/oneDrugStore/aggregators/create')
            }
          >
            CREATE
          </CustomButton>
        </div>

        <div className="mt-5">
          {isLoading ? (
            <div className="spinner-border d-flex  mx-auto" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <table className={`table table-hover`}>
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Description</th>
                  <th scope="col">Address</th>
                </tr>
              </thead>
              <tbody>
                {(data?.data || []).map((aggregator, index) => (
                  <tr key={aggregator.id}>
                    <th scope="row">{index + 1}</th>
                    <td
                      style={{ cursor: 'pointer', color: 'steelblue' }}
                      onClick={() =>
                        history.push(
                          `/admin/OneDrugStore/aggregators/${aggregator.id}`
                        )
                      }
                    >
                      {aggregator.name}
                    </td>
                    <td>{aggregator.description}</td>
                    <td>{aggregator.address}</td>
                    <td>
                      <Button
                        variant="link"
                        className="text-secondary"
                        onClick={() =>
                          history.push(
                            `/admin/oneDrugStore/aggregators/update/${aggregator.id}`
                          )
                        }
                      >
                        Edit
                      </Button>
                      <Button
                        variant="link"
                        className="text-danger"
                        onClick={() => handleDelete(aggregator.id)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default Aggregators
