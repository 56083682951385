import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useHistory, useParams } from 'react-router-dom'
import uploadPrescriptionImg from '../../assets/upload-prescription-img.png'
import savedPrescriptionImg from '../../assets/saved-prescription-img.png'
import prescriptionSampleImg from '../../assets/prescription-sample-img.png'
import { uploadCartItemPrescriptionRequested } from '../../../../redux/healthBanc/reducer/oneDrugStore/user/cartItemsSlices'

import './prescriptionUpload.scss'

const PrescriptionUpload = () => {
  const [uploadedPrescription, setUploadedPrescription] = useState(null)
  const [selectedFileUrl, setSelectedFileUrl] = useState(null)

  const history = useHistory()
  const dispatch = useDispatch()

  const isAuthUser = useSelector((state) => state.user.userInfo.isAuth)
  const { data: cartItems } = useSelector((state) => state.getCartItems)
  const { isLoading } = useSelector((state) => state.uploadCartItemPrescription)

  const { id } = useParams()

  const handleFileUpload = (e) => {
    const file = e.target.files[0]

    const reader = new FileReader()
    reader.onload = (e) => {
      setUploadedPrescription(file)
      setSelectedFileUrl(e.target.result)
    }
    reader.readAsDataURL(file)
  }

  const handleAppendPrescription = () => {
    if (isAuthUser) {
      const itemForPrescription = cartItems.find(
        (item) => item.drugItemId === Number(id)
      )

      dispatch(
        uploadCartItemPrescriptionRequested({
          ...itemForPrescription,
          prescription: selectedFileUrl,
          history,
        })
      )

      return
    }

    dispatch(
      uploadCartItemPrescriptionRequested({
        prescription: selectedFileUrl,
        itemId: Number(id),
        history,
      })
    )
  }

  return (
    <div className="prescription-upload-box d-flex flex-wrap justify-content-between">
      <ToastContainer />
      <>
        <div className="document__upload col-12 col-md-5 mb-md-0 mb-sm-4 me-md-4 py-4">
          <div className="document__upload--header px-md-4">
            <h6>Upload Prescription Document</h6>
            <p>Please attach a prescription to proceed</p>
          </div>

          <div className="document__upload--cta">
            <div className="">
              <label htmlFor="uploadPrescription" className="custom-upload-btn">
                <img src={uploadPrescriptionImg} alt="" className="me-md-3" />
                <span>Upload new prescription</span>
              </label>
              <input
                id="uploadPrescription"
                type="file"
                name="myImage"
                accept="image/jpg, image/jpg, .pdf, image/png"
                onChange={(e) => handleFileUpload(e)}
              />
              <small className="uploaded-document--name text-center">
                {uploadedPrescription?.name && (
                  <>
                    <span className="text-info">Selected: </span>
                    <span className="text-success">
                      {uploadedPrescription.name}
                    </span>
                  </>
                )}
              </small>
            </div>

            {/* <div>
              <label htmlFor="savedPrescription" className="custom-upload-btn">
                <img src={savedPrescriptionImg} alt="" className="memd-3" />
                <span>Saved Prescription</span>
              </label>
              <input
                id="savedPrescription"
                type="file"
                name="myImage"
                accept="image/*"
              />
            </div> */}
          </div>
        </div>

        <div className="document__guide flex-lg-grow-1">
          <div className="py-md-5 d-flex flex-column px-lg-4">
            <div className="document__upload--header px-lg-4">
              <h6>Guide for a valid prescription</h6>
              <p>Sample</p>
            </div>

            <div className="prescription-sample-box d-flex flex-wrap p-3">
              <img
                src={prescriptionSampleImg}
                alt="Prescription sample"
                className="img-fluid prescription-sample-img"
              />
              <div className="prescription-sample-instruction col-xs-12">
                Don’t crop out any part of the image Avoid blurred image Include
                details of doctor and patient + clinic visit date Medicines will
                be dispensed as per prescription Supported files type: jpeg ,
                jpg , png , pdf Maximum allowed file size: 5MB
              </div>
            </div>
          </div>
        </div>
      </>

      <>
        <div className="document__preview d-flex justify-content-center align-items-center col-12 col-md-5 mb-md-0 my-sm-3 me-md-4 py-2 ">
          {!selectedFileUrl ? (
            <div className="document__preview--empty-state d-flex justify-contnet-center align-content-center">
              <img
                src={savedPrescriptionImg}
                alt=""
                className="img-fluid me-4 empty-state--img"
              />
              <div>
                <h6>Uploaded prescription preview</h6>
                <p>Uploaded prescription will be shown here</p>
              </div>
            </div>
          ) : (
            <div className="document__preview--image">
              <div>
                <h6 className="text-center mb-3">
                  Uploaded prescription preview
                </h6>
              </div>
              <img
                src={selectedFileUrl}
                alt="selected file preview"
                className="img-fluid uploaded-prescription--img"
              />
            </div>
          )}
        </div>
        <div className="upload--btn flex-grow-1 mt-sm-4">
          <p className="mb-5">
            Government regulations require a valid prescription
          </p>
          <button
            className="col-xs-12 col-md-6"
            disabled={isLoading}
            onClick={handleAppendPrescription}
          >
            {isLoading ? 'Uploading...' : 'Proceed'}
          </button>
        </div>
      </>
    </div>
  )
}

export default PrescriptionUpload
