import { ACTIONS } from '../actions/actionTypes'

const INITIAL_STATE = {
  userInfo: {
    isAuth: false,
  },
}

export const userReducer = (state = INITIAL_STATE, action) => {
  const { SIGNIN_ASYNC, SIGN_OUT } = ACTIONS

  switch (action.type) {
    case SIGNIN_ASYNC:
      return {
        ...state,
        isLoading: false,
        userInfo: { ...action.payload, isAuth: true },
      }

    // case SIGN_OUT: {
    //   localStorage.clear()
    //   return { userInfo: { isAuth: false } }
    // }

    default:
      return state
  }
}
