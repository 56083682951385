import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: {},
  errors: [],
}

const deactivateFamilyMemberSlice = createSlice({
  name: 'DeactivateFamilyMember',
  initialState,
  reducers: {
    DEACTIVATE_FAMILY_MEMBER_REQUESTED: (state) => {
      return { isLoading: true, data: {}, errors: [] }
    },

    DEACTIVATE_FAMILY_MEMBER_SUCCESSFUL: (state, action) => {
      return { isLoading: false, data: { ...action.payload }, errors: [] }
    },

    DEACTIVATE_FAMILY_MEMBER_FAILED: (state, action) => {
      return { isLoading: false, data: {}, errors: [action.payload] }
    },
  },
})

export const {
  DEACTIVATE_FAMILY_MEMBER_SUCCESSFUL,
  DEACTIVATE_FAMILY_MEMBER_REQUESTED,
  DEACTIVATE_FAMILY_MEMBER_FAILED,
} = deactivateFamilyMemberSlice.actions

export default deactivateFamilyMemberSlice.reducer
