import React from 'react'
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import Template from '../Templates/BackgroundTemplate'
import CustomInput from '../Templates/CustomInput'
import CustomButton from '../Templates/CustomButton'
import { healthBancSignOut } from '../../redux/healthBanc/actions/actionCreators'

const ChangePassword = () => {
  const isAuthUser = useSelector((state) => state.user.userInfo.isAuth)
  const history = useHistory()
  const dispatch = useDispatch()

  const handleChangePassword = async (values, { setSubmitting, resetForm }) => {
    try {
      await axios({
        method: 'post',
        url: '/v2/api/Identity/ChangePassword',
        data: { ...values },
      }).then((response) => {
        alert('Your new password has been set!')
        dispatch(healthBancSignOut())
      })
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          alert(
            'Current password is incorrect. \nTry again with the correct password or Reset Password.'
          )
        }
        alert(error.response.data.message)
      } else if (error.request) {
        alert('Server Response Error')
      } else {
        alert('An Error occured!')
      }
    } finally {
      resetForm()
    }
  }

  const changePasswordSchema = Yup.object({
    password: Yup.string().required('Required'),

    newPassword: Yup.string()
      .min(8, 'Password must be 8 characters or more')
      .required('Required')
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/,
        'Must Contain 8 Characters or more, 1 Uppercase, 1 Lowercase and 1 Number and a special character.'
      ),

    confirmPassword: Yup.string()
      .required('Required')
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
  })

  if (isAuthUser) {
    return (
      <Template showLogo={true} formTitle="Change Password">
        <Formik
          initialValues={{
            password: '',
            newPassword: '',
            confirmPassword: '',
          }}
          validationSchema={changePasswordSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            handleChangePassword(values, {
              setSubmitting,
              resetForm,
            })
          }}
        >
          {(formik) => (
            <Form>
              <Field
                as={CustomInput}
                label="Password"
                type="password"
                name="password"
                placeholder="Enter your current password"
                autoFocus
              />

              <Field
                as={CustomInput}
                label="New Password"
                type="password"
                name="newPassword"
                placeholder="Choose a new password"
              />

              <Field
                as={CustomInput}
                label="Confirm Password"
                type="password"
                name="confirmPassword"
                placeholder="Confirm Password"
              />

              <div>
                <CustomButton
                  type="submit"
                  disabled={formik.isSubmitting ? true : false}
                >
                  {formik.isSubmitting
                    ? 'Changing Password...'
                    : 'Change Password'}
                </CustomButton>
              </div>
            </Form>
          )}
        </Formik>
      </Template>
    )
  } else {
    return <Redirect to="/signin" />
  }
}
export default ChangePassword
