import {
  GET_CORPORATE_ACTIVITY_LOG_FAILED,
  GET_CORPORATE_ACTIVITY_LOG_REQUEST,
  GET_CORPORATE_ACTIVITY_LOG_SUCCESS,
} from '../../../actions/healthInsuredTypes/corporateActionTypes'

export const getCorporateActivityLogReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CORPORATE_ACTIVITY_LOG_REQUEST:
      return { isLoading: true }

    case GET_CORPORATE_ACTIVITY_LOG_SUCCESS:
      return { isLoading: false, ...action.payload }

    case GET_CORPORATE_ACTIVITY_LOG_FAILED:
      return { isLoading: false, errorMessage: action.payload }

    default:
      return state
  }
}
