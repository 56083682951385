import React, { useState, useReducer, useContext } from 'react'
import { IoMdRadioButtonOn } from 'react-icons/io'
import { AiFillCheckCircle } from 'react-icons/ai'
import CustomInput from '../../../../../healthBancComponents/Templates/CustomInput'
import { Formik, Field } from 'formik'
import * as yup from 'yup'
import './paydetails.css'
import Header from '../template/header/Header'
import CustomButton from '../template/customButton/CustomeButton'
import Wallet from './payWithWallet/Wallet'
import Payment from './Payment'
import { init } from 'aos'
import { set } from 'date-fns'
import PayContextProvider, { PayContext } from './context/PayContext'
import { Provider, connect } from 'react-redux'
//import {store} from './store'
// import { pay_wallet, pay_card } from './redux/PaymentReducer';
import { useSelector } from 'react-redux'
// useReducer

const PaymentDetails = ({ tokenizeCard, loading, error, paystackError }) => {
  //const { payWallet } = useContext(PayContext)
  //const pay_card = payWallet.payWithWallet
  const { payWithWallet } = useSelector((state) => state.payWithWallet)
  // const [payWithWallet, setPayWithWallet] = useState(false)

  const cardInfoSchema = yup.object().shape({
    cardNumber: yup.number().required('Required'),
    expiryDate: yup.string().required('Required'),
    cvv: yup.string().required('Required'),
    pin: yup
      .string()
      .min(4, 'Minimum of 4 digits required')
      .required('Required'),
  })

  const handleSubmitCardInfo = (values) => {
    tokenizeCard(values)
  }

  return (
    <div className="axa-mansard-sign-up-payment-details">
      <Header />

      {payWithWallet ? (
        <div>
          {/* <PayContextProvider>
            <Payment />
          </PayContextProvider> */}

          <Payment />
          <Wallet />

          {/* <Payment setPayWithWallet={setPayWithWallet}/>
          <Wallet /> */}
          <div className="progress">
            <ul>
              <li>
                <AiFillCheckCircle className="icon me3 fulfilled" />
                <span className="inactive active-inactive">
                  Personal details
                </span>
              </li>

              <hr className="z" />

              <li>
                <AiFillCheckCircle className="icon me3 fulfilled" />
                <span className="inactive active-inactive">
                  Bank information
                </span>
              </li>

              <hr className="z-z" />

              <li>
                <AiFillCheckCircle className="icon me3 fulfilled" />
                <span className="inactive">Care provider details</span>
              </li>

              <hr className="z-z-z" />

              <li>
                <IoMdRadioButtonOn className="icon me3 fulfilled" />
                <span className="active">Payment information</span>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <div className="Card-full">
          <div>
            {/* <PayContextProvider>
            <Payment />
          </PayContextProvider> */}
            {/* < Payment setPayWithWallet={setPayWithWallet} /> */}

            {/* <Payment /> */}
          </div>

          <div className="form-container mCard">
            <div className="personal-details-form">
              <Formik
                initialValues={{
                  cardNumber: '',
                  expiryDate: '',
                  cvv: '',
                  pin: '',
                }}
                validationSchema={cardInfoSchema}
                onSubmit={(values, { isSubmitting }) => {
                  handleSubmitCardInfo(values, { isSubmitting })
                }}
              >
                {(props) => (
                  <form className="personal-form" onSubmit={props.handleSubmit}>
                    <h1 className="start-your">Start your free month</h1>
                    <p className="provide-some mb-4">
                      Provide your card information
                    </p>

                    <Field
                      as={CustomInput}
                      type="tel"
                      label="Card Number"
                      format="#####################"
                      name="cardNumber"
                    />

                    <Field
                      as={CustomInput}
                      type="password"
                      maxLength="4"
                      label="Card Pin"
                      format="####"
                      name="pin"
                    />

                    <Field
                      as={CustomInput}
                      name="expiryDate"
                      type="tel"
                      label="Expiry Date"
                      format="##/##"
                      placeholder="MM/YY"
                      mask={['M', 'M', 'Y', 'Y']}
                    />

                    <br></br>

                    <Field
                      as={CustomInput}
                      type="tel"
                      name="cvv"
                      label="CVV"
                      maxLength="3"
                    />

                    {error && (
                      <menu
                        className={
                          error
                            ? 'error-message error-message-show text-danger p-0 m-0'
                            : 'error-message  p-0 m-0'
                        }
                      >
                        <small>*{error}</small>
                      </menu>
                    )}

                    {paystackError && (
                      <menu
                        className={
                          paystackError
                            ? 'error-message error-message-show text-danger m-0 p-0'
                            : 'error-message p-0 m-0'
                        }
                      >
                        <small>*{paystackError}</small>
                      </menu>
                    )}

                    <CustomButton
                      buttonText={loading ? 'Proceeding...' : 'Proceed'}
                      disable={loading ? true : false}
                    />
                  </form>
                )}
              </Formik>
            </div>

            <div className="progres">
              <ul>
                <li>
                  <AiFillCheckCircle className="icon me3 fulfilled" />
                  <span className="inactive active-inactive">
                    Personal details
                  </span>
                </li>

                <hr className="z" />

                <li>
                  <AiFillCheckCircle className="icon me3 fulfilled" />
                  <span className="inactive active-inactive">
                    Bank information
                  </span>
                </li>

                <hr className="z-z" />

                <li>
                  <AiFillCheckCircle className="icon me3 fulfilled" />
                  <span className="inactive">Care provider details</span>
                </li>

                <hr className="z-z-z" />

                <li>
                  <IoMdRadioButtonOn className="icon me3 fulfilled" />
                  <span className="active">Payment information</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default PaymentDetails
