import { put, call, takeLatest } from 'redux-saga/effects'
import {
  GET_TOWNS_FAILED,
  GET_TOWNS_REQUESTED,
  GET_TOWNS_SUCCESSFUL,
} from '../../../reducer/healthInsuredReducer/locationReducers/getTownsReducer'
import { getTownsAPI } from './locationApi'

function* handleGetStates({ payload = '' }) {
  try {
    yield GET_TOWNS_REQUESTED()

    const { data } = yield call(getTownsAPI, payload)

    yield put(GET_TOWNS_SUCCESSFUL(data.data[0].cities))
  } catch (error) {
    yield put(GET_TOWNS_FAILED(error.response.data))
  }
}

export function* watchGetTowns() {
  yield takeLatest(GET_TOWNS_REQUESTED.type, handleGetStates)
}
