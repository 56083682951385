import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const DoughnutChart = ({ serviceBreakdowns }) => {
  const canvasRef = React.createRef();

  // get an array of service name
  const serviceName = serviceBreakdowns && serviceBreakdowns.map(serviceBreakdown => {
    return serviceBreakdown.name;
  });

  // get an array of service count
  const serviceCount = serviceBreakdowns && serviceBreakdowns.map(serviceBreakdown => {
    return serviceBreakdown.count
  });

  const data = {
    //Bring in data
    labels: serviceName,
    datasets: [
      {
        label: "Sales",
        data: serviceCount,
        backgroundColor: ['#6610f2', ' #A630A0', '#EB5757', '#532C60', '#20A4C1'],
      }
    ]
  };

  return (
    <Doughnut data={data} ref={canvasRef} options={{
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        displayTitle: true,
        position: 'right'
      },
      plugins: {
        datalabels: {
          labels: {
            title: {
              color: 'white'
            }
          }
        }
      }
    }} />
  );
};

export default DoughnutChart;
