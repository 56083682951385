import React from 'react';
import { Modal } from 'react-bootstrap';

const CardModal = ({
  show,
  handleClose,
  handleChange,
  action,
  cancel,
  handleAction,
  loading,
  isChecked,
  success,
  error,
}) => {

  return (
    <div>
      <Modal show={show} onHide={handleClose} dialogClassName='axa-mansard-cancel-modal'>
        <Modal.Header className='header' closeButton></Modal.Header>

        <Modal.Body bsPrefix='modal-body'>
          <h2 className='title'>Cancel subscription</h2>
          <p className='message'>Before you cancel, let us know why you are leaving.</p>

          <form className='p-0 mt-4' onSubmit={handleAction} id='cancel-subscription'>
            <div className='input-wrapper'>
              <input
                type='radio'
                name='reason-to-reject'
                className='radio-check'
                id='not-satisfied'
                value='I am not satisfied with the service'
                onChange={handleChange}
              />
              <label htmlFor='not-satisfied' className='input-text'>
                I am not satisfied with the service
                </label>
            </div>

            <div className='input-wrapper'>
              <input
                type='radio'
                name='reason-to-reject'
                className='radio-check'
                value='It is expensive'
                onChange={handleChange}
              />
              <label className='input-text'>It is expensive</label>
            </div>

            <div className='input-wrapper'>
              <input type='radio'
                id='other-reason'
                name='reason-to-reject'
                name='reason-to-reject'
                className='radio-check'
                value='other options'
                onChange={handleChange}
              />
              <textarea
                style={{ width: '100%', paddingRight: '5px', paddingLeft: '5px' }}
                onChange={handleChange}
                placeholder='Write your own reason...'
                disabled={!isChecked ? true : false}
              >
              </textarea>
            </div>
            <menu className='inform-user'>
              Cancelling now does not terminate your current subscription.
              You will have access till the end of this cycle.
            </menu>
          </form>
        </Modal.Body>

        <Modal.Footer bsPrefix='modal-footer'>
          <button
            disabled={!!loading}
            className={'action'}
            form='cancel-subscription'>
            {loading ? 'Cancel...' : 'Cancel subscription'}
          </button>

          <button
            className="cancel"
            onClick={handleClose}>
            Stay subscribed
            </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CardModal;
