import React from 'react'
import styled from 'styled-components'

export const Button = styled.button`
  background: ${(props) => (props.outline ? '#fff' : '#D71E1B')};
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  width: ${(props) => (props.auto ? 'auto' : '100%')};
  height: 40px;
  border: ${(props) => (props.outline ? '1px solid #D71E1B' : 'none')};
  color: ${(props) => (props.outline ? '#D71E1B' : '#fff')};
  font-weight: 600;
  font-size: 14px;
  outline: none;
  transition: all 0.3s;

  &:hover,
  &:active,
  &:focus {
    background: #b62926;
    outline: none;
    color: #fff;
  }

  &:focus {
    outline: none;
  }

  &[disabled] {
    background: #eb8e8d;
    cursor: not-allowed;
  }
`

const CustomButton = ({ children, ...props }) => {
  return <Button {...props}>{children}</Button>
}

export default CustomButton
