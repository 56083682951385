import React from 'react';
import { Route, Redirect } from "react-router-dom";
import authenticated from './authenticated';


const AdminProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={(props) => (
      authenticated() ? <Component {...props} /> :

        <Redirect to={{
          pathname: "/admin_signin",
          state: { from: props.location }
        }} />

    )} />
  );
};

export default AdminProtectedRoute;
