export const GET_DASHBOARD_REQUIREMENTS_ASYNC =
	'GET_DASHBOARD_REQUIREMENTS_ASYNC'

export const GET_USER_NOTIFICATIONS_ASYNC = 'GET_USER_NOTIFICATIONS_ASYNC'

export const GET_BANK_ACCOUNT_DETAILS_ASYNC = 'GET_BANK_ACCOUNT_DETAILS_ASYNC'

// New User Registration
export const SET_NEW_ACCOUNT = 'SET_NEW_ACCOUNT'
export const SET_COMPANY_DETAILS = 'SET_COMPANY_DETAILS'

export const SET_ADMIN_DETAILS = 'SET_ADMIN_DETAILS'
// export const SET_PASSWORD = 'SET_PASSWORD'
// export const SET_RECOVERY_QUESTIONS_AND_SUBMIT = 'SET_RECOVERY_QUESTIONS_AND_SUBMIT'

export const IS_LOADING = 'IS_LOADING'

// export const LOGIN_ASYNC = 'LOGIN_ASYNC'
// export const LOGIN_ASYNC_FAILED = 'LOGIN_ASYNC_FAILED'

// export const LOGOUT = 'LOG_OUT'

// export const SIGNUP_ASYNC = 'SIGNUP_ASYNC'
// export const SIGNUP_ASYNC_FAILED = 'SIGNUP_ASYNC_FAILED'

// Clear Error Message
export const CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE'

// export const FORGOT_PASSWORD_ASYNC = 'FORGOT_PASSWORD_ASYNC'
// export const SET_NEW_PASSWORD_ASYNC = 'SET_NEW_PASSWORD_ASYNC'

//DocumentS exist
export const VALID_ID_STATUS = 'VALID_ID_STATUS'
export const PHARMACIST_LICENCE_STATUS = 'PHARMACIST_LICENCE_STATUS'
export const PREMISES_LICENCE_STATUS = 'PREMISES_LICENCE_STATUS'

//Opening Current Account
export const SAVE_BUSINESS_INFO_ASYNC = 'SAVE_BUSINESS_INFO_FORM_ASYNC'
export const SAVE_ROLES_AND_SIGNATORIES = 'SAVE_ROLES_AND_SIGNATORIES'
export const SAVE_BUSINESS_CREDENTIALS_ASYNC = 'SAVE_BUSINESS_CREDENTIALS_ASYNC'

//Products
export const FETCH_COMPANY_LIST_IN_USER_LINE_ASYNC =
	'FETCH_COMPANY_LIST_IN_USER_LINE_ASYNC'

export const FETCH_USER_PRODUCTS_ASYNC = 'FETCH_USER_PRODUCTS_ASYNC'
export const FETCH_SHOWCASE_PRODUCTS_ASYNC = 'FETCH_SHOWCASE_PRODUCTS_ASYNC'

// Cart
export const GET_CART_ITEMS_ASYNC = 'GET_CART_ITEMS_ASYNC'
export const ADD_TO_CART_ASYNC = 'ADD_TO_CART_ASYNC'
export const UPDATE_CART_ITEM_QTY_ASYNC = 'UPDATE_CART_ITEM_QTY_ASYNC'
export const DELETE_FROM_CART_ASYNC = 'DELETE_FROM_CART_ASYNC'

// admin backend action type
export const USER_ID = 'USER_ID'
export const ACCOUNT_REQUEST_ID = 'ACCOUNT_REQUEST_ID'
export const COMPANY_ID = 'COMPANY_ID'
export const ADMIN_ID = 'ADMIN_ID'
export const ADMIN_INDIVIDUAL_ID = 'ADMIN_INDIVIDUAL_ID'

//Get user upline list
export const GET_USER_UPLINE_LIST_ASYNC = 'GET_USER_UPLINE_LIST_ASYNC'

//Get user downline list
export const GET_USER_DOWNLINE_LIST_ASYNC = 'GET_USER_DOWNLINE_LIST_ASYNC'

//Get requests to join network
export const GET_REQUEST_TO_JOIN_ASYNC = 'GET_REQUEST_TO_JOIN_ASYNC'

//Orders
export const INCOMING_ORDER_REQUEST_ASYNC = 'INCOMING_ORDER_REQUEST_ASYNC'
export const OUTGOING_ORDER_REQUEST_ASYNC = 'OUTGOING_ORDER_REQUEST_ASYNC'
