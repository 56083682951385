const INITIAL_STATE = {
  adminIndividualId : ""
}


export const adminIndividualIdReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case 'ADMIN_INDIVIDUAL_ID':
      return {...state, adminIndividualId: action.id }

    default: return state
  }
} 