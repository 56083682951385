import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { BsDot } from 'react-icons/bs'
import './transactionLog.scss'

const UserTransactionLog = ({ userPersonalInfo }) => {
  const history = useHistory()

  const dispatch = useDispatch()

  const transactionLog = useSelector(
    (state) => state.getHealthInsuredAdminTransactionLog
  )

  useEffect(() => {
    dispatch({
      type: 'GET_HEALTHINSURED_ADMIN_TRANSACTION_LOG',
      payload: { email: userPersonalInfo.email },
    })
  }, [dispatch, userPersonalInfo])

  return (
    <section className="healthInsured__user__profile--transaction-log">
      <h2 className="title">Transaction Log</h2>

      <UserTransactionTable
        userPersonalInfo={userPersonalInfo}
        transactionLog={
          transactionLog && transactionLog.data && transactionLog.data
        }
      />

      <div className="text-center ">
        <button
          className="healthInsured__user__profile__transaction-log--see-more-btn"
          disabled={
            transactionLog &&
            transactionLog.data &&
            transactionLog.data.data.length === 0
              ? true
              : false
          }
          onClick={() =>
            history.push({
              // pathname: `/admin/health_insured/users/profile/${userPersonalInfo.surname}/transaction-log`,
              pathname: `/admin/health_insured/users/transactions/${userPersonalInfo.surname}`,
              state: { userPersonalInfo },
            })
          }
        >
          See More
        </button>
      </div>
    </section>
  )
}

export const UserTransactionTable = ({
  userPersonalInfo,
  transactionLog = {},
  ...props
}) => {
  const userTransactionLog = (transactionLog.data || []).map(
    (log, index) =>
      index < 3 && (
        <tr key={index}>
          <td>&#8358;{log.amount}</td>
          <td>
            <BsDot size="1.2rem" className="text-success" />
            Active
          </td>
          <td>{new Date(log.date).toDateString()}</td>
          <td>
            {log.status === 'Successful' ? (
              <span className="healthInsured__user__profile--transaction-status success">
                {log.status}
              </span>
            ) : (
              <span className="healthInsured__user__profile--transaction-status failed">
                {log.status}
              </span>
            )}
          </td>
        </tr>
      )
  )

  return (
    <table className="table healthInsured__user__profile--transaction-table">
      <thead>
        <tr>
          <th>Amount</th>
          <th>Enrollment Status</th>
          <th>Date</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {userTransactionLog.length > 0 ? (
          userTransactionLog
        ) : (
          <tr>
            <td colSpan="4" className="text-center">
              No transaction logs
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default UserTransactionLog
