import React from 'react'
import Header from '../../../template/header/Header'
import Payment from '../../Payment'
import Progress from '../../Progress'
import './Pay.css'
import useGetOtp from '../walletHooks/useGetOtp'
import { ToastContainer } from 'react-toastify'

export let _phoneNumber = ''

const Pay = () => {
  const [phoneNumber, setPhoneNumber] = React.useState('')

    const method = 'POST'
    const url = `/v2/api/Wallet/GenerateOTPForExistingWallet`
    const nextUrl = '/pay_with_wallet/input_otp'

  _phoneNumber = phoneNumber

  const { isLoading, handleClick } = useGetOtp(method, url, nextUrl, phoneNumber)

  return (
    <section className="payMethSwitch">
      <Header />
      <Payment />
      <div className="l_wall" style={{ paddingLeft: '50px' }}>
        {/* <p style={{ margin: 0, padding: 0 }}>profile@mail.com</p>
                <p style={{ margin: 0, padding: 0 }}>Pay <span style={{color: 'red', fontWeight: 'bold'}}>Amount</span></p> */}
        <div className="pay_health_in">
          <section>
            <h2 style={{ padding: 0, marginBottom: 0 }}>
              Pay for health insurance
            </h2>
            <p className="p-link" style={{ padding: 0, margiTop: 0 }}>
              If you have a sterling bank account, use the number registered to
              it.
            </p>

            <label>Enter your wallet ID</label>
            <br></br>
            <input
              type="tel"
              maxLength={11}
              placeholder="703302345670"
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </section>
          <br></br>
          <button onClick={handleClick} disabled={isLoading}>
            <p>{isLoading ? 'Sending Otp' : 'Link Wallet'}</p>
          </button>
          <ToastContainer />
        </div>
      </div>

      <Progress />
    </section>
  )
}

export default Pay
