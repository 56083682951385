import React from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { FaAngleRight } from 'react-icons/fa'
import { MdPanoramaFishEye } from 'react-icons/md'
import authenticated from '../auth/authenticated'

const Sidebar = () => {
  // instanciate location object
  const location = useLocation()
  const history = useHistory()
  const path = location.pathname

  const logOut = () => {
    localStorage.clear()
    if (!authenticated()) {
      history.push('/admin_signin')
    }
  }

  return (
    <aside>
      <div className="hb-admin-sidebar">
        <ul className="nav flex-column mb-4 ul">
          <li className="nav-item ">
            <Link
              // add active class to current page
              className={
                path === '/admin' || path === '/admin_profile'
                  ? 'nav-link active admin-link-text mt-4 py-2'
                  : 'nav - link  admin-link-text mt-4 py-2'
              }
              to="/admin"
            >
              dashboard
            </Link>
          </li>

          <li className="nav-item">
            <details>
              <summary
                className={
                  path === '/registered_users' ||
                  path === '/active_users' ||
                  path === '/inactive_users' ||
                  path === '/deactivated_users'
                    ? 'hb-sidebar-details admin-link-text active d-flex align-items-center justify-content-between pr-2 pr-sm-4 '
                    : 'hb-sidebar-details admin-link-text d-flex align-items-center justify-content-between pr-2 pr-sm-4'
                }
              >
                <span>Users</span>
                <i>
                  <FaAngleRight className="arrow" />
                </i>
              </summary>

              <Link
                className={
                  path === '/registered_users'
                    ? 'nav-item  active summary-links d-block'
                    : 'nav-item summary-links d-block'
                }
                to="/registered_users"
              >
                Registered
              </Link>

              <Link
                className={
                  path === '/active_users'
                    ? 'nav-item  active summary-links d-block'
                    : 'nav-item summary-links d-block'
                }
                to="active_users"
              >
                Active
              </Link>

              <Link
                className={
                  path === '/inactive_users'
                    ? 'nav-item  active summary-links d-block'
                    : 'nav-item summary-links d-block'
                }
                to="inactive_users"
              >
                Inactive
              </Link>

              <Link
                className={
                  path === '/deactivated_users'
                    ? 'nav-item  active summary-links d-block'
                    : 'nav-item summary-links d-block'
                }
                to="deactivated_users"
              >
                Deactivated
              </Link>
            </details>
          </li>

          <li className="nav-item">
            <Link
              className={
                path === '/administrator'
                  ? 'nav-link active admin-link-text'
                  : 'nav-link admin-link-text'
              }
              to="/administrator"
            >
              Administrator
            </Link>
          </li>

          {/* <li className="nav-item">
            <details >
              <summary className={path === '/sent' || path === '/draft' || path === '/schedule' || path === '/trash' || path === '/message' ?
                'hb-sidebar-details admin-link-text active d-flex align-items-center justify-content-between pr-2 pr-sm-4' :
                'hb-sidebar-details admin-link-text d-flex align-items-center justify-content-between pr-2 pr-sm-4'}
              >
                <span>Notifications</span><i><FaAngleRight className="arrow" /></i>
              </summary>

              <Link
                className={path === '/sent' ? "nav-item active summary-links d-block" : "nav-item  summary-links d-block"}
                to="/sent">Sent
              </Link>

              <Link
                className={path === '/draft' ? "nav-item active summary-links d-block" : "nav-item  summary-links d-block"}
                to="/draft">Drafts
              </Link>

              <Link
                className={path === '/schedule' ? "nav-item active summary-links d-block" : "nav-item  summary-links d-block"}
                to="/schedule">Scheduled
              </Link>

              <Link
                className={path === '/trash' ? "nav-item active summary-links d-block" : "nav-item  summary-links d-block"}
                to="/trash">Trash
              </Link>
            </details>
          </li> */}
          <li className="nav-item hb-sidebar-divider">
            <Link className="nav-link admin-link-text" to="/admin#">
              Services
            </Link>
          </li>

          <ul className="p-0">
            <li className="mb-3 nav-item">
              <Link
                className="nav-link admin-link-text d-flex align-items-center"
                to="/admin/health_insured/dashboard"
              >
                <MdPanoramaFishEye className="me2ms-3 icon" />
                HealthInsured
              </Link>
            </li>

            <li className="mb-3 nav-item">
              <Link
                className="nav-link admin-link-text d-flex align-items-center"
                to="/admin/oneDrugStore/dashboard"
              >
                <MdPanoramaFishEye className="me2ms-3 icon" />
                OneDrugStore
              </Link>
            </li>

            <li className="mb-3 nav-item">
              <Link
                className="nav-link admin-link-text d-flex align-items-center"
                to="/admin/health-finance/dashboard"
              >
                <MdPanoramaFishEye className="me2ms-3 icon" />
                Health Finance
              </Link>
            </li>
          </ul>
          <li className="nav-item mt-5 d-flex justify-content-center">
            <button className="hb-btn-logout nav-link " onClick={logOut}>
              Logout
            </button>
          </li>
        </ul>
      </div>
    </aside>
  )
}

export default Sidebar
