import { IS_LOADING } from '../actions/actionTypes'

const INITIAL_STATE = {
	isLoading: false,
}

export const loadingReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case IS_LOADING:
			return { ...state, isLoading: action.payload }

		default:
			return state
	}
}
