import React from 'react';

const BUtton = ({
  buttonText,
  handleClick
}) => {
  return (
    <button onclick={handleClick} className='health__insured__admin__support__btn'>
      {buttonText}move to pending
    </button>
  );
};

export default BUtton;
