import React from 'react'
import { Table } from 'react-bootstrap'
import LoadingIndicator from '../../../../../../healthBancComponents/LoadingIndicator/LoadingIndicator'

const AdminTransactionTable = ({ transactionLog }) => {
  const displayLogs = () => {
    if (transactionLog.hasOwnProperty('data')) {
      const { data } = transactionLog.data

      return data.length === 0 ? (
        <tr>
          <td colSpan="5" className="text-center">
            No transaction logs
          </td>
        </tr>
      ) : (
        data.map((log, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{log.fullName}</td>
            <td>N{log.amount.toFixed(2)}</td>
            <td>{new Date(log.date).toDateString()}</td>
            <td>{log.status}</td>
          </tr>
        ))
      )
    }
  }

  return (
    <Table striped hover id="healthInsured_admin_transaction_log">
      <thead>
        <tr>
          <th>s/n</th>
          <th>Full Name</th>
          <th>Amount</th>
          <th>Date</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {transactionLog.isLoading ? (
          <tr>
            <td colSpan="5" className="text-center">
              <LoadingIndicator />
            </td>
          </tr>
        ) : (
          displayLogs()
        )}
      </tbody>
    </Table>
  )
}

export default AdminTransactionTable
