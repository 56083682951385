import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useState } from 'react'

const { REACT_APP_BASE_URL } = process.env

const useResendOtp = (resendUrl, phoneNumber) => {
  const { token } = useSelector((state) => state.user.userInfo)
  const [loadingResend, setLoadingResend] = useState(false)

  const handleResend = useCallback(
    async (e) => {
      e.preventDefault()
      setLoadingResend(true)
      try {
        const response = await axios({
          method: 'POST',
          url: `${REACT_APP_BASE_URL}/${resendUrl}`,
          data: { MobileNumber: phoneNumber },
          headers: { Authorization: `Bearer ${token}` },
        })

        toast('Otp Sent')
      } catch (error) {
        if (error.response) {
          const { status } = error.response
          if (status === 404) {
            toast(error.response?.message)
            return
          }
          return
        }
        toast(error.message)
      }
      finally {
        setLoadingResend(false)
      }
    },
    [resendUrl, token]
  )

  return { loadingResend, handleResend }
}

export default useResendOtp
