import {
  GET_HEALTHINSURED_USER_ACQUISITION_REQUEST,
  GET_HEALTHINSURED_USER_ACQUISITION_SUCCESS,
  GET_HEALTHINSURED_USER_ACQUISITION_FAILED,
} from '../../healthInsuredTypes/healthInsuredAdminActionTypes'

export const getHealthInsuredUserAcquisitionRequest = () => ({
  type: GET_HEALTHINSURED_USER_ACQUISITION_REQUEST,
})

export const getHealthInsuredUserAcquisitionSuccess = (data) => ({
  type: GET_HEALTHINSURED_USER_ACQUISITION_SUCCESS,
  payload: data,
})

export const getHealthInsuredUserAcquisitionFailed = (errorMessage) => ({
  type: GET_HEALTHINSURED_USER_ACQUISITION_FAILED,
  payload: errorMessage,
})
