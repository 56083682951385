import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Slider from 'react-slick'
import 'react-toastify/dist/ReactToastify.css'
import Layout from '../../components/Layout/Layout'
import banner from '../../assets/storeImgs/banner.png'
import depressionImg from '../../assets/depressionImg.png'
import CustomSearchInput from '../../../../healthBancComponents/Templates/CustomSearchInput/CustomSearchInput'
import Products from '../../components/Products/Products'
import Brands from '../../components/Brands/Brands'
import FollowOnInsta from '../../components/FollowOnInsta/FollowOnInsta'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './shop.scss'
import { getAllDrugItemRequested } from '../../../../redux/healthBanc/reducer/oneDrugStore/user/drugItemsSlices'

const Shop = () => {
  const [searchValue, setSearchValue] = useState('')
  const [pageNumber, setPageNumber] = useState(1)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllDrugItemRequested({ searchValue, pageNumber }))
  }, [dispatch, pageNumber, searchValue])

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      // {
      //   breakpoint: 480,
      //   settings: {
      //     slidesToShow: 1,
      //     slidesToScroll: 1,
      //   },
      // },
    ],
  }

  // console.log({ isLoading, data, error })

  // const displayDrugItems = (data?.data || []).map((drug) => (
  //   <div key={drug.id} className="product__item">
  //     <Product drug={drug} />
  //   </div>
  // ))

  return (
    <Layout>
      {/* {error && toast.error(error)}
      <ToastContainer /> */}
      <div className="container one-drug__shop pb-5">
        <div className="banner mb-5">
          <img src={banner} alt="banner" className="img-fluid banner--image" />
        </div>

        <main className="main-section">
          <div className="main-section__header d-sm-flex align-items-center justify-content-sm-between">
            <h4 className="section__title">New Products</h4>

            <div className="section__searchbar col-md-6 ">
              <CustomSearchInput
                placeholder="Search entire drug store..."
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value)
                  setPageNumber(1)
                }}
              />
            </div>

            {/* <div className="section__cta">
              <a href="#">See All Products</a>
            </div> */}
          </div>

          {/* <div className="new-product__container my-3">
            <Slider {...settings}>
              <div className="product__item">
                <Product />
              </div>
              <div className="product__item">
                <Product />
              </div>
              <div className="product__item">
                <Product />
              </div>
              <div className="product__item">
                <Product />
              </div>
              <div className="product__item">
                <Product />
              </div>
              <div className="product__item">
                <Product />
              </div>
              <div className="product__item">
                <Product />
              </div>
              <div className="product__item">
                <Product />
              </div>
            </Slider>
          </div> */}

          <hr />

          <div className="products__catalogue">
            {/* {displayDrugItems} */}

            <Products setPageNumber={setPageNumber} pageNumber={pageNumber} />
          </div>

          <div className="mt-5 partner-brands">
            <h6 className="font-weight-bold">Featured Brands</h6>
            <Brands />
          </div>

          <div className="mt-5">
            <FollowOnInsta />
          </div>

          <div className="depressionImg mx-auto">
            <img src={depressionImg} alt="" className="img-fluid" />
          </div>
        </main>
      </div>
    </Layout>
  )
}

export default Shop
