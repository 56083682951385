import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { RiMenu3Line } from 'react-icons/ri'
import HealthBancLogo from '../HealthBancLogo/HealthBancLogo'
import Dropdown from 'react-bootstrap/Dropdown'

const Nav = styled.nav`
  /* background: rgba(0, 0, 0, 0.5); */
  background: transparent;
  color: #21242a;

  .nav-link {
    color: #21242a;
  }

  a.changePassword {
    color: rgba(0, 0, 0, 0.7);
    font-weight: 500;
  }

  .signout {
    outline: none;
    color: red;
    width: auto;
    font-weight: 500;
  }
`

const SubDashboardHeader = ({ user, sign_out }) => {
  return (
    <Nav>
      <nav className="navbar navbar-expand-lg container ">
        <HealthBancLogo />
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span>
            <RiMenu3Line style={{ color: '#21242a' }} />
          </span>
        </button>

        <div
          className="text-center collapse navbar-collapse"
          id="navbarSupportedContent"
        >
          <ul className="ms-auto text-center navbar-nav">
            <li className="nav-item">
              <a className="nav-link" href="/about-us">
                About
              </a>
            </li>
            {/* <li className='nav-item'>
							<a className='nav-link' href='#'>
								FAQs
							</a>
						</li> */}
            <li className="nav-item dropdown">
              <div
                className="nav-link dropdown-toggle"
                id="dropdownMenuOffset"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {user.name.split(' ')[0]}
              </div>

              <div
                className="dropdown-menu px-1 text-left "
                aria-labelledby="dropdownMenuOffset"
                style={{
                  backgroundColor: 'white',
                  width: 'auto',
                  border: 'none',
                }}
              >
                {user.services !== null ? (
                  <>
                    <Dropdown.Header>Recently Used</Dropdown.Header>

                    {user.services.map((service, index) => (
                      <div key={index}>
                        <Link
                          className="changePassword"
                          to={
                            service === 'Pharmmall'
                              ? '/pharmHub'
                              : '/health_profile'
                          }
                        >
                          {service === 'Pharmmall' || service === 'Healthmall'
                            ? 'Healthmall'
                            : 'HealthInsured'}
                        </Link>
                      </div>
                    ))}
                    <Dropdown.Divider />
                  </>
                ) : null}

                {/* {services.length !== 0 ? (
									<Dropdown.Divider>
										{services}
									</Dropdown.Divider>
								) : null} */}
                <Link to="/change-password" className="changePassword">
                  Change Password
                </Link>
                <div className="dropdown-divider"></div>

                <button
                  type="button"
                  className="btn btn-link nav-link signout px-0"
                  onClick={() => sign_out()}
                >
                  Sign Out
                </button>
              </div>
            </li>
            {/* <li className='nav-item dropdown'>
                            <div className='dropdown-toggle' role='button' data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">User</div>
                            <div className="dropdown-menu text-center" aria-labelledby="navbarDropdown">
                                <div className='dropdown-item' role='button'>Action 1</div>
                                <div className='dropdown-item' role='button'>Action  2</div>
                            </div>
                        </li> */}
          </ul>
        </div>
      </nav>
    </Nav>
  )
}

const mapStateToProps = (state) => ({
  user: state.user.userInfo,
})

const mapDispatchToProps = (dispatch) => ({
  sign_out: () => dispatch({ type: 'SIGN_OUT' }),
})

export default connect(mapStateToProps, mapDispatchToProps)(SubDashboardHeader)
