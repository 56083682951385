import React from 'react'
import { useSelector } from 'react-redux'
import NavBar from '../navbar/NavBar'
import Header from '../header/Header'

import FriendsTable from './friendsTable/FriendsTable'

const FriendsSection = () => {
  const friends = useSelector((state) => state.friends)
  const profileStatus = useSelector(
    (state) => state.profileCompletionStatus.data
  )

  return (
    <div>
      <Header />

      <div className="container">
        <div className="d-flex justify-content-center mx-auto">
          <NavBar friends={friends} />
        </div>

        <FriendsTable
          friends={friends.data?.data ?? []}
          isCardTokenized={profileStatus?.tokenizationCompleted}
        />
      </div>
    </div>
  )
}

export default FriendsSection
