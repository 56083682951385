import React from 'react'
import { FiActivity } from 'react-icons/fi'
import LoadingIndicator from '../../../../../../healthBancComponents/LoadingIndicator/LoadingIndicator'
import HealthInsuredPagination from '../../../../HealthInsuredCorporateFlow/components/healthInsuredPagination/HealthInsuredPagination'
import './activityLog.scss'

const ActivityLog = ({ data, handleGoNextPage, handleGoPrevPage }) => {
  const displayLogs = () => {
    if (data.hasOwnProperty('data')) {
      return data.data.data.length === 0 ? (
        <div className="text-center">No logged activity</div>
      ) : (
        data.data.data.map((log) => (
          <div
            key={log.id}
            className="healthInsured__admin__activity-log__activity"
          >
            <div className="healthInsured__admin__activity-log__activity--icon">
              <FiActivity />
            </div>
            <div className="healthInsured__admin__activity-log__activity--activity">
              <div className="healthInsured__admin__activity-log__activity--date">
                {new Date(log.date).toDateString()} @{' '}
                {new Date(log.date).toLocaleTimeString()}
              </div>
              <div className="healthInsured__admin__activity-log__activity--title">
                {log.actionApplied}
              </div>
            </div>
          </div>
        ))
      )
    }
  }
  return (
    <div className="healthInsured__admin__activity-log">
      <h2>Activity Log</h2>
      {data.isLoading ? (
        <div className="text-center">
          <LoadingIndicator />
        </div>
      ) : (
        <>
          {displayLogs()}
          <div className="my-4">
            <HealthInsuredPagination
              prev={handleGoPrevPage}
              next={handleGoNextPage}
              pageDetails={data.hasOwnProperty('data') && data.data}
            />
          </div>
        </>
      )}

      {/* <div className="healthInsured__admin__activity-log__activity">
        <div className="healthInsured__admin__activity-log__activity--icon">
          <FiActivity />
        </div>
        <div className="healthInsured__admin__activity-log__activity--activity">
          <div className="healthInsured__admin__activity-log__activity--date">
            May 12 2020 at 2:30.
          </div>
          <div className="healthInsured__admin__activity-log__activity--title">
            Admin put an account on hold.
          </div>
        </div>
      </div>
      <div className="healthInsured__admin__activity-log__activity">
        <div className="healthInsured__admin__activity-log__activity--icon">
          <FiActivity />
        </div>
        <div className="healthInsured__admin__activity-log__activity--activity">
          <div className="healthInsured__admin__activity-log__activity--date">
            May 12 2020 at 2:30.
          </div>
          <div className="healthInsured__admin__activity-log__activity--title">
            Admin put an account on hold.
          </div>
        </div>
      </div>
      <div className="healthInsured__admin__activity-log__activity">
        <div className="healthInsured__admin__activity-log__activity--icon">
          <FiActivity />
        </div>
        <div className="healthInsured__admin__activity-log__activity--activity">
          <div className="healthInsured__admin__activity-log__activity--date">
            May 12 2020 at 2:30.
          </div>
          <div className="healthInsured__admin__activity-log__activity--title">
            Admin put an account on hold.
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default ActivityLog
