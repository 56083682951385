import { takeLatest, put } from 'redux-saga/effects'
import axios from 'axios'
import {
  addLocalCartItemsFailed,
  addLocalCartItemsRequested,
  addLocalCartItemsSuccessful,
} from '../../../reducer/oneDrugStore/user/cartItemsSlices'

// Uploads cart items from local storage to db and deletes the cart local storage if succssful
function* addLocalCartItemsAsync({ payload }) {
  yield addLocalCartItemsRequested()

  try {
    const { data } = yield axios({
      method: 'POST',
      url: `${process.env.REACT_APP_ONE_DRUG_BASE}/v1/api/Cart/AddLocalCartItem`,
      data: payload,
    })

    yield put(addLocalCartItemsSuccessful(data))

    yield window.localStorage.removeItem('oneDrugCart')
  } catch (error) {
    if (error.response) {
      yield put(
        addLocalCartItemsFailed(error.response.data.responseDescription)
      )
      yield put(addLocalCartItemsFailed(null))
      return
    }

    yield put(addLocalCartItemsFailed(error.message))
    yield put(addLocalCartItemsFailed(null))
  }
}

export function* watchAddLocalCartItems() {
  yield takeLatest(addLocalCartItemsRequested().type, addLocalCartItemsAsync)
}
