import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: {},
  error: null,
}

const updateDrugItemSlice = createSlice({
  name: 'updateDrugItem',
  initialState,
  reducers: {
    updateDrugItemRequested: (state) => {
      state.isLoading = true
      state.data = {}
      state.error = null
    },

    updateDrugItemSuccessful: (state, action) => {
      state.isLoading = false
      state.data = action.payload
      state.error = null
    },

    updateDrugItemFailed: (state, action) => {
      state.isLoading = false
      state.data = {}
      state.error = action.payload
    },
  },
})

export const {
  updateDrugItemRequested,
  updateDrugItemSuccessful,
  updateDrugItemFailed,
} = updateDrugItemSlice.actions
export default updateDrugItemSlice.reducer
