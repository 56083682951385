import { takeEvery, put } from 'redux-saga/effects'
import axios from 'axios'
import {
  getExtendedHealthInsuredAdminProfileFailed,
  getExtendedHealthInsuredAdminProfileRequest,
  getExtendedHealthInsuredAdminProfileSuccess,
} from '../../actions/healthInsuredCreators/adminUserActions/getExtendedHealthInsuredAdminProfile'

function* getExtendedHealthInsuredAdminProfile({ payload }) {
  yield put(getExtendedHealthInsuredAdminProfileRequest())
  try {
    const { data } = yield axios({
      method: 'post',
      url: `/v2/api/Insurance/GetExtendedInsuranceProfileByEmail`,
      data: { Email: payload.email },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('adminInfo')}`,
      },
    }).then((response) => response)

    yield put(getExtendedHealthInsuredAdminProfileSuccess(data))
  } catch (error) {
    yield put(getExtendedHealthInsuredAdminProfileFailed(error.response))
  }
}

export function* watchGetUserHealthInsuredAdminProfile() {
  yield takeEvery(
    'GET_EXTENDED_HEALTHINSURED_ADMIN_PROFILE',
    getExtendedHealthInsuredAdminProfile
  )
}
