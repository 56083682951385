import React from 'react'
import { Spinner } from 'react-bootstrap'

const LoadingIndicator = () => {
  return (
    <Spinner animation="border" role="status" style={{ width: 50, height: 50 }}>
      <span className="sr-only">Loading...</span>
    </Spinner>
  )
}

export default LoadingIndicator
