import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Card, Row, Col } from "react-bootstrap"
import { FiUser } from "react-icons/fi"
import { BsDownload } from "react-icons/bs"
import { BsFileEarmarkText } from "react-icons/bs"

// import

import "./hospitalListAndTemplate.scss"

const HospitalListAndTemplate = () => {
  const dispatch = useDispatch()

  const { insuranceService } = useSelector(
    (state) => state.corporateProfileCompletion?.data?.data ?? {}
  )
  const { isLoading } = useSelector((state) => state.corporateProfileCompletion)

  useEffect(() => {
    dispatch({ type: "CORPORATE_PROFILE_COMPLETION_STATUS" })
  }, [dispatch])

  return (
    <section>
      <Card className='hospital__list'>
        <Row>
          <Col className='card__icon--container'>
            <div className='card__icon--icon'>
              <FiUser />
            </div>
          </Col>
        </Row>
        <Row>
          <Col className='card__content'>
            <h3 className='card__content--title'>Partner Hospitals</h3>
            <p className='card__content--description'>
              Your subscribers will choose hospitals based on listed health
              Institutions in this document
            </p>
            <div className='mb-3'>
              {!isLoading && (
                <a
                  href={
                    insuranceService && insuranceService === "Axamansard"
                      ? `https://docs.google.com/file/d/1paYnGZDZOBTIEvFjLXS7YrYru4BQmt2q/edit?usp=docslist_api&filetype=msexcel`
                      : `https://docs.google.com/file/d/18jjs_jCwvqYGCVZzsyLmPUwC7nJAsGpb/edit?usp=docslist_api&filetype=msexcel`
                  }
                  target='_blank'
                  download
                  className='card__link'
                >
                  {insuranceService && insuranceService === "Axamansard"
                    ? "Axa Mansard Hospitals List"
                    : "Hygeia Hospitals List"}
                  <span className='card__link--download__icon'>
                    {" "}
                    <BsDownload />{" "}
                  </span>
                </a>
              )}
            </div>
          </Col>
        </Row>
      </Card>

      <Card className='hospital__template'>
        <Row>
          <Col className='card__icon--container'>
            <div className='card__icon--icon'>
              <BsFileEarmarkText />
            </div>
          </Col>
        </Row>
        <Row>
          <Col className='card__content'>
            {!isLoading && (
              <>
                <h3 className='card__content--title'>
                  {insuranceService && insuranceService === "Axamansard"
                    ? "Axa Mansard"
                    : "Hygeia"}{" "}
                  Excel Template
                </h3>
              </>
            )}
            <p className='card__content--description'>
              To upload your company beneficiaries, you need to upload an Excel
              document that contains their details and preferences.
            </p>
            <div>
              {!isLoading && (
                <>
                  <a
                    href={
                      insuranceService && insuranceService === "Axamansard"
                        ? `https://docs.google.com/file/d/1bZU6oj738tKWnOU5q9xM8aVWCE1TY5tF/edit?usp=docslist_api&filetype=msexcel`
                        : `https://docs.google.com/file/d/1IVejs4RNdFEaJ0iMTX6HE3Wnu_uf6fzJ/edit?usp=docslist_api&filetype=msexcel`
                    }
                    target='_blank'
                    download
                    className='card__link'
                  >
                    Download Excel template{" "}
                    <span className='card__link--download__icon'>
                      {" "}
                      <BsDownload />{" "}
                    </span>
                  </a>
                </>
              )}
            </div>
          </Col>
        </Row>
      </Card>
    </section>
  )
}

export default HospitalListAndTemplate
