import React from 'react'
import { Accordion, Card, Button as BootstrapBtn } from 'react-bootstrap'

import { ReactComponent as Checker } from '../../assets/checker.svg'

const ProvidersCard = ({
  ProviderLogo,
  provider,
  choosenProvider,
  chooseProvider,
  alt,
  ...props
}) => {
  return (
    <div
      className={
        choosenProvider === provider
          ? 'choose__provider-provider-card  select'
          : 'choose__provider-provider-card'
      }
      onClick={() => chooseProvider(provider)}
    >
      {choosenProvider === provider && (
        <i>
          <Checker className="checker" />
        </i>
      )}
      <img src={ProviderLogo} className="provider-logo" alt={alt} />

      {props.benefitFile && (
        <div className="benefits text-center">
          <a
            href={props.benefitFile}
            target="_blank"
            rel="noopener noreferrer"
            className="text-info"
          >
            See benefits...
          </a>
        </div>
      )}
    </div>
  )
}

export default ProvidersCard
