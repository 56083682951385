const maritalStatus = [
  {
    name: 'Married',
    id: 'Married'
  },
  {
    name: 'Single',
    id: 'Single'
  },
  {
    name: 'Divorced',
    id: 'Divorced'
  },
  {
    name: 'Widow',
    id: 'Widow'
  },
  {
    name: 'Widower',
    id: 'Widower'
  }
];

export default maritalStatus;