import React from 'react'
import HealthInsuredDashboardStructure from '../../HealthInsuredDashboardStructure/HealthInsuredDashboardStructure'
import { ScreenNameWithBackBtn } from '../../screens/healthInsuredUserProfile/HealthInsuredUserProfile'
import { UserActivity } from '../../components/users/userProfile/activityLog/ActivityLog'
import HealthInsuredPagination from '../../../HealthInsuredCorporateFlow/components/healthInsuredPagination/HealthInsuredPagination'

import './healthInsuredAdminUserActivities.scss'

const HealthInsuredAdminUserActivities = () => {
  return (
    <HealthInsuredDashboardStructure
      title={<ScreenNameWithBackBtn screenName="Activity Log" />}
    >
      <section className="healthInsuredAdimUserActivities">
        <UserActivity />

        <div className="text-right">
          {/* <HealthInsuredPagination prev='' next='' pageDetails='' /> */}
        </div>
      </section>
    </HealthInsuredDashboardStructure>
  )
}

export default HealthInsuredAdminUserActivities
