import React, { useState } from 'react'
import { useHistory } from 'react-router'
import NavBar from '../../navbar/NavBar'
import Header from '../../header/Header'
import AddFriendByEmailModal from './AddFriendByEmailModal'

import './_addFriendsSection.scss'

const AddFriendsSection = () => {
  const [show, setShow] = useState(false)

  const history = useHistory()

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <>
      <Header />
      <div className="add-friends-section ">
        <div className="d-flex justify-content-center mb-4">
          <NavBar />
        </div>

        <div className="container add-friends-box">
          <div className="add-friends-box__content">
            <button
              className="back-btn"
              onClick={() => history.push('/health-friends')}
            >
              &#8592; Go back to friends table
            </button>
            <h2 className="add-friends-box__title">Pay For Friends</h2>

            <div className="add-friends-box__options">
              <div
                className="add-friends-box__options--1"
                onClick={() =>
                  history.push('/health-friends/add-friend-full-details')
                }
              >
                <p>
                  Provide full details of the friend you would like to pay for
                </p>
              </div>

              <div className="add-friends-box__options--2" onClick={handleShow}>
                <p>Provide email address of the friend only</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddFriendByEmailModal
        show={show}
        handleShow={handleShow}
        handleClose={handleClose}
      />
    </>
  )
}

export default AddFriendsSection
