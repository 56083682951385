import React from 'react'
import { TiLockClosedOutline } from 'react-icons/ti'
import { FaUserCircle } from 'react-icons/fa'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { useSelector, connect } from 'react-redux'

import Logo from '../../../../../../healthBancComponents/HealthBancLogo/HealthBancLogo'

const NavigationBar = ({ sign_out }) => {
  // instanciate history object
  const history = useHistory()

  const authUser = useSelector((state) => state.user.userInfo.isAuth)
  const user = useSelector((state) => state.user.userInfo.name)

  return (
    <header className="health-insurance-header">
      <Navbar
        bg="light"
        expand="lg"
        className="px-3 template-headers py-1"
        collapseOnSelect
      >
        <Navbar.Brand href="#home">
          <Logo />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className=" align-items-center ms-auto">
            {authUser ? (
              <>
                {/* <Nav.Link
                className="login text-center "
                onClick={() => history.push('/dashboard')}
              >
                Dashboard
              </Nav.Link> */}
              </>
            ) : (
              <>
                <Nav.Link
                  className="login text-center"
                  onClick={() => history.push('/signin')}
                >
                  <TiLockClosedOutline className="lock-up-key" />
                  Login
                </Nav.Link>
                <Nav.Link
                  to="#home"
                  className=" text-center"
                  onClick={() => history.push('/signup')}
                >
                  <button className="sign-up py-1 px-3">Sign Up</button>
                </Nav.Link>
              </>
            )}
            {authUser && <FaUserCircle className="avatar" />}
            {authUser && (
              <NavDropdown title={user} id="collasible-nav-dropdown">
                <NavDropdown.Item
                  href="#action"
                  className="button__sign-out"
                  onClick={() => sign_out()}
                >
                  Sign Out
                </NavDropdown.Item>
              </NavDropdown>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  )
}

const mapDispatchToProps = (dispatch) => ({
  sign_out: () => dispatch({ type: 'SIGN_OUT' }),
})

export default connect(null, mapDispatchToProps)(NavigationBar)
