import React, { useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';

const Nav = () => {
  //get current page
  const location = useLocation().pathname.split('/').splice(-1).join();

  useEffect(() => {

    //add active class to the li to indicate current page
    const listItems = document.querySelectorAll('.healthInsured__admin__notification--nav-item');

    listItems.forEach((li) => {
      li.textContent.toLocaleLowerCase() === location && li.classList.add('healthInsured__admin__notification--active');
    });
  }, [location])


  return (
    <nav className="healthInsured__admin__notification">
      <ul className='healthInsured__admin__notification--nav'>
        <li className='healthInsured__admin__notification--nav-item'>
          <Link to='/health_insured/admin/notifications/drafts' className='healthInsured__admin__notification--nav-link'>
            Drafts
          </Link>
        </li>
        <li className='healthInsured__admin__notification--nav-item'>
          <Link to='/health_insured/admin/notifications/scheduled' className='healthInsured__admin__notification--nav-link'>
            Scheduled
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
