import { call, put, takeEvery } from '@redux-saga/core/effects'
import { getFamilyMembersRequested } from '../../../reducer/healthInsuredReducer/healthInsuredFamily/getFamilyMembersReducer'
import {
  removeFamilyMemberRequested,
  removeFamilyMemberFailed,
  removeFamilyMemberSuccessful,
} from '../../../reducer/healthInsuredReducer/healthInsuredFamily/removeFamilyMemberReducer'
import { removeFamilyMemberAPI } from './familyApis'

function* handleRemoveFamilyMember({ payload = {} }) {
  try {
    yield removeFamilyMemberRequested()
    const { data } = yield call(removeFamilyMemberAPI, payload)
    yield put(removeFamilyMemberSuccessful(data))

    yield put(getFamilyMembersRequested())
  } catch (error) {
    if (error.response) {
      alert(error.response.data.message)
      yield put(removeFamilyMemberFailed([error.response.data.message]))
    } else {
      alert(error.message)
    }
  }
}

export function* watchRemoveFamilyMember() {
  yield takeEvery(removeFamilyMemberRequested.type, handleRemoveFamilyMember)
}
