import {
  FETCH_CORPORATE_BENEFICIARIES_FAILED,
  FETCH_CORPORATE_BENEFICIARIES_REQUEST,
  FETCH_CORPORATE_BENEFICIARIES_SUCCESS,
} from '../../healthInsuredTypes/corporateActionTypes'

export const fetchActiveCorporateBeneficiariesRequest = () => ({
  type: FETCH_CORPORATE_BENEFICIARIES_REQUEST,
})

export const fetchActiveCorporateBeneficiariesSuccess = (payload) => ({
  type: FETCH_CORPORATE_BENEFICIARIES_SUCCESS,
  payload,
})

export const fetchActiveCorporateBeneficiariesFailed = (errorMessage) => ({
  type: FETCH_CORPORATE_BENEFICIARIES_FAILED,
  payload: errorMessage,
})
