import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import axios from 'axios'

import Header from '../template/header/Header'
import SuccessImg from '../../assets/success.png'
import EnrolleCard from './enrolleCard/EnrolleCard'
import Loading from '../../../../pharmHub/admin/components/Spinner/Spinner'
import Alert from '../../../../../healthBancServices/healthInsured/template/alert/ALert'

import { getHealthInsuredIndividualProfileSuccess } from '../../../../../redux/healthBanc/actions/healthInsuredCreators/healthInsuredIndividualProfile'

import { profileCompletionSuccess } from '../../../../../redux/healthBanc/actions/healthInsuredCreators/profileCompletionStatus'
const profileUrl = '/v2/api/Insurance/GetUserInsuranceProfile'
const customerStateUrl = '/v2/api/Insurance/GetProfileCompletion'

const SuccessfulSignUp = () => {
  const [profile, setProfile] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const history = useHistory()
  const { profileCompletionStatus } = useSelector(
    (state) => state.profileCompletionStatus.data
  )

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)

        const {
          data: { data },
        } = await axios.get(profileUrl)

        setProfile(data)
        dispatch(getHealthInsuredIndividualProfileSuccess(data))

        setLoading(false)
      } catch (error) {
        setLoading(false)
        error.response
          ? setError(error.response.data.message)
          : setError(error.message)
      }
    })()
  }, [])

  const handleGoto = async () => {
    if (
      profileCompletionStatus?.referee &&
      profileCompletionStatus?.tokenizationCompleted &&
      profileCompletionStatus?.profileCompleted
    ) {
      return history.push('/health_profile')
    }

    //redirect a user to profile page if he has tokenize his card and completed
    //registration else redirect to sign up page
    try {
      const response = await axios.get(customerStateUrl)
      const { profileCompleted, tokenizationCompleted } = response.data.data

      profileCompletionSuccess(response.data.data)

      /**redirect a user to profile page if he has registered and tokenized else ask them to sign up */
      if (profileCompleted && tokenizationCompleted) {
        return history.push('/health_profile')
      }

      return history.push('/health_signup')
    } catch (error) {
      error.response
        ? setError(error.response.data.message)
        : setError(error.message)
    }
  }

  return (
    <section className="axa-mansard-sign-up-success align-items-center ">
      <Header />
      {error && (
        <Alert variant="danger" showAlert={true}>
          {' '}
          {error}{' '}
        </Alert>
      )}

      {loading ? (
        <Loading />
      ) : (
        <div className="success-modal">
          <img
            src={SuccessImg}
            alt="success message"
            className="success-img"
            data-aos="fade-up-right"
          />
          <h2 className="title">Success</h2>

          <p className="message">
            Your health insurance plan profile has been created{' '}
            {window.innerWidth > 420 && <br />} successfully.
          </p>

          <div className="enrolle-info w-100">
            <EnrolleCard
              title="Primary Care Provider"
              name="Name"
              nameValue={profile.careProviderName}
              address="Address"
              addressValue={profile.cpAddress}
              enrollee={'Enrolee number'}
              enrolleNumber={profile.enrolleeNumber}
            />
          </div>
          <button className="button-goto" onClick={handleGoto}>
            Go to profile
          </button>
        </div>
      )}
    </section>
  )
}

export default SuccessfulSignUp
