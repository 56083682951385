import React from 'react'
import { GoSearch } from 'react-icons/go'

const SearchInput = ({ handleChange, searchName, searchTerms, className }) => {
  const searchSubmit = (e) => {
    e.preventDefault()
  }

  return (
    <div className="health__insurance__template-search">
      <form
        className={`search-form d-flex ${className}`}
        onSubmit={searchSubmit}
      >
        <label className="label-search">
          <i>
            <GoSearch className="me2 search-icon" />
          </i>
          <input
            type="search"
            name={searchName === 'undefine' ? 'search' : searchName}
            className="search"
            placeholder="Search"
            value={searchTerms}
            onChange={handleChange}
            incremental
            autoComplete={true}
          />
        </label>
      </form>
    </div>
  )
}

export default SearchInput
