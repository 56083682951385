import { put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import {
  chargeCorporateCardFailed,
  chargeCorporateCardRequest,
  chargeCorporateCardSuccess,
} from '../../actions/healthInsuredCreators/corporateUserActions/chargeCorporateCardAction'

function* chargeCorporateCard({ payload }) {
  yield put(chargeCorporateCardRequest())

  const cardDetails = {
    card: {
      cvv: payload.cvv,
      number: payload.cardNumber.split(' ').join(''),
      expiry_month: Number(payload.expiryDate.split('/')[0]),
      expiry_year: Number(payload.expiryDate.split('/')[1]),
    },
    pin: payload.pin,
  }

  try {
    const { data } = yield axios
      .post('/v2/api/Tokenization/ChargeCard', cardDetails)
      .then((response) => response)

    //listen to paystack cross tab communication for payment
    window.addEventListener('message', (e) => {
      if (e.origin !== 'https://standard.paystack.co') return

      const { message, status } = JSON.parse(
        e.data.replace('PaystackAuth', ' ')
      )

      if (status === 'success') {
        alert(message)
      } else {
        throw Error(message)
      }
    })
    yield put(chargeCorporateCardSuccess(data))
  } catch (error) {
    yield put(chargeCorporateCardFailed(error.response))
  }
}

export function* watchChargeCorporateCard() {
  yield takeEvery('CHARGE_CORPORATE_CARD', chargeCorporateCard)
}
