import React from 'react'
import { BsFilterRight } from 'react-icons/bs'

const Filter_Sort = ({ filter, sort, filterOptions }) => {
  return (
    <div>
      <form className="search-form px-3 d-flex">
        <label className="label-filter d-flex align-items-center me3">
          Filter
          <i className="filter-icon-holderms-3">
            <select
              name="Filter"
              id="pet-select"
              className="pl-2 pr-2 py-2 filter"
              onChange={filter}
            >
              <option value="">All</option>
              {filterOptions}
            </select>
            <BsFilterRight className="filter-icon me2" />
          </i>
        </label>
        <label className="label-sort">
          {' '}
          Sort by
          <select
            name="reg-sort"
            className="pl-3 pr-4 py-2ms-3 sort"
            onChange={sort}
          >
            <option value="4">Most recent</option>
            <option value="1">First name</option>
            <option value="2">Last name</option>
            <option value="3">Email</option>
          </select>
        </label>
      </form>
    </div>
  )
}

export default Filter_Sort
