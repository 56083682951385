import { takeLatest, put } from 'redux-saga/effects'
import axios from 'axios'
import {
  updatePharmacyRequested,
  updatePharmacySuccessful,
  updatePharmacyFailed,
  getAllPharmacyRequested,
} from '../../../reducer/oneDrugStore/admin/pharmacySlices'

function* updatePharmacyAsync({ payload }) {
  try {
    yield updatePharmacyRequested()

    const { data } = yield axios({
      method: 'PUT',
      url: `${process.env.REACT_APP_ONE_DRUG_BASE}/v1/api/Pharmacy/Edit?pharmacyId=${payload.pharmacyId}`,
      headers: { Authorization: `Bearer ${localStorage.getItem('adminInfo')}` },
      data: payload,
    })

    yield put(updatePharmacySuccessful(data))
    yield put(getAllPharmacyRequested())
    yield payload.history.goBack()
  } catch (error) {
    if (error.response) {
      yield put(updatePharmacyFailed(error.response.data))
      yield put(updatePharmacyFailed(null))
      return
    }

    yield put(updatePharmacyFailed(error.message))
    yield put(updatePharmacyFailed(null))
  }
}

export function* watchUpdatePharmacy() {
  yield takeLatest(updatePharmacyRequested.type, updatePharmacyAsync)
}
