import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Row, Col } from 'react-bootstrap'
import { Formik, Field } from 'formik'
import * as yup from 'yup'
import CustomButton from '../../../../../healthBancComponents/Templates/CustomButton'
import CorporateDashboardStructure from '../../corporateDashboardStructure/CorporateDashboardStructure'
import './CompanyProfileUpdate.scss'

const CompanyProfileUpdate = () => {
  const dispatch = useDispatch()
  const updateCorporateProfile = useSelector(
    (state) => state.updateCorporateProfile
  )

  const companyDetailsSchema = yup.object().shape({
    industry: yup.string().required('Required'),
    companySize: yup.string().required('Required'),
  })

  const handleCompanyProfileUpdate = (values) => {
    dispatch({ type: 'UPDATE_CORPORATE_PROFILE', payload: { ...values } })
  }

  return (
    <CorporateDashboardStructure screenTitle="Company Profile Update">
      <div className="company__profile__update w-100 h-100">
        <Formik
          initialValues={{ industry: '', companySize: '' }}
          validationSchema={companyDetailsSchema}
          onSubmit={(values) => handleCompanyProfileUpdate(values)}
        >
          {(props) => (
            <Form className="px-md-6" onSubmit={props.handleSubmit}>
              <Row className="mb-4 mt-4">
                <Col className="field__desc" md={4}>
                  Industry information
                </Col>

                <Col md={7}>
                  <Form.Label>
                    What industry does your company belong
                  </Form.Label>

                  <Field
                    as="select"
                    className="form-control col-sm-8 col-md-8"
                    name="industry"
                    value={props.industry}
                    onChange={props.handleChange}
                  >
                    <option value="">- Select Service Type -</option>
                    <option value="Financial Services">
                      Financial Services
                    </option>
                    <option value="Health Services"> Health Services</option>
                    <option value="Engineering Services">
                      Engineering Services
                    </option>
                    <option value="Human Resource Services">
                      Human Resource Services
                    </option>
                    <option value="Transport Services">
                      Transport Services
                    </option>
                    <option value="Others">Others</option>
                  </Field>
                  {props.errors.industry && props.touched.industry ? (
                    <div className="error__message">
                      *{props.errors.industry}
                    </div>
                  ) : null}
                </Col>
              </Row>

              <Row>
                <Col className="field__desc" md={4}>
                  Company Size
                </Col>
                <Col md={7}>
                  <Form.Label>What is the size of your company?</Form.Label>
                  <Field
                    as="select"
                    className="form-control col-sm-8 col-md-8"
                    name="companySize"
                    value={props.companySize}
                    onChange={props.handleChange}
                  >
                    <option value="">- Select Company Size -</option>
                    <option value="less than 5">Less than 5</option>
                    <option value="5-20">5 - 20</option>
                    <option value="20-100">20 - 100</option>
                    <option value="100-500">100 - 500</option>
                    <option value="500-1000">500 - 1000</option>
                    <option value="greater than 1000">Greater than 1000</option>
                  </Field>
                  {props.errors.companySize && props.touched.companySize ? (
                    <div className="error__message">
                      *{props.errors.companySize}
                    </div>
                  ) : null}
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md={4}></Col>
                <Col className="col-sm-8">
                  <div className="col-xs-12 col-sm-8 col-md-5 p-0">
                    <CustomButton
                      type="submit"
                      disabled={
                        updateCorporateProfile &&
                        updateCorporateProfile.isLoading
                          ? true
                          : false
                      }
                    >
                      {updateCorporateProfile &&
                      updateCorporateProfile.isLoading
                        ? 'Updating...'
                        : 'Update Company Profile'}
                    </CustomButton>
                  </div>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </CorporateDashboardStructure>
  )
}

export default CompanyProfileUpdate
