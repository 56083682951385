import {
  CHARGE_CORPORATE_CARD_FAILED,
  CHARGE_CORPORATE_CARD_REQUEST,
  CHARGE_CORPORATE_CARD_SUCCESS,
} from '../../healthInsuredTypes/corporateActionTypes'

export const chargeCorporateCardRequest = () => ({
  type: CHARGE_CORPORATE_CARD_REQUEST,
})

export const chargeCorporateCardSuccess = (data) => ({
  type: CHARGE_CORPORATE_CARD_SUCCESS,
  payload: data,
})

export const chargeCorporateCardFailed = (errorMessage) => ({
  type: CHARGE_CORPORATE_CARD_FAILED,
  payload: errorMessage,
})
