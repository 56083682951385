import React from 'react'
import { useHistory, Link } from 'react-router-dom'

import { ReactComponent as GoBackArrow } from '../../../../assets/goBackArrow.svg'

const GoBackButton = ({ children, ...props }) => {
  const history = useHistory()

  return (
    <Link
      {...props}
      onClick={() => history.goBack()}
      className={`health__insured__template-btn-goback ${props.className}`}
    >
      <GoBackArrow className="me2" />
      {children}
    </Link>
  )
}

export default GoBackButton
