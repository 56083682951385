import React from 'react'
import ReactHTMLTableToExcel from 'react-html-table-to-excel'

import { Row, Col, FormCheck, InputGroup, Form, Button } from 'react-bootstrap'
import { BsSearch } from 'react-icons/bs'
import './beneficiaryFilter.scss'

const BeneficiaryFilter = ({ search, sort, tableName = '' }) => {
  return (
    <div className='beneficiary__review--filter__options'>
      <Row className='options__header'>
        <Col sm={1}>
          <FormCheck />
        </Col>

        <Col sm='3' md='4'>
          <div className='pr-2'>
            <InputGroup.Prepend>
              <InputGroup.Text>
                <BsSearch />
              </InputGroup.Text>
              <Form.Control placeholder='Search' onChange={search} />
            </InputGroup.Prepend>
          </div>
        </Col>

        <Col md='4' className='sort__by'>
          <span className='pr-2'>Sort by</span>
          <span>
            <Form.Control as='select' sm='5' onChange={sort}>
              <option value=''>- Select -</option>
              <option value='1'>First Name</option>
              <option value='2'>Last Name</option>
              <option value='3'>Email</option>
              <option value='4'>Date Created</option>
            </Form.Control>
          </span>
        </Col>

        <Col className='export__btn'>
          <Button
            as={ReactHTMLTableToExcel}
            variant='outline-dark'
            id='table-xls-button'
            className='download-table-xls-button'
            table={tableName}
            filename='beneficiaries_review_table'
            sheet='tablexls'
            buttonText='Export as Excel'
          />
        </Col>
      </Row>
    </div>
  )
}

export default BeneficiaryFilter
