import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import axios from 'axios'

import SectionCard from '../sectionCard/SectionCard'
import NavBar from '../navbar/NavBar'
import { getHealthInsuredIndividualProfileSuccess } from '../../../../../redux/healthBanc/actions/healthInsuredCreators/healthInsuredIndividualProfile'
import { profileCompletionSuccess } from '../../../../../redux/healthBanc/actions/healthInsuredCreators/profileCompletionStatus'
import Alert from '../../../../../healthBancServices/healthInsured/template/alert/ALert'

const customerStateUrl = '/v2/api/Insurance/GetProfileCompletion'
const profileUrl = '/v2/api/Insurance/GetUserInsuranceProfile'

const MainSection = () => {
  const [profile, setProfile] = useState({})
  const [error, setError] = useState()
  const [loading, setLoading] = useState()

  const dispatch = useDispatch()
  const history = useHistory()

  const { profileCompleted, referee } = useSelector(
    (state) =>
      state.profileCompletionStatus.data?.data ??
      state.profileCompletionStatus.data ??
      {}
  )

  useEffect(() => {
    dispatch({ type: 'PROFILE_COMPLETION_STATUS' })
  }, [dispatch])

  useEffect(() => {
    if (referee && !profileCompleted) {
      history.push('/health_signup')
    }
  }, [history, profileCompleted, referee])

  useEffect(() => {
    setLoading(true)

    function getProfile() {
      return axios.get(profileUrl)
    }

    function getUserStatus() {
      return axios.get(customerStateUrl)
    }

    axios
      .all([getProfile(), getUserStatus()])
      .then(
        axios.spread((profileResponse, profileCompletionStatusResponse) => {
          const { profileCompleted, tokenizationCompleted } =
            profileCompletionStatusResponse.data

          dispatch(
            profileCompletionSuccess(profileCompletionStatusResponse.data)
          )

          // // check if a user has signed up and tokenized
          if (profileCompleted === false || tokenizationCompleted === false) {
            history.push('/health_signup')
          }

          setProfile(profileResponse.data.data)
          getHealthInsuredIndividualProfileSuccess(profileResponse.data.data)

          setLoading(false)
        })
      )
      .catch((error) => {
        setLoading(false)

        error.response
          ? setError(error.response.data.message)
          : setError(error.message)
      })
  }, [dispatch, history])

  return (
    <main className="axa-mansard-dashboard-mainsection px-3 pb-4">
      <NavBar />
      {error && (
        <Alert variant="danger" showAlert={true}>
          {' '}
          {error}{' '}
        </Alert>
      )}
      <SectionCard loading={loading} userProfile={profile} error={error} />
    </main>
  )
}

export default MainSection
