import React from 'react'
import styled from 'styled-components'

import HealthBancLogo from '../HealthBancLogo/HealthBancLogo'
import BackgroundEclipse from '../../assets/healthBancLandingPageImgs/Ellipse1.png'

const LogoContainer = styled.div`
  position: absolute;
  margin: 10px 0 0 20px;
  z-index: 1;
`

const Canvas = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap');

  font-family: 'Inter', sans-serif;
  position: relative;
  min-height: 100vh;
  max-width: 100vw;
  background: #e5e5e5 url(${BackgroundEclipse});
  background-size: 80% 100%;
  background-repeat: no-repeat;
  color: #0c162c;

  .formContainer {
    margin: 70px 0;
    max-width: 400px;
    background-color: #fff;
    border: 0.5px solid #cccccc;
    /* box-sizing: border-box; */
    box-shadow: 0px 5px 10px rgba(33, 63, 125, 0.05);
    border-radius: 2px;

    .formTitle {
      color: #0c162c;
      font-weight: bold;
      font-size: 26px;
      text-align: center;
    }

    .subTitle {
      color: #494d55;
      font-size: 14px;
      text-align: center;
    }
  }
`

const Template = ({ children, showLogo, formTitle, subTitle, topElement }) => {
  return (
    <>
      {showLogo && (
        <LogoContainer>
          <HealthBancLogo />
        </LogoContainer>
      )}

      <Canvas className="row align-items-center mx-0 px-2">
        {/* <div className='d-flexms-md-5 my-3'>
                    <HealthBancLogo />
                    <div className='d-flex'>
                        <div>Services</div>
                        <div>About</div>
                        <div>Help</div>
                    </div>
                </div> */}

        {formTitle || subTitle ? (
          <div className="col mx-md-auto formContainer container">
            {topElement}

            <div className="my-4">
              <div className="formTitle">{formTitle}</div>
              <div className="subTitle">{subTitle}</div>
            </div>

            {children}
          </div>
        ) : (
          children
        )}
      </Canvas>
    </>
  )
}

export default Template
