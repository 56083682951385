import { SET_HEALTH_PLAN } from '../../actions/healthInsuredTypes/healthInsuredUserChoosenPlanType'

const initialState = {
  choosenPlan: null,
}

export const userHealthPlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HEALTH_PLAN:
      return { ...state, choosenPlan: action.payload }

    default:
      return state
  }
}
