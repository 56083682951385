import React from 'react';
import { Table } from 'react-bootstrap';

const HealthCareTable = ({ tableId, tableRow }) => {

  return (
    <Table hover responsive className='template__health__insured--table' borderless id={tableId}>
      <thead className='hb-admin-table-h'>
        <tr>
          <th>Name</th>
          <th>coverage</th>
          <th>address</th>
          <th>city</th>
          <th>state</th>
        </tr>
      </thead>
      <tbody >
        {tableRow}
      </tbody>
    </Table>
  );
};

export default HealthCareTable;
