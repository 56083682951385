import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'
import { FiActivity } from 'react-icons/fi'
import HealthInsuredDashboardStructure from '../../../HealthInsuredDashboardStructure/HealthInsuredDashboardStructure'
import HealthInsuredPagination from '../../../../HealthInsuredCorporateFlow/components/healthInsuredPagination/HealthInsuredPagination'
import LoadingIndicator from '../../../../../../healthBancComponents/LoadingIndicator/LoadingIndicator'

import './healthInsuredExtendedUserActivities.scss'

const HealthInsuredExtendedUserActivities = () => {
  const [pageNumber, setPageNumber] = useState(1)

  const dispatch = useDispatch()
  const activityLog = useSelector(
    (state) => state.getHealthInsuredAdminUserActivityLogs
  )
  const history = useHistory()

  const userEmail = history.location.state.hasOwnProperty('userEmail')
    ? history.location.state.userEmail
    : ''

  useEffect(() => {
    dispatch({
      type: 'GET_HEALTHINSURED_ADMIN_USER_ACTIVITY_LOGS',
      payload: { email: userEmail, pageNumber },
    })
  }, [dispatch, pageNumber, userEmail])

  const handleGoToPrevPage = () => {
    if (activityLog.hasOwnProperty('data')) {
      if (activityLog.data.pageNumber === 1) {
        return
      } else {
        setPageNumber(pageNumber - 1)
      }
    }
  }

  const handleGoToNextPage = () => {
    if (activityLog.hasOwnProperty('data')) {
      if (activityLog.data.pageNumber === activityLog.data.pageCount) {
        return
      } else {
        setPageNumber(pageNumber + 1)
      }
    }
  }

  const displayActivities = () => {
    if (activityLog.hasOwnProperty('data')) {
      const { data } = activityLog.data

      return data.length === 0 ? (
        <Row className="text-center mt-4">
          <Col>
            <p className="text-center">No Activities recorded</p>
          </Col>
        </Row>
      ) : (
        data.map((activity) => (
          <Row
            key={activity.id}
            className="extendedUserActivities__container__activity"
          >
            <div className="extendedUserActivities__container__activity--icon">
              <FiActivity />
            </div>

            <Col className="extendedUserActivities__container__activity--title">
              <div>{activity.actionApplied}</div>
            </Col>
            <Col className="extendedUserActivities__container__activity--date">
              {new Date(activity.date).toDateString()}
            </Col>
          </Row>
        ))
      )
    }
  }

  return (
    <HealthInsuredDashboardStructure title="Activity Log">
      <section className="extendedUserActivities__container">
        {activityLog.isLoading ? (
          <div className="text-center">
            {' '}
            <LoadingIndicator />{' '}
          </div>
        ) : (
          <>
            {displayActivities()}
            <div className="d-flex justify-content-end mt-3">
              <HealthInsuredPagination
                next={handleGoToNextPage}
                prev={handleGoToPrevPage}
                pageDetails={
                  activityLog.hasOwnProperty('data') && activityLog.data
                }
              />
            </div>
          </>
        )}
      </section>
    </HealthInsuredDashboardStructure>
  )
}

export default HealthInsuredExtendedUserActivities
