import axios from 'axios'

import { combineReducers } from 'redux'

import { signupReducer } from './healthBanc/reducer/signupReducer'
import {
  serviceProvider,
  listOfHealthCareProviders,
} from './healthBanc/reducer/healthInsuredReducer/serviceProvider'
import { townOfResidence } from './healthBanc/reducer/healthInsuredReducer/townOfResidence'
import { healthCareProviders } from './healthBanc/reducer/healthInsuredReducer/healthCareProvider'
import { healthInsuredStateAndPlan } from './healthBanc/reducer/healthInsuredReducer/healthInsuredStateAndPlan'
import { profileCompletionStatusReducer } from './healthBanc/reducer/healthInsuredReducer/profileCompletionStatus'
import { healthInsuredSignupDetails } from './healthBanc/reducer/healthInsuredReducer/healthInsuredIndividualSignupDetails'
import { healthInsuredIndividualProfileReducer } from './healthBanc/reducer/healthInsuredReducer/healthInsuredIndividualProfile'
import { notificationMessageReducer } from './healthBanc/reducer/notificationMessageReducer'
import { userNotificationsReducer } from './pharmHub/user/reducer/userNotificationsReducer'
import { bankAccountDetailsReducer } from './pharmHub/user/reducer/bankAccountDetailsReducer'
import { userReducer } from './healthBanc/reducer/userReducer'
import signInRequestOTPReducer from './healthBanc/reducer/healthInsuredSignInRequestOTPSlice'

import { pharmHubAuthReducer } from './pharmHub/user/reducer/pharmHubAuthReducer'
import { newAccountReducer } from './pharmHub/user/reducer/newUserAccountReducer'
import { openAccountReducer } from './pharmHub/user/reducer/openAccountReducer'
import { loadingReducer } from './pharmHub/user/reducer/loadingReducer'
import { showcaseProductsReducer } from './pharmHub/user/reducer/showcaseProductsReducer'
import { cartReducer } from './pharmHub/user/reducer/cartReducer'
import { userIdReducer } from './pharmHub/user/reducer/userIdReducer'
import { accountRequestIdReducer } from './pharmHub/user/reducer/accountRequestIdReducer'
import { companyIdReducer } from './pharmHub/user/reducer/companyIdReducer'
import { adminIdReducer } from './pharmHub/user/reducer/adminIdReducer'
import { adminIndividualIdReducer } from './pharmHub/user/reducer/adminIndividualIdReducer'
import { userProductsReducer } from './pharmHub/user/reducer/productsReducer'
import { uplineAndDownlineReducer } from './pharmHub/user/reducer/uplineAndDownlineReducer'
import { getRequestToJoinReducer } from './pharmHub/user/reducer/getRequestToJoinReducer'
import { ordersReducer } from './pharmHub/user/reducer/ordersReducers'
import { companyListReducer } from './pharmHub/user/reducer/fetchCompanyListReducer'
import { axaUserPinReferenceReducer } from './healthBanc/reducer/axaUserPinReferenceReducer'
import { userHealthPlanReducer } from './healthBanc/reducer/healthInsuredReducer/userHealthPlanReducer'

////////////////////////
// FRIENDS REDUCERS
import friendsReducer from './healthBanc/reducer/healthInsuredReducer/getIndividualFriendsReducer'
import addFriendByEmailReducer from './healthBanc/reducer/healthInsuredReducer/addFriendByEmailReducer'
import removeFriendReducer from './healthBanc/reducer/healthInsuredReducer/removeFriendReducer'
import addFriendWithDetailsReducer from './healthBanc/reducer/healthInsuredReducer/addFriendWithDetailsReducer'
import deactivateFriendReducer from './healthBanc/reducer/healthInsuredReducer/deactivateFriendReducer'
import activateFriendReducer from './healthBanc/reducer/healthInsuredReducer/activateFriendReducer'

///////////////////////////////
// LOCATION REDUCERS IMPORT
import getStatesReducer from './healthBanc/reducer/healthInsuredReducer/locationReducers/getStatesReducer'
import getTownsReducer from './healthBanc/reducer/healthInsuredReducer/locationReducers/getTownsReducer'
import healthCentersReducer from './healthBanc/reducer/healthInsuredReducer/locationReducers/getHealthCenterListReducer'

/////////// HEALTH INSURED FAMILY PLAN
// import { storeFamilyMembersReducers } from './healthBanc/reducer/healthInsuredReducer/healthInsuredFamily/storeFamilyMembersReducer'
import createFamilyProfileReducer from './healthBanc/reducer/healthInsuredReducer/healthInsuredFamily/createFamilyProfileReducer'
import addFamilyMemberReducer from './healthBanc/reducer/healthInsuredReducer/healthInsuredFamily/addFamilyMemberReducer'
import getFamilyMembersReducer from './healthBanc/reducer/healthInsuredReducer/healthInsuredFamily/getFamilyMembersReducer'
import removeFamilyMemberReducer from './healthBanc/reducer/healthInsuredReducer/healthInsuredFamily/removeFamilyMemberReducer'
import activateFamilyMemberReducer from './healthBanc/reducer/healthInsuredReducer/healthInsuredFamily/activateFamilyMemberReducer'
import deactivateFamilyMemberReducer from './healthBanc/reducer/healthInsuredReducer/healthInsuredFamily/deactivateFamilyMemberReducer'

//HealthInsured Corporate Users Reducers Imports
import { createCorporateUserReducer } from './healthBanc/reducer/healthInsuredReducer/corporateUserReducers/createCorporateUserReducer'
import { getCorporateUserReducer } from './healthBanc/reducer/healthInsuredReducer/corporateUserReducers/getCorporateUserReducer'
import { resendCorporateOtpReducer } from './healthBanc/reducer/healthInsuredReducer/corporateUserReducers/resendCorporateUserOtpReducer'
import { verifyCorporateUserOtpReducer } from './healthBanc/reducer/healthInsuredReducer/corporateUserReducers/verifyCorporateUserOtpReducer'
import { updateCorporateProfileReducer } from './healthBanc/reducer/healthInsuredReducer/corporateUserReducers/updateCorporateProfileReducer'
import { uploadCorporateBeneficiariesReducer } from './healthBanc/reducer/healthInsuredReducer/corporateUserReducers/uploadCorporateBeneficiariesReducer'
import { beneficiariesReviewReducer } from './healthBanc/reducer/healthInsuredReducer/corporateUserReducers/beneficiariesReviewReducer'
import { removeBeneficiaryInReviewReducer } from './healthBanc/reducer/healthInsuredReducer/corporateUserReducers/removeBeneficiaryInReviewReducer'
import { restoreBeneficiaryInReviewReducer } from './healthBanc/reducer/healthInsuredReducer/corporateUserReducers/restoreBeneficiaryInReviewReducer'
import { chargeCorporateCardReducer } from './healthBanc/reducer/healthInsuredReducer/corporateUserReducers/chargeCorporateCardReducer'
import { submitCorporateCardOtpReducer } from './healthBanc/reducer/healthInsuredReducer/corporateUserReducers/submitCorporateCardOtpReducer'
import { fetchCorporateBeneficiariesReducers } from './healthBanc/reducer/healthInsuredReducer/corporateUserReducers/fetchCorporateBeneficiariesReducers'
import { getCompanyProfileReducer } from './healthBanc/reducer/healthInsuredReducer/corporateUserReducers/getCompanyProfileReducer'
import { getCorporateAnalyticsReducer } from './healthBanc/reducer/healthInsuredReducer/corporateUserReducers/getCorporateAnalyticsReducer'
import { corporateProfileCompletionReducer } from './healthBanc/reducer/healthInsuredReducer/corporateUserReducers/corporateProfileCompetionReducer'
import { deactivateActiveBeneficiariesReducer } from './healthBanc/reducer/healthInsuredReducer/corporateUserReducers/deactivateActiveBeneficiariesReducer'
import { movePendingBeneficiariesToInactiveListReducer } from './healthBanc/reducer/healthInsuredReducer/corporateUserReducers/movePendingBeneficiariesToInactiveListReducer'
import { moveInactiveBeneficiariesToPendingListReducer } from './healthBanc/reducer/healthInsuredReducer/corporateUserReducers/moveInactiveBeneficiariesToPendingListReducer'
import { getCorporateActivityLogReducer } from './healthBanc/reducer/healthInsuredReducer/corporateUserReducers/getCorporateActivityLogReducer'
// -- END -- HealthInsured Corporate reducers import

// --start-- HealthInsured ADMIN reducers
import { getHealthInsuredAdminDashboardAnalyticsReducer } from './healthBanc/reducer/healthInsuredAdmin/getHealthInsuredAdminDashboardAnalyticsReducer'
import { getHealthInsuredUserAcquisitionReducer } from './healthBanc/reducer/healthInsuredAdmin/getHealthInsuredUserAcquisitionReducer'
import { getHealthInsuredSubscriberAcquisitionReducer } from './healthBanc/reducer/healthInsuredAdmin/getHealthInsuredSubscriberAcquisitionReducer'
import { getInsuranceProfilesReducer } from './healthBanc/reducer/healthInsuredAdmin/getInsurenceProfilesReducer'
import { getHealthInsuredAdminTransactionLogReducer } from './healthBanc/reducer/healthInsuredAdmin/getHealthInsuredAdminTransactionLogReducer'
import { getHealthInsuredAdminUserActivityLogsReducer } from './healthBanc/reducer/healthInsuredAdmin/getHealthInsuredAdminUserActivityLogsReducer'
import { getHealthInsuredAdminActivityLogReducer } from './healthBanc/reducer/healthInsuredAdmin/getHealthInsuredAdminActivityLogReducer'
import { getHealthInsuredAdminErrorLogsReducer } from './healthBanc/reducer/healthInsuredAdmin/getHealthInsuredAdminErrorLogsReducer'
import { getExtendedUserHealthInsuredAdminProfileReducer } from './healthBanc/reducer/healthInsuredAdmin/getExtendedUserHealthInsuredAdminProfileReducer'

////////////////---- ONE DRUG STORE -- drug - item ---------------/////////////
import { getAllDrugItemReducer } from './healthBanc/reducer/oneDrugStore/user/drugItemsSlices'
import {
  createDrugItemReducer,
  deleteDrugItemReducer,
  updateDrugItemReducer,
} from './healthBanc/reducer/oneDrugStore/admin/drugItemSlices'

////////////////--------- drug category -----------////
import {
  getDrugCategoryReducer,
  createDrugCategoryReducer,
  updateDrugCategoryReducer,
} from './healthBanc/reducer/oneDrugStore/admin/drugCategorySlice'

////////////// ---------- pharmacy -----------------////
import {
  getAllPharmacyReducer,
  deletePharmacyReducer,
  createPharmacyReducer,
  updatePharmacyReducer,
} from './healthBanc/reducer/oneDrugStore/admin/pharmacySlices'

////////////////---- cart-item ---------------/////////////
import {
  getCartItemsReducer,
  deleteCartItemReducer,
  addCartItemReducer,
  updateCartItemQuantityReducer,
  uploadCartItemPrescriptionReducer,
  addLocalCartItemsReducer,
  nonAuthUserCartItemsReducer,
} from './healthBanc/reducer/oneDrugStore/user/cartItemsSlices'

////////////////------ aggregator ---------------/////////////
import {
  createAggregatorReducer,
  getAggregatorsReducer,
  updateAggregatorReducer,
  deleteAggregatorReducer,
} from './healthBanc/reducer/oneDrugStore/admin/aggregatorSlices'

import {
  createOrderReducer,
  getMyOrdersReducer,
  getAllOrdersReducer,
} from './healthBanc/reducer/oneDrugStore/admin/orderSlices'

////////////////---- card payment ---------------/////////////
import { cardPaymentReducer } from './healthBanc/reducer/oneDrugStore/user/cardPaymentSlices'

/////////////// --------- wallet payment ---------////////
import {
  walletPaymentReducer,
  walletConfirmOTPReducer,
} from './healthBanc/reducer/oneDrugStore/user/walletPaymentSlices'

////////////////------ delivery info ---------------/////////////
import { getDeliveryInfoReducer } from './healthBanc/reducer/oneDrugStore/user/deliveryInfoSlices'
import { updateDeliveryInfoReducer } from './healthBanc/reducer/oneDrugStore/user/deliveryInfoSlices'

import logoutReducer from './healthBanc/reducer/logoutReducer'

// Wallet Section
import payWithWalletReducer from './healthBanc/reducer/healthInsuredReducer/walletReducer'

const appReducer = combineReducers({
  signup: signupReducer,
  user: userReducer,
  signInRequestOTP: signInRequestOTPReducer,
  bankAccountDetails: bankAccountDetailsReducer,
  pharmHubAuth: pharmHubAuthReducer,
  userNotifications: userNotificationsReducer,
  notificationMessage: notificationMessageReducer,
  axaUserPinReference: axaUserPinReferenceReducer,
  serviceProvider,
  isLoading: loadingReducer,
  newAccount: newAccountReducer,
  newBankAccount: openAccountReducer,
  companyList: companyListReducer,
  showcaseProducts: showcaseProductsReducer,
  cart: cartReducer,
  userId: userIdReducer,
  accountRequestId: accountRequestIdReducer,
  companyId: companyIdReducer,
  adminId: adminIdReducer,
  adminIndividualId: adminIndividualIdReducer,
  products: userProductsReducer,
  uplineDownline: uplineAndDownlineReducer,
  getRequestToJoin: getRequestToJoinReducer,
  orders: ordersReducer,
  healthInsuredStateAndPlan,
  profileCompletionStatus: profileCompletionStatusReducer,
  healthInsuredIndividualProfile: healthInsuredIndividualProfileReducer,
  healthInsuredSignupDetails,
  townOfResidence,
  listOfHealthCareProviders,
  healthCareProviders,
  userHealthPlan: userHealthPlanReducer,

  /////////////////////////
  // FRIENDS
  friends: friendsReducer,
  addFriendByEmail: addFriendByEmailReducer,
  removeFriend: removeFriendReducer,
  addFriendWithDetails: addFriendWithDetailsReducer,
  deactivateFriend: deactivateFriendReducer,
  activateFriend: activateFriendReducer,

  ////////////////////////////////////////////
  // LOCATION REDUCERS:
  getStates: getStatesReducer,
  getTowns: getTownsReducer,
  healthCenters: healthCentersReducer,

  ///// HEALTH INSURED FAMILY PLAN
  createFamilyProfile: createFamilyProfileReducer,
  addFamilyMember: addFamilyMemberReducer,
  getFamilyMembers: getFamilyMembersReducer,
  removeFamilyMember: removeFamilyMemberReducer,
  activateFamilyMember: activateFamilyMemberReducer,
  deactivateFamilyMember: deactivateFamilyMemberReducer,

  //healthInsured Corporate Users reducers
  createCorporateUser: createCorporateUserReducer,
  getCorporateUser: getCorporateUserReducer,
  resendCorporateOtp: resendCorporateOtpReducer,
  verifyCorporateUserOtp: verifyCorporateUserOtpReducer,
  updateCorporateProfile: updateCorporateProfileReducer,
  uploadCorporateBeneficiaries: uploadCorporateBeneficiariesReducer,
  beneficiariesReview: beneficiariesReviewReducer,
  removeBeneficiaryInReview: removeBeneficiaryInReviewReducer,
  restoreBeneficiaryInReview: restoreBeneficiaryInReviewReducer,
  chargeCorporateCard: chargeCorporateCardReducer,
  submitCorporateCardOtp: submitCorporateCardOtpReducer,
  fetchCorporateBeneficiaries: fetchCorporateBeneficiariesReducers,
  getCompanyProfile: getCompanyProfileReducer,
  getCorporateAnalytics: getCorporateAnalyticsReducer,
  corporateProfileCompletion: corporateProfileCompletionReducer,
  deactivateActiveBeneficiaries: deactivateActiveBeneficiariesReducer,
  movePendingBeneficiariesToInactiveList:
    movePendingBeneficiariesToInactiveListReducer,
  moveInactiveBeneficiariesToPendingList:
    moveInactiveBeneficiariesToPendingListReducer,
  getCorporateActivityLog: getCorporateActivityLogReducer,
  // -- END -- healthInsured Corporate Users reducers

  // HealthInsured ADMIN reducers
  getHealthInsuredAdminDashboardAnalytics:
    getHealthInsuredAdminDashboardAnalyticsReducer,
  getHealthInsuredUserAcquisition: getHealthInsuredUserAcquisitionReducer,
  getHealthInsuredSubscriberAcquisition:
    getHealthInsuredSubscriberAcquisitionReducer,
  getInsuranceProfiles: getInsuranceProfilesReducer,
  getHealthInsuredAdminTransactionLog:
    getHealthInsuredAdminTransactionLogReducer,
  getHealthInsuredAdminUserActivityLogs:
    getHealthInsuredAdminUserActivityLogsReducer,
  getHealthInsuredAdminActivityLog: getHealthInsuredAdminActivityLogReducer,
  getHealthInsuredAdminErrorLogs: getHealthInsuredAdminErrorLogsReducer,
  getExtendedUserHealthInsuredAdminProfile:
    getExtendedUserHealthInsuredAdminProfileReducer,

  ///////////////////// ----------  ONE DRUG STORE -- drug item ----------------///////////////
  getAllDrugItem: getAllDrugItemReducer,
  createDrugItem: createDrugItemReducer,
  updateDrugItem: updateDrugItemReducer,
  deleteDrugItem: deleteDrugItemReducer,

  ////// ----------------- Phamacy --------------////
  getAllPharmacy: getAllPharmacyReducer,
  createPharmacy: createPharmacyReducer,
  updatePharmacy: updatePharmacyReducer,
  deletePharmacy: deletePharmacyReducer,

  //// ------ drug category ---------//
  createDrugCategory: createDrugCategoryReducer,
  getDrugCategory: getDrugCategoryReducer,
  updateDrugCategory: updateDrugCategoryReducer,

  // -- cart item -- //
  getCartItems: getCartItemsReducer,
  deleteCartItem: deleteCartItemReducer,
  addCartItem: addCartItemReducer,
  updateCartItemQuantity: updateCartItemQuantityReducer,
  uploadCartItemPrescription: uploadCartItemPrescriptionReducer,
  addLocalCartItems: addLocalCartItemsReducer,

  nonAuthUserCartItems: nonAuthUserCartItemsReducer,

  // -- aggregator -- //
  getAggregators: getAggregatorsReducer,
  updateAggregator: updateAggregatorReducer,
  deleteAggregator: deleteAggregatorReducer,
  createAggregator: createAggregatorReducer,

  //--- orders ----//
  createOrder: createOrderReducer,
  getMyOrders: getMyOrdersReducer,
  getAllOrders: getAllOrdersReducer,

  // --------- card payment -----------//
  cardPayment: cardPaymentReducer,

  // -----------wallet payment ---------//
  walletPayment: walletPaymentReducer,
  walletConfirmOTP: walletConfirmOTPReducer,

  payWithWallet: payWithWalletReducer,

  // -- delivery info -- //
  getDeliveryInfo: getDeliveryInfoReducer,
  updateDeliveryInfo: updateDeliveryInfoReducer,

  // Logout
  logout: logoutReducer,
})

// Reset state on SIGN_OUT action
const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = {}
  }
  return appReducer(state, action)
}

export default rootReducer
