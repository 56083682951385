import React, { useState, useReducer, useEffect } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { FaEyeSlash } from 'react-icons/fa'

import Logo from '../../../healthBancComponents/HealthBancLogo/HealthBancLogo'
import authenticated from '../auth/authenticated'

const loginUrl = `/v2/api/BackendAdminAuth/BackendLogin`
const baseUrl = process.env.REACT_APP_BASE_URL

const Signin = () => {
  const [showPassword, setShowPassword] = useState(false)
  const [signInfo, setSignInfo] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { aD_Username: '', aD_Password: '', aD_OTP: '' }
  )
  const [loginError, setLoginError] = useState('')
  const [loading, setLoading] = useState(false)

  const history = useHistory()

  useEffect(() => {
    let login = true
    // check if a user is authenticated
    if (login) {
      if (authenticated()) {
        history.push('/admin')
      }
    }

    return () => {
      login = false
    }
  })

  // toggle password field
  const handleShowPassword = () =>
    showPassword ? setShowPassword(false) : setShowPassword(true)

  const handleChange = (e) => {
    const { name, value } = e.target
    setSignInfo({ [name]: value })
  }

  const handleLogin = async (e) => {
    e.preventDefault()
    setLoginError('')

    try {
      setLoading(true)

      const response = await axios.post(`${baseUrl}${loginUrl}`, signInfo)
      const adminInfo = response.data.data

      adminInfo && localStorage.setItem('admin', JSON.stringify(adminInfo))

      // save login details to local storage
      adminInfo && localStorage.setItem('adminInfo', adminInfo.token)

      setLoading(false)
      // redirect user to admin dashbaord
      history.push('/admin')
    } catch (error) {
      error.response
        ? setLoginError(error.response.data.message)
        : setLoginError(error.message)
      setLoading(false)
    }
  }

  return (
    <div className="signin-container p-3 p-sm-0">
      <header className="header">
        <Logo />
      </header>
      <section className="card-container">
        <div className="card-wrapper">
          <h1 className="signing-welcome">Welcome</h1>
          <p className="signing-subtext ">
            Sign in as an administrator to continue
          </p>
          <form className="p-0" onSubmit={handleLogin}>
            <div>
              <label className="signing-email-label">Sterling username</label>
              <input
                type="text"
                name="aD_Username"
                onChange={handleChange}
                className="signing-email-input p-2"
              />
            </div>

            <div>
              <label className="password-label">Sterling password</label>
              <span className="password-wrapper p-0">
                <input
                  type={showPassword ? 'text' : 'password'}
                  name="aD_Password"
                  onChange={handleChange}
                  className="signing-email-password py-2 m-0 pl-2"
                />
                <i className="pt-2 pr-2 arrow" onClick={handleShowPassword}>
                  <FaEyeSlash />
                </i>
              </span>
            </div>

            <div>
              <label className="signing-email-label">Otp</label>
              <input
                type="tel"
                name="aD_OTP"
                onChange={handleChange}
                className="signing-email-input p-2"
                maxLength="6"
                minLength="6"
              />
            </div>
            <menu
              className={
                loginError
                  ? 'error-message error-message-show'
                  : 'error-message'
              }
            >
              {loginError}
            </menu>
            <input
              type="submit"
              value={loading ? 'Sign In ...' : 'Sign In'}
              className="btn-signing py-2 text-center"
            />
          </form>
        </div>
      </section>
    </div>
  )
}

export default Signin
