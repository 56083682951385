import axios from 'axios'
import { put, takeEvery } from 'redux-saga/effects'
import {
  beneficiariesReviewRequest,
  beneficiariesReviewSuccess,
} from '../../actions/healthInsuredCreators/corporateUserActions/corporateBeneficiariesReviewActions'
import {
  removeBeneficiaryInReviewFailed,
  removeBeneficiaryInReviewRequest,
  removeBeneficiaryInReviewSuccess,
} from '../../actions/healthInsuredCreators/corporateUserActions/removeBeneficiaryInReviewActions'

function* removeBeneficiaryInReview({ payload }) {
  try {
    yield put(removeBeneficiaryInReviewRequest())

    const { data } = yield axios
      .post(`/v2/api/CorporateInsurance/RemoveCompanyBeneficiaryReviewUser`, {
        data: { Email: payload },
      })
      .then((response) => response)

    yield put(removeBeneficiaryInReviewSuccess(data))

    // Fetch list of updated beneficiaries
    // yield put(beneficiariesReviewRequest())

    const updatedBeneficiaries = yield axios
      .post(`/v2/api/CorporateInsurance/GetCompanyBeneficiaryReview`)
      .then((response) => response.data)

    yield put(beneficiariesReviewSuccess(updatedBeneficiaries))
  } catch (error) {
    yield put(removeBeneficiaryInReviewFailed(error.response))
  }
}

export function* watchRemoveBeneficiaryInReview() {
  yield takeEvery('REMOVE_BENEFICIARY_IN_REVIEW', removeBeneficiaryInReview)
}
