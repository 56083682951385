import { call, put, takeEvery } from 'redux-saga/effects'
import {
  GET_FRIENDS_FAILED,
  GET_FRIENDS_REQUESTED,
  GET_FRIENDS_SUCCESSFUL,
} from '../../../reducer/healthInsuredReducer/getIndividualFriendsReducer'
import { getFriendsAPI } from './friendsAPIs'

function* handleGetFriends({ payload }) {
  yield GET_FRIENDS_REQUESTED()
  try {
    const { data } = yield call(getFriendsAPI, payload)

    yield put(GET_FRIENDS_SUCCESSFUL(data.data))
  } catch (error) {
    if (error.response) {
      yield alert(error.response.data.message)
      return yield put(GET_FRIENDS_FAILED(error.response.data.message))
    }

    // yield alert(error.message)
  }
}

export function* watchGetFriends() {
  yield takeEvery(GET_FRIENDS_REQUESTED.type, handleGetFriends)
}
