import { takeLatest, put } from 'redux-saga/effects'
import axios from 'axios'
import {
  getAggregatorsRequested,
  getAggregatorsSuccessful,
  getAggregatorsFailed,
} from '../../../reducer/oneDrugStore/admin/aggregatorSlices'

function* getAggregatorAsync({ payload }) {
  try {
    yield getAggregatorsRequested()

    const { data } = yield axios({
      method: 'GET',
      url: `${process.env.REACT_APP_ONE_DRUG_BASE}/v1/api/Aggregator/List`,
      headers: { Authorization: `Bearer ${localStorage.getItem('adminInfo')}` },
    })

    yield put(getAggregatorsSuccessful(data.data))
  } catch (error) {
    if (error.response) {
      yield put(getAggregatorsFailed(error.response.data))
      yield put(getAggregatorsFailed(null))
      return
    }

    yield put(getAggregatorsFailed(error.message))
    yield put(getAggregatorsFailed(null))
  }
}

export function* watchGetAggregator() {
  yield takeLatest(getAggregatorsRequested.type, getAggregatorAsync)
}
