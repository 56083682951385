import React from 'react'
import { BsArrowLeft } from 'react-icons/bs'
import { useHistory } from 'react-router-dom'

const Goback = () => {
  const history = useHistory()

  // take a user to previous location
  const handleBack = () => {
    history.goBack()
  }

  return (
    <div className="d-flex ">
      <i className="me3" onClick={handleBack}>
        <BsArrowLeft className="arrow-back" />
      </i>
      <p className="back-text">Back</p>
    </div>
  )
}

export default Goback
