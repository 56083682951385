import styled from 'styled-components'

const StatusStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .icon-success {
    font-size: 10rem;
    color: green;
  }

  .icon-failed {
    font-size: 10rem;
    color: var(--color-primary);
  }
`

export default StatusStyle
