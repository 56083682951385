import React from 'react'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'

import './healthInsuredPagination.scss'

const HealthInsuredPagination = ({ prev, next, pageDetails = {} }) => {
  return (
    <div className="healthInsuredPagination">
      <button
        className="healthInsuredPagination--back-btn"
        onClick={prev}
        disabled={pageDetails.pageNumber <= 1 && true}
      >
        <IoIosArrowBack /> Prev
      </button>
      <span className="healthInsuredPagination--page-number">
        {' '}
        Page {pageDetails.pageNumber || 1} of{' '}
        {pageDetails.pageCount === 0 ? 1 : pageDetails.pageCount}
      </span>
      <button
        className="healthInsuredPagination--next-btn"
        onClick={next}
        disabled={
          (pageDetails.pageNumber >= pageDetails.pageCount ||
            !pageDetails.pageNumber) &&
          true
        }
      >
        Next <IoIosArrowForward />{' '}
      </button>
    </div>
  )
}

export default HealthInsuredPagination
