import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: {},
  error: null,
}

const createDrugCategorySlice = createSlice({
  name: 'createDrugCategory',
  initialState,
  reducers: {
    createDrugCategoryRequested: (state) => {
      state.isLoading = true
      state.data = {}
      state.error = null
    },

    createDrugCategorySuccessful: (state, action) => {
      state.isLoading = false
      state.data = action.payload
      state.error = null
    },

    createDrugCategoryFailed: (state, action) => {
      state.isLoading = false
      state.data = {}
      state.error = action.payload
    },
  },
})

export const {
  createDrugCategoryRequested,
  createDrugCategorySuccessful,
  createDrugCategoryFailed,
} = createDrugCategorySlice.actions
export default createDrugCategorySlice.reducer
