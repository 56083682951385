import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as Create } from '../../../../../../assets/healthInsuredAdmin/create.svg';


const Button = ({ handleShowCreateNotification }) => {
  return (
    <Link className='healthInsured__admin__notification__btn' onClick={handleShowCreateNotification}>
      <i><Create className='healthInsured__admin__notification__btn--create' /></i><span>Create New</span>
    </Link>
  )
}

export default Button
