import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: {},
  errors: [],
}

const removeFriendSlice = createSlice({
  name: 'Remove_Friend',
  initialState,
  reducers: {
    REMOVE_FRIEND_REQUESTED: (state) => {
      return { isLoading: true, data: {}, errors: [] }
    },

    REMOVE_FRIEND_SUCCESSFUL: (state, action) => {
      return { isLoading: false, data: { ...action.payload }, errors: [] }
    },

    REMOVE_FRIEND_FAILED: (state, action) => {
      return { isLoading: false, data: {}, error: [action.payload] }
    },
  },
})

export const {
  REMOVE_FRIEND_REQUESTED,
  REMOVE_FRIEND_SUCCESSFUL,
  REMOVE_FRIEND_FAILED,
} = removeFriendSlice.actions

export default removeFriendSlice.reducer
