import {
  VERIFY_CORPORATE_OTP_FAILED,
  VERIFY_CORPORATE_OTP_REQUEST,
  VERIFY_CORPORATE_OTP_SUCCESS,
} from '../../healthInsuredTypes/corporateActionTypes'

export const verifyCorporateOtpRequest = () => ({
  type: VERIFY_CORPORATE_OTP_REQUEST,
})

export const verifyCorporateOtpSuccess = (data) => ({
  type: VERIFY_CORPORATE_OTP_SUCCESS,
  payload: data,
})

export const verifyCorporateOtpFailed = (errorMessage) => ({
  type: VERIFY_CORPORATE_OTP_FAILED,
  payload: errorMessage,
})
