import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: {},
  error: null,
}

const updateCartItemQuantitySlice = createSlice({
  name: 'updateCartItemQuantity',
  initialState,
  reducers: {
    updateCartItemQuantityRequested: (state) => {
      state.isLoading = true
      state.data = {}
      state.error = null
    },

    updateCartItemQuantitySuccessful: (state, action) => {
      state.isLoading = false
      state.data = action.payload
      state.error = null
    },

    updateCartItemQuantityFailed: (state, action) => {
      state.isLoading = false
      state.data = {}
      state.error = action.payload
    },
  },
})

export const {
  updateCartItemQuantityRequested,
  updateCartItemQuantitySuccessful,
  updateCartItemQuantityFailed,
} = updateCartItemQuantitySlice.actions

export default updateCartItemQuantitySlice.reducer
