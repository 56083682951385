
const INITIAL_STATE = {
  accountRequestId: ""
}

export const accountRequestIdReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "ACCOUNT_REQUEST_ID":
      return { ...state, accountRequestId: action.id }

    default: return state
  }
}