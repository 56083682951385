import React from 'react'

const Tagline = () => {
  return (
    <header className="choose__provider-tagline" data-aos="fade-right">
      <h2 className="title">Click on the Health Insurer and proceed</h2>
      {/* <h2 className='title'>Choose your preferred service provider</h2>
      <p className='description'>Your health insurance will be provided by your preferred option</p> */}
    </header>
  )
}

export default Tagline
