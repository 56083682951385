import {
  HEALTH_INSURED_INDIVIDUAL_PROFILE_FAILED,
  HEALTH_INSURED_INDIVIDUAL_PROFILE_SUCCESS,
  HEALTH_INSURED_INDIVIDUAL_PROFILE_REQUEST,
} from '../../actions/healthInsuredTypes/healthInsuredIndividualProfile'

const healthInsuredIndividualProfile = {
  transId: '',
  enrolleeNumber: '',
  gender: '',
  customerNo: '',
  surname: '',
  othernames: '',
  dateOfBirth: '',
  phoneNumber: '',
  email: '',
  contactAddress: '',
  occupation: '',
  maritalStatus: '',
  identification: '',
  careProviderName: '',
  cpPhone: '',
  cpAddress: '',
  cpCity: '',
  cpEmail: '',
  alternateHospital: '',
  medicalCondition: '',
  planCode: '',
  premium: 0,
  stateOfResidence: '',
  townOfResidence: '',
  activeStatus: true,
}

export const healthInsuredIndividualProfileReducer = (
  state = healthInsuredIndividualProfile,
  action
) => {
  switch (action.type) {
    case HEALTH_INSURED_INDIVIDUAL_PROFILE_REQUEST:
      return { ...state, loading: true }

    case HEALTH_INSURED_INDIVIDUAL_PROFILE_SUCCESS:
      return { healthInsuredIndividualProfile: action.payload, loading: false }

    case HEALTH_INSURED_INDIVIDUAL_PROFILE_FAILED:
      return { error: action.payload, loading: false }

    default:
      return state
  }
}
