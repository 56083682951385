import { takeLatest, put } from 'redux-saga/effects'
import axios from 'axios'
import {
  getMyOrdersRequested,
  getMyOrdersSuccessful,
  getMyOrdersFailed,
} from '../../../reducer/oneDrugStore/admin/orderSlices'

function* getMyOrdersAsync({ payload }) {
  try {
    yield getMyOrdersRequested()

    const { data } = yield axios({
      method: 'GET',
      url: `${process.env.REACT_APP_ONE_DRUG_BASE}/v1/api/Order/MyOrder`,
      headers: { Authorization: `Bearer ${localStorage.getItem('adminInfo')}` },
    })

    yield put(getMyOrdersSuccessful(data))
  } catch (error) {
    if (error.response) {
      yield put(getMyOrdersFailed(error.response.data))
      yield put(getMyOrdersFailed(null))
      return
    }

    yield put(getMyOrdersFailed(error.message))
    yield put(getMyOrdersFailed(null))
  }
}

export function* watchGetMyOrders() {
  yield takeLatest(getMyOrdersRequested.type, getMyOrdersAsync)
}
