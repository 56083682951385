import React, { useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { Formik, Field } from 'formik'
import Background from './Background'
import ReactivationHeader from '../signup/template/header/Header'
import ContentWrapper from './ContentWrapper'
import CustomSelect from '../../../../healthBancComponents/Templates/CustomSelect'
import CustomButton from '../../../../healthBancComponents/Templates/CustomButton'
import BackArrow from '../assets/BackArrow.svg'

const Heading = styled.div`
  margin-bottom: 30px;
  h3 {
    font-size: 20px;
    font-weight: bold;
    color: #0c162c;
  }

  p {
    font-size: 16px;
    color: #4f4f4f;
    font-weight: 500;
  }
`

const Button = styled.button`
  background: transparent;
  border: none;
  text-align: left;

  &:focus {
    outline: none;
    transform: scale(1.05);
    border-radius: 50px;
    box-shadow: 1px 1px 5px lightgray;
  }
`

const VarifyCard = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleReactivation = async () => {
    setIsSubmitting(true)
    try {
      await axios({
        method: 'get',
        url: '/v2/api/Tokenization/ReactivateWithPresentPrimaryCard',
      }).then((response) => {
        alert('Success!')
        history.push('/health_profile')
      })
    } catch (error) {
      if (error.response) {
        alert(error.response.data.Message)
      } else if (error.request) {
        alert('Error!\nNo response from the server')
      } else {
        alert('Error!\nPlease try again or contact support!')
      }
    } finally {
      setIsSubmitting(false)
    }
  }
  const history = useHistory()
  return (
    <Background>
      <ReactivationHeader />
      <ContentWrapper>
        <div className="col-md-8 col-sm-12 mx-auto">
          {/* <h3>Card Image here</h3> */}
          <Heading>
            <p>We saved your card from last time, do you want to continue?</p>
          </Heading>
          <div className="d-flex flex-md-nowrap flex-wrap justify-content-around">
            <div>
              <CustomButton
                onClick={handleReactivation}
                disabled={isSubmitting ? true : false}
                className="mb-2 mt-2"
              >
                {isSubmitting ? 'Submitting...' : 'Yes, Continue'}
              </CustomButton>
            </div>
            <div>
              <CustomButton
                outline
                onClick={() => history.push('/health_card')}
                className="mt-2"
              >
                Change Card
              </CustomButton>
            </div>
          </div>
        </div>
      </ContentWrapper>
    </Background>
  )
}

export default VarifyCard
