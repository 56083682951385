import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, Redirect } from 'react-router-dom'
import { Form } from 'react-bootstrap'
import { Formik, Field } from 'formik'
import * as yup from 'yup'
import BackgroundTemplate from '../../../../../healthBancComponents/Templates/BackgroundTemplate'
import CustomButton from '../../../../../healthBancComponents/Templates/CustomButton'
import CustomInput from '../../../../../healthBancComponents/Templates/CustomInput'
import LoadingIndicator from '../../../../../healthBancComponents/LoadingIndicator/LoadingIndicator'
import './businessRegistration.scss'

const BusinessRegistration = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const createCorporateUser = useSelector((state) => state.createCorporateUser)

  const corporateProfileCompletion = useSelector(
    (state) => state.corporateProfileCompletion
  )

  useEffect(() => {
    dispatch({ type: 'CORPORATE_PROFILE_COMPLETION_STATUS' })
  }, [dispatch])

  // useEffect(() => {
  if (corporateProfileCompletion.isLoading) {
    return (
      <div
        className="text-center mt-5"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <LoadingIndicator />
      </div>
    )
  }

  if (corporateProfileCompletion && corporateProfileCompletion?.data) {
    const { companyEmail, emailConfirmed, profileCompleted } =
      corporateProfileCompletion?.data?.data ?? {}

    if (companyEmail && !emailConfirmed) {
      return <Redirect to="/health_corporate/business_registration-otp" />
      // return history.push('/health_corporate/business_registration-otp')
    }

    if (emailConfirmed && !profileCompleted) {
      return <Redirect to="/health_corporate/profile_update" />
    }

    if (companyEmail && emailConfirmed) {
      return <Redirect to="/health_corporate/welcome" />
      // return history.push('/health_corporate/welcome')
    }
  }
  // }, [corporateProfileCompletion, history])

  const registrationSchema = yup.object().shape({
    companyName: yup.string().required('Required'),
    companyEmail: yup.string().email().required('Required'),
  })

  const insuranceProvider =
    history.location.state && history.location.state.insuranceProvider

  const handleBusinessRegistration = (values, { setSubmitting }) => {
    dispatch({
      type: 'CREATE_CORPORATE_USER',
      payload: { ...values, insuranceProvider },
    })
  }

  return (
    <>
      {corporateProfileCompletion.isLoading ? (
        <div className="text-center mt-4">
          <LoadingIndicator />
        </div>
      ) : (
        <BackgroundTemplate
          showLogo={true}
          formTitle="Register as a Business"
          subTitle="Provide your company information to continue"
        >
          <div className="mx-auto">
            <Formik
              initialValues={{ companyName: '', companyEmail: '' }}
              validationSchema={registrationSchema}
              onSubmit={(values, { setSubmitting }) =>
                handleBusinessRegistration(values, { setSubmitting })
              }
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit}>
                  <Form.Group>
                    <Field
                      as={CustomInput}
                      name="companyName"
                      type="text"
                      placeholder="Company Name"
                      label="Company Name"
                    />
                    <Field
                      as={CustomInput}
                      type="email"
                      name="companyEmail"
                      label="Company Email"
                      placeholder="example@company.org"
                    />
                  </Form.Group>
                  <div>
                    <CustomButton
                      type="submit"
                      disabled={
                        createCorporateUser && createCorporateUser.isLoading
                          ? true
                          : false
                      }
                    >
                      {createCorporateUser && createCorporateUser.isLoading
                        ? 'Registering...'
                        : 'Register'}
                    </CustomButton>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </BackgroundTemplate>
      )}
    </>
  )
}

export default BusinessRegistration
