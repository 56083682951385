import React from 'react';
import { Modal } from 'react-bootstrap';

const CardModal = ({
  show,
  handleClose,
  title,
  message,
  action,
  cancel,
  handleAction,
  loading
}) => {

  return (
    <div>
      <Modal show={show} onHide={handleClose} dialogClassName='axa-mansard-card-modal'>
        <Modal.Header className='header' closeButton></Modal.Header>

        <Modal.Body bsPrefix='modal-body'>
          <h2 className='title'>{title}</h2>
          <p className='message'>{message}</p>
        </Modal.Body>

        <Modal.Footer bsPrefix='modal-footer'>
          <button
            disabled={loading ? true : false}
            className="action"
            onClick={handleAction}>
            {loading ? `${action}...` : action}
          </button>
          <button
            className="cancel"
            onClick={handleClose}>
            {cancel}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CardModal;
