import {
	GET_USER_UPLINE_LIST_ASYNC,
	GET_USER_DOWNLINE_LIST_ASYNC,
} from '../actions/actionTypes'

const INITIAL_STATE = {
	userUpline: {
		paginatedListDTO: [],
		currentPage: 0,
		pageCount: 0,
		pageSize: 0,
		recordCount: 0,
	},

	userDownline: {
		paginatedListDTO: [],
		currentPage: 0,
		pageCount: 0,
		pageSize: 0,
		recordCount: 0,
	},
}

export const uplineAndDownlineReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_USER_UPLINE_LIST_ASYNC:
			return { ...state, userUpline: { ...action.payload } }
		case GET_USER_DOWNLINE_LIST_ASYNC:
			return { ...state, userDownline: { ...action.payload } }
		default:
			return state
	}
}
