import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: {},
  error: null,
}

const deleteCartItemSlice = createSlice({
  name: 'deleteCartItem',
  initialState,
  reducers: {
    deleteCartItemRequested: (state) => {
      state.isLoading = true
      state.data = {}
      state.error = null
    },

    deleteCartItemSuccessful: (state, action) => {
      state.isLoading = false
      state.data = action.payload
      state.error = null
    },

    deleteCartItemFailed: (state, action) => {
      state.isLoading = false
      state.data = {}
      state.error = action.payload
    },
  },
})

export const {
  deleteCartItemRequested,
  deleteCartItemSuccessful,
  deleteCartItemFailed,
} = deleteCartItemSlice.actions

export default deleteCartItemSlice.reducer
