import React from 'react';
import { Card } from 'react-bootstrap';

import GoBackButton from '../../../../template/goBackButton/GoBackButton';
import SearchBar from '../../../../template/searchInput/SearchInput';
import SelectInput from '../../../../template/selectInput/SelectInput';
import HealthCareProviderTable from '../../../../template/table/Table';
import Loader from '../../../../../pharmHub/admin/components/Spinner/Spinner';
import Pagination from '../../../../template/pagination/Pagination';


const CareProviderSection = ({
	stateOptions,
	townOption,
	tableRow,
	handleSearch,
	handlePagination,
	handleCityChange,
	handleStateChange,
	healthCareProviders,
	loading,
	pageCount,
	pageNumber,
	recordCount,
}) => {


	return (
		<Card className='health__insurance-care-provider-card'>
			<Card.Body className='card-dashboard'>
				<GoBackButton className='d-block mb-4'>Go Back</GoBackButton>

				<h2 className='title'>Roaming</h2>

				<div className='container--search'>
					<SearchBar handleChange={handleSearch} />
				</div>

				<div className='container__select--input'>
					<SelectInput
						labelText='State'
						sortByOption={stateOptions}
						handleSort={handleStateChange}
					/>
					<SelectInput
						labelText='City'
						sortByOption={townOption}
						handleSort={handleCityChange}
					/>
				</div>

				{loading ? <Loader /> :
					<div className='table__container'>
						<HealthCareProviderTable
							tableId={'template__health__insured-table'}
							tableRow={tableRow}
						/>
						{healthCareProviders.length > 0 ?
							<Pagination
								className='align-self-end'
								pageCount={pageCount}
								recordCount={recordCount}
								pageNumber={pageNumber}
								handlePagination={handlePagination}
							/> : <h6 className='text-center'>we currently have no supported hospital in this state, choose another state or kindly refresh the page</h6>}
					</div>
				}
			</Card.Body>
		</Card>
	);
};;

export default CareProviderSection;
