import React from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../../../../healthBancComponents/Templates/Footer'
import styles from './layout.module.scss'

const Layout = ({ children, ...props }) => {
  return (
    <>
      <div
        className={`${styles.oneDrugLayout} p-0`}
        data-aos="fade-zoom-in"
        data-aos-once={true}
        data-aos-offset="150"
      >
        <Navbar />
        <main className="content">{children}</main>
      </div>
      <Footer />
    </>
  )
}

export default Layout
