import React from 'react'
import { Formik, Field, Form } from 'formik'
import * as yup from 'yup'
import Layout from '../../../components/adminComponents/Layout/Layout'
import CustomInput from '../../../../../healthBancComponents/Templates/CustomInput'
import CustomTextarea from '../../../../../healthBancComponents/Templates/CustomTextarea'
import CustomButton from '../../../../../healthBancComponents/Templates/CustomButton'

const CreateDrugCategory = () => {
  const pharmacySchema = yup.object().shape({
    name: yup.string().required('This field is required'),
    description: yup.string(),
    address: yup.string(),
  })

  const handleCreateDrugCategory = (values, actions) => {
    console.log(values)
  }

  return (
    <Layout canGoBack>
      <div className="mt-5 mx-4">
        <div className="mt-5">
          <h4>Create Drug Category</h4>

          <div>
            <Formik
              initialValues={{ name: '', description: '', address: '' }}
              validationSchema={pharmacySchema}
              onSubmit={(values, actions) =>
                handleCreateDrugCategory(values, actions)
              }
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit}>
                  <div className="col-6 ms-4 my-4">
                    <Field
                      as={CustomInput}
                      id="name"
                      type="text"
                      label="Name"
                      name="name"
                      placeholder="Enter drug category name"
                      value={props.value}
                    />

                    <Field
                      as={CustomTextarea}
                      id="description"
                      type="text"
                      label="Description"
                      name="description"
                      placeholder="Brief description"
                      value={props.value}
                    />

                    <div className="col-6">
                      <CustomButton type="submit">Create</CustomButton>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CreateDrugCategory
