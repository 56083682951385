import styled from 'styled-components'

export const BackBtn = styled.button`
  cursor: pointer;
  border: none;
  background-color: inherit;

  &:hover,
  &:active,
  &:focus {
    outline: none;
    background-color: #ebebeb;
  }
`
