import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import HealthBancLogo from '../HealthBancLogo/HealthBancLogo'

const FooterSection = styled.footer`
  padding-top: 50px;
  max-width: 100vw;
  background-color: rgb(242, 244, 248);

  h6 {
    font-size: 18px;
    font-weight: bold;
    color: #160304;
  }

  ul {
    list-style: none;
    padding-left: 0px;
    color: #494d55;
    font-size: 14px;
    line-height: 30px;

    a {
      text-decoration: none;
      color: inherit;
    }
  }

  .footer {
    font-size: 14px;
    color: #494d55;
    background-color: inherit;
    border-top: 1px solid #e0e0e0;
  }
`

const Footer = () => {
  // const isAuthUser = useSelector((state) => state.user.userInfo.isAuth)

  return (
    <FooterSection>
      <div className="pb-4 d-sm-flex flex-md-row justify-content-around container">
        <div className="mb-2">
          <HealthBancLogo />
        </div>
        <div>
          <h6>Company</h6>
          <ul>
            <li>
              <Link to="/about-us">About Us</Link>
            </li>
            {/* <li>FAQs</li> */}
            <li>
              <Link to="/terms">Terms of use</Link>
            </li>
            <li>
              <Link to="/privacyPolicy">Privacy Policy</Link>
            </li>
          </ul>
        </div>
        <div>
          <h6>Services</h6>
          <ul>
            <li>
              <Link to={'/health_insurance'}>Health Insured</Link>
            </li>
            {/* <li>
              <Link to="/OneDrugStore">OneDrugStore</Link>
            </li>
            <li>
              <Link to="/digital-hospital">Digital Hospital</Link>
            </li>
            <li>
              <Link to="/health-finance">Health Finance</Link>
            </li> */}
          </ul>
        </div>
        <div>
          <h6>Contact</h6>
          <ul>
            <li>
              <a href="mailto:HealthInsured@sterling.ng">
                HealthInsured@sterling.ng
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer col-sm-12 text-center mt-0 py-3">
        &copy; {new Date().getFullYear()} HealthInsured. All Rights Reserved
      </div>
    </FooterSection>
  )
}

export default Footer
