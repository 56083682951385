import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'
import CustomButton from '../../../../../healthBancComponents/Templates/CustomButton'
import { getAllPharmacyRequested } from '../../../../../redux/healthBanc/reducer/oneDrugStore/admin/pharmacySlices/getAllPharmacy'
import Layout from '../../../components/adminComponents/Layout/Layout'
import { deletePharmacyRequested } from '../../../../../redux/healthBanc/reducer/oneDrugStore/admin/pharmacySlices'

const Aggregator = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { id: aggregatorId } = useParams()

  const { data: aggregators } = useSelector((state) => state.getAggregators)
  const { data: pharmacies, isLoading } = useSelector(
    (state) => state.getAllPharmacy
  )

  const aggregator = (aggregators.data || []).find(
    (aggregator) => aggregator.id === +aggregatorId
  )

  useEffect(() => {
    dispatch(getAllPharmacyRequested(aggregatorId))
  }, [aggregatorId, dispatch])

  const handleDelete = (id) => {
    const confirmDelete = window.confirm('Delete this pharmacy?')

    if (confirmDelete) {
      dispatch(deletePharmacyRequested(id))
    }
  }

  return (
    <Layout canGoBack>
      <div className="mt-5 ps-5 container">
        <h4>Manage Pharmacies</h4>
        {/* <h6>
          <small>For:</small> {aggregator?.name}
        </h6> */}
        <div className="col-3 mt-5">
          {aggregatorId && (
            <CustomButton
              outline
              onClick={() =>
                history.push(
                  `/admin/oneDrugStore/pharmacy/create/${aggregator.id}`
                )
              }
            >
              CREATE
            </CustomButton>
          )}
        </div>

        <div className="mt-5">
          {isLoading ? (
            <div className="spinner-border d-flex  mx-auto" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <table className={`table table-hover`}>
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Description</th>
                  <th scope="col">Address</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {(pharmacies?.data || []).map((pharmacy, index) => (
                  <tr key={pharmacy.id}>
                    <th scope="row">{index + 1}</th>
                    <td
                      style={{ cursor: 'pointer', color: 'steelblue' }}
                      onClick={() =>
                        history.push(
                          `/admin/oneDrugStore/pharmacy/${pharmacy.id}`
                        )
                      }
                    >
                      {pharmacy?.name}
                    </td>
                    <td>{pharmacy?.description}</td>
                    <td>{pharmacy?.address}</td>
                    <td>
                      <Button
                        variant="link"
                        className="text-secondary"
                        onClick={() =>
                          history.push(
                            `/admin/oneDrugStore/aggregator/pharmacy-update/${pharmacy.id}`
                          )
                        }
                      >
                        Edit
                      </Button>
                      <Button
                        variant="link"
                        className="text-danger"
                        onClick={() => handleDelete(pharmacy?.id)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default Aggregator
