import {
  GET_HEALTHINSURED_ADMIN_TRANSACTION_LOGS_FAILED,
  GET_HEALTHINSURED_ADMIN_TRANSACTION_LOGS_REQUEST,
  GET_HEALTHINSURED_ADMIN_TRANSACTION_LOGS_SUCCESS,
} from '../../healthInsuredTypes/healthInsuredAdminActionTypes'

export const getHealthInsuredAdminTransactionLogsRequest = () => ({
  type: GET_HEALTHINSURED_ADMIN_TRANSACTION_LOGS_REQUEST,
})

export const getHealthInsuredAdminTransactionLogsSuccess = (logs) => ({
  type: GET_HEALTHINSURED_ADMIN_TRANSACTION_LOGS_SUCCESS,
  payload: logs,
})

export const getHealthInsuredAdminTransactionLogsFailed = (errorMessage) => ({
  type: GET_HEALTHINSURED_ADMIN_TRANSACTION_LOGS_FAILED,
  payload: errorMessage,
})
