import { takeLatest, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { toast } from 'react-toastify'
import {
  cardPaymentFailed,
  cardPaymentRequested,
  cardPaymentSuccessful,
} from '../../../reducer/oneDrugStore/user/cardPaymentSlices'

function* cardPaymentAsync({ payload }) {
  try {
    yield cardPaymentRequested()

    const { data } = yield axios({
      method: 'GET',
      url: `${process.env.REACT_APP_ONE_DRUG_BASE}/v1/api/CardPayment/Pay`,
    })

    var popUp = window.open(data.data)
    if (popUp == null || typeof popUp == 'undefined') {
      yield toast.error(
        'Please disable your pop-up blocker and click the "Open" link again.',
        {
          autoClose: false,
        }
      )
    } else {
      popUp.focus()
    }

    // yield window.open('https://www.google.com/')

    yield put(cardPaymentSuccessful(data))
  } catch (error) {
    if (error.response) {
      yield put(cardPaymentFailed(error.response.responseDescription))
      yield put(cardPaymentFailed(null))
      return
    }

    yield put(
      cardPaymentFailed('And error occured!\nTry again or contact support')
    )
    yield put(cardPaymentFailed(null))
  }
}

export function* watchCardPayment() {
  yield takeLatest(cardPaymentRequested.type, cardPaymentAsync)
}
