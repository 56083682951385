import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { GiMedicines } from 'react-icons/gi'
import './orderSummary.scss'

const OrderSummary = () => {
  const [productsInCart, setProductsInCart] = useState([])

  const { data: cartItems } = useSelector((state) => state.getCartItems)
  const { data: allDrugItems } = useSelector(
    (state) => state.getAllDrugItem.data
  )

  useEffect(() => {
    cartItems &&
      allDrugItems &&
      setProductsInCart(itemsWithQuantityAndSumTotal())
  }, [allDrugItems, cartItems])

  //Get drug Items and append quantity and total for each item
  const itemsWithQuantityAndSumTotal = () => {
    const cartDetails = []

    cartItems.forEach((item) => {
      const product = (allDrugItems || []).find(
        (drug) => drug.id === item.drugItemId
      )

      cartDetails.push({
        ...product,
        quantity: item.quantity,
        total: product.price * item.quantity,
      })
    })

    return cartDetails
  }

  return (
    <div className="order__summaryms-md-2">
      <h5 className="order__summary--title">Order Summary</h5>

      <p className="order__summary--items-count">
        {cartItems.length} items in cart
      </p>
      <div className="order__summary--items">
        {productsInCart.map((product) => (
          <div key={product.id} className="order__summary--item d-flex mb-3">
            <div className="item--image">
              {product.image ? (
                <img
                  src={product.image}
                  alt={product.name}
                  className="img-fluid"
                />
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center h-100"
                  style={{ border: '1px solid gray', borderRadius: '4px' }}
                >
                  <GiMedicines size="70px" className="text-secondary" />
                </div>
              )}
            </div>
            <div className="item__description ms-3">
              <p className="item__description--name">
                <strong>{product.name}</strong>
              </p>
              <div className="item__description--details">
                <span className="item--qty">Quantity: {product.quantity}</span>
                <div className="item--price">Total: &#8358;{product.total}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default OrderSummary
