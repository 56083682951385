import React from 'react'
// import Navbar from '../Navbar/Navbar'
import { useHistory, Link } from 'react-router-dom'

import { IoIosArrowRoundBack } from 'react-icons/io'
import Navbar from '../../../../healthInsured/HealthInsuredAdmin/components/navbar/Navbar'
import Footer from '../../../../../healthBancComponents/Templates/Footer'
import { BackBtn } from '../BackBtn.style'
import styles from './layout.module.scss'

const Layout = ({ children, ...props }) => {
  const history = useHistory()

  return (
    <>
      <div
        className={`${styles.oneDrugLayout} p-0`}
        data-aos="fade-zoom-in"
        data-aos-once={true}
        data-aos-offset="150"
      >
        <Navbar />
        <main className="content">
          <div className={`${styles.dashboard} d-flex w-100`}>
            <div
              className={`${styles.sideNav} col-lg-3 col-4 d-flex flex-column justify-content-between`}
            >
              <ul className={`${styles.menuItems}`}>
                <li>
                  <Link to="/admin/oneDrugStore/dashboard">Dashboard</Link>
                </li>
                <li>
                  <Link to="/admin/OneDrugStore/aggregators">Aggregators</Link>
                </li>
                <li>
                  <Link to="/admin/oneDrugStore/pharmacies">Pharmacies</Link>
                </li>
                <li>
                  <Link to="/admin/oneDrugStore/orders">All Orders</Link>
                </li>
              </ul>
              {/* <div className="ms-4 mb-5">Logout</div> */}
            </div>

            <div className={`${styles.content} flex-fill ms-3`}>
              {props.canGoBack && (
                <BackBtn className="mt-5 ms-4" onClick={() => history.goBack()}>
                  <IoIosArrowRoundBack size="40" /> Go back
                </BackBtn>
              )}
              {children}
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </>
  )
}

export default Layout
