import { call, put, takeEvery } from 'redux-saga/effects'
import {
  ACTIVATE_FRIEND_FAILED,
  ACTIVATE_FRIEND_REQUESTED,
  ACTIVATE_FRIEND_SUCCESSFUL,
} from '../../../reducer/healthInsuredReducer/activateFriendReducer'
import { GET_FRIENDS_REQUESTED } from '../../../reducer/healthInsuredReducer/getIndividualFriendsReducer'
import { activateFriendAPI } from './friendsAPIs'

function* handleActivateFriendAsync({ payload }) {
  try {
    yield ACTIVATE_FRIEND_REQUESTED()

    const { data } = yield call(activateFriendAPI, payload.id)

    yield put(ACTIVATE_FRIEND_SUCCESSFUL(data))

    // yield put(GET_FRIENDS_REQUESTED())

    yield alert(data.message)

    yield window.location.reload()
  } catch (error) {
    if (error.response) {
      yield alert(error.response.data.message)
      return yield put(ACTIVATE_FRIEND_FAILED(error.response.data.message))
    }

    yield alert(error.message)
  } finally {
    yield payload.setShow(false)
  }
}

export function* watchActivateFriend() {
  yield takeEvery(ACTIVATE_FRIEND_REQUESTED.type, handleActivateFriendAsync)
}
