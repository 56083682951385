import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: {},
  error: null,
}

const createDrugItemSlice = createSlice({
  name: 'createDrugItem',
  initialState,
  reducers: {
    createDrugItemRequested: (state) => {
      state.isLoading = true
      state.data = {}
      state.error = null
    },

    createDrugItemSuccessful: (state, action) => {
      state.isLoading = false
      state.data = action.payload
      state.error = null
    },

    createDrugItemFailed: (state, action) => {
      state.isLoading = false
      state.data = {}
      state.error = action.payload
    },
  },
})

export const {
  createDrugItemRequested,
  createDrugItemSuccessful,
  createDrugItemFailed,
} = createDrugItemSlice.actions
export default createDrugItemSlice.reducer
