import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { AiOutlineStop, AiOutlineCheck } from 'react-icons/ai'
import { RiErrorWarningLine } from 'react-icons/ri'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import CustomButton from '../../../../../healthBancComponents/Templates/CustomButton'
import CustomSearchInput from '../../../../../healthBancComponents/Templates/CustomSearchInput/CustomSearchInput'

// import approvedImg from '../../../assets/orderStatusIcons/order-completed.svg'
import approvedImg from '../../../assets/orderStatusIcons/order-completed.png'
import declinedImg from '../../../assets/orderStatusIcons/clear-shopping-cart.png'
import awaitingImg from '../../../assets/orderStatusIcons/shopping-trolley.png'
import { getAllOrdersRequested } from '../../../../../redux/healthBanc/reducer/oneDrugStore/admin/orderSlices'
// import shippedImg from '../../../assets/orderStatusIcons/delivery.png'

const StatusBtnsContainer = styled.div`
  button {
    font-size: 12px;
    padding: 5px 10px;
    width: max-content;

    a {
      color: inherit;
    }

    img {
      height: 15px;
      width: 15px;
    }
  }

  .active {
    background: var(--color-primary);
    color: white;
  }
`

const OrderFilter = () => {
  const [searchText, setSearchText] = useState('')
  const [status, setStatus] = useState('')

  const location = useLocation()

  const dispatch = useDispatch()

  useEffect(() => {
    window.location.hash = '#all'
  }, [])

  useEffect(() => {
    dispatch(getAllOrdersRequested({ searchText, status }))
  }, [dispatch, searchText, status])

  const buttonClassNames = (hash) =>
    classNames({
      active: location.hash === hash,
    })

  const setActiveBtn = (hash) => {
    switch (hash) {
      case '#all':
        setStatus('')
        buttonClassNames(hash)
        break
      case '#approved':
        setStatus('Approved')
        buttonClassNames(hash)
        break
      case '#abandoned':
        setStatus('Abandoned')
        buttonClassNames(hash)
        break
      case '#failed':
        setStatus('Failed')
        buttonClassNames(hash)
        break

      default:
        setStatus('')
        buttonClassNames(hash)
    }
  }
  return (
    <>
      <StatusBtnsContainer className="d-flex">
        <Link to="#all">
          <CustomButton
            outline
            className={`${buttonClassNames('#all')} me-4`}
            onClick={() => setActiveBtn('#all')}
          >
            <img src={awaitingImg} alt="" className="me-2" />
            All Orders
          </CustomButton>
        </Link>

        <Link to="#approved">
          <CustomButton
            outline
            className={`${buttonClassNames('#approved')} me-4`}
            onClick={() => setActiveBtn('#approved')}
          >
            <AiOutlineCheck className="me-2" size="15px" />
            {/* <img src={approvedImg} alt="" className="me-2" /> */}
            Approved
          </CustomButton>
        </Link>
        {/* <Link to="#declined">
          <CustomButton
            outline
            className={`${buttonClassNames('#declined')}  me-4`}
            onClick={() => setActiveBtn('#declined')}
          >
            <img src={declinedImg} alt="" className="me-2" />
            Declined
          </CustomButton>
        </Link> */}

        {/* <Link to="#pending">
        <CustomButton
          outline
          className={`${buttonClassNames('#pending')} me-4`}
          onClick={() => setActiveBtn('#pending')}
        >
          <img src={awaitingImg} alt="" className="me-2" />
          Pending Approval
        </CustomButton>
      </Link>
      */}
        <Link to="#abandoned">
          <CustomButton
            outline
            className={`${buttonClassNames('#abandoned')} me-4`}
            onClick={() => setActiveBtn('#abandoned')}
          >
            <AiOutlineStop className="me-2" size="15px" />
            {/* <img src={<AiOutlineStop />} alt="" className="me-2" /> */}
            Abandoned
          </CustomButton>
        </Link>

        <Link to="#failed">
          <CustomButton
            outline
            className={`${buttonClassNames('#failed')} me-4`}
            onClick={() => setActiveBtn('#failed')}
          >
            <RiErrorWarningLine className="me-2" size="15px" />
            {/* <img src={declinedImg} alt="" className="me-2" /> */}
            Failed
          </CustomButton>
        </Link>
      </StatusBtnsContainer>
      <div className="col-12 block mt-5">
        <div className=" col-6">
          <CustomSearchInput
            placeholder="Search orders"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
      </div>
    </>
  )
}

export default OrderFilter
