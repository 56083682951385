import { NOTIFICATION_MESSAGE } from '../actions/actionTypes'

const INIT_STATE = {
  id: '',
  imageURl: '',
  message: '',
  scheduleDate: '',
  service: '',
  serviceId: '',
  status: '',
  subject: ''
}

export const notificationMessageReducer = (state = INIT_STATE, action) => {

  switch (action.type) {
    case NOTIFICATION_MESSAGE:
      return { ...state, ...action.message }

    default:
      return state
  }
};
