
const INITIAL_STATE = {
  companyId : ""
}

export const companyIdReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case "COMPANY_ID" : 
      return { ...state, companyId: action.id }
      
    default: return state
  }
}