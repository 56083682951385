import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Table } from 'react-bootstrap'
// import payWithSpecta from '../../assets/pay-with-specta.png'

import './summary.scss'

const Summary = ({ productsInCart }) => {
  const [totalPrice, setTotalPrice] = useState(0)

  const history = useHistory()

  const isAuthUser = useSelector((state) => state.user.userInfo.isAuth)
  // const { data: phoneNumberResponse } = useSelector(
  //   (state) => state.walletPayment
  // )

  // const handleShow = () => {
  //   if (isAuthUser) {
  //     setShowOTPModal(false)
  //     setShowPhoneNumberModal(true)
  //   } else {
  //     window.localStorage.setItem('fromOneDrugStore', 'true')
  //     history.push('/signin')
  //   }
  // }

  // const handleShowOTP = () => {
  //   if (isAuthUser) {
  //     setShowOTPModal(true)
  //     setShowPhoneNumberModal(false)
  //   } else {
  //     window.localStorage.setItem('fromOneDrugStore', 'true')
  //     history.push('/signin')
  //   }
  // }

  useEffect(() => {
    const calculateTotal = () =>
      (productsInCart || []).reduce((a, b) => a + b.total, 0)

    setTotalPrice(calculateTotal())
  }, [productsInCart])

  const handleCheckout = (paymentMethod) => {
    if (isAuthUser) {
      history.push({
        pathname: '/OneDrugStore/checkout',
        hash: paymentMethod,
      })
    } else {
      window.localStorage.setItem('fromOneDrugStore', 'true')
      history.push('/signin')
    }
  }

  return (
    <div className="oneDrug__cart__summary p-3">
      <h3 className="title">Summary</h3>
      <div className="cart__summary__details">
        <Table size="sm" borderless className="w-100">
          <tbody>
            <tr>
              <th>Subtotal</th>
              <td className="text-right">&#8358;{totalPrice}</td>
            </tr>
            <tr>
              <th>Shipping</th>
              <td className="text-right">&#8358;0</td>
            </tr>
            <tr>
              <td colSpan="2" className="shipping__info text-wrap">
                (Standard Rate - Price may vary depending on the
                item/destination. Shop Staff will contact you.)
              </td>
            </tr>
            <tr>
              <th>Tax</th>
              <td className="text-right">0%</td>
            </tr>
            <tr className="order__total">
              <th>Order Total</th>
              <td className="text-right">&#8358;{totalPrice}</td>
            </tr>
          </tbody>
        </Table>
        <div className="cart__checkout__cta">
          <button
            className="cart__checkout--btn"
            disabled={productsInCart.length === 0}
            onClick={() => handleCheckout('cardPayment')}
          >
            Pay with Card
          </button>
          <button
            className="cart__checkout--btn with-specta"
            disabled={productsInCart.length === 0}
            onClick={() => handleCheckout('walletPayment')}
            // onClick={!phoneNumberResponse ? handleShow : handleShowOTP}
          >
            Pay with <strong className="ps-1"> Wallet</strong>
            {/* <img
              src={payWithSpecta}
              alt="checkout with specta"
              className="img-fluid"
            /> */}
          </button>
        </div>
      </div>
    </div>
  )
}

export default Summary
