import React from 'react'
import { useHistory } from 'react-router-dom'
import Payment from '../../Payment';
import Progress from '../../Progress';
import NavWallet from '../NavWallet';
import './styles/Created.css'

const Created = () => {
    let history = useHistory();

    function handleClick() {
        history.push("/health_success_signup")
    }
    return (
        <section className='wall_created'>
            <NavWallet />
            <Payment />
            <div  className="created"  style={{paddingLeft: '50px'}}>
                
            {/* <p style={{ margin: 0, padding: 0 }}>userprofile@email.com</p>
            <p style={{ margin: 0, padding: 0 }}>Pay <span style={{color: 'red', fontWeight: 'bold'}}>1000</span></p> */}
        <div className='created2'>
            <h2 style={{marginBottom: '20px'}}>Congratulations</h2>
            
            <p className='p_cong'>Your wallet has been sucessfully created and linked. 
            </p>

            <button onClick={handleClick}><p>Pay for insurance</p></button>
        </div>
            
        </div>
            < Progress />
        </section>
        
    )
}

export default Created