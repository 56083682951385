import React, { useState, useEffect } from 'react';
import axios from 'axios';

import Card from '../card/Card';
import Button from '../btn/Button';
import CreateNotification from '../createNotification/CreateNotification';


const MainSection = () => {

  const [showCreateNotification, setShowCreateNotificaton] = useState(false);

  useEffect(()=> {

  });

  const handleShowCreateNotification = () => setShowCreateNotificaton(true);
  const handleCloseCreateNotification = () => setShowCreateNotificaton(false);

  return (
    <section className='healthInsured__admin__notification__section'>
      <CreateNotification
        showCreateNotification={showCreateNotification}
        handleCloseCreateNotification={handleCloseCreateNotification}
      />
      <Card
        buttonText='Schedule Now'
        title='Notification title'
        description='it has been long established fact that a reader will be distracted by the content'
        dateText='May 20'
      />

      <Card
        buttonText='Schedule Now'
        title='Notification title'
        description='it has been long established fact that a reader will be distracted by the content'
        dateText='May 20'
      />

      <Card
        buttonText='Schedule Now'
        title='Notification title'
        description='it has been long established fact that a reader will be distracted by the content'
        dateText='May 20'
      />

      <Card
        buttonText='Schedule Now'
        title='Notification title'
        description='it has been long established fact that a reader will be distracted by the content'
        dateText='May 20'
      />

      <Button handleShowCreateNotification={handleShowCreateNotification} />
    </section>
  );
};

export default MainSection;
