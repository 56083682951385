import {
  GET_EXTENDED_USER_INSURANCE_PROFILE_FAILED,
  GET_EXTENDED_USER_INSURANCE_PROFILE_REQUEST,
  GET_EXTENDED_USER_INSURANCE_PROFILE_SUCCESS,
} from '../../actions/healthInsuredTypes/healthInsuredAdminActionTypes'

export const getExtendedUserHealthInsuredAdminProfileReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case GET_EXTENDED_USER_INSURANCE_PROFILE_REQUEST:
      return { isLoading: true }

    case GET_EXTENDED_USER_INSURANCE_PROFILE_SUCCESS:
      return { isLoading: false, ...action.payload }

    case GET_EXTENDED_USER_INSURANCE_PROFILE_FAILED:
      return { isLoading: false, ...action.payload }

    default:
      return state
  }
}
