import { call, takeEvery, put } from 'redux-saga/effects'
import {
  addFamilyMemberFailed,
  addFamilyMemberRequested,
  addFamilyMemberSuccessful,
} from '../../../reducer/healthInsuredReducer/healthInsuredFamily/addFamilyMemberReducer'
import { addFamilyMemberAPI } from './familyApis'

function* handleAddFamilyMember({ payload = {} }) {
  const { memberDetails } = payload

  try {
    yield addFamilyMemberRequested()

    const { data } = yield call(addFamilyMemberAPI, memberDetails)

    yield alert(data.message)

    yield put(addFamilyMemberSuccessful(data))

    //Redirect to family dashboard
    window.location.replace('/health-family')
  } catch (error) {
    if (error.response) {
      alert(error.response.data.message)
      yield put(addFamilyMemberFailed([error.response.data.message]))
    } else {
      alert(error.message)
    }
  }
}

export function* watchAddFamilyMember() {
  yield takeEvery(addFamilyMemberRequested.type, handleAddFamilyMember)
}
