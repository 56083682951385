export const GET_HEALTHINSURED_ADMIN_DASHBOARD_ANALYTICS_REQUEST =
  'GET_HEALTHINSURED_ADMIN_DASHBOARD_ANALYTICS_REQUEST'

export const GET_HEALTHINSURED_ADMIN_DASHBOARD_ANALYTICS_SUCCESS =
  'GET_HEALTHINSURED_ADMIN_DASHBOARD_ANALYTICS_SUCCESS'

export const GET_HEALTHINSURED_ADMIN_DASHBOARD_ANALYTICS_FAILED =
  'GET_HEALTHINSURED_ADMIN_DASHBOARD_ANALYTICS_FAILED'

// HealthInsured Admin Activity Log
export const GET_HEALTHINSURED_ADMIN_ACTIVITY_LOG_REQUEST =
  ' GET_HEALTHINSURED_ADMIN_ACTIVITY_LOG_REQUEST'
export const GET_HEALTHINSURED_ADMIN_ACTIVITY_LOG_SUCCESS =
  'GET_HEALTHINSURED_ADMIN_ACTIVITY_LOG_SUCCESS'
export const GET_HEALTHINSURED_ADMIN_ACTIVITY_LOG_FAILED =
  'GET_HEALTHINSURED_ADMIN_ACTIVITY_LOG_FAILED'

// HealthInsured User Aqusition
export const GET_HEALTHINSURED_USER_ACQUISITION_REQUEST =
  'GET_HEALTHINSURED_USER_ACQUISITION_REQUEST'

export const GET_HEALTHINSURED_USER_ACQUISITION_SUCCESS =
  'GET_HEALTHINSURED_USER_ACQUISITION_SUCCESS'

export const GET_HEALTHINSURED_USER_ACQUISITION_FAILED =
  'GET_HEALTHINSURED_USER_ACQUISITION_FAILED'

// HealthInsured Subscriber Aqusition
export const GET_HEALTHINSURED_SUBSCRIBER_ACQUISITION_REQUEST =
  'GET_HEALTHINSURED_SUBSCRIBER_ACQUISITION_REQUEST'

export const GET_HEALTHINSURED_SUBSCRIBER_ACQUISITION_SUCCESS =
  'GET_HEALTHINSURED_SUBSCRIBER_ACQUISITION_SUCCESS'

export const GET_HEALTHINSURED_SUBSCRIBER_ACQUISITION_FAILED =
  'GET_HEALTHINSURED_SUBSCRIBER_ACQUISITION_FAILED'

// Get Health Insurance Profiles for admin
export const GET_INSURANCE_PROFILES_REQUEST = 'GET_INSURANCE_PROFILES_REQUEST'
export const GET_INSURANCE_PROFILES_SUCCESS = 'GET_INSURANCE_PROFILES_SUCCESS'
export const GET_INSURANCE_PROFILES_FAILED = 'GET_INSURANCE_PROFILES_FAILED'

// Get full details of a particular health insurance user
export const GET_EXTENDED_USER_INSURANCE_PROFILE_REQUEST =
  'GET_EXTENDED_USER_INSURANCE_PROFILE_REQUEST'
export const GET_EXTENDED_USER_INSURANCE_PROFILE_SUCCESS =
  'GET_EXTENDED_USER_INSURANCE_PROFILE_SUCCESS'
export const GET_EXTENDED_USER_INSURANCE_PROFILE_FAILED =
  'GET_EXTENDED_USER_INSURANCE_PROFILE_FAILED'

// Get Health Insured Admin Transaction Logs
export const GET_HEALTHINSURED_ADMIN_TRANSACTION_LOGS_REQUEST =
  'GET_HEALTHINSURED_ADMIN_TRANSACTION_LOGS_REQUEST'
export const GET_HEALTHINSURED_ADMIN_TRANSACTION_LOGS_SUCCESS =
  'GET_HEALTHINSURED_ADMIN_TRANSACTION_LOGS_SUCCESS'
export const GET_HEALTHINSURED_ADMIN_TRANSACTION_LOGS_FAILED =
  'GET_HEALTHINSURED_ADMIN_TRANSACTION_LOGS_FAILED'

//Get Health Insured Admin User Activity Logs
export const GET_HEALTHINSURED_ADMIN_USER_ACTIVITY_LOG_REQUEST =
  'GET_HEALTHINSURED_ADMIN_USER_ACTIVITY_LOG_REQUEST'
export const GET_HEALTHINSURED_ADMIN_USER_ACTIVITY_LOG_SUCCESS =
  'GET_HEALTHINSURED_ADMIN_USER_ACTIVITY_LOG_SUCCESS'
export const GET_HEALTHINSURED_ADMIN_USER_ACTIVITY_LOG_FAILED =
  'GET_HEALTHINSURED_ADMIN_USER_ACTIVITY_LOG_FAILED'

//Get health insured admin error logs
export const GET_HEALTHINSURED_ADMIN_ERROR_LOGS_REQUEST =
  ' GET_HEALTHINSURED_ADMIN_ERROR_LOGS_REQUEST'
export const GET_HEALTHINSURED_ADMIN_ERROR_LOGS_SUCCESS =
  ' GET_HEALTHINSURED_ADMIN_ERROR_LOGS_SUCCESS'
export const GET_HEALTHINSURED_ADMIN_ERROR_LOGS_FAILED =
  ' GET_HEALTHINSURED_ADMIN_ERROR_LOGS_FAILED'
