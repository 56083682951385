import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import axios from 'axios'

import Header from '../header/Header'
import MainSection from './mainSection/MainSection'
import ChangeCardModal from './changeCardModal/ChangeCardModal'
import ResponseModal from '../../../../pharmHub/admin/components/responseModal/ResponseModal'
import CancelSubscriptionModal from './cancelSUbscriptionModal/CancelSUbscriptionModal'
import { getHealthInsuredIndividualProfileSuccess } from '../../../../../redux/healthBanc/actions/healthInsuredCreators/healthInsuredIndividualProfile'
import { HEALTH_INSURED_INDIVIDUAL_PROFILE_SUCCESS } from '../../../../../redux/healthBanc/actions/healthInsuredTypes/healthInsuredIndividualProfile'

const urlCards = '/v2/api/Tokenization/GetCards'
const urlChangeCard = '/v2/api/Tokenization/ChangePrimaryCard'
const cancelSubscriptionUrl = '/v2/api/Tokenization/CancelSubscription'
const baseUrl = process.env.REACT_APP_BASE_URL

const Subscription = ({ history }) => {
  const [showChangeCard, setShowChangeCard] = useState(false)
  const [showCancelSUbscriptionModal, setShowCancelSubscriptionModal] =
    useState(false)
  const [cards, setCards] = useState([])
  const [cardInfo, setCardInfo] = useState('')
  const [loading, setLoading] = useState(false)
  const [showResponseModal, setShowResponseModal] = useState(false)
  const [error, setError] = useState('')
  const [changeCardLoading, setchangeCardLoading] = useState(false)
  const [sucess, setSuccess] = useState('')
  const [cancellelationReason, setCancellationReason] = useState('')
  const [isChecked, setIsChecked] = useState(false)
  const [cancelLoading, setCancelLoading] = useState(false)
  const [cancelSuccess, setCancelSuccess] = useState('')
  const [cancelError, setCancelError] = useState('')

  useEffect(() => {
    let signal = axios.CancelToken.source() //instanciate abort signal to cancel subscription
    setLoading(true)

    axios
      .get(`${baseUrl}${urlCards}`, { cancelToken: signal.token }) //make api call and pass signal to enable subscription canncellation
      .then((response) => {
        setCards(response.data.data)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        setShowResponseModal(true)
        if (error.response) return setError(error.response.data.message)
      })
    return () => {
      signal.cancel() //cancel subscription on component willUnMount
    }
  }, [])

  const dispatch = useDispatch()

  /** close change modal card*/
  const closeChangeCardModal = () => setShowChangeCard(false)

  /**show change card modal */
  const handleShowCardModal = () => {
    if (cards.length === 0) return alert('You have no card, Kindly add card ')
    if (cards.length === 1)
      return alert('You have only one card. Kindly add card to make a switch')
    setShowChangeCard(true)
  }

  /**show cancel modal */
  const handleShowCancelModal = () => setShowCancelSubscriptionModal(true)

  /**close cancel subscription modal */
  const closeCancelSubscriptionModal = () => {
    setCancellationReason('') // reset cancelling reason if reason has been selected
    setShowCancelSubscriptionModal(false)
  }

  /**get user card choice to change */
  const changeCardInput = (e) => {
    let id = e.target.value
    let status = e.target.dataset.status //get customer card status
    const card = { id, status } //construct object for customer card info
    setCardInfo(card)
  }

  /**get user card choice to change */
  const cancelSubscriptionInput = (e) => {
    let checkedOtherOptions =
      document.querySelector('input#other-reason').checked
    setIsChecked(checkedOtherOptions)

    if (e.target.value === 'other options') {
      //reset reason if other option is selected
      return setCancellationReason('')
    }

    let reason = e.target.value
    setCancellationReason(reason)
  }

  const cancelSubscription = async (e) => {
    e.preventDefault()
    if (cancellelationReason === '')
      return alert('please choose a reason for cancellation')
    setCancelLoading(true)
    try {
      const response = await axios.post(`${baseUrl}${cancelSubscriptionUrl}`, {
        data: {
          Reason: cancellelationReason,
        },
      })
      const success = await response.data.message
      // axaUserProfile(response.data.data); //send user profile to redux store
      dispatch(
        getHealthInsuredIndividualProfileSuccess({
          type: HEALTH_INSURED_INDIVIDUAL_PROFILE_SUCCESS,
          payload: response.data.data,
        })
      )

      const cardResponse = await axios.get(`${baseUrl}${urlCards}`) //call endpoint to get current update of the card array
      await setCards(cardResponse.data.data)
      setCancelLoading(false)
      alert(success)
      closeCancelSubscriptionModal() //hide cancel modal
    } catch (error) {
      setCancelLoading(false)
      if (error.response) {
        setCancelError(error.response.data.message)
        alert(error.response.data.message)
      } else {
        console.log(error.message)
      }
    }
  }

  /**change user primary card */
  const changeCard = async (e) => {
    e.preventDefault()
    if (cardInfo.id === '') return alert('please input your card')
    setchangeCardLoading(true)
    try {
      const response = await axios.post(`${baseUrl}${urlChangeCard}`, {
        data: {
          Id: cardInfo.id,
        },
      })
      const success = await response.data.message

      const cardResponse = await axios.get(`${baseUrl}${urlCards}`) //call endpoint to get current update of the card array
      await setCards(cardResponse.data.data)
      await setchangeCardLoading(false)
      await alert(success)
      closeChangeCardModal()
    } catch (error) {
      setchangeCardLoading(false)
      if (error.response) return alert(error.response.data.message)
    }
  }

  /**hide response modal */
  const hideSHowResonseModal = () => {
    if (error === 'Session has expired') return history.push('/signin') //redirect user to signin page if session has expired
    setShowResponseModal(false)
  }

  return (
    <div>
      <ResponseModal
        show={showResponseModal}
        handleHide={hideSHowResonseModal}
        error={error}
        success={''}
      />

      <ChangeCardModal
        show={showChangeCard}
        handleClose={closeChangeCardModal}
        cards={cards}
        handleAction={changeCard}
        handleChange={changeCardInput}
        loading={changeCardLoading}
      />

      <CancelSubscriptionModal
        show={showCancelSUbscriptionModal}
        handleClose={closeCancelSubscriptionModal}
        handleChange={cancelSubscriptionInput}
        handleAction={cancelSubscription}
        loading={cancelLoading}
        cancelError={cancelError}
        cancelSuccess={cancelSuccess}
        isChecked={isChecked}
      />

      <Header />
      <MainSection
        handleShowCardModal={handleShowCardModal}
        handleShowCancelModal={handleShowCancelModal}
        cards={cards}
        loading={loading}
      />
    </div>
  )
}

export default Subscription
