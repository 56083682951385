import {
  UPDATE_CORPORATE_PROFILE_FAILED,
  UPDATE_CORPORATE_PROFILE_REQUEST,
  UPDATE_CORPORATE_PROFILE_SUCCESS,
} from '../../../actions/healthInsuredTypes/corporateActionTypes'

export const updateCorporateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_CORPORATE_PROFILE_REQUEST:
      return { isLoading: true }

    case UPDATE_CORPORATE_PROFILE_SUCCESS:
      window.location.href = '/health_corporate/profile_update_success'
      return { isLoading: false, ...action.payload }

    case UPDATE_CORPORATE_PROFILE_FAILED:
      alert(action.payload)
      return { isLoading: false, errorMessage: action.payload }

    default:
      return state
  }
}
