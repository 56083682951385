import React from 'react';

import NewNotificationModal from '../../template/NewNotificationModal';

const EmtyNotification = ({
  handleCreateNew,
  show,
  handleHide,
  filterOptions
}) => {

  return (
    <section className='hb-main-section hb-registered-users p-4 d-flex flex-column align-items-center justify-content-center'>
      <NewNotificationModal
        show={show}
        handleHide={handleHide}
        filterOptions={filterOptions}
      />
      <h3>No notifications here</h3>
      <p className='mb-2'>Start by creating a notification.</p>
      <button className='btn btn-danger text-white px-3 ' onClick={handleCreateNew}>
        Create a Notification
      </button>
    </section>
  );
};

export default EmtyNotification;
