import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useFormik } from 'formik'
import * as yup from 'yup'
import NumberFormat from 'react-number-format'
import fileDownload from 'js-file-download'

import './identifierModal.scss'
import { ReactComponent as Close } from '../../../../../../assets/healthInsuredAdmin/close.svg'
import Input from '../../../../../../healthBancComponents/Templates/CustomInput'
import ResponseToast from '../../../../template/responseToast/ResponseToast'
import axios from 'axios'

const downloadIdentifierUrl = '/v2/api/Insurance/DownloadIdentifiers'

const IdentifierModal = ({ show, close }) => {
  const [token] = useState(localStorage.getItem('adminInfo'))
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const generateIdentifiers = async (values, handleReset) => {
    try {
      setLoading(true)

      const { data } = await axios.post(`${downloadIdentifierUrl}`, {
        data: { Id: Number(values.count) },
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'blob',
      })

      setLoading(false)
      handleReset()
      fileDownload(data, 'identifier.xlsx')
    } catch (error) {
      setLoading(false)
      error.response
        ? setError(error.response.data.message)
        : setError(error.message)
    }
  }

  const validationSchema = yup.object().shape({
    count: yup
      .number()
      .required('Required')
      .positive('Number must be a positive number'),
  })

  const formik = useFormik({
    initialValues: {
      count: '',
    },

    validationSchema,
    onSubmit: (values) => {
      generateIdentifiers(values, formik.handleReset)
    },
  })

  const closeToast = () => {
    setError('')
  }

  return (
    <Modal
      show={show}
      onHide={close}
      dialogClassName="modal-sz health__insured__admin__provider__identifier__modal"
      aria-labelledby="provider identifier modal"
      animation={true}
      autoFocus={true}
      keyboard={true}
    >
      <Modal.Body className="health__insured__admin__provider__identifier__modal--body">
        <ResponseToast
          error={error}
          showToast={error}
          closeToast={closeToast}
          delay={5000}
          right={50}
        />

        <i
          className="health__insured__admin__provider__identifier__modal--icon-wrapper"
          onClick={close}
        >
          <Close className="health__insured__admin__remove__admin__modal--icon-close" />
        </i>
        <h2 className="health__insured__admin__provider__identifier__modal--heading">
          Generate a Unique Identifier
        </h2>

        <p className="health__insured__admin__provider__identifier__modal--para">
          Insert a number to generate identifiers
        </p>

        <form
          className="p-0"
          onSubmit={formik.handleSubmit}
          id="healthadmin-update-role"
        >
          <div className="health__insured__admin__provider__identifier__modal--form-control">
            <label className="health__insured__admin__provider__identifier__modal--form-label"></label>
            <NumberFormat
              as={Input}
              className="health__insured__admin__provider__identifier__modal--form-input"
              type="tel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="count"
              value={formik.values.count}
            />
            {formik.touched.count && formik.errors.count ? (
              <div aria-labelledby="error message" className="text-danger">
                <small>*{formik.errors.count}</small>
              </div>
            ) : null}
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer className="health__insured__admin__provider__identifier__modal--footer">
        <button
          className="health__insured__admin__provider__identifier__modal--btn-remove"
          type="submit"
          onClick={formik.handleSubmit}
        >
          {loading ? 'Generating...' : 'Generate'}
        </button>
        <button
          className="health__insured__admin__provider__identifier__modal--btn-cancel"
          onClick={close}
        >
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default IdentifierModal
