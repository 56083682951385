import React from 'react';

import HealthInsuredDashboardStructure from '../../HealthInsuredDashboardStructure/HealthInsuredDashboardStructure';
import MainSection from '../../components/support/mainSection/PendingTicket';

const PendingTicket = () => {
  return (
    <HealthInsuredDashboardStructure title='Support'>
      <MainSection />
    </HealthInsuredDashboardStructure>
  );
};

export default PendingTicket;
