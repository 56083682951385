import React from 'react'
import { useHistory } from 'react-router-dom'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import CustomButton from '../../../../healthBancComponents/Templates/CustomButton'
import StatusStyle from './StatusPage.style'

const PaymentFailed = () => {
  const history = useHistory()

  return (
    <StatusStyle>
      <AiOutlineCloseCircle className="icon-failed" />
      <div className="status-massage ms-4">
        <h2>Oops!!!</h2>
        <p>There was an error processing your payment</p>
        <div className="col-sm-12 col-md-7">
          <CustomButton onClick={() => history.push('/OneDrugStore/shop')}>
            Back to OneDrugStore
          </CustomButton>
        </div>
      </div>
    </StatusStyle>
  )
}

export default PaymentFailed
