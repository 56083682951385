import {
  SIGNUP_FAILED,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
} from '../actions/actionTypes'

const INITIAL_STATE = {
  isLoading: false,
}

export const signupReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SIGNUP_REQUEST:
      return { isLoading: true }

    case SIGNUP_SUCCESS:
      return { isLoading: false, ...action.payload }

    case SIGNUP_FAILED:
      return { isLoading: false, ...action.payload }

    default:
      return state
  }
}
