/**
 * @param {*} userDetails 
 * @description validate provider's details
 * @returns {false} if there is no validation error
 */

function providerValidator(userDetails) {
  /**destructure form details */
  const {
    CareProviderName,
  } = userDetails;

  /**create error object */
  const error = {
    CareProviderNameError: '',
  };

  if (CareProviderName === '') {
    error.CareProviderNameError = "care provider's name is required";
    return error;
  }

  return false;
}

export default providerValidator;