import React from 'react';
import Header from '../dashboard/Header';
import Sidebar from '../dashboard/Sidebar';
import MainSection from './MainSection';

const Profile = () => {

  return (
    <main className='hb-admin-dashboard'>
      <div className='hb-admin-item'>
        <Header />
      </div>
      <div className='hb-admin-item'>
        <div className='hb-admin-child'>
          <Sidebar />
        </div>
        <div className='hb-admin-child'>
          <MainSection />
        </div>
      </div>
    </main >
  );
};

export default Profile;
