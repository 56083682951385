import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import BackgroundTemplate from './Templates/BackgroundTemplate'
import CustomButton from './Templates/CustomButton'

const ErrorMsg = styled.h1`
  .error__main {
    display: block;
    font-size: 70px;
    font-weight: 700;
    letter-spacing: 4px;
    color: #bbb;
  }

  .error__sub {
    font-size: 1.2rem;
  }
`

const ErrorPage = () => {
  const history = useHistory()

  return (
    <BackgroundTemplate showLogo={true}>
      <div className="mx-auto">
        <ErrorMsg className="text-center">
          <span className="error__main">404</span>
          <span className="error__sub">
            Sorry, we couldn't find the page you are looking for.
          </span>
        </ErrorMsg>
        <div className="col-sm-4 col-md-2 col-6 mx-auto mt-5">
          <CustomButton onClick={() => history.goBack()}>Go Back</CustomButton>
        </div>
      </div>
    </BackgroundTemplate>
  )
}

export default ErrorPage
