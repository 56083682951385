import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Table, FormCheck } from 'react-bootstrap'
import Spinner from 'react-bootstrap/Spinner'
import { format } from 'date-fns'
import LoadingIndicator from '../../../../../healthBancComponents/LoadingIndicator/LoadingIndicator'
import CustomButton from '../../../../../healthBancComponents/Templates/CustomButton'
import HealthInsuredPagination from '../../components/healthInsuredPagination/HealthInsuredPagination'

const InactiveBeneficiariesTable = () => {
  const [userEmail, setUserEmail] = useState('')

  const dispatch = useDispatch()

  const inactiveBeneficiaries = useSelector(
    (state) => state.fetchCorporateBeneficiaries
  )

  const moveInactiveBeneficiariesToPendingList = useSelector(
    (state) => state.moveInactiveBeneficiariesToPendingList
  )

  useEffect(() => {
    dispatch({ type: 'FETCH_CORPORATE_BENEFICIARIES', payload: { status: 3 } })
  }, [dispatch])

  const handleSendToPendingList = (email) => {
    setUserEmail(email)
    dispatch({
      type: 'MOVE_INACTIVE_BENEFICIARIES_TO_PENDING_LIST',
      payload: [email],
    })
  }

  const handleGoToPrevPage = () => {
    if (inactiveBeneficiaries && inactiveBeneficiaries.data) {
      const { data } = inactiveBeneficiaries
      if (data.pageNumber === 1) {
        return
      } else {
        dispatch({
          type: 'FETCH_CORPORATE_BENEFICIARIES',
          payload: { pageNumber: data.pageNumber - 1, status: 3 },
        })
      }
    }
  }

  const handleGoToNextPage = () => {
    if (inactiveBeneficiaries && inactiveBeneficiaries.data) {
      const { data } = inactiveBeneficiaries
      if (data.pageNumber === data.pageCount) {
        return
      } else {
        dispatch({
          type: 'FETCH_CORPORATE_BENEFICIARIES',
          payload: { pageNumber: data.pageNumber + 1, status: 3 },
        })
      }
    }
  }

  const beneficiariesList =
    inactiveBeneficiaries &&
    inactiveBeneficiaries.data &&
    inactiveBeneficiaries.data.data &&
    inactiveBeneficiaries.data.data.map((beneficiary, index) => (
      <tr key={index}>
        <td className="text-center">
          <FormCheck className="px-auto" />
        </td>
        <td>{beneficiary.fullName}</td>
        <td className="text-wrap">
          {beneficiary.enrolleNumber === null
            ? 'Pending'
            : beneficiary.enrolleNumber}
        </td>
        <td>{beneficiary.email}</td>
        <td>{beneficiary.phoneNumber}</td>
        <td>{beneficiary.amount.toFixed(2)}</td>
        <td>{format(new Date(beneficiary.dateCreated), 'dd-MM-yyyy')}</td>
        <td>
          <CustomButton
            onClick={() => handleSendToPendingList(beneficiary.email)}
            disabled={
              moveInactiveBeneficiariesToPendingList &&
              moveInactiveBeneficiariesToPendingList.isLoading &&
              userEmail === beneficiary.email
            }
          >
            {moveInactiveBeneficiariesToPendingList &&
            moveInactiveBeneficiariesToPendingList.isLoading &&
            userEmail === beneficiary.email ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              'Move to Pending'
            )}
          </CustomButton>
        </td>
      </tr>
    ))

  return (
    <>
      {inactiveBeneficiaries.isLoading ? (
        <div className="mx-auto">
          <LoadingIndicator />
        </div>
      ) : (
        <>
          <Table
            striped
            hover
            responsive
            className="beneficiary__table"
            id="inactive-beneficiary-table-to-excel" //If reusing the BeneficiaryFilter Component, table Id is required and Must be the same as the tableName value passed to the beneficiaryFilter component for the 'export as excel' feature to work
          >
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Enrollee No.</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Amount</th>
                <th>Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {beneficiariesList && beneficiariesList.length > 0 ? (
                beneficiariesList
              ) : (
                <tr>
                  <td colSpan="7" className="text-center">
                    Currently no beneficiaries
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <div className="ml-auto my-4">
            <HealthInsuredPagination
              prev={handleGoToPrevPage}
              next={handleGoToNextPage}
              pageDetails={
                inactiveBeneficiaries && inactiveBeneficiaries.data
                  ? inactiveBeneficiaries.data
                  : {}
              }
            />
          </div>
        </>
      )}
    </>
  )
}
export default InactiveBeneficiariesTable
