import React, { useState } from 'react'
import './wallet.css'
import { useHistory } from 'react-router-dom'
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { FiLink } from 'react-icons/fi'
import Payment from '../Payment';
import { useSelector } from 'react-redux'
import axios from 'axios'

const { REACT_APP_BASE_URL } = process.env

const Wallet = () => {
    const {token} = useSelector(state => state.user.userInfo)

    const [createWallet, setCreateWallet] = useState(true);
    const [isColor, setIsColor] = useState(false);
    const [hover, setHover] = useState(false);
    const [payWithWallet, setPayWithWallet] = useState(true)
    const [data, setData] = useState([])
    let history = useHistory();


    // function that directs them to the create wallet or link wallet 
    const handleClick = () => {
        history.push('/pay_with_wallet/create_wallet')
    }

    function handleClick2() {
        history.push('/pay_with_wallet/link_wallet')
    }
    return (
        <section className='wallet-setup'>

        <div className="setup" style={{marginTop: '0'}}>
        
            <div className="setup2" >
                <div className='set-user'>
                <p style={{ margin: 0, padding: 0 }}>{data.email}</p>
                <p style={{ margin: 0, padding: 0 }}>Pay <span style={{color: 'red', fontWeight: 'bold'}}>Amount</span></p>
                </div>
                
            <section>

            <main className='main-class'>
            <div className="inner_setup">
                <div className="top_head">
                    <h2>Set up Wallet Payment</h2>
                    <p>Select Wallet Option</p>
                </div>
            <div className="create_link">
                
                <div className='create_wall' 
                    style={{display: 'block', backgroundColor: hover ? '#F3F3F4' : '#FCFCFC', cursor: 'pointer'}} 
                    onMouseEnter={() => {setHover(true); setIsColor(true)}} 
                    onMouseOver={() => {setCreateWallet(true)}} 
                    onClick={() => setIsColor(true)}
                    >
                    <AiOutlinePlusCircle />
                    <p>Create New Wallet</p>
                </div>

                <div className="link_wall"
                    style={{display: 'block', backgroundColor: hover ? '#FCFCFC' : '#F3F3F4', cursor: 'pointer' }} 
                    onMouseEnter={() => {setHover(false); setIsColor(false)}} 
                    onMouseOver={() => {setCreateWallet(false)}}  
                    onClick={() => {setIsColor(false)}}
                    >
                    < FiLink />
                    <p>Link Existing Wallet</p>
                    
                </div>
                </div>


            </div>
            {/* button color conditional */}
            { 
    
            }
            
            {/*  Wallet button conditional */}
            {
                createWallet ? (
                <div className='setup-btns'>
                    <button onClick={handleClick} style={{backgroundColor: isColor ? '#D71E1B' : '#FEFEFE'}}><p style={{color: isColor ? '#FEFEFE' : '#4F4F4F' }}>Create Wallet</p></button>
                    <p className='desc-btn' style={{visibility: hover ? 'visible': 'hidden'}}>Easily create a digital wallet for your health insurance. </p>
                </div>
                ) : (
                    <div className='setup-btns'>
                    <button onClick={handleClick2} style={{backgroundColor: isColor ? '#A4A4A4' : '#D71E1B'}}><p style={{color: '#FFFFFF', backgroundColor: isColor ? '' : ''}}>Link Wallet</p></button>
                    <div className='desc-div desc-btn' style={{visibility: hover ? 'hidden': 'visible'}}>
                        <p >Link your exsisting wallet. </p>
                        <p  style={{visibility: hover ? 'hidden': 'visible', color: 'red', fontStyle: 'italic'}}>
                        NB if you have a sterling bank account you have a wallet. 😉</p>
                    </div>
                
                </div>
                )
            }
            </main>

                
                
                </section>
            </div>
        </div>
    </section>
    )
}

export default Wallet;