import React from 'react'
import styled from 'styled-components'

//Import parnter logos
import DrugStore from '../../../assets/healthBancLandingPageImgs/partnersLogos/drugstore.png'
// import Fidson from '../../../assets/healthBancLandingPageImgs/partnersLogos/fidson.png'
// import Gsk from '../../../assets/healthBancLandingPageImgs/partnersLogos/gsk.png'
// import HealthPlus from '../../../assets/healthBancLandingPageImgs/partnersLogos/healthPlus.png'
// import MedPlus from '../../../assets/healthBancLandingPageImgs/partnersLogos/medPlus.png'
import hygiea from '../../../assets/healthBancLandingPageImgs/partnersLogos/hygiea.svg'
import axa from '../../../assets/healthBancLandingPageImgs/partnersLogos/axa.svg'

const Section = styled.section`
	background-color: #f2f4f8;
	position: relative;
	padding-top: 50px;
	padding-bottom: 50px;
	/* margin-bottom: 200px; */

	.partnerStatement {
		/* max-width: 400px;
    margin: 0 20px; */

		h3 {
			color: #213f7d;
			font-size: 16px;
			font-weight: 700;
		}

		/* p.intro {
      font-size: 20px;
      font-weight: 700;
      line-height: 130%;
      color: #0c162c;
    } */

		/* p {
      font-size: 16px;
      color: #474747;
      line-height: 150%;
    } */
	}

	.partnerLogos {
		img {
			width: auto;
			max-height: 39px;
			margin: 20px;
		}
	}
`

const Partners = () => {
	return (
		<Section className='px-md-5'>
			<div className='container'>
				<div className='partnerStatement col-md-6'>
					<h3 className='mb-4'>OUR PARTNERS</h3>
					{/* <p className='intro'>
                    Access to good Healthcare is a challenging issue in Nigeria. One we can’t tackle alone.
                </p>
                <p className=''>
                    HealthBanc is focused on bridiging the gap between <span className='text-danger bold'>more text go in here, more text go in here,more text.</span>
                </p> */}
				</div>

				{/* <div className='partnerLogos col-md-6'> */}
				<div className='partnerLogos d-flex flex-row flex-wrap  justify-content-around'>
					<img src={hygiea} alt='' />
					{/* <img src={MedPlus} alt='' />
				<img src={Gsk} alt='' />
				<img src={HealthPlus} alt='' />
				<img src={Fidson} alt='' /> */}
					<img src={axa} alt='' />
					<img src={DrugStore} alt='' />
				</div>
			</div>
		</Section>
	)
}

export default Partners
