import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import CustomButton from '../../../../../healthBancComponents/Templates/CustomButton'

function RejectOrderModal({ show, setShow, handleSubmit }) {
  const [textInput, setTextInput] = useState('')

  const handleClose = () => setShow(false)

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Reason for rejection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea
            rows="5"
            className="form-control outline"
            value={textInput}
            onChange={(e) => setTextInput(e.target.value)}
          ></textarea>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex col-6">
            <CustomButton outline className="me-3" onClick={handleClose}>
              Cancel
            </CustomButton>
            <CustomButton onClick={handleSubmit}>Submit</CustomButton>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default RejectOrderModal
