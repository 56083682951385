import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import axios from 'axios'

import CardProviderSection from '../careProviderSection/CareProviderSection'
import NavBar from '../../navbar/NavBar'
// import { getHealthInsuredIndividualProfile } from '../../../../../../redux/healthBanc/actions/healthInsuredCreators/healthInsuredIndividualProfile'
// import { profileCompletionSuccess } from '../../../../../../redux/healthBanc/actions/healthInsuredCreators/profileCompletionStatus'
import Alert from '../../../../../../healthBancServices/healthInsured/template/alert/ALert'

// const customerStateUrl = '/v2/api/Insurance/GetProfileCompletion'
// const profileUrl = '/v2/api/Insurance/GetUserInsuranceProfile'

const MainSection = () => {
  const [profile, setProfile] = useState({})
  const [error, setError] = useState()
  const [loading, setLoading] = useState()

  const dispatch = useDispatch()
  const history = useHistory()

  // useEffect(() => {
  //   setLoading(true)

  //   function getProfile() {
  //     return axios.get(profileUrl)
  //   }

  //   function getUserStatus() {
  //     return axios.get(customerStateUrl)
  //   }

  //   axios
  //     .all([getProfile(), getUserStatus()])
  //     .then(
  //       axios.spread((profileResponse, profileCompletionStatusResponse) => {
  //         const { profileCompleted, tokenizationCompleted } =
  //           profileCompletionStatusResponse.data.data

  //         dispatch(
  //           profileCompletionSuccess(profileCompletionStatusResponse.data.data)
  //         )

  //         // // check if a user has signed up and tokenized
  //         if (profileCompleted === false || tokenizationCompleted === false) {
  //           history.push('/health_signup')
  //         }

  //         setProfile(profileResponse.data.data)
  //         getHealthInsuredIndividualProfile(profileResponse.data.data)

  //         setLoading(false)
  //       })
  //     )
  //     .catch((error) => {
  //       setLoading(false)

  //       error.response
  //         ? setError(error.response.data.message)
  //         : setError(error.message)
  //     })
  // }, [])

  return (
    <main className="axa-mansard-dashboard-mainsection px-3 pb-4">
      <NavBar />
      {error && (
        <Alert variant="danger" showAlert={true}>
          {' '}
          {error}{' '}
        </Alert>
      )}
      <CardProviderSection profile={profile} loading={loading} />
    </main>
  )
}

export default MainSection
