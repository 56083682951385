import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: [],
  errors: [],
}

const healthCentersSlice = createSlice({
  name: 'Health center list',
  initialState,
  reducers: {
    HEALTH_CENTERS_REQUESTED: (state) => {
      return { isLoading: true, data: [], errors: [] }
    },

    HEALTH_CENTERS_SUCCESSFUL: (state, action) => {
      return { isLoading: false, data: [...action.payload] }
    },

    HEALTH_CENTERS_FAILED: (state, action) => {
      return { isLoading: false, data: [], errors: [...action.payload] }
    },
  },
})

export const {
  HEALTH_CENTERS_REQUESTED,
  HEALTH_CENTERS_SUCCESSFUL,
  HEALTH_CENTERS_FAILED,
} = healthCentersSlice.actions

export default healthCentersSlice.reducer
