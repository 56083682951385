
import {
  TOWN_OF_RESIDENCE_SUCCESS,
} from '../healthInsuredTypes/townOfResidence';

export const getTownOfResidence = (data) => async (dispatch) => {

    dispatch({
      type: TOWN_OF_RESIDENCE_SUCCESS,
      payload: data,
    });
};