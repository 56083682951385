import {
  GET_CORPORATE_ANALYTICS_FAILED,
  GET_CORPORATE_ANALYTICS_REQUEST,
  GET_CORPORATE_ANALYTICS_SUCCESS,
} from '../../healthInsuredTypes/corporateActionTypes'

export const getCorporateAnalyticsRequest = () => ({
  type: GET_CORPORATE_ANALYTICS_REQUEST,
})

export const getCorporateAnalyticsSuccess = (payload) => ({
  type: GET_CORPORATE_ANALYTICS_SUCCESS,
  payload,
})

export const getCorporateAnalyticsFailed = (errorMessage) => ({
  type: GET_CORPORATE_ANALYTICS_FAILED,
  payload: errorMessage,
})
