import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import HealthInsuredDashboardStructure from '../../HealthInsuredDashboardStructure/HealthInsuredDashboardStructure'
import StatsCard from '../../components/dashboard/statsCard/StatsCard'
import UserAquisitionChart from '../../components/dashboard/UserAquisitionChart/UserAquisitionChart'
import SubscriberAquisitionChart from '../../components/dashboard/SubscriberAquisitionChart/SubscriberAquisitionChart'
import ActivityLog from '../../components/dashboard/ActivityLog/ActivityLog'

import './healthInsuredAdminDashboard.scss'

const HealthInsuredAdminDashboard = () => {
  const [activityPageNumber, setActivityPageNumber] = useState(1)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: 'GET_HEALTHINSURED_ADMIN_DASHBOARD_ANALYTICS' })
    dispatch({ type: 'GET_HEALTHINSURED_USERS_ACQUISITION' })
    dispatch({ type: 'GET_HEALTHINSURED_SUBSCRIBER_ACQUISITION' })
    dispatch({
      type: 'GET_HEALTHINSURED_ADMIN_ACTIVITY_LOG',
      payload: { pageNumber: activityPageNumber },
    })
  }, [activityPageNumber, dispatch])

  const adminDashboardAnalytics = useSelector(
    (state) => state.getHealthInsuredAdminDashboardAnalytics
  )

  const userAcquisition = useSelector(
    (state) => state.getHealthInsuredUserAcquisition
  )

  const subscriberAcquisition = useSelector(
    (state) => state.getHealthInsuredSubscriberAcquisition
  )

  const adminActivityLog = useSelector(
    (state) => state.getHealthInsuredAdminActivityLog
  )

  const handleFilterUserAquisition = (value) => {
    dispatch({
      type: 'GET_HEALTHINSURED_USERS_ACQUISITION',
      payload: Number(value),
    })
  }

  const handleFilterSubscriberAquisition = (value) => {
    dispatch({
      type: 'GET_HEALTHINSURED_SUBSCRIBER_ACQUISITION',
      payload: Number(value),
    })
  }

  const handleGoNextPage = () => {
    if (adminActivityLog.hasOwnProperty('data')) {
      const data = adminActivityLog.data
      if (data.pageNumber === data.pageCount) {
        return
      } else {
        setActivityPageNumber(activityPageNumber + 1)
      }
    }
  }

  const handleGoPrevPage = () => {
    if (adminActivityLog.hasOwnProperty('data')) {
      const data = adminActivityLog.data
      if (data.pageNumber === 1) {
        return
      } else {
        setActivityPageNumber(activityPageNumber - 1)
      }
    }
  }

  return (
    <HealthInsuredDashboardStructure title="Dashboard">
      <section>
        <StatsCard
          data={
            adminDashboardAnalytics && adminDashboardAnalytics.data
              ? adminDashboardAnalytics.data
              : {}
          }
        />
      </section>

      <section className="healthInsured-dashboard__activities">
        <div className="user_acquisition_graph graph">
          <UserAquisitionChart
            data={userAcquisition}
            filterByTimeFrame={handleFilterUserAquisition}
          />
        </div>

        <div className="subscriber_aquisition_graph graph">
          <SubscriberAquisitionChart
            data={subscriberAcquisition}
            filterByTimeFrame={handleFilterSubscriberAquisition}
          />
        </div>

        <section className="activity_log--container">
          <ActivityLog
            data={adminActivityLog}
            handleGoNextPage={handleGoNextPage}
            handleGoPrevPage={handleGoPrevPage}
          />
        </section>
      </section>
    </HealthInsuredDashboardStructure>
  )
}

export default HealthInsuredAdminDashboard
