import axios from 'axios'

const statesAPI = '/v2/api/Insurance/GetState'
const townsAPI = '/v2/api/Insurance/GetTowns'
const healthCentersAPI = '/v2/api/Insurance/GetHealthProvider'

export const getAllStatesAPI = async () => {
  const states = await axios.get(statesAPI)
  return states
}

export const getTownsAPI = async (payload) => {
  return await axios.post(`${townsAPI}`, {
    data: {
      InsurancePovider: 1,
      State: payload,
    },
  })
}

export const getHealthCentersAPI = async ({
  selectedState,
  selectedTown,
  insuranceProvider = 'axamansard',
}) => {
  const list = await axios.post(`${healthCentersAPI}`, {
    data: {
      InsurancePovider: insuranceProvider,
      State: selectedState,
      City: selectedTown,
    },
  })

  return list
}
