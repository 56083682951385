import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Field, Form } from 'formik'
import * as yup from 'yup'
import Layout from '../../../components/adminComponents/Layout/Layout'
import CustomInput from '../../../../../healthBancComponents/Templates/CustomInput'
import CustomTextarea from '../../../../../healthBancComponents/Templates/CustomTextarea'
import CustomButton from '../../../../../healthBancComponents/Templates/CustomButton'
import {
  createPharmacyRequested,
  updatePharmacyRequested,
} from '../../../../../redux/healthBanc/reducer/oneDrugStore/admin/pharmacySlices'

const CreatePharmacy = () => {
  const [pharmacy, setPharmacy] = useState({})

  const dispatch = useDispatch()
  const history = useHistory()

  const { aggregatorId } = useParams()
  const { pharmacyId } = useParams()

  const isForUpdate =
    history.location.pathname ===
    `/admin/oneDrugStore/aggregator/pharmacy-update/${pharmacyId}`

  const { isLoading: isCreating } = useSelector((state) => state.createPharmacy)
  const { isLoading: isUpdating } = useSelector((state) => state.updatePharmacy)
  const pharmacies = useSelector((state) => state.getAllPharmacy.data)

  useEffect(() => {
    if (isForUpdate) {
      const pharmacyForEdit = pharmacies?.data?.find(
        (pharmacy) => pharmacy.id === +pharmacyId
      )
      setPharmacy(pharmacyForEdit)
    }
  }, [isForUpdate, pharmacies, pharmacyId])

  const pharmacySchema = yup.object().shape({
    name: yup.string().required('This field is required'),
    description: yup.string(),
    address: yup.string(),
  })

  const handleSubmit = (values, actions) => {
    if (isForUpdate) {
      dispatch(
        updatePharmacyRequested({
          ...values,
          pharmacyId: Number(pharmacyId),
          history,
        })
      )
    } else {
      dispatch(
        createPharmacyRequested({
          pharmaDetails: { ...values, aggregatorId },
          history,
        })
      )
    }
  }

  return (
    <Layout canGoBack>
      <div className="mt-5 mx-4">
        <div className="mt-5">
          <h4>{isForUpdate ? 'Update Pharmacy' : 'Create Pharmacy'}</h4>

          <div>
            <Formik
              initialValues={{
                name: pharmacy?.name || '',
                description: pharmacy?.description || '',
                address: pharmacy?.address || '',
              }}
              enableReinitialize={true}
              validationSchema={pharmacySchema}
              onSubmit={(values, actions) => handleSubmit(values, actions)}
            >
              {(props) => (
                <Form>
                  <div className="col-6 ms-4 my-4">
                    <Field
                      as={CustomInput}
                      id="name"
                      type="text"
                      label="Name"
                      name="name"
                      placeholder="Enter an pharmacy name"
                    />

                    <Field
                      as={CustomTextarea}
                      id="description"
                      type="text"
                      label="Description"
                      name="description"
                      placeholder="Brief description"
                    />

                    <Field
                      as={CustomInput}
                      id="address"
                      type="text"
                      label="Address"
                      name="address"
                      placeholder="Enter a pharmacy address"
                    />
                    <div className="col-6">
                      {isForUpdate ? (
                        <CustomButton type="submit" disabled={isUpdating}>
                          {isUpdating ? 'Updating...' : 'Update'}
                        </CustomButton>
                      ) : (
                        <CustomButton type="submit" disabled={isCreating}>
                          {isCreating ? 'Creating...' : 'Create'}
                        </CustomButton>
                      )}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CreatePharmacy
