import { takeLatest, put } from 'redux-saga/effects'
import axios from 'axios'
import {
  createDrugCategoryRequested,
  createDrugCategorySuccessful,
  createDrugCategoryFailed,
} from '../../../reducer/oneDrugStore/admin/drugCategorySlice'

function* createDrugCategoryAsync({ payload }) {
  try {
    yield createDrugCategoryRequested()

    const { data } = yield axios.post(
      `${process.env.REACT_APP_ONE_DRUG_BASE}/v1/api/DrugCategory/Create`,
      {
        payload,

        headers: {
          Authorization: `Bearer ${localStorage.getItem('adminInfo')}`,
        },
      }
    )

    yield put(createDrugCategorySuccessful(data))
  } catch (error) {
    if (error.response) {
      yield put(createDrugCategoryFailed(error.response.data))
      yield put(createDrugCategoryFailed(null))
      return
    }

    yield put(createDrugCategoryFailed(error.message))
    yield put(createDrugCategoryFailed(null))
  }
}

export function* watchCreateDrugCategory() {
  yield takeLatest(createDrugCategoryRequested.type, createDrugCategoryAsync)
}
