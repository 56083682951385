import React, { useState, useReducer } from 'react';
import { Modal } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io';
import axios from 'axios';
import { config } from 'dotenv';

import ResponseToast from '../template/ResponseToast';

const url = '/v1/api/Notification/CreateNotification'
const baseUrl = process.env.REACT_APP_BASE_URL;

const NewNotificationModal = ({ show, handleHide, filterOptions }) => {
  config(); //instanciate environmental variables

  const [serviceName, setServiceName] = useState('');
  const [modalSize, setModalSize] = useState('modal-90w');
  const [toastShow, setToastShow] = useState(false);
  const [toastError, setToastError] = useState('');
  const [send, setSend] = useState(false);

  const [notification, setNotification] = useReducer((state, newState) => ({
    ...state, ...newState
  }), {
    Subject: '',
    Message: '',
    ScheduleDate: '',
    ServiceId: '',
    Status: ''
  });


  //  close toast
  const handleToastClose = () => setToastShow(false);

  // get value of different input elements
  const handleNotificationChange = (e) => {
    const { name, value } = e.target;
    setNotification({ [name]: value });
  }

  // submit notification
  const send_DraftNotification = async (status, e) => {
    // prevent default action of form submit
    e.preventDefault();
    setSend(true);
    // setNotification(notification.Status = status);

    const token = localStorage.getItem("adminInfo");

    // get service name from status and set to state
    if (status === 1) {
      setServiceName('Pharmall');
    } else {
      setServiceName('Insurance');
    }

    // attach values to formdata
    const formdata = new FormData();
    formdata.append('Subject', notification.Subject)
    formdata.append('Message', notification.Message)
    formdata.append('ScheduleDate', notification.ScheduleDate)
    formdata.append('ServiceId', Number(notification.ServiceId))
    formdata.append('Status', status)

    try {

      await axios.post(`${baseUrl}${url}`, formdata, {
        headers: { Authorization: `Bearer ${token}` }
      });

      // show response toast on successful notification sending
      setSend(false);
      setToastShow(true);

      setNotification({
        Subject: '',
        Message: '',
        ScheduleDate: '',
        ServiceId: '',
        Status: ''
      });

    } catch (error) {
      // show response toast when error occur
      setSend(false);
      setToastShow(true);

      switch (error.response.status) {
        case 401:
          return setToastError("Session has expired");

        default:
          return setToastError(error.response.statusText);
      }
    }
  };

  return (
    <div >
      <Modal
        show={show}
        className='notification-modal'
        dialogClassName={modalSize}
      >
        <Modal.Header className='header d-flex justify-content-between align-items-center'>
          <h1 className='title'>New notification</h1>
          <i>
            {/* <AiOutlineMinus className='icon' onClick={() => setModalSize('minimize')} />
            < IoIosResize className='icon' onClick={() => setModalSize('maximize')} /> */}
            <IoMdClose className='icon' onClick={handleHide} />
          </i>
        </Modal.Header>

        <Modal.Body>
          <form className='p-0' id='send-notification'>
            <ResponseToast
              toastShow={toastShow}
              handleToastClose={handleToastClose}
              toastError={toastError}
            />
            <div className='d-flex flex-column mb-2'>
              <label className='label'>Post to</label>
              <select className='input p-2' name='ServiceId'
                onChange={handleNotificationChange}
                required
              >
                <option value=''>Select service</option>
                {filterOptions}
              </select>
              <span
                className={serviceName ? ' d-block menu p-2 my-2 text-center' : 'menu p-2 my-2 text-center'} >
                {serviceName && serviceName} <IoMdClose className='icon' />
              </span>
            </div>

            <div className='d-flex flex-column mb-2'>
              <label className='label' id='subject'>Subject</label>
              <input type='text'
                name='Subject'
                className='input p-2'
                id='subject'
                onChange={handleNotificationChange}
                required
              />
            </div>

            <div>
              <label htmlFor='message'>
                <textarea
                  className='text-area'
                  cols='100'
                  rows='8'
                  id='message'
                  name='Message'
                  onChange={handleNotificationChange}
                  required
                />
              </label>
            </div>
            {/* form footer */}
            <menu className='p-0 bg-light text-danger show-attachment'></menu>

            <div className='d-flex  align-items-center'>
              <div class="btn-group">
                <button
                  type="button"
                  class="btn btn-danger send-notification px-4 py-2"
                  form='send-notification'
                  onClick={(e) => send_DraftNotification(1, e)}
                >
                  Send
                </button>
                <button type="button"
                  class="btn btn-danger  send-notification dropdown-toggle dropdown-toggle-split"
                  id="dropdownMenuReference"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  data-reference="parent"
                >
                  <span class="sr-only">Toggle Dropdown</span>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuReference">
                  <a class="dropdown-item" href="#" form='send-notification'
                    onClick={(e) => send_DraftNotification(2, e)}>
                    Draft
                  </a>
                </div>
              </div>

              <label className='mx-2' htmlFor='date'>
                <input
                  id='date'
                  type='date'
                  name='ScheduleDate'
                  placeholder='choose a date'
                  className='date py-1 px-3'
                  onChange={handleNotificationChange}
                  required
                />
              </label>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default NewNotificationModal;
