import { call, put, takeEvery } from 'redux-saga/effects'
import {
  ADD_FRIEND_BY_EMAIL_FAILED,
  ADD_FRIEND_BY_EMAIL_REQUESTED,
  ADD_FRIEND_BY_EMAIL_SUCCESSFUL,
} from '../../../reducer/healthInsuredReducer/addFriendByEmailReducer'
import { addFriendByEmailAPI } from './friendsAPIs'

function* handleAddFriendByEmail({ payload }) {
  try {
    yield ADD_FRIEND_BY_EMAIL_REQUESTED()

    const { data } = yield call(addFriendByEmailAPI, payload.values)

    alert(data.message)

    yield put(ADD_FRIEND_BY_EMAIL_SUCCESSFUL(data))
  } catch (error) {
    if (error.response) {
      alert(error.response.data.message)
      yield put(ADD_FRIEND_BY_EMAIL_FAILED(error.response.data.message))
      return
    }

    yield put(ADD_FRIEND_BY_EMAIL_FAILED(error.message))
    alert(error.message)
  }
}

export function* watchAddFriendByEmail() {
  yield takeEvery(ADD_FRIEND_BY_EMAIL_REQUESTED.type, handleAddFriendByEmail)
}
