import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { formatDistanceToNow } from 'date-fns'
import { Row, Col, Card } from 'react-bootstrap'
import { IoMdTime } from 'react-icons/io'
import LoadingIndicator from '../../../../../../healthBancComponents/LoadingIndicator/LoadingIndicator'
import './activityLog.scss'

const ActivityLogTable = () => {
  const dispatch = useDispatch()
  const activityLog = useSelector((state) => state.getCorporateActivityLog)

  useEffect(() => {
    dispatch({ type: 'GET_CORPORATE_ACTIVITY_LOG' })
  }, [dispatch])

  const activitiesList =
    activityLog && activityLog.data
      ? activityLog.data.data.map((activity, index) => (
          <Row key={index} className='activity__log--activity'>
            <Col className='activity__log--item'>
              <div className='activity__title'>{activity.actionApplied}</div>
            </Col>
            <Col className='text-right activity__log--time'>
              <div className='ml-auto'>
                <IoMdTime />{' '}
                {formatDistanceToNow(new Date(activity.date), {
                  includeSeconds: true,
                  addSuffix: true,
                })}
              </div>
            </Col>
          </Row>
        ))
      : []

  return (
    <Card className='activity__log'>
      {activityLog.isLoading ? (
        <div className='mx-auto'>
          <LoadingIndicator />
        </div>
      ) : activitiesList && activitiesList.length === 0 ? (
        <p className='text-center'>No activities recorded</p>
      ) : (
        activitiesList
      )}
    </Card>
  )
}

export default ActivityLogTable
