import React, { useState, useEffect } from 'react'
import { Formik, Form, Field } from 'formik'
import axios from 'axios'
import * as Yup from 'yup'
import Navbar from '../../healthBancComponents/LandingPage/Navbar/Navbar'
import CustomInput from '../../healthBancComponents/Templates/CustomInput'
import CustomSelect from '../../healthBancComponents/Templates/CustomSelect'
import CustomButton from '../../healthBancComponents/Templates/CustomButton'
import Footer from '../../healthBancComponents/Templates/Footer'

import img1 from '../../assets/DigitalHospitalImages/digital1.png'
import img2 from '../../assets/DigitalHospitalImages/digital2.png'
import img3 from '../../assets/DigitalHospitalImages/digital3.png'
import img4 from '../../assets/DigitalHospitalImages/digital4.png'

import users from '../../healthBancServices/DigitalHospital/Assets/users.svg'
import coin from '../../healthBancServices/DigitalHospital/Assets/coin.svg'
import creditCard from '../../healthBancServices/DigitalHospital/Assets/credit-card.svg'
import digitalHospital from '../../healthBancServices/DigitalHospital/Assets/digitalHospital.svg'
import Group from '../../healthBancServices/DigitalHospital/Assets/Group.svg'
import patient from '../../healthBancServices/DigitalHospital/Assets/patient.svg'

import './DigitalHospital.scss'

const DigitalHospital = () => {
  const [otherService, setOtherService] = useState(false)

  //Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const formSchema = Yup.object({
    healthServiceProviderName: Yup.string().required('Required'),
    healthServiveProviderType: Yup.string().required('Required'),
    otherServiceType: Yup.string(),
    emailAddress: Yup.string()
      .email('Email address must be a valid email')
      .required('Required'),
    phoneNumber: Yup.string()
      // .length(11)
      .min(11, 'Phone number should be at least 11 digits. ')
      .max(13, 'Phone Number should not be more than 13 digits.')
      .required('Required'),
  })

  const handleSubmitForm = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true)
    try {
      const response = await axios({
        method: 'post',
        url: '/v2/api/LeadGenerator/SendHeliumNotification',
        data: { ...values },
      })

      if (response.status === 200) {
        alert("Thank you! \nWe'll reach out to you soon")
        resetForm()
      }
    } catch (error) {
      if (error.response) {
        alert('There was an error. \nPlease try again or contact support.')
      }
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <>
      <Navbar />
      <div className="digital__health--container container">
        <div className="digital__health--heading">
          <h1 className="digital__health--heading--main">
            Innovative solutions for Health Systems and Hospitals.
          </h1>
          <p className="digital__health--heading--description">
            Partnering with Helium Health, one of the leading health-tech
            companies in Africa, we created <em>Digital Hospital</em>, a
            solution package that helps you run your healthcare practice in a
            simpler and more efficient way, so you can focus on what matters
            most — your patients.
          </p>
        </div>

        <div className="digital__health--images d-flex">
          <div>
            <img src={img1} alt="digialHospitalImg" />
          </div>
          <div>
            <div>
              <img src={img3} alt="digialHospitalImg" />
            </div>
            <div>
              <img src={img4} alt="digialHospitalImg" />
            </div>
          </div>
          <div>
            <img src={img2} alt="digialHospitalImg" />
          </div>
        </div>

        <div className="digital__health--goal">
          <h2 className="digital__health--goal--title">
            Improving Healthcare outcomes
          </h2>
          <p className="digital__health--goal-text">
            We are accelerating Africa’s transition to a technology and
            data-driven healthcare sector.
          </p>
          <p className="digital__health--goal-text">
            We provide a suite of solutions that serves as the digital
            infrastructure for all healthcare stakeholders in Africa;{' '}
            <strong>providers, payers, patients</strong> &{' '}
            <strong>governments.</strong>
          </p>
        </div>

        <div className="digital__health__service">
          <div className="digital__health__service--card">
            <div className="digital__health__service--card--bullet">
              <img src={digitalHospital} alt="Ems-logo" />
            </div>
            <div className="digital__health__service--card--desc">
              <h3 className="digital__health__service--card--desc--main">
                EMR/HMS
              </h3>
              <p className="digital__health__service--card--desc--sub">
                Our quick-to-deploy and easy-to-use revolutionary EHR system
                will totally transform the way you collect, analyse and use
                customers' health data.
              </p>
            </div>
          </div>
          <div className="digital__health__service--card">
            <div className="digital__health__service--card--bullet">
              {/* <img src={users} alt='Ems-logo' /> */}
              <img src={Group} alt="Ems-logo" />
            </div>
            <div className="digital__health__service--card--desc">
              <h3 className="digital__health__service--card--desc--main">
                Telemedicine
              </h3>
              <p className="digital__health__service--card--desc--sub">
                This super-efficient solution will help you provide virtual care
                to your patients from anywhere around the world, anytime. More
                patients. More revenue.
              </p>
            </div>
          </div>
          <div className="digital__health__service--card">
            <div className="digital__health__service--card--bullet">
              <img src={creditCard} alt="Ems-logo" />
            </div>
            <div className="digital__health__service--card--desc">
              <h3 className="digital__health__service--card--desc--main">
                Payment & Claims Processing
              </h3>
              <p className="digital__health__service--card--desc--sub">
                An easy, quick & complete revenue cycle management that ensures
                Seamless Reconciliation, Accounting & Audit and improved
                liquidity.
              </p>
            </div>
          </div>
          <div className="digital__health__service--card">
            <div className="digital__health__service--card--bullet">
              <img src={users} alt="Ems-logo" />
            </div>
            <div className="digital__health__service--card--desc">
              <h3 className="digital__health__service--card--desc--main">
                Patient Portal
              </h3>
              <p className="digital__health__service--card--desc--sub">
                Having an easy Appointment Scheduling & Helpful Reminders system
                for your customers/patients will definitely make you a choice
                health institution.
              </p>
            </div>
          </div>
          <div className="digital__health__service--card">
            <div className="digital__health__service--card--bullet">
              <img src={patient} alt="Ems-logo" />
            </div>
            <div className="digital__health__service--card--desc">
              <h3 className="digital__health__service--card--desc--main">
                Lead Generation/Appointment Booking
              </h3>
              <p className="digital__health__service--card--desc--sub">
                Building your online reputation has become a necessity in the
                21st century. It will help your health business attract & engage
                new customer/patents easily.
              </p>
            </div>
          </div>
          <div className="digital__health__service--card">
            <div className="digital__health__service--card--bullet">
              <img src={coin} alt="Ems-logo" />
            </div>
            <div className="digital__health__service--card--desc">
              <h3 className="digital__health__service--card--desc--main">
                Instant Loan Access
              </h3>
              <p className="digital__health__service--card--desc--sub">
                Getting on Digital Health will give you access to instant,
                hassle-free financing for your facility and patients. What more
                could you ask for?
              </p>
            </div>
          </div>
        </div>

        <div className="digital__health--info-form--container">
          <div className="digital__health--form">
            <div className="digital__health--form--header">
              <h2 className="digital__health--form--header--main">
                Get Started
              </h2>
              <p className="digital__health--form--header--sub">
                Give us a little information so that our team can get across to
                you soonest.
              </p>
            </div>

            <Formik
              initialValues={{
                healthServiceProviderName: '',
                healthServiveProviderType: '',
                otherServiceType: '',
                emailAddress: '',
                phoneNumber: '',
              }}
              validate={(values) => {
                if (values.healthServiveProviderType === 'Others') {
                  setOtherService(true)
                } else {
                  setOtherService(false)
                }
              }}
              validationSchema={formSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                handleSubmitForm(values, {
                  setSubmitting,
                  resetForm,
                })
              }}
            >
              {(formik) => (
                <Form style={{ background: 'inherit' }}>
                  <Field
                    as={CustomInput}
                    label="Health Care service Provider Name"
                    type="text"
                    name="healthServiceProviderName"
                    placeholder="Abc Diagnostics"
                  />

                  <Field
                    as={CustomSelect}
                    label="Type of Service Provided"
                    name="healthServiveProviderType"
                    // onChange={handleProvider}
                  >
                    <option value="" disabled defaultValue="" hidden>
                      Select a service type
                    </option>
                    <option value="Clinic">Clinic</option>
                    <option value="Diagnostic Center">Diagnostic Center</option>
                    <option value="Hospital">Hospital</option>
                    <option value="Laboratories">Laboratory</option>
                    <option value="Others">Others</option>
                  </Field>

                  {otherService && (
                    <Field
                      as={CustomInput}
                      label="Other Service Type"
                      type="text"
                      name="otherServiceType"
                      placeholder="Input the type of servcie"
                    />
                  )}
                  <Field
                    as={CustomInput}
                    label="Email"
                    type="email"
                    name="emailAddress"
                    placeholder="example@abc.diagnostics.com"
                  />

                  {/* <Field
										as={CustomInput}
										label='Phone Number'
										type='number'
										max='13'
										min='11'
										name='phoneNumber'
										placeholder='e.g. 08087654321'
									/> */}

                  <Field
                    as={CustomInput}
                    label="Phone Number"
                    type="tel"
                    name="phoneNumber"
                    minLength="11"
                    maxLength="13"
                    placeholder="e.g. 08087654321"
                  />

                  <div>
                    <CustomButton
                      type="submit"
                      disabled={formik.isSubmitting ? true : false}
                    >
                      {formik.isSubmitting ? 'Submitting...' : 'Submit'}
                    </CustomButton>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default DigitalHospital
