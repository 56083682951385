import { takeLatest, put } from 'redux-saga/effects'
import axios from 'axios'
import { toast } from 'react-toastify'
import {
  deleteAggregatorRequested,
  deleteAggregatorSuccessful,
  deleteAggregatorFailed,
  getAggregatorsRequested,
} from '../../../reducer/oneDrugStore/admin/aggregatorSlices'

function* deleteAggregatorAsync({ payload }) {
  try {
    yield deleteAggregatorRequested()

    const { data } = yield axios({
      method: 'DELETE',
      url: `${process.env.REACT_APP_ONE_DRUG_BASE}/v1/api/Aggregator/Delete?aggregatorId=${payload.aggregatorId}`,
      headers: { Authorization: `Bearer ${localStorage.getItem('adminInfo')}` },
    })

    yield toast.success(data.responseDescription)

    yield put(deleteAggregatorSuccessful(data))
    yield put(getAggregatorsRequested())
  } catch (error) {
    if (error.response) {
      yield toast.error(error.response.data.responseDescription)
      yield put(deleteAggregatorFailed(error.response.data.responseDescription))
      yield put(deleteAggregatorFailed(null))
      return
    }

    yield put(deleteAggregatorFailed(error.message))
    yield put(deleteAggregatorFailed(null))
  }
}

export function* watchDeleteAggregator() {
  yield takeLatest(deleteAggregatorRequested.type, deleteAggregatorAsync)
}
