import React, { useState, useEffect } from 'react'
import { Card } from 'react-bootstrap'
import axios from 'axios'
import { FcBusinessman } from 'react-icons/fc'
import Addadmin from './Addadmin'
import ManageAdmin from './ManageAdmin'
import NewButton from './../template/NewButton'
import Loading from '../../../healthBancServices/pharmHub/admin/components/Spinner/Spinner'
import ResponseModal from '../../../healthBancServices/pharmHub/admin/components/responseModal/ResponseModal'

const url = '/v2/api/BackendAdminAuth/GetBackendAdminUsers'
const roleUrl = '/v2/api/BackendAdminAuth/GetAdminRoles'
const createAdminurl = '/v2/api/BackendAdminAuth/CreateBackendAdmin'

const MainSection = () => {
  const [adminData, setAdminData] = useState([])
  const [roles, setRoles] = useState('')
  const [admin, setAdmin] = useState({})
  const [show, setShow] = useState(false)
  const [showManage, setShowManage] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [token] = useState(localStorage.getItem('adminInfo'))
  const [showResponse, setShowResponse] = useState(false)
  const [toastShow, setToastShow] = useState(false)
  const [toastError, setToastError] = useState('')
  const [send, setSend] = useState(false)
  const [refetchAdmin, setRefetchAdmin] = useState(false)

  useEffect(() => {
    setLoading(true)

    function getAdmins() {
      return axios.get(`${url}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
    }

    function adminRoles() {
      return axios.get(`${roleUrl}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
    }
    // make multiple request to get admins and their roles
    axios
      .all([getAdmins(), adminRoles()])
      .then(
        axios.spread((admins, roles) => {
          const adminn = admins.data.data
          const adminRoles = roles.data.data

          setAdminData(adminn)
          setRoles(adminRoles)
          setLoading(false)
        })
      )
      .catch((error) => {
        setLoading(false)
        error.response
          ? alert(error.response.data.message)
          : alert(error.message)
      })
  }, [])

  //update the user interface if admin was either created, deleted or role changed
  useEffect(() => {
    ;(async () => {
      try {
        const response = await axios.get(`${url}`, {
          headers: { Authorization: `Bearer ${token}` },
        })

        setAdminData(response.data.data)
        setRefetchAdmin(false)
      } catch (error) {
        setRefetchAdmin(false)
        error.response
          ? alert(error.response.data.message)
          : alert(error.message)
      }
    })()
  }, [refetchAdmin])

  const createAdmin = async (values, handleReset) => {
    setToastError('')
    setSend(true)

    try {
      await axios.post(
        `${createAdminurl}`,
        {
          firstName: values.firstName,
          lastName: values.lastName,
          userName: values.userName,
          email: values.email,
          roleId: Number(values.roleId),
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )

      setRefetchAdmin(true)
      handleReset()
      setSend(false)
      setToastShow(true)
    } catch (error) {
      setSend(false)
      setToastShow(true)

      error.response
        ? setToastError(error.response.data.message)
        : setToastError(error.message)
    }
  }

  // close toast
  const handleToastClose = () => setToastShow(false)

  // show manage admin modal
  const handleShowManage = (admin) => {
    setAdmin(admin)
    setShowManage(true)
  }

  // close manage admin modal
  const handleCloseManage = () => setShowManage(false)

  // map admin data  to a card component
  const AdminCard =
    adminData &&
    adminData.map((admin, index) => (
      <div className="col-3" key={index}>
        <Card className="admin-info text-center">
          <Card.Body>
            <div className="dp-wrapper">
              <FcBusinessman className="dp" />
            </div>
            <p className="admin-card-name">
              {admin.firstName} {admin.lastName}{' '}
            </p>
            <p className="admin-email">{admin.email}</p>
            <p className="admin-role">{admin.classOrRole.name}</p>
            <p className="manage-admin" onClick={() => handleShowManage(admin)}>
              Manage
            </p>
          </Card.Body>
        </Card>
      </div>
    ))

  // toggle add admin modal
  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)

  // hide alert modal
  const handleHide = () => setShowResponse(false)

  //show loading spinner on page load or network request
  if (loading) return <Loading />

  return (
    <div className="hb-main-section  p-4 administrator">
      <ResponseModal
        show={showResponse}
        handleHide={handleHide}
        error={error}
      />
      <divc className="container">
        <div className="row">{AdminCard}</div>
      </divc>

      <Addadmin
        show={show}
        handleClose={handleClose}
        roles={roles}
        toastShow={toastShow}
        toastError={toastError}
        send={send}
        handleToastClose={handleToastClose}
        createAdmin={createAdmin}
      />
      <ManageAdmin
        showManage={showManage}
        handleCloseManage={handleCloseManage}
        admin={admin}
        token={token}
        roles={roles}
        setRefetchAdmin={setRefetchAdmin}
      />
      <NewButton openCreateModal={handleShow} />
    </div>
  )
}

export default MainSection
