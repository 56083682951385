import { useEffect, useState } from 'react';
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const { REACT_APP_BASE_URL } = process.env

const useGetOtp = (method,  url, nextUrl, phoneNumber) => {
    const history = useHistory()
    const { token } = useSelector(state  => state.user.userInfo)
    const [isLoading, setIsLoading] = useState(false)

    const handleClick = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        try {
            const response = await axios({
                method: method,
                url: `${REACT_APP_BASE_URL}/${url}`,
                headers: {Authorization: `Bearer ${token}`},
                data: {
                    MobileNumber: phoneNumber
                }
            })
            history.push(nextUrl)                    
            
        }
    
        catch (error){
            if (error.response) {
                toast(error.response?.data?.message)
                const { status } = error.response;
                if (status === 404) {
                    toast(error.response?.statusText)
                    return
                }
            }
        }
        finally {
            setIsLoading(false)
    
        }
        
    }
    // useEffect(() => {
    //     handleClick()
    // },[])

    return {isLoading, handleClick}

}

export default useGetOtp;