import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Row, Col } from 'react-bootstrap'
import { FiUser, FiBriefcase } from 'react-icons/fi'
import { IoIosCheckmarkCircle } from 'react-icons/io'
import BackgroundTemplate from '../../../../../healthBancComponents/Templates/BackgroundTemplate'
import Header from '../../../healthInsuredIndividualFlow/signup/template/header/Header'
import LoadingIndicator from '../../../../../healthBancComponents/LoadingIndicator/LoadingIndicator'
import CustomButton from '../../../../../healthBancComponents/Templates/CustomButton'
import { SET_HEALTH_PLAN } from '../../../../../redux/healthBanc/actions/healthInsuredTypes/healthInsuredUserChoosenPlanType'
import { createFamilyProfileRequested } from '../../../../../redux/healthBanc/reducer/healthInsuredReducer/healthInsuredFamily/createFamilyProfileReducer'

import './insuranceTypeSelect.scss'

const InsuranceTypeSelectScreen = ({ history }) => {
  const [selectedType, setSelectedType] = useState('')

  const dispatch = useDispatch()
  const profileCompletionStatus = useSelector(
    (state) => state.profileCompletionStatus
  )

  const createFamilyProfile = useSelector((state) => state.createFamilyProfile)

  useEffect(() => {
    dispatch({ type: 'PROFILE_COMPLETION_STATUS' })
  }, [dispatch])

  useEffect(() => {
    const userPlan = profileCompletionStatus.data?.healthInsuredPlan

    //0 === Redirect to individual flow
    if (userPlan === 0) {
      setSelectedType('individual')

      const { profileCompleted, referee, tokenizationCompleted } =
        profileCompletionStatus.data ?? {}
      if (profileCompleted && tokenizationCompleted) {
        return history.push('/health_profile')
      }

      if (referee && !profileCompleted) {
        return history.push({
          pathname: '/health_choose_provider',
          state: {
            user: selectedType,
          },
        })
      }

      return history.push('/health_signup')
    }

    //1 === Redirect to family flow
    if (profileCompletionStatus.data?.healthInsuredPlan === 1) {
      return history.push('/health-family')
    }

    //2 === Redirect to corporate flow
    if (profileCompletionStatus.data?.healthInsuredPlan === 2) {
      return history.push('/health_corporate/business_registration')
    }

    return history.push('/health_corporate/select_type')
  }, [history, profileCompletionStatus, dispatch, selectedType])

  useEffect(() => {
    dispatch({ type: SET_HEALTH_PLAN, payload: selectedType })
  }, [dispatch, selectedType])

  const handleTypeSelect = (type) => {
    setSelectedType(type)
  }

  const handleUserFowarding = () => {
    if (selectedType === 'family' || selectedType === 'friend') {
      dispatch(createFamilyProfileRequested(selectedType))
      return
    }

    history.push({
      pathname: '/health_choose_provider',
      state: {
        user: selectedType,
      },
    })
  }

  return (
    <>
      <Header />
      <BackgroundTemplate>
        {profileCompletionStatus.isLoading ? (
          <Row>
            <div className="d-flex h-100 w-100 justify-content-center align-items-center mx-auto">
              <LoadingIndicator />
            </div>
          </Row>
        ) : (
          <Row className="m-0 mt-5 insurance__type mx-auto w-100 justify-content-center">
            <Col>
              <div className="insurance__type--title">
                How would you like to enjoy HealthInsured
              </div>
              <div className="insurance__type--cards mx-auto">
                <Row className=" max-auto d-md-flex d-block justify-content-around">
                  <Col>
                    <Card
                      className="mx-auto insurance__type--card"
                      onClick={() => handleTypeSelect('individual')}
                    >
                      <Col className="d-flex p-0 justify-content-between">
                        <span className="insurance__type--card--icon">
                          <FiUser />
                        </span>
                        {selectedType === 'individual' && (
                          <span className="icon--selected">
                            <IoIosCheckmarkCircle />
                          </span>
                        )}
                      </Col>

                      <div className="card__title">As an Individual</div>
                      <div className="card__text">
                        Your health insurance will be provided by your preferred
                        provider.
                      </div>
                    </Card>
                  </Col>

                  <Col>
                    <Card
                      className="mx-auto insurance__type--card"
                      onClick={() => handleTypeSelect('business')}
                    >
                      <Col className="d-flex p-0 justify-content-between">
                        <span className="insurance__type--card--icon">
                          <FiBriefcase />
                        </span>
                        {selectedType === 'business' && (
                          <span className="icon--selected">
                            <IoIosCheckmarkCircle />
                          </span>
                        )}
                      </Col>
                      <div className="card__title">As a Business</div>
                      <div className="card__text">
                        Your health insurance will be provided by your preferred
                        provider.
                      </div>
                    </Card>
                  </Col>

                  <Col>
                    <Card
                      className="mx-auto insurance__type--card"
                      onClick={() => handleTypeSelect('family')}
                    >
                      <Col className="d-flex p-0 justify-content-between">
                        <span className="insurance__type--card--icon">
                          <FiBriefcase />
                        </span>
                        {selectedType === 'family' && (
                          <span className="icon--selected">
                            <IoIosCheckmarkCircle />
                          </span>
                        )}
                      </Col>
                      <div className="card__title">As a Family</div>
                      <div className="card__text">
                        {/* Your health insurance will be provided by your preferred
                        provider. */}
                        Your health insurance will be provided by Axa Mansard
                        HMO.
                      </div>
                    </Card>
                  </Col>

                  <Col>
                    <Card
                      className="mx-auto insurance__type--card"
                      onClick={() => handleTypeSelect('friend')}
                    >
                      <Col className="d-flex p-0 justify-content-between">
                        <span className="insurance__type--card--icon">
                          <FiBriefcase />
                        </span>
                        {selectedType === 'friend' && (
                          <span className="icon--selected">
                            <IoIosCheckmarkCircle />
                          </span>
                        )}
                      </Col>
                      <div className="card__title">Pay for Others</div>
                      <div className="card__text">
                        {/* Your health insurance will be provided by your preferred
                        provider. */}
                        Your health insurance will be provided by Axa Mansard
                        HMO.
                      </div>
                    </Card>
                  </Col>
                </Row>

                <div className="col-6 col-sm-4 mx-auto mt-3">
                  <CustomButton
                    disabled={
                      selectedType === '' || createFamilyProfile.isLoading
                    }
                    onClick={handleUserFowarding}
                  >
                    Proceed
                  </CustomButton>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </BackgroundTemplate>
    </>
  )
}

export default InsuranceTypeSelectScreen
