import { put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import {
  movePendingBeneficiariesToInactiveListRequest,
  movePendingBeneficiariesToInactiveListSuccess,
  movePendingBeneficiariesToInactiveListFailed,
} from '../../actions/healthInsuredCreators/corporateUserActions/movePendingBeneficiariesToInactiveListActions'

function* moveToInactiveList({ payload }) {
  yield put(movePendingBeneficiariesToInactiveListRequest())

  try {
    const { data } = yield axios.post(
      '/v2/api/CorporateInsurance/MoveCompanyBeneficiaryFromPendingToInactiveState',
      { emails: [...payload] }
    )

    alert(data.message)

    yield put(movePendingBeneficiariesToInactiveListSuccess(data))
  } catch (error) {
    yield put(movePendingBeneficiariesToInactiveListFailed(error.response))
  }
}

export function* watchMovePendingBeneficiariesToInactiveList() {
  yield takeEvery(
    'MOVE_PENDING_BENEFICIARIES_TO_INACTIVE_LIST',
    moveToInactiveList
  )
}
