import { takeLatest, put } from 'redux-saga/effects'
import axios from 'axios'
import {
  LOGOUT_REQUESTED,
  LOGOUT_SUCCESSFUL,
  LOGOUT_FAILED,
} from '../reducer/logoutReducer'
import { store } from '../../store'

function* handleSignout() {
  try {
    yield LOGOUT_REQUESTED()

    const { data } = yield axios.get('/v2/api/Identity/LogOut')

    yield put(LOGOUT_SUCCESSFUL(data))
  } catch (error) {
    yield put(LOGOUT_FAILED(error.response.data))
  } finally {
    yield store.dispatch({ type: 'LOGOUT' })

    function redirect() {
      window.localStorage.clear()

      if (window.location.pathname.includes('admin')) {
        window.location.replace('/admin_signin')
      } else {
        window.location.replace('/signin')
      }
    }

    yield redirect()
  }
}

export function* watchLogout() {
  yield takeLatest('SIGN_OUT', handleSignout)
}
