import {
  GET_HEALTHINSURED_ADMIN_DASHBOARD_ANALYTICS_FAILED,
  GET_HEALTHINSURED_ADMIN_DASHBOARD_ANALYTICS_REQUEST,
  GET_HEALTHINSURED_ADMIN_DASHBOARD_ANALYTICS_SUCCESS,
} from '../../actions/healthInsuredTypes/healthInsuredAdminActionTypes'

export const getHealthInsuredAdminDashboardAnalyticsReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case GET_HEALTHINSURED_ADMIN_DASHBOARD_ANALYTICS_REQUEST:
      return { isLoading: true }

    case GET_HEALTHINSURED_ADMIN_DASHBOARD_ANALYTICS_SUCCESS:
      return { isLoading: false, ...action.payload }

    case GET_HEALTHINSURED_ADMIN_DASHBOARD_ANALYTICS_FAILED:
      return { isLoading: false, errorMessage: action.payload }

    default:
      return state
  }
}
