import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Hero from './Hero/Hero'
import ServicesIntro from './ServicesIntro/ServicesIntro'
import Partners from './Partners/Partners'
import JoinCommunity from './JoinCommunity/JoinCommunity'
import Footer from '../Templates/Footer'

const LandingPage = styled.div`
  /* position: relative; */
  min-height: 100vh;
  background-color: #fff;

  header {
    margin-top: 118px;
    margin-bottom: 72px;
    text-align: center;

    .main {
      display: block;
      font-size: 32px;
      font-weight: bold;
      margin-bottom: 16px;
      color: #0c162c;
    }

    .sub {
      display: block;
      font-size: 18px;
      color: #494d55;
    }
  }
`

const HBLandingPage = ({ user }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  })

  if (!user.isAuth) {
    return (
      <LandingPage className="">
        <Hero />
        <div className="our__solutions container">
          <header>
            <span className="main">Our Solutions</span>
            <span className="sub">
              Explore our sophisticated but easy-to-use products.{' '}
            </span>
          </header>

          <div className="services">
            <ServicesIntro />
          </div>
        </div>
        <Partners />
        <JoinCommunity />
        <Footer />
      </LandingPage>
    )
  } else {
    // return <Redirect to='/dashboard' />
    return <Redirect to="/" />
  }
}

const mapStateToProps = (state) => ({
  user: state.user.userInfo,
})

export default connect(mapStateToProps)(HBLandingPage)
