import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as Notification } from '../../../../../../assets/healthInsuredAdmin/notification.svg';
import { ReactComponent as Clock } from '../../../../../../assets/healthInsuredAdmin/clock.svg';

const Card = ({
  buttonText,
  title,
  description,
  dateText
}) => {
  return (
    <article className='healthInsured__admin__notification__card'>
      <div className='healthInsured__admin__notification__card--container'>
        <i className='healthInsured__admin__notification__card__icon--notification'><Notification /></i>
        <h4 className='healthInsured__admin__notification__card--title'>{title}</h4>
        <p className='healthInsured__admin__notification__card--description'>{description}</p>
        <div className='healthInsured__admin__notification__card--time'>
          <i>
            <Clock className='healthInsured__admin__notification__card--time--icon--clock' />
            <span className='healthInsured__admin__notification__card--time--span'>{dateText}</span>
          </i>
          <Link className='healthInsured__admin__notification__card--btn' >{buttonText}</Link>
        </div>
      </div>
    </article>
  )
}

export default Card
