import React, { useEffect, useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import axios from 'axios'

// import BackgroundTemplate from '../Templates/BackgroundTemplate'
import SubDashboardHeader from './SubDashboardHeader'
import ServiceItemTemplate from './ServiceItemTemplate'
import Footer from '../Templates/Footer'

// import pharmHubLogo from '../../assets/ServicesLogo/favicon.png'

import crowdfunding from '../../healthBancServices/DigitalHospital/Assets/multiple-users-silhouette1.svg'
import pillsImg from '../../healthBancServices/oneDrugStore/assets/vaadin_pills.svg'
import funding from '../../healthBancServices/DigitalHospital/Assets/coin.svg'
import medicalRecords from '../../healthBancServices/DigitalHospital/Assets/medical-records1.png'
import medicalKit from '../../healthBancServices/DigitalHospital/Assets/medical-kit1.svg'
import medicalInsurance from '../../healthBancServices/DigitalHospital/Assets/medical-insurance.svg'
import Alert from '../../healthBancServices/healthInsured/template/alert/ALert'
import { getHealthInsuredPlanAndState } from '../../redux/healthBanc/actions/healthInsuredCreators/healthInsuredPlanAndState'
// import { getProfileCompletionStatus } from '../../redux/healthBanc/actions/healthInsuredCreators/profileCompletionStatus'

// const customerStateUrl = '/v2/api/Insurance/GetProfileCompletion'
const getHealthPlanUrl = '/v1/api/Insurance/AxaMansardGetHealthPlans'
const statesUrl = '/v1/api/Insurance/GetState'

const SubDashboard = ({ user }) => {
  const [error, setError] = useState('')

  const dispatch = useDispatch()
  const history = useHistory()

  //Dispatch a request to check the profile completion status of the signed in user
  useEffect(() => {
    dispatch({ type: 'PROFILE_COMPLETION_STATUS' })
  }, [dispatch])

  useEffect(() => {
    /**make concurrent axios call to get healthplans and list of states */
    function getHealthPlan() {
      return axios.get(getHealthPlanUrl)
    }

    function getStates() {
      return axios.get(statesUrl)
    }

    axios
      .all([getHealthPlan(), getStates()])
      .then(
        axios.spread((healthplanResponse, statesResponse) => {
          const healthplans = healthplanResponse.data.data
          const states = statesResponse.data

          const state = { healthplans, states }
          // dispatch all state to redux store
          dispatch(getHealthInsuredPlanAndState(state))
        })
      )
      .catch((error) => {
        error.response
          ? setError(error.response.data.message)
          : setError(error.message)
      })
  }, [])

  return (
    <>
      <SubDashboardHeader />
      {/* <BackgroundTemplate> */}
      {/* <Navbar /> */}

      <div className=" my-5 text-center mx-auto">
        {error && (
          <Alert variant="danger" showAlert={true}>
            {' '}
            {error}{' '}
          </Alert>
        )}
        <h3>
          Welcome,{' '}
          <strong>
            {(user.name.charAt(0).toUpperCase() + user.name.slice(1)).split(
              ' '
            )[0] || 'User'}
          </strong>
          {/* <strong>{user.name[0].toUpperCase().splice(1)}</strong> */}
        </h3>

        <div
          className="d-flex flex-wrap justify-content-center justify-content-lg-start col-md-10  mx-auto mt-5"
          // style={{ maxWidth: 1050 }}
        >
          <ServiceItemTemplate
            name="HealthInsured"
            logo={medicalInsurance}
            description="We aim to reduce the out of pocket payments made by patients and access to affordable healthcare."
            isAvailable={true}
            redirect={() => history.push('/health_corporate/select_type')}
          />

          {/* <ServiceItemTemplate
							logo={pharmHubLogo}
							name='Healthmall'
							description='This is an e-commerce platform that creates a marketplace for the pharmaceutical value and supply chain.'
							isAvailable={true}
							redirect={() => history.push('/pharmHub')}
						/> */}

          <ServiceItemTemplate
            name="OneDrugStore"
            logo={pillsImg}
            description="Get access to a wide range of quality and affordable medications. We make payment easy and deliver straight to your doorstep."
            isAvailable={true}
            redirect={() => history.push('/OneDrugStore')}
          />

          <ServiceItemTemplate
            name="Digital Hospitals"
            logo={medicalRecords}
            description="Health Management Information System (HMIS) powered by Helium Health."
            isAvailable={true}
            redirect={() => history.push('/digital-hospital')}
          />

          <ServiceItemTemplate
            name="Health Finance"
            logo={funding}
            description="Our partnership with organizations & healthcare institutions to enhance the quality and access to healthcare delivery in Nigeria."
            isAvailable={true}
            redirect={() => history.push('/health-finance')}
          />

          <ServiceItemTemplate
            name="Telemedicine"
            logo={medicalKit}
            description="No need for you to visit hospital and spend hours in the waiting rooms before you have access to a medical doctor."
            isAvailable={false}
          />

          <ServiceItemTemplate
            name="Crowd Funding"
            logo={crowdfunding}
            description="This is dedicated to building partnerships to create lasting social impact and making the world a better place."
            isAvailable={false}
          />
        </div>
      </div>
      {/* </BackgroundTemplate> */}

      <Footer />
    </>
  )
}

const mapStateToProps = (state) => ({
  user: state.user.userInfo,
})

export default connect(mapStateToProps)(withRouter(SubDashboard))
