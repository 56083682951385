import React from 'react'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'

import './_paginationTemplate.scss'

const PaginationTemplate = ({ prev, next, pageDetails, ...props }) => {
  return (
    <div className={`pagination ${props.className}`}>
      <button
        className="pagination--back-btn"
        onClick={prev}
        disabled={(pageDetails?.pageNumber <= 1 && true) || true}
      >
        <IoIosArrowBack /> Prev
      </button>
      <span className="pagination--page-number">
        {' '}
        Page {pageDetails?.pageNumber || 1} of{' '}
        {pageDetails?.pageCount === 0 ? 1 : pageDetails?.pageCount}
      </span>
      <button
        className="pagination--next-btn"
        onClick={next}
        disabled={
          (pageDetails?.pageNumber >= pageDetails?.pageCount ||
            !pageDetails?.pageNumber) &&
          true
        }
      >
        Next <IoIosArrowForward />{' '}
      </button>
    </div>
  )
}

export default PaginationTemplate
