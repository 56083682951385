import { takeLatest, put } from 'redux-saga/effects'
import axios from 'axios'
import {
  deleteDrugItemRequested,
  deleteDrugItemSuccessful,
  deleteDrugItemFailed,
} from '../../../reducer/oneDrugStore/admin/drugItemSlices'

function* deleteDrugItemAsync({ payload }) {
  try {
    yield deleteDrugItemRequested()

    const { data } = yield axios.delete(
      `${process.env.REACT_APP_ONE_DRUG_BASE}/v1/api/DrugItem/Delete?drugItemId=${payload.drugItemId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('adminInfo')}`,
        },
      }
    )

    yield put(deleteDrugItemSuccessful(data))
  } catch (error) {
    if (error.response) {
      yield put(deleteDrugItemFailed(error.response.data))
      yield put(deleteDrugItemFailed(null))
      return
    }

    yield put(deleteDrugItemFailed(error.message))
    yield put(deleteDrugItemFailed(null))
  }
}

export function* watchDeleteDrugItem() {
  yield takeLatest(deleteDrugItemRequested.type, deleteDrugItemAsync)
}
