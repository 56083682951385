import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Card, Row, Col, Form } from "react-bootstrap"
import { AiOutlinePaperClip } from "react-icons/ai"
import CorporateDashboardStructure from "../../corporateDashboardStructure/CorporateDashboardStructure"
import CustomButton from "../../../../../healthBancComponents/Templates/CustomButton"
import "./addBeneficiaries.scss"

const AddBeneficiaries = () => {
  const [beneficiariesList, setBeneficiariesList] = useState({})

  const dispatch = useDispatch()

  const uploadCorporateBeneficiaries = useSelector(
    (state) => state.uploadCorporateBeneficiaries
  )

  // const serviceProvider = useSelector(
  //   (state) => state.corporateProfileCompletion
  // )

  const { insuranceService } = useSelector(
    (state) => state.corporateProfileCompletion?.data?.data ?? {}
  )

  const handleFileUpload = (e) => {
    const file = e.target.files[0]

    //Check file type to be and excel file before saving to state
    if (
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" /* .xlsx filetype */ ||
      file.type === "application/vnd.ms-excel" /* .xls filetype */
    ) {
      setBeneficiariesList(file)
    } else {
      alert("File must be and excel file (.xlsx or .xls)")
    }
  }

  const handleUploadBeneficiaries = (e) => {
    e.preventDefault()
    dispatch({
      type: "UPLOAD_CORPORATE_BENEFICIARIES",
      payload: beneficiariesList,
    })
  }

  return (
    <CorporateDashboardStructure screenTitle='Add Beneficiaries'>
      <Card className='add__beneficiaries p-4'>
        <Row>
          <Col className='p-md-5 add__beneficiaries--process' sm={10} md={8}>
            <Row className='add__beneficiaries--procedure'>
              <Col>
                <div>
                  <h3 className='title'>Procedure </h3>
                  <p>
                    To upload your beneficiaries, you need to upload an Excel
                    document that contains their details and preferences.
                  </p>
                  <a
                    href={
                      insuranceService && insuranceService === "Axamansard"
                        ? `https://docs.google.com/file/d/1bZU6oj738tKWnOU5q9xM8aVWCE1TY5tF/edit?usp=docslist_api&filetype=msexcel`
                        : `https://docs.google.com/file/d/1IVejs4RNdFEaJ0iMTX6HE3Wnu_uf6fzJ/edit?usp=docslist_api&filetype=msexcel`
                    }
                    target='_blank'
                    download
                  >
                    Download{" "}
                    {insuranceService && insuranceService === "Axamansard"
                      ? "Axa Mansard"
                      : "Hygeia"}{" "}
                    Excel template
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={5} className='add__beneficiaries--form'>
                <h3 className='title'>Beneficiary List</h3>
              </Col>
              <Col xs={12} md={7}>
                <Form onSubmit={handleUploadBeneficiaries} className='px-0'>
                  Excel file
                  <div className='custom-file-upload-container mt-3 mb-2'>
                    <Form.Label
                      htmlFor='file-upload'
                      className='file-upload m-0 px-0'
                    >
                      <span className='pr-2'>
                        <AiOutlinePaperClip />
                      </span>
                      Browse files
                    </Form.Label>
                  </div>
                  <Form.Control
                    id='file-upload'
                    type='file'
                    name='beneficiariesList'
                    onChange={handleFileUpload}
                    className='file-upload'
                    required
                    // accept={
                    //   'application/vnd.ms-excel' ||
                    //   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    // }
                  ></Form.Control>
                  {beneficiariesList && beneficiariesList.name ? (
                    <div className='add__beneficiaries--file-name'>
                      {beneficiariesList.name}
                    </div>
                  ) : null}
                  <Row className='mt-4'>
                    <Col md={7} sm={12}>
                      <CustomButton
                        type='submit'
                        disabled={
                          uploadCorporateBeneficiaries &&
                          uploadCorporateBeneficiaries.isLoading
                            ? true
                            : false
                        }
                      >
                        {uploadCorporateBeneficiaries &&
                        uploadCorporateBeneficiaries.isLoading
                          ? "Uploading..."
                          : "Upload"}
                      </CustomButton>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </CorporateDashboardStructure>
  )
}

export default AddBeneficiaries
