import { takeLatest, put } from 'redux-saga/effects'
import axios from 'axios'
import {
  getAllPharmacyRequested,
  getAllPharmacySuccessful,
  getAllPharmacyFailed,
} from '../../../reducer/oneDrugStore/admin/pharmacySlices'

function* getPharmacyAsync({ payload }) {
  try {
    yield getAllPharmacyRequested()

    const { data } = yield axios({
      method: 'GET',
      url: `${
        process.env.REACT_APP_ONE_DRUG_BASE
      }/v1/api/Pharmacy/List?aggregatorId=${payload || ''}`,
      headers: { Authorization: `Bearer ${localStorage.getItem('adminInfo')}` },
    })

    yield put(getAllPharmacySuccessful(data.data))
  } catch (error) {
    if (error.response) {
      yield put(getAllPharmacyFailed(error.response.data))
      yield put(getAllPharmacyFailed(null))
      return
    }

    yield put(getAllPharmacyFailed(error.message))
    yield put(getAllPharmacyFailed(null))
  }
}

export function* watchGetPharmacy() {
  yield takeLatest(getAllPharmacyRequested.type, getPharmacyAsync)
}
