import React from 'react'
import { Row, Col } from 'react-bootstrap'
import './personalInfo.scss'

const PersonalInfo = ({ userPersonalInfo }) => {
  userPersonalInfo =
    userPersonalInfo && userPersonalInfo.data ? userPersonalInfo.data : {}

  return (
    <Row className="healthInsuredAdmin__user-profile__info">
      <Col className="healthInsuredAdmin__user-profile__info--personal-info items">
        <p className="title">Personal Information</p>
        <table className=" table table-borderless">
          <tbody>
            <tr>
              <td>Full Name</td>
              <td className="table--value">{`${
                userPersonalInfo.othernames ?? ''
              } ${userPersonalInfo.surname ?? ''}`}</td>
            </tr>

            <tr>
              <td>Enrolee Number</td>
              <td className="table--value text-wrap">
                {userPersonalInfo.enrolleeNumber || '--'}
              </td>
            </tr>

            <tr>
              <td>Date of Birth</td>
              <td className="table--value">
                {new Date(userPersonalInfo.dateOfBirth).toDateString() || ''}
              </td>
            </tr>

            <tr>
              <td>Gender</td>
              <td className="table--value">{userPersonalInfo.gender || ''}</td>
            </tr>

            <tr>
              <td>Marital Status</td>
              <td className="table--value">
                {userPersonalInfo.maritalStatus || ''}
              </td>
            </tr>

            <tr>
              <td>Occupation</td>
              <td className="table--value">
                {userPersonalInfo.occupation || ''}
              </td>
            </tr>

            <tr>
              <td>Home Address</td>
              <td className="text-wrap table--value">
                {userPersonalInfo.contactAddress || ''}
              </td>
            </tr>
            <tr>
              <td>State of Residence</td>
              <td className="table--value">
                {userPersonalInfo.stateOfResidence || ''}
              </td>
            </tr>
          </tbody>
        </table>
      </Col>

      <Col className="healthInsuredAdmin__user-profile__info--sub-info items">
        <p className="title">Subscription Information</p>
        <table className=" table table-borderless">
          <tbody>
            <tr>
              <td>Plan</td>
              <td className="table--value">{userPersonalInfo.premium || ''}</td>
            </tr>

            <tr>
              <td>Insurance Provider Name</td>
              <td className="table--value">
                {userPersonalInfo.insuranceService || ''}
              </td>
            </tr>

            <tr>
              <td>Preferred Care Provider</td>
              <td className="table--value text-wrap">
                {userPersonalInfo.careProviderName || ''}
              </td>
            </tr>
          </tbody>
        </table>
      </Col>

      <Col className="healthInsuredAdmin__user-profile__info--card-info items">
        <p className="title">Card Information</p>
        <table className=" table table-borderless">
          <tbody>
            <tr>
              <td>Card Number</td>
              {/* <td className='table--value'>*****************</td> */}
              <td className="table--value">
                {userPersonalInfo &&
                userPersonalInfo.cardDTOs &&
                userPersonalInfo.cardDTOs.length > 0
                  ? userPersonalInfo.cardDTOs.map(
                      (card) => `**** **** **** ${card.lastFourDigit}`
                    )
                  : 'No card info.'}
              </td>
            </tr>
          </tbody>
        </table>
      </Col>
    </Row>
  )
}

export default PersonalInfo
