/**
 *
 * @param {*} userDetails
 * @description validate provider's details
 * @returns {false} if there is no validation error
 */

function subscriptionValidation(PlanCode) {
  const error = { PlanCodeError: '' };

  if (PlanCode === '') {
    error.PlanCodeError = 'please select your health plan';
    return error;
  }

  return false;
}

export default subscriptionValidation;