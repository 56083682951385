import React from 'react';
import { Card, Form } from 'react-bootstrap';
import LineChart from './LineChart';

const SignupAnalytics = ({ signUpMonths, filterSignupanalytics }) => {

  return (
    <Card>
      <Card.Body className='py-5'>
        <div className='d-flex justify-content-between mb-4'>
          <p className='breakdown-service-p'>Sign-Up Analytics</p>
          <Form.Group>
            <Form.Control as="select" size="sm" onChange={filterSignupanalytics}>
              <option value=''>All Time</option>
              <option value='1'>Yearly</option>
            </Form.Control>
          </Form.Group>
        </div>
        <div style={{ height: '25rem' }}>
          <LineChart signUpMonths={signUpMonths} />
        </div>
      </Card.Body>
    </Card>
  );
};

export default SignupAnalytics;
