import React from 'react';
import { Modal } from 'react-bootstrap';
import { FcBusinessman } from 'react-icons/fc';
import { useFormik } from 'formik';
import * as yup from 'yup';

import ResponseToast from '../../../../template/responseToast/ResponseToast';
import { ReactComponent as Close } from '../../../../../../assets/healthInsuredAdmin/close.svg';

const ManageAdmin = ({
  showManageAdmin,
  closeManageAdmin,
  showRemoveAdminHandler,
  name,
  email,
  updateAdminRole,
  roles,
  updateLoading,
  updateError,
  showUpdateToast,
  closeToast,
  updateSuccess,
  showUpdateToastSuccess,
}) => {

  const validationSchema = yup.object().shape({
    roleId: yup.string().required('Required').trim(),
  });

  const formik = useFormik({
    initialValues: {
      roleId: ''
    },

    validationSchema,
    onSubmit: values => { updateAdminRole(values, formik.handleReset) }
  })

  // get all administrator roles and id as option value and name
  const filterOptions = roles.length > 0 && roles.map((role, index) => (
    <option key={index} value={role.id}>{role.name}</option>
  ));


  return (
    <Modal
      show={showManageAdmin}
      onHide={closeManageAdmin}
      dialogClassName="modal-sz health__insured__admin__manage__admin__modal"
      aria-labelledby="manage admin modal"
      animation={true}
      autoFocus={true}
      keyboard={true}
    >
      <Modal.Body
        className='health__insured__admin__manage__admin__modal--body'
      >
        <ResponseToast
          error={updateError}
          showToast={showUpdateToast}
          closeToast={closeToast}
          delay={5000}
          right={50}
        />

        <ResponseToast
          success={updateSuccess}
          showToast={showUpdateToastSuccess}
          closeToast={closeToast}
          delay={5000}
          right={50}
        />

        <i className='health__insured__admin__manage__admin__modal--icon-wrapper' onClick={closeManageAdmin}>
          <Close className='health__insured__admin__manage__admin__modal--icon-close' />
        </i>
        <h2 className='health__insured__admin__manage__admin__modal--heading'>Manage admin</h2>

        <div className='health__insured__admin__manage__admin__modal--profile-wrapper'>
          <div
            className='health__insured__admin__manage__admin__modal--profile--picture-wrapper'
            role='img' aria-label='admin profile picture'
          >
            <FcBusinessman className='health__insured__admin__manage__admin__modal__profile--img' />
          </div>


          <div>
            <h4 className='health__insured__admin__manage__admin__modal--name'>{name}</h4>
            <p className='health__insured__admin__manage__admin__modal--email'>{email}</p>
          </div>
        </div>

        <form className='p-0' onSubmit={formik.handleSubmit} id='healthadmin-update-role'>
          <div className='health__insured__admin__manage__admin__modal--form-control'>
            <label className='health__insured__admin__manage__admin__modal--form-label'>Assign role</label>
            <select
              className='health__insured__admin__manage__admin__modal--form-input'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name='roleId'
              value={formik.values.roleId}
            >
              <option></option>
              {filterOptions}
            </select>
            {
              formik.touched.roleId && formik.errors.roleId ?
                <div aria-labelledby='error message' className='text-danger'>
                  <small>*{formik.errors.roleId}</small>
                </div> : null
            }
          </div>
        </form>

      </Modal.Body>
      <Modal.Footer className='health__insured__admin__manage__admin__modal--footer'>
        <button className='health__insured__admin__manage__admin__modal--btn-update' onClick={formik.handleSubmit}>{updateLoading ? 'Updating...' : 'Update'}</button>
        {/* <button className='health__insured__admin__manage__admin__modal--btn-remove' onClick={showRemoveAdminHandler}>Remove</button> */}
        <button className='health__insured__admin__manage__admin__modal--btn-cancel' form='update-role' onClick={closeManageAdmin}>Cancel</button>
      </Modal.Footer>
    </Modal>
  );
};

export default ManageAdmin;
