import { GET_INSURANCE_PROFILES_FAILED, GET_INSURANCE_PROFILES_REQUEST, GET_INSURANCE_PROFILES_SUCCESS } from "../../actions/healthInsuredTypes/healthInsuredAdminActionTypes"

export const getInsuranceProfilesReducer = (state = {}, action) => {
    switch(action.type) {

        case GET_INSURANCE_PROFILES_REQUEST:
            return {isLoading: true}

        case GET_INSURANCE_PROFILES_SUCCESS:
            return {isLoading: false, ...action.payload}

        case GET_INSURANCE_PROFILES_FAILED:
            return {isLoading: false, ...action.payload}

        default:
            return state
    }
}