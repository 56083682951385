import React from 'react'
import { Table } from 'react-bootstrap'

// import UserDp from '../../../../../../../assets/healthInsuredAdmin/adminProfile.png';

const HealthCareTable = ({ tableId, tableRow }) => {
  return (
    <Table
      hover
      responsive
      className="health__insured__admin__provider--table"
      borderless
      id={tableId}
    >
      <thead className="hb-admin-table-h">
        <tr>
          {/* <th><input type='checkbox' /></th> */}
          <th>Full Name</th>
          <th>Email Address</th>
          <th>Phone Number</th>
          <th>Company</th>
        </tr>
      </thead>
      <tbody>
        {/*
        <tr>
          <td><input type='checkbox' /></td>
          <td><img src={UserDp} alt='user dp' />Annette Black</td>
          <td>Tosin@gmail.com</td>
          <td>08012345678</td>
          <td>Sterling</td>
        </tr> */}
        {tableRow}
      </tbody>
    </Table>
  )
}

export default HealthCareTable
