import React from 'react'
import styled from 'styled-components'

const Div = styled.div`
	/* position: relative; */
	background-color: #fafafa;
	min-height: 100vh;
	width: 100vw;
`

export default (props) => <Div>{props.children}</Div>
