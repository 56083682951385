import React, { useState } from 'react'
// import { useField } from 'formik'
import styled from 'styled-components'

const TextareaContainer = styled.div`
  margin-bottom: 20px;

  .error-container {
    height: 10px;
  }

  .select-div {
    height: auto;
    border: 1px solid #a4a4a4;
    /* border: 1px solid red; */
    border-radius: 3px;
    background-color: #fff;
    /* overflow:  hidden; */

    /* &:active {
			border-width: 2px;
		} */
  }
`

const Label = styled.div`
  font-size: 13px;
  font-weight: 500;
`

const Textarea = styled.textarea`
  width: 100%;
  border: none;
  outline: none;
  /* border: 1px solid #213f7d; */
  box-sizing: border-box;
  border-radius: 3px;
  height: auto;
  padding: 7px;
  font-size: 14px;
  background-color: #fff;

  ::placeholder {
    font-size: 12px;
  }
`

const Error = styled.div`
  font-size: 12px;
  color: red;
`

const CustomTextarea = ({ label, children, ...props }) => {
  const [field, meta] = useState(props)
  // const [field, meta] = useField(props)

  return (
    <TextareaContainer>
      <div>
        <Label htmlFor={props.id || props.name}>{label}</Label>
      </div>
      <div className="select-div">
        <Textarea className="formInput" {...field} {...props}>
          {children}
        </Textarea>
      </div>
      <div className="error-container">
        {meta.touched && meta.error ? (
          <Error className="error p-0 m-0">*{meta.error}</Error>
        ) : null}
      </div>
    </TextareaContainer>
  )
}

export default CustomTextarea
