import React, { useState, useEffect } from 'react'
import axios from 'axios'

import UsersCard from './UsersCard'
import BreakdownService from './BreakdownServices'
import SignupAnalytics from './SignupAnalytics'
import Loading from '../../../healthBancServices/pharmHub/admin/components/Spinner/Spinner'
import ResponseToast from '../../../healthBancServices/healthInsured/template/responseToast/ResponseToast'

const url = '/v2/api/DashboardAnalytics/DashboardAnalytics'
const breakdownServiceUrl = '/v2/api/DashboardAnalytics/GetServiceBreakdown'
const signupAnalyticsUrl = '/v2/api/DashboardAnalytics/GetSignUpAnalytics'
const baseUrl = process.env.REACT_APP_BASE_URL

const MainSection = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [show, setShow] = useState(false)
  const [users, setUsers] = useState({})
  const [serviceBreakdowns, setServiceBreakdowns] = useState([])
  const [signUpMonths, setSignUpMonths] = useState([])
  const [token, setToken] = useState(localStorage.getItem('adminInfo'))

  useEffect(() => {
    setToken(token)
    setLoading(true)

    axios
      .get(`${baseUrl}${url}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const users = response.data.data
        const serviceBreakdowns = response.data.data.serviceBreakdowns
        const signUpMonths = response.data.data.signUpMonths

        setUsers(users)
        setServiceBreakdowns(serviceBreakdowns)
        setSignUpMonths(signUpMonths)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        setShow(true)
        error.response
          ? setError(error.response.statusText)
          : setError(error.meesage)
      })
  }, [])

  // handle filter for breakdownServiceUrl on the analytical dashboard
  const filterBreakdownbyservice = async (e) => {
    let value

    // change value to number or leave it as empty string
    if (e.target.value === '') {
      value = ''
    } else {
      value = Number(e.target.value)
    }

    try {
      const response = await axios.post(`${baseUrl}${breakdownServiceUrl}`, {
        data: { Id: value },
        headers: { Authorization: `Bearer ${token}` },
      })

      const serviceBreakdowns = response.data.data.serviceBreakdowns

      setServiceBreakdowns(serviceBreakdowns)
    } catch (error) {
      setShow(true)
      error.response
        ? setError(error.response.statusText)
        : setError(error.meesage)
    }
  }

  // handle filter for breakdownServiceUrl on the analytical dashboard
  const filterSignupanalytics = async (e) => {
    let value

    // change value to number or leave it as empty string
    if (e.target.value === '') {
      value = ''
    } else {
      value = Number(e.target.value)
    }

    try {
      const response = await axios.post(`${baseUrl}${signupAnalyticsUrl}`, {
        data: { Id: value },
        headers: { Authorization: `Bearer ${token}` },
      })

      const signUpMonths = response.data.data.signUpMonths
      setSignUpMonths(signUpMonths)
    } catch (error) {
      setShow(true)
      error.response
        ? setError(error.response.statusText)
        : setError(error.meesage)
    }
  }

  const closeToast = () => {
    setError('')
    setShow(false)
  }

  //show loading spinner on page load or network request
  if (loading) return <Loading />

  return (
    <div className="hb-main-section d-column p-2  p-sm-4">
      <ResponseToast
        error={error}
        position="absolute"
        top={80}
        right={20}
        delay={5000}
        closeToast={closeToast}
        showToast={show}
      />
      <div className="card-container mb-4">
        <UsersCard
          className="card-item"
          title="Registered users"
          figure={users && users.registeredUsers}
          percent={
            Object.values(users).length !== 0 ? users.registeredUsers / 100 : ''
          }
          color=""
        />
        <UsersCard
          className="card-item "
          title="Active users"
          figure={users && users.activeUsers}
          percent={
            Object.values(users).length !== 0 ? users.activeUsers / 100 : ''
          }
          color="green"
          spacing=""
        />
        <UsersCard
          className="card-item"
          title="Inactive users"
          figure={users && users.inactiveUsers}
          percent={
            Object.values(users).length !== 0 ? users.activeUsers / 100 : ''
          }
          color="red"
        />
      </div>
      <div className="mb-4">
        <BreakdownService
          serviceBreakdowns={serviceBreakdowns}
          filterBreakdownbyservice={filterBreakdownbyservice}
        />
      </div>
      <div>
        <SignupAnalytics
          signUpMonths={signUpMonths}
          filterSignupanalytics={filterSignupanalytics}
        />
      </div>
    </div>
  )
}

export default MainSection
