import { call, put, takeEvery } from 'redux-saga/effects'
import {
  DEACTIVATE_FRIEND_FAILED,
  DEACTIVATE_FRIEND_REQUESTED,
  DEACTIVATE_FRIEND_SUCCESSFUL,
} from '../../../reducer/healthInsuredReducer/deactivateFriendReducer'
import { GET_FRIENDS_REQUESTED } from '../../../reducer/healthInsuredReducer/getIndividualFriendsReducer'
import { deactivateFriendAPI } from './friendsAPIs'

function* handleDeactivateFriendAsync({ payload }) {
  try {
    yield DEACTIVATE_FRIEND_REQUESTED()

    const { data } = yield call(deactivateFriendAPI, payload.id)

    yield put(DEACTIVATE_FRIEND_SUCCESSFUL(data))

    // yield put(GET_FRIENDS_REQUESTED())
    yield alert(data.message)

    yield window.location.reload()
  } catch (error) {
    if (error.response) {
      yield alert(error.response.data.message)
      return yield put(DEACTIVATE_FRIEND_FAILED(error.response.data.message))
    }

    yield alert(error.message)
  } finally {
    yield payload.setShow(false)
  }
}

export function* watchDeactivateFriend() {
  yield takeEvery(DEACTIVATE_FRIEND_REQUESTED.type, handleDeactivateFriendAsync)
}
