import React from 'react';
import { Link } from 'react-router-dom';
import { FcBusinessman } from 'react-icons/fc';

const Card = ({
  buttonText,
  name,
  email,
  role,
  showManageAdminHandler,
}) => {
  return (
    <article className='healthInsured__admin__roles__card'>
      <div className='healthInsured__admin__roles__card--container'>
        <div
          className='healthInsured__admin__roles__card__profile--container'
          role='img' aria-label='admin profile picture'
        >
          <FcBusinessman className='healthInsured__admin__roles__card__profile--img' />
        </div>


        <h4 className='healthInsured__admin__roles__card--name'>{name}</h4>
        <p className='healthInsured__admin__roles__card--email'>{email}</p>
        <p className='healthInsured__admin__roles__card--role'>{role}</p>

        <Link className='healthInsured__admin__roles__card--btn' onClick={showManageAdminHandler}>{buttonText}</Link>
      </div>
    </article>
  )
}

export default Card;