import { takeLatest, put } from 'redux-saga/effects'
import axios from 'axios'
import {
  getAllOrdersRequested,
  getAllOrdersSuccessful,
  getAllOrdersFailed,
} from '../../../reducer/oneDrugStore/admin/orderSlices'

function* getAllOrdersAsync({ payload }) {
  const { searchText = '', status = '' } = payload || {}
  try {
    yield getAllOrdersRequested()

    const { data } = yield axios({
      method: 'GET',
      url: `${process.env.REACT_APP_ONE_DRUG_BASE}/v1/api/Order/AllOrder?Status=${status}&SearchText=${searchText}`,
      headers: { Authorization: `Bearer ${localStorage.getItem('adminInfo')}` },
    })

    yield put(getAllOrdersSuccessful(data.data))
  } catch (error) {
    if (error.response) {
      yield put(getAllOrdersFailed(error.response.data))
      yield put(getAllOrdersFailed(null))
      return
    }

    yield put(getAllOrdersFailed(error.message))
    yield put(getAllOrdersFailed(null))
  }
}

export function* watchGetAllOrders() {
  yield takeLatest(getAllOrdersRequested.type, getAllOrdersAsync)
}
