import { call, put, takeEvery } from 'redux-saga/effects'
import { GET_FRIENDS_REQUESTED } from '../../../reducer/healthInsuredReducer/getIndividualFriendsReducer'
import {
  REMOVE_FRIEND_FAILED,
  REMOVE_FRIEND_REQUESTED,
  REMOVE_FRIEND_SUCCESSFUL,
} from '../../../reducer/healthInsuredReducer/removeFriendReducer'
import { removeFriendAPI } from './friendsAPIs'

function* handleRemoveFriend({ payload }) {
  try {
    yield REMOVE_FRIEND_REQUESTED()

    const { data } = yield call(removeFriendAPI, payload.email)

    alert(data.message)
    yield put(REMOVE_FRIEND_SUCCESSFUL(data))

    // yield put(GET_FRIENDS_REQUESTED())

    yield payload.setShow(false)

    yield window.location.reload()
  } catch (error) {
    if (error.response) {
      alert(error.response.data.message)
      return yield put(REMOVE_FRIEND_FAILED(error.response.data))
    }

    alert(error.message)
    return yield put(REMOVE_FRIEND_FAILED(error.message))
  }
}

export function* watchRemoveFriend() {
  yield takeEvery(REMOVE_FRIEND_REQUESTED.type, handleRemoveFriend)
}
