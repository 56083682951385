import React from 'react';

import Search from '../template/Search';
import Filter_Sort from './Filter_Sort';
import ExportButton from '../template/ExportButton';
import Pagination from "../template/Pagination";
import InactiveTable from '../template/UserTable';
import EmptyUser from '../activeUsers/EmptyUser';
import Loading from '../../../healthBancServices/pharmHub/admin/components/Spinner/Spinner';
import ResponseModal from '../../../healthBancServices/pharmHub/admin/components/responseModal/ResponseModal';

const MainSection = ({
  loading,
  show,
  error,
  handleHide,
  inactiveUsers,
  handleChange,
  searchSubmit,
  filter,
  services,
  pageCount,
  pageNumber,
  recordCount,
  paginate,
  sort
}) => {


  // get all service names and values 
  const filterOptions = services && services.map(service => (
    <option value={service.id}>{service.name}</option>
  ));

  //show loading spinner on page load or network request
  if (loading) return <Loading />;
  // alert(services.length)

  return (
    <div className='hb-main-section hb-registered-users p-4'>
      <div className='d-flex flex-row'>
        <ResponseModal
          show={show}
          handleHide={handleHide}
          error={error}
        />
        <Search
          handleChange={handleChange}
          searchSubmit={searchSubmit}
        />
        <Filter_Sort
          filter={filter}
          sort={sort}
          filterOptions={filterOptions}
        />
        <ExportButton />

        {
          inactiveUsers.length === 0 ? null :
            <Pagination
              pageCount={pageCount}
              pageNumber={pageNumber}
              recordCount={recordCount}
              paginate={paginate}
            />
        }
      </div>

      {inactiveUsers.length === 0 ? <EmptyUser /> : <InactiveTable Users={inactiveUsers} />}

    </div>
  );
};

export default MainSection;
