import React from 'react';
import { useLocation } from 'react-router-dom';

const ExportButton = () => {

  // instanciate history object
  const location = useLocation();
  const path = location.pathname.slice(1, location.pathname.length);

  function exportTableToExcel(tableID, filename = '') {
    var downloadLink;
    var dataType = 'application/vnd.ms-excel';
    var tableSelect = document.querySelector(tableID);
    var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');

    // Specify file name
    filename = filename ? filename + '.xls' : 'excel_data.xls';

    // Create download link element
    downloadLink = document.createElement("a");

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      var blob = new Blob(['\ufeff', tableHTML], {
        type: dataType
      });
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // Create a link to the file
      downloadLink.href = 'data:' + dataType + ', ' + tableHTML;

      // Setting the file name
      downloadLink.download = filename;

      //triggering the function
      downloadLink.click();
    }
  }

  return (
    <button
      className="px-4 export-button btn-export"
      onClick={() => exportTableToExcel(`#${path}`, path)}
    >
      Export
    </button>
  );
};

export default ExportButton;
