import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: {},
  errors: [],
}

const activateFriendSlice = createSlice({
  name: 'activate_friend',
  initialState,
  reducers: {
    ACTIVATE_FRIEND_REQUESTED: (state) => {
      return { isLoading: true, data: {}, errors: [] }
    },

    ACTIVATE_FRIEND_SUCCESSFUL: (state, action) => {
      return { isLoading: false, data: action.payload, errors: [] }
    },

    ACTIVATE_FRIEND_FAILED: (state, action) => {
      return { isLoading: false, data: {}, error: [action.payload] }
    },
  },
})

export const {
  ACTIVATE_FRIEND_REQUESTED,
  ACTIVATE_FRIEND_SUCCESSFUL,
  ACTIVATE_FRIEND_FAILED,
} = activateFriendSlice.actions

export default activateFriendSlice.reducer
