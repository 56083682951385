import React, { useState } from 'react'
import { useField } from 'formik'
import NumberFormat from 'react-number-format'
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai'
import styled from 'styled-components'

const InputContainer = styled.div`
  margin-bottom: 20px;

  .error-container {
    height: 10px;
  }

  .password__toggler {
    padding-top: 7px;
    cursor: pointer;
    padding-left: 2%;
  }

  .input-div {
    height: 40px;

    border: 0.5px solid #a4a4a4;
    border-radius: 3px;
    background-color: #fff;

    overflow: hidden;
  }
`

const Label = styled.div`
  font-size: 13px;
  font-weight: 500;
`

const FormInput = styled.input`
  width: 100%;
  border: none;

  outline: none;
  width: 90%;
  box-sizing: border-box;
  border: none;
  border-radius: 3px;
  height: 40px;
  padding: 7px;
  font-size: 14px;
  background-color: #fff;

  &:active,
  &:focus {
    background: inherit;
  }

  ::placeholder {
    font-size: 12px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

const NumberFormInput = styled(NumberFormat)`
  width: 100%;
  border: none;

  outline: none;
  width: 90%;
  box-sizing: border-box;
  border-radius: 3px;
  height: 39px;
  padding: 7px;
  font-size: 14px;
  background-color: inherit;

  ::placeholder {
    font-size: 12px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

const Error = styled.div`
  font-size: 12px;
  color: red;
`

const CustomInput = ({ label, ...props }) => {
  const [field, meta] = useField(props)

  const [newType, setNewType] = useState(props.type)

  const [isPassword, setIsPassword] = useState(true)
  const [passwordIsVisible, setPasswordIsVisible] = useState(false)

  const handleTogglePassword = () => {
    setPasswordIsVisible(!passwordIsVisible)
    if (newType === 'text') {
      setNewType('password')
      setIsPassword(true)
    } else {
      setNewType('text')
      setIsPassword(false)
    }
  }

  return (
    <InputContainer>
      <div>
        <Label htmlFor={props.id || props.name}>{label}</Label>
      </div>
      <div className="input-div">
        {props.type !== 'number' && props.type !== 'tel' ? (
          <>
            <div className="d-flex flex-row">
              <FormInput
                className="formInput"
                {...props}
                // {...field}
                type={newType}
              />
              {props.type === 'password' && (
                <span
                  className="password__toggler"
                  onClick={handleTogglePassword}
                >
                  {isPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                </span>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="d-flex flex-row">
              <NumberFormInput
                className="formInput"
                // {...field}
                {...props}
                type={newType}
                // isNumericString={props.type === 'tel' && true}
                allowNegative={
                  (props.type === 'tel' || props.type === 'date') && false
                }
                allowLeadingZeros={
                  (props.type === 'tel' || props.type === 'date') && true
                }
              />
            </div>
          </>
        )}
      </div>
      <div className="error-container">
        {meta.touched && meta.error ? (
          <Error className="error p-0 m-0">*{meta.error}</Error>
        ) : null}
      </div>
    </InputContainer>
  )
}

export default CustomInput
