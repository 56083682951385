import React from 'react'
import { format } from 'date-fns'
import { useSelector } from 'react-redux'
import { Row, Col, Card } from 'react-bootstrap'
import { FiUsers, FiUserCheck, FiUserMinus, FiCalendar } from 'react-icons/fi'
import './statsCard.scss'

const StatsCards = () => {
  const analytics = useSelector((state) => state.getCorporateAnalytics)

  const {
    activeBeneficiaryCount = 0,
    pendingBeneficiaryCount = 0,
    inactiveBeneficiaryCount = 0,
    nextPaymentDate = null,
  } = analytics && analytics.data ? analytics.data : {}

  return (
    <>
      <Row className="justify-content-between p-0 m-0">
        <Col xs={12} sm={6} md={3}>
          <Card className="stats__card px-2">
            <Row>
              <Col className="ms-1 col-auto dashboard__icon--container">
                <div className="users__active">
                  <FiUsers className="dashboard--icon p-0" />
                </div>
              </Col>
              <Col className="p-0">
                <div className="dashboard--stats--counter">
                  {activeBeneficiaryCount}
                </div>
                <div className="dashboard--stats--label">
                  Active{' '}
                  {activeBeneficiaryCount === 1 ? 'Subscriber' : 'Subscribers'}
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={12} sm={6} md={3}>
          <Card className=" stats__card px-2">
            <Row>
              <Col className="ml-3 col-auto dashboard__icon--container">
                <div className="users__pending">
                  <FiUserCheck className="dashboard--icon " />
                </div>
              </Col>
              <Col className="p-0">
                <div className="dashboard--stats--counter">
                  {pendingBeneficiaryCount}
                </div>
                <div className="dashboard--stats--label">Pending</div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={12} sm={6} md={3}>
          <Card className="stats__card px-2">
            <Row>
              <Col className="ml-3 col-auto dashboard__icon--container">
                <div className="users__inactive">
                  <FiUserMinus className="dashboard--icon" />
                </div>
              </Col>
              <Col className="p-0">
                <div className="dashboard--stats--counter">
                  {inactiveBeneficiaryCount}
                </div>
                <div className="dashboard--stats--label">Inactive</div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={12} sm={6} md={3}>
          <Card className="stats__card px-2">
            <Row>
              <Col className="ml-3 col-auto dashboard__icon--container">
                <div className="users__payment__date">
                  <FiCalendar className="dashboard--icon" />
                </div>
              </Col>
              <Col className="p-0">
                <div className="dashboard--stats--counter">
                  {nextPaymentDate === null
                    ? 'N/A'
                    : format(new Date(nextPaymentDate), 'dd-MM-yyyy')}
                </div>
                <div className="dashboard--stats--label">Next Payment</div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default StatsCards
