import React from 'react'
import { ReactComponent as Arrowleft } from '../../healthInsuredIndividualFlow/assets/arrow-left.svg'
import { ReactComponent as ArrowRight } from '../../healthInsuredIndividualFlow/assets/arrow-right.svg'

const Pagination = ({ pageCount, handlePagination, pageNumber }) => {
  const linkItem = []

  for (let i = pageNumber; i <= pageCount; i++) {
    linkItem.push(
      <li
        key={i}
        className={`pagination__link--item ${pageNumber === i ? 'active' : ''}`}
        onClick={() => handlePagination(i)}
      >
        {i}
      </li>
    )
    if (linkItem.length === 3) break
  }

  return (
    <div className="template__pagination--container">
      <p className="pagination--count">
        Showing {pageNumber} of {pageCount}
      </p>

      <ul className="pagination--link">
        <li
          disabled={pageNumber < pageCount}
          className="pagination__link--item d-flex align-items-center pl-0"
          onClick={() => handlePagination(pageNumber - 1)}
        >
          <i className="me3">
            <Arrowleft />
          </i>
          Prev
        </li>
        {linkItem}
        <li
          disabled={pageNumber === pageCount}
          className="pagination__link--item d-flex align-items-center pr-0"
          onClick={() => handlePagination(pageNumber + 1)}
        >
          Next
          <i className="ml-3">
            <ArrowRight />
          </i>
        </li>
      </ul>
    </div>
  )
}

export default Pagination
