import { takeEvery, put } from 'redux-saga/effects'
import axios from 'axios'
import {
  getCorporateAnalyticsFailed,
  getCorporateAnalyticsRequest,
  getCorporateAnalyticsSuccess,
} from '../../actions/healthInsuredCreators/corporateUserActions/getCorporateAnalyticsActions'

function* getCorporateAnalytics() {
  try {
    yield put(getCorporateAnalyticsRequest())

    const { data } = yield axios.get(
      '/v2/api/CorporateInsurance/CorporateSubscribersAnalytics'
    )

    yield put(getCorporateAnalyticsSuccess(data))
  } catch (error) {
    yield put(getCorporateAnalyticsFailed(error.response))
  }
}

export function* watchGetCorporateAnalytics() {
  yield takeEvery('GET_CORPORATE_ANALYTICS', getCorporateAnalytics)
}
