import React, { useReducer, useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector, connect, useDispatch } from 'react-redux'
import Iframe from 'react-iframe'

import PersonalDetails from './personalDetails/PersonalDetails'
import ProviderDetails from './providerDetails/ProviderDetails'
import PaymentDetails from './paymentDetails/PaymentDetails'
import Subscription from './subscription/Subscription'
import personalInfoValidator from '../signup/validations/personalInfoValidator'
import subscriptionValidator from '../signup/validations/subscriptionValidation'
import providerValidator from '../signup/validations/providerSValidator'
import { axaUserPinReference } from '../../../../redux/healthBanc/actions/actionCreators'
import { getTownOfResidence } from '../../../../redux/healthBanc/actions/healthInsuredCreators/townOfResidence'
import { getHealthCareProviderList } from '../../../../redux/healthBanc/actions/healthInsuredCreators/serviceProvider'
import { useHistory } from 'react-router'

const createProfileUrl = '/v1/api/Insurance/CreateUserInsuranceProfile'
const tokenizeCardUrl = '/v2/api/Tokenization/ChargeCard'
const getTownsUrl = '/v2/api/Insurance/GetTowns'

const SignUp = ({ axaUserPinReference }) => {
  const [showAlert, setShowAlert] = useState(false)
  const [getTownError, setGetTownError] = useState('')
  const [townLoading, setTownLoading] = useState(false)
  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [validationErrors, setValidationErrors] = useState('')
  const [paystackError, setpayStackError] = useState('')

  const [healthInsuredSignupDetails, setHealthInsuredSignupDetails] =
    useReducer((state, newState) => ({ ...state, ...newState }), {
      DateOfBirth: '',
      Gender: '',
      MaritalStatus: '',
      Occupation: '',
      ContactAddress: '',
      StateOfResidence: '',
      TownOfResidence: '',
      CareProviderName: '',
      CPPhone: '',
      CPEmail: '',
      CPAddress: '',
      CPCity: '',
      PlanCode: '',
      UserImage: '',
    })

  const dispatch = useDispatch()
  const history = useHistory()

  const {
    profileCompleted,
    tokenizationCompleted,
    referee,
    healthInsuredPlan,
  } = useSelector((state) => state.profileCompletionStatus.data) ?? {}

  const {
    hospitalAndPlan: { healthplans, states },
  } = useSelector((state) => state.healthInsuredStateAndPlan)
  const insuranceProvider = useSelector((state) => state.serviceProvider)
  const { townOfResidence } = useSelector((state) => state.townOfResidence)
  const { listOfHealthCareProviders } = useSelector(
    (state) => state.listOfHealthCareProviders
  )

  const {
    DateOfBirth,
    Gender,
    MaritalStatus,
    Occupation,
    ContactAddress,
    StateOfResidence,
    TownOfResidence,
    CareProviderName,
    PlanCode,
    UserImage,
  } = healthInsuredSignupDetails

  // tokenization state
  const [tokenizationLoading, setTokenizationLoading] = useState(false)
  const [tokenizationError, setTokenizationError] = useState('')
  const [paymentRedirectUrl, setPaymentRedirectUrl] = useState(null)

  //check if a user has completed healthinsured signup process
  useEffect(() => {
    if (profileCompleted && tokenizationCompleted)
      return history.push('/health_profile')

    if (profileCompleted && healthInsuredPlan === 0) {
      setStep(4)
    }
  }, [profileCompleted, tokenizationCompleted, healthInsuredPlan, history])

  useEffect(() => {
    ;(async () => {
      try {
        setTownLoading(true)

        const {
          data: { data },
        } = await axios.post(`${getTownsUrl}`, {
          data: {
            InsurancePovider: insuranceProvider,
            State: StateOfResidence,
          },
        })

        dispatch(getTownOfResidence(data))

        setTownLoading(false)
      } catch (error) {
        setTownLoading(false)
        error.response
          ? setGetTownError(error.response.data.message)
          : setGetTownError(error.message)
      }
    })()
  }, [StateOfResidence])

  //get list of healthcare providers when a user selects town of residence
  useEffect(() => {
    dispatch(
      getHealthCareProviderList(
        StateOfResidence,
        TownOfResidence,
        insuranceProvider
      )
    )
  }, [TownOfResidence])

  // capture user inputs
  const handleChange = (e) => {
    const { name, value, files } = e.target

    //dispatch detail to redux store
    setHealthInsuredSignupDetails(
      files ? { [name]: files[0] } : { [name]: value }
    )

    setValidationErrors('')
  }

  // show previous form
  const prevPage = () => {
    if (referee) {
      setStep(1)
      return
    }

    setStep(step <= 1 ? 1 : step - 1)
  }

  const next = () => {
    if (referee) {
      setStep(3)
      return
    }

    setStep(step >= 4 ? 4 : step + 1)
  }

  // show form steps
  const nextPage = (e) => {
    e.preventDefault()

    if (step === 2) {
      const validationError = subscriptionValidator(PlanCode)
      setValidationErrors(validationError)
      if (validationError) return
    }

    if (step === 1) {
      const validationError = personalInfoValidator(healthInsuredSignupDetails)
      setValidationErrors(validationError)
      if (validationError) return
    }
    next()
  }

  const createProfile = (e) => {
    e.preventDefault()

    const validationError = providerValidator(healthInsuredSignupDetails)

    setValidationErrors(validationError)
    if (validationError) return

    setLoading(true)

    /**create a form data */
    const formdata = new FormData()
    formdata.append('DateOfBirth', DateOfBirth)
    formdata.append('Gender', Gender)
    formdata.append('MaritalStatus', MaritalStatus)
    formdata.append('Occupation', Occupation)
    formdata.append('ContactAddress', ContactAddress)
    formdata.append('StateOfResidence', StateOfResidence)
    formdata.append('TownOfResidence', TownOfResidence)
    formdata.append('PlanCode', PlanCode)
    formdata.append('CareProviderName', CareProviderName)
    formdata.append('InsuranceService', insuranceProvider)
    formdata.append('UserImage', UserImage)

    axios
      .post(`${createProfileUrl}`, formdata)
      .then((response) => {
        setLoading(false)

        if (response.status === 200) {
          // Check if the user is a referee
          // If true, health signup is complete.
          if (referee) {
            return history.push('/health_success_signup')
          }

          next()
        }
      })
      .catch((error) => {
        setLoading(false)

        if (error.response) {
          alert(
            error.response?.data?.data ??
              error.response.data[0] ??
              error.response.data.message
          )
        } else {
          alert(error.message)
        }
      })
  }

  const tokenizeCard = (values) => {
    const { cardNumber, expiryDate, cvv, pin } = values

    setTokenizationLoading(true)
    setTokenizationError('')

    const month = expiryDate.slice(0, 2)
    const year = expiryDate.slice(3)

    const tokenFormData = {
      card: {
        cvv: cvv,
        expiry_month: Number(month),
        expiry_year: Number(year),
        number: cardNumber.trim(),
        type: '',
      },
      pin: pin,
    }

    axios
      .post(`${tokenizeCardUrl}`, tokenFormData)
      .then((response) => {
        setTokenizationLoading(false)

        if (response.data.status && response.data.responseCode === 0) {
          // show success page if there is no otp required
          history.push('/health_success_signup')
        }

        //redirect to opt page
        if (response.data.status && response.data.responseCode === 12) {
          const { pin, reference } = response.data.data
          const pinReference = { pin, reference }

          axaUserPinReference(pinReference) // dispatch to redux store user pin and reference id
          history.push('/health_otp') // show otp page if the endpoint requires otp code
        }

        //redirect to external payment gateway if need be
        if (response.data.status && response.data.responseCode === 20) {
          const { pin, reference, redirectUrl } = response.data.data
          const pinReference = { pin, reference }

          axaUserPinReference(pinReference) // dispatch to redux store user pin and reference id
          let newWindow = window.open(redirectUrl, '_blank')

          if (
            !newWindow ||
            newWindow.closed ||
            typeof newWindow.closed === 'undefined'
          ) {
            setPaymentRedirectUrl(redirectUrl)
            // alert(
            //   'Please enable pop up and redirect on your browser to complete payment'
            // )
          }
        }
      })
      .catch((error) => {
        setTokenizationLoading(false)
        if (error.response) {
          const { message, Message } = error.response.data
          message
            ? setTokenizationError(message)
            : setTokenizationError(Message)
          setTokenizationError(message)
        } else {
          console.log(error.message)
        }
      })
  }

  //listen to paystack cross tab communication for payment
  window.addEventListener('message', (e) => {
    if (e.origin !== 'https://standard.paystack.co') return

    const { message, status } = JSON.parse(e.data.replace('PaystackAuth', ' '))

    if (status === 'success') {
      setTokenizationLoading(true)
      setTimeout(() => setTokenizationLoading(false), 9900) //delay for 9s to enable the backend sync the user data
      setTimeout(() => history.push('/health_success_signup'), 10000)
    } else {
      message && setpayStackError(message)
    }
  })

  function closeAlert() {
    setShowAlert(false)
  }

  switch (step) {
    case 2:
      return (
        <React.Fragment>
          <Subscription
            userDetails={healthInsuredSignupDetails}
            handleChange={handleChange}
            handlePrevious={prevPage}
            nextPage={nextPage}
            healthPlans={healthplans}
            error={error}
            errorMessage={validationErrors}
          />
        </React.Fragment>
      )

    case 3:
      return (
        <React.Fragment>
          <ProviderDetails
            userDetails={healthInsuredSignupDetails}
            handleChange={handleChange}
            handlePrevious={prevPage}
            error={error}
            showAlert={showAlert}
            closeAlert={closeAlert}
            healthCareProviders={listOfHealthCareProviders}
            createProfile={createProfile}
            loading={loading}
            errorMessage={validationErrors}
          />
        </React.Fragment>
      )

    case 4:
      return (
        <React.Fragment>
          {paymentRedirectUrl ? (
            <div className="bg-white opacity-0">
              <Iframe
                url={paymentRedirectUrl}
                width="450px"
                height="450px"
                id="signupPayment"
                className="signupPayment"
                display="initial"
                position="absolute"
              />
            </div>
          ) : (
            <PaymentDetails
              tokenizeCard={tokenizeCard}
              loading={tokenizationLoading}
              error={tokenizationError}
              paystackError={paystackError}
            />
          )}
        </React.Fragment>
      )

    default:
      return (
        <React.Fragment>
          <PersonalDetails
            healthInsuredSignupDetails={healthInsuredSignupDetails}
            handleChange={handleChange}
            nextPage={nextPage}
            states={states}
            towns={townOfResidence}
            townLoading={townLoading}
            getTownError={getTownError}
            errorMessage={validationErrors}
          />
        </React.Fragment>
      )
  }
}

export default connect(null, { axaUserPinReference })(SignUp)
