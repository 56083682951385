import { put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import {
  updateCorporateProfileFailed,
  updateCorporateProfileRequest,
  updateCorporateProfileSuccess,
} from '../../actions/healthInsuredCreators/corporateUserActions/updateCorporateProfileAction'

function* updateCorporateProfile({ payload }) {
  try {
    yield put(updateCorporateProfileRequest())

    const { data } = yield axios
      .post('/v2/api/CorporateInsurance/UpdateCorporateUser', payload)
      .then((response) => response)

    yield put(updateCorporateProfileSuccess(data))
  } catch (error) {
    if (error) {
      yield put(
        updateCorporateProfileFailed('An error occured. \nPlease try again')
      )
    }
  }
}

export function* watchUpdateCorporateProfile() {
  yield takeEvery('UPDATE_CORPORATE_PROFILE', updateCorporateProfile)
}
