import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: {},
  error: null,
}

const createOrderSlice = createSlice({
  name: 'createOrder',
  initialState,
  reducers: {
    createOrderRequested: (state) => {
      state.isLoading = true
      state.data = {}
      state.error = null
    },

    createOrderSuccessful: (state, action) => {
      state.isLoading = false
      state.data = action.payload
      state.error = null
    },

    createOrderFailed: (state, action) => {
      state.isLoading = false
      state.data = {}
      state.error = action.payload
    },
  },
})

export const {
  createOrderRequested,
  createOrderSuccessful,
  createOrderFailed,
} = createOrderSlice.actions
export default createOrderSlice.reducer
