import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import HealthInsuredDashboardStructure from '../../HealthInsuredDashboardStructure/HealthInsuredDashboardStructure'
import UserFilter from '../../components/users/userFilter/UserFilter'
import UsersTable from '../../components/users/usersTable/UsersTable'
import './healthInsuredUser.scss'

const HealthInsuredUsers = () => {
  const [activeTab, setActiveTab] = useState({
    active: { isActive: true, code: 1 },
    pending: { isActive: false, code: 2 },
    inactive: { isActive: false, code: 3 },
  })

  const [filter, setFilter] = useState({
    searchText: '',
    pageNumber: 1,
    sortBy: 0,
    filter: 0,
  })

  const dispatch = useDispatch()
  const profiles = useSelector((state) => state.getInsuranceProfiles)

  useEffect(() => {
    const { active, pending, inactive } = activeTab
    dispatch({
      type: 'GET_INSURANCE_PROFILES',
      payload: {
        status: active.isActive
          ? active.code
          : pending.isActive
          ? pending.code
          : inactive.code,
        ...filter,
      },
    })
  }, [filter, dispatch, activeTab])

  const handleSearch = (e) => {
    setFilter({ ...filter, searchText: e.target.value })
  }

  const handleProviders = (code) => {
    setFilter({ ...filter, filter: Number(code) })
  }

  const handleGoToPrevPage = () => {
    if (profiles && profiles.data && profiles.data.pageNumber <= 1) {
      return
    } else {
      setFilter({ ...filter, pageNumber: filter.pageNumber - 1 })
    }
  }

  const handleGoToNextPage = () => {
    if (
      profiles &&
      profiles.data &&
      profiles.data.pageNumber === profiles.data.pageCount
    ) {
      return
    } else {
      setFilter({ ...filter, pageNumber: filter.pageNumber + 1 })
    }
  }

  return (
    <HealthInsuredDashboardStructure title="Users">
      <section className="healthInsuredAdmin__users">
        <div>
          <UserFilter
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            handleSearch={handleSearch}
            searchText={filter.searchText}
            handleProviders={handleProviders}
          />
        </div>
        <UsersTable
          handleGoToPrevPage={handleGoToPrevPage}
          handleGoToNextPage={handleGoToNextPage}
          pageDetails={profiles.hasOwnProperty('data') ? profiles.data : {}}
        />
      </section>
    </HealthInsuredDashboardStructure>
  )
}

export default HealthInsuredUsers
