import React from 'react'
import { withRouter } from 'react-router-dom'
import { IoIosArrowDropright } from 'react-icons/io'
import styled from 'styled-components'

import otherServicesImg from '../../../assets/healthBancLandingPageImgs/otherServicesImg.png'
// import SterlingSun from '../../../assets/healthBancLandingPageImgs/sterlingSun.png'

// import { GetStartedBtn, LearnMoreBtn } from './PharmHubIntro'

const Img = styled.img`
  height: auto;
`

export const LearnMoreBtn = styled.button`
  color: #d71e1b;
  border: none;
  background-color: inherit;
  font-weight: 500;
  font-size: 16px;
`

// const AnotherServiceContainer = styled.div`
//     /* margin-top: 200px; */
// `

const PHDesc = styled.div`
  height: 400px;
  /* max-width: 500px; */
  background-size: contain;
  background-position: right;

  div.features {
    display: inline-block;
    font-size: 14px;
    color: #d71e1b;
    margin: 10px 10px;
    background: rgba(215, 30, 27, 0.05);
    padding: 8px;
    border-radius: 3px;
  }

  p.heading {
    font-size: 28px;
    font-weight: bold;
    color: #0c162c;
    max-width: 450px;
    line-height: 130%;
  }

  p.desc {
    max-width: 390px;
    font-size: 16px;
    line-height: 150%;
    color: #474747;
  }

  /* .coming__soon {
		font-weight: 700;
		margin-right: 20px;
	} */
`

const DigitalHospital = ({ history }) => {
  return (
    <div className="d-flex flex-wrap justify-content-around">
      <div className="col-sm-12 col-md-6 order-md-2 order-sm-2">
        <Img src={otherServicesImg} alt="PharmHub_Dash" className="img-fluid" />
      </div>

      <div className="col-sm-12 col-md-6 pe-md-5 d-flex flex-column order-1 order-sm-1">
        <PHDesc className="ms-sm-3">
          <div className="features">Simple-to-use, Revenue Improvement</div>
          <p className="heading mt-4">
            A suite of innovative digital solutions for hospitals and other
            healthcare facilities.
          </p>
          <div></div>
          <p className="desc mt-4">
            We have created a full-service management solution that helps
            simplify your health practice and make it super-efficient.
          </p>

          <div className="d-flex align-items-center">
            {/* <div className='coming__soon text-secondary'>
							Coming soon!
						</div> */}
            <LearnMoreBtn
              className="px-0"
              onClick={() => history.push('/digital-hospital')}
            >
              Learn More <IoIosArrowDropright style={{ fontSize: 25 }} />
            </LearnMoreBtn>

            {/* <LearnMoreBtn className='learnMore d-flexms-3 text-secondary'> */}

            {/* </LearnMoreBtn> */}
          </div>
        </PHDesc>
      </div>
    </div>
  )
}

export default withRouter(DigitalHospital)
