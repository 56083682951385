import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import { useFormik } from 'formik'
import * as yup from 'yup'

import ResponseToast from '../template/ResponseToast'
import RemoveAdmin from './RemoveAdmin'

const changeRoleUrl = '/v2/api/BackendAdminAuth/ChangeAdminRole'
const disableAdminUrl = '/v2/api/BackendAdminAuth/DisableAdmin'
const enableAdminUrl = '/v2/api/BackendAdminAuth/EnableAdmin'

const ManageAdmin = ({
  showManage,
  handleCloseManage,
  admin,
  roles,
  setRefetchAdmin,
}) => {
  const [toastShow, setToastShow] = useState(false)
  const [toastError, setToastError] = useState('')
  const [showRemove, setShowRemove] = useState(false)
  const [update, setUpdate] = useState(false)
  const [loading, setLoading] = useState(false)
  const [token] = useState(localStorage.getItem('adminInfo'))

  // hide toast response
  const handleToastClose = () => setToastShow(false)

  // show remove admin modal
  const handleShowRemove = () => {
    handleCloseManage()
    setShowRemove(true)
  }

  // close remove admin modal
  const handleCloseRemove = () => setShowRemove(false)

  // get all administrator roles and id as option value and name
  const filterOptions =
    roles && roles.map((role) => <option value={role.id}>{role.name}</option>)

  // update administrator role
  const handleSubmit = async (values, handlereSet) => {
    setUpdate(true)

    try {
      await axios.post(`${changeRoleUrl}`, {
        headers: { Authorization: `Bearer ${token}` },
        data: {
          Email: admin.email,
          RoleId: values.roleId,
        },
      })

      setUpdate(false)
      handlereSet()
      setToastShow(true)
      setRefetchAdmin(true)
    } catch (error) {
      // show response toast when error occur
      setUpdate(false)
      setToastShow(true)

      error.response
        ? setToastError(error.response.data.message)
        : setToastError(error.message)
    }
  }

  const validationSchema = yup.object().shape({
    roleId: yup.string().required('Required').trim(),
  })

  const formik = useFormik({
    initialValues: {
      roleId: '',
    },

    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values, formik.handleReset)
    },
  })

  const disableAdmin = async () => {
    setLoading(true)

    try {
      const { data } = await axios.get(`${disableAdminUrl}`, {
        headers: { Authorization: `Bearer ${token}` },
        data: { Email: admin.email },
      })
      setLoading(false)

      setRefetchAdmin(true)
      alert(data.message)
      handleCloseManage()
    } catch (error) {
      setLoading(false)

      error.response ? alert(error.response.data.message) : alert(error.message)
    }
  }

  const enableAdmin = async () => {
    setLoading(true)
    try {
      const { data } = await axios.get(
        `${enableAdminUrl}?email=${admin.email}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      setLoading(false)

      setRefetchAdmin(true)
      alert(data.message)
      handleCloseManage()
    } catch (error) {
      setLoading(false)

      error.response ? alert(error.response.data.message) : alert(error.message)
    }
  }

  return (
    <>
      <RemoveAdmin
        showRemove={showRemove}
        admin={admin}
        setRefetchAdmin={setRefetchAdmin}
        handleCloseRemove={handleCloseRemove}
      />
      <Modal
        show={showManage}
        onHide={handleCloseManage}
        closeButton
        dialogClassName="modal-sz"
        className="add-admin-modal p-4"
      >
        <Modal.Body className="d-flex flex-column">
          <ResponseToast
            toastShow={toastShow}
            handleToastClose={handleToastClose}
            toastError={toastError}
          />
          <h1 className="add-admin">Manage admin</h1>

          <div className="d-flex flex-column text-justify justify-content-between align-items-center py-1 px-2 admin-name-email">
            <p
              className="name mb-0 text-justify"
              style={{ whiteSpace: 'nowrap' }}
            >
              {admin.firstName} {admin.lastName}
            </p>
            <p className="email mb-0 text-justify">{admin.email}</p>
          </div>

          <form className="p-0" onSubmit={formik.handleSubmit} id="update-role">
            <div className="d-flex flex-column">
              <label className="add-labels">Assign role</label>
              <select
                className="add-inputs p-1"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="roleId"
                value={formik.values.roleId}
              >
                <option></option>
                {filterOptions}
              </select>
              {formik.touched.roleId && formik.errors.roleId ? (
                <div aria-labelledby="error message" className="text-danger">
                  <small>*{formik.errors.roleId}</small>
                </div>
              ) : null}
            </div>

            {/* <p className="remove" onClick={handleShowRemove}>
              Remove Admin?
            </p> */}
          </form>
        </Modal.Body>
        <Modal.Footer className="add-footer">
          <button className="btn-cancel" onClick={handleCloseManage}>
            cancel
          </button>
          <button className="btn-send" form="update-role">
            {update ? 'Updating...' : 'Update'}
          </button>
          <button
            className="btn-send"
            onClick={admin.disabled ? enableAdmin : disableAdmin}
          >
            {admin.disabled ? 'Enable' : 'Disable'}
            {loading && '...'}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ManageAdmin
