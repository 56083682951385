import React from 'react';
import { AiOutlinePlus } from 'react-icons/ai';

const NewButton = ({ openCreateModal }) => {
  return (
    <div className='new-button'>
      <button className='icon-container' onClick={openCreateModal}>
        <AiOutlinePlus />
      </button>
    </div>
  );
};

export default NewButton;
