import React from 'react';

const Button = ({ text, handler = null, disable }) => {
  return (
    <button
      className='choose__provider-button'
      type='button'
      onClick={handler ? () => handler() : null}
      disabled={disable}
    >
      {text}
    </button>
  );
};

export default Button;
