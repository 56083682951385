import {
  HEALTH_INSURED_INDIVIDUAL_SIGNUP_DETAILS,
  RESET_HEALTH_INSURED_INDIVIDUAL_SIGNUP_DETAILS,
} from '../../actions/healthInsuredTypes/healthInsuredIndividualSignupDetails'

const INITIAL_STATE = {
  DateOfBirth: '',
  Gender: '',
  MaritalStatus: '',
  Occupation: '',
  ContactAddress: '',
  StateOfResidence: '',
  TownOfResidence: '',
  UserImage: {},
  Identification: '',
  IdentityPhoto: {},
  CareProviderName: '',
  CPPhone: '',
  CPEmail: '',
  CPAddress: '',
  CPCity: '',
  PlanCode: '',
}

export const healthInsuredSignupDetails = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HEALTH_INSURED_INDIVIDUAL_SIGNUP_DETAILS:
      return { ...state, ...action.payload }

    case RESET_HEALTH_INSURED_INDIVIDUAL_SIGNUP_DETAILS:
      return { ...INITIAL_STATE }

    default:
      return state
  }
}
