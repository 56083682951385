import React from 'react'
import { Card, Form } from 'react-bootstrap'
import DoughnutChart from './DoughnutChart'
import ServicePercent from './ServicePercent'

const BreakdownServices = ({ serviceBreakdowns, filterBreakdownbyservice }) => {
  // get number of users per service
  const serviceCount =
    serviceBreakdowns &&
    serviceBreakdowns.map((serviceBreakdown) => {
      return serviceBreakdown.count
    })

  //get names of services
  const serviceName =
    serviceBreakdowns &&
    serviceBreakdowns.map((serviceBreakdown) => {
      return serviceBreakdown.name
    })

  return (
    <Card>
      <Card.Body className="py-5">
        <div className="d-flex justify-content-between mb-4">
          <p className="breakdown-service-p">User Breakdown By Service</p>
          <Form.Group>
            <Form.Control
              as="select"
              size="sm"
              onChange={filterBreakdownbyservice}
            >
              <option value="">All Time</option>
              <option value="1">Yearly</option>
              <option value="2">Weekly</option>
            </Form.Control>
          </Form.Group>
        </div>
        {/* doughnut component */}
        <div className="d-flex">
          <div className="me3">
            <DoughnutChart serviceBreakdowns={serviceBreakdowns} />
          </div>
          <div>
            {/* percent component */}
            <div className="d-flex">
              <ServicePercent
                borderC="5px solid rgba(235, 87, 87, 0.1)"
                backgroundC="#eb5757"
                serviceName={serviceName[0]}
                amount={serviceCount[0]}
                percent={serviceCount[0] / 100}
              />
              <ServicePercent
                borderC="5px solid rgba(83, 44, 96, 0.1)"
                backgroundC="#532c60"
                serviceName={serviceName[1]}
                amount={serviceCount[1]}
                percent={serviceCount[1] / 100}
              />
            </div>
            {/* <div className='d-flex'>
            <ServicePercent
              borderC='5px solid rgba(166, 48, 160, 0.1)'
              backgroundC='#A630A0'
              serviceName='Service'
              amount='743,645'
              percent='63'
            />
            <ServicePercent
              borderC='5px solid rgba(32, 164, 193, 0.1)'
              backgroundC='#20A4C1'
              serviceName='Service'
              amount='345,645'
              percent='45'
            />
          </div> */}
          </div>
        </div>
      </Card.Body>
    </Card>
  )
}

export default BreakdownServices
