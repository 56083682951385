import { call, put, takeEvery } from 'redux-saga/effects'
import {
  ACTIVATE_FAMILY_MEMBER_FAILED,
  ACTIVATE_FAMILY_MEMBER_REQUESTED,
  ACTIVATE_FAMILY_MEMBER_SUCCESSFUL,
} from '../../../reducer/healthInsuredReducer/healthInsuredFamily/activateFamilyMemberReducer'
import { activateFamilyMemberAPI } from './familyApis'

function* handleActivate({ payload = {} }) {
  try {
    yield ACTIVATE_FAMILY_MEMBER_REQUESTED()

    const { data } = yield call(activateFamilyMemberAPI, payload)

    alert(data.message)
    yield put(ACTIVATE_FAMILY_MEMBER_SUCCESSFUL(data))
  } catch (error) {
    if (error.response) {
      alert(error.response.data.message)
      return yield put(
        ACTIVATE_FAMILY_MEMBER_FAILED(error.response.data.message)
      )
    }

    alert(error.message)
  }
}

export function* watchActivateFamilyMember() {
  yield takeEvery(ACTIVATE_FAMILY_MEMBER_REQUESTED.type, handleActivate)
}
