import React from 'react';

const TicketNotification = ({
  title,
  number
}) => {
  return (
    <div className='health__insured__admin__support__tickect__notification'>
      <p className='health__insured__admin__support__tickect__notification--para'>{title}New Tickets</p>
      <span className='health__insured__admin__support__tickect__notification--span'>{number}24</span>
    </div>
  );
};

export default TicketNotification;

