import { SIGNUP_REQUEST, SIGNUP_SUCCESS, SIGNUP_FAILED } from './actionTypes'

export const signupRequest = () => {
  return { type: SIGNUP_REQUEST }
}

export const signupSuccess = (response) => {
  return {
    type: SIGNUP_SUCCESS,
    payload: response,
  }
}

export const signupFailed = (response) => {
  return {
    type: SIGNUP_FAILED,
    payload: response,
  }
}
