import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css'
import { FaCheckCircle } from 'react-icons/fa'
import { GiMedicines } from 'react-icons/gi'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import productSampleImg from '../../assets/storeImgs/product-sample.png'
import { addCartItemRequested } from '../../../../redux/healthBanc/reducer/oneDrugStore/user/cartItemsSlices'
import './product.scss'

const Product = ({ drug, cartItems, setError }) => {
  const dispatch = useDispatch()

  const handleAddToCart = (id, price) => {
    const isAlreadyInCart = cartItems.find((item) => item.drugItemId === id)

    if (isAlreadyInCart) {
      setError(true)
    } else {
      dispatch(addCartItemRequested({ drugItemId: id, quantity: 1, price }))
    }
  }

  return (
    <div className="product-box px-2 px-md-3 mb-4">
      {/* <ToastContainer /> */}
      <div className="product-availability">
        {drug.stock > 0 ? (
          <>
            <FaCheckCircle />
            <span className="product-availability--text ">in stock</span>
          </>
        ) : (
          <>
            <AiOutlineCloseCircle className="text-danger" />
            <span className="product-availability--text text-danger ">
              out of stock
            </span>
          </>
        )}
      </div>
      <div className="product__image mx-auto">
        {drug.image ? (
          <img src={drug.image} alt={drug.name} className="img-fluid" />
        ) : (
          <div
            className="d-flex align-items-center justify-content-center h-100"
            style={{ border: '1px solid gray', borderRadius: '4px' }}
          >
            <GiMedicines size="70px" className="text-secondary" />
          </div>
        )}
      </div>
      <div className="product__name mt-2 ">{drug.name}</div>
      <div className="product__price">
        &#8358;{`${drug.price.toLocaleString()}`}
      </div>
      <button
        className="product__review mt-2"
        onClick={() => handleAddToCart(drug.id, drug.price)}
      >
        Add to cart
      </button>
    </div>
  )
}

export default Product
