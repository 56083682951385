import React from 'react';
import { GoSearch } from 'react-icons/go';

const SearchInput = ({
  handleChange,
  searchName,
  searchTerms,
  className
}) => {

  const searchSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className='health__insured__admin__providers__search'>
      <form
        className={`health__insured__admin__providers__search--form ${className}`}
        onSubmit={searchSubmit}
      >
        <label className='health__insured__admin__providers__search--label'>
          <i>
            <GoSearch className='health__insured__admin__providers__search--icon' />
          </i>

          <input
            type='search'
            name={searchName === 'undefine' ? 'search' : searchName}
            className='health__insured__admin__providers__search--input' placeholder='Search for Users'
            value={searchTerms}
            onChange={handleChange}
            incremental
            autoComplete={true}
          />
        </label>
      </form>
    </div>
  );
};

export default SearchInput;
