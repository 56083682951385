const INITIAL_STATE = {
  adminId : ""
}

export const adminIdReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case "ADMIN_ID" : 
      return {...state, adminId: action.id}

    default : return state
  }
}