import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: {},
  error: null,
}

const getAllDrugItemSlice = createSlice({
  name: 'getAllDrugItem',
  initialState,
  reducers: {
    getAllDrugItemRequested: (state) => {
      state.isLoading = true
      state.data = {}
      state.error = null
    },

    getAllDrugItemSuccessful: (state, action) => {
      state.isLoading = false
      state.data = action.payload
      state.error = null
    },

    getAllDrugItemFailed: (state, action) => {
      state.isLoading = false
      state.data = {}
      state.error = action.payload
    },
  },
})

export const {
  getAllDrugItemRequested,
  getAllDrugItemSuccessful,
  getAllDrugItemFailed,
} = getAllDrugItemSlice.actions

export default getAllDrugItemSlice.reducer
