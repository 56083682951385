import {
  GET_HEALTHINSURED_ADMIN_ERROR_LOGS_FAILED,
  GET_HEALTHINSURED_ADMIN_ERROR_LOGS_REQUEST,
  GET_HEALTHINSURED_ADMIN_ERROR_LOGS_SUCCESS,
} from '../../healthInsuredTypes/healthInsuredAdminActionTypes'

export const getHealthInsuredAdminErrorLogsRequest = () => ({
  type: GET_HEALTHINSURED_ADMIN_ERROR_LOGS_REQUEST,
})

export const getHealthInsuredAdminErrorLogsSuccess = (data) => ({
  type: GET_HEALTHINSURED_ADMIN_ERROR_LOGS_SUCCESS,
  payload: data,
})

export const getHealthInsuredAdminErrorLogsFailed = (errorMessage) => ({
  type: GET_HEALTHINSURED_ADMIN_ERROR_LOGS_FAILED,
  payload: errorMessage,
})
