import axios from 'axios'

//@desc     Create family plan for signed up user
//method    GET
export const createFamilyProfileAPI = async () => {
  const { data } = await axios({
    method: 'POST',
    url: '/v2/api/FamilyInsurance/CreateFamilyProfile',
    data: { Axamansard: 'Axamansard' },
  })

  return data
}

//@desc     Add family member under signed up user
//method    POST
export const addFamilyMemberAPI = async (data) => {
  return await axios({
    method: 'post',
    url: '/v2/api/FamilyInsurance/CreateInsuranceProfileForFamilyMember',
    data,
  })
}

//@desc     Get family member under signed up user
//method    POST
export const getFamilyMembersAPI = async (data) => {
  const { pageNumber = 1, searchValue = '', SortBy, Filter, Status } = data

  return await axios({
    method: 'post',
    url: `/v2/api/FamilyInsurance/GetFamilyMembers`,
    data: { SearchText: searchValue, PageNumber: pageNumber },
  })
}

//@desc     remove family member under signed up user
//method    POST
export const removeFamilyMemberAPI = async (id) => {
  return await axios({
    method: 'post',
    url: `/v2/api/FamilyInsurance/RemoveFamilyMember`,
    data: { FamiyMemeberId: id },
  })
}

//@desc     Activate family member
//method    GET
export const activateFamilyMemberAPI = async (id) => {
  return await axios({
    method: 'POST',
    url: `/v2/api/Tokenization/ActivateFamilyMember`,
    data: {
      Id: id,
    },
  })
}

//@desc     Dectivate family member
//method    GET
export const deactivateFamilyMemberAPI = async (id) => {
  return await axios({
    method: 'POST',
    url: `/v2/api/Tokenization/DeactivateFamilyMember`,
    data: {
      Id: id,
    },
  })
}
