import { takeEvery, put } from 'redux-saga/effects'
import axios from 'axios'
import {
  getHealthInsuredAdminTransactionLogsFailed,
  getHealthInsuredAdminTransactionLogsRequest,
  getHealthInsuredAdminTransactionLogsSuccess,
} from '../../actions/healthInsuredCreators/adminUserActions/getHealthInsuredAdminTransactionLogs'

function* getTransactionLog({ payload }) {
  const { email = '', pageNumber = 1, searchText = '', status = 0 } = payload

  yield put(getHealthInsuredAdminTransactionLogsRequest())

  try {
    const { data } = yield axios({
      method: 'post',
      url: `/v2/api/Insurance/GetPaginatedTransactionLogs`,
      data: {
        SearchText: searchText,
        PageNumber: pageNumber,
        Email: email,
        Status: status === 0 ? '' : status,
      },
      headers: { Authorization: `Bearer ${localStorage.getItem('adminInfo')}` },
    }).then((response) => response)

    yield put(getHealthInsuredAdminTransactionLogsSuccess(data))
  } catch (error) {
    yield put(getHealthInsuredAdminTransactionLogsFailed(error.response))
  }
}

export function* watchGetHealthInsuredAdminTransactionLog() {
  yield takeEvery('GET_HEALTHINSURED_ADMIN_TRANSACTION_LOG', getTransactionLog)
}
