import {
  SUBMIT_CORPORATE_CARD_OTP_FAILED,
  SUBMIT_CORPORATE_CARD_OTP_REQUEST,
  SUBMIT_CORPORATE_CARD_OTP_SUCCESS,
} from '../../../actions/healthInsuredTypes/corporateActionTypes'

export const submitCorporateCardOtpReducer = (state = {}, action) => {
  switch (action.type) {
    case SUBMIT_CORPORATE_CARD_OTP_REQUEST:
      return { isLoading: true }

    case SUBMIT_CORPORATE_CARD_OTP_SUCCESS:
      window.location.replace('/health_corporate/beneficiaries')
      return { isLoading: false, ...action.payload }

    case SUBMIT_CORPORATE_CARD_OTP_FAILED:
      if (
        action.payload &&
        action.payload.data &&
        action.payload.data.Message
      ) {
        // Error msg with uppercase 'M'
        alert(action.payload.data.Message)
      } else if (
        action.payload &&
        action.payload.data &&
        action.payload.data.message
      ) {
        // Error msg with lowercase 'm'
        alert(action.payload.data.message)
      } else {
        alert('An Error occured. \nPlease try again or contact support.')
      }
      return { isLoading: false, errorMessage: action.payload }
    default:
      return state
  }
}
