import React from 'react';

import Button from '../btn/BUtton';
import ProfileDp from '../../../../../../assets/healthInsuredAdmin/adminProfile.png';

const TicketBody = () => {
  return (
    <div className='health__insured__admin__support__notification__body'>
      <h4 className='health__insured__admin__support__notification__body--heading'>Request for Onboarding</h4>
      <p className='health__insured__admin__support__notification__body--para'>
        it has been long established fact that a reader will be
        distracted by the readable content of a page when looking
        at its layout. The point of using Lorem Ipsum is that it
        has a more-or-less normal distribution of letters.
      </p>

      <div className='health__insured__admin__support__notification__body--container-img'>
        <img src={ProfileDp} alt='profile picture' className='health__insured__admin__support__notification__body--img' />
      </div>

      <Button />
    </div>
  );
};

export default TicketBody;
