import {
  GET_CORPORATE_USER_FAILED,
  GET_CORPORATE_USER_REQUEST,
  GET_CORPORATE_USER_SUCCESS,
} from '../../../actions/healthInsuredTypes/corporateActionTypes'

export const getCorporateUserReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CORPORATE_USER_REQUEST:
      return { isLoading: true }

    case GET_CORPORATE_USER_SUCCESS:
      return { isLoading: false, ...action.payload.data }

    case GET_CORPORATE_USER_FAILED:
      return { isLoading: false, ...action.payload }
    default:
      return state
  }
}
