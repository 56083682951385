import {
  VERIFY_CORPORATE_OTP_FAILED,
  VERIFY_CORPORATE_OTP_REQUEST,
  VERIFY_CORPORATE_OTP_SUCCESS,
} from '../../../actions/healthInsuredTypes/corporateActionTypes'

export const verifyCorporateUserOtpReducer = (state = {}, action) => {
  switch (action.type) {
    case VERIFY_CORPORATE_OTP_REQUEST:
      return { isLoading: true }

    case VERIFY_CORPORATE_OTP_SUCCESS:
      state = { isLoading: false, ...action.payload }
      window.location.replace('/health_corporate/welcome')
      alert(action.payload.message)
      return state

    case VERIFY_CORPORATE_OTP_FAILED:
      alert(action.payload.data.message)
      return { isLoading: false, errorMessage: action.payload }
    default:
      return state
  }
}
