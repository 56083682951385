import React from 'react';

const EmtyUser = () => {
  return (
    <section className='hb-main-section hb-registered-users p-4 d-flex flex-column align-items-center justify-content-center'>
      <h2>Nothing to see here</h2>
      <p>A list of all users will appear here once they are available.</p>
    </section>
  );
};

export default EmtyUser;
