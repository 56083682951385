import { takeEvery, put, call } from 'redux-saga/effects'
import {
  ADD_FRIEND_WITH_DETAILS_FAILED,
  ADD_FRIEND_WITH_DETAILS_REQUESTED,
  ADD_FRIEND_WITH_DETAILS_SUCCESSFUL,
} from '../../../reducer/healthInsuredReducer/addFriendWithDetailsReducer'
import { addFriendWithDetailsAPI } from './friendsAPIs'

function* handleAddFriendWithDetails({ payload }) {
  try {
    yield ADD_FRIEND_WITH_DETAILS_REQUESTED()

    const { data } = yield call(addFriendWithDetailsAPI, payload.formData)

    yield put(ADD_FRIEND_WITH_DETAILS_SUCCESSFUL(data))

    alert(data.message)

    yield payload.resetForm()

    yield window.location.replace('/health-friends/add-friend')
  } catch (error) {
    if (error.response) {
      alert(error.response.data.message)

      return yield put(
        ADD_FRIEND_WITH_DETAILS_FAILED(error.response.data.message)
      )
    }

    alert(error.message)
  } finally {
    payload.setSubmitting(false)
  }
}

export function* watchAddFriendWithDetails() {
  yield takeEvery(
    ADD_FRIEND_WITH_DETAILS_REQUESTED.type,
    handleAddFriendWithDetails
  )
}
