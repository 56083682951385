import React from 'react'
import { IoMdRadioButtonOn, IoIosRadioButtonOff } from 'react-icons/io'
import { AiFillCheckCircle } from 'react-icons/ai'
import { MdKeyboardBackspace } from 'react-icons/md'
import { useSelector } from 'react-redux'

import Header from '../template/header/Header'
import CustomSelectInput from '../template/customSelect/CustomeSelectput'
import CustomButton from '../template/customButton/CustomeButton'
import { insuranceProvider } from '../../data/insuranceProvider'

const ProviderDetails = ({
  userDetails,
  handleChange,
  handlePrevious,
  healthCareProviders,
  error,
  errorMessage,
  createProfile,
  loading,
}) => {
  const provider = useSelector((state) => state.serviceProvider)
  const { referee } = useSelector((state) => state.profileCompletionStatus.data)

  const healthCareProviderOption =
    healthCareProviders.length !== 0 &&
    healthCareProviders.map((careProvider, index) => {
      return (
        <option
          key={index}
          value={`${careProvider.hospitalName}:${careProvider.address}:${careProvider.city}`}
        >
          {careProvider.hospitalName}
        </option>
      )
    })

  return (
    <div className="axa-mansard-sign-up-provider-details">
      <Header />

      <div className="form-container">
        <div className="personal-details-form">
          <form className="personal-form" onSubmit={(e) => createProfile(e)}>
            <h1 className="start-your">Start your 1-month free trial</h1>
            <p className="provide-some mb-4">
              Provide some basic information to get access too all our benefits.
            </p>

            <p className="previous-text" onClick={handlePrevious}>
              <i>
                <MdKeyboardBackspace className="previous-icon me3" />
              </i>{' '}
              Previous
            </p>

            <CustomSelectInput
              labelName={'Select a primary care provider'}
              handleChange={handleChange}
              inputValue={userDetails.CareProviderName}
              selectOptions={healthCareProviderOption}
              inputName="CareProviderName"
              errorMessage={errorMessage.CareProviderNameError}
            />

            {provider === insuranceProvider.hygeia && (
              <menu className="roaming">
                Roaming is available when not in your domiciliary location by
                tendering your enrolee number
              </menu>
            )}
            <menu
              className={
                error
                  ? 'error-message error-message-show text-danger'
                  : 'error-message'
              }
            >
              *{error}
            </menu>

            <CustomButton
              buttonText={loading ? 'Creating profile...' : 'Create profile'}
              disable={loading ? true : false}
            />
          </form>
        </div>

        <div className="progres">
          <ul>
            <li>
              <AiFillCheckCircle className="icon me3 fulfilled" />
              <span className="inactive active-two">Personal Information</span>
            </li>

            <hr className="line-progress" />
            {!referee && (
              <>
                <li>
                  <AiFillCheckCircle className="icon me3 fulfilled" />
                  <span className="inactive active-two">Subscription</span>
                </li>
                <hr className="line-progress-two" />
              </>
            )}

            <li>
              <IoMdRadioButtonOn className="icon me3 fulfilled" />
              <span className="active">Care provider Information</span>
            </li>

            {!referee && (
              <>
                <hr className="line-progress-three" />
                <li>
                  <IoIosRadioButtonOff className="icon me3 inactive" />
                  <span className="inactive">Payment</span>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default ProviderDetails
