import {
  MOVE_INACTIVE_BENEFICIARIES_TO_PENDING_LIST_FAILED,
  MOVE_INACTIVE_BENEFICIARIES_TO_PENDING_LIST_REQUEST,
  MOVE_INACTIVE_BENEFICIARIES_TO_PENDING_LIST_SUCCESS,
} from '../../../actions/healthInsuredTypes/corporateActionTypes'

export const moveInactiveBeneficiariesToPendingListReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case MOVE_INACTIVE_BENEFICIARIES_TO_PENDING_LIST_REQUEST:
      return { isLoading: true }

    case MOVE_INACTIVE_BENEFICIARIES_TO_PENDING_LIST_SUCCESS:
      return { isLoading: false, ...action.payload }

    case MOVE_INACTIVE_BENEFICIARIES_TO_PENDING_LIST_FAILED:
      return { isLoading: false, errorMessage: action.payload }

    default:
      return state
  }
}
