import { takeLatest, put } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import axios from 'axios'
import { store } from '../../../../store'
import {
  addCartItemRequested,
  addCartItemSuccessful,
  addCartItemFailed,
  getCartItemsRequested,
} from '../../../reducer/oneDrugStore/user/cartItemsSlices'

function* addCartItemAsync({ payload }) {
  const isAuthUser = store.getState().user.userInfo.isAuth

  yield addCartItemRequested()

  try {
    if (isAuthUser) {
      const { data } = yield axios({
        method: 'GET',
        url: `${process.env.REACT_APP_ONE_DRUG_BASE}/v1/api/Cart/Add?drugItemId=${payload.drugItemId}`,
      })

      yield put(addCartItemSuccessful(data))
    } else {
      const currentCartItems =
        JSON.parse(window.localStorage.getItem('oneDrugCart')) || []
      window.localStorage.setItem(
        'oneDrugCart',
        JSON.stringify([...currentCartItems, payload])
      )
      yield put(addCartItemSuccessful(payload))
    }
    yield put(getCartItemsRequested())

    yield toast.success('Item added to cart successfully.')
  } catch (error) {
    if (error.response) {
      yield put(addCartItemFailed(error.response.data))
      yield put(addCartItemFailed(null))
      return
    }

    yield put(addCartItemFailed(error.message))
    yield put(addCartItemFailed(null))
  }
}

export function* watchAddCartItem() {
  yield takeLatest(addCartItemRequested.type, addCartItemAsync)
}
