import React from 'react';
import { Modal, Table } from 'react-bootstrap';
import { IoIosArrowDropdown } from 'react-icons/io';

const UserDetails = ({ show, handleClose, user }) => {
  return (
    <>
      <Modal show={show} onHide={handleClose} className='user-details-modal' scrollable>
        <Modal.Header className='modal-header ' closeButton>
          <Modal.Title><h1 className='modal-title'>User details</h1></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <details>
            <summary className='user-details-summary d-flex justify-content-between align-items-center pb-3'>
              <span>Personal details</span><i><IoIosArrowDropdown className='arrow' /></i>
            </summary>
            <Table hover size="md" responsive='sm'>
              <tbody hb-admin-table-b >
                <tr>
                  <td>Title</td>
                  <td>{user.title}</td>
                </tr>

                <tr className='tt'>
                  <td>First Name</td>
                  <td>{user.firstName}</td>
                </tr>

                <tr>
                  <td >Last Name</td>
                  <td>{user.lastName}</td>
                </tr>

                <tr>
                  <td>Email</td>
                  <td>{user.email}</td>
                </tr>

                <tr>
                  <td>Phone</td>
                  <td>{user.phoneNumber}</td>
                </tr>

                <tr>
                  <td >Service</td>
                  <td>{user.serviceUsed}</td>
                </tr>
              </tbody>
            </Table>
          </details>
        </Modal.Body >
      </Modal >
    </>
  );
};

export default UserDetails;
