import { takeLatest, put } from 'redux-saga/effects'
import axios from 'axios'
import { toast } from 'react-toastify'
import { store } from '../../../../store'
import {
  uploadCartItemPrescriptionRequested,
  uploadCartItemPrescriptionSuccessful,
  uploadCartItemPrescriptionFailed,
  getCartItemsRequested,
} from '../../../reducer/oneDrugStore/user/cartItemsSlices'

function* uploadCartItemPrescriptionAsync({ payload }) {
  const isAuthUser = store.getState().user.userInfo.isAuth

  yield uploadCartItemPrescriptionRequested()

  try {
    if (isAuthUser) {
      const { data } = yield axios({
        url: `${process.env.REACT_APP_ONE_DRUG_BASE}/v1/api/Cart/UploadPrescription`,
        method: 'POST',
        data: {
          cartId: Number(payload.id),
          image: payload.prescription,
        },
      })
      yield put(uploadCartItemPrescriptionSuccessful(data))
    } else {
      const cartList = JSON.parse(window.localStorage.getItem('oneDrugCart'))

      const updatedList = cartList.map((item) =>
        item.drugItemId === payload.itemId
          ? { ...item, prescription: payload.prescription }
          : item
      )

      window.localStorage.setItem('oneDrugCart', JSON.stringify(updatedList))

      yield put(uploadCartItemPrescriptionSuccessful(updatedList))
    }

    yield toast.success('Prescription uploaded successfully!')

    yield put(getCartItemsRequested())
    yield payload.history.push('/OneDrugStore/cart')
  } catch (error) {
    if (error.response) {
      yield put(uploadCartItemPrescriptionFailed(error.response.data))
      yield put(uploadCartItemPrescriptionFailed(null))
      return
    }

    yield put(uploadCartItemPrescriptionFailed(error.message))
    yield put(uploadCartItemPrescriptionFailed(null))
  }
}

export function* watchUploadCartItemPrescription() {
  yield takeLatest(
    uploadCartItemPrescriptionRequested.type,
    uploadCartItemPrescriptionAsync
  )
}
