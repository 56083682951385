import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import axios from 'axios'

import CardProviderSection from '../careProviderSection/CareProviderSection'
import NavBar from '../../navbar/NavBar'
import Alert from '../../../../../../healthBancServices/healthInsured/template/alert/ALert'
import Loader from '../../../../../pharmHub/admin/components/Spinner/Spinner'
import { getHealthInsuredIndividualProfileSuccess } from '../../../../../../redux/healthBanc/actions/healthInsuredCreators/healthInsuredIndividualProfile'
import { profileCompletionSuccess } from '../../../../../../redux/healthBanc/actions/healthInsuredCreators/profileCompletionStatus'
import { useHealthcareProviders } from './useHealthcareProviders'
const customerStateUrl = '/v2/api/Insurance/GetProfileCompletion'
const profileUrl = '/v2/api/Insurance/GetUserInsuranceProfile'
const statesUrl = '/v2/api/Insurance/GetState'
const getTownsUrl = '/v2/api/Insurance/GetTowns'
const getHealthCareProviderUrl =
  '/v2/api/Insurance/FilterHygeiaHealthCareProvider'

const MainSection = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [profile, setProfile] = useState({})
  const [error, setError] = useState(' ')
  const [states, setStates] = useState([])

  useEffect(() => {
    function getProfile() {
      return axios.get(profileUrl)
    }

    function getUserStatus() {
      return axios.get(customerStateUrl)
    }

    async function getStates() {
      const states = await axios.get(statesUrl)
      return states
    }

    axios
      .all([getProfile(), getUserStatus(), getStates()])
      .then(
        axios.spread(
          (
            profileResponse,
            profileCompletionStatusResponse,
            statesResponse
          ) => {
            const { profileCompleted, tokenizationCompleted } =
              profileCompletionStatusResponse.data.data

            dispatch(
              profileCompletionSuccess(
                profileCompletionStatusResponse.data.data
              )
            )

            // // check if a user has signed up and tokenized
            if (profileCompleted === false || tokenizationCompleted === false) {
              history.push('/health_signup')
            }

            setProfile(profileResponse.data.data)
            getHealthInsuredIndividualProfileSuccess(profileResponse.data.data)
            setStates(statesResponse.data)
          }
        )
      )
      .catch((error) => {
        error.response.data
          ? setError(error.response.data.message)
          : setError(error.message)
      })
  }, [])

  const [searchTerm, setSearchTerm] = useState(' ')
  const [pageSize, setPageSize] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)
  const [careproviderTown, setCareProviderTown] = useState([])
  const [townLoading, setTownLoading] = useState(false)
  const [getTownError, setGetTownError] = useState(' ')
  const [state, setState] = useState(' ')
  const [city, setCity] = useState(' ')
  const [url, setUrl] = useState(
    `${getHealthCareProviderUrl}?PageNumber=${pageNumber}&PageSize=${pageSize}&state=${state}`
  )
  const [insuranceProviders] = useHealthcareProviders(url)

  const {
    error: errors,
    errorMessage,
    loading,
    healthcareProviders: {
      data,
      pageNumber: pagenumber,
      pageCount: pagecount,
      recordCount: recordcount,
    },
  } = insuranceProviders

  useEffect(() => {
    getCity(state)
  }, [state])

  async function getCity(state) {
    try {
      setTownLoading(true)

      const {
        data: { data },
      } = await axios.post(`${getTownsUrl}`, {
        data: {
          InsurancePovider: profile.insuranceService,
          State: state,
        },
      })
      setCareProviderTown(data)

      setTownLoading(false)
    } catch (error) {
      setTownLoading(false)

      error.response.data
        ? setGetTownError(error.response.data.message)
        : setGetTownError(error.message)
    }
  }

  const handleStateChange = (e) => {
    //reset state for accurate result
    setState(e.target.value)
    setError(' ')
    setSearchTerm(false)
    setCity(' ')
    setPageNumber(1)
    setUrl(
      `${getHealthCareProviderUrl}?PageNumber=${pageNumber}&PageSize=${pageSize}&state=${e.target.value}`
    )
  }

  const handleCityChange = (e) => {
    setCity(e.target.value)
    setError(' ')
    setSearchTerm(false)
    setPageNumber(1)
    setUrl(
      `${getHealthCareProviderUrl}?PageNumber=${pageNumber}&PageSize=${pageSize}&state=${state}&city=${e.target.value}`
    )
  }

  const handleSearch = (e) => {
    setSearchTerm(e.target.value)
    setError(' ')
    setCity(' ')
    setState(' ')
    setPageNumber(1)
    setUrl(
      `${getHealthCareProviderUrl}?PageNumber=${pageNumber}&PageSize=${pageSize}&SearchText=${e.target.value}`
    )
  }

  const handlePagination = (pagenumber) => {
    if (pagenumber < 1) return
    if (pagenumber > pagecount) return

    if (searchTerm)
      return setUrl(
        `${getHealthCareProviderUrl}?PageNumber=${pagenumber}&PageSize=${pageSize}&SearchTerm=${searchTerm}`
      )

    setUrl(
      `${getHealthCareProviderUrl}?PageNumber=${pagenumber}&PageSize=${pageSize}&state=${state}&city=${city}`
    )
  }

  const tableRow =
    data.length > 0 &&
    data.map((careprovider) => (
      <tr key={careprovider.id}>
        <td>{careprovider.hospitalName}</td>
        <td>{careprovider.specialisation}</td>
        <td>{careprovider.address}</td>
        <td>{careprovider.city}</td>
        <td>{careprovider.state}</td>
      </tr>
    ))

  const stateOptions =
    states.length === 0 ? (
      <option>N/A</option>
    ) : (
      states.map((state) => (
        <option value={state} key={state}>
          {state}
        </option>
      ))
    )

  const townOption = townLoading ? (
    <Loader />
  ) : (
    careproviderTown.length > 0 &&
    careproviderTown[0].cities.map((towns) => {
      return (
        <option key={towns} value={towns}>
          {towns}
        </option>
      )
    })
  )

  return (
    <main className="axa-mansard-dashboard-mainsection px-3 pb-4">
      <NavBar />
      {error ||
        getTownError ||
        (errors && (
          <Alert variant="danger" showAlert={true}>
            {' '}
            {error}{' '}
          </Alert>
        ))}
      <CardProviderSection
        userProfile={profile}
        states={states}
        stateOptions={stateOptions}
        townOption={townOption}
        tableRow={tableRow}
        handleSearch={handleSearch}
        handlePagination={handlePagination}
        handleCityChange={handleCityChange}
        handleStateChange={handleStateChange}
        healthCareProviders={data}
        loading={loading}
        pageCount={pagecount}
        pageNumber={pagenumber}
        recordCount={recordcount}
      />
    </main>
  )
}

export default MainSection
