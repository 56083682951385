import {
  PROFILE_COMPLETION_STATUS_FAIL,
  PROFILE_COMPLETION_STATUS_REQUEST,
  PROFILE_COMPLETION_STATUS_SUCCESS,
} from '../../actions/healthInsuredTypes/profileCompletionStatus'

const initialState = {
  isLoading: false,
  data: {},
  error: null,
}

export const profileCompletionStatusReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case PROFILE_COMPLETION_STATUS_REQUEST:
      return { loading: true, data: {}, error: null }

    case PROFILE_COMPLETION_STATUS_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        error: null,
      }

    case PROFILE_COMPLETION_STATUS_FAIL:
      return {
        loading: false,
        data: {},
        error: { ...action.payload },
      }

    default:
      return state
  }
}
