import { put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import {
  submitCorporateCardOtpFailed,
  submitCorporateCardOtpRequest,
  submitCorporateCardOtpSuccess,
} from '../../actions/healthInsuredCreators/corporateUserActions/submitCorporateCardOtpActions'

function* submitCorporateCardOtp({ payload }) {
  try {
    yield put(submitCorporateCardOtpRequest())

    const { data } = yield axios
      .post('/v2/api/Tokenization/SubmitOtp', payload)
      .then((response) => response)

    yield put(submitCorporateCardOtpSuccess(data))
  } catch (error) {
    yield put(submitCorporateCardOtpFailed(error.response))
  }
}

export function* watchSubmitCorporateCardOtp() {
  yield takeEvery('SUBMIT_CORPORATE_CARD_OTP', submitCorporateCardOtp)
}
