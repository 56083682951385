import React from 'react';


import HealthInsuredDashboardStructure from '../../HealthInsuredDashboardStructure/HealthInsuredDashboardStructure';
import MainSection from '../../components/support/mainSection/ResolvedTicket';

const ResolvedTicket = () => {
  return (
    <HealthInsuredDashboardStructure title='Support'>
      <MainSection />
    </HealthInsuredDashboardStructure>
  );
};

export default ResolvedTicket;
