import React from 'react';

import ProvidersCard from '../providersCard/ProvidersCard';
import HygeiaLogo from '../../../../../../assets/healthInsuredAdmin/hygiealogo.png';
import UniqueidentifierCard from '../uniqueidentifierCard/UniqueidentifierCard';
import AxamansardLogo from '../../../../../../assets/healthInsuredAdmin/axamansardlogo.png';


const MainSection = () => {
  return (
    <section className='health__Insured__admin__providers__section'>
      <ProvidersCard
        providersName='hygiea/dashboard'
        img={HygeiaLogo}
        alt='hygeia'
      />
      <ProvidersCard
        providersName='axamansard/dashboard'
        img={AxamansardLogo}
        alt='axamansard'
      />
      <UniqueidentifierCard />
    </section>
  );
};

export default MainSection;
