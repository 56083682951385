import {
  BENEFICIARIES_REVIEW_FAILED,
  BENEFICIARIES_REVIEW_REQUEST,
  BENEFICIARIES_REVIEW_SUCCESS,
} from '../../../actions/healthInsuredTypes/corporateActionTypes'

export const beneficiariesReviewReducer = (state = {}, action) => {
  switch (action.type) {
    case BENEFICIARIES_REVIEW_REQUEST:
      return { isLoading: true }

    case BENEFICIARIES_REVIEW_SUCCESS:
      return { isLoading: false, ...action.payload }

    case BENEFICIARIES_REVIEW_FAILED:
      return { isLoading: false, errorMessage: action.payload }
    default:
      return state
  }
}
