import React, { useState } from 'react'
import PaymentDetails from './PaymentDetails'
import './mPro.css'

import { AiFillWallet, AiFillCreditCard } from 'react-icons/ai'
import { useContext } from 'react'
import { PayContext } from './context/PayContext'
import { useDispatch, useSelector } from 'react-redux'
//import { pay_wallet, pay_card } from './redux/PaymentReducer';
import { setPayWithWallet } from '../../../../../redux/healthBanc/reducer/healthInsuredReducer/walletReducer'

const Payment = () => {
  //const [bgColor, setBgColor] = useState(false);
  const dispatch = useDispatch()

  const { payWithWallet } = useSelector((state) => state.payWithWallet)

  return (
    <div className="l-panel">
      <div className="m-div">
        <div className="m-div-p">
          <p className="mp">Pay With</p>
        </div>
        <div className="btns">
          <div className="mdiv-btn-f">
            <button
              className="m-btn-f"
              onClick={() => dispatch(setPayWithWallet(false))}
              style={{
                backgroundColor: payWithWallet ? '#F3F3F4' : '#FEFEFE',
                color: payWithWallet ? '#000000' : '#D71E1B',
              }}
            >
              <AiFillCreditCard fontSize="16px" /> Card
            </button>
          </div>

          <div className="mdiv-btn-f">
            <button
              className="m-btn-f"
              onClick={() => dispatch(setPayWithWallet(true))}
              style={{
                backgroundColor: payWithWallet ? '#FEFEFE' : '#F3F3F4',
                color: payWithWallet ? '#D71E1B' : '#00000',
              }}
            >
              <AiFillWallet fontSize="16px" /> Wallet <span>NEW</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Payment
