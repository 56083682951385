import { all } from 'redux-saga/effects'
import { watchHealthBancSignin } from './healthBanc/sagas/signInSaga'
import { watchSignInRequestOTP } from './healthBanc/sagas/signInConfirmOTPSaga'
import { watchRefreshToken } from './healthBanc/sagas/refreshTokenSaga'
import { watchSignup } from './healthBanc/sagas/signupSaga'

// import { watchGetDashboardRequirements } from './pharmHub/sagas/getDashboardRequirementsSaga'
// import { watchGetBankAccountDetails } from './pharmHub/sagas/getBankAccountDetailsSaga'
// import { watchUserNotifications } from './pharmHub/sagas/userNotificationsSaga'
// import { watchSaveBusinessInfo } from './pharmHub/sagas/businessInfoSaga'
// import { watchRolesAndSignatories } from './pharmHub/sagas/rolesAndSignatoriesSaga'
// import { watchCredentialsUpload } from './pharmHub/sagas/credentialsUploadSaga'
// import { watchAddToCart } from './pharmHub/sagas/addToCartSaga'
// import { watchDeleteFromCart } from './pharmHub/sagas/deleteFromCartSaga'
// import { watchFetchUserProducts } from './pharmHub/sagas/userProductsSaga'
// import { watchGetUserUpline } from './pharmHub/sagas/userUplineSaga'
// import { watchGetUserDownline } from './pharmHub/sagas/userDownlineSaga'
// import { watchRequestToJoin } from './pharmHub/sagas/getRequestToJoinSaga'
// import { watchGetCartItems } from './pharmHub/sagas/getCartItemsSaga'
// import { watchUpdateCartItemQty } from './pharmHub/sagas/updateCartItemQtySaga'
// import { watchGetShowcaseProducts } from './pharmHub/sagas/getShowcaseProductsSaga'
// import { watchGetIncomingOrders } from './pharmHub/sagas/getIncomingOrdersSaga'
// import { watchGetOutgoingOrders } from './pharmHub/sagas/getOutgoingOrdersSaga'
// import { watchFetchCompanyList } from './pharmHub/sagas/fetchCompanyListSaga'

////////////////////
//INDIVIDUAL FRIENDS
import { watchGetFriends } from './healthBanc/sagas/healthInsuredSagas/individualSagas/getFriendsSaga'
import { watchAddFriendByEmail } from './healthBanc/sagas/healthInsuredSagas/individualSagas/addFriendByEmailSaga'
import { watchRemoveFriend } from './healthBanc/sagas/healthInsuredSagas/individualSagas/removeFriendSaga'
import { watchAddFriendWithDetails } from './healthBanc/sagas/healthInsuredSagas/individualSagas/addFriendwithDetailsSaga'
import { watchDeactivateFriend } from './healthBanc/sagas/healthInsuredSagas/individualSagas/deactivateFriendSaga'
import { watchActivateFriend } from './healthBanc/sagas/healthInsuredSagas/individualSagas/activateFriendSaga'

//////////////////////////////////
// GET LOCATION IMPORTS
import { watchGetStates } from './healthBanc/sagas/healthInsuredSagas/locationSagas/getStatesSaga'
import { watchGetTowns } from './healthBanc/sagas/healthInsuredSagas/locationSagas/getTownsSaga'
import { watchGetHealthCenters } from './healthBanc/sagas/healthInsuredSagas/locationSagas/getHealthCentersSaga'

///////////////////
//HEALTH INSURED
import { watchProfileCompletionStatus } from './healthBanc/sagas/healthInsuredSagas/profileCompletionStatusSaga'

/////////////////////////
// INDIVIDUAL
import { watchGetIndividualProfile } from './healthBanc/sagas/healthInsuredSagas/individualSagas/healthInsuredIndividualProfile'

/////////////////////////////////////
// HEALTH INSURED FAMILY
import { watchCreateFamilyProfile } from './healthBanc/sagas/healthInsuredSagas/familySagas/createFamilyProfileSaga'
import { watchAddFamilyMember } from './healthBanc/sagas/healthInsuredSagas/familySagas/addFamilyMemberSaga'
import { watchGetFamilyMembers } from './healthBanc/sagas/healthInsuredSagas/familySagas/getFamilyMembersSaga'
import { watchRemoveFamilyMember } from './healthBanc/sagas/healthInsuredSagas/familySagas/removeFamilyMemberSaga'
import { watchActivateFamilyMember } from './healthBanc/sagas/healthInsuredSagas/familySagas/activateFamilyMemberSaga'
import { watchDeactivateFamilyMember } from './healthBanc/sagas/healthInsuredSagas/familySagas/deactivateFamilyMemberSaga'

// Corporate User Imports
import { watchCreateCorporateUser } from './healthBanc/sagas/healthInsuredSagas/createCorporateUserSaga'
import { watchGetCorporateUser } from './healthBanc/sagas/healthInsuredSagas/getCorporateUserSaga'
import { watchResendCorporateOtp } from './healthBanc/sagas/healthInsuredSagas/resendCorporateOtpSaga'
import { watchVerifyCorporateOtp } from './healthBanc/sagas/healthInsuredSagas/verifyCorporateOtpSaga'
import { watchUpdateCorporateProfile } from './healthBanc/sagas/healthInsuredSagas/updateCorporateProfileSaga'
import { watchUploadCorporateBeneficiaries } from './healthBanc/sagas/healthInsuredSagas/uploadCorporateBeneficiariesSaga'
import { watchBeneficiariesReview } from './healthBanc/sagas/healthInsuredSagas/beneficiariesReviewSaga'
import { watchRemoveBeneficiaryInReview } from './healthBanc/sagas/healthInsuredSagas/removeBeneficiaryInReviewSaga'
import { watchRestoreBeneficiaryInReview } from './healthBanc/sagas/healthInsuredSagas/restoreBeneficiaryInReviewSaga'
import { watchChargeCorporateCard } from './healthBanc/sagas/healthInsuredSagas/chargeCorporateCardSaga'
import { watchSubmitCorporateCardOtp } from './healthBanc/sagas/healthInsuredSagas/submitCorporateCardOtpSaga'
import { watchFetchCorporateBeneficiaries } from './healthBanc/sagas/healthInsuredSagas/fetchCorporateBeneficiariesSaga'
import { watchGetCompanyProfile } from './healthBanc/sagas/healthInsuredSagas/getCompanyProfileSaga'
import { watchGetCorporateAnalytics } from './healthBanc/sagas/healthInsuredSagas/getCorporateAnalyticsSaga'
import { watchCorporateProfileCompletionStatus } from './healthBanc/sagas/healthInsuredSagas/corporateProfileCompletionStatusSaga'
import { watchDeactivateActiveBeneficiaries } from './healthBanc/sagas/healthInsuredSagas/deactivateActiveBeneficiariesSaga'
import { watchMovePendingBeneficiariesToInactiveList } from './healthBanc/sagas/healthInsuredSagas/movePendingBeneficiariesToInactiveListSaga'
import { watchMoveInactiveBeneficiariesToPendingList } from './healthBanc/sagas/healthInsuredSagas/moveInactiveBeneficiariesToPendingListSaga'
import { watchGetCorporateActivityLog } from './healthBanc/sagas/healthInsuredSagas/getCorporateActivityLogSaga'

// HealthInsured ADMIN sagas
import { watchGetHealthInsuredAdminDashboardAnalytics } from './healthBanc/sagas/healthInsuredAdminSagas/getHealthInsuredAdminDashboardAnalyticsSaga'
import { watchGetHealthInsuredUserAcquisition } from './healthBanc/sagas/healthInsuredAdminSagas/healthInsuredUserAcquisitionSaga'
import { watchGetHealthInsuredSubscriberAcquisition } from './healthBanc/sagas/healthInsuredAdminSagas/healthInsuredSubscriberAcquisitionSaga'
import { watchGetInsuranceProfiles } from './healthBanc/sagas/healthInsuredAdminSagas/getInsuranceProfilesSaga'
import { watchGetHealthInsuredAdminTransactionLog } from './healthBanc/sagas/healthInsuredAdminSagas/getHealthInsuredAdminTransactionLogSaga'
import { watchGetHealthInsuredAdminUserActivityLogs } from './healthBanc/sagas/healthInsuredAdminSagas/getHealthInsuredAdminUserActivityLogsSaga'
import { watchGetHealthInsuredAdminActivityLog } from './healthBanc/sagas/healthInsuredAdminSagas/getHealthInsuredAdminActivityLogSaga'
import { watchGetHealthInsuredAdminErrorLogs } from './healthBanc/sagas/healthInsuredAdminSagas/getHealthInsuredAdminErrorLogsSaga'
import { watchGetUserHealthInsuredAdminProfile } from './healthBanc/sagas/healthInsuredAdminSagas/getExtendedUserHealthInsuredAdminProfileSaga'

///////////////////// ---- ONE DRUG STORE ----------- /////////////
//-- aggregator
import {
  watchCreateAggregator,
  watchDeleteAggregator,
  watchGetAggregator,
  watchUpdateAggregator,
} from './healthBanc/sagas/oneDrugStore/aggregator'

// -- pharmacy
import {
  watchCreatePharmacy,
  watchDeletePharmacy,
  watchGetPharmacy,
  watchUpdatePharmacy,
} from './healthBanc/sagas/oneDrugStore/pharmacy'

//-- cart item
import {
  watchAddCartItem,
  watchDeleteCartItem,
  watchGetCartItems,
  watchUpdateCartItemQuantity,
  watchUploadCartItemPrescription,
  watchAddLocalCartItems,
} from './healthBanc/sagas/oneDrugStore/cartItem'

// ---- payment
import { watchCardPayment } from './healthBanc/sagas/oneDrugStore/cardPayment'
import {
  watchWalletPayment,
  watchWalletConfirmOTP,
} from './healthBanc/sagas/oneDrugStore/walletPayment'

//-- delivery info
import {
  watchGetDeliveryInfo,
  watchUpdateDeliveryInfo,
} from './healthBanc/sagas/oneDrugStore/deliveryInfo'

// -- drug category
import {
  watchCreateDrugCategory,
  watchGetDrugCategory,
  watchUpdateDrugCategory,
} from './healthBanc/sagas/oneDrugStore/drugCategory'

// - drug item --
import {
  watchGetAllDrugItem,
  watchCreateDrugItem,
  watchUpdateDrugItem,
  watchDeleteDrugItem,
} from './healthBanc/sagas/oneDrugStore/drugItem'

// -- order
import {
  watchCreateOrder,
  watchGetAllOrders,
  watchGetMyOrders,
} from './healthBanc/sagas/oneDrugStore/order'

import { watchLogout } from './healthBanc/sagas/logoutSaga'

export default function* rootSaga() {
  yield all([
    watchHealthBancSignin(),
    watchSignInRequestOTP(),
    watchRefreshToken(),
    watchSignup(),

    //--------INACTIVE PHARMHUB ACTIONS (deprecated)
    // watchGetDashboardRequirements(),
    // watchUserNotifications(),
    // watchGetBankAccountDetails(),

    // watchSaveBusinessInfo(),
    // watchRolesAndSignatories(),
    // watchCredentialsUpload(),
    // watchGetCartItems(),
    // watchAddToCart(),
    // watchUpdateCartItemQty(),
    // watchDeleteFromCart(),
    // watchFetchUserProducts(),
    // watchGetUserUpline(),
    // watchGetUserDownline(),
    // watchRequestToJoin(),
    // watchGetShowcaseProducts(),
    // watchGetIncomingOrders(),
    // watchGetOutgoingOrders(),
    // watchFetchCompanyList(),

    ////////////////////
    // INDIVIDUAL
    watchGetIndividualProfile(),

    ////////////////////
    //INDIVIDUAL FRIENDS
    watchGetFriends(),
    watchAddFriendByEmail(),
    watchRemoveFriend(),
    watchAddFriendWithDetails(),
    watchDeactivateFriend(),
    watchActivateFriend(),

    //////////////////
    //LOCATIONS
    watchGetStates(),
    watchGetTowns(),
    watchGetHealthCenters(),

    ///////////////////////
    // HEALTH INSURED
    watchProfileCompletionStatus(),

    ////////////////////
    //HEALTH INSURED FAMILY
    watchCreateFamilyProfile(),
    watchAddFamilyMember(),
    watchGetFamilyMembers(),
    watchRemoveFamilyMember(),
    watchActivateFamilyMember(),
    watchDeactivateFamilyMember(),

    // Corporate Insurance Sagas
    watchCreateCorporateUser(),
    watchGetCorporateUser(),
    watchResendCorporateOtp(),
    watchVerifyCorporateOtp(),
    watchUpdateCorporateProfile(),
    watchUploadCorporateBeneficiaries(),
    watchBeneficiariesReview(),
    watchRemoveBeneficiaryInReview(),
    watchRestoreBeneficiaryInReview(),
    watchChargeCorporateCard(),
    watchSubmitCorporateCardOtp(),
    watchFetchCorporateBeneficiaries(),
    watchGetCompanyProfile(),
    watchGetCorporateAnalytics(),
    watchCorporateProfileCompletionStatus(),
    watchDeactivateActiveBeneficiaries(),
    watchMovePendingBeneficiariesToInactiveList(),
    watchMoveInactiveBeneficiariesToPendingList(),
    watchGetCorporateActivityLog(),

    // HealthInsured Admin Sagas
    watchGetHealthInsuredAdminDashboardAnalytics(),
    watchGetHealthInsuredUserAcquisition(),
    watchGetHealthInsuredSubscriberAcquisition(),
    watchGetInsuranceProfiles(),
    watchGetHealthInsuredAdminTransactionLog(),
    watchGetHealthInsuredAdminUserActivityLogs(),
    watchGetHealthInsuredAdminActivityLog(),
    watchGetHealthInsuredAdminErrorLogs(),
    watchGetUserHealthInsuredAdminProfile(),

    ///////////////// ---------- ONE DRUG STORE -----------//////////
    // -- Aggregator
    watchCreateAggregator(),
    watchDeleteAggregator(),
    watchGetAggregator(),
    watchUpdateAggregator(),

    //-- pharmachy
    watchCreatePharmacy(),
    watchDeletePharmacy(),
    watchGetPharmacy(),
    watchUpdatePharmacy(),

    //-- cart item
    watchAddCartItem(),
    watchDeleteCartItem(),
    watchGetCartItems(),
    watchUpdateCartItemQuantity(),
    watchUploadCartItemPrescription(),
    watchAddLocalCartItems(),

    //---- card payment
    watchCardPayment(),

    //--- wallet payment
    watchWalletPayment(),
    watchWalletConfirmOTP(),

    // -- delivery info
    watchGetDeliveryInfo(),
    watchUpdateDeliveryInfo(),

    //-- drug category
    watchCreateDrugCategory(),
    watchGetDrugCategory(),
    watchUpdateDrugCategory(),

    //-- drug item
    watchGetAllDrugItem(),
    watchCreateDrugItem(),
    watchUpdateDrugItem(),
    watchDeleteDrugItem(),

    // -- order
    watchCreateOrder(),
    watchGetAllOrders(),
    watchGetMyOrders(),

    //
    watchLogout(),
  ])
}
