import {
  UPDATE_CORPORATE_PROFILE_FAILED,
  UPDATE_CORPORATE_PROFILE_REQUEST,
  UPDATE_CORPORATE_PROFILE_SUCCESS,
} from '../../healthInsuredTypes/corporateActionTypes'

export const updateCorporateProfileRequest = () => ({
  type: UPDATE_CORPORATE_PROFILE_REQUEST,
})

export const updateCorporateProfileSuccess = (data) => ({
  type: UPDATE_CORPORATE_PROFILE_SUCCESS,
  payload: data,
})

export const updateCorporateProfileFailed = (errorMessage) => ({
  type: UPDATE_CORPORATE_PROFILE_FAILED,
  payload: errorMessage,
})
