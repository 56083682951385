import React, { useEffect } from 'react'
import { Formik, Form, Field } from 'formik'
import axios from 'axios'
import * as Yup from 'yup'

import { Carousel } from 'react-bootstrap'

import Navbar from '../../healthBancComponents/LandingPage/Navbar/Navbar'
import CustomInput from '../../healthBancComponents/Templates/CustomInput'
import CustomButton from '../../healthBancComponents/Templates/CustomButton'
import CustomTextarea from '../../healthBancComponents/Templates/CustomTextarea'
import Footer from '../../healthBancComponents/Templates/Footer'

import leadGenImg1 from './assets/leadGenImg1.jpeg'
import leadGenImg2 from './assets/leadGenImg2.jpeg'
import leadGenImg3 from './assets/leadGenImg3.jpeg'

import './healthFinance.scss'

const HealthFinance = () => {
  //Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const formSchema = Yup.object({
    name: Yup.string().required('Required'),
    email: Yup.string()
      .email('Email address must be a valid email')
      .required('Required'),
    businessName: Yup.string().required('Required'),
    businessAddress: Yup.string().required('Required'),
    businessType: Yup.string().required('Required'),
    phoneNumber: Yup.string()
      .min(11, 'Phone number should be at least 11 digits. ')
      .max(13, 'Phone Number should not be more than 13 digits.')
      .required('Required'),
    amount: Yup.string().required('Required'),
    comment: Yup.string().max(500, 'Max: 500 characters.'),
  })

  const handleSubmitForm = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true)

    try {
      const response = await axios({
        method: 'post',
        url: '/v2/api/LeadGenerator/SendHealthFinanceData',
        data: { ...values },
      })

      if (response.status === 200) {
        alert(response.data.message)
        // alert("Thank you! \nWe'll reach out to you soon")
        resetForm()
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.message)
      }
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <>
      <Navbar />

      <div className="digital__health--heading container mt-4">
        <h1 className="digital__health--heading--main">
          Sterling Health Sector Finance
        </h1>

        <p>
          The goal of the Health Finance Sector, is to serve as an enabler of
          Healthcare Systems in Nigeria by providing /co-creating platforms that
          enable easy and affordable access to healthcare across various social
          classes; leveraging partnerships with Key health stakeholders to
          develop and build capacities to improve the overall healthcare
          outcomes, and most importantly enable growth of investments across the
          health value chain.
        </p>

        <Carousel className="carousel-container mx-auto mb-3">
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={leadGenImg1}
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={leadGenImg2}
              alt="Second slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={leadGenImg3}
              alt="Third slide"
            />
          </Carousel.Item>
        </Carousel>

        <p>
          Sterling has shown its commitment by earmarking &#8358;20B toward the
          development of the sector’s investments, and we have been working on
          improving healthcare systems through an integrated partnership
          approach.
        </p>

        <p>
          We have partnered with various organizations and will continue to
          partner with healthcare institutions to enhance the growth, quality
          and access to healthcare delivery in Nigeria.
        </p>
      </div>

      <div className="digital__health--info-form--container mb-1">
        <div className="digital__health--form">
          <div className="digital__health--form--header">
            <h2 className="digital__health--form--header--main">Get Started</h2>
            <p className="digital__health--form--header--sub">
              Give us a little information so that our team can get across to
              you soonest.
            </p>
          </div>

          <Formik
            initialValues={{
              name: '',
              businessName: '',
              businessAddress: '',
              businessType: '',
              phoneNumber: '',
              amount: '',
              email: '',
              comment: '',
            }}
            validationSchema={formSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              handleSubmitForm(values, {
                setSubmitting,
                resetForm,
              })
            }}
          >
            {(formik) => (
              <Form style={{ background: 'inherit' }}>
                <Field
                  as={CustomInput}
                  label="Your Full Name"
                  type="text"
                  name="name"
                  placeholder="John Doe"
                />

                <Field
                  as={CustomInput}
                  label="Your Business Name"
                  type="text"
                  name="businessName"
                  placeholder="Abc Health Services"
                />

                <Field
                  as={CustomInput}
                  label="Your Business Address"
                  type="text"
                  name="businessAddress"
                  placeholder="eg. 20 Marina, Lagos"
                />

                <Field
                  as={CustomInput}
                  label="Business Type"
                  type="list"
                  list="businessType"
                  name="businessType"
                  placeholder="Enter your kind of business"
                  autocomplete="off"
                />
                <datalist id="businessType">
                  <option value="Clinic" />
                  <option value="Diagnostic Centre" />
                  <option value="Hospital" />
                  <option value="Laboratory" />
                </datalist>

                <Field
                  as={CustomInput}
                  label="Email"
                  type="email"
                  name="email"
                  placeholder="example@abc.diagnostics.com"
                />

                <Field
                  as={CustomInput}
                  label="Contact Phone Number"
                  type="tel"
                  name="phoneNumber"
                  minLength="11"
                  maxLength="13"
                  placeholder="e.g. 08087654321"
                />

                <Field
                  as={CustomInput}
                  label="How much do you require from the bank?"
                  type="tel"
                  name="amount"
                  placeholder="e.g. 1,000,000"
                  thousandSeparator={true}
                  prefix="&#8358; "
                />

                <Field
                  as={CustomTextarea}
                  label="Comment"
                  placeholder="Any other thing you'd like us to know?"
                  name="comment"
                  rows="3"
                />

                <div>
                  <CustomButton
                    type="submit"
                    disabled={formik.isSubmitting ? true : false}
                  >
                    {formik.isSubmitting ? 'Submitting...' : 'Submit'}
                  </CustomButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <Footer />
    </>
  )
}

export default HealthFinance
