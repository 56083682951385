import React from 'react';

import ProfileDp from '../../../../../../assets/healthInsuredAdmin/adminProfile.png';

const TicketHeader = () => {
  return (
    <div className='health__insured__admin__support__notification__header'>
      <div className='health__insured__admin__support__notification__header--container-img'>
        <img src={ProfileDp} alt='profile picture' className='health__insured__admin__support__notification__header--img' />
      </div>

      <p className='health__insured__admin__support__notification__header--para'>Wade Warren</p>
      <time datetime="20:00" className='health__insured__admin__support__notification__header--time'>20:00</time>
    </div>
  );
};

export default TicketHeader;
