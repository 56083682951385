const identifications = [
  {
    code: "5",
    text: "Driver's Licence"

  },
  {
    code: "1",
    text: "International Passport"
  },
  {
    code: "9",
    text: "National ID Card"
  }
]

export default identifications;