import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: {},
  error: null,
}

const deleteDrugItemSlice = createSlice({
  name: 'deleteDrugItem',
  initialState,
  reducers: {
    deleteDrugItemRequested: (state) => {
      state.isLoading = true
      state.data = {}
      state.error = null
    },

    deleteDrugItemSuccessful: (state, action) => {
      state.isLoading = false
      state.data = action.payload
      state.error = null
    },

    deleteDrugItemFailed: (state, action) => {
      state.isLoading = false
      state.data = {}
      state.error = action.payload
    },
  },
})

export const {
  deleteDrugItemRequested,
  deleteDrugItemSuccessful,
  deleteDrugItemFailed,
} = deleteDrugItemSlice.actions
export default deleteDrugItemSlice.reducer
