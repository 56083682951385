import React from 'react'
import { connect } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import BackgroundTemplate from '../Templates/BackgroundTemplate'
import CustomButton from '../Templates/CustomButton'
import mail from '../../assets/healthBancLandingPageImgs/mail.png'

const Img = styled.img`
  width: 150px;
`

const VerifyEmail = ({ user }) => {
  const history = useHistory()
  const topElement = () => (
    <div className="text-center mt-4 mb-0">
      <Img src={mail} alt="mail_image" className="img-fluid" />
    </div>
  )

  if (!user.isAuth) {
    return (
      <BackgroundTemplate
        topElement={topElement()}
        // formTitle="Verify your Email"
        formTitle="Success! Proceed to Sign In"
        // subTitle="A verification link was sent to your email address. Click on the link to verify your account."
        subTitle="Please Proceed to the Sign In page to log in to your account."
        showLogo={true}
      >
        <div className="pb-3">
          <CustomButton onClick={() => history.push('/signin')}>
            Go to Sign in
          </CustomButton>
        </div>
        {/* <div className='text-center'>
                    <Img src={mail} alt='mail_image' className='img-fluid' />
                </div> */}
      </BackgroundTemplate>
    )
  } else {
    // return <Redirect to='/dashboard' />
    return <Redirect to="/" />
  }
}

const mapStateToProps = (state) => ({
  user: state.user.userInfo,
})

export default connect(mapStateToProps)(VerifyEmail)
