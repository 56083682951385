import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { IconContext } from 'react-icons'
import { animateScroll as scroll } from 'react-scroll'

import LandingPageNavbar from '../Navbar/Navbar'
import backgroundImage from '../../../assets/healthBancLandingPageImgs/img.png'
import Mouse from '../../../assets/healthBancLandingPageImgs/Mouse.svg'

const HeroSection = styled.div`
	height: 100vh;
	background: linear-gradient(
			0deg,
			rgba(14, 22, 38, 0.2),
			rgba(14, 22, 38, 0.2)
		),
		url(${backgroundImage}) no-repeat;
	background-size: cover;
	background-position: center;
`

const IntroSection = styled.section`
	@import url('https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap');

	color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 750px;
	text-align: center;
	height: 100%;

	h3 {
		font-family: 'Inter', sans-serif;
		font-size: 2.8rem;
		/* font-size: 48px; */
		font-weight: 700;
	}

	p {
		font-size: 15px;
	}

	/* .features {
    width: 208px;
    height: 33px;
    line-height: 33px;
    padding-left: 8px;
    padding-right: 8px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 3px;
    font-size: 14px;
  } */

	button.getStarted {
		background-color: #d71e1b;
		box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.12);
		border-radius: 3px;
		color: #fff;
		width: 185px;
		height: 51px;
		padding: 16px 32px;
		border: none;
		font-size: 16px;
		font-weight: 500;
	}

	button.scroll__down__btn {
		background-color: transparent;
		border: none;
		outline: none;

		&:hover {
			transform: translateY(3px);
		}
	}
`

const Hero = ({ history }) => {
	const handleScroll = () => {
		scroll.scrollTo(document.documentElement.clientHeight)
	}
	return (
		<IconContext.Provider value={{ size: '1.5em' }}>
			<React.Fragment>
				<HeroSection>
					<LandingPageNavbar />
					<IntroSection className='p-3 d-flex align-items-center mx-auto'>
						<div className='text-center'>
							<h3>Simple healthcare solutions for everyone.</h3>
						</div>

						<div className='mt-5 px-2'>
							<p>Explore our solutions</p>
							<div className='mt-5'>
								{/* <Link to=''> */}
								<button
									className='scroll__down__btn'
									onClick={handleScroll}
								>
									<img src={Mouse} alt='mouseIcon' />
								</button>
								{/* </Link> */}
							</div>
							{/* <p>
								We intend to use digitization, advocacy and
								finance to solve the inadequacies of service
								delivery in the health sector.
							</p> */}
						</div>

						{/* <div className='features mx-auto mt-4 mb-5'>
              Order. Track. Keep Inventory.
            </div> */}

						{/* <div className='mt-4'> */}
						{/* <button
								className='getStarted p-0'
								onClick={() => history.push('/signup')}
							>
								<span> Get Started </span> <BsArrowRight />
							</button> */}
						{/* <button
								className='getStarted p-0'
								onClick={() => history.push('/signup')}
							>
								<Mouse />
								<span> Get Started </span> <BsArrowRight />
							</button> */}
						{/* </div> */}
					</IntroSection>
				</HeroSection>
			</React.Fragment>
		</IconContext.Provider>
	)
}

export default withRouter(Hero)
