import React from 'react'
import { Formik, Field } from 'formik'
import * as yup from 'yup'
import { Card, Row, Col, Form } from 'react-bootstrap'
import CustomInput from '../Templates/CustomInput'
import CustomButton from '../Templates/CustomButton'

import './debitCardInfoForm.scss'

const CardInfoForm = ({ chargeCard, handleSubmitCardInfo }) => {
  const cardInfoSchema = yup.object().shape({
    cardHolder: yup.string().required('Required'),
    cardNumber: yup.string().required('Required'),
    expiryDate: yup.string().required('Required'),
    cvv: yup.string().required('Required'),
    pin: yup
      .string()
      .min(4, 'Minimum of 4 digits required.')
      .required('Required'),
  })

  return (
    <Card
      className=" w-100 h-100 p-2 p-md-5 col-12 col-sm-6 col-lg-4 mx-auto payment--card__details"
      style={{ maxWidth: '350px' }}
    >
      <Row>
        <Col>
          <Row>
            <Col className="text-center">
              <h3 className="payment--title font-weight-bold">
                Setup your payment
              </h3>
              <p>Provide your card information</p>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <Formik
                initialValues={{
                  cardHolder: '',
                  cardNumber: '',
                  expiryDate: '',
                  cvv: '',
                  pin: '',
                }}
                validationSchema={cardInfoSchema}
                onSubmit={(values, { isSubmitting }) => {
                  handleSubmitCardInfo(values, { isSubmitting })
                }}
              >
                {(props) => (
                  <Form onSubmit={props.handleSubmit}>
                    <Form.Row>
                      <Col>
                        <Field
                          as={CustomInput}
                          type="text"
                          label="Cardholder's name"
                          name="cardHolder"
                          onChange={props.handleChange}
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col>
                        <Field
                          as={CustomInput}
                          type="tel"
                          label="Card number"
                          name="cardNumber"
                          format="#### #### #### #### ###"
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col>
                        <Field
                          as={CustomInput}
                          type="tel"
                          label="Expiry date"
                          name="expiryDate"
                          format="##/##"
                          placeholder="MM/YY"
                          mask={['M', 'M', 'Y', 'Y']}
                        />
                      </Col>
                      <Col>
                        <Field
                          as={CustomInput}
                          type="tel"
                          label="CVV"
                          name="cvv"
                          format="###"
                        />
                      </Col>
                      <Col>
                        <Field
                          as={CustomInput}
                          type="password"
                          label="PIN"
                          name="pin"
                          format="####"
                          maxLength="4"
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col>
                        <CustomButton
                          className="col-sm-6"
                          disabled={chargeCard?.isLoading ? true : false}
                          type="submit"
                        >
                          {chargeCard?.isLoading
                            ? 'Checking Card...'
                            : 'Proceed'}
                        </CustomButton>
                      </Col>
                    </Form.Row>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  )
}

export default CardInfoForm
