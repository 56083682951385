import {
  GET_INSURANCE_PROFILES_FAILED,
  GET_INSURANCE_PROFILES_REQUEST,
  GET_INSURANCE_PROFILES_SUCCESS,
} from '../../healthInsuredTypes/healthInsuredAdminActionTypes'

export const getInsuranceProfilesRequest = () => ({
  type: GET_INSURANCE_PROFILES_REQUEST,
})

export const getInsuranceProfilesSuccess = (profiles) => ({
  type: GET_INSURANCE_PROFILES_SUCCESS,
  payload: profiles,
})

export const getInsuranceProfilesFailed = (errorMessage) => ({
  type: GET_INSURANCE_PROFILES_FAILED,
  payload: errorMessage,
})
