import React from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { IoIosArrowBack } from 'react-icons/io';

const Pagination = ({ pageNumber, pageCount, recordCount, paginate }) => {
  return (
    <div className='ml-3 d-flex align-items-center'>
      <p className='paginate-p'>{pageNumber}-{pageCount} of {recordCount}
        <span className='ml-3'>
          <i className={pageNumber === 1 ? 'disabled mx-3 paginate-icon' : 'mx-3 paginate-icon'}>
            <IoIosArrowBack
              onClick={() => paginate(pageNumber - 1)}
            />
          </i>

          <i>
            <IoIosArrowForward
              onClick={() => paginate(pageNumber + 1)}
              className={pageNumber > pageCount ? 'disabled mx-3 paginate-icon' : 'mx-3 paginate-icon'}
            />
          </i>
        </span>
      </p>
    </div>
  );
};

export default Pagination;
