import React, { useState } from 'react';
import Search from '../../template/Search';
import Filter_Sort from '../../template/Filter_Sort';
import Pagination from "../../template/Pagination";
import TrashTable from '../../template/Table';
import NewButton from '../../template/NewButton';
import EmptyNotification from '../sent/EmptyNotification';
import Loading from '../../../../healthBancServices/pharmHub/admin/components/Spinner/Spinner';
import sortByOptions from '../../template/sortByOptions';
import NewNotificationModal from '../../template/NewNotificationModal';
import ResponseToast from '../../template/ResponseToast';

const MainSection = ({
  loading,
  trashNotifications,
  handleChange,
  searchSubmit,
  filter,
  services,
  pageCount,
  pageNumber,
  recordCount,
  paginate,
  sort,
  toastError,
  toastShow,
  handleToastClose,
  handleRestore,
  handleDelete,
}) => {

  const [show, setShow] = useState(false);

  // get all service names and values 
  const filterOptions = services && services.map(service => (
    <option value={service.id}>{service.name}</option>
  ));

  // get all sort options: names and values 
  const sortByOption = sortByOptions && sortByOptions.map(option => (
    <option value={option.id}>{option.name}</option>
  ));

  // dropdown options on the table
  const dottedOptions = {
    option1: 'Restore',
    option2: 'Delete'
  }

  //show loading spinner on page load or network request
  if (loading) return <Loading />;

  //inform the user that there is nothing to show
  if (trashNotifications.length === 0) return <EmptyNotification />;

  // hide and show create modal
  const handleHide = () => setShow(false);
  const openCreateModal = () => {
    setShow(true);
  }

  return (
    <div className='hb-main-section hb-registered-users p-4'>
      <div className='d-flex flex-row'>
        <NewNotificationModal
          show={show}
          handleHide={handleHide}
          filterOptions={filterOptions}
        />
        <ResponseToast
          handleToastClose={handleToastClose}
          toastError={toastError}
          toastShow={toastShow}
        />
        <Search
          handleChange={handleChange}
          searchSubmit={searchSubmit}
        />
        <Filter_Sort
          filter={filter}
          sort={sort}
          filterOptions={filterOptions}
          sortByOption={sortByOption}
        />
        <Pagination
          pageCount={pageCount}
          pageNumber={pageNumber}
          recordCount={recordCount}
          paginate={paginate}
        />
      </div>
      <TrashTable
        notifications={trashNotifications}
        dottedOptions={dottedOptions}
        handleSend={handleRestore}
        handleTrash={handleDelete}
      />
      <NewButton openCreateModal={openCreateModal} />
    </div>
  );
};

export default MainSection;
