import React from 'react';

import NavBar from '../nav/NavBar';
import TicketNotification from '../ticketNotification/TicketNotification';
import TicketHeader from '../ticketHeader/TicketHeader';
import TicketBody from '../ticketBody/TicketBody';
import TicketList from '../ticketList/TicketList';

const NewTicket = () => {
  return (
    <section className='health__insured__admin__support__section'>
      <NavBar />

      <div className='health__insured__admin__support__section--container'>
        <TicketNotification />
        <TicketHeader />

        <div className='health__insured__admin__support__section--ticket--list--container'>

          <TicketList />
          <TicketList />
          <TicketList />
        </div>
        <TicketBody />
      </div>
    </section>
  );
};

export default NewTicket;
