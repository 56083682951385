import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import CustomButton from '../../../../../healthBancComponents/Templates/CustomButton'
import Layout from '../../../components/adminComponents/Layout/Layout'

// import styles from './aggregators.module.scss'

const Pharmacy = () => {
  const history = useHistory()

  const { pharmacyId } = useParams()

  const { data } = useSelector((state) => state.getAllPharmacy)

  const pharmacy = data?.data.find((pharmacy) => pharmacy.id === +pharmacyId)

  return (
    <Layout canGoBack>
      <div className="mt-5 ps-5 container">
        <h4>{pharmacy.name}</h4>
        <div className="col-3 mt-5">
          <CustomButton
            outline
            onClick={() =>
              history.push(
                `/admin/oneDrugStore/drug-item/create/${pharmacy.id}`
              )
            }
          >
            Add drug
          </CustomButton>
        </div>

        <div className="mt-5">
          {/* <p>Show List of drugs under this pharmacy</p> */}
          {/* {isLoading ? (
            <div className="spinner-border d-flex  mx-auto" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <table class={`table table-hover`} style={{ cursor: 'pointer' }}>
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Description</th>
                  <th scope="col">Address</th>
                </tr>
              </thead>
              <tbody>
                {(data?.data || []).map((aggregator, index) => (
                  <tr
                    key={aggregator.id}
                    onClick={() =>
                      history.push(
                        `/admin/oneDrugStore/aggregators/${aggregator.id}`
                      )
                    }
                  >
                    <th scope="row">{index + 1}</th>
                    <td>{aggregator.name}</td>
                    <td>{aggregator.description}</td>
                    <td>{aggregator.address}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )} */}
        </div>
      </div>
    </Layout>
  )
}

export default Pharmacy
