import { takeLatest, put } from 'redux-saga/effects'
import axios from 'axios'
import {
  createDrugItemRequested,
  createDrugItemSuccessful,
  createDrugItemFailed,
} from '../../../reducer/oneDrugStore/admin/drugItemSlices'

function* createDrugItemAsync({ payload }) {
  const { formValues, pharmacyId, history } = payload

  try {
    yield createDrugItemRequested()
    const { data } = yield axios({
      method: 'POST',
      url: `${process.env.REACT_APP_ONE_DRUG_BASE}/v1/api/DrugItem/Create?pharmacyId=${pharmacyId}`,
      data: formValues,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('adminInfo')}`,
      },
      timeout: 60000,
    })

    yield put(createDrugItemSuccessful(data))
    yield history.goBack()
    alert('Drug Item created successfully')
  } catch (error) {
    if (error.response) {
      yield put(createDrugItemFailed(error.response.data))
      yield put(createDrugItemFailed(null))
      return
    }

    yield put(createDrugItemFailed(error.message))
    yield put(createDrugItemFailed(null))
  }
}

export function* watchCreateDrugItem() {
  yield takeLatest(createDrugItemRequested.type, createDrugItemAsync)
}
