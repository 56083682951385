import { takeLatest, put } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import axios from 'axios'
import {
  updateAggregatorRequested,
  updateAggregatorSuccessful,
  updateAggregatorFailed,
} from '../../../reducer/oneDrugStore/admin/aggregatorSlices'

function* updateAggregatorAsync({ payload }) {
  try {
    yield updateAggregatorRequested()
    const { data } = yield axios({
      method: 'PUT',
      url: `${process.env.REACT_APP_ONE_DRUG_BASE}/v1/api/Aggregator/Edit?aggregatorId=${payload.aggregatorId}`,
      headers: { Authorization: `Bearer ${localStorage.getItem('adminInfo')}` },
      data: payload,
    })
    yield toast.success('Aggregator updated successfully!')

    yield put(updateAggregatorSuccessful(data))
    yield payload.history.goBack()
  } catch (error) {
    if (error.response) {
      yield toast.error(error.response.data.responseDescription)
      yield put(updateAggregatorFailed(error.response.data.responseDescription))
      yield put(updateAggregatorFailed(null))
      return
    }

    yield put(updateAggregatorFailed(error.message))
    yield put(updateAggregatorFailed(null))
  }
}

export function* watchUpdateAggregator() {
  yield takeLatest(updateAggregatorRequested.type, updateAggregatorAsync)
}
