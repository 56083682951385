import React from 'react'
import { IoMdRadioButtonOn, IoIosRadioButtonOff } from 'react-icons/io'
import { AiFillCheckCircle } from 'react-icons/ai'
import { MdKeyboardBackspace } from 'react-icons/md'
import { useSelector } from 'react-redux'

import Header from '../template/header/Header'
import CustomSelectInput from '../template/customSelect/CustomeSelectput'
import CustomButton from '../template/customButton/CustomeButton'
import { insuranceProvider } from '../../data/insuranceProvider'

const ProviderDetails = ({
  userDetails,
  handleChange,
  handlePrevious,
  errorMessage,
  nextPage,
  healthPlans,
}) => {
  const provider = useSelector((state) => state.serviceProvider)

  const nairaUnicode = String.fromCharCode(8358) //convert the input to naira sign

  const { PlanCode } = userDetails
  const healthPlansOption =
    healthPlans &&
    healthPlans.map((healthplan) => {
      return (
        <option key={healthplan.code} value={healthplan.code}>
          {healthplan.code === '7'
            ? `Ruby (${nairaUnicode}1000)`
            : `Sapphire (${nairaUnicode}2000)`}
        </option>
      )
    })

  const hygeiaPlan = (
    <option
      value={'8'}
      defaultValue={'8'}
    >{`Sapphire (${nairaUnicode}2000)`}</option>
  )

  return (
    <div className="axa-mansard-sign-up-payment-details">
      <Header />

      <div className="form-container">
        <div className="personal-details-form">
          <form className="personal-form" onSubmit={nextPage}>
            <h1 className="start-your">Subscription preference</h1>
            <p className="provide-some mb-4">
              Choose your preferred health plan.
            </p>

            <p className="previous-text" onClick={handlePrevious}>
              <i>
                <MdKeyboardBackspace className="previous-icon me3" />
              </i>{' '}
              Previous
            </p>

            <CustomSelectInput
              selectOptions={
                provider === insuranceProvider.hygeia
                  ? hygeiaPlan
                  : healthPlansOption
              }
              labelName={'Health Plan'}
              handleChange={handleChange}
              inputValue={PlanCode}
              inputName="PlanCode"
              hideOption={true}
              errorMessage={errorMessage.PlanCodeError}
            />

            <CustomButton buttonText="Next" />
          </form>
        </div>

        <div className="progres">
          <ul>
            <li>
              <AiFillCheckCircle className="icon me3 fulfilled" />
              <span className="inactive active-two">Personal Information</span>
            </li>

            <hr className="first-link" />

            <li>
              <IoMdRadioButtonOn className="icon me3 active" />
              <span className="active">Subscription</span>
            </li>

            <hr className="second-link" />

            <li>
              <IoIosRadioButtonOff className="grey-icon me3 inactive" />
              <span className="grey-inactive">Care provider Information</span>
            </li>

            <hr className="third-link" />

            <li>
              <IoIosRadioButtonOff className="grey-icon me3 inactive" />
              <span className="grey-inactive">Payment</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default ProviderDetails
