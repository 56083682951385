import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Image } from 'react-bootstrap'
import CustomButton from '../../../../../healthBancComponents/Templates/CustomButton'
import CorporateDashboardStructure from '../../corporateDashboardStructure/CorporateDashboardStructure'
import welcomeImage from './welcomeImage.svg'
import './corporateWelcomeScreen.scss'

const CorporateWelcomeScreen = () => {
  const history = useHistory()
  const { profileCompleted } = useSelector(
    (state) => state.corporateProfileCompletion.data?.data ?? {}
  )

  useEffect(() => {
    if (profileCompleted) {
      history.push('/health_corporate/dashboard')
    }
  }, [profileCompleted, history])

  return (
    <CorporateDashboardStructure>
      <div className="welcome__screen text-center w-100 h-100">
        <div className="welcome__screen--image max-auto">
          <Image src={welcomeImage} alt="welcome_image" fluid />
        </div>
        <div className="welcome__screen--text text-center">
          <h3>Welcome to HealthInsured for Business. </h3>
          <p>
            Thank you for setting up your profile. Kindly update your company
            profile by clicking below.
          </p>
        </div>
        <div>
          <CustomButton
            onClick={() => history.push('/health_corporate/profile_update')}
          >
            Update Company Profile
          </CustomButton>
        </div>
      </div>
    </CorporateDashboardStructure>
  )
}

export default CorporateWelcomeScreen
