import {
  REMOVE_BENEFICIARIES_IN_REVIEW_FAILED,
  REMOVE_BENEFICIARIES_IN_REVIEW_REQUEST,
  REMOVE_BENEFICIARIES_IN_REVIEW_SUCCESS,
} from '../../healthInsuredTypes/corporateActionTypes'

export const removeBeneficiaryInReviewRequest = () => ({
  type: REMOVE_BENEFICIARIES_IN_REVIEW_REQUEST,
})

export const removeBeneficiaryInReviewSuccess = (data) => ({
  type: REMOVE_BENEFICIARIES_IN_REVIEW_SUCCESS,
  payload: data,
})

export const removeBeneficiaryInReviewFailed = (errorMessage) => ({
  type: REMOVE_BENEFICIARIES_IN_REVIEW_FAILED,
  payload: errorMessage,
})
