import {
  ACTIONS,
  NOTIFICATION_MESSAGE,
  AXA_USER_PIN_REFERENCE,
} from './actionTypes'

export const healthBancSigninAsync = (signinDetails) => ({
  type: ACTIONS.SIGNIN_ASYNC,
  payload: signinDetails,
})

export const refreshTokenAsync = (token, refreshToken) => ({
  type: ACTIONS.REFRESHTOKEN,
  payload: { token, refreshToken },
})

export const healthBancSignOut = () => ({
  type: ACTIONS.SIGN_OUT,
})

// healthbanc admin creators starts here
export const notificationMessage = (message) => ({
  type: NOTIFICATION_MESSAGE,
  message,
})

export const axaUserPinReference = (pinReference) => ({
  type: AXA_USER_PIN_REFERENCE,
  pinReference,
})
