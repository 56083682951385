import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: {},
  errors: [],
}

const getFamilyMembersSlice = createSlice({
  name: 'GetFamilyMembers',
  initialState,
  reducers: {
    getFamilyMembersRequested: (state) => {
      return { isLoading: true, data: {}, errors: [] }
    },

    getFamilyMembersSuccessful: (state, action) => {
      return { isLoading: false, data: { ...action.payload }, errors: [] }
    },

    getFamilyMembersFailed: (state, action) => {
      return { isLoading: false, data: {}, errors: [...action.payload] }
    },
  },
})

export const {
  getFamilyMembersRequested,
  getFamilyMembersSuccessful,
  getFamilyMembersFailed,
} = getFamilyMembersSlice.actions

export default getFamilyMembersSlice.reducer
