import React from 'react'
import { Card } from 'react-bootstrap'
import { GoArrowUp } from 'react-icons/go'

const UsersCard = ({ title, figure, percent, color, spacing }) => {
  return (
    <Card
      className={spacing}
      style={{ width: '18rem', height: '10rem', marginRight: '1rem' }}
    >
      <Card.Body className="d-flex flex-column px-2 py-2 px-sm-4 py-sm-5">
        <Card.Title className="users-title" style={{ color: color }}>
          {title}
        </Card.Title>
        <Card.Text className="me2 users-text">
          {figure}{' '}
          <span className="users-arrow" style={{ color: color }}>
            <GoArrowUp />
            {percent}%
          </span>
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

export default UsersCard
