import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: {},
  error: null,
}

const createPharmacySlice = createSlice({
  name: 'createPharmacy',
  initialState,
  reducers: {
    createPharmacyRequested: (state) => {
      state.isLoading = true
      state.data = {}
      state.error = null
    },

    createPharmacySuccessful: (state, action) => {
      state.isLoading = false
      state.data = action.payload
      state.error = null
    },

    createPharmacyFailed: (state, action) => {
      state.isLoading = false
      state.data = {}
      state.error = action.payload
    },
  },
})

export const {
  createPharmacyRequested,
  createPharmacySuccessful,
  createPharmacyFailed,
} = createPharmacySlice.actions
export default createPharmacySlice.reducer
