import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: {},
  error: null,
}

const logoutSlice = createSlice({
  name: 'logout',
  initialState,
  reducers: {
    LOGOUT_REQUESTED: (state, action) => {
      return { isLoading: true, data: {}, error: null }
    },

    LOGOUT_SUCCESSFUL: (state, action) => {
      // return { isLoading: false, data: action.payload, error: null }
      return { isLoading: false, data: {}, error: null }
    },

    LOGOUT_FAILED: (state, action) => {
      return { isLoading: false, data: {}, error: action.payload }
    },
  },
})

export const { LOGOUT_REQUESTED, LOGOUT_SUCCESSFUL, LOGOUT_FAILED } =
  logoutSlice.actions

export default logoutSlice.reducer
