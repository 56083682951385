import {
  DEACTIVATE_ACTIVE_BENEFICIARIES_FAILED,
  DEACTIVATE_ACTIVE_BENEFICIARIES_REQUEST,
  DEACTIVATE_ACTIVE_BENEFICIARIES_SUCCESS,
} from '../../healthInsuredTypes/corporateActionTypes'

export const deactivateActiveBeneficiariesRequest = () => ({
  type: DEACTIVATE_ACTIVE_BENEFICIARIES_REQUEST,
})

export const deactivateActiveBeneficiariesSuccess = (payload) => ({
  type: DEACTIVATE_ACTIVE_BENEFICIARIES_SUCCESS,
  payload,
})

export const deactivateActiveBeneficiariesFailed = (errorMessage) => ({
  type: DEACTIVATE_ACTIVE_BENEFICIARIES_FAILED,
  payload: errorMessage,
})
