import React, { useState } from 'react';

import NewButton from '../../template/NewButton';
import Goback from './Goback';
import MessageBody from './MessageBody';
import NewNotificationModal from '../../template/NewNotificationModal';

const MainSection = () => {
  const [show, setShow] = useState(false);

  const handleHide = () => setShow(false);

  const openCreateModal = () => {
    setShow(true);
  };

  return (
    <div className='hb-main-section hb-registered-users message p-4'>
      <NewNotificationModal show={show} handleHide={handleHide} />
      <Goback />
      <MessageBody />
      <NewButton openCreateModal={openCreateModal} />
    </div>
  );
};

export default MainSection;
