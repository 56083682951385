// Create a corporate user
export const CREATE_CORPORATE_USER_REQUEST = 'CREATE_CORPORATE_USER_REQUEST'
export const CREATE_CORPORATE_USER_SUCCESS = 'CREATE_CORPORATE_USER_SUCCESS'
export const CREATE_CORPORATE_USER_FAILED = 'CREATE_CORPORATE_USER_FAILED'

// Create get corporate profile completion
export const CORPORATE_PROFILE_COMPLETION_STATUS_REQUEST =
  'CORPORATE_PROFILE_COMPLETION_STATUS_REQUEST'
export const CORPORATE_PROFILE_COMPLETION_STATUS_SUCCESS =
  'CORPORATE_PROFILE_COMPLETION_STATUS_SUCCESS'
export const CORPORATE_PROFILE_COMPLETION_STATUS_FAILED =
  'CORPORATE_PROFILE_COMPLETION_STATUS_FAILED'

// Get the current logged in corporate user
export const GET_CORPORATE_USER_REQUEST = 'GET_CORPORATE_USER_REQUEST'
export const GET_CORPORATE_USER_SUCCESS = 'GET_CORPORATE_USER_SUCCESS'
export const GET_CORPORATE_USER_FAILED = 'GET_CORPORATE_USER_FAILED'

// Resend corporate user OTP
export const RESEND_CORPORATE_USER_OTP_REQUEST =
  'RESEND_CORPORATE_USER_OTP_REQUEST'
export const RESEND_CORPORATE_USER_OTP_SUCCESS =
  'RESEND_CORPORATE_USER_OTP_SUCCESS'
export const RESEND_CORPORATE_USER_OTP_FAILED =
  'RESEND_CORPORATE_USER_OTP_FAILED'

// Verify Corporate User OTP
export const VERIFY_CORPORATE_OTP_REQUEST = 'VERIFY_CORPORATE_OTP_REQUEST'
export const VERIFY_CORPORATE_OTP_SUCCESS = 'VERIFY_CORPORATE_OTP_SUCCESS'
export const VERIFY_CORPORATE_OTP_FAILED = 'VERIFY_CORPORATE_OTP_FAILED'

// Get Company Profile
export const GET_COMPANY_PROFILE_REQUEST = 'GET_COMPANY_PROFILE_REQUEST'
export const GET_COMPANY_PROFILE_SUCCESS = 'GET_COMPANY_PROFILE_SUCCESS'
export const GET_COMPANY_PROFILE_FAILED = 'GET_COMPANY_PROFILE_FAILED'

// Update Corporate User Profile
export const UPDATE_CORPORATE_PROFILE_REQUEST =
  'UPDATE_CORPORATE_PROFILE_REQUEST'
export const UPDATE_CORPORATE_PROFILE_SUCCESS =
  'UPDATE_CORPORATE_PROFILE_SUCCESS'
export const UPDATE_CORPORATE_PROFILE_FAILED = 'UPDATE_CORPORATE_PROFILE_FAILED'

// Upload excel file of beneficiaries of a corporate user
export const UPLOAD_BENEFICIARIES_FILE_REQUEST =
  'UPLOAD_BENEFICIARIES_FILE_REQUEST'
export const UPLOAD_BENEFICIARIES_FILE_SUCCESS =
  'UPLOAD_BENEFICIARIES_FILE_SUCCESS'
export const UPLOAD_BENEFICIARIES_FILE_FAILED =
  'UPLOAD_BENEFICIARIES_FILE_FAILED'

// Fetch Corporate Beneficiaries
export const FETCH_CORPORATE_BENEFICIARIES_REQUEST =
  'FETCH_CORPORATE_BENEFICIARIES_REQUEST'
export const FETCH_CORPORATE_BENEFICIARIES_SUCCESS =
  'FETCH_CORPORATE_BENEFICIARIES_SUCCESS'
export const FETCH_CORPORATE_BENEFICIARIES_FAILED =
  'FETCH_CORPORATE_BENEFICIARIES_FAILED'

// Review corporate beneficiaries
export const BENEFICIARIES_REVIEW_REQUEST = 'BENEFICIARY_REVIEW_REQUEST'
export const BENEFICIARIES_REVIEW_SUCCESS = 'BENEFICIARY_REVIEW_SUCCESS'
export const BENEFICIARIES_REVIEW_FAILED = 'BENEFICIARY_REVIEW_FAILED'

//Remove Beneficiary in Review
export const REMOVE_BENEFICIARIES_IN_REVIEW_REQUEST =
  'REMOVE_BENEFICIARIES_IN_REVIEW_REQUEST'
export const REMOVE_BENEFICIARIES_IN_REVIEW_SUCCESS =
  'REMOVE_BENEFICIARIES_IN_REVIEW_SUCCESS'
export const REMOVE_BENEFICIARIES_IN_REVIEW_FAILED =
  'REMOVE_BENEFICIARIES_IN_REVIEW_FAILED'

//Restore Beneficiary in Review
export const RESTORE_BENEFICIARIES_IN_REVIEW_REQUEST =
  'RESTORE_BENEFICIARIES_IN_REVIEW_REQUEST'
export const RESTORE_BENEFICIARIES_IN_REVIEW_SUCCESS =
  'RESTORE_BENEFICIARIES_IN_REVIEW_SUCCESS'
export const RESTORE_BENEFICIARIES_IN_REVIEW_FAILED =
  'RESTORE_BENEFICIARIES_IN_REVIEW_FAILED'

// Deactivate active beneficiaries
export const DEACTIVATE_ACTIVE_BENEFICIARIES_REQUEST =
  'DEACTIVATE_ACTIVE_BENEFICIARIES_REQUEST'
export const DEACTIVATE_ACTIVE_BENEFICIARIES_SUCCESS =
  'DEACTIVATE_ACTIVE_BENEFICIARIES_SUCCESS'
export const DEACTIVATE_ACTIVE_BENEFICIARIES_FAILED =
  'DEACTIVATE_ACTIVE_BENEFICIARIES_FAILED'

// Move PENDING beneficiaries to INACTIVE list
export const MOVE_PENDING_BENEFICIARIES_TO_INACTIVE_LIST_REQUEST =
  'MOVE_PENDING_BENEFICIARIES_TO_INACTIVE_LIST_REQUEST'
export const MOVE_PENDING_BENEFICIARIES_TO_INACTIVE_LIST_SUCCESS =
  'MOVE_PENDING_BENEFICIARIES_TO_INACTIVE_LIST_SUCCESS'
export const MOVE_PENDING_BENEFICIARIES_TO_INACTIVE_LIST_FAILED =
  'MOVE_PENDING_BENEFICIARIES_TO_INACTIVE_LIST_FAILED'

// Move INACTIVE beneficiaries to PENDING list
export const MOVE_INACTIVE_BENEFICIARIES_TO_PENDING_LIST_REQUEST =
  'MOVE_INACTIVE_BENEFICIARIES_TO_PENDING_LIST_REQUEST'
export const MOVE_INACTIVE_BENEFICIARIES_TO_PENDING_LIST_SUCCESS =
  'MOVE_PENDING_BENEFICIARIES_TO_PENDING_LIST_SUCCESS'
export const MOVE_INACTIVE_BENEFICIARIES_TO_PENDING_LIST_FAILED =
  'MOVE_INACTIVE_BENEFICIARIES_TO_PENDING_LIST_FAILED'

// Charge Corporate Card
export const CHARGE_CORPORATE_CARD_REQUEST = 'CHARGE_CORPORATE_CARD_REQUEST'
export const CHARGE_CORPORATE_CARD_SUCCESS = 'CHARGE_CORPORATE_CARD_SUCCESS'
export const CHARGE_CORPORATE_CARD_FAILED = 'CHARGE_CORPORATE_CARD_FAILED'

// Submit Corporate Card Otp
export const SUBMIT_CORPORATE_CARD_OTP_REQUEST =
  'SUBMIT_CORPORATE_CARD_OTP_REQUEST'
export const SUBMIT_CORPORATE_CARD_OTP_SUCCESS =
  'SUBMIT_CORPORATE_CARD_OTP_SUCCESS'
export const SUBMIT_CORPORATE_CARD_OTP_FAILED =
  'SUBMIT_CORPORATE_CARD_OTP_FAILED'

// Get corporate subscribers analytics
export const GET_CORPORATE_ANALYTICS_REQUEST = 'GET_CORPORATE_ANALYTICS_REQUEST'
export const GET_CORPORATE_ANALYTICS_SUCCESS = 'GET_CORPORATE_ANALYTICS_SUCCESS'
export const GET_CORPORATE_ANALYTICS_FAILED = 'GET_CORPORATE_ANALYTICS_FAILED'

// Get corporate user activity logs
export const GET_CORPORATE_ACTIVITY_LOG_REQUEST =
  'GET_CORPORATE_ACTIVITY_LOG_REQUEST'
export const GET_CORPORATE_ACTIVITY_LOG_SUCCESS =
  'GET_CORPORATE_ACTIVITY_LOG_SUCCESS'
export const GET_CORPORATE_ACTIVITY_LOG_FAILED =
  'GET_CORPORATE_ACTIVITY_LOG_FAILED'
