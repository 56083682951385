import React, { useEffect, useState } from 'react'
import axios from 'axios'
import NumberFormat from 'react-number-format'
import fileDownload from 'js-file-download'

import { useHistory } from 'react-router-dom'
import { Table, InputGroup, FormControl } from 'react-bootstrap'
import { FiSearch } from 'react-icons/fi'

import Navbar from '../../healthInsured/HealthInsuredAdmin/components/navbar/Navbar'
import CustomButton from '../../../healthBancComponents/Templates/CustomButton'
import LoadingIndicator from '../../../healthBancComponents/LoadingIndicator/LoadingIndicator'
import HealthInsuredPagination from '../../healthInsured/HealthInsuredCorporateFlow/components/healthInsuredPagination/HealthInsuredPagination'

import authenticated from '../../../healthBancAdmin/components/auth/authenticated'

import './healthFinanceAdmin.scss'

const HealthFinanceAdmin = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [financeData, setFinanceData] = useState({})
  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const [searchText, setSearchText] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [isExportingFile, setIsExportingFile] = useState(false)

  const history = useHistory()

  const rearrangeDate = (dateStr) => {
    if (dateStr === '') {
      return ''
    }

    const YYMMDD = dateStr.split('-')

    const year = YYMMDD[0]
    const month = YYMMDD[1]
    const day = YYMMDD[2]

    return `${month}/${day}/${year}` || ''
  }

  //Fetch finance data from db
  useEffect(() => {
    const fetchFinanceData = async () => {
      try {
        setIsLoading(true)

        //Check if start date is not empty and is less than end date
        if (startDate && endDate && startDate > endDate) {
          alert('Start Date must be less than End Date!')
          setEndDate('')
          return
        }

        const { data } = await axios({
          url: `/v2/api/LeadGenerator/GetPaginatedFinanceData?`,
          method: 'POST',
          data: {
            SearchText: searchText,
            PageNumber: currentPageNumber,
            StartDate: rearrangeDate(startDate),
            EnsDate: rearrangeDate(endDate),
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('adminInfo')}`,
          },
        })

        setFinanceData(data.data)
      } catch (error) {
        alert(error.message)
      } finally {
        setIsLoading(false)
      }
    }

    fetchFinanceData()
  }, [searchText, currentPageNumber, startDate, endDate])

  const handleLogout = () => {
    localStorage.clear()
    if (!authenticated()) {
      history.push('/admin_signin')
    }
  }

  const handlePrevPage = () => {
    if (!financeData.pageNumber || financeData.pageNumber <= 1) {
      return
    }

    setCurrentPageNumber(financeData.pageNumber - 1)
  }

  const handleNextPage = () => {
    if (
      !financeData.pageNumber ||
      financeData.pageNumber >= financeData.pageCount
    ) {
      return
    }

    setCurrentPageNumber(financeData.pageNumber + 1)
  }

  const handleFileExport = async () => {
    try {
      setIsExportingFile(true)

      const response = await axios({
        method: 'POST',
        url: `/v2/api/LeadGenerator/DowloadFinaceExcelData`,

        data: {
          StartDate: rearrangeDate(startDate),
          EnsDate: rearrangeDate(endDate),
        },

        headers: {
          Authorization: `Bearer ${localStorage.getItem('adminInfo')}`,
        },
        responseType: 'blob',
      })

      fileDownload(response.data, 'health-finance-data.xlsx')
    } catch (error) {
      if (error.response && typeof error.response.data === 'string') {
        alert(error.response.data)
      } else {
        alert(error.message)
      }
    } finally {
      setIsExportingFile(false)
    }
  }

  return (
    <>
      <Navbar />

      <div className="container-fluid mt-3 health__finance__admin">
        <div className="container mb-4 d-flex justify-content-between health__finance__admin__header">
          <div className="health__finance__admin__header--title">
            <h3>Health Finance Admin</h3>
          </div>
          <div className="health__finance__admin__header--logout-cta">
            <CustomButton onClick={handleLogout}>Logout</CustomButton>
          </div>
        </div>

        <div className="health__finance__admin__content">
          <div className="d-flex flex-wrap justify-content-between align-items-end health__finance__admin__content__filter">
            <div>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon1">
                    <FiSearch />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </InputGroup>
            </div>
            <div className="d-flex flex-wrap">
              <div className="mb-3 me3">
                <label htmlFor="startDate">Start Date</label>{' '}
                <input
                  type="date"
                  className="form-control"
                  id="startDate"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="endDate">End Date</label>{' '}
                <input
                  type="date"
                  id="endDate"
                  className="form-control"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </div>
            <div className="pb-3ms-auto ">
              <CustomButton
                outline
                onClick={handleFileExport}
                disabled={isExportingFile && true}
              >
                {isExportingFile ? 'Exporting...' : 'Export as Excel'}
              </CustomButton>
            </div>
          </div>
          <Table responsive bordered striped>
            <thead>
              <tr>
                <th>s/n</th>
                <th>Name</th>
                <th>Business Name</th>
                <th>Business Address</th>
                <th>Business Type</th>
                <th>Email</th>
                <th>Amount</th>
                <th>Phone Num.</th>
                <th>Submitted on</th>
                <th>Comment</th>
              </tr>
            </thead>

            <tbody>
              {isLoading && (
                <tr>
                  <td colSpan="10" className="text-center my-2">
                    <LoadingIndicator />
                  </td>
                </tr>
              )}
              {!isLoading &&
                financeData?.data?.map((data, index) => (
                  <tr key={data.id}>
                    <td>{index + 1}</td>
                    <td>{data.name}</td>
                    <td>{data.businessName}</td>
                    <td>{data.businessAddress}</td>
                    <td>{data.businessType}</td>
                    <td>{data.email}</td>
                    {/* <td>{data.amount}</td> */}
                    <td className="text-right">
                      <NumberFormat
                        thousandSeparator={true}
                        displayType={'text'}
                        prefix="&#8358;"
                        value={data.amount.toFixed(2)}
                      />
                    </td>
                    <td>{data.phonenumber}</td>
                    <td>{new Date(data.dateSubmitted).toDateString()}</td>
                    <td>{data.comment}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <div className="my-2 d-flex justify-content-end">
            <HealthInsuredPagination
              prev={handlePrevPage}
              next={handleNextPage}
              pageDetails={financeData}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default HealthFinanceAdmin
