import React from 'react';

import SubscriptionSection from '../subscriptionSection/SubscriptionSection';
import NavBar from '../../navbar/NavBar';

const MainSection = ({
  handleShowCardModal,
  cards,
  handleShowCancelModal,
  loading
}) => {

  return (
    <main className='axa-mansard-dashboard-mainsection px-3 pb-4 .axa-main'>
      <NavBar />
      <SubscriptionSection
        handleShowCardModal={handleShowCardModal}
        handleShowCancelModal={handleShowCancelModal}
        cards={cards}
        loading={loading}
      />
    </main>
  );
};

export default MainSection;