import { GET_USER_NOTIFICATIONS_ASYNC } from '../actions/actionTypes'
const INITIAL_STATE = {
	notifications: {
		status: true,
		responseCode: 0,
		message: '',
		data: [],
		pageNumber: 1,
		pageSize: 1,
		pageCount: 1,
		recordCount: 0,
	},
}

export const userNotificationsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_USER_NOTIFICATIONS_ASYNC:
			return { ...state, notifications: action.payload }
		default:
			return state
	}
}
