import React, { useEffect } from 'react'
import axios from 'axios'
import { withRouter, Redirect } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

import BackgroundTemplate from '../Templates/BackgroundTemplate'
import CustomInput from '../Templates/CustomInput'
import CustomButton from '../Templates/CustomButton'

const HBSetNewPassword = ({ user, history }) => {
  const getEmailUrl = new URLSearchParams(history.location.search)
  const email = getEmailUrl.get('email')
  const emailToken = getEmailUrl.get('emailToken')
  const destination = getEmailUrl.get('destination')

  const dispatch = useDispatch()

  const newPasswordSchema = Yup.object({
    password: Yup.string()
      .min(8, 'Password must be 8 characters or more')
      .required('Required')
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/,
        'Must Contain 8 Characters or more, 1 Uppercase, 1 Lowercase and 1 Number and a special character.'
      ),

    confirmPassword: Yup.string()
      .required('Required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  })

  const handleSetNewPassword = async (values, { resetForm, setSubmitting }) => {
    const { password, confirmPassword } = values

    try {
      //Check if the URL contains the destination parameter
      // If true, then the user is setting up the password as an ADMIN
      // Else, the SUPER ADMIN is trying to set a new password

      const SET_NEW_PASSWORD_API = `/v2/api/Identity/ResetPassword?email=${encodeURIComponent(
        email
      )}&emailToken=${encodeURIComponent(emailToken)}`

      const SET_ADMIN_PASSWORD_API = `/v1/api/Identity/AdminReg?email=${encodeURIComponent(
        email
      )}&emailToken=${encodeURIComponent(emailToken)}`

      await axios({
        method: 'post',
        url:
          destination === 'adminreg'
            ? SET_ADMIN_PASSWORD_API
            : SET_NEW_PASSWORD_API,

        data:
          destination === 'adminreg'
            ? {
                NewPassword: password,
                ConfirmPassword: confirmPassword,
              }
            : { password, confirmPassword },
      }).then((response) => response.data)

      alert('Password Reset Successful! \nProceed to Sign In.')

      resetForm({})

      history.push('/signin')
    } catch (error) {
      if (error.response) {
        if (error.response.data.responseCode === 23) {
          history.push('/resend_email_link')
          return
        }
        alert(error.response.data.message)
      } else if (error.request) {
        alert('Response Error! \nPlease try again or contact support')
      } else {
        alert('An Error occured! \nPlease try again or contact support. ')
      }
    } finally {
      setSubmitting(false)
    }
  }

  //@desc If page is loaded for admin registration,
  //clear localStorage and redirect to 'set new password' page holding token
  useEffect(() => {
    if (destination === 'adminreg') {
      localStorage.clear()
      dispatch({ type: 'SIGN_OUT' })
      history.push(`${history.location.pathname}${history.location.search}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!user.isAuth || (user.isAuth && destination === 'adminreg')) {
    return (
      <BackgroundTemplate
        formTitle="Almost there!"
        subTitle="Set a new password to continue"
        showLogo={true}
      >
        <Formik
          initialValues={{
            password: '',
            confirmPassword: '',
          }}
          validationSchema={newPasswordSchema}
          onSubmit={(values, { resetForm, setSubmitting }) =>
            handleSetNewPassword(values, {
              resetForm,
              setSubmitting,
            })
          }
        >
          {(formik) => (
            <Form>
              <Field
                as={CustomInput}
                label="New password"
                type="password"
                name="password"
                placeholder="Enter a new password"
                autoFocus
              />

              <Field
                as={CustomInput}
                label="Confirm new password"
                type="password"
                name="confirmPassword"
                placeholder="Confirm new password"
              />

              <CustomButton
                type="submit"
                disabled={formik.isSubmitting ? true : false}
              >
                {formik.isSubmitting
                  ? 'Setting New Password...'
                  : 'Set Password'}
              </CustomButton>
            </Form>
          )}
        </Formik>
      </BackgroundTemplate>
    )
  } else {
    // return <Redirect to="/dashboard" />
    return <Redirect to="/" />
  }
}

const mapStateToProps = (state) => ({
  user: state.user.userInfo,
})

export default connect(mapStateToProps)(withRouter(HBSetNewPassword))
