import React from 'react';
import { Modal } from 'react-bootstrap';
import { RiVisaLine } from 'react-icons/ri';

import MasterCard from '../../../assets/mastercard.png';
import VerveCard from '../../../assets/verveCard.png';

const CardModal = ({
  show,
  handleClose,
  handleAction,
  loading,
  cards,
  handleChange
}) => {

  const chosenCard = cards.length !== 0 && cards.map((card, index) => (
    <div className='input-wrapper' key={index}>
      <input type='radio' value={card.id} className='checked-card' name='checked-card' id='#checked-input' onChange={handleChange} data-status={card.status}></input>
      <label className='last-digit-wrapper' htmlFor='checked-input'>
        <span className='last-digit' >• • • •{card.lastFourDigit}</span>
        {card.type.trim() === 'master' ?
          <img src={MasterCard} alt='master card' className='master-card-logo' /> :
          card.type.trim() === 'verve' ? <img src={VerveCard} alt='verve logo' className='master-card-verve' /> :
            <RiVisaLine className='master-visa-logo' />
        }
      </label>
    </div>
  ));

  return (
    <div>
      <Modal show={show} onHide={handleClose} dialogClassName='axa-mansard-change-card-modal'>
        <Modal.Header className='header' closeButton></Modal.Header>

        <Modal.Body bsPrefix='modal-body'>
          <h2 className='title'>Change card</h2>
          <p className='message'>Select your preferred card.</p>
          <form className='p-0' id='change-card' onSubmit={handleAction}>
            {chosenCard}
          </form>
        </Modal.Body>

        <Modal.Footer bsPrefix='modal-footer'>
          <button
            className="action"
            disabled={loading ? true : false}
            form='change-card'>
            {loading ? 'Save...' : 'Save'}
          </button>
          <button className="cancel" onClick={handleClose}>Cancel</button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CardModal;
