import {
  RESEND_CORPORATE_USER_OTP_FAILED,
  RESEND_CORPORATE_USER_OTP_REQUEST,
  RESEND_CORPORATE_USER_OTP_SUCCESS,
} from '../../healthInsuredTypes/corporateActionTypes'

export const resendCorporateUserOtpRequest = () => ({
  type: RESEND_CORPORATE_USER_OTP_REQUEST,
})

export const resendCorporateOtpSuccess = (data) => ({
  type: RESEND_CORPORATE_USER_OTP_SUCCESS,
  payload: data,
})

export const resendCorporateOtpFailed = (errorMsg) => ({
  type: RESEND_CORPORATE_USER_OTP_FAILED,
  payload: errorMsg,
})
