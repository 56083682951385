import React, { useState } from 'react'
import './styles/create_wallet.css'
import NavWallet from '../NavWallet'
import Progress from '../../Progress'
import Payment from '../../Payment'
import '../../styles/CreateWallet.css'
import useGetOtp from '../walletHooks/useGetOtp'
import { ToastContainer } from 'react-toastify'

export let _phoneNumber = ''

function CreateWallet() {
  const [phoneNumber, setPhoneNumber] = useState('')

  const method = 'POST'
  const url = `/v2/api/Wallet/GenerateOTPForNewWallet`
  const nextUrl = '/pay_with_wallet/create_wallet/input_otp'

  const { isLoading, handleClick } = useGetOtp(
    method,
    url,
    nextUrl,
    phoneNumber
  )

  _phoneNumber = phoneNumber

  return (
    <section className="cr-wall-sect">
      <NavWallet />
      <Payment />
      <div className="create-align">
        <div className="create_wallet">
          <div className="inner-create">
            <div className="dig_wall">
              <h2>Create your digital wallet</h2>
              <p>
                A One Time Password (OTP) will be sent to verify your phone
                number.
              </p>

              <div className="part-create">
                <label>Enter your phone number</label>
                <br />

                <input
                  type="tel"
                  maxLength={11}
                  placeholder="070-333-33333"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
            </div>
            <button disabled={isLoading} onClick={handleClick}>
              <p>{isLoading ? 'Sending OTP...' : 'Send OTP'}</p>
            </button>
            <ToastContainer />
          </div>
        </div>
      </div>
      <Progress />
    </section>
  )
}

export default CreateWallet
