import React, { useReducer, useState } from 'react'
import { IoMdRadioButtonOn } from 'react-icons/io'
import { AiFillCheckCircle } from 'react-icons/ai'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { config } from 'dotenv'

import Header from '../template/header/Header'
import OtpInputField from '../template/otpInputField/OtpInputField'
import CustomButton from '../template/customButton/CustomeButton'

const optUrl = '/v2/api/Tokenization/SubmitOtp'
const baseUrl = process.env.REACT_APP_BASE_URL

const Otp = ({ history }) => {
  const [validationErrors, setValidationErrors] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const pinReference = useSelector((state) => state.axaUserPinReference)
  const [otp, setOtp] = useState('')
  // const [otp, setOtp] = useReducer(
  //   (state, newState) => ({ ...state, ...newState }),
  //   {
  //     one: '',
  //     two: '',
  //     three: '',
  //     four: '',
  //     five: '',
  //     six: '',
  //   }
  // )

  const { one, two, three, four, five, six } = otp
  const otpPin = `${one}${two}${three}${four}${five}${six}`

  config() //instantiate environmental variables

  const validateOtpInput = () => {
    const error = {
      otpError: '',
    }

    // const serializedOtp = otpPin.split(' ').join('')

    // if (serializedOtp === '') {
    //   error.otpError = 'Otp is required'
    //   return error
    // }
    if (otp === '') {
      error.otpError = 'Otp is required'
      return error
    }

    const regex = RegExp(/^[A-Z]+$/i)

    if (regex.test(Number(otp))) {
      error.otpError = 'otp must not contain alphabet'
      return error
    }

    if (otp.length > 9 || otp.length < 4) {
      error.otpError = 'Not a valid Otp'
      return error
    }

    return false
  }

  // capture user inputs
  const handleChange = (e) => {
    const { name, value } = e.target
    setOtp({ [name]: value })
    setValidationErrors('')
  }

  // auto focus next or previous text input on keyUp
  const handleKeyUp = (e) => {
    const input = e.target
    const eventCode = e.keyCode

    if ((eventCode === 8 || eventCode === 37) && input.previousElementSibling) {
      //focus previous input if keypress is backspace or delete
      input.previousElementSibling.focus()
    } else if (
      ((eventCode >= 48 && eventCode <= 57) ||
        (eventCode >= 65 && eventCode <= 90) ||
        (eventCode >= 96 && eventCode <= 105) ||
        eventCode === 39) &&
      input.nextElementSibling
    ) {
      input.nextElementSibling.focus() // focus the next input if keypress is any alphanumeric character
    } else {
      return
    }
  }

  const otpValidation = async (e) => {
    e.preventDefault()

    const validationErrors = validateOtpInput()
    setValidationErrors(validationErrors)
    if (validationErrors) return

    const data = {
      otp,
      // otp: otpPin,
      pin: pinReference.pin,
      reference: pinReference.reference,
    }
    setLoading(true)

    try {
      const response = await axios.post(`${baseUrl}${optUrl}`, data)
      setLoading(false)

      if (response.status === 200) {
        history.push('/health_success_signup')
      }
    } catch (error) {
      setLoading(false)
      if (error.response) {
        setError(error.response.data.message)
      } else {
        console.log(error.message)
      }
    }
  }

  return (
    <div className="axa-mansard-sign-up-payment-details">
      <Header />

      <div className="form-container">
        <div className="personal-details-form">
          <form className="personal-form" onSubmit={otpValidation}>
            <h1 className="start-your">Complete your payment</h1>
            <p className="provide-some mb-4">Provide your One Time Pin</p>

            <p className="enter-otp">
              Enter the OTP sent to your phone number to continue
            </p>

            <div className="d-flex">
              <input
                className="w-h-100"
                type="tel"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
              {/* <OtpInputField
                inputType={'tel'}
                handleChange={handleChange}
                inputValue={otp.one}
                inputName="one"
                handleKeyUp={handleKeyUp}
                max="1"
              /> */}
            </div>
            {error && (
              <menu
                className={
                  error
                    ? 'error-message error-message-show text-danger m-0 p-0'
                    : 'error-message m-0 p-0'
                }
              >
                <small>*{error}</small>
              </menu>
            )}
            {validationErrors.otpError && (
              <p className="text-danger text-justify d-block m-0 w-100">
                <small>*{validationErrors.otpError}</small>
              </p>
            )}
            <CustomButton
              disable={loading ? true : false}
              buttonText={loading ? 'Confirming...' : 'Confirm'}
            />

            <p className="resend-otp">
              You can resend OTP in{' '}
              <span className="text-danger">25 seconds</span>
            </p>
          </form>
        </div>

        <div className="progres">
          <ul>
            <li>
              <AiFillCheckCircle className="icon me3 fulfilled" />
              <span className="inactive active-inactive">Personal details</span>
            </li>

            <hr className="z" />

            <li>
              <AiFillCheckCircle className="icon me3 fulfilled" />
              <span className="inactive active-inactive">Bank information</span>
            </li>

            <hr className="z-z" />

            <li>
              <AiFillCheckCircle className="icon me3 fulfilled" />
              <span className="inactive">Care provider details</span>
            </li>

            <hr className="z-z-z" />

            <li>
              <IoMdRadioButtonOn className="icon me3 fulfilled" />
              <span className="active">Payment information</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Otp
