import {
  HEALTH_INSURED_INDIVIDUAL_PROFILE_REQUEST,
  HEALTH_INSURED_INDIVIDUAL_PROFILE_SUCCESS,
  HEALTH_INSURED_INDIVIDUAL_PROFILE_FAILED,
} from '../healthInsuredTypes/healthInsuredIndividualProfile'

export const getHealthInsuredIndividualProfileRequest = () => ({
  type: HEALTH_INSURED_INDIVIDUAL_PROFILE_REQUEST,
})

export const getHealthInsuredIndividualProfileSuccess = (data) => ({
  type: HEALTH_INSURED_INDIVIDUAL_PROFILE_SUCCESS,
  payload: data,
})

export const getHealthInsuredIndividualProfileFailed = (data) => ({
  type: HEALTH_INSURED_INDIVIDUAL_PROFILE_FAILED,
  payload: data,
})
