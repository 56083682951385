import React from 'react';

import ProfileDp from '../../../../../../assets/healthInsuredAdmin/adminProfile.png';

const TicketList = () => {
  return (
    <div className='health__insured__admin__support__notification__list'>
      <div className='health__insured__admin__support__notification__list--container-img'>
        <img src={ProfileDp} alt='profile picture' className='health__insured__admin__support__notification__list--img' />
      </div>

      <div>
        <h4 className='health__insured__admin__support__notification__list--heading'>Request for Onboarding</h4>
        <p className='health__insured__admin__support__notification__list--para'>This is the request text, this is the description.</p>
        <time datetime="20:00" className='health__insured__admin__support__notification__list--time'>20:00</time>
      </div>

    </div>
  );
};

export default TicketList;
