import React from 'react'
import { Bar } from 'react-chartjs-2'
import { Dropdown } from 'react-bootstrap'
import { FiCalendar } from 'react-icons/fi'

// import './userAquisitionChart.scss'

const SubscriberAquisitionChart = ({ data, filterByTimeFrame }) => {
  const subscriberAcquisitions = () => {
    let chartLabels = []
    let chartData = []

    if (data.hasOwnProperty('data')) {
      chartLabels = (data.data.subscriberAcquisitions || []).map(
        (item) => item.name
      )
      chartData = (data.data.subscriberAcquisitions || []).map(
        (item) => item.count
      )
    }

    return { chartLabels, chartData }
  }

  const graphData = {
    labels: [...subscriberAcquisitions().chartLabels],
    datasets: [
      {
        label: 'Subscribers',
        data: [...subscriberAcquisitions().chartData],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          //   '#1789FC',
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 2,
      },
    ],
  }
  return (
    <div className="healthInsured__user-aquisition-chart">
      <div className="healthInsured__user-aquisition-chart__header">
        <h2 className="healthInsured__user-aquisition-chart__header--title">
          Subscriber Aquisition
        </h2>
        <div>
          <div className="form-group">
            <select
              className="form-control"
              onChange={(e) => filterByTimeFrame(Number(e.target.value))}
            >
              <option value="">This Year</option>
              <option value="1">Monthly</option>
              <option value="2">Weekly</option>
            </select>
          </div>

          {/* <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
              <span className="px-2">
                <FiCalendar size="20" style={{ color: '#D71E1B' }} />
              </span>
              <span className="px-2">This Year</span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
              <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
              <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
        </div>
      </div>
      <Bar data={graphData} />
    </div>
  )
}

export default SubscriberAquisitionChart
