import React from 'react';
import { Modal } from "react-bootstrap";

const ResponseModal = ({
  show,
  handleHide,
  error,
  success
}) => {

  return (

    <Modal
      show={show}
      onHide={handleHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p className="text-primary  modal-p">{success}</p>
        <p className="text-danger  modal-p">{error}</p>
      </Modal.Body>
    </Modal>
  );
};

export default ResponseModal;
