import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import debounce from 'lodash.debounce'

import * as FileSaver from 'file-saver'

import StatsCard from '../statsCard/StatsCard'
import RevenueIcon from '../../../../../../../assets/healthInsuredAdmin/revenueIcon.png'
import TotalRevenueIcon from '../../../../../../../assets/healthInsuredAdmin/totalRevenueIcon.png'
import UserIcon from '../../../../../../../assets/healthInsuredAdmin/userIcon.png'
import SearchInput from '../searchInput/SearchInput'
import Filter from '../filter/Filter'
import ExportButton from '../exportButton/ExportButton'
import Table from '../table/Table'
import Pagination from '../../../../../template/pagination/Pagination'
import { useFetchdata } from '../FetchData'
import Loader from '../../../../../../pharmHub/admin/components/Spinner/Spinner'
import { filter } from '../filterOption'
import exportButton from '../../../../../../../healthBancAdmin/components/template/exportTableData'
import ResponseToast from '../../../../../template/responseToast/ResponseToast'
import EmptyUser from '../../../../../template/emptyList/EmptyList'
import ExportAllToExcel from '../../../ExportAll/ExportAll'

const statsUrl = `/v2/api/DashboardAnalytics/HealthInsuredDashBoardAnalytics`
const insuranceUrl = `/v1/api/Insurance/GetPaginatedInsuranceProfiles?Filter=${2}&PageSize=${50}`

const MainSection = () => {
  const [users, setUrl] = useFetchdata(insuranceUrl)
  const [stats, setStats] = useState(null)
  const [token, setToken] = useState(localStorage.getItem('adminInfo'))
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [searchTerms, setSearchTerms] = useState(' ')
  const [filterTerms, setFilterTerms] = useState(0)

  const [allAxaSubscribers, setAllAxaSubscribers] = useState([])
  const fileName = 'AxaMansard Subscribers'

  const debounceSave = useRef(debounce((value) => setUrl(value), 500)).current
  const history = useHistory()

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)

        const { data } = await axios.post(`${statsUrl}`, {
          data: { Id: 2 },
          headers: { Authorization: `Bearer ${token}` },
        })

        setStats(data.data)
        setLoading(false)
      } catch (error) {
        error.response
          ? setError(error.response.data.message)
          : setError(error.message)
        setLoading(false)
      }
    })()
  }, [])

  useEffect(() => {
    const fetchAxaSubscribers = async () => {
      const { data } = await axios.post(
        `/v2/api/Insurance/DowloadInsuranceProfileExcelData`,
        {
          responseType: 'blob',
          data: {
            ActiveStatus:
              filterTerms === 0 ? '' : filterTerms === 1 ? true : false,
            Service: 'axamansard',
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('adminInfo')}`,
          },
        }
      )

      data && setAllAxaSubscribers([data])
    }

    fetchAxaSubscribers()
  }, [filterTerms])

  const handleSearch = (e) => {
    setSearchTerms(e.target.value)
    setFilterTerms(' ')
    debounceSave(`${insuranceUrl}&SearchText=${e.target.value}`)
  }

  const handleFilter = (e) => {
    const filterValue = Number(e.target.value)
    setSearchTerms(' ')
    setFilterTerms(' ')
    setFilterTerms(filterValue)
    setUrl(`${insuranceUrl}&Status=${filterValue}`)
  }

  const handlePagination = (pagenumber) => {
    if (pagenumber < 1) return
    if (pagenumber > users.users.pageCount) return

    let filterValue = !filterTerms ? 0 : filterTerms
    setUrl(
      `${insuranceUrl}&PageNumber=${pagenumber}&Status=${filterValue}&SearchText=${searchTerms}`
    )
  }

  const handleTableExport = () => {
    exportButton('axamansard-table')
  }

  const closeToast = () => {
    setError(' ')
    users.setError(' ')
  }

  const revenueDue = <span>&#x20A6;{stats ? stats.revenueDue : 0}</span>
  const totalRevenue = <span>&#x20A6;{stats ? stats.toatlRevenue : 0}</span>

  const tableRows =
    users.users &&
    users.users.data.length > 0 &&
    users.users.data.map((customer, index) => (
      <tr
        key={index}
        onClick={() =>
          history.push({
            pathname: `/admin/health_insured/users/profile/${customer.surname}`,
            state: { userPersonalInfo: customer },
          })
        }
        style={{ cursor: 'pointer' }}
      >
        {/* <td><input type='checkbox' /></td> */}
        <td>
          {customer.surname} {customer.othernames}
        </td>
        <td>{customer.email}</td>
        <td>{customer.phoneNumber}</td>
        <td>{customer.companyName ? customer.companyName : 'N/A'}</td>
      </tr>
    ))

  const filterOption =
    filter &&
    filter.map((option) => (
      <option key={option.id} value={option.id}>
        {option.name}
      </option>
    ))

  return (
    <section className="health__insured__admin__provider__section__hygiea">
      <ResponseToast
        error={error}
        showToast={error ? true : false}
        closeToast={closeToast}
        delay={5000}
        right={500}
        top={-70}
      />

      <ResponseToast
        error={users.error}
        showToast={users.error ? true : false}
        closeToast={closeToast}
        delay={5000}
        right={500}
        top={-70}
      />

      {loading ? (
        <Loader />
      ) : (
        <div className="health__insured__admin__provider__section__hygiea--card-container">
          <StatsCard
            img={RevenueIcon}
            alt="Revenue Due"
            title="Revenue Due"
            amount={revenueDue}
          />
          <StatsCard
            img={TotalRevenueIcon}
            alt="Total Revenue"
            title="Total Revenue"
            amount={totalRevenue}
          />

          <StatsCard
            img={UserIcon}
            alt="Users"
            title="Users"
            amount={stats ? stats.totalUser : 0}
          />
        </div>
      )}

      <section className="health__insured__admin__provider__section__hygiea--table-container">
        <h4 className="health__insured__admin__provider__section__hygiea--table--container-heading">
          User Information
        </h4>
        <div className="health__insured__admin__provider__section__hygiea--search--container">
          <SearchInput
            handleChange={handleSearch}
            searchName="hygeai__search"
            searchTerms={searchTerms}
          />
          <Filter filterOptions={filterOption} filter={handleFilter} />
          {/* <ExportButton handleTableExport={handleTableExport} /> */}
          <ExportAllToExcel apiData={allAxaSubscribers} fileName={fileName} />
        </div>

        {users.loading ? (
          <Loader />
        ) : users.users && users.users.data.length > 0 ? (
          <>
            <Table tableRow={tableRows} tableId="axamansard-table" />
            <hr />
            <Pagination
              pageNumber={users.users.pageNumber}
              pageCount={users.users && users.users.pageCount}
              handlePagination={handlePagination}
            />
          </>
        ) : users.users && users.users.data.length > 0 ? (
          <Pagination
            pageNumber={users.users.pageNumber}
            pageCount={users.users && users.users.pageCount}
            handlePagination={handlePagination}
          />
        ) : (
          <EmptyUser
            description="A list of all users will appear here once they are available."
            title="Nothing to see here"
          />
        )}
      </section>
    </section>
  )
}

export default MainSection
