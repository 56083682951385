import { put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'

import {
  getHealthInsuredSubscriberAcquisitionFailed,
  getHealthInsuredSubscriberAcquisitionRequest,
  getHealthInsuredSubscriberAcquisitionSuccess,
} from '../../actions/healthInsuredCreators/adminUserActions/healthInsuredSubscriberAcquisition'

function* healthInsuredSubscriberAcquisition({ payload = 0 }) {
  try {
    yield put(getHealthInsuredSubscriberAcquisitionRequest())

    const { data } = yield axios({
      method: 'post',
      url: `/v2/api/DashboardAnalytics/HealthInsuredSubscriberAcquisition
      }`,
      data: { Id: payload === 0 ? '' : payload },
      headers: { Authorization: `Bearer ${localStorage.getItem('adminInfo')}` },
    }).then((response) => response)

    yield put(getHealthInsuredSubscriberAcquisitionSuccess(data))
  } catch (error) {
    yield put(getHealthInsuredSubscriberAcquisitionFailed(error.response))
  }
}

export function* watchGetHealthInsuredSubscriberAcquisition() {
  yield takeEvery(
    'GET_HEALTHINSURED_SUBSCRIBER_ACQUISITION',
    healthInsuredSubscriberAcquisition
  )
}
