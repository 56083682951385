import { call, put, takeEvery } from 'redux-saga/effects'
import {
  createFamilyProfileFailed,
  createFamilyProfileRequested,
  createFamilyProfileSuccess,
} from '../../../reducer/healthInsuredReducer/healthInsuredFamily/createFamilyProfileReducer'
import { createFamilyProfileAPI } from './familyApis'

function* handleCreateFamilyProfile(payload) {
  try {
    yield createFamilyProfileRequested()

    const { data } = yield call(createFamilyProfileAPI)

    payload === 'friend'
      ? window.location.replace('/health-family')
      : window.location.replace('/health-friend')

    yield put(createFamilyProfileSuccess(data))
  } catch (error) {
    yield put(createFamilyProfileFailed(error.response))
  }
}

export function* watchCreateFamilyProfile() {
  yield takeEvery(createFamilyProfileRequested.type, handleCreateFamilyProfile)
}
