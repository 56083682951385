import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: {},
  errors: [],
}

const friendsSlice = createSlice({
  name: 'Friends',
  initialState,
  reducers: {
    GET_FRIENDS_REQUESTED: (state) => {
      return { isLoading: true, data: {}, errors: [] }
    },

    GET_FRIENDS_SUCCESSFUL: (state, action) => {
      return { isLoading: false, data: action.payload, errors: [] }
    },

    GET_FRIENDS_FAILED: (state, action) => {
      return { isLoading: false, data: {}, errors: [action.payload] }
    },
  },
})

export const {
  GET_FRIENDS_REQUESTED,
  GET_FRIENDS_SUCCESSFUL,
  GET_FRIENDS_FAILED,
} = friendsSlice.actions

export default friendsSlice.reducer
