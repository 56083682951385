import { call, put, takeEvery } from 'redux-saga/effects'
import {
  DEACTIVATE_FAMILY_MEMBER_FAILED,
  DEACTIVATE_FAMILY_MEMBER_REQUESTED,
  DEACTIVATE_FAMILY_MEMBER_SUCCESSFUL,
} from '../../../reducer/healthInsuredReducer/healthInsuredFamily/deactivateFamilyMemberReducer'
import { deactivateFamilyMemberAPI } from './familyApis'

function* handleDeactivate({ payload = {} }) {
  try {
    yield DEACTIVATE_FAMILY_MEMBER_REQUESTED()

    const { data } = yield call(deactivateFamilyMemberAPI, payload)

    alert(data.message)

    yield put(DEACTIVATE_FAMILY_MEMBER_SUCCESSFUL(data))
  } catch (error) {
    if (error.response) {
      alert(error.response.data?.message)
      return yield put(
        DEACTIVATE_FAMILY_MEMBER_FAILED(error.response.data.message)
      )
    }

    alert(error.message)
  }
}

export function* watchDeactivateFamilyMember() {
  yield takeEvery(DEACTIVATE_FAMILY_MEMBER_REQUESTED.type, handleDeactivate)
}
