import { takeLatest, put } from 'redux-saga/effects'
import axios from 'axios'
import {
  createPharmacyRequested,
  createPharmacySuccessful,
  createPharmacyFailed,
} from '../../../reducer/oneDrugStore/admin/pharmacySlices'

function* createPharmacyAsync({ payload }) {
  const { pharmaDetails, history } = payload

  try {
    yield createPharmacyRequested()

    const { data } = yield axios({
      method: 'POST',
      url: `${process.env.REACT_APP_ONE_DRUG_BASE}/v1/api/Pharmacy/Create?aggregatorId=${pharmaDetails.aggregatorId}`,
      data: pharmaDetails,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('adminInfo')}`,
      },
    })

    yield put(createPharmacySuccessful(data))
    yield history.goBack()
    yield (window.location.href = '/admin/OneDrugStore/aggregators/1')
  } catch (error) {
    if (error.response) {
      yield put(createPharmacyFailed(error.response.data))
      yield put(createPharmacyFailed(null))
      return
    }

    yield put(createPharmacyFailed(error.message))
    yield put(createPharmacyFailed(null))
  }
}

export function* watchCreatePharmacy() {
  yield takeLatest(createPharmacyRequested.type, createPharmacyAsync)
}
