import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BsDot } from 'react-icons/bs'

import HealthInsuredDashboardStructure from '../../HealthInsuredDashboardStructure/HealthInsuredDashboardStructure'
import { ScreenNameWithBackBtn } from '../../screens/healthInsuredUserProfile/HealthInsuredUserProfile'
import HealthInsuredPagination from '../../../HealthInsuredCorporateFlow/components/healthInsuredPagination/HealthInsuredPagination'

import './healthInsuredAdminUserTransactions.scss'

const HealthInsuredAdminUserTransactions = ({ history }) => {
  const dispatch = useDispatch()
  const transactionLog = useSelector(
    (state) => state.getHealthInsuredAdminTransactionLog
  )

  const userInfo =
    history.location.state && history.location.state.userPersonalInfo
      ? history.location.state.userPersonalInfo
      : {}

  useEffect(() => {
    dispatch({
      type: 'GET_HEALTHINSURED_ADMIN_TRANSACTION_LOG',
      payload: { email: userInfo.email },
    })
  }, [dispatch, userInfo])

  const handleGoToPrevPage = () => {
    const { pageNumber } = transactionLog.data
    if (pageNumber <= 1) {
      return
    }

    dispatch({
      type: 'GET_HEALTHINSURED_ADMIN_TRANSACTION_LOG',
      payload: { email: userInfo.email, pageNumber: pageNumber - 1 },
    })
  }

  const handleGoToNextPage = () => {
    const { pageCount, pageNumber } = transactionLog.data

    if (pageNumber === pageCount) {
      return
    }

    dispatch({
      type: 'GET_HEALTHINSURED_ADMIN_TRANSACTION_LOG',
      payload: { email: userInfo.email, pageNumber: pageNumber + 1 },
    })
  }

  const displayTransactionLog = (
    (transactionLog && transactionLog.data && transactionLog.data.data) ||
    []
  ).map((log, index) => (
    <tr key={index}>
      <td>&#8358;{log.amount}</td>
      <td>
        <BsDot size="1.2rem" className="text-success" />
        Active
      </td>
      <td>{new Date(log.date).toDateString()}</td>
      <td>
        {log.status === 'Successful' ? (
          <span className="healthInsured__user__profile--transaction-status success">
            {log.status}
          </span>
        ) : (
          <span className="healthInsured__user__profile--transaction-status failed">
            {log.status}
          </span>
        )}
      </td>
    </tr>
  ))

  return (
    <HealthInsuredDashboardStructure
      title={<ScreenNameWithBackBtn screenName="Transaction Log" />}
    >
      <section className="healthInsuredAdimUserTransactions">
        {/* <UserTransactionTable /> */}
        <table className="table healthInsured__user__profile--transaction-table">
          <thead>
            <tr>
              <th>Amount</th>
              <th>Enrollment Status</th>
              <th>Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {displayTransactionLog.length > 0 ? (
              displayTransactionLog
            ) : (
              <tr>
                <td colSpan="4" className="text-center">
                  No transaction logs
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <div className="d-flex justify-content-end mt-4">
          <HealthInsuredPagination
            prev={handleGoToPrevPage}
            next={handleGoToNextPage}
            pageDetails={
              transactionLog && transactionLog.data ? transactionLog.data : {}
            }
          />
        </div>
      </section>
    </HealthInsuredDashboardStructure>
  )
}

export default HealthInsuredAdminUserTransactions
