import { put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import {
  getHealthInsuredIndividualProfileFailed,
  getHealthInsuredIndividualProfileRequest,
  getHealthInsuredIndividualProfileSuccess,
} from '../../../actions/healthInsuredCreators/healthInsuredIndividualProfile'

function* handleIndividualProfile() {
  try {
    yield getHealthInsuredIndividualProfileRequest()

    const { data } = yield axios({
      method: 'GET',
      url: '/v2/api/Insurance/GetUserInsuranceProfile',
    })

    yield put(getHealthInsuredIndividualProfileSuccess(data))
  } catch (error) {
    if (error.response) {
      yield put(
        getHealthInsuredIndividualProfileFailed(
          error.response.data.message || error.message
        )
      )
    }
  }
}

export function* watchGetIndividualProfile() {
  yield takeEvery('HEALTH_INSURED_INDIVIDUAL_PROFILE', handleIndividualProfile)
}
