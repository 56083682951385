import { put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'

import {
  getHealthInsuredUserAcquisitionFailed,
  getHealthInsuredUserAcquisitionRequest,
  getHealthInsuredUserAcquisitionSuccess,
} from '../../actions/healthInsuredCreators/adminUserActions/healthInsuredUserAcquisition'

function* healthInsuredUsersAcquisition({ payload = 0 }) {
  try {
    yield put(getHealthInsuredUserAcquisitionRequest())

    const { data } = yield axios({
      method: 'post',
      url: `/v2/api/DashboardAnalytics/HealthInsuredUserAcquisition`,
      data: { Id: payload === 0 ? '' : payload },
      headers: { Authorization: `Bearer ${localStorage.getItem('adminInfo')}` },
    }).then((response) => response)

    yield put(getHealthInsuredUserAcquisitionSuccess(data))
  } catch (error) {
    yield put(getHealthInsuredUserAcquisitionFailed(error.response))
  }
}

export function* watchGetHealthInsuredUserAcquisition() {
  yield takeEvery(
    'GET_HEALTHINSURED_USERS_ACQUISITION',
    healthInsuredUsersAcquisition
  )
}
