import { takeEvery, put } from 'redux-saga/effects'
import axios from 'axios'

import {
  getHealthInsuredAdminUserActivityLogsFailed,
  getHealthInsuredAdminUserActivityLogsRequest,
  getHealthInsuredAdminUserActivityLogsSuccess,
} from '../../actions/healthInsuredCreators/adminUserActions/getHealthInsuredAdminUserActivityLogs'

function* getHealthInsuredAdminUserActivityLogs({ payload }) {
  const { pageNumber = 1, email = '' } = payload

  try {
    yield put(getHealthInsuredAdminUserActivityLogsRequest())
    const { data } = yield axios({
      method: 'post',
      url: `/v2/api/ActivityLog/GetHealthInsuredPaginatedActivityLogByEmail`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('adminInfo')}`,
      },
      data: {
        Email: email,
        PageNumber: pageNumber,
      },
    }).then((response) => response)

    yield put(getHealthInsuredAdminUserActivityLogsSuccess(data))
  } catch (error) {
    yield put(getHealthInsuredAdminUserActivityLogsFailed(error.response))
  }
}

export function* watchGetHealthInsuredAdminUserActivityLogs() {
  yield takeEvery(
    'GET_HEALTHINSURED_ADMIN_USER_ACTIVITY_LOGS',
    getHealthInsuredAdminUserActivityLogs
  )
}
