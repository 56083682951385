import React from 'react'
import HmoCard from '../hmoCard/HmoCard'

const Hmo = () => {
  return (
    <section className="axa-mansard-hmo">
      <h2 className="why-you-need text-center px-3">
        Why choose HealthInsured?
      </h2>

      <div className="container">
        <div className="row">
          <HmoCard
            title="1 month free"
            message="We are giving you a 100% FREE cover for the 
            first month after you sign up, which means you only
            get to pay for 11 months in the first year!
            It’s never been easier."
          />
          <HmoCard
            title="Flexible Monthly payment"
            message="With as low as N1000 naira per month, 
            your family and loved ones can have a safe and 
            secure health all year round. Yes, it’s as real
            as it sounds."
          />
          <HmoCard
            title="Efficient Hospitals"
            message="All our hospitals have passed our strict
            quality assurance check and you are sure to be cared 
            for with high standards medical practice."
          />
        </div>
      </div>
    </section>
  )
}

export default Hmo
