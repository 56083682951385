import React from 'react';
import { Modal } from 'react-bootstrap';

import { ReactComponent as Close } from '../../../../../../assets/healthInsuredAdmin/close.svg';
import ResponseToast from '../../../../template/responseToast/ResponseToast';

const ManageAdmin = ({
  showRemoveAdmin,
  closeRemoveAdmin,
  removeAdmin,
  showRemoveToast,
  showRemoveSuccess,
  showRemoveError,
  showRemoveErrorToast,
  closeToast,
  removeAdminLoading,
}) => {

  return (
    <Modal
      show={showRemoveAdmin}
      onHide={closeRemoveAdmin}
      dialogClassName="modal-sz health__insured__admin__remove__admin__modal"
      aria-labelledby="remove admin modal"
      animation={true}
      autoFocus={true}
      keyboard={true}
    >
      <Modal.Body
        className='health__insured__admin__remove__admin__modal--body'
      >
        <ResponseToast
          success={showRemoveSuccess}
          showToast={showRemoveToast}
          closeToast={closeToast}
          delay={5000}
          right={50}
        />

        <ResponseToast
          error={showRemoveError}
          showToast={showRemoveErrorToast}
          closeToast={closeToast}
          delay={5000}
          right={50}
        />

        <i className='health__insured__admin__remove__admin__modal--icon-wrapper' onClick={closeRemoveAdmin}>
          <Close className='health__insured__admin__remove__admin__modal--icon-close' />
        </i>
        <h2 className='health__insured__admin__remove__admin__modal--heading'>Remove Admin</h2>

        <p className='health__insured__admin__remove__admin__modal--para'>Are you sure you want to remove this admin?</p>

      </Modal.Body>
      <Modal.Footer className='health__insured__admin__remove__admin__modal--footer'>
        <button className='health__insured__admin__remove__admin__modal--btn-remove' onClick={removeAdmin}>{removeAdminLoading ? 'Removing...' : 'Remove'}</button>
        <button className='health__insured__admin__remove__admin__modal--btn-cancel' onClick={closeRemoveAdmin}>Cancel</button>
      </Modal.Footer>
    </Modal>
  );
};

export default ManageAdmin;
