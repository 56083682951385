import React, { useState, useEffect } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { Row, Col, ListGroup } from 'react-bootstrap'
import { FiHome } from 'react-icons/fi'
import { RiGroupLine } from 'react-icons/ri'
import Header from '../../healthInsuredIndividualFlow/signup/template/header/Header'
import './corporateDashboardStructure.scss'

const CorporateDashboard = ({ children, screenTitle, actionBtn, ...props }) => {
  const [activeTab, setActiveTab] = useState({
    home: false,
    profile: false,
    beneficiaries: false,
  })

  const handleSelectTab = (e) => {
    setActiveTab({ [e.target.name]: true })
  }

  return (
    <div>
      <Header />
      <div className='corporate__dashboard'>
        <Row className='content__area m-0 '>
          <Col
            md={3}
            className='p-0 px-2 content__area--sidebar d-none d-lg-block'
          >
            <div className='content__area--sidebar--menu'>
              <ListGroup className='flush'>
                <Link to='/health_corporate/dashboard'>
                  <ListGroup.Item
                    action
                    name='home'
                    onClick={(e) => handleSelectTab(e)}
                  >
                    <span className='pr-2'>
                      <FiHome />
                    </span>
                    <span>Home</span>
                  </ListGroup.Item>
                </Link>

                <Link to='/health_corporate/profile'>
                  <ListGroup.Item
                    action
                    name='profile'
                    onClick={(e) => handleSelectTab(e)}
                  >
                    <span className='pr-2'>
                      <RiGroupLine />
                    </span>
                    <span>Profile</span>
                  </ListGroup.Item>
                </Link>

                <Link to='/health_corporate/beneficiaries'>
                  <ListGroup.Item
                    action
                    name='beneficiaries'
                    onClick={(e) => handleSelectTab(e)}
                  >
                    <span className='pr-2'>
                      <RiGroupLine />
                    </span>
                    <span>Beneficiaries</span>
                  </ListGroup.Item>
                </Link>
              </ListGroup>
            </div>
          </Col>
          <Col className='content__area--main-content p-2 p-md-4'>
            <MobileMenuView />
            {(screenTitle || actionBtn) && (
              <Row className='screen__header mb-4'>
                <Col xs={12} md={6} className='screen__header--title'>
                  {screenTitle}
                </Col>
                <Col
                  xs={12}
                  md={6}
                  className='screen__header--actionBtn col-xs-12 col-sm-6 '
                >
                  <div className='ml-auto'>{actionBtn}</div>
                </Col>
              </Row>
            )}
            <Row className='mx-auto m-1 p-0 container'>{children}</Row>
          </Col>
        </Row>
      </div>
    </div>
  )
}

const MobileMenuView = () => {
  return (
    <Row className='d-block d-md-none'>
      <Col className='mx-auto mb-4'>
        <div className='content__area--sidebar--menu__mobile__view'>
          <ListGroup horizontal className='flush'>
            <ListGroup.Item action>
              <Link to='/health_corporate/dashboard'>
                <span className='pr-1'>
                  <FiHome />
                </span>
                <span>Home</span>
              </Link>
            </ListGroup.Item>

            <ListGroup.Item action>
              <Link to='/health_corporate/profile'>
                <span className='pr-1'>
                  <RiGroupLine />
                </span>
                <span>Profile</span>
              </Link>
            </ListGroup.Item>

            <ListGroup.Item action>
              <Link to='/health_corporate/beneficiaries'>
                <span className='pr-1'>
                  <RiGroupLine />
                </span>
                <span>Beneficiaries</span>
              </Link>
            </ListGroup.Item>
          </ListGroup>
        </div>
      </Col>
    </Row>
  )
}

export default CorporateDashboard
