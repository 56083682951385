import { put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { healthBancSigninAsync } from '../actions/actionCreators'

function* signin({ payload }) {
  const {
    values: { email, password, otp },
    afterSubmitAction: { setSubmitting, resetForm },
    history,
  } = payload

  const SIGNIN_API = '/v2/api/Identity/Login'

  try {
    const response = yield axios({
      method: 'post',
      url: SIGNIN_API,
      data: {
        EmailAddress: email,
        Password: password,
        OTP: otp.trim(),
      },
    }).then((response) => response.data.data)

    yield put(healthBancSigninAsync(response))

    // if (history.location.pathname === '/oneDrugStore/authentication') {
    //   // history.push('/v1/api/CardPayment/Pay?amount=')
    //   history.push('/OneDrugStore/cart')
    //   return
    // }

    const isFromOneDrugStore = window.localStorage.getItem('fromOneDrugStore')

    if (isFromOneDrugStore === 'true') {
      history.push('/OneDrugStore/cart')
      window.localStorage.removeItem('fromOneDrugStore')
      return
    }

    // history.push('/dashboard')
    history.push('/')

    resetForm()
  } catch (error) {
    if (error.response) {
      alert(error.response.data.message)
      window.location.reload()
    } else if (error.request) {
      alert('Response error! \nPlease try again or contact support.')
      window.location.reload()
    } else {
      // alert(error.message)
      alert('Login Error! \nPlease try again or contact support.')
      window.location.reload()
    }
  } finally {
    setSubmitting(false)
  }
}

// Watch for signin dispatch action
export function* watchHealthBancSignin() {
  yield takeEvery('HEALTHBANC_SIGNIN', signin)
}
