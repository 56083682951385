import { takeEvery, put } from 'redux-saga/effects'
import axios from 'axios'
import {
  deactivateActiveBeneficiariesFailed,
  deactivateActiveBeneficiariesRequest,
  deactivateActiveBeneficiariesSuccess,
} from '../../actions/healthInsuredCreators/corporateUserActions/deactivateActiveBeneficiariesActions'

function* deactivateBeneficiaries({ payload }) {
  try {
    yield put(deactivateActiveBeneficiariesRequest())

    const { data } = yield axios
      .post('/v1/api/Tokenization/DeactivateCompanyBeneficiary', {
        emails: [...payload],
      })
      .then((response) => response)

    alert(data.data)

    yield put(deactivateActiveBeneficiariesSuccess(data))
  } catch (error) {
    yield put(deactivateActiveBeneficiariesFailed(error.response))
  }
}

export function* watchDeactivateActiveBeneficiaries() {
  yield takeEvery('DEACTIVATE_BENEFICIARIES', deactivateBeneficiaries)
}
