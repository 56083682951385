import React from 'react';

import './emptyList.scss';

const EmtyUser = ({ description, title, background, minHeight }) => {
  return (
    <section className='health__Insured__template__empty__list' style={{ background, minHeight }}>
      <h2>{title}</h2>
      <p>{description}</p>
    </section>
  );
};

export default EmtyUser;

EmtyUser.defaultProps = {
  background: 'inherit',
  minHeight: '40vh',
}
