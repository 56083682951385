const gender = [
  {
    name: 'Male',
    id: 'Male'
  },
  {
    name: 'Female',
    id: 'Female'
  }
];

export default gender;