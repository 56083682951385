import {
  UPLOAD_BENEFICIARIES_FILE_FAILED,
  UPLOAD_BENEFICIARIES_FILE_REQUEST,
  UPLOAD_BENEFICIARIES_FILE_SUCCESS,
} from '../../healthInsuredTypes/corporateActionTypes'

export const uploadBeneficiariesFileRequest = () => ({
  type: UPLOAD_BENEFICIARIES_FILE_REQUEST,
})

export const uploadBeneficiariesFileSuccess = (data) => ({
  type: UPLOAD_BENEFICIARIES_FILE_SUCCESS,
  payload: data,
})

export const uploadBeneficiariesFileFailed = (errorMessage) => ({
  type: UPLOAD_BENEFICIARIES_FILE_FAILED,
  payload: errorMessage,
})
