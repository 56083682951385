import { takeLatest, put } from 'redux-saga/effects'
import axios from 'axios'
import {
  updateDrugCategoryRequested,
  updateDrugCategorySuccessful,
  updateDrugCategoryFailed,
} from '../../../reducer/oneDrugStore/admin/drugCategorySlice'

function* updateDrugCategoryAsync({ payload }) {
  try {
    yield updateDrugCategoryRequested()

    const { data } = yield axios.put(
      `${process.env.REACT_APP_ONE_DRUG_BASE}/v1/api/DrugCategory/Edit`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('adminInfo')}`,
        },
      }
    )

    yield put(updateDrugCategorySuccessful(data))
  } catch (error) {
    if (error.response) {
      yield put(updateDrugCategoryFailed(error.response.data))
      yield put(updateDrugCategoryFailed(null))
      return
    }

    yield put(updateDrugCategoryFailed(error.message))
    yield put(updateDrugCategoryFailed(null))
  }
}

export function* watchUpdateDrugCategory() {
  yield takeLatest(updateDrugCategoryRequested.type, updateDrugCategoryAsync)
}
