import React from 'react';
import { Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';

import ResponseToast from '../template/ResponseToast';

const Addadmin = ({
  show,
  handleClose,
  roles,
  send,
  toastShow,
  toastError,
  createAdmin,
  handleToastClose,
}) => {

  const validationSchema = yup.object().shape({
    firstName: yup.string().required('Required').max(20, 'Must be 20 characters or less').trim(),
    lastName: yup.string().required('Required').max(20, 'Must be 20 characters or less').trim(),
    lastName: yup.string().required('Required').max(20, 'Must be 20 characters or less').trim(),
    email: yup.string().email('Invalid email address').required('Required').trim(),
    roleId: yup.string().required('Required').trim(),
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      userName: '',
      email: '',
      roleId: '',
    },
    validationSchema,
    onSubmit: values => { createAdmin(values, formik.handleReset) },
  });


  // get all administrator roles and id as option value and name
  const filterOptions = roles && roles.map(role => (
    <option value={role.id}>{role.name}</option>
  ));

  return (

    <> <Modal
      show={show}
      onHide={handleClose}
      onHide={handleClose}
      closeButton
      dialogClassName="modal-sz"
      className='add-admin-modal p-4'
    >
      <Modal.Body
        className='d-flex flex-column'
      >
        <ResponseToast
          toastShow={toastShow}
          handleToastClose={handleToastClose}
          toastError={toastError}
        />
        <h1 className='add-admin'> Add new admin</h1>

        <form className='p-0' onSubmit={formik.handleSubmit} id='create-admin'>
          <div className='d-flex flex-column add-admin-modal--input--control'>
            <label className='add-labels'>First Name</label>
            <input
              type='text'
              name='firstName'
              value={formik.values.firstName}
              className='add-inputs p-1'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}

            />
            {
              formik.touched.firstName && formik.errors.firstName ?
                <div aria-labelledby='error message' className='text-danger'>
                  <small>*{formik.errors.firstName}</small>
                </div> : null
            }
          </div>

          <div className='d-flex flex-column add-admin-modal--input--control'>
            <label className='add-labels'>Last Name</label>
            <input
              type='text'
              name='lastName'
              value={formik.values.lastName}
              className='add-inputs p-1'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            {
              formik.touched.lastName && formik.errors.lastName ?
                <div aria-labelledby='error message' className='text-danger'>
                  <small>*{formik.errors.lastName}</small>
                </div> : null
            }
          </div>

          <div className='d-flex flex-column add-admin-modal--input--control'>
            <label className='add-labels'>Username</label>
            <input
              type='text'
              name='userName'
              value={formik.values.userName}
              className='add-inputs p-1'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            {
              formik.touched.userName && formik.errors.userName ?
                <div aria-labelledby='error message' className='text-danger'>
                  <small>*{formik.errors.userName}</small>
                </div> : null
            }
          </div>

          <div className='d-flex flex-column add-admin-modal--input--control'>
            <label className='add-labels'>Email</label>
            <input
              type='email'
              name='email'
              value={formik.values.email}
              className='add-inputs p-1'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            {
              formik.touched.email && formik.errors.email ?
                <div aria-labelledby='error message' className='text-danger'>
                  <small>*{formik.errors.email}</small>
                </div> : null
            }
          </div>

          <div className='d-flex flex-column add-admin-modal--input--control'>
            <label className='add-labels'>Assign role</label>
            <select className='add-inputs p-1' onChange={formik.handleChange} onBlur={formik.handleBlur} name='roleId' value={formik.values.roleId}>
              <option value=''></option>
              {filterOptions}
            </select>
            {
              formik.touched.roleId && formik.errors.roleId ?
                <div aria-labelledby='error message' className='text-danger'>
                  <small>*{formik.errors.roleId}</small>
                </div> : null
            }
          </div>
        </form>


      </Modal.Body>
      <Modal.Footer className='add-footer'>
        <button className='btn-cancel' onClick={handleClose}>cancel</button>
        <button className='btn-send' form='create-admin' disabled={send}>
          {send ? 'Sending...' : 'Send invite'}
        </button>
      </Modal.Footer>
    </Modal>
    </>
  );
};

export default Addadmin;
