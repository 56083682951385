import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { IoIosArrowDropright } from 'react-icons/io'

import { Button as CustomButtonStyle } from '../../Templates/CustomButton'

import Insurance from '../../../assets/InsuranceImgs/Insurance.svg'
//  from 'C:\Users\effiongge\Documents\work\HealthBanc\src\healthBancServices\healthFinance\assets\leadGenImg3.jpeg'
import HealthFinanceImg from '../../../healthBancServices/healthFinance/assets/healthFinanceModified.png'

import SterlingSun from '../../../assets/healthBancLandingPageImgs/sterlingSun.png'

export const GetStartedBtn = styled(CustomButtonStyle)`
  background-color: #d71e1b;
  height: 51px;
  width: 153px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #d71e1b;
  box-sizing: border-box;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
`

export const LearnMoreBtn = styled.button`
  color: #d71e1b;
  border: none;
  background-color: inherit;
  font-weight: 500;
  font-size: 16px;
`

const Img = styled.div`
  /* margin-top: 400px; */
  img {
    max-height: 392px;
    object-fit: cover;
    object-position: 50% 10%;
  }
`

const PHDesc = styled.div`
  height: 400px;
  background: url(${SterlingSun}) no-repeat;
  background-size: contain;
  background-position: right;

  div.features {
    width: 200px;
    font-size: 14px;
    color: #d71e1b;
    margin: 10px 10px;
    background: rgba(215, 30, 27, 0.05);
    padding: 8px;
    border-radius: 3px;
  }

  p.heading {
    font-size: 28px;
    font-weight: bold;
    color: #0c162c;
    max-width: 450px;
    line-height: 130%;
  }

  p.desc {
    max-width: 390px;
    font-size: 16px;
    line-height: 150%;
    margin-top: 32px;
    margin-bottom: 32px;
    color: #474747;
  }
`

const InsuranceIntro = ({ history }) => {
  return (
    <div className="d-flex flex-wrap justify-content-between ">
      <Img className="col-sm-12 col-md-6 order-md-1 order-sm-2">
        <img
          src={HealthFinanceImg}
          alt="HealthFinanceImg"
          className="img-fluid"
        />
      </Img>

      <div className="col-sm-12 col-md-6  ps-md-5 d-flex flex-column order-2 order-sm-1">
        <PHDesc>
          <div className="features">Health Finance</div>
          <p className="heading mt-4">Get easy access to funds</p>

          <p className="desc">
            Sterling has shown its commitment by earmarking ₦20B toward the
            development of the sector’s investments, and we have been working on
            improving healthcare systems through an integrated partnership
            approach.
          </p>
          <div className="d-flex align-items-center">
            {/* <GetStartedBtn
              onClick={() => history.push('/health-finance')}
              className="me2"
            >
              {' '}
              Get Started
            </GetStartedBtn> */}
            <LearnMoreBtn className="learnMore d-flex me-3">
              <div
                className="text"
                onClick={() => history.push('/health-finance')}
              >
                Learn more <IoIosArrowDropright style={{ fontSize: 25 }} />
              </div>
            </LearnMoreBtn>
          </div>
        </PHDesc>
      </div>
    </div>
  )
}

export default withRouter(InsuranceIntro)
