import { takeLatest, put } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import axios from 'axios'
import {
  createAggregatorRequested,
  createAggregatorSuccessful,
  createAggregatorFailed,
} from '../../../reducer/oneDrugStore/admin/aggregatorSlices'

function* createAggregatorAsync({ payload }) {
  try {
    yield createAggregatorRequested()

    const { data } = yield axios({
      method: 'POST',
      url: `${process.env.REACT_APP_ONE_DRUG_BASE}/v1/api/Aggregator/Create`,
      data: payload,
      headers: { Authorization: `Bearer ${localStorage.getItem('adminInfo')}` },
    })

    yield toast.success(data.responseDescription)

    yield put(createAggregatorSuccessful(data))
    yield (window.location.href = '/admin/OneDrugStore/aggregators')
  } catch (error) {
    if (error.response) {
      yield put(createAggregatorFailed(error.response.data))
      yield put(createAggregatorFailed(null))
      return
    }

    yield put(createAggregatorFailed(error.message))
    yield put(createAggregatorFailed(null))
  }
}

export function* watchCreateAggregator() {
  yield takeLatest(createAggregatorRequested.type, createAggregatorAsync)
}
