import {
  BENEFICIARIES_REVIEW_FAILED,
  BENEFICIARIES_REVIEW_REQUEST,
  BENEFICIARIES_REVIEW_SUCCESS,
} from '../../healthInsuredTypes/corporateActionTypes'

export const beneficiariesReviewRequest = () => ({
  type: BENEFICIARIES_REVIEW_REQUEST,
})

export const beneficiariesReviewSuccess = (data) => ({
  type: BENEFICIARIES_REVIEW_SUCCESS,
  payload: data,
})

export const beneficiariesReviewFailed = (errorMessage) => ({
  type: BENEFICIARIES_REVIEW_FAILED,
})
