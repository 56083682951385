import React, { useState, useEffect } from 'react'
import './styles/InputOtp.css'
import OtpInput from 'react-otp-input'
import Progress from '../../Progress'
import Payment from '../../Payment'
import { _phoneNumber } from './CreateWallet'
import useConfirmOtp from '../walletHooks/useConfirmOtp'
import useResendOtp from '../walletHooks/useResendOtp'
import { ToastContainer } from 'react-toastify'

// type in your OTP

export let _otp = ''
const InputOtp_ = () => {
    const [otp, setOtp] = useState('')
    const [countTimer, setCountTimer] = useState(30)
    _otp = otp 

  const method = 'POST'
  const url = '/v2/api/Wallet/CreateWallet'
  const nextRoute = '/pay_with_wallet/create_wallet/verify_otp'
  const action = 'CreateWallet'
  const phoneNumber = _phoneNumber
  const resendUrl = `/v2/api/Wallet/GenerateOTPForNewWallet`

    const {isLoading, handleClick} = useConfirmOtp(method, url, nextRoute, otp, action, phoneNumber)

  const { loadingResend, handleResend } = useResendOtp(resendUrl, _phoneNumber)
  let phnNum = ''

    useEffect(() => {
        if (!countTimer) return;
        const timeout = setInterval(() => {
                setCountTimer((prev) => prev - 1)
            }, 1000)

            return () => {
                clearInterval(timeout)
            }

    }, [countTimer])

    return (
        <div className='inputOTP'>
            <Payment />
            <div className='create-align'/>
            <div className="otp_input">
        
            <div className="otp_pos">
                    <div className='otp_pos2'>
                        <h2>Verify your phone number</h2>
                        <p>A (One Time Password) OTP was sent to your Phone number {phnNum}. Enter the code to continue.</p>
            <div className='otp-box-lg'>
            <OtpInput
                className='otp-box'
                value={otp}
                onChange={(value) => setOtp(value)}
                numInputs={6}
                inputStyle={{
                  height: '59px',
                  width: '59px',
                  outline: 'none',
                  borderRadius: '5px',
                  marginLeft: '5px',
                  border: '1px solid grey',
                }}
              />
            </div>

            <div className="otp-box-md">
              <OtpInput
                className="otp-box-lg"
                value={otp}
                onChange={(value) => setOtp(value)}
                numInputs={6}
                inputStyle={{
                  height: '45px',
                  width: '45px',
                  outline: 'none',
                  borderRadius: '5px',
                  marginLeft: '5px',
                  border: '1px solid grey',
                }}
              />
            </div>
          </div>

          <div className="otp-btns">
            <button onClick={handleClick} disabled={isLoading}>
              <p>{isLoading ? 'Verifying OTP' : 'Verify OTP'}</p>
            </button>

            <p className="d-p">
              Did not get OTP? Click to{' '}
              <span
                onClick={handleResend}
                disabled={isLoading}
                style={{
                  color: 'red',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
              >
                {loadingResend ? 'Resending OTP' : 'Resend OTP'}
              </span>{' '}
              in {countTimer}s
            </p>
            <ToastContainer />
          </div>
        </div>
      </div>
      <div>
        <Progress />
      </div>
    </div>
  )
}

export default InputOtp_
