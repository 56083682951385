import React from 'react'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useIdleTimer } from 'react-idle-timer'
import { healthBancSignOut } from './redux/healthBanc/actions/actionCreators'
import { useDispatch } from 'react-redux'

import AdminProtectedRoute from './healthBancAdmin/components/auth/AdminProtectedRoute'

// import PharmHubRoutes from './healthBancServices/Routes'
// import PharmHubRoutes from './healthBancServices/pharmHub/Routes'

import { ProtectedRoute } from './healthBancComponents/Templates/ProtectedRoute'

// @Desc Health Bank Components Import
import HBLandingPage from './healthBancComponents/LandingPage/LandingPage'
import AboutUs from './healthBancComponents/AboutUs/AboutUs'
import HBSignup from './healthBancComponents/authentication/Signup'
import VerifyEmail from './healthBancComponents/authentication/VerifyEmail'
import HBSignin from './healthBancComponents/authentication/SignIn'
import ResendPasswordLink from './healthBancComponents/ResendPasswordLink'
import HBResetPassword from './healthBancComponents/authentication/ResetPassword'
import HBSetNewPassword from './healthBancComponents/authentication/SetNewPassword'
import SubDashboard from './healthBancComponents/SubDashboard/SubDashboard'
import ChangePassword from './healthBancComponents/authentication/ChangePassword'
import DigitalHospital from './healthBancServices/DigitalHospital/DigitalHospital'
import HealthFinance from './healthBancServices/healthFinance/HealthFinance'
import TermsOfUse from './healthBancComponents/TermsOfUse/TermsOfUse'
import PrivacyPolicy from './healthBancComponents/PrivacyPolicy/PrivacyPolicy'

//HealthBanc Admin
import AdminDashboard from './healthBancAdmin/components/dashboard/AdminDashboard'
import RegisteredUsers from './healthBancAdmin/components/registeredUsers/RegisteredUsers'
import ActiveUsers from './healthBancAdmin/components/activeUsers/ActiveUsers'
import InactiveUsers from './healthBancAdmin/components/inactiveUsers/InactiveUsers'
import DeactivatedUsers from './healthBancAdmin/components/deactivatedUsers/DeactivatedUsers'
import Administrator from './healthBancAdmin/components/administrator/Administrator'
import Profile from './healthBancAdmin/components/profile/Profile'
import Signin from './healthBancAdmin/components/singin/Signin'
import Sent from './healthBancAdmin/components/notification/sent/Sent'
import Message from './healthBancAdmin/components/notification/message/Message'
import Draft from './healthBancAdmin/components/notification/draft/Draft'
import Schedule from './healthBancAdmin/components/notification/shedule/Schedule'
import Trash from './healthBancAdmin/components/notification/trash/Trash'

//Health bank component import --END--

//healthinsured service CardProvider
import chooseProvider from './healthBancServices/healthInsured/healthInsuredIndividualFlow/chooseProvider/chooseProvider'
/**End */

// @Desc Axa Mansard components import starts here
import LandingPage from './healthBancServices/healthInsured/healthInsuredIndividualFlow/landingPage/LandingPage'
import AxaSignUp from './healthBancServices/healthInsured/healthInsuredIndividualFlow/signup/SignUp'
import ProfileDashboard from './healthBancServices/healthInsured/healthInsuredIndividualFlow/profileDashboard/ProfileDashboard'
import CardProvider from './healthBancServices/healthInsured/healthInsuredIndividualFlow/profileDashboard/cardProvider/CardProvider'
import HealthCareProviders from './healthBancServices/healthInsured/healthInsuredIndividualFlow/profileDashboard/healthCareProviders/HealthCareProviders'
import Card from './healthBancServices/healthInsured/healthInsuredIndividualFlow/profileDashboard/card/Card'
import Subscription from './healthBancServices/healthInsured/healthInsuredIndividualFlow/profileDashboard/subscription/Subscription'
import SuccessPage from './healthBancServices/healthInsured/healthInsuredIndividualFlow/signup/successfulSignUp/SuccessfulSignUp'
import Otp from './healthBancServices/healthInsured/healthInsuredIndividualFlow/signup/otp/Otp'
import Reactivation from './healthBancServices/healthInsured/healthInsuredIndividualFlow/reactivation/Reactivation'
import ReactivateSubscription from './healthBancServices/healthInsured/healthInsuredIndividualFlow/reactivation/ReactivateSubscription'
import VerifyCard from './healthBancServices/healthInsured/healthInsuredIndividualFlow/reactivation/VerifyCard'
import UpdateDetails from './healthBancServices/healthInsured/healthInsuredIndividualFlow/reactivation/UpdateDetails'
import CardTokenization from './healthBancServices/healthInsured/healthInsuredIndividualFlow/profileDashboard/tokenization/payments/CardTokenization'
import CardOtp from './healthBancServices/healthInsured/healthInsuredIndividualFlow/profileDashboard/tokenization/otp/TokenizatioOtp'
import WalletMain from './healthBancServices/healthInsured/healthInsuredIndividualFlow/profileDashboard/sectionWallet/WalletMain'

/////////////////////
//FRIEND
import FriendsSection from './healthBancServices/healthInsured/healthInsuredIndividualFlow/profileDashboard/friends/FriendsSection'
import AddFriendsSection from './healthBancServices/healthInsured/healthInsuredIndividualFlow/profileDashboard/friends/addFriend/AddFriendSection'
import FriendDetails from './healthBancServices/healthInsured/healthInsuredIndividualFlow/profileDashboard/friends/friendDetails/FriendDetails'

//@Desc Health Insured Coporate Components imports starts here
import InsuranceTypeSelect from './healthBancServices/healthInsured/HealthInsuredCorporateFlow/screens/insuranceTypeSelect/InsuranceTypeSelect'
import BusinessRegistration from './healthBancServices/healthInsured/HealthInsuredCorporateFlow/screens/businessRegistration/BusinessRegistration'
import BusinessRegistrationOTPVerification from './healthBancServices/healthInsured/HealthInsuredCorporateFlow/screens/businessRegistrationOTPVerification/BusinessRegistrationOTPVerification'
import CorporateDashboard from './healthBancServices/healthInsured/HealthInsuredCorporateFlow/screens/Dashboard/Dashboard'
import CorporateWelcomeScreen from './healthBancServices/healthInsured/HealthInsuredCorporateFlow/screens/welcome/CorporateWelcomeScreen'
import CompanyProfileUpdate from './healthBancServices/healthInsured/HealthInsuredCorporateFlow/screens/companyProfileUpdate/CompanyProfileUpdate'
import CompanyProfileUpdateSuccess from './healthBancServices/healthInsured/HealthInsuredCorporateFlow/screens/companyProfileUpdateSuccess/CompanyProfileUpdateSuccess'
import Beneficiaries from './healthBancServices/healthInsured/HealthInsuredCorporateFlow/screens/beneficiaries/Beneficiaries'
import AddBeneficiaries from './healthBancServices/healthInsured/HealthInsuredCorporateFlow/screens/addBeneficiaries/AddBeneficiaries'
import BeneficiaryReview from './healthBancServices/healthInsured/HealthInsuredCorporateFlow/screens/beneficiaryReview/BeneficiaryReview'
import CorporatePaymentCardInfo from './healthBancServices/healthInsured/HealthInsuredCorporateFlow/screens/corporatePaymentCardInfo/CorporatePaymentCardInfo'
import CorporatePayment_otp from './healthBancServices/healthInsured/HealthInsuredCorporateFlow/screens/corporatePayment_otp/CorporatePayment_otp'
import CompanyProfile from './healthBancServices/healthInsured/HealthInsuredCorporateFlow/screens/companyProfile/CompanyProfile'

///////////// ONE DRUG STORE
import OneDrugLandingPage from './healthBancServices/oneDrugStore/pages/LandingPage/LandingPage'
import OneDrugShop from './healthBancServices/oneDrugStore/pages/shop/Shop'
import OneDrugCart from './healthBancServices/oneDrugStore/pages/cart/Cart'
import OneDrugUploadPrescription from './healthBancServices/oneDrugStore/pages/uploadPrescription/UploadPrescription'
import OneDrugCheckout from './healthBancServices/oneDrugStore/pages/checkout/Checkout'
import OneDrugAuthentication from './healthBancServices/oneDrugStore/pages/authentication/Authentication'
import OneDrugCardDebit from './healthBancServices/oneDrugStore/pages/payment/CardDebit'
import OneDrugDashboard from './healthBancServices/oneDrugStore/pages/dashboard/Dashboard'
import OneDrugAdminLogin from './healthBancServices/oneDrugStore/pages/admin/login/Login'
import OneDrugAdminDashboard from './healthBancServices/oneDrugStore/pages/admin/dashboard/Dashboard'
import OneDrugAdminAggregators from './healthBancServices/oneDrugStore/pages/admin/aggregators/Aggregators'
import OneDrugAdminAggregator from './healthBancServices/oneDrugStore/pages/admin/aggregator/Aggregator'
import OneDrugAdminCreateAggregator from './healthBancServices/oneDrugStore/pages/admin/createAggregator/CreateAggregator'
import OneDrugAdminCreatePharamcy from './healthBancServices/oneDrugStore/pages/admin/createPharmacy/CreatePharmacy'
import OneDrugPaymentSuccess from './healthBancServices/oneDrugStore/pages/paymentStatus/PaymentSuccess'
import OneDrugPaymentFailed from './healthBancServices/oneDrugStore/pages/paymentStatus/PaymentFailed'

import OneDrugAdminAllPharamcy from './healthBancServices/oneDrugStore/pages/admin/pharmacies/Pharmacies'
import OneDrugAdminPharamcy from './healthBancServices/oneDrugStore/pages/admin/pharmacy/Pharmacy'
import OneDrugAdminCreateDrugCategory from './healthBancServices/oneDrugStore/pages/admin/createDrugCategory/CreateDrugCategory'
import OneDrugAdminCreateDrugItem from './healthBancServices/oneDrugStore/pages/admin/createDrugItem/CreateDrugItem'
import OneDrugAdminOrders from './healthBancServices/oneDrugStore/pages/admin/orders/Orders'
import OneDrugAdminOrder from './healthBancServices/oneDrugStore/pages/admin/order/Order'

//@Desc Health Insured Admin Components Import starts here
import HealthInsuredAdminDashboard from './healthBancServices/healthInsured/HealthInsuredAdmin/screens/healthInsuredAdminDashboard/HealthInsuredAdminDashboard'
import HealthInsuredUsers from './healthBancServices/healthInsured/HealthInsuredAdmin/screens/healthInsuredUsers/HealthInsuredUsers'
import HealthInsuredUserProfile from './healthBancServices/healthInsured/HealthInsuredAdmin/screens/healthInsuredUserProfile/HealthInsuredUserProfile'
import HealthInsuredAdminUserTransactions from './healthBancServices/healthInsured/HealthInsuredAdmin/screens/healthInsuredAdminUserTransactions/HealthInsuredAdminUserTransactions'
import HealthInsuredAdminUserActivities from './healthBancServices/healthInsured/HealthInsuredAdmin/screens/healthInsuredAdminUserActivities/HealthInsuredAdminUserActivities'
import HealthInsuredExtendedUserActivities from './healthBancServices/healthInsured/HealthInsuredAdmin/screens/healthInsuredAdminUserActivities/healthInsuredExtendedAdminUserActivities/HealthInsuredExtendedUserActivities'
import HealthInsuredAdminTransactionLog from './healthBancServices/healthInsured/HealthInsuredAdmin/screens/healthInsuredAdminTransactionLog/HealthInsuredAdminTransactionLog'
import HealthInsuredAdminErrorLog from './healthBancServices/healthInsured/HealthInsuredAdmin/screens/healthInsuredAdminErrorLog/HealthInsuredAdminErrorLog'
import HealthInduredNotificationDraft from './healthBancServices/healthInsured/HealthInsuredAdmin/screens/notifications/Draft'
import HealthInduredNotificationScheduled from './healthBancServices/healthInsured/HealthInsuredAdmin/screens/notifications/Scheduled'
import HealthInduredAdminRoles from './healthBancServices/healthInsured/HealthInsuredAdmin/screens/adminRoles/AdminRoles'
import HealthInduredAdminProviders from './healthBancServices/healthInsured/HealthInsuredAdmin/screens/providers/Providers'
import HealthInduredAdminProvidersHygiea from './healthBancServices/healthInsured/HealthInsuredAdmin/screens/providers/services/hygiea/Hygiea'
import HealthInduredAdminProvidersAxamansard from './healthBancServices/healthInsured/HealthInsuredAdmin/screens/providers/services/axamansard/Axamansard'
import HealthInduredAdminSupportNewTicket from './healthBancServices/healthInsured/HealthInsuredAdmin/screens/support/NewTicket'
import HealthInduredAdminSupportPendingTicket from './healthBancServices/healthInsured/HealthInsuredAdmin/screens/support/PendingTicket'
import HealthInduredAdminSupportResolvedTicket from './healthBancServices/healthInsured/HealthInsuredAdmin/screens/support/ResolvedTicket'

// Health Finance Admin
import HealthFinanceAdmin from './healthBancServices/healthFinance/healthFinanceAdmin/HealthFinanceAdmin'

///////////// Health Insured Family
import HealthInsuredFamily from './healthBancServices/healthInsured/healthInsuredFamilyFlow/HealthInsuredFamily'
import AddFamilyMemberForm from './healthBancServices/healthInsured/healthInsuredFamilyFlow/addFamilyMemberForm/AddFamilyMemberForm'
import FamilyMemberDetails from './healthBancServices/healthInsured/healthInsuredFamilyFlow/FamilyMemberDetails'

import ErrorPage from './healthBancComponents/ErrorPage'

// Pay with Wallet

import Wallet from './healthBancServices/healthInsured/healthInsuredIndividualFlow/signup/paymentDetails/payWithWallet/Wallet'
import CreateWallet from './healthBancServices/healthInsured/healthInsuredIndividualFlow/signup/paymentDetails/payWithWallet/createNewWallet/CreateWallet'
import InputOtp from './healthBancServices/healthInsured/healthInsuredIndividualFlow/signup/paymentDetails/payWithWallet/linkExistingWallet/InputOtp'
import VerifyOtp from './healthBancServices/healthInsured/healthInsuredIndividualFlow/signup/paymentDetails/payWithWallet/linkExistingWallet/VerifyOtp'
import VerifiedOtp from './healthBancServices/healthInsured/healthInsuredIndividualFlow/signup/paymentDetails/payWithWallet/linkExistingWallet/VerifiedOtp'
import Created from './healthBancServices/healthInsured/healthInsuredIndividualFlow/signup/paymentDetails/payWithWallet/createNewWallet/Created'
import Pay from './healthBancServices/healthInsured/healthInsuredIndividualFlow/signup/paymentDetails/payWithWallet/linkExistingWallet/Pay'

import InputOtp_ from './healthBancServices/healthInsured/healthInsuredIndividualFlow/signup/paymentDetails/payWithWallet/createNewWallet/InputOtp'
import VerifyOtp_ from './healthBancServices/healthInsured/healthInsuredIndividualFlow/signup/paymentDetails/payWithWallet/createNewWallet/VerifyOtp'
import VerifiedOtp_ from './healthBancServices/healthInsured/healthInsuredIndividualFlow/signup/paymentDetails/payWithWallet/createNewWallet/VerifiedOtp'

import './App.css'

const App = (props) => {
  //Signout an inactive user in 5 mins
  const dispatch = useDispatch()

  const userToken = useSelector((state) => state?.user?.userInfo?.token)
  const adminToken = localStorage.adminInfo

  const handleOnIdle = () => {
    if (
      (userToken || adminToken) &&
      (window.location.pathname !== '/' ||
        window.location.pathname !== '/signin' ||
        window.location.pathname !== '/admin_signin')
    ) {
      dispatch(healthBancSignOut())
    }
  }

  // Time out after 5 mins
  useIdleTimer({
    timeout: 1000 * 60 * 5,
    onIdle: handleOnIdle,
    debounce: 500,
  })

  return (
    <Router>
      {/* <div className="App p-0"> */}
      <div>
        <div>
          <Switch>
            {/* @desc: HEALTH BANK ROUTES --START-- */}
            {/* <Route exact path="/" component={HBLandingPage} /> */}
            <Route exact path="/" component={LandingPage} />
            <Route exact path="/signup" component={HBSignup} />
            <Route exact path="/about-us" component={AboutUs} />
            <Route exact path="/verify-email" component={VerifyEmail} />
            <Route exact path="/signin" component={HBSignin} />
            <Route exact path="/reset-password" component={HBResetPassword} />
            <Route
              exact
              path="/set-new-password"
              component={HBSetNewPassword}
            />
            <Route
              exact
              path="/resend_email_link"
              component={ResendPasswordLink}
            />
            <ProtectedRoute exact path="/dashboard" component={SubDashboard} />
            <ProtectedRoute
              exact
              path="/change-password"
              component={ChangePassword}
            />

            <Route exact path="/terms" component={TermsOfUse} />
            <Route exact path="/privacyPolicy" component={PrivacyPolicy} />
            {/* HEALTH BANK ROUTES --END-- */}

            {/* Digital Hospital Route */}
            <Route exact path="/digital-hospital" component={DigitalHospital} />

            {/* Health Finance Lead generator */}
            <Route exact path="/health-finance" component={HealthFinance} />
            <AdminProtectedRoute
              exact
              path="/admin/health-finance/dashboard"
              component={HealthFinanceAdmin}
            />

            <Route exact path="/admin_signin" component={Signin} />
            <AdminProtectedRoute
              exact
              path="/admin"
              component={AdminDashboard}
            />
            <AdminProtectedRoute
              exact
              path="/admin_profile"
              component={Profile}
            />
            <AdminProtectedRoute
              exact
              path="/registered_users"
              component={RegisteredUsers}
            />
            <AdminProtectedRoute
              exact
              path="/active_users"
              component={ActiveUsers}
            />
            <AdminProtectedRoute
              exact
              path="/inactive_users"
              component={InactiveUsers}
            />
            <AdminProtectedRoute
              exact
              path="/deactivated_users"
              component={DeactivatedUsers}
            />
            <AdminProtectedRoute
              exact
              path="/administrator"
              component={Administrator}
            />
            <AdminProtectedRoute exact path="/sent" component={Sent} />
            <AdminProtectedRoute exact path="/message" component={Message} />
            <AdminProtectedRoute exact path="/draft" component={Draft} />
            <AdminProtectedRoute exact path="/schedule" component={Schedule} />
            <AdminProtectedRoute exact path="/trash" component={Trash} />
            {/**healthinsured choose service provider */}
            <ProtectedRoute
              exact
              path="/health_choose_provider"
              component={chooseProvider}
            />

            {/* @Desc Axa Mansard componets starts here */}
            <Route exact path="/health_insurance" component={LandingPage} />
            <ProtectedRoute exact path="/health_signup" component={AxaSignUp} />
            <ProtectedRoute
              exact
              path="/health_reactivation"
              component={Reactivation}
            />
            <ProtectedRoute
              exact
              path="/health_reactivate_subscription"
              component={ReactivateSubscription}
            />
            <ProtectedRoute
              exact
              path="/health_verify_card"
              component={VerifyCard}
            />
            <ProtectedRoute
              exact
              path="/health_profile"
              component={ProfileDashboard}
            />
            <ProtectedRoute
              exact
              path="/health_care_provider"
              component={CardProvider}
            />
            <ProtectedRoute
              exact
              path="/health_care_providers"
              component={HealthCareProviders}
            />
            <ProtectedRoute exact path="/health_card" component={Card} />
            <ProtectedRoute
              exact
              path="/health_subscription"
              component={Subscription}
            />

            <ProtectedRoute
              exact
              path="/health_section_wallet"
              component={WalletMain}
            />

            <ProtectedRoute
              exact
              path="/health_update_details"
              component={UpdateDetails}
            />
            <ProtectedRoute exact path="/health_otp" component={Otp} />
            <ProtectedRoute
              exact
              path="/health_tokenization_payment"
              component={CardTokenization}
            />
            <ProtectedRoute
              exact
              path="/health_tokenization_otp"
              component={CardOtp}
            />
            <ProtectedRoute
              exact
              path="/health_success_signup"
              component={SuccessPage}
            />

            {/* ////////////////////////// FRIENDS FOR INDIVIDUAL ////////////// */}
            <ProtectedRoute
              exact
              path="/health-friends"
              component={FriendsSection}
            />

            <ProtectedRoute
              exact
              path="/health-friends/add-friend"
              component={AddFriendsSection}
            />

            <ProtectedRoute
              exact
              path="/health-friends/add-friend-full-details"
              component={AddFamilyMemberForm}
            />

            <ProtectedRoute
              exact
              path="/health-friends/:email"
              component={FriendDetails}
            />

            {/* ///////////////////// HEALTH FOR FAMILY /////////////////////// */}
            <ProtectedRoute
              exact
              path="/health-family"
              component={HealthInsuredFamily}
            />

            <ProtectedRoute
              exact
              path="/health-friend"
              component={HealthInsuredFamily}
            />

            <ProtectedRoute
              exact
              path="/add-family-member"
              component={AddFamilyMemberForm}
            />

            <ProtectedRoute
              exact
              path="/health-family/:name"
              component={FamilyMemberDetails}
            />

            {/* @desc Health Insured Coporate flow routes */}
            <ProtectedRoute
              exact
              path="/health_corporate/select_type"
              component={InsuranceTypeSelect}
            />

            <Route exact path="/health-insurance" component={HBSignup} />

            <ProtectedRoute
              exact
              path="/health_corporate/business_registration"
              component={BusinessRegistration}
            />

            <ProtectedRoute
              exact
              path="/health_corporate/business_registration-otp"
              component={BusinessRegistrationOTPVerification}
            />

            <ProtectedRoute
              exact
              path="/health_corporate/dashboard"
              component={CorporateDashboard}
            />

            <ProtectedRoute
              exact
              path="/health_corporate/welcome"
              component={CorporateWelcomeScreen}
            />

            <ProtectedRoute
              exact
              path="/health_corporate/profile"
              component={CompanyProfile}
            />

            <ProtectedRoute
              exact
              path="/health_corporate/profile_update"
              component={CompanyProfileUpdate}
            />

            <ProtectedRoute
              exact
              path="/health_corporate/profile_update_success"
              component={CompanyProfileUpdateSuccess}
            />

            <ProtectedRoute
              exact
              path="/health_corporate/beneficiaries"
              component={Beneficiaries}
            />

            <ProtectedRoute
              exact
              path="/health_corporate/add_beneficiaries"
              component={AddBeneficiaries}
            />

            <ProtectedRoute
              exact
              path="/health_corporate/beneficiary_review"
              component={BeneficiaryReview}
            />

            <ProtectedRoute
              exact
              path="/health_corporate/payment_card-info"
              component={CorporatePaymentCardInfo}
            />

            <ProtectedRoute
              exact
              path="/health_corporate/payment_otp"
              component={CorporatePayment_otp}
            />

            {/* Payment with Wallet */}

            <ProtectedRoute exact path="/pay_with_wallet" component={Wallet} />

            <ProtectedRoute
              exact
              path="/pay_with_wallet/create_wallet"
              component={CreateWallet}
            />

            <ProtectedRoute
              exact
              path="/pay_with_wallet/input_otp"
              component={InputOtp}
            />

            <ProtectedRoute
              exact
              path="/pay_with_wallet/verify_otp"
              component={VerifyOtp}
            />

            <ProtectedRoute
              exact
              path="/pay_with_wallet/verify"
              component={VerifiedOtp}
            />

            <ProtectedRoute
              exact
              path="/pay_with_wallet/create_wallet/created"
              component={Created}
            />

            <ProtectedRoute
              exact
              path="/pay_with_wallet/link_wallet"
              component={Pay}
            />

            <ProtectedRoute
              exact
              path="/pay_with_wallet/create_wallet/input_otp"
              component={InputOtp_}
            />

            <ProtectedRoute
              exact
              path="/pay_with_wallet/create_wallet/verify_otp"
              component={VerifyOtp_}
            />

            <ProtectedRoute
              exact
              path="/pay_with_wallet/create_wallet/verify"
              component={VerifiedOtp_}
            />

            {/*////////////////////////////////// ONE DRUG STORE ///////////////////////////////////////////////*/}
            <Route exact path="/oneDrugStore" component={OneDrugLandingPage} />
            <Route exact path="/oneDrugStore/shop" component={OneDrugShop} />
            <Route exact path="/oneDrugStore/cart" component={OneDrugCart} />
            <Route
              exact
              path="/OneDrugStore/upload-prescription/:id"
              component={OneDrugUploadPrescription}
            />
            <Route
              exact
              path="/oneDrugStore/checkout"
              component={OneDrugCheckout}
            />
            <Route
              exact
              path="/oneDrugStore/authentication"
              component={OneDrugAuthentication}
            />

            <Route
              exact
              path="/oneDrugStore/card-debit"
              component={OneDrugCardDebit}
            />

            <Route
              exact
              path="/oneDrugStore/payment-success"
              component={OneDrugPaymentSuccess}
            />
            <Route
              exact
              path="/oneDrugStore/payment-failed"
              component={OneDrugPaymentFailed}
            />

            <Route
              exact
              path="/oneDrugStore/dashboard"
              component={OneDrugDashboard}
            />

            <Route
              exact
              path="/oneDrugStore/admin/login"
              component={OneDrugAdminLogin}
            />

            <AdminProtectedRoute
              exact
              path="/admin/OneDrugStore/dashboard"
              component={OneDrugAdminDashboard}
            />

            <AdminProtectedRoute
              exact
              path="/admin/OneDrugStore/aggregators"
              component={OneDrugAdminAggregators}
            />

            <AdminProtectedRoute
              exact
              path="/admin/oneDrugStore/aggregators/create"
              component={OneDrugAdminCreateAggregator}
            />

            <AdminProtectedRoute
              exact
              path="/admin/oneDrugStore/aggregators/update/:id"
              component={OneDrugAdminCreateAggregator}
            />

            <AdminProtectedRoute
              exact
              path="/admin/OneDrugStore/aggregators/:id"
              component={OneDrugAdminAggregator}
            />

            <AdminProtectedRoute
              exact
              path="/admin/oneDrugStore/pharmacy/create/:aggregatorId"
              component={OneDrugAdminCreatePharamcy}
            />

            <AdminProtectedRoute
              exact
              path="/admin/oneDrugStore/aggregator/pharmacy-update/:pharmacyId"
              component={OneDrugAdminCreatePharamcy}
            />

            <AdminProtectedRoute
              exact
              path="/admin/oneDrugStore/pharmacies"
              // component={OneDrugAdminAllPharamcy}
              component={OneDrugAdminAggregator}
            />

            <AdminProtectedRoute
              exact
              path="/admin/oneDrugStore/pharmacy/:pharmacyId"
              component={OneDrugAdminPharamcy}
            />

            <AdminProtectedRoute
              exact
              path="/admin/oneDrugStore/drug-category/create/"
              component={OneDrugAdminCreateDrugCategory}
            />

            <AdminProtectedRoute
              exact
              path="/admin/oneDrugStore/drug-item/create/:pharmacyId"
              component={OneDrugAdminCreateDrugItem}
            />

            <AdminProtectedRoute
              exact
              path="/admin/oneDrugStore/orders"
              component={OneDrugAdminOrders}
            />

            <AdminProtectedRoute
              exact
              path="/admin/oneDrugStore/order/:id"
              component={OneDrugAdminOrder}
            />

            {/*///////////////////////// @desc Health Insured Admin flow routes //////////////////////////////////////*/}
            <AdminProtectedRoute
              exact
              path="/admin/health_insured/dashboard"
              component={HealthInsuredAdminDashboard}
            />

            <AdminProtectedRoute
              exact
              path="/health_insured/admin/notifications/drafts"
              component={HealthInduredNotificationDraft}
            />

            <AdminProtectedRoute
              exact
              path="/admin/health_insured/users"
              component={HealthInsuredUsers}
            />
            <AdminProtectedRoute
              exact
              path="/admin/health_insured/users/profile/:user"
              component={HealthInsuredUserProfile}
            />

            <AdminProtectedRoute
              exact
              path="/health_insured/admin/notifications/scheduled"
              component={HealthInduredNotificationScheduled}
            />

            <AdminProtectedRoute
              exact
              path="/admin/health_insured/users/transactions/:user"
              component={HealthInsuredAdminUserTransactions}
            />

            <AdminProtectedRoute
              exact
              path="/health_insured/admin/roles"
              component={HealthInduredAdminRoles}
            />

            <AdminProtectedRoute
              exact
              path="/health_insured/admin/providers"
              component={HealthInduredAdminProviders}
            />

            <AdminProtectedRoute
              exact
              path="/admin/health_insured/users/activities/:user"
              component={HealthInsuredAdminUserActivities}
            />

            <AdminProtectedRoute
              exact
              path="/health_insured/admin/providers/hygiea/dashboard/"
              component={HealthInduredAdminProvidersHygiea}
            />

            <AdminProtectedRoute
              exact
              path="/admin/health_insured/users/profile/activity-log/:user"
              component={HealthInsuredExtendedUserActivities}
            />

            <AdminProtectedRoute
              exact
              path="/health_insured/admin/providers/axamansard/dashboard/"
              component={HealthInduredAdminProvidersAxamansard}
            />

            <AdminProtectedRoute
              exact
              path="/admin/health_insured/transaction-log"
              component={HealthInsuredAdminTransactionLog}
            />

            <AdminProtectedRoute
              exact
              path="/admin/health_insured/error-log"
              component={HealthInsuredAdminErrorLog}
            />

            <AdminProtectedRoute
              exact
              path="/health_insured/admin/support/newtickets"
              component={HealthInduredAdminSupportNewTicket}
            />

            <ProtectedRoute
              exact
              path="/health_insured/admin/support/pendingtickets"
              component={HealthInduredAdminSupportPendingTicket}
            />

            <ProtectedRoute
              exact
              path="/health_insured/admin/support/resolvedtickets"
              component={HealthInduredAdminSupportResolvedTicket}
            />

            {/* @desc: PharmHub Routes --START- */}
            {/* <Route component={PharmHubRoutes} /> */}
            {/* pharmHube Routes --END-- */}

            {/* 404 Page */}
            <Route component={ErrorPage} />
            {/* <Route component={() => '404: Page Not Found'} /> */}
          </Switch>
        </div>
      </div>
    </Router>
  )
}

export default App

// @desc: The code below adds the chat widget to the application
// Comment in for live environment, comment out for test environment

var Tawk_API = Tawk_API || {},
  Tawk_LoadStart = new Date()
;(function () {
  var s1 = document.createElement('script'),
    s0 = document.getElementsByTagName('script')[0]
  s1.async = true
  s1.src = 'https://embed.tawk.to/5e8787e835bcbb0c9aad889d/default'
  s1.charset = 'UTF-8'
  s1.setAttribute('crossorigin', '*')
  s0.parentNode.insertBefore(s1, s0)
})()
