import {
  RESET_PROVIDER,
  SERVICE_PROVIDER,
  HEALTHCARE_PROVIDER_LIST_FAIL,
  HEALTHCARE_PROVIDER_LIST_SUCCESS,
  HEALTHCARE_PROVIDER_LIST_REQUEST,
} from '../../actions/healthInsuredTypes/serviceProvider';

export const serviceProvider = (state = null, action) => {
  switch (action.type) {
    case SERVICE_PROVIDER: return action.payload;

    case RESET_PROVIDER: return action.payload;

    default: return state;
  }
};

export const listOfHealthCareProviders = (state = { listOfHealthCareProviders: [] }, action) => {
  switch (action.type) {
    case HEALTHCARE_PROVIDER_LIST_REQUEST:
      return { loading: true, listOfHealthCareProviders: [] };

    case HEALTHCARE_PROVIDER_LIST_SUCCESS:
      return { loading: false, listOfHealthCareProviders: [...action.payload] };

    case HEALTHCARE_PROVIDER_LIST_FAIL:
      return { loading: false, error: action.payload, listOfHealthCareProviders: [] };

    default: return state;
  }
};
