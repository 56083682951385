import { put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import {
  beneficiariesReviewFailed,
  beneficiariesReviewRequest,
  beneficiariesReviewSuccess,
} from '../../actions/healthInsuredCreators/corporateUserActions/corporateBeneficiariesReviewActions'

function* beneficiariesReview({ payload = {} }) {
  const { pageNumber = 1, searchText = '', sortBy = 1 } = payload

  try {
    yield put(beneficiariesReviewRequest())

    const { data } = yield axios
      .post(`/v2/api/CorporateInsurance/GetCompanyBeneficiaryReview`, {
        data: {
          PageNumber: pageNumber,
          SortBy: sortBy,
          SearchText: searchText,
        },
      })
      .then((response) => response)

    yield put(beneficiariesReviewSuccess(data))
  } catch (error) {
    yield put(beneficiariesReviewFailed(error.response))
  }
}

export function* watchBeneficiariesReview() {
  yield takeEvery('BENEFICIARIES_REVIEW', beneficiariesReview)
}
