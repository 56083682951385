import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import axios from 'axios'

import IntroSection from './introSection/IntroSection'
import Hmo from '../landingPage/Hmo/Hmo'
import IntroSectionTwo from '../landingPage/IntroSectionTwo/IntroSectionTwo'
import SubscriptionPlan from './subscriptionPlan/SubscriptionPlan'
import Ready from '../landingPage/readySection/Ready'
import Footer from '../../../../healthBancComponents/Templates/Footer'

import { getHealthInsuredPlanAndState } from '../../../../redux/healthBanc/actions/healthInsuredCreators/healthInsuredPlanAndState'
import { profileCompletionSuccess } from '../../../../redux/healthBanc/actions/healthInsuredCreators/profileCompletionStatus'
import Header from './header/Header'
import Hero from './hero/Hero'

const customerStateUrl = '/v2/api/Insurance/GetProfileCompletion'

// const customerStateUrl = '/v2/api/Insurance/GetProfileCompletion'
const getHealthPlanUrl = '/v1/api/Insurance/AxaMansardGetHealthPlans'
const statesUrl = '/v1/api/Insurance/GetState'

const LandingPage = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const authenticatedUser = useSelector((state) => state.user.userInfo.isAuth)

  const dispatch = useDispatch()

  const history = useHistory()

  //Dispatch a request to check the profile completion status of the signed in user
  useEffect(() => {
    if (authenticatedUser) {
      dispatch({ type: 'PROFILE_COMPLETION_STATUS' })
    }
  }, [authenticatedUser])

  useEffect(() => {
    if (authenticatedUser) {
      /**make concurrent axios call to get healthplans and list of states */
      function getHealthPlan() {
        return axios.get(getHealthPlanUrl)
      }

      function getStates() {
        return axios.get(statesUrl)
      }

      axios
        .all([getHealthPlan(), getStates()])
        .then(
          axios.spread((healthplanResponse, statesResponse) => {
            const healthplans = healthplanResponse.data.data
            const states = statesResponse.data

            const state = { healthplans, states }

            // dispatch all state to redux store
            dispatch(getHealthInsuredPlanAndState(state))
          })
        )
        .catch((error) => {
          error.response
            ? setError(error.response.data.message)
            : setError(error.message)
        })
    }
  }, [authenticatedUser])

  useEffect(() => {
    window.scrollTo(0, 0) // take the user to page top on redirection to the landing page

    /**make concurrent axios call to get healthplans and list of states */
    dispatch(getHealthInsuredPlanAndState())
  }, [])

  const checkCustomerState = async () => {
    setLoading(true)

    if (!authenticatedUser) {
      history.push('/signin')
      return
    }

    try {
      const response = await axios.get(customerStateUrl)

      const { profileCompleted, tokenizationCompleted } = response.data.data

      profileCompletionSuccess(response.data.data)
      setLoading(false)

      /**redirect a user to profile page if he has registered and tokenized else ask them to sign up */
      // if (profileCompleted && tokenizationCompleted) {
      //   return history.push('/health_profile')
      // }

      return history.push('/health_corporate/select_type')
      // return history.push('/health_signup')
    } catch (error) {
      setLoading(false)

      error.response ? alert(error.response) : alert(error.message)
    }
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 p-0">
          {/* <IntroSection
            checkCustomerState={checkCustomerState}
            loading={loading}
          /> */}
          <Header />
          <Hero checkCustomerState={checkCustomerState}
          loading={loading} />
        </div>
        <div className="col-12 p-0">
          <Hmo />
        </div>
        <div className="col-12 p-0">
          <IntroSectionTwo />
        </div>
        <div className="col-12 p-0">
          <SubscriptionPlan
            checkCustomerState={checkCustomerState}
            loading={loading}
          />
        </div>
        <div className="col-12 p-0">
          <Ready checkCustomerState={checkCustomerState} loading={loading} />
        </div>
        <div className="col-12 p-0">
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default LandingPage
