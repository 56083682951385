import { put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
// import { healthBancSigninAsync } from '../actions/actionCreators'
import {
  signInRequestOTPRequested,
  signInRequestOTPFailed,
  signInRequestOTPSuccessful,
} from '../reducer/healthInsuredSignInRequestOTPSlice'

function* signInRequestOTP({ payload }) {
  const {
    email,
    afterSubmitAction: { setSubmitting },
  } = payload

  const requestOTPAPI = '/v2/api/Identity/GetLoginOTP'

  try {
    yield signInRequestOTPRequested()

    const response = yield axios({
      method: 'POST',
      url: requestOTPAPI,
      data: { email },
    }).then((response) => response)

    // console.log(response)

    // yield put(signInRequestOTPSuccessful(response))
    yield put(signInRequestOTPSuccessful(response?.data))
  } catch (error) {
    if (error.response) {
      yield put(signInRequestOTPFailed(error?.response?.data?.message))
    } else if (error.request) {
      alert('Error! \nPlease try again or contact support.')
    } else {
      // alert(error.message)
      alert('Error Sending OTP! \nPlease try again or contact support.')
    }
  } finally {
    setSubmitting(false)
  }
}

// Watch for OTP dispatch action
export function* watchSignInRequestOTP() {
  yield takeEvery('HEALTHBANC_SIGNIN_REQUEST_OTP', signInRequestOTP)
}
