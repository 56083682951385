import { put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import {
  createCorporateUserRequest,
  createCorporateUserSuccess,
  createCorporateUserFailed,
} from '../../actions/healthInsuredCreators/corporateUserActions/createCorporateUserActions'

function* createUser(userDetails) {
  const { companyName, companyEmail, insuranceProvider } = userDetails.payload

  yield put(createCorporateUserRequest())

  try {
    const { data } = yield axios
      .post('/v2/api/CorporateInsurance/CreateCorporateUser', {
        name: companyName,
        email: companyEmail,
        insuranceProvider,
      })
      .then((response) => response)

    yield put(createCorporateUserSuccess(data))
  } catch (error) {
    yield put(createCorporateUserFailed(error.response.data.message))
  }
}

export function* watchCreateCorporateUser() {
  yield takeEvery('CREATE_CORPORATE_USER', createUser)
}
