import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { serviceProvider } from '../../../../../redux/healthBanc/actions/healthInsuredCreators/serviceProvider'
import { insuranceProvider } from '../../data/insuranceProvider'
import Tagline from '../Tagline/Tagline'
import Button from '../button/Button'
import ProviderCard from '../providersCard/ProvidersCard'
import Axamansard from '../../assets/axamansard.svg'
import Hygia from '../../assets/hygia.svg'
import Bastion from '../../assets/bastion-logo.png'
import axaBenefitsFile from '../../../files/axa_benefit.pdf'
import hygeiaBenefitsFile from '../../../files/hygeia_benefit.pdf'
import bastionBenefitsFile from '../../../files/Bastion_Health_Plans_Lafiya_Plan.pdf'

const MainSection = () => {
  const [choosenProvider, setChoosenProvider] = useState(null)

  const dispatch = useDispatch()
  const history = useHistory()
  const choosenHealthPlan = useSelector(
    (state) => state.userHealthPlan.choosenPlan
  )
  const { healthInsuredPlan } =
    useSelector((state) => state.profileCompletionStatus.data) ?? {}

  const chooseProvider = (careProviderType) => {
    if (careProviderType === choosenProvider) {
      setChoosenProvider(null)
      return dispatch(serviceProvider(null))
    }

    setChoosenProvider(careProviderType)
    dispatch(serviceProvider(careProviderType))
  }

  const gotoNextPage = () => {
    if (choosenHealthPlan === 'individual' || healthInsuredPlan === 0) {
      history.push('/health_signup')
    } else if (choosenHealthPlan === 'family' || healthInsuredPlan === 1) {
      history.push({
        pathname: '/health_family',
      })
    } else {
      history.push({
        pathname: '/health_corporate/business_registration',
        state: { insuranceProvider: choosenProvider },
      })
    }
  }

  return (
    <main className="choose__provider-section">
      <Tagline />
      <div className="provider-card-wrapper">
        {(history.location.state && history.location.state.fromSocials) ||
        choosenHealthPlan === 'family' ? (
          <ProviderCard
            ProviderLogo={Axamansard}
            provider={insuranceProvider.axamansard}
            chooseProvider={chooseProvider}
            choosenProvider={choosenProvider}
            alt="Axa Mansard"
            benefitFile={axaBenefitsFile}
          />
        ) : (
          <>
            <ProviderCard
              ProviderLogo={Axamansard}
              provider={insuranceProvider.axamansard}
              chooseProvider={chooseProvider}
              choosenProvider={choosenProvider}
              alt="Axa Mansard"
              benefitFile={axaBenefitsFile}
            />

            <ProviderCard
              ProviderLogo={Hygia}
              provider={insuranceProvider.hygeia}
              chooseProvider={chooseProvider}
              choosenProvider={choosenProvider}
              alt="Hygeia"
              benefitFile={hygeiaBenefitsFile}
            />

            {/* <ProviderCard
              ProviderLogo={Bastion}
              provider={insuranceProvider.bastion}
              chooseProvider={chooseProvider}
              choosenProvider={choosenProvider}
              alt="Bastion"
              benefitFile={bastionBenefitsFile}
            /> */}
          </>
        )}
      </div>
      <Button
        text="Proceed"
        handler={gotoNextPage}
        disable={!choosenProvider ? true : false}
      />
    </main>
  )
}

export default MainSection
