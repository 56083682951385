const sortByoptions = [
  {
    id: 1,
    name: 'Subject'
  },
  {
    id: 2,
    name: 'Date'
  }
];

export default sortByoptions;