import React from 'react'

// component imports
import Header from './Header'
import Sidebar from './Sidebar'
import MainSection from './MainSection'

const AdminDashboard = () => {
  return (
    <main className="hb-admin-dashboard">
      <div className="hb-admin-item">
        <Header />
      </div>

      <div className="hb-admin-item">
        <div className="hb-admin-child">
          <Sidebar />
        </div>
        <div className="hb-admin-child">
          <MainSection />
        </div>
      </div>
    </main>
  )
}

export default AdminDashboard
