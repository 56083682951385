import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    payWithWallet: false
}

const payWithWalletSlice = createSlice({
    name: 'payWallet',
    initialState,
    reducers: {
        setPayWithWallet: (state, action) => {
            state.payWithWallet = action.payload
        },

        // setPayWithCard: (state, action) => {
        //     state.payWithWallet = false
        // }
    }
})

export const {setPayWithWallet} = payWithWalletSlice.actions
export default payWithWalletSlice.reducer