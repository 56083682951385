import React from 'react';

const CustomSelectInput = ({
	labelName,
	handleChange,
	inputName,
	inputValue,
	hideOption,
	selectOptions,
	errorMessage,
}) => {


	return (
		<div className='d-flex flex-column mb-2 axa-mansard-signup-input-group'>
			<label className='mb-1 select-label d-flex flex-column' htmlFor={inputName}>
				{labelName}
				<span className='arrow-icon'>&#10597;</span>
				<select
					onChange={(e) => handleChange(e)}
					name={inputName}
					className='select-field px-2'
					id={inputName}
					value={inputValue}
				>
					<option value=''>{null}</option>
					{selectOptions}
				</select>
			</label>
			{errorMessage && (
				<p className='m-0 text-justify text-danger'>
					<small>*{errorMessage}</small>
				</p>
			)}
		</div>
	)
}

export default CustomSelectInput;



