import React from 'react'
import { useHistory } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap'
import { FaUserCircle } from 'react-icons/fa'

import { IoMdNotificationsOutline } from 'react-icons/io'
import HealthBancLogo from '../../../../../healthBancComponents/HealthBancLogo/HealthBancLogo'

import './navbar.scss'

const Navbar = () => {
  const history = useHistory()

  return (
    <nav className="nav healthInsuredAdmin__navbar">
      <div className="navbar__logo-container">
        <HealthBancLogo />
      </div>

      <Dropdown>
        {/* <div className="me2 healthInsuredAdmin__navbar__notification">
          <span className="navbar__notification--notification-icon">
            <IoMdNotificationsOutline size="1.5em" />{' '}
          </span>
          <span className="healthInsuredAdmin__navbar__notification--new-notification-alert"></span>
        </div> */}
        <Dropdown.Toggle
          variant="outline"
          id="dropdown-basic"
          className="d-flex"
        >
          <div className="healthInsuredAdmin__navbar--avatar">
            <FaUserCircle size="3em" />
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => history.push('/admin')}>
            Back to Admin
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      {/* </div> */}
    </nav>
  )
}

export default Navbar
