import React, { useState, useEffect } from 'react'
import { withRouter, Redirect, Link } from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'

import BackgroundTemplate from '../Templates/BackgroundTemplate'
import CustomInput from '../Templates/CustomInput'
import CustomButton from '../Templates/CustomButton'

const Condition = styled.div`
  font-size: 13px;
  color: #494d55;
  margin-bottom: 20px;

  span {
    color: #d71e1b;
  }
`

const LoginLink = styled.div`
  font-size: 14px;

  button {
    color: #d71e1b;
    text-decoration: none;
    border: none;
    background-color: inherit;
  }
`

const HBSignup = ({ user, history }) => {
  const [signupFormInitialValues, setSignupFormInitialValues] = useState({})

  const dispatch = useDispatch()

  const signupState = useSelector((state) => state.signup)

  useEffect(() => {
    setSignupFormInitialValues({
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      password: '',
      confirmPassword: '',
    })
  }, [])

  //@desc Using Yup to validate input fields
  const signUpSchema = Yup.object({
    firstName: Yup.string()
      .min(2, 'Enter 2 or more letters')
      .matches(/^[A-Z\-]+$/i, 'Enter letters and hyphens only')
      .required('Required'),
    lastName: Yup.string()
      .min(2, 'Enter 2 or more letters')
      .matches(/^[A-Z\-]+$/i, 'Enter letters and hyphens only')
      .required('Required'),
    email: Yup.string().email('Invalid Email').required('Required'),
    phoneNumber: Yup.string()
      .min(11, 'Phone number should be at least 11 digits. ')
      .max(13, 'Phone Number should not be more than 13 digits')
      .required('Required'),
    password: Yup.string()
      .required('Required')
      .min(8, 'Password must be 8 characters or more')
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/,
        'Must Contain 8 Characters or more, 1 Uppercase, 1 Lowercase and 1 Number and a special character.'
      ),
    confirmPassword: Yup.string()
      .required('Required')
      .oneOf([Yup.ref('password'), null], 'Passwords do not match'),
  })

  const handleSignup = async (values, { resetForm, setSubmitting }) => {
    dispatch({
      type: 'SIGNUP',
      payload: { values, resetForm, setSubmitting, history },
    })
  }

  if (!user.isAuth) {
    return (
      <BackgroundTemplate
        formTitle="Get Started"
        subTitle="Sign up to have access to HealthInsured"
        showLogo={true}
      >
        <Formik
          initialValues={signupFormInitialValues}
          validationSchema={signUpSchema}
          onSubmit={(values, { resetForm, setSubmitting }) =>
            handleSignup(values, { resetForm, setSubmitting })
          }
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <Field
                as={CustomInput}
                label="First Name"
                name="firstName"
                type="text"
                placeholder="Enter your first name"
                title="Letters Only (Minimum: 2)"
                autoFocus
              />

              <Field
                as={CustomInput}
                label="Last Name"
                name="lastName"
                type="text"
                placeholder="Enter your last name"
                title="Letters Only (Minimum: 2)"
              />

              <Field
                as={CustomInput}
                label="Email"
                name="email"
                type="email"
                placeholder="example@company.com"
              />

              <Field
                as={CustomInput}
                label="Phone Number"
                name="phoneNumber"
                type="tel"
                minLength="11"
                maxLength="13"
                placeholder="e.g 08087654321"
              />

              <Field
                as={CustomInput}
                label="Password"
                name="password"
                type="password"
                placeholder="Choose password"
              />

              <Field
                as={CustomInput}
                label="Confirm Password"
                name="confirmPassword"
                type="password"
                placeholder="Repeat password"
              />

              <Condition className="terms mt-4">
                By signing up you agree to the{' '}
                <span>
                  <Link to="/terms">Terms & Conditions</Link>
                </span>{' '}
                and{' '}
                <span>
                  <Link to="/privacyPolicy">Privacy Policy</Link>
                </span>
                .
              </Condition>

              <CustomButton
                type="submit"
                disabled={signupState.isLoading ? true : false}
              >
                {signupState.isLoading ? 'Signing you up...' : 'Submit'}
              </CustomButton>
            </Form>
          )}
        </Formik>

        <LoginLink className="text-center my-4">
          Already have an account?
          <button
            onClick={() => history.push('./signin')}
            className="text-danger"
          >
            Sign In
          </button>
        </LoginLink>
      </BackgroundTemplate>
    )
  } else {
    return <Redirect to="/dashboard" />
  }
}

const mapStateToProps = (state) => ({
  user: state.user.userInfo,
})

export default connect(mapStateToProps)(withRouter(HBSignup))
