import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import TimePicker from 'react-time-picker';


import { Editor } from '@tinymce/tinymce-react';
import { ReactComponent as Close } from '../../../../../../assets/healthInsuredAdmin/close.svg';
import { ReactComponent as Calendar } from '../../../../../../assets/healthInsuredAdmin/calendar.svg';


// import tiny from '../../../../../../../node_modules/tinymce/tinymce'

const CreateNotification = ({
  showCreateNotification,
  handleCloseCreateNotification

}) => {

  const [content, setContent] = useState()
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState(new Date());


  const handleEditorChange = (content, editor) => {
    setContent(content)
  }


  return (
    <Modal
      show={showCreateNotification}
      onHide={handleCloseCreateNotification}
      dialogClassName="modal-lg health__insured__admin__create__notification__modal"
      aria-labelledby="create notification modal"
      animation={true}
      autoFocus={true}
      keyboard={true}
    >
      <Modal.Body
        className='modal-body health__insured__admin__create__notification__modal--body'
      >

        <i className='health__insured__admin__create__notification__modal--icon-wrapper' onClick={handleCloseCreateNotification}>
          <Close className='health__insured__admin__create__notification__modal--icon-close' />
        </i>
        <h2 className='health__insured__admin__create__notification__modal--heading'>Create Notification</h2>


        <form className='health__insured__admin__create__notification__modal--form'>

          <div className='d-flex flex-column'>
            <label>
              <input
                type='text'
                placeholder='Notification Subject'
                className='health__insured__admin__create__notification__modal--input' />
            </label>
          </div>

          <Editor
            initialValue="Write a message.."
            apiKey='yl681t37j5ia4xgl5gd26ulehl7gp19tq1jmo8t6pnb0ttsp'

            init={{
              height: 300,
              menubar: false,
              branding: false,
              value: content,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help '
              ],
              toolbar:
                'undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help',
              image_list: [
              ]
            }}
            onEditorChange={handleEditorChange}
          />

          <div className='health__insured__admin__create__notification__modal--date--time--wrapper'>
            <div className='d-flex flex-column mt-2'>
              <label>Date</label>
              <DatePicker
                onChange={setDate}
                value={date}
                calendarIcon='&#128197;'
                clearIcon={null}
                className='health__insured__admin__create__notification__modal--calendar'
              />
            </div>

            <div className='d-flex flex-column mt-2'>
              <label>Time</label>
              <TimePicker
                clearIcon={null}
                onChange={(value) => setTime(value)}
                value={time}
                clockIcon='&#9202;'
                className='health__insured__admin__create__notification__modal--time'
              />
            </div>
          </div>

          <div>
            <button className='health__insured__admin__create__notification__modal--btn-scheduled'
              onClick={handleCloseCreateNotification}>
              &#x1F4C5; Schedule
            </button>


            <button className='health__insured__admin__create__notification__modal--btn-save' form='update-role'>
              Save as draft
              </button>
          </div>
        </form>

      </Modal.Body>
    </Modal>
  )
}

export default CreateNotification;
