import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import LoadingIndicator from '../../../../../../healthBancComponents/LoadingIndicator/LoadingIndicator'
import HealthInsuredPagination from '../../../../HealthInsuredCorporateFlow/components/healthInsuredPagination/HealthInsuredPagination'
import './userTable.scss'

const UsersTable = ({
  handleGoToPrevPage,
  handleGoToNextPage,
  pageDetails,
  ...props
}) => {
  const history = useHistory()

  const profiles = useSelector((state) => state.getInsuranceProfiles)

  const displayUsersDetails = () => {
    if (profiles && profiles.data.data) {
      return profiles.data.data.length === 0 ? (
        <tr>
          <td colSpan="6">
            <p className="text-center">
              There are currently no registered users.
            </p>
          </td>
        </tr>
      ) : (
        profiles &&
          profiles.data.data.map((details) => (
            <tr
              key={details.email}
              style={{ cursor: 'pointer' }}
              onClick={() =>
                history.push({
                  pathname: `/admin/health_insured/users/profile/${details.surname}`,
                  state: { userPersonalInfo: details },
                })
              }
            >
              <td>{`${details.surname}, ${details.othernames}`}</td>
              <td>{details.email}</td>
              <td>{details.phoneNumber}</td>
              <td>{details.companyName || 'N/A'}</td>
              <td>{details.insuranceService}</td>
            </tr>
          ))
      )
    }
  }

  return (
    <div className="healthInsuredAdmin__user-table table-responsive">
      <div>
        <table className="table table-hover" id="admin_users_table-to-xls">
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Company</th>
              <th>Service Provider</th>
            </tr>
          </thead>
          <tbody>
            {profiles.isLoading ? (
              <tr>
                <td colSpan="6" className="text-center">
                  <LoadingIndicator />
                </td>
              </tr>
            ) : (
              profiles && profiles.data && displayUsersDetails()
            )}
          </tbody>
        </table>
        <div className="ml-auto mt-2 healthInsuredAdmin__user-table--pagination">
          <HealthInsuredPagination
            prev={handleGoToPrevPage}
            next={handleGoToNextPage}
            pageDetails={pageDetails}
          />
        </div>
      </div>
    </div>
  )
}

export default UsersTable
