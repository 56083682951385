import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import NavWallet from '../NavWallet'
import '../createNewWallet/styles/VerifyOtp.css'
import Progress from '../../Progress'
import Payment from '../../Payment'
// verified otp
import { _otp } from './InputOtp'
import { _phoneNumber } from './Pay'

const VerifiedOtp = () => {
  const [redirectingTimer, setRedirectingTimer] = useState(5)
  let history = useHistory()

  let otp_spread = [..._otp]

  // function handleClick() {
  //   history.push('/health_success_signup')
  // }

  useEffect(() => {
    const interval = setInterval(() => {
    setRedirectingTimer((prev) => prev- 1)
    },  1000)
    return () => {
        clearInterval(interval)
        }
    }, [])
    
  useEffect(() => {
          const interval = setInterval(() => {
            history.push('/health_success_signup')
      
      }, 5000)

          return () =>{
          clearInterval(interval)
          }
  }, [history])

  return (
    <div className="ver-main">
      <NavWallet />
      <Payment />
      <section className="ver-sect">
        <div className="verify_otp" style={{ paddingTop: '10px' }}>
          <div className="green-v">
            <p>OTP validated successfully.</p>
          </div>
          <div className="otp_pos">
            <h2>Verify your phone number</h2>
            <p>
              A (One Time Password) OTP was sent to your Phone Number. Enter the
              code to continue.
            </p>

            <div className="verify_otp_sp">
              {otp_spread.map((l, i) => (
                <span key={i}>{l}</span>
              ))}
            </div>

            <h4>Redirecting page in {redirectingTimer} seconds</h4>
            <div className="ver">
              {/* <button onClick={handleClick}>
                <p>Verified</p>
              </button> */}

              {/* <p>Did not get OTP? Click to resend OTP in 20s</p> */}
            </div>
          </div>
        </div>
      </section>
      <Progress />
    </div>
  )
}

export default VerifiedOtp
