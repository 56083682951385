import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: {},
  errors: [],
}

const addFriendWithDetailsSlice = createSlice({
  name: 'add_friend_with_details',
  initialState,
  reducers: {
    ADD_FRIEND_WITH_DETAILS_REQUESTED: (state) => {
      return { isLoading: true, data: {}, errors: [] }
    },

    ADD_FRIEND_WITH_DETAILS_SUCCESSFUL: (state, action) => {
      return { isLoading: false, data: action.payload, errors: [] }
    },

    ADD_FRIEND_WITH_DETAILS_FAILED: (state, action) => {
      return { isLoading: false, data: {}, errors: [action.payload] }
    },
  },
})

export const {
  ADD_FRIEND_WITH_DETAILS_REQUESTED,
  ADD_FRIEND_WITH_DETAILS_SUCCESSFUL,
  ADD_FRIEND_WITH_DETAILS_FAILED,
} = addFriendWithDetailsSlice.actions

export default addFriendWithDetailsSlice.reducer
