import { takeLatest, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { toast } from 'react-toastify'

import {
  walletPaymentFailed,
  walletPaymentRequested,
  walletPaymentSuccessful,
} from '../../../reducer/oneDrugStore/user/walletPaymentSlices'

function* walletPaymentAsync({ payload }) {
  try {
    yield walletPaymentRequested()

    const { data } = yield axios({
      method: 'POST',
      url: `${process.env.REACT_APP_ONE_DRUG_BASE}/v1/api/WalletPayment/SendUserOTP`,
      data: {
        mobile: payload.phoneNumber,
      },
    })

    yield toast.success(`OTP sent to ${payload.phoneNumber}`)

    yield put(walletPaymentSuccessful(data))
    yield put(walletPaymentSuccessful(null))
  } catch (error) {
    if (error.response) {
      // yield toast.error(error.response.data.responseDescription)
      yield put(walletPaymentFailed(error.response.data.responseDescription))
      yield put(walletPaymentFailed(null))
      return
    }

    yield put(
      walletPaymentFailed('And error occured!\nTry again or contact support')
    )
    yield put(walletPaymentFailed(null))
  }
}

export function* watchWalletPayment() {
  yield takeLatest(walletPaymentRequested.type, walletPaymentAsync)
}
