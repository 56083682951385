import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { Table, Col, Row } from 'react-bootstrap'
import HealthInsuredDashboardStructure from '../../HealthInsuredDashboardStructure/HealthInsuredDashboardStructure'
import { LogSearchAndFilter } from '../healthInsuredAdminTransactionLog/HealthInsuredAdminTransactionLog'
import HealthInsuredPagination from '../../../HealthInsuredCorporateFlow/components/healthInsuredPagination/HealthInsuredPagination'
import LoadingIndicator from '../../../../../healthBancComponents/LoadingIndicator/LoadingIndicator'
import ErrorLogModal from './ErrorLogModal'

import './healthInsuredAdminErrorLog.scss'

const HealthInsuredAdminErrorLog = () => {
  const [show, setShow] = useState(false)
  const [errorDetails, setErrorDetails] = useState({})

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const [filter, setFilter] = useState({
    pageNumber: 1,
    searchText: '',
  })

  const dispatch = useDispatch()
  const errorLogs = useSelector((state) => state.getHealthInsuredAdminErrorLogs)

  useEffect(() => {
    dispatch({
      type: 'GET_HEALTHINSURED_ADMIN_ERROR_LOGS',
      payload: { ...filter },
    })
  }, [dispatch, filter])

  const handleShowModal = (errorLog) => {
    setErrorDetails(errorLog)
    setShow(true)
  }

  const displayErrors = () => {
    if (errorLogs.hasOwnProperty('data')) {
      const { data } = errorLogs.data
      return data.length === 0 ? (
        <tr>
          <td colSpan="6" className="text-center">
            No logged errors
          </td>
        </tr>
      ) : (
        data.map((errorLog) => (
          <tr
            key={errorLog.id}
            onClick={() => handleShowModal(errorLog)}
            style={{ cursor: 'pointer' }}
          >
            <td className="text-wrap">{errorLog.errorMessage}</td>
            <td>{errorLog.source}</td>
            {/* <td>{error.link}</td> */}
            <td>{errorLog.errorCode}</td>
            <td>{new Date(errorLog.errorDate).toDateString()}</td>
            <td className="text-wrap m">
              {errorLog.stackTrace.slice(0, 300)}...
            </td>
          </tr>
        ))
      )
    }
  }

  const handleFilterByDate = (e) => {
    setFilter({
      ...filter,
      searchText: new Date(e.target.value).toISOString(),
    })
  }

  const handleGoToNext = () => {
    if (errorLogs.hasOwnProperty('data')) {
      const { data } = errorLogs

      if (data.pageNumber >= data.pageCount) {
        return
      } else {
        setFilter({ ...filter, pageNumber: filter.pageNumber + 1 })
      }
    }
  }

  const handleGoToPrev = () => {
    if (errorLogs.hasOwnProperty('data')) {
      const { data } = errorLogs

      if (data.pageNumber < data.pageCount) {
        setFilter({ ...filter, pageNumber: filter.pageNumber - 1 })
      }
    }
  }

  return (
    <HealthInsuredDashboardStructure title="Error Log">
      <ErrorLogModal
        handleShow={handleShow}
        handleClose={handleClose}
        show={show}
        errorDetails={errorDetails}
      />

      <div className="healthInsuredAdminErrorLog">
        <LogSearchAndFilter
          filter={filter}
          setFilter={setFilter}
          tableName="healthInsured_admin_error_log"
          handleFilterByDate={handleFilterByDate}
        />

        <Row>
          <Col>
            <Table responsive hover id="healthInsured_admin_error_log">
              <thead>
                <tr>
                  <th>Error Message</th>
                  <th>Error Source</th>
                  {/* <th>Help Link</th> */}
                  <th>Error Code</th>
                  <th>Error Date</th>
                  <th>Stack Trace</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {errorLogs.isLoading ? (
                  <tr>
                    <td colSpan="6" className="text-center">
                      <LoadingIndicator />
                    </td>
                  </tr>
                ) : (
                  displayErrors()
                )}
              </tbody>
            </Table>
          </Col>
        </Row>

        <div className="d-flex justify-content-end mt-4">
          <HealthInsuredPagination
            prev={handleGoToPrev}
            next={handleGoToNext}
            pageDetails={errorLogs.hasOwnProperty('data') && errorLogs.data}
          />
        </div>
      </div>
    </HealthInsuredDashboardStructure>
  )
}

export default HealthInsuredAdminErrorLog
