import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Formik, Field } from 'formik'
import * as yup from 'yup'
import CustomInput from '../../../../healthBancComponents/Templates/CustomInput'
import CustomButton from '../../../../healthBancComponents/Templates/CustomButton'
import WalletOTPModal from '../cartSummary/WalletOTPModal'
import WalletPaymentModal from '../cartSummary/WalletPaymentModal'

import './shippingDetailsForm.scss'
import { updateDeliveryInfoRequested } from '../../../../redux/healthBanc/reducer/oneDrugStore/user/deliveryInfoSlices/updateDeliveryInfoSlice'
import { cardPaymentRequested } from '../../../../redux/healthBanc/reducer/oneDrugStore/user/cardPaymentSlices'
import { getDeliveryInfoRequested } from '../../../../redux/healthBanc/reducer/oneDrugStore/user/deliveryInfoSlices'

const ShippingDetailsForm = () => {
  const [deliveryInfo, setDeliveryInfo] = useState({})
  const [showPhoneNumberModal, setShowPhoneNumberModal] = useState(false)
  const [showOTPModal, setShowOTPModal] = useState(false)

  const history = useHistory()
  const { hash } = useLocation()

  const dispatch = useDispatch()

  const { data: sendUserOTPResponse } = useSelector(
    (state) => state.walletPayment
  )

  const { isLoading: cardPaymentLoading } = useSelector(
    (state) => state.cardPayment
  )
  const { data } = useSelector((state) => state.getDeliveryInfo)
  const { isLoading: updateDeliveryInfoLoading, data: updatedDeliveryInfo } =
    useSelector((state) => state.updateDeliveryInfo)

  const isAuthUser = useSelector((state) => state.user.userInfo.isAuth)

  const handleShow = () => {
    if (isAuthUser) {
      setShowOTPModal(false)
      setShowPhoneNumberModal(true)
    } else {
      window.localStorage.setItem('fromOneDrugStore', 'true')
      history.push('/signin')
    }
  }

  const handleShowOTP = () => {
    if (isAuthUser) {
      setShowOTPModal(true)
      setShowPhoneNumberModal(false)
    } else {
      window.localStorage.setItem('fromOneDrugStore', 'true')
      history.push('/signin')
    }
  }

  useEffect(() => {
    dispatch(getDeliveryInfoRequested())
  }, [dispatch])

  useEffect(() => {
    data?.data && setDeliveryInfo(data.data)
  }, [data])

  //Check if delivery info update was successful
  useEffect(() => {
    if (updatedDeliveryInfo?.status) {
      if (hash === '#cardPayment') {
        dispatch(cardPaymentRequested())
      } else {
        handleShow()
      }
    }
  }, [dispatch, hash, updatedDeliveryInfo])

  //If otp was sent to phone number successfully
  //Hide phone number modal and show otp modal
  useEffect(() => {
    if (sendUserOTPResponse?.status) {
      setShowOTPModal(true)
      setShowPhoneNumberModal(false)
    }
  }, [sendUserOTPResponse])

  const detailsSchema = yup.object().shape({
    email: yup
      .string()
      .email('Invalid email')
      .required('Email address is required'),
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    address: yup.string().required('A delivery address is required'),
    city: yup.string().required('City is required'),
    state: yup.string().required('State is required'),
    country: yup.string().required('Country is required'),
    phoneNumber: yup
      .string()
      .min(11)
      .max(13)
      .required('Phone number is required'),
  })

  const handleSubmit = (values, actions) => {
    dispatch(
      updateDeliveryInfoRequested({
        values: { ...values, userId: deliveryInfo.userId },
        history,
      })
    )
  }
  return (
    <div>
      <ToastContainer />
      <Formik
        initialValues={{
          email: deliveryInfo.email || '',
          firstName: deliveryInfo.firstName || '',
          lastName: deliveryInfo.lastName || '',
          address: deliveryInfo.streetAddress || '',
          city: deliveryInfo.city || '',
          state: deliveryInfo.state || '',
          country: deliveryInfo.country || '',
          phoneNumber: deliveryInfo.phonenumber || '',
        }}
        enableReinitialize={true}
        validationSchema={detailsSchema}
        onSubmit={(values, actions) => handleSubmit(values, actions)}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <Field
              as={CustomInput}
              name="email"
              label="Email address"
              placeholder="example@email.com"
              disabled={deliveryInfo.email}
              value={deliveryInfo.email || props.email}
              type="email"
            />

            <Field
              as={CustomInput}
              name="firstName"
              label="First name"
              placeholder="Enter your first name"
              disabled={deliveryInfo.firstName}
              value={deliveryInfo.firstName || props.firstName}
              type="text"
            />

            <Field
              as={CustomInput}
              name="lastName"
              label="Last name"
              placeholder="Enter your Last name"
              disabled={deliveryInfo.lastName}
              value={deliveryInfo.lastName || props.lastName}
              type="text"
            />

            <Field
              as={CustomInput}
              name="address"
              label="Delivery address"
              placeholder="Enter your Delivery address"
              value={deliveryInfo.streetAddress || props.address}
              type="address"
            />

            <Field
              as={CustomInput}
              name="city"
              label="City"
              placeholder="Enter your delivery city"
              value={deliveryInfo.city || props.city}
              type="text"
            />
            <Field
              as={CustomInput}
              name="state"
              label="State"
              placeholder="Enter your delivery state"
              value={deliveryInfo.state || props.state}
              type="text"
            />

            <Field
              as={CustomInput}
              name="country"
              label="Country"
              placeholder="Enter your delivery country"
              value={deliveryInfo.country || props.country}
              type="text"
            />

            <Field
              as={CustomInput}
              name="phoneNumber"
              label="Phone number"
              placeholder="Enter your phone number"
              value={deliveryInfo.phonenumber || props.phoneNumber}
              type="tel"
            />

            <div className="mt-5 col-xs-12 col-lg-5">
              <CustomButton
                type="submit"
                disabled={updateDeliveryInfoLoading || cardPaymentLoading}
              >
                {updateDeliveryInfoLoading || cardPaymentLoading
                  ? 'Processing...'
                  : 'Next'}
              </CustomButton>
            </div>
          </form>
        )}
      </Formik>

      <WalletPaymentModal
        setShow={setShowPhoneNumberModal}
        show={showPhoneNumberModal}
      />

      <WalletOTPModal setShow={setShowOTPModal} show={showOTPModal} />
    </div>
  )
}

export default ShippingDetailsForm
