import {
  GET_EXTENDED_USER_INSURANCE_PROFILE_FAILED,
  GET_EXTENDED_USER_INSURANCE_PROFILE_REQUEST,
  GET_EXTENDED_USER_INSURANCE_PROFILE_SUCCESS,
} from '../../healthInsuredTypes/healthInsuredAdminActionTypes'

export const getExtendedHealthInsuredAdminProfileRequest = () => ({
  type: GET_EXTENDED_USER_INSURANCE_PROFILE_REQUEST,
})

export const getExtendedHealthInsuredAdminProfileSuccess = (data) => ({
  type: GET_EXTENDED_USER_INSURANCE_PROFILE_SUCCESS,
  payload: data,
})

export const getExtendedHealthInsuredAdminProfileFailed = (errorMessage) => ({
  type: GET_EXTENDED_USER_INSURANCE_PROFILE_FAILED,
  payload: errorMessage,
})
