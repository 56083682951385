import React, { useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import { Formik, Field } from 'formik'
import * as yup from 'yup'
import CustomInput from '../../../../healthBancComponents/Templates/CustomInput'
import CustomButton from '../../../../healthBancComponents/Templates/CustomButton'
import { walletConfirmOTPRequested } from '../../../../redux/healthBanc/reducer/oneDrugStore/user/walletPaymentSlices'

const WalletOTPModal = ({ setShow, show }) => {
  const handleClose = () => setShow(false)

  const dispatch = useDispatch()
  const history = useHistory()

  const { data, isLoading, error } = useSelector(
    (state) => state?.walletConfirmOTP
  )

  useEffect(() => {
    if (error) {
      toast.error(error)
      return
    }

    if (data) {
      //push to payment success page
      history.push('/oneDrugStore/payment-success')
    }
  }, [data, error, history])

  const otpSchema = yup.object().shape({
    otp: yup.string().required('OTP is required'),
  })

  const handleSubmit = (values) => {
    dispatch(walletConfirmOTPRequested(values))
  }

  return (
    <div>
      <ToastContainer />
      <>
        <Modal centered show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Enter OTP</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                otp: '',
              }}
              validationSchema={otpSchema}
              onSubmit={(values, actions) => handleSubmit(values, actions)}
            >
              {(props) => (
                <form onSubmit={props.handleSubmit}>
                  <Field
                    as={CustomInput}
                    name="otp"
                    label="OTP"
                    placeholder="Enter your OTP"
                    type="tel"
                  />
                  <div className="mt-2 col-xs-12 col-lg-5">
                    <CustomButton type="submit" disabled={isLoading}>
                      {isLoading ? 'Confirming OTP...' : 'Confirm OTP'}
                    </CustomButton>
                  </div>
                </form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
      </>
    </div>
  )
}

export default WalletOTPModal
