import {
  UPLOAD_BENEFICIARIES_FILE_FAILED,
  UPLOAD_BENEFICIARIES_FILE_REQUEST,
  UPLOAD_BENEFICIARIES_FILE_SUCCESS,
} from '../../../actions/healthInsuredTypes/corporateActionTypes'

export const uploadCorporateBeneficiariesReducer = (state = {}, action) => {
  switch (action.type) {
    case UPLOAD_BENEFICIARIES_FILE_REQUEST:
      return { isLoading: true, errorMessage: null }

    case UPLOAD_BENEFICIARIES_FILE_SUCCESS:
      alert(action.payload.message)
      window.location.href = '/health_corporate/beneficiary_review'
      return { isLoading: false, ...action.payload.data }

    case UPLOAD_BENEFICIARIES_FILE_FAILED:
      alert(action.payload)
      return { isLoading: false, errorMessage: action.payload }

    default:
      return state
  }
}
